import React from 'react';
import { oneOfType, any } from 'prop-types';
import { Switch, Route, useHistory } from 'react-router-dom';
import MainLayout from 'views/layouts/main';
import NotFound from 'views/not-found';
import Login from 'views/identity/login';
import DevicesMap from 'views/devices-map';
import Dashboard from 'views/dashboard';
import Devices from 'views/devices';
import Rides from 'views/rides';
import Campaigns from 'views/campaigns';
import AddEditCampaign from 'views/campaigns/add-edit-campaign';
import EnrollDevices from 'views/enroll-devices';
import CampaignQr from 'views/campaign-qr';
import AdInventoryConfig from 'views/ad-inventory-config';
import Reading from 'views/reading';
import Games from 'views/games';
import Discover from 'views/discover';
import WatchContent from 'views/watch/content';
import Profile from 'views/profile';
import { getUserLocalStorage } from 'utils';
import Users from 'views/users';
import AppTiles from 'views/app-tiles';
import WatchCategory from 'views/watch/category';
import DevicesStatus from 'views/devices-status';
import DeviceDetailsPage from 'views/devices/detail-page';
import EnrolledDeviceDetailsPage from 'views/enroll-devices/detail-page';
// import AdGames from 'views/games/ad-games';
import AppSettings from 'views/app-settings';
import DevicesRating from 'views/devices-rating';
import CalculateGeofence from 'views/campaigns/calculate-geofence';
// V2 Import start
import DevicesList from 'views/v2/devices';
import DeviceDetailPage from 'views/v2/devices/detail-page';
import RidesList from 'views/v2/rides';
import CampaignsList from 'views/v2/campaigns';
import BinaryosReleaseManager from 'views/v2/app-release-manager/binaryos-manager';
import UnisocReleaseManager from 'views/v2/app-release-manager/unisoc';
import DevicesLocationMap from 'views/v2/devices-map';
import DeviceRatingList from 'views/v2/devices-rating';
import UserRoles from 'views/v2/user-roles';
// import socketIOClient from 'socket.io-client'; // TO-DO Socket code commented
import AddEditUserRole from 'views/v2/user-roles/add-edit-user-role';
import SocketTestList from 'views/v2/socket-test';
import SocketDeviceDetailPage from 'views/v2/socket-test/detail-page';
import ImportDevices from 'views/v2/devices/import-devices';
import UsersList from 'views/v2/users';
import AddEditUser from 'views/v2/users/add-edit-user';
import AdDeliveryReportList from 'views/v2/ad-delivery-reports';
import BinaryDeviceManager from 'views/v2/app-release-manager/binary-device-manager';
import ReadingList from 'views/v2/content/reading';
import GamesList from 'views/v2/content/games';
import DiscoverList from 'views/v2/content/discover';
import WatchCategoryList from 'views/v2/content/watch/category';
import WatchContentList from 'views/v2/content/watch/content';
import AddEditCampaigns from 'views/v2/campaigns/add-edit-campaign';
import RideMapView from 'views/v2/rides/rideMap';
import ViewCampaign from 'views/v2/campaigns/ViewCampaign/ViewCampaign';
import CreateCampaign from 'views/v2/campaigns/Create Campaign/CreateCampaign';
import ActiveCampaigns from 'views/v2/campaigns/active-campaigns';
import InActiveCampaigns from 'views/v2/campaigns/in-active-campaigns';
import NewViewCampaigns from 'views/v2/campaigns/Create Campaign/new-view-campaigns';
import RidesDetails from 'views/v2/rides/rides-details/RidesDetails';
import RidesCount from 'views/v2/rides/rides-count/RidesCount';
import DeviceIssueReport from 'views/v2/reports/device-issue-report';
import OnGoingRides from 'views/v2/rides/Tabs/OnGoingRides';
import CreateUser from 'views/v2/users/create-user/CreateUser';
import MusicContent from 'views/v2/music/content/MusicContent';
import MusicCategory from 'views/v2/music/category/MusicCategory';
// import SocketContext from 'SocketContext'; // TO-DO Socket code commented
// V2 Import end

// let socket = undefined; // TO-DO Socket code commented

const ProtectedRoute = ({ component: Component, ...rest }) => {
  // TO-DO Socket code commented start
  // const [isSocketConnected, setIsSocketConnected] = useState(false);
  // TO-DO Socket code commented end

  const userData = getUserLocalStorage();
  const history = useHistory();

  // TO-DO Socket code commented start
  // useEffect(() => {
  //   console.log('!!!! establish connection for socket !!!!');
  //   if (!socket) {
  //     console.log('!!!! socket connection required !!!!');
  //     socket = socketIOClient(process.env.REACT_APP_DOMAIN_URL, {
  //       transports: ['websocket'],
  //       reconnectionDelayMax: 10000,
  //     });
  //   }

  //   setIsSocketConnected(true);

  //   socket.on('Error', errorDisplay);
  //   socket.on('invalidSession', invalidSessionFun);
  //   socket.on('connect_error', connectError);
  //   socket.on('disconnect', disconnectError);

  //   //clean up while unmount
  //   return () => {
  //     socket.off('Error', errorDisplay);
  //     socket.off('invalidSession', invalidSessionFun);
  //     socket.off('disconnect', disconnectError);
  //     socket.off('connect_error', connectError);
  //   };
  // }, []);

  // const connectError = (err) => {
  //   console.log('!!socket', err instanceof Error); // true
  //   console.log('!!socket', err.message); // not authorized
  //   console.log('!!socket', err.data); //
  // };

  // const errorDisplay = (data) => {
  //   console.log('!!!!!!!!!data prinetd here', data);
  // };

  // const invalidSessionFun = async (data) => {
  //   console.log('!!!!!!!!!!!!!!!!!!!Invalid Session', data);
  //   // showErrorMsg(
  //   //     'Internal server error. System could not complete the request'
  //   //   );
  // };

  // const disconnectError = async (data) => {
  //   console.log('!!!!!!!!!!!!!!!!!!!disconnect socket Session', data);
  //   // showErrorMsg(
  //   //     'Internal server error. System could not complete the request'
  //   //   );
  // };
  // TO-DO Socket code commented end

  return (
    <Route
      {...rest}
      render={(props) =>
        userData?.token ? (
          <MainLayout>
            <Component {...props} />
          </MainLayout>
        ) : (
          history.push('/login')
        )
      }
    />
  );
};

ProtectedRoute.propTypes = {
  component: oneOfType([any]),
};

export default function Routes() {
  return (
    <Switch>
      <ProtectedRoute exact path="/" component={Dashboard} />
      <ProtectedRoute exact path="/dashboard" component={Dashboard} />
      <ProtectedRoute exact path="/devices-map" component={DevicesMap} />
      <ProtectedRoute exact path="/devices" component={Devices} />
      <ProtectedRoute
        exact
        path="/devices/:device_serial"
        component={DeviceDetailsPage}
      />
      <ProtectedRoute exact path="/devices-status" component={DevicesStatus} />
      <ProtectedRoute exact path="/rides" component={Rides} />
      <ProtectedRoute exact path="/campaigns" component={Campaigns} />
      <ProtectedRoute exact path="/campaigns/add" component={AddEditCampaign} />

      <ProtectedRoute
        exact
        path="/campaigns/calc-geofence"
        component={CalculateGeofence}
      />
      <ProtectedRoute exact path="/content/reading" component={Reading} />
      <ProtectedRoute exact path="/content/games" component={Games} />
      <ProtectedRoute exact path="/content/discover" component={Discover} />
      <ProtectedRoute exact path="/content/watch" component={WatchContent} />
      <ProtectedRoute
        exact
        path="/content/watch/category"
        component={WatchCategory}
      />
      <ProtectedRoute exact path="/content/music/content" component={MusicContent}/>
      <ProtectedRoute exact path="/content/music/category" component={MusicCategory}/>
      <ProtectedRoute exact path="/profile" component={Profile} />
      <ProtectedRoute exact path="/users" component={Users} />
      <ProtectedRoute
        exact
        path="/ad-inventory-config"
        component={AdInventoryConfig}
      />
      <ProtectedRoute
        exact
        path="/campaigns/edit/:id"
        component={AddEditCampaign}
      />
      <ProtectedRoute exact path="/enroll-devices" component={EnrollDevices} />
      <ProtectedRoute
        exact
        path="/enroll-devices/:ama_id"
        component={EnrolledDeviceDetailsPage}
      />
      <ProtectedRoute exact path="/content/app-tiles" component={AppTiles} />
      {/* <ProtectedRoute exact path="/ad-games" component={AdGames} /> */}
      <ProtectedRoute exact path="/settings" component={AppSettings} />
      <ProtectedRoute exact path="/devices-rating" component={DevicesRating} />
      <Route exact path="/login" component={Login} />
      <Route exact path="/campaign-qr/:id" component={CampaignQr} />
      {/* V2 Routes Start */}
      <ProtectedRoute exact path="/v2/devices" component={DevicesList} />
      <ProtectedRoute
        exact
        path="/v2/devices/:device_serial"
        component={DeviceDetailPage}
      />
      <ProtectedRoute exact path="/v2/completed-rides" component={RidesList} />
      <ProtectedRoute exact path="/v2/ongoing-rides" component={OnGoingRides} />
      <ProtectedRoute exact path="/v2/rides/:rideId" component={RidesDetails} />
      <ProtectedRoute exact path="/v2/ride-count" component={RidesCount} />
      <ProtectedRoute exact path="/v2/campaigns" component={CampaignsList} />
      <ProtectedRoute
        exact
        path="/v2/binaryos-manager"
        component={BinaryosReleaseManager}
      />
      <ProtectedRoute
        exact
        path="/v2/devices-map"
        component={DevicesLocationMap}
      />
      <ProtectedRoute
        exact
        path="/v2/device-ratings"
        component={DeviceRatingList}
      />
      <ProtectedRoute exact path="/v2/user-roles" component={UserRoles} />
      <ProtectedRoute
        exact
        path="/v2/user-role/add"
        component={AddEditUserRole}
      />
      
      <ProtectedRoute
        exact
        path="/v2/user-role/edit/:id"
        component={AddEditUserRole}
      />
      <ProtectedRoute exact path="/v2/users" component={UsersList} />
      <ProtectedRoute
        exact
        path="/v2/users/create-user"
        component={CreateUser}
      />
      <ProtectedRoute exact path="/v2/user/add" component={AddEditUser} />
      <ProtectedRoute exact path="/v2/user/edit/:id" component={AddEditUser} />
      <ProtectedRoute exact path="/v2/sockets" component={SocketTestList} />
      <ProtectedRoute
        exact
        path="/v2/sockets/:device_serial"
        component={SocketDeviceDetailPage}
      />
      <ProtectedRoute
        exact
        path="/v2/import-devices"
        component={ImportDevices}
      />
      <ProtectedRoute
        exact
        path="/v2/ad-delivery-report"
        component={AdDeliveryReportList}
      />
      <ProtectedRoute
        exact
        path="/v2/binary-device-manager"
        component={BinaryDeviceManager}
      />
      <ProtectedRoute
        exact
        path="/v2/content/reading"
        component={ReadingList}
      />
      <ProtectedRoute exact path="/v2/content/games" component={GamesList} />
      <ProtectedRoute
        exact
        path="/v2/content/discover"
        component={DiscoverList}
      />
      <ProtectedRoute
        exact
        path="/v2/content/watch/category"
        component={WatchCategoryList}
      />
      <ProtectedRoute
        exact
        path="/v2/content/watch/content"
        component={WatchContentList}
      />
      <ProtectedRoute
        exact
        path="/v2/content/music/content"
        component={MusicContent}
      />
      <ProtectedRoute
        exact
        path="/v2/content/music/category"
        component={MusicCategory}
      />
      <ProtectedRoute exact path="/v2/campaignlist" component={CampaignsList} />
      <ProtectedRoute
        exact
        path="/v2/campaigns/add"
        component={AddEditCampaigns}
      />
      <ProtectedRoute
        exact
        path="/v2/campaigns/create-campaign"
        component={CreateCampaign}
      />
       <ProtectedRoute
        exact
        path="/v2/campaigns/active"
        component={ActiveCampaigns}
      />
      <ProtectedRoute
        exact
        path="/v2/campaigns/in-active"
        component={InActiveCampaigns}
      />
      <ProtectedRoute
        exact
        path="/v2/campaigns/edit/:id"
        component={AddEditCampaigns}
      />
      <ProtectedRoute
        exact
        path="/v2/campaigns/details/:id"
        component={NewViewCampaigns}
      />
      <ProtectedRoute exact path="/v2/campaigns/view-campaign/:id" component={ViewCampaign} />

      <ProtectedRoute
        exact
        path="/v2/unisoc-device-manager"
        component={UnisocReleaseManager}
      />
      <ProtectedRoute exact path="/v2/ridemap" component={RideMapView} />

      <ProtectedRoute exact path="/v2/report/devices" component={DeviceIssueReport} />

      {/* V2 Routes End */}
      <Route component={NotFound} />
    </Switch>
  );
}
