import React from 'react';
import { object } from 'prop-types';
import { Collapse } from 'antd';
import { CollapseBox } from './_device-location.styled';
import SingleDeviceMapBox from 'components/v2/single-device-mapbox';
import { convertUnixToDateFormat } from 'utils';

const { Panel } = Collapse;

const DeviceInfo = (props) => {
  const { deviceLocationDetails } = props;

  return (
    <CollapseBox>
      <Collapse defaultActiveKey={['1']}>
        <Panel
          header={`Device Location ${
            deviceLocationDetails?.updated_at
              ? `(Updated at: ${convertUnixToDateFormat(
                  deviceLocationDetails?.updated_at,
                  'Do MMMM YYYY hh:mm:ss A'
                )})`
              : ''
          }`}
          key="1"
        >
          <SingleDeviceMapBox location={deviceLocationDetails} />
        </Panel>
      </Collapse>
    </CollapseBox>
  );
};

DeviceInfo.propTypes = {
  deviceLocationDetails: object,
};

export default DeviceInfo;
