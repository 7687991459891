import { Breadcrumb, Button, Popconfirm, Spin, Table } from 'antd';
import React, { useEffect, useState } from 'react';
import { Loader, MusicCategoryStyle } from './MusicCategoryStyle.js';
import { URLS, URLS_V2 } from 'utils/urls.js';
import { httpDelete, httpGet } from 'utils/https.js';
import { DeleteOutlined, FormOutlined, LoadingOutlined } from '@ant-design/icons';
import moment from 'moment';
import { API_V2_ERROR_CODES, MODE_TYPES } from 'constants/common.js';
import { showErrorMsg, showSuccessMsg } from 'utils/notifications.js';
import MusicCategoryModal from './category modal/MusicCategoryModal.js';

const MusicCategory = () => {
  const [isFetchingList, setIsFetchingList] = useState(false);
  const [musicCategoryList, setMusicCategoryList] = useState([]);
  const [activeMode, setActiveMode] = useState();
  const [deletingRecordId, setDeletingRecordId] = useState('');
  const [recordId, setRecordId] = useState('');


useEffect(()=>{
    fetchMusicCategory()
},[])

const fetchMusicCategory=()=>{
  setIsFetchingList(true)

    const url = URLS_V2?.MUSIC_CATEGORY?.MUSIC_CATEGORY_LIST;
    httpGet(url)
    .then(res=>{

if(res?.status==200){
  setMusicCategoryList(res?.data)
}
setIsFetchingList(false)

    }).catch((err)=>{
      setIsFetchingList(false)

    })
}


const handleModeType = ({ activeMode, categoryId }) => {
  setRecordId(categoryId || '');
  setActiveMode(activeMode);
};

console.log(activeMode, "nhueohwe");

const closeActiveModeModal = () => {
  setActiveMode('');
};

const handleWatchCallback = () => {
  fetchMusicCategory()
  setRecordId('');
};

const deleteRecord = ({ id }) => {

  setDeletingRecordId(id);
  const url = URLS_V2?.MUSIC_CATEGORY?.DELETE_CATEGORY.replace('#ID#', id);
  httpDelete(url)
    .then((res) => {
      if (res.status === 200) {
        // updateFirebaseGlobalAppSettings(
        //   GLOBAL_APP_SETTINGS_KEYS.watch_category
        // );
        showSuccessMsg(`Category deleted successfully!`);
        fetchMusicCategory();
      } else if (res?.status && API_V2_ERROR_CODES.includes(res?.status)) {
        if (res?.status === 400) {
          showErrorMsg(
            'This record could not be deleted. Category is associated with child records.'
          );
        } else {
          showErrorMsg(res?.message);
        }
      }
      setDeletingRecordId('');
    })
    .catch((err) => {
      showErrorMsg(err);
      setDeletingRecordId('');
    });
};


const columns = [
  {
    title: MUSIC_CATEGORIES_LABEL.SR_NO,
    // width: '4%',
    align: 'center',
    render: (value, item, index) => index + 1,
  },
  {
    key: 'categoryImage',
    title: MUSIC_CATEGORIES_LABEL.IMAGE,
    dataIndex: 'categoryImage',
    // width: '10%',
    align: 'center',

    render: (image, item) => {
      return (function Actions() {
        return (
          <div >
            <img src={image} alt={item?.title} width="50px" />
          </div>
        );
      })();
    },
  },
  {
    key: 'title',
    title: MUSIC_CATEGORIES_LABEL.TITLE,
    dataIndex: 'title',
    // width: '15%',
    align: 'center',
  },
  {
    key: 'ar_title',
    title: MUSIC_CATEGORIES_LABEL.AR_TITLE,
    dataIndex: 'ar_title',
    // width: '15%',
    align: 'center',
  },
  {
    key: 'desc',
    title: MUSIC_CATEGORIES_LABEL.DESCRIPTION,
    dataIndex: 'desc',
    // width: '15%',
    align: 'center',
    render: (description) => {
      return (function Actions() {
        return <div style={{ wordBreak: 'break-all' }}>{description}</div>;
      })();
    },
  },
  {
    key: 'ar_desc',
    title: MUSIC_CATEGORIES_LABEL.AR_DESCRIPTION,
    dataIndex: 'ar_desc',
    // width: '15%',
    align: 'center',
    render: (ar_description) => {
      return (function Actions() {
        return <div style={{ wordBreak: 'break-all' }}>{ar_description}</div>;
      })();
    },
  },
  {
    key: 'created_at',
    title: MUSIC_CATEGORIES_LABEL.CREATED_AT,
    dataIndex: 'created_at',
    align: 'center',
    // width: '15%',
    render: (created_at) => {
      return (function Actions() {
        return (
          <div className="created_at">
            {created_at && moment(created_at).format('Do MMMM YYYY')}
          </div>
        );
      })();
    },
  },
  {
    key: '_id',
    title: MUSIC_CATEGORIES_LABEL.ACTIONS,
    dataIndex: '_id',
    // width: '10%',
    align: 'center',
    render: (id) => {
      return (function Actions() {
        return (
          <div className="actions-btn">
            <Button
              shape="circle"
              className="edit-btn"
              onClick={() => {
                handleModeType({
                  activeMode: MODE_TYPES.EDIT,
                  categoryId: id,
                });
              }}
            >
              <FormOutlined type="primary" className="edit-icon" />
            </Button>
            <Popconfirm
              title="Sure to delete?"
              onConfirm={() => deleteRecord({ id })}
              disabled={deletingRecordId === id}
            >
              <Button
                shape="circle"
                className="delete-btn"
                disabled={deletingRecordId === id}
              >
                {deletingRecordId === id ? (
                  <Spin indicator={<LoadingOutlined spin />} />
                ) : (
                  <DeleteOutlined type="primary" className="delete-icon" />
                )}
              </Button>
            </Popconfirm>
          </div>
        );
      })();
    },
  },
];



  return (
    <MusicCategoryStyle>
      <div className="header">
        <Breadcrumb>
          <Breadcrumb.Item>Content </Breadcrumb.Item>
          <Breadcrumb.Item>Music</Breadcrumb.Item>
          <Breadcrumb.Item>Category </Breadcrumb.Item>
        </Breadcrumb>
        <div className="claim-table-header">
          <h3 className="claim-table-title">Category</h3>
          <Button
            type="primary"
            onClick={() => {
              handleModeType({ activeMode: MODE_TYPES.ADD });
            }}
          >
            Add New
          </Button>
        </div>
      </div>
      <div className="main-table-body">
        <Table
          loading={{
            indicator: <Loader>Fetching...</Loader>,
            spinning: isFetchingList,
          }}
          columns={columns}
          dataSource={musicCategoryList || []}
          size="small"
          pagination={false}
          scroll={{ x: 700, y: 900 }}
          
        />
      </div>
      <MusicCategoryModal
        activeMode={activeMode}
        closeModal={closeActiveModeModal}
        callback={handleWatchCallback}
        categoryId={recordId}
      />
      </MusicCategoryStyle>
    
  );
};

export default MusicCategory;

const MUSIC_CATEGORIES_LABEL = {
  SR_NO: 'Sr. No.',
  IMAGE: 'Image',
  TITLE: 'Title (English)',
  AR_TITLE: 'Title (Arabic)',
  DESCRIPTION: 'Description (English)',
  AR_DESCRIPTION: 'Description (Arabic)',
  CREATED_AT: 'Created At',
  ACTIONS: 'Actions',
};
