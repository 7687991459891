import React, { useState, useEffect } from 'react';
import { Button, Breadcrumb, Table, Popconfirm, Spin } from 'antd';
import {
  httpDelete,
  httpGet,
  URLS,
  showErrorMsg,
  showSuccessMsg,
  updateFirebaseGlobalAppSettings,
} from 'utils';
import { ReadingBox, Loader } from './_watch-content.styled';
import {
  // FormOutlined,
  DeleteOutlined,
  EyeOutlined,
  LoadingOutlined,
} from '@ant-design/icons';
import moment from 'moment';
import WatchContentModal from 'components/v2/watch-content-modal';
import {
  API_V2_ERROR_CODES,
  GLOBAL_APP_SETTINGS_KEYS,
  MODE_TYPES,
} from 'constants/common';

const WatchContent = () => {
  const [isFetchingWatchList, setIsFetchingWatchList] = useState(false);
  const [deletingWatchId, setDeletingWatchId] = useState('');
  const [watchList, setWatchList] = useState([]);
  const [activeMode, setActiveMode] = useState();
  const [recordId, setRecordId] = useState();

  useEffect(() => {
    fetchWatchList();
  }, []);

  const fetchWatchList = () => {
    setIsFetchingWatchList(true);
    const url = URLS?.WATCH_CONTENT?.WATCH_CONTENTS_LIST;
    httpGet(url)
      .then((res) => {
        if (res?.status === 200) {
          let readingListData = [];
          if (res?.data && res?.data.length > 0) {
            readingListData = res?.data.map((data) => {
              return {
                ...data,
                key: data?._id,
              };
            });
          }
          setWatchList(readingListData || []);
        } else if (res?.status && API_V2_ERROR_CODES.includes(res?.status)) {
          showErrorMsg(res?.message);
        }
        setIsFetchingWatchList(false);
      })
      .catch(() => {
        setIsFetchingWatchList(false);
      });
  };

  const handleModeType = ({ activeMode, recordId }) => {
    setRecordId(recordId || '');
    setActiveMode(activeMode);
  };

  const closeActiveModeModal = () => {
    setActiveMode('');
  };

  const handleWatchCallback = () => {
    fetchWatchList();
    setRecordId('');
  };

  const deleteWatch = ({ id }) => {
    setDeletingWatchId(id);
    const url = URLS?.WATCH_CONTENT?.DELETE_WATCH_CONTENT.replace('#ID#', id);
    httpDelete(url)
      .then((res) => {
        if (res.status === 200) {
          updateFirebaseGlobalAppSettings(
            GLOBAL_APP_SETTINGS_KEYS.watch_content
          );
          showSuccessMsg('Content deleted successfully!');
          fetchWatchList();
        } else if (res?.status && API_V2_ERROR_CODES.includes(res?.status)) {
          showErrorMsg(res?.message);
        }
        setDeletingWatchId('');
      })
      .catch((err) => {
        showErrorMsg(err);
        setDeletingWatchId('');
      });
  };

  const columns = [
    {
      title: CONTENT_LABELS.SR_NO,
      width: '4%',
      render: (value, item, index) => index + 1,
    },
    {
      key: 'title',
      title: CONTENT_LABELS.TITLE,
      dataIndex: 'title',
      width: '15%',
    },
    {
      key: 'category',
      title: CONTENT_LABELS.CATEGORY,
      dataIndex: 'category',
      width: '15%',
      render: (category) => {
        return (function Actions() {
          return <div>{category && category.title}</div>;
        })();
      },
    },
    {
      key: 'lang',
      title: CONTENT_LABELS.LANGUAGE,
      dataIndex: 'lang',
      width: '15%',
    },
    {
      key: 'created_at',
      title: CONTENT_LABELS.CREATED_AT,
      dataIndex: 'created_at',
      width: '15%',
      render: (created_at) => {
        return (function Actions() {
          return (
            <div className="created_at">
              {created_at && moment(created_at).format('Do MMMM YYYY')}
            </div>
          );
        })();
      },
    },
    {
      key: '_id',
      title: CONTENT_LABELS.ACTIONS,
      dataIndex: '_id',
      width: '10%',
      align: 'center',
      render: (id) => {
        return (function Actions() {
          return (
            <div className="actions-btn">
              <Button
                shape="circle"
                className="edit-btn"
                onClick={() => {
                  handleModeType({
                    activeMode: MODE_TYPES.VIEW,
                    recordId: id,
                  });
                }}
              >
                <EyeOutlined type="primary" className="preview-icon" />
              </Button>
              {/* <Button
                shape="circle"
                className="edit-btn"
                onClick={() => {
                  handleModeType({
                    activeMode: MODE_TYPES.EDIT,
                    recordId: id,
                  });
                }}
              >
                <FormOutlined type="primary" className="edit-icon" />
              </Button> */}
              <Popconfirm
                title="Sure to delete?"
                onConfirm={() => deleteWatch({ id })}
                disabled={deletingWatchId === id}
              >
                <Button
                  shape="circle"
                  className="delete-btn"
                  disabled={deletingWatchId === id}
                >
                  {deletingWatchId === id ? (
                    <Spin indicator={<LoadingOutlined spin />} />
                  ) : (
                    <DeleteOutlined type="primary" className="delete-icon" />
                  )}
                </Button>
              </Popconfirm>
            </div>
          );
        })();
      },
    },
  ];

  return (
    <ReadingBox>
      <div className="header">
        <Breadcrumb>
          <Breadcrumb.Item>Content </Breadcrumb.Item>
          <Breadcrumb.Item>Watch </Breadcrumb.Item>
          <Breadcrumb.Item>Content </Breadcrumb.Item>
        </Breadcrumb>
        <div className="claim-table-header">
          <h3 className="claim-table-title"> Content</h3>
          <Button
            type="primary"
            onClick={() => {
              handleModeType({ activeMode: MODE_TYPES.ADD });
            }}
          >
            Add New
          </Button>
        </div>
      </div>
      <div className="main-table-body">
        <Table
          loading={{
            indicator: <Loader>Fetching...</Loader>,
            spinning: isFetchingWatchList,
          }}
          columns={columns}
          dataSource={watchList || []}
          size="small"
          pagination={false}
        />
      </div>
      <WatchContentModal
        activeMode={activeMode}
        closeModal={closeActiveModeModal}
        callback={handleWatchCallback}
        contentId={recordId}
      />
    </ReadingBox>
  );
};

export default WatchContent;

const CONTENT_LABELS = {
  SR_NO: 'Sr. No.',
  TITLE: 'Title',
  CATEGORY: 'Category',
  LANGUAGE: 'Language',
  CREATED_AT: 'Created At',
  ACTIONS: 'Actions',
};
