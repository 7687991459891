import React, { useState, useEffect } from 'react';
import {
  Table,
  Button,
  /* Form, Row, Col, Input, */
  Popconfirm,
  Switch,
} from 'antd';
import CommonBreadCrumb from 'components/common-breadcrumb';
import {
  currentPage,
  httpDelete,
  httpGet,
  httpPut,
  showErrorMsg,
  showSuccessMsg,
  URLS_V2,
} from 'utils';
import { DeleteOutlined, FormOutlined, InfoCircleOutlined, PlusOutlined } from '@ant-design/icons';
// import { debounce } from 'lodash';
import AddEditUserRoleModal from './add-edit-user-role';
import { MODE_TYPES } from 'constants/common';
import AccessModal from './access-modal/AccessModal';

const data = [
  {
    _id: "Dyk21kUjmsdssad1Sw398YGvsdkwU82",
    title: "Admin",
    role_type: "Super Admin",
    is_active: true,
    access_modules: [
      {
        module_name: "Devices",
        read: true,
        write: false
      },
      {
        module_name: "Campaigns",
        read: true,
        write: true
      },
      {
        module_name: "Content",
        read: true,
        write: false
      },
      {
        module_name: "Rides",
        read: true,
        write: false
      },
      {
        module_name: "User Management",
        read: false,
        write: false
      }
    ]
  },
  {
    _id: "Dyk21kUjmsdseWs98YGvsdkwU82",
    title: "Admin2",
    role_type: " User",
    is_active: false,
  },
]

const UserRoles = () => {
  const breakCrumbList = [
    { label: 'Home', link: '/dashboard' },
    { label: 'User Roles' },
  ];
  const [userRoleList, setUserRoleList] = useState([]);
  const [totalRecords, setTotalRecords] = useState(0);
  const [activeMode, setActiveMode] = useState('');
  const [isFetching, setIsFetching] = useState(false);
  const [modalVisible, setModalVisible] = useState(false);
  const [accessModal, setAccessModal] = useState(false);
  const [accessModalData, setAccessModalData] = useState({});

  const [recordId, setRecordId] = useState(undefined);
  const [orderPagination, setOrderPagination] = useState({
    skip: 0,
    limit: 100,
    order: 1,
    orderBy: 'title',
  });
  const [searchParam] = useState({
    title: '',
    role_type: '',
  });

  useEffect(() => {
    fetchUserRoleList();
  }, [searchParam]);

  const fetchUserRoleList = (orderPaginationParams = orderPagination) => {
    setIsFetching(true);

    const queryParams = new URLSearchParams();
    queryParams.append('skip', orderPaginationParams.skip);
    queryParams.append('limit', orderPaginationParams.limit);
    queryParams.append('order', orderPaginationParams.order);
    queryParams.append('order_by', orderPaginationParams.orderBy);

    if (searchParam && Object.keys(searchParam).length >= 1) {
      Object.keys(searchParam).forEach((key) => {
        queryParams.append(key, searchParam[key]);
      });
    }

    const url = `${URLS_V2?.ROLES?.ROLES_LIST}?${queryParams.toString()}`;
    httpGet(url)
      .then((res) => {
        if (res?.status === 200) {
          let userRoleListData = [];
          if (res?.data && res?.data.length > 0) {
            userRoleListData = res?.data.map((data) => {
              return {
                ...data,
                key: data?._id,
              };
            });
          }
          setUserRoleList(userRoleListData || []);
          setTotalRecords(res?.total || 0);
        }
        setIsFetching(false);
      })
      .catch((err) => {
        setIsFetching(false);
      });
  };

  const columns = [
    {
      title: ENROLL_LABELS.SR_NO,
      width: 80,
      render: (value, item, index) =>
        (currentPage(orderPagination.skip, orderPagination.limit) - 1) *
        orderPagination.limit +
        index +
        1,
    },
    {
      key: 'title',
      title: ENROLL_LABELS.ROLE_TITLE,
      dataIndex: 'title',
      // sorter: true,
      defaultSortOrder: 'ascend',
    },
    {
      key: 'role_type',
      title: ENROLL_LABELS.ROLE_TYPE,
      dataIndex: 'role_type',
      // sorter: true,
    },
    {
      key: 'is_active',
      title: ENROLL_LABELS.ACTIVE,
      dataIndex: 'is_active',
      render: (is_active, item) => {
        return (function Actions() {
          return (
            <div>
              <Popconfirm
                title={
                  <>{`Are you sure you want to update status to ${is_active ? 'Inactive' : 'Active'
                    }?`}</>
                }
                onConfirm={() => onChangeActiveInactive(item._id, !is_active)}
              >
                <Switch
                  checked={is_active}
                  className={`${is_active ? 'bg-success' : 'bg-danger'}`}
                  checkedChildren="Active"
                  unCheckedChildren="Inactive"
                />
              </Popconfirm>
            </div>
          );
        })();
      },
    },
    {
      key: '_id',
      title: ENROLL_LABELS.ACCESS,
      dataIndex: '_id',
      width: 150,
      render: (itemId) => {
        return (function Actions() {
          return (
            <div className="actions-btn">
              <Button
                shape="circle"
                className="edit-btn"
                onClick={() => handleAccessModalOpen(itemId)}
              >
                <InfoCircleOutlined type="primary" className="edit-icon" />
              </Button>
            </div>
          );
        })();
      },
    },
    {
      key: '_id',
      title: ENROLL_LABELS.ACTIONS,
      dataIndex: '_id',
      width: 150,
      render: (itemId) => {
        return (function Actions() {
          return (
            <div className="actions-btn">
              <Button
                shape="circle"
                className="edit-btn"
                onClick={() => handleUpdateButtonAction(itemId)}
              >
                <FormOutlined type="primary" className="edit-icon" />
              </Button>
              <Popconfirm
                title={<>{`Are you sure you want to delete this record?`}</>}
                onConfirm={() => deleteItem(itemId)}
              >
                <Button
                  shape="circle"
                  className="delete-btn"
                  style={{ marginLeft: '0.5rem' }}
                >
                  <DeleteOutlined type="primary" className="delete-icon" />
                </Button>
              </Popconfirm>
            </div>
          );
        })();
      },
    },
  ];


  const handleAccessModalOpen = (id) => {
    const filtered = data.filter((item) => item?._id === id);
    setAccessModalData(filtered?.[0]);
    setAccessModal(true);
  }

  const onChangeActiveInactive = (selectedId, value) => {
    setIsFetching(true);
    const data = {
      id: selectedId,
      is_active: value,
    };
    updateRolesStatus(data);
  };

  const updateRolesStatus = (data) => {
    const url = URLS_V2?.ROLES?.UPDATE_ROLE_STATUS;
    httpPut(url, data)
      .then((res) => {
        if (res?.status === 200) {
          showSuccessMsg('Role updated successfully!');
          fetchUserRoleList(orderPagination);
        } else {
          showErrorMsg(res?.error);
        }
        setIsFetching(false);
      })
      .catch((err) => {
        setIsFetching(false);
      });
  };

  const deleteItem = (selectedId) => {
    const queryParams = new URLSearchParams();
    queryParams.append('id', selectedId);
    queryParams.append('is_deleted', true);
    const url = `${URLS_V2?.ROLES?.DELETE_ROLE}/?${queryParams.toString()}`;
    httpDelete(url)
      .then((res) => {
        if (res?.status === 200) {
          showSuccessMsg('Role deleted successfully!');
          fetchUserRoleList(orderPagination);
        } else {
          showErrorMsg(res?.error);
        }
        setIsFetching(false);
      })
      .catch((err) => {
        setIsFetching(false);
      });
  };

  // const handleTextSearch = (e) => {
  //   setSearchParam({
  //     ...searchParam,
  //     [e.target.name]: e.target.value,
  //   });
  // };

  const handlePaginationChange = (pagination, filters, sorter) => {
    const { current, pageSize } = pagination;
    const tempSearchPagination = {
      ...orderPagination,
      skip: current * pageSize - pageSize,
      limit: pageSize,
      order: sorter.order === 'descend' ? -1 : 1,
      orderBy: sorter.field,
    };
    setOrderPagination(tempSearchPagination);
    fetchUserRoleList(tempSearchPagination);
  };

  const defaultTitle = () => {
    return (
      <div className="cus-table-right-btn">
        {`Showing Records: ${userRoleList.length} / Total Records: ${totalRecords}`}
      </div>
    );
  };

  const handleCreateButtonAction = () => {
    setActiveMode(MODE_TYPES.ADD);
    setModalVisible(true);
  };

  const handleUpdateButtonAction = (itemId) => {
    setActiveMode(MODE_TYPES.UPDATE);
    setRecordId(itemId);
    setModalVisible(true);
  };

  const handleCloseModal = () => {
    setActiveMode('');
    setRecordId(undefined);
    setModalVisible(false);
  };

  const handleSubmitSuccessModal = () => {
    handleCloseModal();
    fetchUserRoleList();
  };

  return (
    <>
      <CommonBreadCrumb breadCrumbs={breakCrumbList} />
      <div className="while-bg-box cus-table-header">
        <h3 className="table-title">User Role Management</h3>
        <div className="cus-table-right-btn">
          <div className="bulk-action-btn">
            <Button type="primary" onClick={handleCreateButtonAction}>
              <PlusOutlined />
              Create New User Role
            </Button>
          </div>
        </div>
      </div>
      {/* <div className="while-bg-box">
        <Form className="main-filter-form">
          <Row gutter={[16, 16]}>
            <Col md={8}>
              <Form.Item name="role_name" style={{ marginBottom: '0' }}>
                <Input
                  type="text"
                  name="role_name"
                  onChange={debounce((event) => handleTextSearch(event))}
                  placeholder="Search Role"
                />
              </Form.Item>
            </Col>
            <Col md={8}>
              <Form.Item name="role_type" style={{ marginBottom: '0' }}>
                <Input
                  type="text"
                  name="role_type"
                  onChange={debounce((event) => handleTextSearch(event))}
                  placeholder="Search Role Type"
                />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </div> */}

      <div className="main-table-body-cus">
        <Table
          title={defaultTitle}
          loading={{
            indicator: <div className="table-loader">Fetching...</div>,
            spinning: isFetching,
          }}
          columns={columns}
          dataSource={data || []}
          size="small"
          onChange={handlePaginationChange}
          pagination={{
            defaultPageSize: 100,
            total: totalRecords,
          }}
          scroll={{ x: 500 }}
        />
      </div>

      {modalVisible && (
        <AddEditUserRoleModal
          activeMode={activeMode}
          handleCloseModal={handleCloseModal}
          handleSubmitSuccessModal={handleSubmitSuccessModal}
          modalVisible={modalVisible}
          recordId={recordId}
          sectionName="Role"
        />
      )}

      {accessModal && (
        <AccessModal
          accessModal={accessModal}
          setAccessModal={setAccessModal}
          data={accessModalData}
        />
      )}
    </>
  );
};

export default UserRoles;

const ENROLL_LABELS = {
  SR_NO: 'Sr. No.',
  ROLE_TITLE: 'Title',
  ROLE_TYPE: 'Role Type',
  ACCESS: "Access",
  ACTIVE: 'Status',
  ACTIONS: 'Actions',
};
