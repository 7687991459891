import React, { useState, useEffect, useRef } from 'react';
import { firebaseDb } from 'configs';
import DeviceMap from 'components/device-map';
import { DevicesMapBox } from './_devices-map.styled';

const DevicesMap = () => {
  const [deviceStatusList, setDeviceStatusList] = useState([]);
  const initialTimeStamp = useRef(Date.now());
  const isDataLoadedOnce = useRef(false);

  const mapTimeDelayedInSecs = 10;

  useEffect(() => {
    fetchDeviceStatusList();
  }, []);

  const fetchDeviceStatusList = () => {
    const ref = firebaseDb.ref('device_status');
    ref.on('value', (response) => {
      const deviceStatusListData = response.val();
      const tempList = [];
      for (let data in deviceStatusListData) {
        const deviceStatusStructure = {
          batteryPercent: deviceStatusListData[data].battery_percent || '',
          binaryCode: deviceStatusListData[data].binary_code || '',
          deviceSerial: deviceStatusListData[data].device_serial || '',
          position: {
            lat: deviceStatusListData[data].current_lat || '',
            lng: deviceStatusListData[data].current_lng || '',
          },
          isCharging:
            deviceStatusListData[data].is_charging == true
              ? 'Charging'
              : 'Not Charging',
          screenStatus:
            deviceStatusListData[data].is_screen_on == true ? 'On' : 'Off',
          wifiName: deviceStatusListData[data].required_wifi_name
            ? deviceStatusListData[data].required_wifi_name
            : '',
          updatedAt: deviceStatusListData[data].updated_at,
        };
        tempList.push(deviceStatusStructure);
      }
      let currentTimeStamp = Date.now();
      if (
        (currentTimeStamp - initialTimeStamp.current) / 1000 >=
          mapTimeDelayedInSecs ||
        !isDataLoadedOnce.current
      ) {
        initialTimeStamp.current = Date.now();
        isDataLoadedOnce.current = true;
        setDeviceStatusList(tempList);
      }
    });
  };

  return (
    <DevicesMapBox>
      <div className="table-header">
        <h3 className="table-title">Device Map View</h3>
      </div>
      <div className="map-box">
        {deviceStatusList && deviceStatusList.length > 0 ? (
          <DeviceMap locations={deviceStatusList} />
        ) : (
          <DeviceMap locations={[]} />
        )}
      </div>
    </DevicesMapBox>
  );
};

export default DevicesMap;
