import React, { useState } from 'react'
import { Form, Modal, } from 'antd';
import CheckboxComponent from '../checkbox-component/CheckboxComponent';

const AccessModal = ({ accessModal, setAccessModal, data }) => {
    const [ accessModule,setAccessModule ]=useState([]);

    const handleOk=()=>{
        setAccessModal(false);
    }
    return (
        <>
            <Modal
                title="Access Modal"
                open={accessModal}
                onCancel={() => setAccessModal(false)}
                onOk={handleOk}
            >
                <Form>
                    {
                        data?.access_modules?.map((item) => (
                            <CheckboxComponent key={item?.module_name}  data={item} setAccessModule={setAccessModule} />
                        ))
                    }
                </Form>
            </Modal>
        </>
    )
}

export default AccessModal;