import React, { useState, useEffect } from 'react';
import { httpGet, httpPut, URLS, showSuccessMsg, showErrorMsg } from 'utils';
import { Form, Row, Col, Input, Button } from 'antd';
import { AdInventoryConfigBox } from './_ad-inventory-config.styled';

const AdInventoryConfig = () => {
  const [validateFieldsName, setValidateFieldsName] = useState([]);
  const [isFetching, setIsFetching] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [form] = Form.useForm();

  useEffect(() => {
    fetchAdInventoryConfig();
  }, []);

  const fetchAdInventoryConfig = () => {
    setIsFetching(true);
    const url = URLS?.AD_INVENTORY_CONFIG?.GET_AD_INVENTORY_CONFIG;
    httpGet(url)
      .then((res) => {
        if (res?.status === 200) {
          if (
            res?.data &&
            Object.entries(res?.data) &&
            Object.entries(res?.data).length > 0
          ) {
            Object.entries(res?.data).forEach(([key, value]) => {
              form.setFieldsValue({
                [key]: value,
              });
            });
            calculateInventoryConfig();
          }
        }
        setIsFetching(false);
      })
      .catch(() => {
        setIsFetching(false);
      });
  };

  const handleValidateFieldNames = (name) => {
    const isFieldName = validateFieldsName.find(
      (fieldName) => fieldName === name
    );
    if (isFieldName) return 'onChange';
    return 'onBlur';
  };

  const submitForm = () => {
    const summation =
      parseFloat(form.getFieldValue('mass_campaign_inventory')) +
      parseFloat(form.getFieldValue('geofence_campaign_inventory'));
    if (summation > 100) {
      showErrorMsg(
        'Sum of Mass Campaign inventory & Geofence Campaign inventory cannot exceed 100'
      );
    } else {
      form.submit();
      form
        .validateFields()
        .then(async (values) => {
          handleEditAdInventoryConfig({ values });
        })
        .catch((errorInfo) => errorInfo);
    }
  };

  const handleEditAdInventoryConfig = ({ values }) => {
    let adInventoryConfigParams = {
      no_of_cars: values?.no_of_cars || 0,
      no_of_rides_per_car_per_day: values?.no_of_rides_per_car_per_day || 0,
      per_ride_per_car_seconds: values?.per_ride_per_car_seconds || 0,
      ad_percentage: values?.ad_percentage || 0,
      geofence_campaign_inventory: values?.geofence_campaign_inventory || 0,
      mass_campaign_inventory: values?.mass_campaign_inventory || 0,
    };
    setIsSaving(true);
    const url = URLS?.AD_INVENTORY_CONFIG?.UPDATE_AD_INVENTORY_CONFIG;
    httpPut(url, adInventoryConfigParams)
      .then((res) => {
        if (res.status === 200) {
          showSuccessMsg('Data saved successfully!');
        } else if (res?.error) {
          showErrorMsg(res?.error);
        }
        setIsSaving(false);
      })
      .catch(() => {
        setIsSaving(false);
      });
  };

  const calculateInventoryConfig = () => {
    const noOfCars = form && form.getFieldValue('no_of_cars');
    const massCampaignInventoryPercentage =
      form && form.getFieldValue('mass_campaign_inventory');
    const geofenceCampaignInventoryPercentage =
      form && form.getFieldValue('geofence_campaign_inventory');
    const noOfRidesPerCarPerDay =
      form && form.getFieldValue('no_of_rides_per_car_per_day');
    const perRidePerCarSeconds =
      form && form.getFieldValue('per_ride_per_car_seconds');
    const adPercentage = form && form.getFieldValue('ad_percentage');
    const totalDailyInRideMinutes =
      noOfCars * noOfRidesPerCarPerDay * perRidePerCarSeconds;
    form.setFieldsValue({
      total_daily_in_ride_minutes: totalDailyInRideMinutes,
    });
    const totalDailyAdMinutes = (totalDailyInRideMinutes * adPercentage) / 100;
    form.setFieldsValue({
      total_daily_ad_minutes: totalDailyAdMinutes,
    });
    const totalDailyAdSeconds = totalDailyAdMinutes * 60;
    form.setFieldsValue({
      total_daily_ad_seconds: totalDailyAdSeconds,
    });
    const totalMonthlyAdSeconds = totalDailyAdSeconds * 30;
    form.setFieldsValue({
      total_monthly_ad_seconds: totalMonthlyAdSeconds,
    });
    const totalDailyMassCampaignAdSeconds =
      totalDailyAdSeconds * (massCampaignInventoryPercentage / 100);
    form.setFieldsValue({
      total_daily_mass_campaign_ad_seconds: totalDailyMassCampaignAdSeconds,
    });
    const totalDailyGeofenceCampaignAdSeconds =
      totalDailyAdSeconds * (geofenceCampaignInventoryPercentage / 100);
    form.setFieldsValue({
      total_daily_geofence_campaign_ad_seconds:
        totalDailyGeofenceCampaignAdSeconds,
    });
  };

  return (
    <AdInventoryConfigBox>
      <div className="table-header">
        <h3 className="table-title">Ad Inventory Config</h3>
        <div className="table-right-side"></div>
      </div>
      <div className="main-table-body">
        {isFetching ? (
          'Fetching...'
        ) : (
          <Form form={form} layout="vertical">
            <Row gutter={16}>
              <Col className="gutter-row" xs={24} sm={6}>
                <Form.Item
                  name="no_of_cars"
                  label="Number of cars"
                  validateTrigger={handleValidateFieldNames('no_of_cars')}
                  rules={[
                    {
                      required: true,
                      message: 'Please enter Number of cars!',
                    },
                  ]}
                >
                  <Input
                    type="number"
                    placeholder="Number of cars"
                    onBlur={() =>
                      setValidateFieldsName([
                        ...validateFieldsName,
                        'no_of_cars',
                      ])
                    }
                    onChange={calculateInventoryConfig}
                  />
                </Form.Item>
              </Col>
              <Col className="gutter-row" xs={24} sm={6}>
                <Form.Item
                  name="no_of_rides_per_car_per_day"
                  label="Number of rides per car per day"
                  validateTrigger={handleValidateFieldNames(
                    'no_of_rides_per_car_per_day'
                  )}
                  rules={[
                    {
                      required: true,
                      message: 'Please enter Number of rides per car per day!',
                    },
                  ]}
                >
                  <Input
                    type="number"
                    placeholder="Number of rides per car per day"
                    onBlur={() =>
                      setValidateFieldsName([
                        ...validateFieldsName,
                        'no_of_rides_per_car_per_day',
                      ])
                    }
                    onChange={calculateInventoryConfig}
                  />
                </Form.Item>
              </Col>
              <Col className="gutter-row" xs={24} sm={6}>
                <Form.Item
                  name="per_ride_per_car_seconds"
                  label="Number of minutes per ride"
                  validateTrigger={handleValidateFieldNames(
                    'per_ride_per_car_seconds'
                  )}
                  rules={[
                    {
                      required: true,
                      message: 'Please enter Number of minutes per ride!',
                    },
                  ]}
                >
                  <Input
                    type="number"
                    placeholder="Number of minutes per ride"
                    onBlur={() =>
                      setValidateFieldsName([
                        ...validateFieldsName,
                        'per_ride_per_car_seconds',
                      ])
                    }
                    onChange={calculateInventoryConfig}
                  />
                </Form.Item>
              </Col>
              <Col className="gutter-row" xs={24} sm={6}>
                <Form.Item
                  name="ad_percentage"
                  label="Ad %"
                  validateTrigger={handleValidateFieldNames('ad_percentage')}
                  rules={[
                    {
                      required: true,
                      message: 'Please enter Ad %!',
                    },
                  ]}
                >
                  <Input
                    type="number"
                    placeholder="Ad %"
                    onBlur={() =>
                      setValidateFieldsName([
                        ...validateFieldsName,
                        'ad_percentage',
                      ])
                    }
                    onChange={calculateInventoryConfig}
                  />
                </Form.Item>
              </Col>
              <Col className="gutter-row" xs={24} sm={6}>
                <Form.Item
                  name="mass_campaign_inventory"
                  label="Mass Campaign inventory %"
                  validateTrigger={handleValidateFieldNames(
                    'mass_campaign_inventory'
                  )}
                  rules={[
                    {
                      required: true,
                      message: 'Please enter Mass Campaign inventory %!',
                    },
                  ]}
                >
                  <Input
                    type="number"
                    placeholder="Mass Campaign inventory %"
                    onBlur={() =>
                      setValidateFieldsName([
                        ...validateFieldsName,
                        'mass_campaign_inventory',
                      ])
                    }
                    onChange={calculateInventoryConfig}
                  />
                </Form.Item>
              </Col>
              <Col className="gutter-row" xs={24} sm={6}>
                <Form.Item
                  name="geofence_campaign_inventory"
                  label="Geofence Campaign inventory %"
                  validateTrigger={handleValidateFieldNames(
                    'geofence_campaign_inventory'
                  )}
                  rules={[
                    {
                      required: true,
                      message: 'Please enter Geofence Campaign inventory %!',
                    },
                  ]}
                >
                  <Input
                    type="number"
                    placeholder="Geofence Campaign inventory %"
                    onBlur={() =>
                      setValidateFieldsName([
                        ...validateFieldsName,
                        'geofence_campaign_inventory',
                      ])
                    }
                    onChange={calculateInventoryConfig}
                  />
                </Form.Item>
              </Col>
            </Row>

            <Row gutter={16}>
              <Col className="gutter-row" xs={24} sm={6}>
                <Form.Item
                  label="Total Daily In-Ride Minutes"
                  name="total_daily_in_ride_minutes"
                >
                  <Input
                    type="number"
                    placeholder="Total Daily In-Ride Minutes"
                    disabled={true}
                  />
                </Form.Item>
              </Col>
              <Col className="gutter-row" xs={24} sm={6}>
                <Form.Item
                  label="Total Daily Ad Minutes"
                  name="total_daily_ad_minutes"
                >
                  <Input
                    type="number"
                    placeholder="Total Daily Ad Minutes"
                    disabled={true}
                  />
                </Form.Item>
              </Col>
              <Col className="gutter-row" xs={24} sm={6}>
                <Form.Item
                  label="Total Daily Ad Seconds"
                  name="total_daily_ad_seconds"
                >
                  <Input
                    type="number"
                    placeholder="Total Daily Ad Seconds"
                    disabled={true}
                  />
                </Form.Item>
              </Col>
              <Col className="gutter-row" xs={24} sm={6}>
                <Form.Item
                  label="Total Monthly Ad Seconds"
                  name="total_monthly_ad_seconds"
                >
                  <Input
                    type="number"
                    placeholder="Total Monthly Ad Seconds"
                    disabled={true}
                  />
                </Form.Item>
              </Col>
              <Col className="gutter-row" xs={24} sm={6}>
                <Form.Item
                  label="Total Daily Mass Campaign Ad Seconds"
                  name="total_daily_mass_campaign_ad_seconds"
                >
                  <Input
                    type="number"
                    placeholder="Total Daily Mass Campaign Ad Seconds"
                    disabled={true}
                  />
                </Form.Item>
              </Col>
              <Col className="gutter-row" xs={24} sm={6}>
                <Form.Item
                  label="Total Daily Geofence Campaign Ad Seconds"
                  name="total_daily_geofence_campaign_ad_seconds"
                >
                  <Input
                    type="number"
                    placeholder="Total Daily Geofence Campaign Ad Seconds"
                    disabled={true}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Button
              type="primary"
              className="add-btn"
              onClick={submitForm}
              disabled={isSaving}
            >
              {isSaving ? 'Saving...' : 'Save'}
            </Button>
          </Form>
        )}
      </div>
    </AdInventoryConfigBox>
  );
};

export default AdInventoryConfig;
