export const CAMPAIGN_TYPES = {
  BRAND_AWARENESS: { value: 'brand_awareness', label: 'Brand Awareness' },
  WEBSITE_VISITS: { value: 'website_visits', label: 'Website Visits' },
  CAPTURE_LEADS: { value: 'capture_leads', label: 'Capture Leads' },
  GROUP_ADS: { value: 'game_visits', label: 'Group Ads' },
};

export const CAMPAIGN_TYPES_LIST = [
  CAMPAIGN_TYPES.BRAND_AWARENESS,
  CAMPAIGN_TYPES.WEBSITE_VISITS,
  // CAMPAIGN_TYPES.CAPTURE_LEADS,
  // CAMPAIGN_TYPES.GROUP_ADS,
];
export const CAMPAIGN_CLASSES = {
  MASS_CAMPAIGN: 'Mass Campaign',
  GEOFENCE_CAMPAIGN: 'Geofence Campaign',
};

export const CAMPAIGN_CLASSES_LIST = [
  CAMPAIGN_CLASSES.MASS_CAMPAIGN,
  CAMPAIGN_CLASSES.GEOFENCE_CAMPAIGN,
];
export const INDUSTRY_TYPES = {
  TECHNOLOGY: 'Technology',
  HEALTHCARE: 'Healthcare',
  CONSTRUCTION: 'Construction',
  FMCG: 'FMCG',
  TELECOM: 'Telecom',
  MEDICAL: 'Medical',
  BANKING_FINANCIAL: 'Banking/Financial',
  ENTERTAINMENT: 'Entertainment',
  ELECTRONICS: 'Electronics',
  CARS: 'Cars',
  HOSPITALITY: 'Hospitality',
  WATCHES: 'Watches',
  FASTFOOD: 'Fastfood',
  RESTAURANT: 'Restaurant',
  HOTELS: 'Hotels',
  PARKS: 'Parks',
  GOVERNMENT: 'Government',
  PUBLIC_ANNOUNCEMENT: 'Public_Announcement',
  TOURISM: 'Tourism',
  EVENTS: 'Events',
  TECH_COMPANY: 'Tech Company',
  SERVICE_COMPANY: 'Service Company',
  MALLS: 'Malls',
  REAL_ESTATE: 'Real Estate',
  JEWELERY: 'Jewelery',
  FURNITURE: 'Furniture',
  APPAREL_ACCESSORIES: 'Apparel & Accessories',
  KIDS_PRODUCTS: 'Kids Products',
  BEAUTY_CENTERS: 'Beauty Products',
  EDUCATION: 'Education',
  HYGIENE_PRODUCTS: 'Hygiene Products',
  WHITE_GOODS: 'White Goods',
  PERFUME: 'Perfume',
  OTHERS: 'Others',
};

export const INDUSTRY_TYPES_LIST = [
  INDUSTRY_TYPES.TECHNOLOGY,
  INDUSTRY_TYPES.HEALTHCARE,
  INDUSTRY_TYPES.CONSTRUCTION,
  INDUSTRY_TYPES.FMCG,
  INDUSTRY_TYPES.TELECOM,
  INDUSTRY_TYPES.MEDICAL,
  INDUSTRY_TYPES.BANKING_FINANCIAL,
  INDUSTRY_TYPES.ENTERTAINMENT,
  INDUSTRY_TYPES.ELECTRONICS,
  INDUSTRY_TYPES.ELECTRONICS,
  INDUSTRY_TYPES.CARS,
  INDUSTRY_TYPES.HOSPITALITY,
  INDUSTRY_TYPES.WATCHES,
  INDUSTRY_TYPES.FASTFOOD,
  INDUSTRY_TYPES.RESTAURANT,
  INDUSTRY_TYPES.HOTELS,
  INDUSTRY_TYPES.PARKS,
  INDUSTRY_TYPES.GOVERNMENT,
  INDUSTRY_TYPES.PUBLIC_ANNOUNCEMENT,
  INDUSTRY_TYPES.TOURISM,
  INDUSTRY_TYPES.EVENTS,
  INDUSTRY_TYPES.TECH_COMPANY,
  INDUSTRY_TYPES.SERVICE_COMPANY,
  INDUSTRY_TYPES.MALLS,
  INDUSTRY_TYPES.REAL_ESTATE,
  INDUSTRY_TYPES.JEWELERY,
  INDUSTRY_TYPES.FURNITURE,
  INDUSTRY_TYPES.APPAREL_ACCESSORIES,
  INDUSTRY_TYPES.KIDS_PRODUCTS,
  INDUSTRY_TYPES.BEAUTY_CENTERS,
  INDUSTRY_TYPES.EDUCATION,
  INDUSTRY_TYPES.HYGIENE_PRODUCTS,
  INDUSTRY_TYPES.WHITE_GOODS,
  INDUSTRY_TYPES.PERFUME,
  INDUSTRY_TYPES.OTHERS,
];

export const GENDER = {
  MALE: 'Male',
  FEMALE: 'Female',
  OTHER: 'Other',
};

export const GENDER_LIST = [GENDER.MALE, GENDER.FEMALE, GENDER.OTHER];

export const AGE = {
  A: '12-18',
  B: '19-28',
  C: '29-45',
  D: '46-65',
  E: '66+',
};

export const AGE_LIST = [AGE.A, AGE.B, AGE.C, AGE.D, AGE.E];

export const LANGUAGES = {
  ENGLISH: 'English',
  ARABIC: 'Arabic',
};

export const LANGUAGES_LIST = [LANGUAGES.ENGLISH, LANGUAGES.ARABIC];

export const DAYS = {
  MONDAY: 'Monday',
  TUESDAY: 'Tuesday',
  WEDNESDAY: 'Wednesday',
  THURSDAY: 'Thursday',
  FRIDAY: 'Friday',
  SATURDAY: 'Saturday',
  SUNDAY: 'Sunday',
};

export const DAYS_LIST = [
  DAYS.MONDAY,
  DAYS.TUESDAY,
  DAYS.WEDNESDAY,
  DAYS.THURSDAY,
  DAYS.FRIDAY,
  DAYS.SATURDAY,
  DAYS.SUNDAY,
];

export const MODE_OF_COMMUNICATION = {
  EMAIL: 'Email',
  MOBILE: 'Mobile',
};

export const MODE_OF_COMMUNICATION_LIST = [
  MODE_OF_COMMUNICATION.EMAIL,
  MODE_OF_COMMUNICATION.MOBILE,
];

export const MEDIA_TYPES = {
  IMAGE: 'Image',
  VIDEO: 'Video',
  VIDEO_IMAGE: 'VideoImage',
  AUDIO:'Audio'
};
