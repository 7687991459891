import React from 'react';
// import moment from 'moment';
import { oneOfType, func, array, bool, string } from 'prop-types';
import {
  Row,
  Col,
  Collapse,
  Form,
  Space,
  DatePicker,
  Input,
  // Checkbox,
} from 'antd';
import { CollapseBox } from './_alloted-spots.styled';
import { CAMPAIGN_CLASSES } from 'constants/campaign';
const { RangePicker } = DatePicker;
const { Panel } = Collapse;

const AllotedSpots = (props) => {
  const {
    isActive,
    handleValidateFieldNames,
    validateFieldsName,
    setValidateFieldsName,
    setCampaignStartEndDate,
    campaignStartEndDate,
    isCustomFrequency,
    // setIsCustomFrequency,
    selectedClassCampaign,
  } = props;

  const startEndDateReq = campaignStartEndDate ? false : true;
  const handleStartEndTime = (value) => {
    if (value && value.length > 1) {
      setCampaignStartEndDate(value);
    }
  };

  // const handleCustomFrequencyCheckbox = (e) => {
  //   setIsCustomFrequency(e.target.checked);
  // };

  const handleKeyPress = (event) => {
    if (!/[0-9]/.test(event.key)) {
      event.preventDefault();
    }
  };

  return (
    <CollapseBox>
      <Collapse defaultActiveKey={['1']}>
        <Panel header="Alloted Spots" key="1">
          <Row gutter={16}>
            <Col className="gutter-row" xs={24} sm={24}>
              <Form.Item
                label="Campaign Start and End Date"
                name="campaign_start_end_date"
                rules={[
                  {
                    required: isActive && startEndDateReq,
                    message: 'Please Enter Campaign Start and End Date!',
                  },
                ]}
              >
                <Space direction="vertical" size={12}>
                  <RangePicker
                    value={campaignStartEndDate}
                    showTime={{ format: 'HH:mm' }}
                    format="YYYY-MM-DD HH:mm"
                    onChange={(startEndTime) =>
                      handleStartEndTime(startEndTime)
                    }
                  />
                </Space>
              </Form.Item>
            </Col>
            <Col className="gutter-row" xs={24} sm={12}>
              <Form.Item
                name="duration"
                label="Duration (in seconds)"
                validateTrigger={handleValidateFieldNames('duration')}
                rules={[
                  {
                    required: isActive,
                    message: 'Please enter duration!',
                  },
                  {
                    pattern: new RegExp(/^\d+$/),
                    message: 'Input must be numeric!',
                  },
                  () => ({
                    validator(_, value) {
                      if ((value && value > 0) || !isActive) {
                        return Promise.resolve();
                      } else if (isActive) {
                        return Promise.reject(
                          new Error('Duration must be greater than 0')
                        );
                      }
                    },
                  }),
                ]}
              >
                <Input
                  type="text"
                  onKeyPress={handleKeyPress}
                  placeholder="Duration (in seconds)"
                  onBlur={() =>
                    setValidateFieldsName([...validateFieldsName, 'duration'])
                  }
                />
              </Form.Item>
            </Col>
            {selectedClassCampaign == CAMPAIGN_CLASSES.MASS_CAMPAIGN && (
              <Col className="gutter-row" xs={24} sm={12}>
                <Form.Item
                  name="slots"
                  label="Slots"
                  validateTrigger={handleValidateFieldNames('slots')}
                  rules={[
                    {
                      required: isActive,
                      message: 'Please enter slots!',
                    },
                    {
                      pattern: new RegExp(/^\d+$/),
                      message: 'Input must be numeric!',
                    },
                    () => ({
                      validator(_, value) {
                        if ((value && value > 0) || !isActive) {
                          return Promise.resolve();
                        }
                        return Promise.reject(
                          new Error('Slots must be greater than 0')
                        );
                      },
                    }),
                  ]}
                >
                  <Input
                    type="text"
                    onKeyPress={handleKeyPress}
                    placeholder="Slots"
                    onBlur={() =>
                      setValidateFieldsName([...validateFieldsName, 'slots'])
                    }
                  />
                </Form.Item>
              </Col>
            )}
            <Col className="gutter-row" xs={24} sm={12}>
              <Form.Item
                name="maximum_daily_slots"
                label="Maximum slots"
                validateTrigger={handleValidateFieldNames(
                  'maximum_daily_slots'
                )}
                rules={[
                  {
                    required: isActive,
                    message: 'Please enter Maximum slots!',
                  },
                  {
                    pattern: new RegExp(/^\d+$/),
                    message: 'Input must be numeric!',
                  },
                  () => ({
                    validator(_, value) {
                      if ((value && value > 0) || !isActive) {
                        return Promise.resolve();
                      }
                      return Promise.reject(
                        new Error('Maximum slots must be greater than 0')
                      );
                    },
                  }),
                ]}
              >
                <Input
                  type="text"
                  placeholder="Maximum slots!"
                  onKeyPress={handleKeyPress}
                  onBlur={() =>
                    setValidateFieldsName([
                      ...validateFieldsName,
                      'maximum_daily_slots',
                    ])
                  }
                />
              </Form.Item>
            </Col>
            <Col className="gutter-row" xs={24}>
              {/* <Checkbox
                defaultChecked={isCustomFrequency}
                onChange={handleCustomFrequencyCheckbox}
              >
                Define Custom Frequency
              </Checkbox> */}
            </Col>
            {isCustomFrequency && (
              <Col
                className="gutter-row"
                xs={24}
                sm={12}
                style={{ marginTop: '16px' }}
              >
                <Form.Item
                  name="per_ride_frequency"
                  label="Per ride frequency"
                  validateTrigger={handleValidateFieldNames(
                    'per_ride_frequency'
                  )}
                  rules={[
                    {
                      required: isActive,
                      message: 'Please enter per ride frequency!',
                    },
                    {
                      pattern: new RegExp(/^\d+$/),
                      message: 'Input must be numeric!',
                    },
                    () => ({
                      validator(_, value) {
                        if ((value && value > 0) || !isActive) {
                          return Promise.resolve();
                        }
                        return Promise.reject(
                          new Error('Per ride frequency must be greater than 0')
                        );
                      },
                    }),
                  ]}
                >
                  <Input
                    type="text"
                    onKeyPress={handleKeyPress}
                    min={1}
                    max={4}
                    defaultValue={1}
                    placeholder="Per ride frequency"
                    onBlur={() =>
                      setValidateFieldsName([
                        ...validateFieldsName,
                        'per_ride_frequency',
                      ])
                    }
                  />
                </Form.Item>
              </Col>
            )}
          </Row>
        </Panel>
      </Collapse>
    </CollapseBox>
  );
};

AllotedSpots.propTypes = {
  campaignStartEndDate: oneOfType([array]),
  // form: oneOfType([object, array]),
  handleValidateFieldNames: func,
  isActive: bool,
  isCustomFrequency: bool,
  selectedClassCampaign: string,
  setCampaignStartEndDate: func,
  // setIsCustomFrequency: func,
  setValidateFieldsName: func,
  validateFieldsName: oneOfType([array]),
};

export default AllotedSpots;
