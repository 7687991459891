import React from 'react';
// import moment from 'moment';
import { array, oneOfType, func } from 'prop-types';
import {
  // Form,
  Row,
  Col,
  // Radio,
  Collapse,
  // TimePicker
} from 'antd';
import {
  ScheduleComponent,
  Inject,
  Day,
  Week,
  WorkWeek,
  Month,
  Agenda,
  DragAndDrop,
  Resize,
} from '@syncfusion/ej2-react-schedule';
import { DateTimePickerComponent } from '@syncfusion/ej2-react-calendars';
import { L10n } from '@syncfusion/ej2-base';
import { CollapseBox, EditorBox } from './_time_slots.styled';

L10n.load({
  'en-US': {
    schedule: {
      saveButton: 'Add',
      cancelButton: 'Close',
      deleteButton: 'Remove',
      newEvent: 'Add Event',
    },
  },
});

const { Panel } = Collapse;
// const format = 'HH:mm';

const TimeSlots = (props) => {
  const { setTimeSlots, timeSlots, campaignStartEndDate } = props;
  const campaignStartEndDateStructure = {
    startDate: new Date(new Date()),
    endDate: new Date(new Date()),
  };
  if (campaignStartEndDate && campaignStartEndDate.length > 1) {
    campaignStartEndDateStructure.startDate = campaignStartEndDate[0].toDate();
    campaignStartEndDateStructure.endDate = campaignStartEndDate[1].toDate();
  }

  const onResizeStart = (args) => {
    args.interval = 10;
  };

  const onDragStart = (args) => {
    args.interval = 1;
  };

  const handleEditorTemplate = (editor) => {
    return (
      <EditorBox>
        <table className="custom-event-editor">
          <tbody>
            <tr>
              <td className="e-text-label e-heading">Name</td>
              <td>
                <input
                  id="Subject"
                  className="e-field e-input"
                  type="text"
                  name="Subject"
                  data-name="Subject"
                />
              </td>
            </tr>
            <tr>
              <td className="e-from-date e-heading">From</td>
              <td>
                <DateTimePickerComponent
                  id="StartTime"
                  data-name="StartTime"
                  className="e-field"
                  value={
                    new Date(
                      editor?.IsAllDay
                        ? new Date().setHours(0, 0, 0, 0)
                        : editor?.StartTime
                    )
                  }
                ></DateTimePickerComponent>
              </td>
            </tr>
            <tr>
              <td className="e-to-date e-heading">To</td>
              <td>
                <DateTimePickerComponent
                  id="EndTime"
                  data-name="EndTime"
                  className="e-field"
                  value={
                    new Date(
                      editor?.IsAllDay
                        ? new Date().setHours(23, 59, 59, 999)
                        : editor?.EndTime
                    )
                  }
                ></DateTimePickerComponent>
              </td>
            </tr>
          </tbody>
        </table>
      </EditorBox>
    );
  };

  const onActionComplete = (action) => {
    if (action?.requestType === 'eventChanged') {
      const copytimeSlots = [...timeSlots];
      const index = copytimeSlots.findIndex(
        (data) => data.Id === action?.data[0].Id
      );
      copytimeSlots[index] = action?.data[0];
      setTimeSlots(copytimeSlots);
    }
    // if (action?.requestType === 'eventCreated') {
    //   const addedTimeSlots = [...timeSlots, action?.data[0]];
    //   setTimeSlots(addedTimeSlots);
    // }
    if (action?.requestType === 'eventRemoved') {
      const filteredTimeSlots = timeSlots.filter(
        (data) => data.Id !== action?.data[0].Id
      );
      setTimeSlots(filteredTimeSlots || []);
    }
  };

  return (
    <CollapseBox>
      <Collapse defaultActiveKey={['1']}>
        <Panel header="Select Time Slots" key="1">
          <Row gutter={16}>
            {/* <Col className="gutter-row" xs={24}>
              <Form.Item name="is_every_day">
                <Radio.Group defaultValue={isEveryDay}>
                  <Radio
                    value={true}
                    onClick={() => {
                      setIsEveryDay(true);
                    }}
                  >
                    Every Day
                  </Radio>
                  <Radio
                    value={false}
                    onClick={() => {
                      setIsEveryDay(false);
                    }}
                  >
                    Specific Day
                  </Radio>
                </Radio.Group>
              </Form.Item>
            </Col> */}
            <Col className="gutter-row" xs={24}>
              {/* {isEveryDay && (
                <div>
                  {everyDayTimeSlots &&
                    Object.entries(everyDayTimeSlots) &&
                    Object.entries(everyDayTimeSlots).length > 0 &&
                    Object.entries(everyDayTimeSlots).map((data, index) => {
                      if (!data || (data && data.length <= 0)) return null;
                      const [day, value] = data || [];

                      return (
                        <div key={index} className="slot-box">
                          <div className="slot-label">{day}:</div>
                          <TimePicker
                            placeholder="From"
                            format={format}
                            value={
                              (value?.from && moment(value?.from, format)) || ''
                            }
                            onChange={(value) =>
                              handleTimeSlotsData({
                                name: day,
                                type: 'from',
                                value,
                              })
                            }
                          />
                          <TimePicker
                            placeholder="To"
                            format={format}
                            value={
                              (value?.to && moment(value?.to, format)) || ''
                            }
                            onChange={(value) =>
                              handleTimeSlotsData({
                                name: day,
                                type: 'to',
                                value,
                              })
                            }
                          />
                        </div>
                      );
                    })}
                </div>
              )} */}
              <div>
                <ScheduleComponent
                  currentView="Week"
                  onDragStart={onDragStart}
                  resizeStart={onResizeStart}
                  editorTemplate={handleEditorTemplate}
                  actionComplete={onActionComplete}
                  eventSettings={{ dataSource: timeSlots }}
                  minDate={campaignStartEndDateStructure.startDate}
                  maxDate={campaignStartEndDateStructure.endDate}
                >
                  <Inject
                    services={[
                      Day,
                      Week,
                      WorkWeek,
                      Month,
                      Agenda,
                      DragAndDrop,
                      Resize,
                    ]}
                  />
                </ScheduleComponent>
              </div>
            </Col>
          </Row>
        </Panel>
      </Collapse>
    </CollapseBox>
  );
};

TimeSlots.propTypes = {
  // everyDayTimeSlots: shape({}),
  // isEveryDay: bool,
  campaignStartEndDate: oneOfType([array]),
  setTimeSlots: func,
  timeSlots: oneOfType([array]),
  // setIsEveryDay: func,
  // setSpecificDayTimeSlots: func,
  // specificDayTimeSlots: shape({}),
};

export default TimeSlots;
