import React, { useEffect, useState } from 'react';
import { string } from 'prop-types';
import { Table, Collapse } from 'antd';
import { CollapseBox, Loader } from './_device-operation-list.styled';
import {
  formatDate,
  getSingleValueFromEncodedURL,
  URLS,
  showErrorMsg,
  getAMAAuthTokenLocalStorage,
  generateAndroidManagementToken,
} from 'utils';

const { Panel } = Collapse;

const DeviceOperationList = (props) => {
  const { deviceAMAId } = props;

  const [isFetching, setIsFetching] = useState(false);
  const [deviceOperationList, setDeviceOperationList] = useState([]);

  const [pagination, setPagination] = useState({
    currentPage: 1,
    pageSize: 100,
    total: 0,
    nextPageToken: '',
    prevPageToken: [],
  });

  const apiKey =
    process.env.REACT_APP_ANDROID_MANAGEMENT_API_KEY ||
    'AIzaSyCVhMeBzCjfhBbSWenrG3aBGkXCkbvMjNc';
  const discoveryDocs = [
    'https://androidmanagement.googleapis.com/$discovery/rest?version=v1',
  ];

  const apiRequestFailedLimit = 2;
  let apiRequestFailedCount = 0;

  useEffect(() => {
    handleClientLoad();
  }, []);

  const handleClientLoad = () => {
    setIsFetching(true);
    if (window.gapi) {
      window.gapi.load('client', initClient);
    } else {
      setTimeout(handleClientLoad, 1000);
    }
  };

  const initClient = () => {
    window.gapi.client
      .init({
        apiKey: apiKey,
        discoveryDocs: discoveryDocs,
      })
      .then(() => {
        fetchDeviceList();
      })
      .catch(() => {
        setIsFetching(false);
        showErrorMsg('System is facing error, Please try again later');
      });
  };

  const fetchDeviceList = (apiPagination = pagination) => {
    setIsFetching(true);

    const { pageSize, nextPageToken } = apiPagination; // pagination object
    const authToken = getAMAAuthTokenLocalStorage(); // AMA auth token

    const deviceOperationListURL = `${URLS.ANDROID_MANAGEMENT.GET_ENROLLED_DEVICES_OPERATION_DETAILS}/${deviceAMAId}/operations?pageSize=${pageSize}&pageToken=${nextPageToken}`;

    window.gapi.client
      .request({
        path: deviceOperationListURL,
        method: 'GET',
        headers: { Authorization: 'Bearer ' + authToken },
      })
      .then((resp) => {
        const deviceList =
          (resp?.result?.operations &&
            resp?.result?.operations.map((device) => {
              return {
                ...device,
                key: getSingleValueFromEncodedURL(device?.name, 5), // key params must be there after
                name: getSingleValueFromEncodedURL(device?.name, 3), // key params must be there after
                command_status: device?.done ? 'Successful' : 'Failed',
                created_at: device?.metadata?.createTime
                  ? formatDate(device?.metadata?.createTime)
                  : '-',
                command_type: device?.metadata?.type
                  ? device?.metadata?.type
                  : 'No Command Found',
                duration: device?.metadata?.duration
                  ? device?.metadata?.duration
                  : 0,
              };
            })) ||
          [];
        let totalRecords = pagination.pageSize * apiPagination.currentPage;
        if (resp?.result?.nextPageToken) {
          totalRecords = totalRecords + 100;
        }
        setPagination({
          ...apiPagination,
          nextPageToken: resp?.result?.nextPageToken || '',
          total: totalRecords,
        });
        setDeviceOperationList(deviceList);
        setIsFetching(false);
      })
      .catch((apiExp) => {
        const { code, status, details } = apiExp?.result?.error || {};
        if (
          apiRequestFailedCount < apiRequestFailedLimit &&
          code === 401 &&
          status === 'UNAUTHENTICATED' &&
          (details?.[0]?.reason === 'ACCESS_TOKEN_EXPIRED' ||
            details?.[0]?.reason === 'CREDENTIALS_MISSING')
        ) {
          try {
            generateAndroidManagementToken();
            setTimeout(fetchDeviceList, 1000);
            apiRequestFailedCount = apiRequestFailedCount + 1;
          } catch (authError) {
            setIsFetching(false);
            console.log('System is facing error, Please try again later');
            throw authError;
          }
        } else {
          setIsFetching(false);
          showErrorMsg('System is facing error, Please try again later');
        }
      });
  };

  const onChangePagination = (currentPage) => {
    let tempPagination = pagination;
    if (currentPage < pagination.currentPage) {
      tempPagination = {
        ...tempPagination,
        nextPageToken:
          currentPage > 1 ? pagination.prevPageToken[currentPage - 2] : '',
        currentPage: currentPage,
      };
    } else {
      const prevPageTokens =
        pagination.prevPageToken && pagination.prevPageToken[currentPage - 2]
          ? [...pagination.prevPageToken]
          : [...pagination.prevPageToken, pagination.nextPageToken];
      tempPagination = {
        ...tempPagination,
        currentPage: currentPage,
        prevPageToken: prevPageTokens,
      };
    }
    setPagination(tempPagination);
    fetchDeviceList(tempPagination);
  };

  const columns = [
    // {
    //   key: 'Name',
    //   title: DEVICE_OPERATION_LIST_LABELS.NAME,
    //   dataIndex: 'name',
    // },
    {
      key: 'Command Type',
      title: DEVICE_OPERATION_LIST_LABELS.COMMAND_TYPE,
      dataIndex: 'command_type',
    },
    {
      key: 'duration',
      title: DEVICE_OPERATION_LIST_LABELS.DURATION,
      dataIndex: 'duration',
    },
    {
      key: 'Create At',
      title: DEVICE_OPERATION_LIST_LABELS.CREATED_AT,
      dataIndex: 'created_at',
    },
    {
      key: 'Command Status',
      title: DEVICE_OPERATION_LIST_LABELS.STATUS,
      dataIndex: 'command_status',
    },
  ];

  return (
    <CollapseBox>
      <Collapse defaultActiveKey={['1']}>
        <Panel header="Device Operation History" key="1">
          <div className="main-table-body">
            <Table
              loading={{
                indicator: <Loader>Fetching...</Loader>,
                spinning: isFetching,
              }}
              columns={columns}
              dataSource={deviceOperationList || []}
              size="small"
              pagination={{
                showSizeChanger: false,
                defaultPageSize: pagination.pageSize,
                total: pagination.total,
                onChange: onChangePagination,
              }}
            />
          </div>
        </Panel>
      </Collapse>
    </CollapseBox>
  );
};

DeviceOperationList.propTypes = {
  deviceAMAId: string,
};

export default DeviceOperationList;

const DEVICE_OPERATION_LIST_LABELS = {
  // NAME: 'Name',
  COMMAND_TYPE: 'Command Type',
  CREATED_AT: 'Created At',
  DURATION: 'Duration',
  STATUS: 'Status',
};
