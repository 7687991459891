import React, { useState, useEffect } from 'react';
import { string, func, bool, array } from 'prop-types';
import { Form, Modal, Button, Steps, Result, Table, Spin } from 'antd';
// import { PlusOutlined, DeleteOutlined, EyeOutlined } from '@ant-design/icons';
// import { GLOBAL_APP_SETTINGS_KEYS, MODE_TYPES } from 'constants/common';
import {
  getAMAAuthTokenLocalStorage,
  generateAndroidManagementToken,
  URLS,
  showErrorMsg,
  asyncDelay,
  getSingleValueFromEncodedURL,
} from 'utils';
// import validUrl from 'valid-url';
import {
  AMA_COMMAND_LIST,
  AMA_COMMAND_DURATION,
} from 'constants/enrolled-device';
import {
  LoadingOutlined,
  MobileOutlined,
  CheckCircleOutlined,
  FileSyncOutlined,
  SyncOutlined,
} from '@ant-design/icons';
import { CSVLink } from 'react-csv';

import { BulkCommandProcessModalBox } from './_bulk-command-process-modal.styled';

const { Step } = Steps;

const BulkCommandProcessModal = (props) => {
  const {
    modalTitle,
    modalVisible,
    handleOk,
    handleModal,
    selectedDeviceDetails,
    // callback,
  } = props;

  const ref = React.useRef(null);

  const [currentStep, setCurrentStep] = useState(0);
  const [currentDeviceId, setCurrentDeviceId] = useState(0);
  const [isFetchingCSV, setIsFetchingCSV] = useState(false);
  const [CSVDownload, setCSVDownload] = useState([]);
  const [deviceOperationStatus, setDeviceOperationStatus] = useState([]);

  const commandDelayed = 2000;
  const apiRequestFailedLimit = 2;
  let apiRequestFailedCount = 0;

  useEffect(() => {
    window.onbeforeunload = function () {
      return true;
    };

    return () => {
      window.onbeforeunload = null;
    };
  }, []);

  useEffect(() => {
    restartDevices();
  }, [selectedDeviceDetails]);

  const restartDevices = async () => {
    for (let device of selectedDeviceDetails) {
      setCurrentDeviceId(device?.serial_number);
      // restart device function called here
      if (device?.ama_id) {
        await sendCommandToDevice(device?.ama_id);
      }
      await asyncDelay(commandDelayed);
    }
    await asyncDelay(2000);
    await fetchDeviceOperationStatus();
    await asyncDelay(500);
    updateStep(1);
  };

  const sendCommandToDevice = (deviceId) => {
    const command = AMA_COMMAND_LIST.REBOOT;
    const duration = AMA_COMMAND_DURATION[AMA_COMMAND_LIST.REBOOT];

    const issueDeviceCommandURL = `${URLS.ANDROID_MANAGEMENT.ENROLLED_DEVICES_LIST}/${deviceId}:issueCommand`;

    const authToken = getAMAAuthTokenLocalStorage(); // AMA auth token

    window.gapi.client
      .request({
        path: issueDeviceCommandURL,
        method: 'POST',
        body: {
          type: command,
          duration: duration,
        },
        headers: { Authorization: 'Bearer ' + authToken },
      })
      .then((resp) => {
        console.log('Device restarted successfully');
      })
      .catch((apiExp) => {
        if (
          apiRequestFailedCount < apiRequestFailedLimit &&
          apiExp?.result?.error?.code === 401 &&
          apiExp?.result?.error?.status === 'UNAUTHENTICATED' &&
          apiExp?.result?.error?.details &&
          apiExp?.result?.error?.details[0] &&
          apiExp?.result?.error?.details[0]?.reason === 'ACCESS_TOKEN_EXPIRED'
        ) {
          try {
            generateAndroidManagementToken();
            setTimeout(() => {
              sendCommandToDevice(deviceId);
            }, 1000);
            apiRequestFailedCount = apiRequestFailedCount + 1;
          } catch (authError) {
            console.log('System is facing error, Please try again later');
            throw authError;
          }
        } else {
          showErrorMsg('System is facing error, Please try again later');
        }
      });
  };

  const fetchDeviceOperationStatus = async () => {
    let deviceOpRespArray = [];
    try {
      for (let device of selectedDeviceDetails) {
        const deviceOperationResponse = await getDeviceOperationStatus(device);
        deviceOpRespArray.push(deviceOperationResponse);
      }
      setDeviceOperationStatus(deviceOpRespArray);
      handleCSVData(deviceOpRespArray); // prepare CSV file to download the records
    } catch (error) {
      showErrorMsg('System is facing error, Please try again later');
    }
  };

  const getDeviceOperationStatus = (device) => {
    const deviceOperationURL = `${URLS.ANDROID_MANAGEMENT.ENROLLED_DEVICES_LIST}/${device?.ama_id}/operations`;

    const authToken = getAMAAuthTokenLocalStorage(); // AMA auth token

    return new Promise((resolve, reject) => {
      window.gapi.client
        .request({
          path: deviceOperationURL,
          method: 'GET',
          headers: { Authorization: 'Bearer ' + authToken },
        })
        .then((resp) => {
          const { operations } = resp?.result || {};
          if (operations?.[0]) {
            const operationDetails = resp?.result?.operations[0];
            const returnDeviceDetail = {
              key: getSingleValueFromEncodedURL(operationDetails?.name, 3), // key params must be there after the list data retrieved from the API
              serial_number: device?.serial_number,
              ama_id: getSingleValueFromEncodedURL(operationDetails?.name, 3),
              operation_type: operationDetails.metadata?.type,
              operation_status: operationDetails?.done ? 'Success' : 'Failed',
            };
            return resolve(returnDeviceDetail);
          }
        })
        .catch((apiExp) => {
          const { code, status, details } = apiExp?.result?.error || {};
          if (
            apiRequestFailedCount < apiRequestFailedLimit &&
            code === 401 &&
            status === 'UNAUTHENTICATED' &&
            details &&
            details?.[0]?.reason === 'ACCESS_TOKEN_EXPIRED'
          ) {
            try {
              generateAndroidManagementToken();
              setTimeout(() => {
                getDeviceOperationStatus(device?.ama_id);
              }, 1000);
              apiRequestFailedCount = apiRequestFailedCount + 1;
            } catch (authError) {
              showErrorMsg('System is facing error, Please try again later');
              reject();
              throw authError;
            }
          } else {
            showErrorMsg('System is facing error, Please try again later');
            reject();
          }
        });
    });
  };

  const handleOnCloseModal = () => {
    handleModal(false);
  };

  let modalFooterButtons = [
    <Button key="close" onClick={handleOnCloseModal}>
      Close
    </Button>,
  ];

  const columns = [
    {
      title: DEVICE_REPORT_LABELS.SERIAL_NUMBER,
      dataIndex: 'serial_number',
      key: 'serial_number',
    },
    {
      title: DEVICE_REPORT_LABELS.AMA_ID,
      dataIndex: 'ama_id',
      key: 'ama_id',
    },
    {
      title: DEVICE_REPORT_LABELS.OPERATION_TYPE,
      dataIndex: 'operation_type',
      key: 'operation_type',
    },
    {
      title: DEVICE_REPORT_LABELS.OPERATION_STATUS,
      dataIndex: 'operation_status',
      key: 'operation_status',
    },
  ];

  const handleCSVData = (deviceOperationDetails) => {
    let csvData = [];
    deviceOperationDetails.forEach((data) => {
      const excelStructureData = {
        [DEVICE_REPORT_LABELS.SERIAL_NUMBER]: data?.serial_number || '',
        [DEVICE_REPORT_LABELS.AMA_ID]: data?.ama_id || '',
        [DEVICE_REPORT_LABELS.OPERATION_TYPE]: data?.operation_type || '',
        [DEVICE_REPORT_LABELS.OPERATION_STATUS]: data?.operation_status || '',
      };
      csvData.push(excelStructureData);
    });
    setCSVDownload(csvData);
  };

  const handleExport = () => {
    setIsFetchingCSV(true);
    handleCSVData(deviceOperationStatus || []);
    setIsFetchingCSV(false);
    ref.current.click();
    updateStep(2); // let user to the final page
  };

  const updateStep = (step) => {
    setCurrentStep(step);
  };

  return (
    <div>
      <BulkCommandProcessModalBox>
        <Modal
          title={modalTitle}
          visible={modalVisible}
          onOk={handleOk}
          onCancel={handleOnCloseModal}
          className="expense-modal"
          maskClosable={false}
          width={850}
          forceRender={true}
          footer={currentStep >= 1 && modalFooterButtons}
        >
          <Steps current={currentStep}>
            <Step
              status={currentStep >= 0 ? 'finish' : 'wait'}
              title="Operation Processing"
              icon={
                currentStep === 0 ? <LoadingOutlined /> : <MobileOutlined />
              }
            />
            <Step
              status={currentStep >= 1 ? 'finish' : 'wait'}
              title="Operation Report"
              icon={<FileSyncOutlined />}
            />
            <Step
              status={currentStep >= 2 ? 'finish' : 'wait'}
              title="Finish"
              icon={<CheckCircleOutlined />}
            />
          </Steps>
          {currentStep === 0 ? (
            <Result
              icon={<SyncOutlined spin />}
              status="success"
              title={`Currently ${currentDeviceId} is being restarted...`}
            />
          ) : currentStep === 1 ? (
            <>
              <div
                className="table-header"
                style={{ marginTop: '2rem', textAlign: 'right' }}
              >
                <div className="table-right-side">
                  <div className="export-generate-btn">
                    <Form.Item>
                      <CSVLink
                        data={CSVDownload}
                        headers={headers}
                        filename={'reboot-operation-report.csv'}
                      >
                        <span ref={ref}></span>
                      </CSVLink>
                      {isFetchingCSV ? (
                        <div className="file-export-loading">
                          <Spin
                            indicator={
                              <LoadingOutlined style={{ fontSize: 18 }} spin />
                            }
                          />
                          <div>Data Fetching...</div>
                        </div>
                      ) : (
                        <Button type="primary" onClick={handleExport}>
                          Donwload Report
                        </Button>
                      )}
                    </Form.Item>
                  </div>
                </div>
              </div>

              <Table
                columns={columns}
                dataSource={deviceOperationStatus}
                pagination={false}
                scroll={{ y: 400 }}
              />

              <div
                className="table-right-side"
                style={{ marginTop: '2rem', textAlign: 'right' }}
              >
                <div className="export-generate-btn">
                  <Button type="primary" onClick={() => updateStep(2)}>
                    {'Finish'}
                  </Button>
                </div>
              </div>
            </>
          ) : (
            <>
              <Result
                status="success"
                title={`Operation executed successfully`}
              />
            </>
          )}
        </Modal>
      </BulkCommandProcessModalBox>
    </div>
  );
};

BulkCommandProcessModal.propTypes = {
  // callback: func,
  handleModal: func,
  handleOk: func,
  modalTitle: string,
  modalVisible: bool,
  selectedDeviceDetails: array,
};

export default BulkCommandProcessModal;

const DEVICE_REPORT_LABELS = {
  SERIAL_NUMBER: 'Serial Number',
  AMA_ID: 'AMA Id',
  OPERATION_TYPE: 'Operation Type',
  OPERATION_STATUS: 'Operation Status',
};

const headers = [
  {
    label: DEVICE_REPORT_LABELS.SERIAL_NUMBER,
    key: DEVICE_REPORT_LABELS.SERIAL_NUMBER,
  },
  { label: DEVICE_REPORT_LABELS.AMA_ID, key: DEVICE_REPORT_LABELS.AMA_ID },
  {
    label: DEVICE_REPORT_LABELS.OPERATION_TYPE,
    key: DEVICE_REPORT_LABELS.OPERATION_TYPE,
  },
  {
    label: DEVICE_REPORT_LABELS.OPERATION_STATUS,
    key: DEVICE_REPORT_LABELS.OPERATION_STATUS,
  },
];
