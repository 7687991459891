import React, { useState, useEffect } from 'react';
import { string, func } from 'prop-types';
import { Input, Modal, Form, Row, Col, Button, Typography } from 'antd';
import { PlusOutlined, DeleteOutlined, EyeOutlined } from '@ant-design/icons';
import { DISCOVER_IMAGE_DIMENSIONS } from 'constants/discover';
import { MODE_TYPES, GLOBAL_APP_SETTINGS_KEYS } from 'constants/common';
import {
  httpPost,
  httpPut,
  httpGet,
  URLS,
  showErrorMsg,
  showSuccessMsg,
  updateFirebaseGlobalAppSettings,
} from 'utils';
import {
  DiscoverModalBox,
  UploadImageBox,
  PreviewImageBox,
} from './_discover-modal.styled';

const { TextArea } = Input;
const { Text } = Typography;

const logoImageInitialStructure = {
  base64: '',
  file: '',
  gcloudUrl: '',
};
const DiscoverModal = (props) => {
  const { modalTitle, activeMode, handleOk, closeModal, callback, discoverId } =
    props;
  const [form] = Form.useForm();
  const [validateFieldsName, setValidateFieldsName] = useState([]);
  const [logoImage, setLogoImage] = useState(logoImageInitialStructure);
  const [isPreviewLogoImg, setIsPreviewLogoImg] = useState(false);
  const [isDiscoverAdding, setIsDiscoverAdding] = useState(false);
  const [isDiscoverUpdating, setIsDiscoverUpdating] = useState(false);
  const [, setIsFetchingDiscover] = useState(false);

  const isDisabled = isDiscoverAdding || isDiscoverUpdating;

  useEffect(() => {
    if (activeMode === MODE_TYPES.ADD) {
      form.resetFields();
      setLogoImage(logoImageInitialStructure);
    }
    if (!discoverId) return;
    setIsFetchingDiscover(true);
    const url = URLS?.DISCOVER.GET_DISCOVER_BY_ID.replace('#ID#', discoverId);
    httpGet(url)
      .then((res) => {
        if (res.status === 200) {
          if (
            res?.data &&
            Object.entries(res?.data) &&
            Object.entries(res?.data).length > 0
          ) {
            Object.entries(res?.data).forEach(([key, value]) => {
              if (key === 'art_work') {
                setLogoImage({
                  ...logoImageInitialStructure,
                  gcloudUrl: value,
                });
              } else {
                form.setFieldsValue({
                  [key]: value,
                });
              }
            });
          }
        } else if (res?.error) {
          showErrorMsg(res?.error);
        }
        setIsFetchingDiscover(false);
      })
      .catch((err) => {
        showErrorMsg(err);
        setIsFetchingDiscover(false);
      });
  }, [activeMode]);

  const handleValidateFieldNames = (name) => {
    const isFieldName = validateFieldsName.find(
      (fieldName) => fieldName === name
    );
    if (isFieldName) return 'onChange';
    return 'onBlur';
  };

  const getBase64 = (img, callback) => {
    const reader = new FileReader();
    reader.addEventListener('load', () => callback(reader.result));
    reader.readAsDataURL(img);
  };

  const handleLogoImgUpload = async (e) => {
    const file = e.target.files[0];

    if (file) {
      const isImageValid = await new Promise((resolve, reject) => {
        // check the file type - you can specify the types you'd like here:
        const isImg =
          file.type === 'image/jpeg' ||
          file.type === 'image/jpg' ||
          file.type === 'image/png';

        if (!isImg) {
          showErrorMsg(
            `Invalid image extension. Image must be in .png, .jpeg, or .jpg extension`
          );
          resolve(false);
          return;
        }

        // check the file size - you can specify the file size you'd like here:
        const imageSize =
          file.size / 1024 > DISCOVER_IMAGE_DIMENSIONS.FILE_SIZE;
        if (imageSize) {
          showErrorMsg(
            `Image size must be smaller then ${DISCOVER_IMAGE_DIMENSIONS.FILE_SIZE} KB`
          );
          resolve(false);
          return;
        }

        // check for dimensions
        var reader = new FileReader();
        // Read the contents of Image File.
        reader.readAsDataURL(file);
        reader.onload = (event) => {
          // Initiate the JavaScript Image object.
          var image = new Image();

          // Set the Base64 string return from FileReader as source.
          image.src = event.target.result;

          image.onload = function () {
            const { height, width } = this;
            // if the aspect ratio is in our sweet spot, proceed - you can specify whatever checks for height and width you want
            if (
              width !== DISCOVER_IMAGE_DIMENSIONS.FILE_WIDTH ||
              height !== DISCOVER_IMAGE_DIMENSIONS.FILE_HEIGHT
            ) {
              showErrorMsg(
                `Image does not have optimal dimensions. Recommended image dimensions are ${DISCOVER_IMAGE_DIMENSIONS.FILE_WIDTH} X ${DISCOVER_IMAGE_DIMENSIONS.FILE_HEIGHT}.`
              );
              resolve(false);
            } else {
              resolve(true);
            }
          };
        };
      });

      if (isImageValid) {
        getBase64(file, (imageUrl) => {
          setLogoImage({
            ...logoImageInitialStructure,
            base64: imageUrl,
            file,
          });
        });
      }
    }
  };

  const handlePreviewLogoImg = ({ isVisible }) => {
    setIsPreviewLogoImg(isVisible);
  };

  const getImageUrl = logoImage.gcloudUrl || logoImage.base64;

  const handleLogoImgDelete = () => {
    setLogoImage(logoImageInitialStructure);
  };

  const handleAddDiscover = () => {
    form.submit();
    form
      .validateFields()
      .then(async (values) => {
        const formData = new FormData();
        formData.append('art_work', logoImage.file);
        formData.append('name', values.name);
        formData.append('ar_name', values.ar_name);
        formData.append('description', values.description);
        formData.append('ar_description', values.ar_description);
        if (!logoImage.file) {
          showErrorMsg('Art work required!');
          return;
        }
        setIsDiscoverAdding(true);
        httpPost(URLS?.DISCOVER.ADD_DISCOVER, formData)
          .then((res) => {
            if (res.status === 200) {
              showSuccessMsg('Discover added successfully!');
              resetAllData();
              callback();
              updateFirebaseGlobalAppSettings(
                GLOBAL_APP_SETTINGS_KEYS.discover
              );
            } else if (res?.error) {
              showErrorMsg(res?.error);
            }
            setIsDiscoverAdding(false);
          })
          .catch((err) => {
            showErrorMsg(err);
            setIsDiscoverAdding(false);
          });
      })
      .catch((errorInfo) => errorInfo);
  };

  const handleEditDiscover = () => {
    form.submit();
    form
      .validateFields()
      .then(async (values) => {
        const formData = new FormData();
        formData.append('art_work', logoImage.file);
        formData.append('name', values.name);
        formData.append('ar_name', values.ar_name);
        formData.append('description', values.description);
        formData.append('ar_description', values.ar_description);
        if (!logoImage.file && !logoImage.gcloudUrl) {
          showErrorMsg('Art work required!');
          return;
        }
        setIsDiscoverUpdating(true);
        const url = URLS?.DISCOVER?.UPDATE_DISCOVER.replace('#ID#', discoverId);
        httpPut(url, formData)
          .then((res) => {
            if (res.status === 200) {
              showSuccessMsg('Discover updated successfully!');
              resetAllData();
              callback();
              updateFirebaseGlobalAppSettings(
                GLOBAL_APP_SETTINGS_KEYS.discover
              );
            } else if (res?.error) {
              showErrorMsg(res?.error);
            }
            setIsDiscoverUpdating(false);
          })
          .catch((err) => {
            showErrorMsg(err);
            setIsDiscoverUpdating(false);
          });
      })
      .catch((errorInfo) => errorInfo);
  };

  const resetAllData = () => {
    form.resetFields();
    setLogoImage(logoImageInitialStructure);
    closeModal();
  };
  const handleOnCloseModal = () => {
    if (isDisabled) return;
    resetAllData();
  };

  let modalfooterButtons = [
    <Button
      key="add"
      type="primary"
      onClick={handleAddDiscover}
      disabled={isDisabled}
    >
      {isDiscoverAdding ? 'Loading...' : 'Add Discover'}
    </Button>,
    <Button key="close" onClick={handleOnCloseModal} disabled={isDisabled}>
      Close
    </Button>,
  ];

  if (activeMode === MODE_TYPES.EDIT) {
    modalfooterButtons = [
      <Button
        key="update"
        type="primary"
        onClick={handleEditDiscover}
        disabled={isDisabled}
      >
        {isDiscoverUpdating ? 'Loading...' : 'Update Discover'}
      </Button>,
      <Button key="close" onClick={handleOnCloseModal} disabled={isDisabled}>
        Close
      </Button>,
    ];
  }
  let getModalTitle = modalTitle;
  if (activeMode === MODE_TYPES.ADD) {
    getModalTitle = 'Add Discover';
  }
  if (activeMode === MODE_TYPES.EDIT) {
    getModalTitle = 'Edit Discover';
  }
  return (
    <div>
      <DiscoverModalBox>
        <Modal
          title={getModalTitle}
          visible={Boolean(activeMode)}
          onOk={handleOk}
          onCancel={handleOnCloseModal}
          className="expense-modal"
          maskClosable={false}
          width={550}
          forceRender={true}
          footer={modalfooterButtons}
        >
          <Form form={form} layout="vertical">
            <Row gutter={16}>
              <Col className="gutter-row" xs={24} sm={8}>
                {getImageUrl ? (
                  <PreviewImageBox>
                    <img src={getImageUrl} alt="logo" />
                    <div className="icons-div">
                      <div className="icons">
                        <div>
                          <EyeOutlined
                            onClick={() => {
                              handlePreviewLogoImg({ isVisible: true });
                            }}
                          />
                        </div>
                        <div>
                          <DeleteOutlined onClick={handleLogoImgDelete} />
                        </div>
                      </div>
                    </div>
                    <Modal
                      visible={isPreviewLogoImg}
                      title={'Preview'}
                      footer={null}
                      onCancel={() =>
                        handlePreviewLogoImg({ isVisible: false })
                      }
                    >
                      <img
                        alt="logo"
                        style={{ width: '100%' }}
                        src={getImageUrl}
                      />
                    </Modal>
                  </PreviewImageBox>
                ) : (
                  <UploadImageBox htmlFor="imageUrl">
                    <div className={`upload-image `}>
                      <>
                        <div className="icon">
                          <PlusOutlined />
                        </div>
                        <div>Upload Art Work</div>
                        <div>
                          <Text type="secondary">{`Max file size: ${DISCOVER_IMAGE_DIMENSIONS.FILE_SIZE} KB`}</Text>
                        </div>
                        <div>
                          <Text type="secondary">{`Resolution: ${DISCOVER_IMAGE_DIMENSIONS.FILE_WIDTH} X ${DISCOVER_IMAGE_DIMENSIONS.FILE_HEIGHT}`}</Text>
                        </div>
                      </>
                      {/* )} */}
                    </div>
                    <input
                      id="imageUrl"
                      type="file"
                      name="imageUrl"
                      onChange={handleLogoImgUpload}
                      style={{ visibility: 'hidden' }}
                    />
                  </UploadImageBox>
                )}
              </Col>
              <Col className="gutter-row" xs={24} sm={16}>
                <Form.Item
                  name="name"
                  label="Name (English)"
                  validateTrigger={handleValidateFieldNames('name')}
                  rules={[
                    {
                      required: true,
                      message: 'Please enter name!',
                    },
                  ]}
                >
                  <Input
                    type="text"
                    placeholder="Name (English)"
                    onBlur={() =>
                      setValidateFieldsName([...validateFieldsName, 'name'])
                    }
                  />
                </Form.Item>
                <Form.Item
                  name="ar_name"
                  label="Name (Arabic)"
                  validateTrigger={handleValidateFieldNames('ar_name')}
                  rules={[
                    {
                      required: true,
                      message: 'Please enter name!',
                    },
                  ]}
                >
                  <Input
                    type="text"
                    placeholder="Name (Arabic)"
                    onBlur={() =>
                      setValidateFieldsName([...validateFieldsName, 'ar_name'])
                    }
                  />
                </Form.Item>
              </Col>
              <Col className="gutter-row" xs={24}>
                <Form.Item
                  name="description"
                  label="Description (English)"
                  validateTrigger={handleValidateFieldNames('description')}
                  rules={[
                    {
                      required: true,
                      message: 'Please enter description!',
                    },
                  ]}
                >
                  <TextArea
                    rows={4}
                    placeholder="Description (English)"
                    onBlur={() =>
                      setValidateFieldsName([
                        ...validateFieldsName,
                        'description',
                      ])
                    }
                  />
                </Form.Item>
                <Form.Item
                  name="ar_description"
                  label="Description (Arabic)"
                  validateTrigger={handleValidateFieldNames('ar_description')}
                  rules={[
                    {
                      required: true,
                      message: 'Please enter description!',
                    },
                  ]}
                >
                  <TextArea
                    rows={4}
                    placeholder="Description (Arabic)"
                    onBlur={() =>
                      setValidateFieldsName([
                        ...validateFieldsName,
                        'ar_description',
                      ])
                    }
                  />
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </Modal>
      </DiscoverModalBox>
    </div>
  );
};

DiscoverModal.propTypes = {
  activeMode: string,
  callback: func,
  closeModal: func,
  discoverId: string,
  handleOk: func,
  modalTitle: string,
};

export default DiscoverModal;
