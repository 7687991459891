import React, { useRef, useState } from 'react';
import { string, object, func } from 'prop-types';
import { Modal } from 'antd';
import ReactPlayer from 'react-player';
import { MEDIA_TYPES } from 'constants/campaign';
// import './_modal.css';
import { PreviewImageBox } from './MediaPreview.styled';
import ReactAudioPlayer from 'react-audio-player';

const MediaPreview = (props) => {
  const {
    modalViewType,
    fileUrl,
    isModalOpen,
    setIsModalOpen,
    audioPlayerRef,
    handleOk,
    // handleCancel,
    selectMediaImageObject,
    selectMediaVideoObject,
    selectMediaImage,
    selectMediaVideo
  } = props;

  // const [audioPlaying, setAudioPlaying] = useState(false);

const handleCancel=()=>{
  if(modalViewType=='Audio'){
    audioPlayerRef.current.audioEl.current.pause();
  }

setIsModalOpen(false)
}

console.log('====================================');
console.log(fileUrl, 'url');
console.log('====================================');

  return (
    // <PreviewImageBox>
      <Modal
        title="Media Preview"
        visible={isModalOpen}
        // onOk={handleOk}
        onCancel={handleCancel}
        // width="100%"
        footer={false}
      >
        {/* {modalViewType === MEDIA_TYPES.VIDEO &&
        
        selectMediaVideo && (
            <div className="video">
              <ReactPlayer
                url={selectMediaVideo}
                width="100%"
                height="100%"
                playing
                controls
              />
            </div>
          )} */}
        {modalViewType === 'Image' && fileUrl && (
          <div className="lead-image" style={{ textAlign: 'center' }}>
            <img src={fileUrl} alt="img" style={{ width: '100%' }} />
          </div>
        )}
        {
          modalViewType=== "Audio" && fileUrl && (
            <div style={{display:'flex', justifyContent:"center"}} >
             <ReactAudioPlayer 
  src={fileUrl}
  autoPlay
  controls
  ref={audioPlayerRef} 
/> 
            </div>
          )
        }
      </Modal>
    // </PreviewImageBox>
  );
};

// Preview.defaultProps = {
//   handleCancel: () => {},
//   handleOk: () => {},
//   modalViewType: '',
//   selectMediaImageObject: {},
//   selectMediaVideoObject: {},
// };

// Preview.propTypes = {
//   handleCancel: func,
//   handleOk: func,
//   modalViewType: string,
//   selectMediaImageObject: object,
//   selectMediaVideoObject: object,
// };

export default MediaPreview;
