import React, { useState, useEffect } from 'react';
import {
  Button,
  // Col,
  Form,
  // Input,
  // Row,
  // Select,
  Spin,
  Table,
  Tooltip,
} from 'antd';
// import { MODE_TYPES } from 'constants/common';
import { DeviceStatusListBox, Loader } from './_devices-rating.styled';
// import { debounce } from 'lodash';

import { fireStoreDb } from '../../configs/firebase';
import moment from 'moment';
import {
  FrownOutlined,
  LoadingOutlined,
  MehOutlined,
  SmileOutlined,
} from '@ant-design/icons';
import { CSVLink } from 'react-csv';

// const { Option } = Select;

// const ratingsOptions = (
//   <>
//     <Option key={'sad'} value={'SAD'}>
//       {'Sad'}
//     </Option>
//     <Option key={'normal'} value={'NORMAL'}>
//       {'Normal'}
//     </Option>
//     <Option key={'good'} value={'GOOD'}>
//       {'Good'}
//     </Option>
//   </>
// );

const DevicesRating = () => {
  const ref = React.useRef(null);
  const [devicesRatingList, setDevicesRatingList] = useState([]);
  // const [, setDeviceStatusList] = useState([]);
  const [isFetching, setIsFetching] = useState(true);
  const [lastQuerySnapShot, setLastQuerySnapShot] = useState({});
  const [firstQuerySnapShot, setFirstQuerySnapShot] = useState({});
  const [isFetchingCSV, setIsFetchingCSV] = useState(false);
  const [CSVDownload, setCSVDownload] = useState([]);
  const initialPagination = {
    current: 1,
    pageSize: 100,
    total: 100,
  };
  const [paginationParams, setPaginationParams] = useState(initialPagination);
  const filterParamsData = {
    deviceSerial: '',
    ratings: '',
  };
  const [filterParams] = useState(filterParamsData);

  useEffect(() => {
    fetchDevicesRatingList();
  }, [filterParams]);

  // const handleNumberSearch = (fieldName, value) => {
  //   setpaginationParams({
  //     ...paginationParams,
  //     [fieldName]: value,
  //   });
  //   refreshList.current = true;
  // };

  // const handleSelectSearch = (selectedValue) => {
  //   if (selectedValue) {
  //     setFilterParams({
  //       ...filterParams,
  //       ratings: selectedValue,
  //     });
  //   } else if (filterParams.searchValue['ratings'] !== undefined) {
  //     setFilterParams({
  //       ...filterParams,
  //       ratings: '',
  //     });
  //   }
  // };

  const fetchDevicesRatingList = (nextPage = 1) => {
    setIsFetching(true);
    const appRatingsData = [];

    let appRatingsRef = fireStoreDb
      .collection('app_ratings')
      .orderBy('time', 'desc')
      .limit(paginationParams.pageSize);

    const fetchNextPage = nextPage >= paginationParams.current;

    if (Object.keys(lastQuerySnapShot).length >= 1) {
      if (fetchNextPage) {
        appRatingsRef = fireStoreDb
          .collection('app_ratings')
          .limit(paginationParams.pageSize)
          .orderBy('time', 'desc')
          .startAfter(lastQuerySnapShot);
      } else {
        appRatingsRef = fireStoreDb
          .collection('app_ratings')
          .limitToLast(paginationParams.pageSize)
          .orderBy('time', 'desc')
          .endBefore(firstQuerySnapShot);
      }
    }

    if (filterParams.deviceSerial) {
      appRatingsRef = appRatingsRef.where(
        'device_serial',
        '==',
        filterParams.deviceSerial
      );
    }
    if (filterParams.ratings) {
      appRatingsRef = appRatingsRef.where(
        'ratings',
        '==',
        filterParams.ratings
      );
    }

    appRatingsRef
      .get()
      .then((querySnapshot) => {
        // // Get the last visible document
        var lastVisible = querySnapshot.docs[querySnapshot.docs.length - 1];
        var firstVisible = querySnapshot.docs[0];

        querySnapshot.forEach((doc) => {
          appRatingsData.push({
            ...doc.data(),
            key: doc.id,
            dateTime: moment
              .unix(doc.data().time?.seconds)
              .format('DD MMMM YYYY hh:mm A'),
          });
        });

        setPaginationParams({
          ...paginationParams,
          total: fetchNextPage
            ? paginationParams.total + appRatingsData.length
            : paginationParams.total - appRatingsData.length,
          current: nextPage,
        });

        setDevicesRatingList(appRatingsData);
        setLastQuerySnapShot(lastVisible);
        setFirstQuerySnapShot(firstVisible);
        setIsFetching(false);
      })
      .catch((error) => {
        setIsFetching(false);
        console.log('Error getting documents: ', error);
      });
  };

  const columns = [
    {
      title: ENROLL_LABELS.SR_NO,
      width: 80,
      render: (value, item, index) =>
        (paginationParams.current - 1) * paginationParams.pageSize + index + 1,
    },
    {
      key: 'device_serial',
      title: ENROLL_LABELS.DEVICE_SERIAL,
      dataIndex: 'device_serial',
      fixed: 'left',
    },
    {
      key: 'sad_reason',
      title: ENROLL_LABELS.RATING,
      dataIndex: 'sad_reason',
      align: 'center',
      render: (data, item) => {
        return (function Actions() {
          return (
            <>
              <Tooltip title={item?.sad_reason ? item?.sad_reason : undefined}>
                {item.rating === 'HAPPY' && (
                  <SmileOutlined
                    style={{ fontSize: '24px', color: '#25B864' }}
                  />
                )}
                {item.rating === 'NORMAL' && (
                  <MehOutlined style={{ fontSize: '24px', color: '#FAAD14' }} />
                )}
                {item.rating === 'SAD' && (
                  <FrownOutlined
                    style={{ fontSize: '24px', color: '#FF4D4F' }}
                  />
                )}
              </Tooltip>
            </>
          );
        })();
      },
    },
    {
      key: 'dateTime',
      title: ENROLL_LABELS.TIME,
      dataIndex: 'dateTime',
    },
  ];

  const handlePaginationChange = (pagination, filters, sorter) => {
    fetchDevicesRatingList(pagination.current || 1);
  };

  const handleExport = async ({
    deviceSerial = filterParams?.deviceSerial,
    sortField = filterParams?.sortField,
    sortOrder = filterParams?.sortOrder,
  } = {}) => {
    handleCSVData([]);
    setIsFetchingCSV(true);

    const appRatingsData = [];

    let appRatingsRef = fireStoreDb
      .collection('app_ratings')
      .orderBy('time', 'desc');

    appRatingsRef
      .get()
      .then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          appRatingsData.push({
            ...doc.data(),
            key: doc.id,
            dateTime: moment
              .unix(doc.data().time?.seconds)
              .format('DD MMMM YYYY hh:mm A'),
          });
        });
        handleCSVData(appRatingsData || []);
        setIsFetchingCSV(false);
        ref.current.click();
      })
      .catch((error) => {
        setIsFetchingCSV(false);
        console.log('Error export CSV: ', error);
      });
  };

  const handleCSVData = (devicesData) => {
    let csvData = [];
    devicesData.forEach((data) => {
      const excelStructureData = {
        [ENROLL_LABELS.DEVICE_SERIAL]: data?.device_serial || '',
        [ENROLL_LABELS.RATING]: data?.rating || '',
        [ENROLL_LABELS.REASON]: data?.sad_reason || '',
        [ENROLL_LABELS.TIME]: data?.dateTime,
      };
      csvData.push(excelStructureData);
    });
    setCSVDownload(csvData);
  };

  // const handleTextSearch = (e) => {
  //   setFilterParams({
  //     ...filterParams,
  //     [e.target.name]: e.target.value,
  //   });
  // };

  return (
    <DeviceStatusListBox>
      <div className="table-header">
        <h3 className="table-title">Devices Ratings</h3>
        <div className="table-right-side">
          <div className="export-generate-btn">
            <Form.Item>
              <CSVLink
                data={CSVDownload}
                headers={headers}
                filename={'device-ratings.csv'}
              >
                <span ref={ref}></span>
              </CSVLink>
              {isFetchingCSV ? (
                <div className="file-export-loading">
                  <Spin
                    indicator={
                      <LoadingOutlined style={{ fontSize: 18 }} spin />
                    }
                  />
                  <div>Data Fetching...</div>
                </div>
              ) : (
                <Button type="primary" onClick={handleExport}>
                  Export To Excel
                </Button>
              )}
            </Form.Item>
          </div>
        </div>
      </div>
      {/* <div className="while-bg-box">
        <Form className="main-filter-form">
          <Row gutter={[16, 16]}>
            <Col md={8}>
              <Form.Item name="deviceSerial" style={{ marginBottom: '0' }}>
                <Input
                  type="text"
                  name="deviceSerial"
                  onChange={debounce((event) => handleTextSearch(event))}
                  placeholder="Search Device Serial"
                />
              </Form.Item>
            </Col>
            <Col md={8}>
              <Form.Item name="ratings" style={{ marginBottom: '0' }}>
                <Select
                  showSearch
                  allowClear
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    option.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                  placeholder="Select Ratings"
                  onChange={handleSelectSearch}
                >
                  {ratingsOptions}
                </Select>
              </Form.Item>
            </Col>
            <Col md={6} align="end" justify="end">
              <Form.Item>
                <CSVLink
                  data={CSVDownload}
                  headers={headers}
                  filename={'device-ratings.csv'}
                >
                  <span ref={ref}></span>
                </CSVLink>
                {isFetchingCSV ? (
                  <div className="file-export-loading">
                    <Spin
                      indicator={
                        <LoadingOutlined style={{ fontSize: 18 }} spin />
                      }
                    />
                    <div>Data Fetching...</div>
                  </div>
                ) : (
                  <Button type="primary" onClick={handleExport}>
                    Export To Excel
                  </Button>
                )}
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </div> */}
      <div className="main-table-body">
        <Table
          rowClassName={(record, index) =>
            record.battery_percent <= 6 ? 'battery-low' : 'battery-normal'
          }
          scroll={{ x: 1200 }}
          loading={{
            indicator: <Loader>Fetching...</Loader>,
            spinning: isFetching,
          }}
          columns={columns}
          dataSource={devicesRatingList || []}
          // dataSource={[]}
          size="small"
          onChange={handlePaginationChange}
          pagination={{
            showSizeChanger: false,
            // simple: true,
            defaultPageSize: paginationParams.pageSize,
            total: paginationParams.total,
            current: paginationParams.current,
          }}
        />
      </div>
    </DeviceStatusListBox>
  );
};

export default DevicesRating;

const ENROLL_LABELS = {
  SR_NO: 'Sr. No.',
  DEVICE_SERIAL: 'Device Serial',
  RATING: 'Rating',
  REASON: 'Reason',
  TIME: 'Time',
};

const headers = [
  { label: ENROLL_LABELS.DEVICE_SERIAL, key: ENROLL_LABELS.DEVICE_SERIAL },
  { label: ENROLL_LABELS.RATING, key: ENROLL_LABELS.RATING },
  { label: ENROLL_LABELS.REASON, key: ENROLL_LABELS.REASON },
  { label: ENROLL_LABELS.TIME, key: ENROLL_LABELS.TIME },
];
