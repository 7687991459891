import React from 'react';
import { bool, object } from 'prop-types';
import { Row, Col, Collapse, Skeleton, Progress } from 'antd';
import { formatDate } from 'utils';
import { CollapseBox } from './_device-info.styled';

const { Panel } = Collapse;

const DeviceLocation = (props) => {
  const { deviceDetailsFB, isFetching } = props;

  const renderLableColumnRows = (
    label,
    value,
    isProgressBar,
    rowGutter = 18,
    lableColSpacing = 6,
    valueColSpacing = 18
  ) => {
    return (
      <Row gutter={rowGutter} style={{ margin: '15px' }}>
        <Col className="gutter-row" xs={lableColSpacing}>
          <strong>{label}</strong>
        </Col>
        <Col className="gutter-row" xs={valueColSpacing}>
          <Skeleton
            loading={isFetching}
            active
            paragraph={{ rows: 1, width: '100%' }}
            title={false}
          >
            {isProgressBar ? <Progress percent={value} /> : value}
          </Skeleton>
        </Col>
      </Row>
    );
  };

  return (
    <CollapseBox>
      <Collapse defaultActiveKey={['1']}>
        <Panel header="Device Info" key="1">
          {renderLableColumnRows(
            'App Language: ',
            deviceDetailsFB?.app_language
          )}
          {renderLableColumnRows('App Version: ', deviceDetailsFB?.app_version)}
          {renderLableColumnRows(
            'Battery: ',
            deviceDetailsFB?.battery_percent,
            true
          )}
          {renderLableColumnRows(
            'Brightness: ',
            deviceDetailsFB?.brightness_level,
            true
          )}
          {renderLableColumnRows(
            'Volume Level: ',
            deviceDetailsFB?.volume_level,
            true
          )}
          {renderLableColumnRows(
            'Last Charged At: ',
            formatDate(deviceDetailsFB?.lastChargedAt, 'Do MMMM YYYY hh:mm A')
          )}
          {renderLableColumnRows('Ride Status: ', deviceDetailsFB?.ride_status)}
          {renderLableColumnRows('Sim Serial: ', deviceDetailsFB?.sim_serial)}
          {renderLableColumnRows(
            'Last Updated: ',
            formatDate(deviceDetailsFB?.updated_at, 'Do MMMM YYYY hh:mm A')
          )}
        </Panel>
      </Collapse>
    </CollapseBox>
  );
};

DeviceLocation.propTypes = {
  deviceDetailsFB: object,
  isFetching: bool,
};

export default DeviceLocation;
