import React, { useState, useEffect } from 'react';
import {
  Dropdown,
  Menu,
  Skeleton,
  Button,
  Row,
  Col,
  Popconfirm,
  Card,
  Form,
  Badge,
  Tag,
  Switch,
} from 'antd';
import { useParams, useHistory } from 'react-router-dom';
import {
  DeviceDetailPageBox,
  // Loader,
} from './_device-detail-page.styled';
import {
  // FormOutlined,
  WifiOutlined,
  MobileOutlined,
  AudioOutlined,
  AudioMutedOutlined,
  UsbOutlined,
  UnlockOutlined,
  LockOutlined,
  BulbOutlined,
  SoundOutlined,
  DownOutlined,
} from '@ant-design/icons';
import {
  formatDate,
  showErrorMsg,
  // httpPut,
  showSuccessMsg,
  httpGet,
  URLS_V2,
  calcMinsFromCurrentDateTime,
  httpPut,
  returnStringToSentenceCase,
  updateFirebaseCollectionTimestamp,
  convertUnixToDateFormat,
  httpPost,
} from 'utils';
import { firebaseDb } from 'configs/firebase';
import {
  DEVICE_BULK_ACTION_LIST_V2,
  DEVICE_V2_TIMESTAMP_FB_COLLECTION_NAME,
  ONLINE_OFFLINE_THRESHOLD,
} from 'constants/devices';
import DeviceInfo from './device-info';
import DeviceLocation from './device-location';
import RidesInfo from './rides-info';
import StatisticCardBlock from 'components/statistic-card-block';
import DeviceRides from './device-rides';
import CommonBreadCrumb from 'components/common-breadcrumb';
import BinaryOsInfo from './binary-os-info';
import moment from 'moment';

const DeviceDetailPage = () => {
  const params = useParams();
  const [rideInfoForm] = Form.useForm();

  const [deviceRideList, setDeviceRideList] = useState([]);
  const [deviceDetailsFB, setDeviceDetailsFB] = useState({});
  const [deviceLocationDetailsFB, setDeviceLocationDetailsFB] = useState({});
  const [deviceDetails, setDeviceDetails] = useState({});
  const [deviceRideFilters, setDeviceRideFilters] = useState({
    rideRange: 'today',
  });
  const [isFetchingFB, setIsFetchingFB] = useState(true);
  const [isFetching, setIsFetching] = useState(true);
  const [isFetchingDeviceRides, setIsFetchingDeviceRides] = useState(true);
  const [isRideInfoFormSubmitting, setIsRideInfoFormSubmitting] = useState(false);
  const [rideInfoInlineEdit, setRideInfoInlineEdit] = useState(false);
  const [deviceSerial, setDeviceSerial] = useState('');
  const [totalDeviceRides, setTotalDeviceRides] = useState(0);
  const [deviceRideStats,setDeviceRideStats]=useState({})

  let history = useHistory();

  useEffect(() => {
    if (!params?.device_serial) {
      // redirect to the back page from here
      handleHistoryToGoBack();
    } else {
      const deviceSerialNo = params?.device_serial;
      setDeviceSerial(deviceSerialNo);
      fetchDeviceAndRideDetails(deviceSerialNo);
      fetchDeviceLocationRealTime(params?.device_serial);
      fetchDeviceDetailsRealTime(params?.device_serial);
      fetchDeviceRides();
      fetchDeviceRidesTotal(params?.device_serial);
    }
  }, []);

  useEffect(() => {
    fetchDeviceRides();
    fetchDeviceRidesTotal(params?.device_serial);
  }, [deviceRideFilters]);



  const breakCrumbList = [
    { label: 'Home', link: '/dashboard' },
    { label: 'Devices', link: '/v2/devices' },
    { label: deviceSerial || '' },
  ];

  useEffect(() => {
    const deviceSerialNo = params?.device_serial
    const url = `${URLS_V2?.RIDES?.RIDES_STATISTICS_RIDESTATS}?deviceSerial=${deviceSerialNo}`;
    httpGet(url).then((res) => {
      if(res?.status===200){
        setDeviceRideStats(res?.data)
      }
    }).catch((err)=>{

    })
  }, [])

  const fetchDeviceAndRideDetails = (deviceSerialNo) => {
    setIsFetching(true);
    const url = `${URLS_V2?.DEVICES?.GET_DEVICE_DETAILS}?device_serial=${deviceSerialNo}`;
    httpGet(url)
      .then((res) => {
        if (res?.status === 200) {
          if (res?.data) {
            setDeviceDetails(res?.data || []);
          }
        } else if (res?.status === 404) {
          alert();
          handleHistoryToGoBack();
        }
        setIsFetching(false);
      })
      .catch((err) => {
        setIsFetching(false);
      });
  };

  const fetchDeviceLocationRealTime = (deviceSerialNo) => {
    setIsFetchingFB(true);
    const deviceLocationRef = firebaseDb.ref('device_location_updates');
    const deviceNodeRef = deviceLocationRef.child(deviceSerialNo);


    deviceNodeRef.on('value', (response) => {
      const firebaseDeviceResp = response.val();

      if (!response.exists() || !firebaseDeviceResp) {
        setIsFetchingFB(false);
        showErrorMsg('Device locations not found');
        return;
      }
      let tempDeviceDetails = {};

      const { latitude, longitude, updated_at } = firebaseDeviceResp || {};
      tempDeviceDetails = {
        ...firebaseDeviceResp,
        current_lat: latitude,
        current_lng: longitude,
        updatedAt: updated_at ? convertUnixToDateFormat(updated_at) : '',
      };
      setDeviceLocationDetailsFB(tempDeviceDetails);
      setTimeout(() => {
        setIsFetchingFB(false);
      }, 1000);
    });
  };

  const fetchDeviceDetailsRealTime = (deviceSerialNo) => {
    setIsFetchingFB(true);
    const deviceStatusColRef = firebaseDb.ref('device_status');
    const deviceNodeRef = deviceStatusColRef.child(deviceSerialNo);

    deviceNodeRef.on('value', (response) => {
      const firebaseDeviceResp = response.val();
      if (!response.exists() || !firebaseDeviceResp) {
        setIsFetchingFB(false);
        showErrorMsg('Device details not found');
        return;
      }
      let tempDeviceDetails = {};

      tempDeviceDetails = {
        ...firebaseDeviceResp,
        ...deviceDetailsFB,
        key: deviceSerialNo,
        ...(firebaseDeviceResp?.battery_percentage !== undefined && {
          batteryPercent: batteryIconRender(
            firebaseDeviceResp?.battery_percentage.toString()
          ),
        }),
        ...(firebaseDeviceResp?.brightness_percentage !== undefined && {
          brightnessLevel: brightnessIconRender(
            firebaseDeviceResp?.brightness_percentage.toString()
          ),
        }),
        ...(firebaseDeviceResp?.volume_percentage !== undefined && {
          volumeLevel: volumeIconRender(
            firebaseDeviceResp?.volume_percentage.toString()
          ),
        }),
        ...(firebaseDeviceResp?.is_charging !== undefined && {
          isCharging: chargingIconRender(
            firebaseDeviceResp?.is_charging,
            firebaseDeviceResp?.battery_percentage.toString()
          ),
        }),
        ...(firebaseDeviceResp?.is_mute !== undefined && {
          isMute: muteIconRender(firebaseDeviceResp?.is_mute),
        }),
        ...(firebaseDeviceResp?.is_screen_on !== undefined && {
          isScreenOn: screenOnIconRender(firebaseDeviceResp?.is_screen_on),
        }),
        ...(firebaseDeviceResp?.is_sleep !== undefined && {
          isSleep: sleepIconRender(firebaseDeviceResp?.is_sleep),
        }),
        ...(firebaseDeviceResp?.is_wifi_on !== undefined && {
          isWiFiOn: wifiIconRender(
            firebaseDeviceResp?.is_wifi_on,
            firebaseDeviceResp?.ride_wifi_trigger
          ),
        }),
        ...(firebaseDeviceResp?.last_charging_status_change !== undefined && {
          lastChargedAt: formatDate(
            firebaseDeviceResp?.last_charging_status_change
          ),
        }),
      };
      setDeviceDetailsFB(tempDeviceDetails);
      setTimeout(() => {
        setIsFetchingFB(false);
      }, 1000);
    });
  };

  const fetchDeviceRides = ({
    skip = 0,
    limit = 100,
    sortField = 'start_time',
    sortOrder = -1,
    deviceSerialNo = params?.device_serial,
  } = {}) => {
    setIsFetchingDeviceRides(true);

    const queryParams = new URLSearchParams();
    queryParams.append('skip', skip);
    queryParams.append('limit', limit);
    queryParams.append('order_by', sortField);
    queryParams.append('order', sortOrder === 'ascend' ? 1 : -1);
    queryParams.append('device_serial', deviceSerialNo);

    if (deviceRideFilters?.rideRange)
      queryParams.append('range', deviceRideFilters?.rideRange);

    const url = `${URLS_V2?.RIDES?.RIDES_LIST}/?${queryParams.toString()}`;
    httpGet(url)
      .then((res) => {
        if (res?.status === 200) {
          if (res?.data) {
            const ridesData =
              (res?.data &&
                res?.data.map((doc) => {
                  const formattedStartTime = formatDate(
                    doc.start_time,
                    'DD-MM-YYYY HH:mm:ss'
                  );

                  const formattedEndTime = formatDate(
                    doc.stop_time,
                    'DD-MM-YYYY HH:mm:ss'
                  );
                  return {
                    ...doc,
                    key: doc._id,
                    startTime: formattedStartTime,
                    stopTime: formattedEndTime,
                    docId: doc._id,
                  };
                })) ||
              [];
            setDeviceRideList(ridesData);
          }
        } else if (res?.status === 400 || res?.status === 401) {
          showErrorMsg(res?.message || res?.error);
        }
        setIsFetchingDeviceRides(false);
      })
      .catch((err) => {
        setIsFetchingDeviceRides(false);
      });
  };

  const fetchDeviceRidesTotal = (deviceSerialNo) => {
    setIsFetchingDeviceRides(true);
    const queryParams = new URLSearchParams();
    queryParams.append('device_serial', deviceSerialNo);

    if (deviceRideFilters?.rideRange)
      queryParams.append('range', deviceRideFilters?.rideRange);

    const url = `${URLS_V2?.RIDES?.RIDES_LIST_COUNT
      }/?${queryParams.toString()}`;

    httpGet(url)
      .then((res) => {
        setTotalDeviceRides(res?.data?.ridesCount || 0);
        setIsFetchingDeviceRides(false);
      })
      .catch((err) => {
        setIsFetchingDeviceRides(false);
      });
  };

  const handlePaginationChange = (pagination, filters, sorter) => {
    const skip = pagination.current * pagination.pageSize - pagination.pageSize;
    const limit = pagination.pageSize;
    const sortField = sorter.field;
    const sortOrder = sorter.order;
    fetchDeviceRides({
      skip: skip,
      limit: limit,
      sortField: sortField,
      sortOrder: sortOrder,
      deviceSerialNo: params?.device_serial,
    });
  };

  const handleSelectSearch = (selectedValue) => {
    setDeviceRideFilters({
      rideRange: selectedValue,
    });
  };

  const handleHistoryToGoBack = () => {
    // redirect to the back page from here
    showErrorMsg('Device details not found');
    history.goBack();
  };

  const handleDeviceStatus = (checked, device_serial) => {
    const url = URLS_V2?.DEVICES?.BULK_ACTION_STATUS_CHANGE;
    const data = {
      devices: [device_serial],
      status: checked,
    };
    httpPut(url, data)
      .then((res) => {
        if (res.status === 200) {
          showSuccessMsg('Device status updated successfully!');
          setDeviceDetails({
            ...deviceDetails,
            is_device_active: checked,
          });
        } else if (res?.error) {
          showErrorMsg(res?.error);
        }
        setIsFetching(false);
      })
      .catch((err) => {
        showErrorMsg(err);
        setIsFetching(false);
      });
  };

  const muteIconRender = (value) => {
    return (
      <>
        {value ? (
          <>
            <AudioMutedOutlined
              style={{
                fontSize: '18px',
                color: '#ff4d4f',
                marginRight: '0.5rem',
              }}
            />
            {`Mute`}
          </>
        ) : (
          <>
            <AudioOutlined
              style={{
                fontSize: '18px',
                color: '#52c41a',
                marginRight: '0.5rem',
              }}
            />
            {`Unmute`}
          </>
        )}
      </>
    );
  };

  const wifiIconRender = (value, wifiName) => {
    return (
      <>
        <WifiOutlined
          style={{
            fontSize: '18px',
            color: value ? '#52c41a' : '#ff4d4f',
            marginRight: '0.5rem',
          }}
        />
        {value ? wifiName : 'Off'}
      </>
    );
  };

  // const activeInactiveIconRender = (value) => {
  //   return (
  //     <>
  //       {value ? (
  //         <Tag color="success">Active</Tag>
  //       ) : (
  //         <Tag color="error">Inactive</Tag>
  //       )}
  //     </>
  //   );
  // };

  // const bluetoothIconRender = (value) => {
  //   return (
  //     <>
  //       <img src={BlueToothIcon} />
  //     </>
  //   );
  // };

  const chargingIconRender = (value, relatedValue = null) => {
    return (
      <>
        <UsbOutlined
          style={{
            fontSize: '18px',
            color: value ? '#52c41a' : '#ff4d4f',
            marginRight: '0.5rem',
          }}
        />
        {value ? 'Charging' : 'Not Charging'}
        <span className="m-l-5">
          {relatedValue &&
            `(${relatedValue?.includes('%') ? relatedValue : relatedValue + '%'
            })`}
        </span>
      </>
    );
  };
  const sleepIconRender = (value) => {
    return (
      <>
        <MobileOutlined
          style={{
            fontSize: '18px',
            color: value ? '#ff4d4f' : '#52c41a',
            marginRight: '0.5rem',
          }}
        />
        {value ? 'Sleep' : 'Working'}
      </>
    );
  };

  const screenOnIconRender = (value) => {
    return (
      <>
        {value ? (
          <>
            <UnlockOutlined
              style={{
                fontSize: '18px',
                color: value ? '#52c41a' : '#ff4d4f',
                marginRight: '0.5rem',
              }}
            />
            {'Unlocked'}
          </>
        ) : (
          <>
            <LockOutlined
              style={{
                fontSize: '18px',
                color: value ? '#52c41a' : '#ff4d4f',
                marginRight: '0.5rem',
              }}
            />
            {'Locked'}
          </>
        )}
      </>
    );
  };

  const batteryIconRender = (value) => {
    return (
      <>
        <UsbOutlined
          style={{
            fontSize: '1rem',
            color: '#faad14',
            marginRight: '0.5rem',
          }}
        />
        {`${value?.includes('%') ? value : value + '%'}`}
      </>
    );
  };

  const brightnessIconRender = (value) => {
    return (
      <>
        <BulbOutlined
          style={{
            fontSize: '1rem',
            color: '#faad14',
            marginRight: '0.5rem',
          }}
        />
        {`${value >= 0 && value?.includes('%') ? value : value + '%'}`}
      </>
    );
  };

  const volumeIconRender = (value) => {
    return (
      <>
        <SoundOutlined
          style={{
            fontSize: '1rem',
            color: '#faad14',
            marginRight: '0.5rem',
          }}
        />
        {`${value >= 0 && value.includes('%') ? value : value + '%'}`}
      </>
    );
  };

  const handleRideInfoFormSubmit = () => {
    if (isRideInfoFormSubmitting) {
      return;
    }
    rideInfoForm.submit();
    rideInfoForm
      .validateFields()
      .then(async (values) => {
        setIsRideInfoFormSubmitting(true);
        setRideInfoInlineEdit(true);
        values = {
          ...values,
          morning_driver_name:
            (values?.morning_driver_name &&
              returnStringToSentenceCase(values?.morning_driver_name)) ||
            '',
          evening_driver_name:
            (values?.evening_driver_name &&
              returnStringToSentenceCase(values?.evening_driver_name)) ||
            '',
          device_serial: deviceSerial,
        };
        const url = URLS_V2?.DEVICES?.UPDATE_DEVICE;
        httpPut(url, values)
          .then((res) => {
            if (res?.status === 200) {
              setDeviceDetails({
                ...deviceDetails,
                taxi_number: res?.data?.taxi_number,
                morning_driver_mobile: res?.data?.morning_driver_mobile,
                morning_driver_name: res?.data?.morning_driver_name,
                evening_driver_name: res?.data?.evening_driver_name,
                evening_driver_mobile: res?.data?.evening_driver_mobile,
              });
              showSuccessMsg('Cab details updated successfully!');
              updateFirebaseCollectionTimestamp(
                DEVICE_V2_TIMESTAMP_FB_COLLECTION_NAME.DEVICE_DETAILS,
                deviceSerial
              );
            } else if (res?.status === 404) {
              showErrorMsg('Internal server error. Please try again later');
              handleHistoryToGoBack();
            }
            setIsRideInfoFormSubmitting(false);
            setRideInfoInlineEdit(false);
            rideInfoForm.resetFields();
          })
          .catch((err) => {
            setIsRideInfoFormSubmitting(false);
            setRideInfoInlineEdit(false);
            rideInfoForm.resetFields();
            showErrorMsg('Internal server error. Please try again later');
          });
      })
      .catch((errorInfo) => errorInfo);
  };

  // const renderStatisticDivBlock = (
  //   title,
  //   value,
  //   isEditable = false,
  //   command = ''
  // ) => {
  //   return (
  //     <>
  //       <h3>{title}</h3>
  //       <Skeleton
  //         loading={isFetchingFB}
  //         active
  //         paragraph={{ rows: 1, width: '100%' }}
  //         title={false}
  //       >
  //         {isEditable ? (
  //           <div
  //             style={{
  //               cursor: 'pointer',
  //             }}
  //           >
  //             <Popconfirm
  //               title={<>{`Are you sure you want to perform this action?`}</>}
  //               onConfirm={() => handleDeviceCommands(command)}
  //               key={value}
  //             >
  //               <Switch checked={value} />
  //             </Popconfirm>
  //           </div>
  //         ) : (
  //           <div>{value}</div>
  //         )}
  //       </Skeleton>
  //     </>
  //   );
  // };

  const menu = (
    <Menu>
      {DEVICE_BULK_ACTION_LIST_V2 &&
        DEVICE_BULK_ACTION_LIST_V2.length > 0 &&
        DEVICE_BULK_ACTION_LIST_V2.map((item) => (
          <Popconfirm
            title={<>{`Are you sure you want to perform this action?`}</>}
            onConfirm={() => handleDeviceCommands(item.command, item.value)}
            key={item.value}
          >
            <Menu.Item key={item.value} icon={React.createElement(item?.icon)}>
              {item.label}
            </Menu.Item>
          </Popconfirm>
        ))}
    </Menu>
  );

  const handleDeviceCommands = (command, value) => {
    const url = URLS_V2?.DEVICES?.DEVICE_COMMAND_UPDATE;
    const data = {
      device_serial: deviceSerial,
      command: command,
      value: value,
      is_implemented: false,
    };
    httpPost(url, data)
      .then((res) => {
        if (res.status === 200) {
          showSuccessMsg('Device status updated successfully!');
          //   if (value === 'inactive' || value === 'active') {
          //     updateFirebaseCollectionTimestamp(
          //       DEVICE_V2_TIMESTAMP_FB_COLLECTION_NAME.DEVICE_DETAILS,
          //       deviceSerial
          //     );
          //   }
          // } else if (res?.error) {
          //   showErrorMsg(res?.error);
          // }
        }
      })
      .catch((err) => {
        showErrorMsg(err);
      });
  };

  const renderRidesStatisticsCard = (
    cardTitle,
    totalRide,
    totalRideTime,
    totalRideAvgTime
  ) => {

    const durationRideAvgTime = moment.duration(totalRideAvgTime, 'minutes');
    const daysRideAvgTime = durationRideAvgTime.days();
    const hoursRideAvgTime = durationRideAvgTime.hours();
    const minutesRideAvgTime = durationRideAvgTime.minutes();
    const formattedTotalRideAvgTime = `${daysRideAvgTime}d : ${hoursRideAvgTime}h : ${minutesRideAvgTime}m`;

    
    const durationTotalRideTime = moment.duration(totalRideTime, 'minutes');
    const daysTotalRideTime = durationTotalRideTime.days();
    const hoursTotalRideTime = durationTotalRideTime.hours()
    const minutesTotalRideTime = durationTotalRideTime.minutes();
    const formattedTotalRideTime = `${daysTotalRideTime}d : ${hoursTotalRideTime}h : ${minutesTotalRideTime}m`;


    return (
      <div className='statistics-device-rides'>

      <Card title={cardTitle} bordered={false}>
        <Skeleton
          loading={isFetching}
          active
          paragraph={{ rows: 2, width: '100%' }}
          title={false}
        >
          <Row gutter={32}>
            <Col span={6}>
              <StatisticCardBlock
                title={'Total Rides'}
                value={(totalRide && totalRide.toString()) || '0'}
                valueStyle={{ color: '#3f8600' }}
              />
            </Col>
            <Col span={9}>
              <StatisticCardBlock
                title="Ride Time Min"
                value={formattedTotalRideTime}
                precision={0}
                valueStyle={{ color: '#3f8600' }}
              />
            </Col>
            <Col span={9}>
              <StatisticCardBlock
                title="Avg Day Min"
                value={formattedTotalRideAvgTime}
                precision={0}
                valueStyle={{ color: '#3f8600' }}
              />
            </Col>
          </Row>
        </Skeleton>
      </Card>
      </div>
    );
  };

  return (
    <DeviceDetailPageBox>
      <CommonBreadCrumb breadCrumbs={breakCrumbList} />
      <div className="table-header">
        <div>
          <h2 className="table-title m-b-0 d-d-title">
            {deviceLocationDetailsFB?.updatedAt &&
              calcMinsFromCurrentDateTime(deviceLocationDetailsFB?.updatedAt) <=
              ONLINE_OFFLINE_THRESHOLD ? (
              <Badge
                status="success"
                title="Online"
                className="details-badge"
              />
            ) : (
              <Badge status="error" title="Offline" className="details-badge" />
            )}
            {deviceSerial}
          </h2>
          <label className="m-l-20 table-title">
            {deviceDetailsFB?.sim_serial}
          </label>
        </div>
        <div className="table-right-side">
          <div className="export-generate-btn">
            <Switch
              style={{ marginRight: '12px' }}
              checked={deviceDetails.is_device_active}
              onChange={(checked) => handleDeviceStatus(checked, deviceSerial)}
            />

            {!isFetching && (
              <>
                {deviceDetailsFB?.is_ride_active ? (
                  <Tag className="m-r-10" color="success">
                    In Ride
                  </Tag>
                ) : (
                  <Tag className="m-r-10" color="error">
                    Idle
                  </Tag>
                )}
                {deviceLocationDetailsFB?.updatedAt &&
                  calcMinsFromCurrentDateTime(
                    deviceLocationDetailsFB?.updatedAt
                  ) <= ONLINE_OFFLINE_THRESHOLD ? (
                  <Tag className="m-r-10" color="success">
                    Online
                  </Tag>
                ) : (
                  <Tag className="m-r-10" color="error">
                    Offline
                  </Tag>
                )}
                {/* <span className="m-r-10" color="font-dark">
                  Active/Inactive:
                </span> */}
                {deviceDetailsFB.is_device_active ? (
                  <Tag className="m-r-10" color="success">
                    Active
                  </Tag>
                ) : (
                  <Tag className="m-r-10" color="error">
                    Inactive
                  </Tag>
                )}
                {/* <Popconfirm
                  title={<>{`Are you sure you want to perform this action?`}</>}
                  onConfirm={() =>
                    updateDeviceStatusInfo(deviceDetailsFB.is_device_active)
                  }
                  key={deviceDetailsFB.is_device_active}
                >
                  <Switch
                    checked={deviceDetailsFB.is_device_active}
                    className={`${
                      deviceDetailsFB.is_device_active
                        ? 'bg-success'
                        : 'bg-danger'
                    }`}
                    checkedChildren="Active"
                    unCheckedChildren="Inactive"
                  />
                </Popconfirm> */}
                <Dropdown overlay={menu} trigger={['click']}>
                  <Button style={{marginTop:"10px"}} >
                    {'Commands'} <DownOutlined />
                  </Button>
                </Dropdown>
              </>
            )}
          </div>
        </div>
      </div>
      <div className="site-statistic-demo-card c-m-10">
        <Row gutter={[14,14]}>
          <Col sm={24} md={12} lg={8}>
            {renderRidesStatisticsCard(
              'Last 12 Hours',
              deviceRideStats?.halfDay?.halfDay_Total_Ride,
              deviceRideStats?.halfDay?.halfDay_Ride_Time_seconds,
              deviceRideStats?.halfDay?.halfDay_Ride_TimeAvg_seconds
            )}
          </Col>
          <Col sm={24} md={12} lg={8}>
            {renderRidesStatisticsCard(
              'Last 24 Hours',
              deviceRideStats?.oneDay?.oneDay_Total_Ride,
              deviceRideStats?.oneDay?.oneDay_Ride_Time_seconds,
              deviceRideStats?.oneDay?.oneDay_Ride_TimeAvg_seconds,
            )}
          </Col>
          <Col sm={24} md={12} lg={8}>
            {renderRidesStatisticsCard(
              'Last 7 Days',
              deviceRideStats?.sevenDays?.sevenDays_Total_Ride,
              deviceRideStats?.sevenDays?.sevenDays_Ride_Time_seconds,
              deviceRideStats?.sevenDays?.sevenDays_Ride_TimeAvg_seconds,
            )}
          </Col>
          <Col sm={24} md={12} lg={8}>
            {renderRidesStatisticsCard(
              'Last 15 Days',
              deviceRideStats?.fifteenDaysResponse?.fifteenDays_Total_Ride,
              deviceRideStats?.fifteenDaysResponse?.fifteenDays_Ride_Time_seconds,
              deviceRideStats?.fifteenDaysResponse?.fifteenDays_Ride_TimeAvg_seconds,
            )}
          </Col>
          <Col sm={24} md={12} lg={8}>
            {renderRidesStatisticsCard(
              'Last 30 Days',
              deviceRideStats?.thirtyDays?.thirtyDays_Total_Ride,
              deviceRideStats?.thirtyDays?.thirtyDays_Ride_Time_seconds,
              deviceRideStats?.thirtyDays?.thirtyDays_Ride_TimeAvg_seconds,
            )}
          </Col>
          <Col sm={24} md={12} lg={8}>
            {renderRidesStatisticsCard(
              'All',
              deviceRideStats?.allRecordResponse?.allRecord_Total_Ride,
              deviceRideStats?.allRecordResponse?.allRecord_Ride_Time_seconds,
              deviceRideStats?.allRecordResponse?.allRecord_Ride_TimeAvg_seconds,
            )}
          </Col>
        </Row>
      </div>
      {/* <div className="site-statistic-demo-card c-m-10">
        <Row gutter={14}>
          <Col sm={24} md={12} lg={8}>
            {renderRidesStatisticsCard(
              'Last 15 Days',
              deviceRideStats?.fifteenDaysResponse?.fifteenDays_Total_Ride,
              deviceRideStats?.fifteenDaysResponse?.fifteenDays_Ride_Time_seconds,
              deviceRideStats?.fifteenDaysResponse?.fifteenDays_Ride_TimeAvg_seconds,
            )}
          </Col>
          <Col sm={24} md={12} lg={8}>
            {renderRidesStatisticsCard(
              'Last 30 Days',
              deviceRideStats?.thirtyDays?.thirtyDays_Total_Ride,
              deviceRideStats?.thirtyDays?.thirtyDays_Ride_Time_seconds,
              deviceRideStats?.thirtyDays?.thirtyDays_Ride_TimeAvg_seconds,
            )}
          </Col>
          <Col sm={24} md={12} lg={8}>
            {renderRidesStatisticsCard(
              'All',
              deviceRideStats?.allRecordResponse?.allRecord_Total_Ride,
              deviceRideStats?.allRecordResponse?.allRecord_Ride_Time_seconds,
              deviceRideStats?.allRecordResponse?.allRecord_Ride_TimeAvg_seconds,
            )}
          </Col>
        </Row>
      </div> */}

      {/* <div className="device-details-header">
        <div>
          {renderStatisticDivBlock('Battery', deviceDetailsFB?.batteryPercent)}
        </div>
        <div className="vr-solid" />
        <div>
          {renderStatisticDivBlock('Charging', deviceDetailsFB?.isCharging)}
        </div>
        <div className="vr-solid" />
        <div>
          {renderStatisticDivBlock('Screen', deviceDetailsFB?.isScreenOn)}
        </div>
        <div className="vr-solid" />
        <div>
          {renderStatisticDivBlock(
            'Brightness',
            deviceDetailsFB?.brightnessLevel
          )}
        </div>
        <div className="vr-solid" />
        <div>
          {renderStatisticDivBlock('Sleep', deviceDetailsFB?.is_sleep, true, {
            is_sleep: !deviceDetailsFB?.is_sleep,
          })}
        </div>
        <div className="vr-solid" />
        <div>
          {renderStatisticDivBlock('Mute', deviceDetailsFB?.is_mute, true, {
            is_mute: !deviceDetailsFB?.is_mute,
          })}
        </div>
        <div className="vr-solid" />
        <div>
          {renderStatisticDivBlock('WiFi', deviceDetailsFB?.is_wifi_on, true, {
            is_wifi_on: !deviceDetailsFB?.is_wifi_on,
          })}
        </div>
        <div className="vr-solid" />
        <div>
          {renderStatisticDivBlock(
            'Bluetooth',
            deviceDetailsFB?.isBlueToothOn,
            {
              is_bluetooth_on: !deviceDetailsFB?.is_bluetooth_on,
            }
          )}
        </div>
        <div className="vr-solid" />
        <div>
          {renderStatisticDivBlock('Status', deviceDetailsFB?.isDeviceActive, {
            is_device_active: !deviceDetailsFB?.is_device_active,
          })}
        </div>
      </div> */}
      <div>
        <Row gutter={16}>
          <Col className="gutter-row" xs={24} sm={24} lg={12} >
            <DeviceInfo
              handleDeviceCommands={handleDeviceCommands}
              deviceDetailsFB={deviceDetailsFB}
              deviceLocationDetails={deviceLocationDetailsFB}
              isFetching={isFetchingFB}
            />
            <BinaryOsInfo
              handleDeviceCommands={handleDeviceCommands}
              deviceDetailsFB={deviceDetailsFB}
              deviceLocationDetails={deviceLocationDetailsFB}
              isFetching={isFetchingFB}
            />
            <RidesInfo
              deviceDetails={deviceDetails}
              isFetching={isFetching}
              handleRideInfoFormSubmit={handleRideInfoFormSubmit}
              isRideInfoFormSubmitting={isRideInfoFormSubmitting}
              setIsRideInfoFormSubmitting={setIsRideInfoFormSubmitting}
              rideInfoInlineEdit={rideInfoInlineEdit}
              setRideInfoInlineEdit={setRideInfoInlineEdit}
              rideInfoForm={rideInfoForm}
            />
          </Col>
          <Col className="gutter-row" xs={24} sm={24} lg={12} >
            <DeviceLocation
              deviceLocationDetails={deviceLocationDetailsFB}
              isFetching={isFetchingFB}
            />
          </Col>
        </Row>
        <Row>
          <Col className="gutter-row" xs={24} sm={24} md={24} >
            <DeviceRides
              isFetchingDeviceRides={isFetchingDeviceRides}
              deviceRideList={deviceRideList}
              totalDeviceRides={totalDeviceRides}
              handlePaginationChange={handlePaginationChange}
              handleSelectSearch={handleSelectSearch}
            />
          </Col>
        </Row>
      </div>
    </DeviceDetailPageBox>
  );
};

export default DeviceDetailPage;
