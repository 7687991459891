import React, { useState, useEffect } from 'react';
import {
  Table,
  Button,
  /* Form, Row, Col, Input, */
  Popconfirm,
  Switch,
} from 'antd';
import CommonBreadCrumb from 'components/common-breadcrumb';
import {
  currentPage,
  // httpDelete,
  // calcHoursFromCurrentDateTime,
  // formatDate,
  httpGet,
  httpPut,
  showErrorMsg,
  showSuccessMsg,
  URLS_V2,
} from 'utils';
import {
  // DeleteOutlined,
  FormOutlined,
  PlusOutlined,
  UnlockOutlined,
} from '@ant-design/icons';
// import { debounce } from 'lodash';
import AddEditUserModal from './add-edit-user';
import { MODE_TYPES } from 'constants/common';
import ChangePassword from './change-password';
import { useHistory } from 'react-router-dom';

const UsersList = () => {
const history=useHistory()

  const breakCrumbList = [
    { label: 'Home', link: '/dashboard' },
    { label: 'Users' },
  ];
  const [userListData, setUserListData] = useState([]);
  const [totalRecords, setTotalRecords] = useState(0);
  const [activeMode, setActiveMode] = useState('');
  const [isFetching, setIsFetching] = useState(false);
  const [modalVisible, setModalVisible] = useState(false);
  const [modalPasswordVisible, setModalPasswordVisible] = useState(false);
  const [recordId, setRecordId] = useState(undefined);
  const [orderPagination, setOrderPagination] = useState({
    skip: 0,
    limit: 100,
    order: 1,
    orderBy: 'title',
  });
  const [searchParam] = useState({
    title: '',
    user_type: '',
  });

  useEffect(() => {
    fetchUsersList();
  }, [searchParam]);

  const fetchUsersList = (orderPaginationParams = orderPagination) => {
    setIsFetching(true);

    const queryParams = new URLSearchParams();
    queryParams.append('skip', orderPaginationParams.skip);
    queryParams.append('limit', orderPaginationParams.limit);
    queryParams.append('order', orderPaginationParams.order);
    queryParams.append('order_by', orderPaginationParams.orderBy);

    if (searchParam && Object.keys(searchParam).length >= 1) {
      Object.keys(searchParam).map((key) => {
        queryParams.append(key, searchParam[key]);
      });
    }

    const url = `${URLS_V2?.USERS?.NEW_USERS_LIST}?${queryParams.toString()}`;
    httpGet(url)
      .then((res) => {
        if (res?.status === 200) {
          let userListDataData = [];
          if (res?.data && res?.data.length > 0) {
            userListDataData = res?.data.map((data) => {
              return {
                ...data,
                key: data?._id,
                role_type: data?.role?.role_type,
                role_title: data?.role?.title,
              };
            });
          }
          setUserListData(userListDataData || []);
          setTotalRecords(res?.total || 0);
        }
        setIsFetching(false);
      })
      .catch((err) => {
        setIsFetching(false);
      });
  };

  const columns = [
    {
      title: 'Sr. No.',
      width: 80,
      render: (value, item, index) =>
        (currentPage(orderPagination.skip, orderPagination.limit) - 1) *
          orderPagination.limit +
        index +
        1,
    },
    {
      key: 'name',
      title: ENROLL_LABELS.USER_NAME,
      dataIndex: 'name',
      // sorter: true,
      defaultSortOrder: 'ascend',
    },
    {
      key: 'email',
      title: ENROLL_LABELS.USER_EMAIL,
      dataIndex: 'email',
      // sorter: true,
    },
    // {
    //   key: 'role_title',
    //   title: ENROLL_LABELS.ROLE,
    //   dataIndex: 'role_title',
    //   // sorter: true,
    // },
    {
      key: 'role_type',
      title: ENROLL_LABELS.ROLE_TYPE,
      dataIndex: 'role_type',
      // sorter: true,
    },
    {
      key: 'is_active',
      title: ENROLL_LABELS.ACTIVE,
      dataIndex: 'is_active',
      render: (is_active, item) => {
        return (function Actions() {
          return (
            <div>
              <Popconfirm
                title={
                  <>{`Are you sure you want to update status to ${
                    is_active ? 'Inactive' : 'Active'
                  }?`}</>
                }
                onConfirm={() => onChangeActiveInactive(item._id, !is_active)}
              >
                <Switch
                  checked={is_active}
                  className={`${is_active ? 'bg-success' : 'bg-danger'}`}
                  checkedChildren="Active"
                  unCheckedChildren="Inactive"
                />
              </Popconfirm>
            </div>
          );
        })();
      },
    },
    {
      key: '_id',
      title: ENROLL_LABELS.ACTIONS,
      dataIndex: '_id',
      width: 150,
      render: (itemId) => {
        return (function Actions() {
          return (
            <div className="actions-btn">
              <Button
                shape="circle"
                className="edit-btn"
                onClick={() => handlePasswordChangeButtonAction(itemId)}
              >
                <UnlockOutlined type="primary" className="edit-icon" />
              </Button>
              <Button
                shape="circle"
                className="edit-btn"
                style={{ marginLeft: '0.5rem' }}
                onClick={() => handleUpdateButtonAction(itemId)}
              >
                <FormOutlined type="primary" className="edit-icon" />
              </Button>
              {/* <Popconfirm
                title={<>{`Are you sure you want to delete this record?`}</>}
                onConfirm={() => deleteItem(itemId)}
              >
                <Button
                  shape="circle"
                  className="delete-btn"
                  style={{ marginLeft: '0.5rem' }}
                >
                  <DeleteOutlined type="primary" className="delete-icon" />
                </Button>
              </Popconfirm> */}
            </div>
          );
        })();
      },
    },
  ];

  const onChangeActiveInactive = (selectedId, value) => {
    setIsFetching(true);
    const data = {
      id: selectedId,
      is_active: value,
    };
    updateRecordStatus(data);
  };

  const updateRecordStatus = (data) => {
    const url = URLS_V2?.USERS?.UPDATE_USER_STATUS;
    httpPut(url, data)
      .then((res) => {
        if (res?.status === 200) {
          showSuccessMsg('User updated successfully!');
          fetchUsersList(orderPagination);
        } else {
          showErrorMsg(
            'System is unable to complete request. Please try again later.'
          );
        }
        setIsFetching(false);
      })
      .catch((err) => {
        setIsFetching(false);
      });
  };

  // const deleteItem = (selectedId) => {
  //   const queryParams = new URLSearchParams();
  //   queryParams.append('id', selectedId);
  //   queryParams.append('is_deleted', true);
  //   const url = `${URLS_V2?.USERS?.DELETE_USER}/?${queryParams.toString()}`;
  //   httpDelete(url)
  //     .then((res) => {
  //       if (res?.status === 200) {
  //         showSuccessMsg('User deleted successfully!');
  //         fetchUsersList(orderPagination);
  //       } else {
  //         showErrorMsg(
  //           'System is unable to complete request. Please try again later.'
  //         );
  //       }
  //       setIsFetching(false);
  //     })
  //     .catch((err) => {
  //       setIsFetching(false);
  //     });
  // };

  // const handleTextSearch = (e) => {
  //   setSearchParam({
  //     ...searchParam,
  //     [e.target.name]: e.target.value,
  //   });
  // };

  const handlePaginationChange = (pagination, filters, sorter) => {
    const { current, pageSize } = pagination;
    const tempSearchPagination = {
      ...orderPagination,
      skip: current * pageSize - pageSize,
      limit: pageSize,
      order: sorter.order === 'descend' ? -1 : 1,
      orderBy: sorter.field,
    };
    setOrderPagination(tempSearchPagination);
    fetchUsersList(tempSearchPagination);
  };

  const defaultTitle = () => {
    return (
      <div className="cus-table-right-btn">
        {`Showing Records: ${userListData.length} / Total Records: ${totalRecords}`}
      </div>
    );
  };

  const handleCreateButtonAction = () => {

    // setActiveMode(MODE_TYPES.ADD);
    // setModalVisible(true);
    history.push('/v2/users/create-user')
  };

  const handleUpdateButtonAction = (itemId) => {
    setActiveMode(MODE_TYPES.UPDATE);
    setRecordId(itemId);
    setModalVisible(true);
  };

  const handlePasswordChangeButtonAction = (itemId) => {
    setActiveMode(MODE_TYPES.UPDATE);
    setRecordId(itemId);
    setModalPasswordVisible(true);
  };

  const handleCloseModal = () => {
    setActiveMode('');
    setRecordId(undefined);
    setModalVisible(false);
    setModalPasswordVisible(false);
  };

  const handleSubmitSuccessModal = () => {
    handleCloseModal();
    fetchUsersList();
  };

  return (
    <>
      <CommonBreadCrumb breadCrumbs={breakCrumbList} />
      <div className="while-bg-box cus-table-header">
        <h3 className="table-title">Users</h3>
        <div className="cus-table-right-btn">
          <div className="bulk-action-btn">
            <Button type="primary" onClick={handleCreateButtonAction}>
              <PlusOutlined />
              Create User
            </Button>
          </div>
        </div>
      </div>
      {/* <div className="while-bg-box">
        <Form className="main-filter-form">
          <Row gutter={[16, 16]}>
            <Col md={8}>
              <Form.Item name="user_name" style={{ marginBottom: '0' }}>
                <Input
                  type="text"
                  name="user_name"
                  onChange={debounce((event) => handleTextSearch(event))}
                  placeholder="Search User"
                />
              </Form.Item>
            </Col>
            <Col md={8}>
              <Form.Item name="user_type" style={{ marginBottom: '0' }}>
                <Input
                  type="text"
                  name="user_type"
                  onChange={debounce((event) => handleTextSearch(event))}
                  placeholder="Search User Type"
                />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </div> */}

      <div className="main-table-body-cus">
        <Table
          title={defaultTitle}
          loading={{
            indicator: <div className="table-loader">Fetching...</div>,
            spinning: isFetching,
          }}
          columns={columns}
          dataSource={userListData || []}
          size="small"
          onChange={handlePaginationChange}
          pagination={{
            defaultPageSize: 100,
            total: totalRecords,
          }}
          scroll={{ x: 600 }}
        />
      </div>

      {modalVisible && (
        <AddEditUserModal
          activeMode={activeMode}
          handleCloseModal={handleCloseModal}
          handleSubmitSuccessModal={handleSubmitSuccessModal}
          modalVisible={modalVisible}
          recordId={recordId}
          sectionName="User"
        />
      )}
      {modalPasswordVisible && (
        <ChangePassword
          activeMode={activeMode}
          handleCloseModal={handleCloseModal}
          handleSubmitSuccessModal={handleSubmitSuccessModal}
          modalVisible={modalPasswordVisible}
          recordId={recordId}
          sectionName="User Password"
        />
      )}
    </>
  );
};

export default UsersList;

const ENROLL_LABELS = {
  USER_NAME: 'Name',
  USER_EMAIL: 'Email',
  // ROLE: 'Role',
  ROLE_TYPE: 'Role Type',
  ACTIVE: 'Status',
  ACTIONS: 'Actions',
};
