import React, { useState, useEffect } from 'react';
import { string, func } from 'prop-types';
import { Input, Modal, Form, Row, Col, Select, Button, Typography } from 'antd';
import { PlusOutlined, DeleteOutlined, EyeOutlined } from '@ant-design/icons';
import {
  READING_CATEGORIES_LIST,
  READING_LANGUAGE_LIST,
  READ_IMAGE_DIMENSIONS,
} from 'constants/reading';
import { GLOBAL_APP_SETTINGS_KEYS, MODE_TYPES } from 'constants/common';
import {
  httpPost,
  httpPut,
  httpGet,
  URLS,
  showErrorMsg,
  showSuccessMsg,
  updateFirebaseGlobalAppSettings,
} from 'utils';
import validUrl from 'valid-url';
import {
  ReadingModalBox,
  UploadImageBox,
  PreviewImageBox,
} from './_reading-modal.styled';

const { Option } = Select;
const { Text } = Typography;

const logoImageInitialStructure = {
  base64: '',
  file: '',
  gcloudUrl: '',
};
const ReadingModal = (props) => {
  const { modalTitle, activeMode, handleOk, closeModal, callback, readingId } =
    props;
  const [form] = Form.useForm();
  const [validateFieldsName, setvalidateFieldsName] = useState([]);
  const [logoImage, setLogoImage] = useState(logoImageInitialStructure);
  const [isPreviewLogoImg, setIsPreviewLogoImg] = useState(false);
  const [isReadingAdding, setIsReadingAdding] = useState(false);
  const [isReadingUpdating, setIsReadingUpdating] = useState(false);
  const [, setIsFetchingReading] = useState(false);

  const isDisabled = isReadingAdding || isReadingUpdating;

  useEffect(() => {
    if (activeMode === MODE_TYPES.ADD) {
      form.resetFields();
      setLogoImage(logoImageInitialStructure);
    }
    if (!readingId) return;
    setIsFetchingReading(true);
    const url = URLS?.READING.GET_READING_BY_ID.replace('#ID#', readingId);
    httpGet(url)
      .then((res) => {
        if (res.status === 200) {
          if (
            res?.data &&
            Object.entries(res?.data) &&
            Object.entries(res?.data).length > 0
          ) {
            Object.entries(res?.data).forEach(([key, value]) => {
              if (key === 'logo') {
                setLogoImage({
                  ...logoImageInitialStructure,
                  gcloudUrl: value,
                });
              } else {
                form.setFieldsValue({
                  [key]: value,
                });
              }
            });
          }
        } else if (res?.error) {
          showErrorMsg(res?.error);
        }
        setIsFetchingReading(false);
      })
      .catch((err) => {
        showErrorMsg(err);
        setIsFetchingReading(false);
      });
  }, [activeMode]);

  const handleValidateFieldNames = (name) => {
    const isFieldName = validateFieldsName.find(
      (fieldName) => fieldName === name
    );
    if (isFieldName) return 'onChange';
    return 'onBlur';
  };

  const getBase64 = (img, callback) => {
    const reader = new FileReader();
    reader.addEventListener('load', () => callback(reader.result));
    reader.readAsDataURL(img);
  };

  const handleLogoImgUpload = async (e) => {
    const file = e.target.files[0];

    if (file) {
      const isImageValid = await new Promise((resolve, reject) => {
        // check the file type - you can specify the types you'd like here:
        const isImg =
          file.type === 'image/jpeg' ||
          file.type === 'image/jpg' ||
          file.type === 'image/png';

        if (!isImg) {
          showErrorMsg(
            `Invalid image extension. Image must be in .png, .jpeg, or .jpg extension`
          );
          resolve(false);
          return;
        }

        // check the file size - you can specify the file size you'd like here:
        const imageSize = file.size / 1024 > READ_IMAGE_DIMENSIONS.FILE_SIZE;
        if (imageSize) {
          showErrorMsg(
            `Image size must be smaller then ${READ_IMAGE_DIMENSIONS.FILE_SIZE} KB`
          );
          resolve(false);
          return;
        }

        // check for dimensions
        var reader = new FileReader();
        // Read the contents of Image File.
        reader.readAsDataURL(file);
        reader.onload = (event) => {
          // Initiate the JavaScript Image object.
          var image = new Image();

          // Set the Base64 string return from FileReader as source.
          image.src = event.target.result;

          image.onload = function () {
            const { height, width } = this;
            // if the aspect ratio is in our sweet spot, proceed - you can specify whatever checks for height and width you want
            if (
              width !== READ_IMAGE_DIMENSIONS.FILE_WIDTH ||
              height !== READ_IMAGE_DIMENSIONS.FILE_HEIGHT
            ) {
              showErrorMsg(
                `Image does not have optimal dimensions. Recommended image dimensions are ${READ_IMAGE_DIMENSIONS.FILE_WIDTH} X ${READ_IMAGE_DIMENSIONS.FILE_HEIGHT}.`
              );
              resolve(false);
            } else {
              resolve(true);
            }
          };
        };
      });

      if (isImageValid) {
        getBase64(file, (imageUrl) => {
          setLogoImage({
            ...logoImageInitialStructure,
            base64: imageUrl,
            file,
          });
        });
      }
    }
  };

  const handlePreviewLogoImg = ({ isVisible }) => {
    setIsPreviewLogoImg(isVisible);
  };

  const getImageUrl = logoImage.gcloudUrl || logoImage.base64;

  const handleLogoImgDelete = () => {
    setLogoImage(logoImageInitialStructure);
  };

  const handleAddReading = () => {
    form.submit();
    form
      .validateFields()
      .then(async (values) => {
        const formData = new FormData();
        formData.append('logo', logoImage.file);
        formData.append('name', values.name);
        formData.append('category', values.category);
        formData.append('link', values.link);
        formData.append('lang', values.lang);
        if (!logoImage.file) {
          showErrorMsg('Logo required!');
          return;
        }
        setIsReadingAdding(true);
        httpPost(URLS?.READING.ADD_READING, formData)
          .then((res) => {
            if (res.status === 200) {
              showSuccessMsg('Reading added successfully!');
              resetAllData();
              callback();
              updateFirebaseGlobalAppSettings(GLOBAL_APP_SETTINGS_KEYS.read);
            } else if (res?.error) {
              showErrorMsg(res?.error);
            }
            setIsReadingAdding(false);
          })
          .catch((err) => {
            showErrorMsg(err);
            setIsReadingAdding(false);
          });
      })
      .catch((errorInfo) => errorInfo);
  };

  const handleEditReading = () => {
    form.submit();
    form
      .validateFields()
      .then(async (values) => {
        const formData = new FormData();
        formData.append('logo', logoImage.file);
        formData.append('name', values.name);
        formData.append('category', values.category);
        formData.append('link', values.link);
        formData.append('lang', values.lang);
        if (!logoImage.file && !logoImage.gcloudUrl) {
          showErrorMsg('Logo required!');
          return;
        }
        setIsReadingUpdating(true);
        const url = URLS?.READING.UPDATE_READING.replace('#ID#', readingId);
        httpPut(url, formData)
          .then((res) => {
            if (res.status === 200) {
              showSuccessMsg('Reading updated successfully!');
              resetAllData();
              callback();
              updateFirebaseGlobalAppSettings(GLOBAL_APP_SETTINGS_KEYS.read);
            } else if (res?.error) {
              showErrorMsg(res?.error);
            }
            setIsReadingUpdating(false);
          })
          .catch((err) => {
            showErrorMsg(err);
            setIsReadingUpdating(false);
          });
      })
      .catch((errorInfo) => errorInfo);
  };

  const resetAllData = () => {
    form.resetFields();
    setLogoImage(logoImageInitialStructure);
    closeModal();
  };
  const handleOnCloseModal = () => {
    if (isDisabled) return;
    resetAllData();
  };

  let modalfooterButtons = [
    <Button
      key="add"
      type="primary"
      onClick={handleAddReading}
      disabled={isDisabled}
    >
      {isReadingAdding ? 'Loading...' : 'Add Reading'}
    </Button>,
    <Button key="close" onClick={handleOnCloseModal} disabled={isDisabled}>
      Close
    </Button>,
  ];

  if (activeMode === MODE_TYPES.EDIT) {
    modalfooterButtons = [
      <Button
        key="update"
        type="primary"
        onClick={handleEditReading}
        disabled={isDisabled}
      >
        {isReadingUpdating ? 'Loading...' : 'Update Reading'}
      </Button>,
      <Button key="close" onClick={handleOnCloseModal} disabled={isDisabled}>
        Close
      </Button>,
    ];
  }
  let getModalTitle = modalTitle;
  if (activeMode === MODE_TYPES.ADD) {
    getModalTitle = 'Add Reading';
  }
  if (activeMode === MODE_TYPES.EDIT) {
    getModalTitle = 'Edit Reading';
  }
  return (
    <div>
      <ReadingModalBox>
        <Modal
          title={getModalTitle}
          visible={Boolean(activeMode)}
          onOk={handleOk}
          onCancel={handleOnCloseModal}
          className="expense-modal"
          maskClosable={false}
          width={550}
          forceRender={true}
          footer={modalfooterButtons}
        >
          <Form form={form} layout="vertical">
            <Row gutter={16}>
              <Col className="gutter-row" xs={24} sm={8}>
                {getImageUrl ? (
                  <PreviewImageBox>
                    <img src={getImageUrl} alt="logo" />
                    <div className="icons-div">
                      <div className="icons">
                        <div>
                          <EyeOutlined
                            onClick={() => {
                              handlePreviewLogoImg({ isVisible: true });
                            }}
                          />
                        </div>
                        <div>
                          <DeleteOutlined onClick={handleLogoImgDelete} />
                        </div>
                      </div>
                    </div>
                    <Modal
                      visible={isPreviewLogoImg}
                      title={'Preview'}
                      footer={null}
                      onCancel={() =>
                        handlePreviewLogoImg({ isVisible: false })
                      }
                    >
                      <img
                        alt="logo"
                        style={{ width: '100%' }}
                        src={getImageUrl}
                      />
                    </Modal>
                  </PreviewImageBox>
                ) : (
                  <UploadImageBox htmlFor="imageUrl">
                    <div className={`upload-image `}>
                      {/* {isDisabled ? (
                    <>No Image</>
                  ) : ( */}
                      <>
                        <div className="icon">
                          <PlusOutlined />
                        </div>
                        <div>Upload Logo</div>
                        <div>
                          <Text type="secondary">{`Max file size: ${READ_IMAGE_DIMENSIONS.FILE_SIZE} KB`}</Text>
                        </div>
                        <div>
                          <Text type="secondary">{`Resolution: ${READ_IMAGE_DIMENSIONS.FILE_WIDTH} X ${READ_IMAGE_DIMENSIONS.FILE_HEIGHT}`}</Text>
                        </div>
                      </>
                      {/* )} */}
                    </div>
                    <input
                      id="imageUrl"
                      // disabled={isDisabled}
                      type="file"
                      name="imageUrl"
                      onChange={handleLogoImgUpload}
                      style={{ visibility: 'hidden' }}
                    />
                  </UploadImageBox>
                )}
              </Col>
              <Col className="gutter-row" xs={24} sm={16}>
                <Form.Item
                  name="name"
                  label="Name"
                  validateTrigger={handleValidateFieldNames('name')}
                  rules={[
                    {
                      required: true,
                      message: 'Please enter name!',
                    },
                  ]}
                >
                  <Input
                    type="text"
                    placeholder="Name"
                    onBlur={() =>
                      setvalidateFieldsName([...validateFieldsName, 'name'])
                    }
                  />
                </Form.Item>
                <Form.Item
                  name="category"
                  label="Category"
                  rules={[
                    {
                      required: true,
                      message: 'Please select category!',
                    },
                  ]}
                >
                  <Select placeholder="Select category">
                    {READING_CATEGORIES_LIST &&
                      READING_CATEGORIES_LIST.length > 0 &&
                      READING_CATEGORIES_LIST.map((name, index) => (
                        <Option value={name} key={index}>
                          {name || ''}
                        </Option>
                      ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col className="gutter-row" xs={24}>
                <Form.Item
                  name="link"
                  label="Link"
                  validateTrigger={handleValidateFieldNames('link')}
                  rules={[
                    {
                      required: true,
                      message: 'Please enter redirect link!',
                    },
                    {
                      validator(_, value) {
                        if (value && !validUrl.isUri(value)) {
                          return Promise.reject('Please enter valid url');
                        }
                        return Promise.resolve();
                      },
                    },
                  ]}
                >
                  <Input
                    type="text"
                    placeholder="Link"
                    onBlur={() =>
                      setvalidateFieldsName([...validateFieldsName, 'link'])
                    }
                  />
                </Form.Item>
              </Col>
              <Col className="gutter-row" xs={24}>
                <Form.Item
                  name="lang"
                  label="Language"
                  rules={[
                    {
                      required: true,
                      message: 'Please select language!',
                    },
                  ]}
                >
                  <Select placeholder="Select language">
                    {READING_LANGUAGE_LIST &&
                      READING_LANGUAGE_LIST.length > 0 &&
                      READING_LANGUAGE_LIST.map((languageItem, index) => (
                        <Option value={languageItem.value} key={index}>
                          {languageItem.label || ''}
                        </Option>
                      ))}
                  </Select>
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </Modal>
      </ReadingModalBox>
    </div>
  );
};

ReadingModal.propTypes = {
  activeMode: string,
  callback: func,
  closeModal: func,
  handleOk: func,
  modalTitle: string,
  readingId: string,
};

export default ReadingModal;
