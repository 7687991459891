import {
  CheckOutlined,
  CloseOutlined,
  HistoryOutlined,
  ReloadOutlined,
  WifiOutlined,
  AudioMutedOutlined,
  MobileOutlined,
} from '@ant-design/icons';
import { CommandEnum } from 'enums/command';

export const DEVICE_BULK_ACTIONS = {
  ACTIVE: { value: 'active', label: 'Active', icon: CheckOutlined },
  INACTIVE: { value: 'inactive', label: 'InActive', icon: CloseOutlined },
};

export const DEVICE_BULK_ACTION_LIST = [
  DEVICE_BULK_ACTIONS.ACTIVE,
  DEVICE_BULK_ACTIONS.INACTIVE,
];

export const DEVICE_BULK_ACTIONS_V2 = {
  REBOOT: {
    value: 'REBOOT',
    label: 'REBOOT',
    icon: ReloadOutlined,
    command: CommandEnum.REBOOT,
  },
  RESET: {
    value: 'RESET',
    label: 'RESET',
    icon: HistoryOutlined,
    command: CommandEnum.RESET,
  },
  // ACTIVE: {
  //   value: 'ACTIVE',
  //   label: 'TOGGLE ACTIVE',
  //   icon: CheckCircleOutlined,
  //   command: CommandEnum.ACTIVE,
  // },
  // IN_ACTIVE: {
  //   value: 'INACTIVE',
  //   label: 'TOGGLE INACTIVE',
  //   icon: CloseCircleOutlined,
  //   command: CommandEnum.INACTIVE,
  // },
  SLEEP: {
    value: 'SLEEP',
    label: 'SLEEP',
    icon: MobileOutlined,
    command: CommandEnum.SLEEP,
  },
  WIFI: {
    value: 'WIFI',
    label: 'WIFI',
    icon: WifiOutlined,
    command: CommandEnum.WIFI,
  },
  MUTE: {
    value: 'MUTE',
    label: 'MUTE',
    icon: AudioMutedOutlined,
    command: CommandEnum.MUTE,
  },
};

export const DEVICE_BULK_ACTION_LIST_V2 = [
  DEVICE_BULK_ACTIONS_V2.REBOOT,
  DEVICE_BULK_ACTIONS_V2.RESET,
  DEVICE_BULK_ACTIONS_V2.SLEEP,
  DEVICE_BULK_ACTIONS_V2.WIFI,
  DEVICE_BULK_ACTIONS_V2.MUTE,
];

export const DEVICE_LIST_BULK_ACTION_LIST_V2 = [
  DEVICE_BULK_ACTIONS_V2.REBOOT,
  DEVICE_BULK_ACTIONS_V2.SLEEP,
  DEVICE_BULK_ACTIONS_V2.WIFI,
  DEVICE_BULK_ACTIONS_V2.MUTE,
];

export const DEVICE_ISSUES = {
  CHARGING_ISSUE: {
    text: 'CHARGING ISSUE',
    value: 'CHARGING ISSUE',
    color: '#ff4d4f',
  },
  NETWORK_ISSUE: {
    text: 'NETWORK ISSUE',
    value: 'NETWORK ISSUE',
    color: '#ff4d4f',
  },
  ALL_GOOD: { text: 'WORKING FINE', value: 'WORKING FINE', color: '#52c41a' },
};

export const DEVICE_ISSUE_LIST = [
  DEVICE_ISSUES.CHARGING_ISSUE,
  DEVICE_ISSUES.NETWORK_ISSUE,
  DEVICE_ISSUES.ALL_GOOD,
];

export const DEVICE_V2_TIMESTAMP_FB_COLLECTION_NAME = {
  DEVICE_DETAILS: 'device_details',
};

export const ALLOWED_IMPORT_DEVICE_MEDIA_MIMETYPES = [
  'application/vnd.ms-excel (official)',
  'application/msexcel',
  'application/x-msexcel',
  'application/x-ms-excel',
  'application/x-excel',
  'application/x-dos_ms_excel',
  'application/xls',
  'application/x-xls',
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
];

export const ALLOWED_IMPORT_DEVICE_MEDIA_SIZE = {
  ALLOWED_IMPORT_DEVICE_MEDIA_SIZE: 30000,
}; // values mentioned in KB so here it is 30 MB

export const SAMPLE_BULK_IMPORT_FILE_PATH =
  (process.env.REACT_APP_CLOUD_STORAGE_MANAGEMENT_API_URL  ||
    'https://storage.googleapis.com') +
  '/' +
  (process.env.REACT_APP_CLOUD_STORAGE_MANAGEMENT_BUCKET || 'binary-storage') +
  '/devices/bulk_import/sample_bulk_upload_devices_sheet.xlsx';

export const ONLINE_OFFLINE_THRESHOLD = 30; // the defined value is in minutes with int type
