import React, { useState, useEffect } from 'react';
import {
  DatePicker,
  Table,
  Button,
  Popover,
  Dropdown,
  Menu,
  Popconfirm,
  Form,
  Input,
} from 'antd';
import { Link } from 'react-router-dom';
import {
  URLS,
  showErrorMsg,
  getAMAAuthTokenLocalStorage,
  generateAndroidManagementToken,
  showSuccessMsg,
  formatDate,
  getSingleValueFromEncodedURL,
  httpGet,
  getUserLocalStorage,
} from 'utils';
import {
  SyncOutlined,
  CheckSquareOutlined,
  CloseSquareOutlined,
  DownOutlined,
  CloseOutlined,
  EyeOutlined,
} from '@ant-design/icons';
import { EnrollDeviceBox, Loader } from './_enroll-devices.styled';
import {
  AMA_COMMAND_LIST,
  AMA_COMMAND_DURATION,
} from 'constants/enrolled-device';
import BulkCommandProcessModal from 'components/bulk-command-process-modal';
import { debounce } from 'lodash';
import moment from 'moment';
// import { gapiRequest } from 'utils/gapi';

const EnrollDevices = () => {
  const localUserData = getUserLocalStorage();

  const [isFetching, setIsFetching] = useState(false);
  const [modalVisible, setModalVisible] = useState(false);
  const [showBulkActionsRecords, setShowBulkActionsRecords] = useState(false);
  const [modalTitle, setModalTitle] = useState('');
  const [enrollDevicesList, setEnrollDevicesList] = useState([]);
  const [totalEnrollDevices, setTotalEnrollDevices] = useState(0);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [selectedDeviceDetails, setSelectedDeviceDetails] = useState([]);
  // const [pagination, setPagination] = useState({
  //   currentPage: 1,
  //   pageSize: 100,
  //   total: 0,
  //   nextPageToken: '',
  //   prevPageToken: [],
  // });
  const [searchPagination, setSearchPagination] = useState({
    skip: 0,
    limit: 100,
    order: 1,
    orderBy: 'serialNumber',
    searchValue: {},
  });

  // const apiKey =
  //   process.env.REACT_APP_ANDROID_MANAGEMENT_API_KEY ||
  //   'AIzaSyCVhMeBzCjfhBbSWenrG3aBGkXCkbvMjNc';
  // const discoveryDocs = [
  //   'https://androidmanagement.googleapis.com/$discovery/rest?version=v1',
  // ];

  const apiRequestFailedLimit = 2;
  let apiRequestFailedCount = 0;

  // useEffect(() => {
  // handleClientLoad();
  // }, []);

  // const handleClientLoad = () => {
  //   setIsFetching(true);
  //   if (window.gapi) {
  //     window.gapi.load('client', initClient);
  //   } else {
  //     setTimeout(handleClientLoad, 1000);
  //   }
  // };

  // const initClient = () => {
  //   window.gapi.client
  //     .init({
  //       apiKey: apiKey,
  //       discoveryDocs: discoveryDocs,
  //     })
  //     .then(() => {
  //       fetchDeviceList();
  //     })
  //     .catch(() => {
  //       setIsFetching(false);
  //       showErrorMsg('System is facing error, Please try again later');
  //     });
  // };

  // const fetchDeviceList = (apiPagination = pagination) => {
  //   setIsFetching(true);

  //   const { pageSize, nextPageToken } = apiPagination; // pagination object
  //   const authToken = getAMAAuthTokenLocalStorage(); // AMA auth token

  //   const deviceListURL = `${URLS.ANDROID_MANAGEMENT.ENROLLED_DEVICES_LIST}?pageSize=${pageSize}&pageToken=${nextPageToken}`;

  //   window.gapi.client
  //     .request({
  //       path: deviceListURL,
  //       method: 'GET',
  //       headers: { Authorization: 'Bearer ' + authToken },
  //     })
  //     .then((resp) => {
  //       const deviceList =
  //         (resp?.result?.devices &&
  //           resp?.result?.devices.map((device) => {
  //             return {
  //               ...device,
  //               key: getSingleValueFromEncodedURL(device?.name, 3), // key params must be there after the list data retrieved from the API
  //               serial_number: device?.hardwareInfo?.serialNumber,
  //               amaId: getSingleValueFromEncodedURL(device?.name, 3),
  //               policyName: getSingleValueFromEncodedURL(
  //                 device?.policyName,
  //                 3
  //               ),
  //               lastPolicySyncTime:
  //                 device?.lastPolicySyncTime &&
  //                 formatDate(
  //                   device?.lastPolicySyncTime,
  //                   'Do MMMM YYYY hh:mm A'
  //                 ),
  //               last_status_report_time:
  //                 device?.lastStatusReportTime &&
  //                 formatDate(
  //                   device?.lastStatusReportTime,
  //                   'Do MMMM YYYY hh:mm A'
  //                 ),
  //             };
  //           })) ||
  //         [];
  //       let totalRecords = pagination.pageSize * apiPagination.currentPage;
  //       if (resp?.result?.nextPageToken) {
  //         totalRecords = totalRecords + 100;
  //       }
  //       setPagination({
  //         ...apiPagination,
  //         nextPageToken: resp?.result?.nextPageToken || '',
  //         total: totalRecords,
  //       });
  //       setEnrollDevicesList(deviceList);
  //       setIsFetching(false);
  //     })
  //     .catch((apiExp) => {
  //       const { code, status, details } = apiExp?.result?.error || {};
  //       if (
  //         apiRequestFailedCount < apiRequestFailedLimit &&
  //         code === 401 &&
  //         status === 'UNAUTHENTICATED' &&
  //         (details?.[0]?.reason === 'ACCESS_TOKEN_EXPIRED' ||
  //           details?.[0]?.reason === 'CREDENTIALS_MISSING')
  //       ) {
  //         try {
  //           generateAndroidManagementToken();
  //           setTimeout(fetchDeviceList, 1000);
  //           apiRequestFailedCount = apiRequestFailedCount + 1;
  //         } catch (authError) {
  //           setIsFetching(false);
  //           console.log('System is facing error, Please try again later');
  //           throw authError;
  //         }
  //       } else {
  //         setIsFetching(false);
  //         showErrorMsg('System is facing error, Please try again later');
  //       }
  //     });
  // };

  // const fetchDeviceList = () => {
  //   const deviceListURL = URLS.ANDROID_MANAGEMENT.ENROLLED_DEVICES_LIST;
  //   gapiRequest(deviceListURL, 'GET')
  //     .then((resp) => {
  //       console.log('resp', resp);
  //       if (
  //         resp.code === 401 &&
  //         resp.status === 'UNAUTHENTICATED' &&
  //         resp.details &&
  //         resp.details[0] &&
  //         resp.details[0]?.reason === 'ACCESS_TOKEN_EXPIRED'
  //       ) {
  //         try {
  //           generateAndroidManagementToken();
  //           fetchDeviceList();
  //         } catch (authError) {
  //           console.log('System is facing error, Please try again later');
  //           throw authError;
  //         }
  //       } else {
  //         setEnrollDevicesList(resp?.result?.devices);
  //       }
  //       setIsFetching(false);
  //     })
  //     .catch(() => {
  //       setIsFetching(false);
  //       showErrorMsg('System is facing error, Please try again later');
  //     });
  // };

  useEffect(() => {
    fetchDeviceList(searchPagination);
  }, [searchPagination?.searchValue]);

  const fetchDeviceList = async (searchPaginationParams) => {
    setIsFetching(true);
    const queryParams = new URLSearchParams();
    queryParams.append('skip', searchPaginationParams.skip);
    queryParams.append('limit', searchPaginationParams.limit);
    queryParams.append('order', searchPaginationParams.order);
    queryParams.append('order_by', searchPaginationParams.orderBy);
    if (
      searchPaginationParams.searchValue &&
      Object.keys(searchPaginationParams.searchValue).length >= 1
    ) {
      Object.keys(searchPaginationParams.searchValue).map((key) => {
        queryParams.append(key, searchPaginationParams.searchValue[key]);
      });
    }

    const url = `${
      URLS?.ENROLLED_DEVICES?.ENROLLED_DEVICES_LIST
    }?${queryParams.toString()}`;

    const resp = await httpGet(url);

    const deviceList =
      (resp?.data &&
        resp?.data.map((device) => {
          return {
            ...device,
            key: getSingleValueFromEncodedURL(device?.name, 3), // key params must be there after the list data retrieved from the API
            serialNumber: device?.hardwareInfo?.serialNumber,
            amaId: getSingleValueFromEncodedURL(device?.name, 3),
            policyName: getSingleValueFromEncodedURL(device?.policyName, 3),
            lastPolicySyncTime:
              device?.lastPolicySyncTime &&
              formatDate(device?.lastPolicySyncTime, 'Do MMMM YYYY hh:mm A'),
            lastStatusReportTime:
              device?.lastStatusReportTime &&
              formatDate(device?.lastStatusReportTime, 'Do MMMM YYYY hh:mm A'),
          };
        })) ||
      [];
    setEnrollDevicesList(deviceList);
    setTotalEnrollDevices(resp?.total || 0);
    setIsFetching(false);
  };

  const columns = [
    {
      key: 'serialNumber',
      title: ENROLL_LABELS.SERIAL_NUMBER,
      dataIndex: 'serialNumber',
      sorter: true,
      defaultSortOrder: 'ascend',
    },
    {
      key: 'amaId',
      title: ENROLL_LABELS.AMA_ID,
      dataIndex: 'amaId',
    },
    {
      key: 'policyName',
      title: ENROLL_LABELS.APPLIED_POLICY_NAME,
      dataIndex: 'policyName',
    },
    {
      key: 'lastPolicySyncTime',
      title: ENROLL_LABELS.LAST_POLICY_SYNC_TIME,
      dataIndex: 'lastPolicySyncTime',
    },
    {
      key: 'lastStatusReportTime',
      title: ENROLL_LABELS.LAST_STATUS_TIME,
      dataIndex: 'lastStatusReportTime',
      sorter: true,
    },
    {
      key: 'nonComplianceDetails',
      title: ENROLL_LABELS.IS_DEVICE_COMPLIANCE,
      dataIndex: 'nonComplianceDetails',
      align: 'center',
      render: (nonComplianceDetails, item) => {
        return (function Actions() {
          return (
            <div key={item?.name}>
              {nonComplianceDetails ? (
                <Popover
                  content={
                    <div className="compliance-details">
                      <div>
                        <b>{'Setting Name: '}</b>
                        {nonComplianceDetails[0]?.settingName}
                      </div>
                      <br></br>
                      <div>
                        <b>{'Non Compliance Reason: '}</b>
                        {nonComplianceDetails[0]?.nonComplianceReason}
                      </div>
                    </div>
                  }
                  title="Non Compliance Details"
                  trigger="hover"
                >
                  <CloseSquareOutlined
                    style={{
                      fontSize: '18px',
                      color: '#ff4d4f',
                      cursor: 'pointer',
                    }}
                  />
                </Popover>
              ) : (
                <CheckSquareOutlined
                  style={{ fontSize: '18px', color: '#52c41a' }}
                />
              )}
            </div>
          );
        })();
      },
    },
    {
      key: 'amaId',
      title: ENROLL_LABELS.ACTION,
      dataIndex: 'amaId',
      render: (amaId) => {
        return (function Actions() {
          return (
            <div className="actions-btn" key={amaId}>
              <Popconfirm
                title={<>Are you sure you want to restart this device?</>}
                onConfirm={() => resetDevice(amaId)}
              >
                <Button shape="circle" className="reset-btn">
                  <SyncOutlined type="primary" className="reset-icon" />
                </Button>
              </Popconfirm>

              <Link to={`/enroll-devices/${amaId}`}>
                <Button
                  shape="circle"
                  className="edit-btn"
                  style={{ marginLeft: '0.5rem' }}
                >
                  <EyeOutlined type="primary" className="preview-icon" />
                </Button>
              </Link>
            </div>
          );
        })();
      },
    },
  ];

  const resetDevice = (deviceId) => {
    if (deviceId) {
      sendCommandToDevice(deviceId);
    }
  };

  const sendCommandToDevice = (deviceId) => {
    setIsFetching(true);
    const command = AMA_COMMAND_LIST.REBOOT;
    const duration = AMA_COMMAND_DURATION[AMA_COMMAND_LIST.REBOOT];
    const issueDeviceCommandURL = `${URLS.ANDROID_MANAGEMENT.ENROLLED_DEVICES_LIST}/${deviceId}:issueCommand`;
    const authToken = getAMAAuthTokenLocalStorage(); // AMA auth token
    window.gapi.client
      .request({
        path: issueDeviceCommandURL,
        method: 'POST',
        body: {
          type: command,
          duration: duration,
        },
        headers: { Authorization: 'Bearer ' + authToken },
      })
      .then((resp) => {
        setIsFetching(false);
        showSuccessMsg(command + ' command issued successfully');
      })
      .catch((apiExp) => {
        const { code, status, details } = apiExp?.result?.error || {};
        if (
          apiRequestFailedCount < apiRequestFailedLimit &&
          code === 401 &&
          status === 'UNAUTHENTICATED' &&
          details?.[0]?.reason === 'ACCESS_TOKEN_EXPIRED'
        ) {
          try {
            generateAndroidManagementToken();
            setTimeout(() => {
              sendCommandToDevice(deviceId);
            }, 1000);
            apiRequestFailedCount = apiRequestFailedCount + 1;
            setIsFetching(false);
          } catch (authError) {
            setIsFetching(false);
            console.log('System is facing error, Please try again later');
            throw authError;
          }
        } else {
          setIsFetching(false);
          showErrorMsg('System is facing error, Please try again later');
        }
      });
  };

  // const onChangePagination = (currentPage) => {
  //   let tempPagination = pagination;
  //   if (currentPage < pagination.currentPage) {
  //     tempPagination = {
  //       ...tempPagination,
  //       nextPageToken:
  //         currentPage > 1 ? pagination.prevPageToken[currentPage - 2] : '',
  //       currentPage: currentPage,
  //     };
  //   } else {
  //     const prevPageTokens =
  //       pagination.prevPageToken && pagination.prevPageToken[currentPage - 2]
  //         ? [...pagination.prevPageToken]
  //         : [...pagination.prevPageToken, pagination.nextPageToken];
  //     tempPagination = {
  //       ...tempPagination,
  //       currentPage: currentPage,
  //       prevPageToken: prevPageTokens,
  //     };
  //   }
  //   setPagination(tempPagination);
  //   fetchDeviceList(tempPagination);
  // };

  const deleteTableColumns = [
    {
      key: 'serialNumber',
      title: ENROLL_LABELS.SERIAL_NUMBER,
      dataIndex: 'serialNumber',
    },
    {
      key: 'amaId',
      title: ENROLL_LABELS.AMA_ID,
      dataIndex: 'amaId',
    },
    {
      key: 'amaId',
      title: ENROLL_LABELS.ACTION,
      dataIndex: 'amaId',
      render: (amaId) => {
        return (function Actions() {
          return (
            <div className="actions-btn" key={amaId}>
              <Button
                danger
                shape="circle"
                className="close-btn"
                onClick={() => removeDeviceSelection(amaId)}
              >
                <CloseOutlined
                  type="primary"
                  className="close-icon"
                  twoToneColor="#ff4d4f"
                />
              </Button>
            </div>
          );
        })();
      },
    },
  ];

  const removeDeviceSelection = (amaId) => {
    const newSelectedRowKeys = selectedRowKeys.filter(
      (deviceId) => deviceId !== amaId
    );
    const newSelectedDeviceDetails = selectedDeviceDetails.filter(
      (device) => device.amaId !== amaId
    );
    setSelectedRowKeys(newSelectedRowKeys);
    setSelectedDeviceDetails(newSelectedDeviceDetails);
    if (newSelectedRowKeys.length <= 0) {
      showBulkActionsRecords(false);
    }
  };

  const hasSelected = selectedRowKeys.length > 0;

  const onSelectedRowKeysChange = (selectedRowKey, selectedRowItem) => {
    setSelectedRowKeys(selectedRowKey);
    setSelectedDeviceDetails(selectedRowItem);
  };

  const showHideBulkActionsRecords = (showHide) => {
    setShowBulkActionsRecords(showHide);
  };

  const handleBulkIssueCommandToDevice = () => {
    setModalVisible(true);
    showHideBulkActionsRecords(false);
    setModalTitle(`${AMA_COMMAND_LIST.REBOOT} Operation`);
  };

  const handleModal = (modalVisibility) => {
    setModalVisible(modalVisibility);
    if (!modalVisibility) {
      setShowBulkActionsRecords(false);
      setModalTitle('');
      setSelectedRowKeys([]);
      setSelectedDeviceDetails([]);
    }
  };

  const handleFilterParams = (event, inputType, inputName = '') => {
    let tempFilterParams = {};
    let tempInputValue = '';
    let tempInputName = '';

    if (inputType === 'text') {
      tempInputValue = event.target.value;
      tempInputName = event.target.name;
    } else if (inputType === 'date') {
      tempInputValue = formatDate(event, 'YYYY-MM-DD');
      tempInputName = inputName;
    }

    if (tempInputValue) {
      tempFilterParams = {
        searchValue: {
          ...searchPagination.searchValue,
          [tempInputName]: tempInputValue,
        },
      };
    } else if (searchPagination.searchValue[tempInputName]) {
      delete searchPagination.searchValue[tempInputName];
      tempFilterParams = searchPagination.searchValue;
    }

    setSearchPagination({
      ...searchPagination,
      searchValue: { ...tempFilterParams.searchValue },
    });
  };

  const handlePaginationChange = (pagination, filters, sorter) => {
    const { current, pageSize } = pagination;
    const tempSearchPagination = {
      ...searchPagination,
      skip: current * pageSize - pageSize,
      limit: pageSize,
      order: sorter.order === 'descend' ? -1 : 1,
      orderBy: sorter.field,
    };
    setSearchPagination(tempSearchPagination);
    fetchDeviceList(tempSearchPagination);
  };

  const menu = (
    <Menu>
      <Menu.Item
        key="reboot"
        icon={<SyncOutlined />}
        onClick={() => showHideBulkActionsRecords(true)}
      >
        {AMA_COMMAND_LIST.REBOOT}
      </Menu.Item>
    </Menu>
  );

  return (
    <EnrollDeviceBox>
      <div className="table-header">
        <div>
          <h3 className="table-title">Enrolled Devices</h3>

          {localUserData?.user_detail?.lastSyncEnrolledDevicesAt && (
            <small>
              {'Last Sync: '}
              {moment(
                localUserData?.user_detail?.lastSyncEnrolledDevicesAt
              ).fromNow()}
            </small>
          )}
        </div>
        <div className="table-right-side">
          <div className="export-generate-btn">
            <Dropdown
              overlay={menu}
              disabled={!hasSelected}
              trigger={['click']}
            >
              <Button>
                {'Bulk Action'} <DownOutlined />
              </Button>
            </Dropdown>
          </div>
        </div>
      </div>
      <div className="while-bg-box">
        <Form layout="inline" className="main-filter-form">
          <div className="left-form">
            <Form.Item name="serialNumber">
              <Input
                type="text"
                name="serialNumber"
                onChange={debounce(
                  (event) => handleFilterParams(event, 'text'),
                  500
                )}
                placeholder="Search Device Serial..."
              />
            </Form.Item>
            <Form.Item name="lastStatusReportTime">
              <DatePicker
                onChange={(event) =>
                  handleFilterParams(event, 'date', 'lastStatusReportTime')
                }
                name="lastStatusReportTime"
              />
            </Form.Item>
          </div>
          <div className="export-generate-btn">
            <div>
              {`Showing Devices: ${
                enrollDevicesList.length || 0
              } / Total Devices: ${totalEnrollDevices}`}
            </div>
          </div>
        </Form>
      </div>
      {selectedDeviceDetails.length >= 1 && showBulkActionsRecords && (
        <div style={{ marginTop: '1rem', marginBottom: '1rem' }}>
          <div className="table-header">
            <h3 className="table-title">
              {`Confirm ${AMA_COMMAND_LIST.REBOOT} Device list`}
            </h3>
            <div className="table-right-side">
              <div style={{ marginRight: '1rem' }}>
                <Button
                  type="primary"
                  onClick={() => handleBulkIssueCommandToDevice()}
                >
                  {'Confirm'}
                </Button>
              </div>
              <div>
                <Button
                  type="secondary"
                  onClick={() => showHideBulkActionsRecords(false)}
                >
                  {'Cancel'}
                </Button>
              </div>
            </div>
          </div>
          <div className="main-table-body">
            <Table
              columns={deleteTableColumns}
              dataSource={selectedDeviceDetails}
              pagination={false}
              size="small"
              scroll={{ y: 300 }}
            />
          </div>
        </div>
      )}
      <div className="main-table-body">
        <Table
          loading={{
            indicator: <Loader>Fetching...</Loader>,
            spinning: isFetching,
          }}
          rowSelection={{
            selectedRowKeys,
            preserveSelectedRowKeys: true,
            onChange: onSelectedRowKeysChange,
          }}
          onChange={handlePaginationChange}
          columns={columns}
          dataSource={enrollDevicesList || []}
          size="small"
          pagination={{ defaultPageSize: 100, total: totalEnrollDevices }}
        />
      </div>
      {modalVisible && (
        <BulkCommandProcessModal
          modalTitle={modalTitle}
          modalVisible={modalVisible}
          handleModal={(value) => handleModal(value)}
          selectedDeviceDetails={selectedDeviceDetails}
        />
      )}
    </EnrollDeviceBox>
  );
};

export default EnrollDevices;

const ENROLL_LABELS = {
  SERIAL_NUMBER: 'Serial Number',
  AMA_ID: 'AMA Id',
  APPLIED_POLICY_NAME: 'Policy name',
  LAST_POLICY_SYNC_TIME: 'Last policy sync time',
  LAST_STATUS_TIME: 'Last status time',
  // USERNAME: 'Username',
  IS_DEVICE_COMPLIANCE: 'Is Compliance?',
  ACTION: 'Action',
};
