import styled from 'styled-components';

export const MapViewBox = styled.div`
  .title {
    font-weight: 600;
    font-size: 34px;
    color: #364257;
    margin-bottom: 20px;
  }
  .map-box {
    margin: 10px;
  }
  .table-header {
    margin: 10px;
    background: white;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 15px;
    border-radius: 4px;
    overflow: hidden;
    h3 {
      margin-bottom: 0px;
    }
    .table-right-side {
      display: flex;
      .export-generate-btn {
        .ant-form-item {
          margin-bottom: 0px;
        }
      }
    }
  }
`;

export const LocationSearchMapViewBox = styled.div`
  position: relative;
  z-index: 1;
  .location-search-input {
    width: 100%;
    font-variant: tabular-nums;
    list-style: none;
    font-feature-settings: 'tnum', 'tnum';
    position: relative;
    display: inline-block;
    width: 100%;
    min-width: 0;
    padding: 4px 11px;
    color: rgba(0, 0, 0, 0.85);
    font-size: 14px;
    line-height: 1.5715;
    background-color: #fff;
    background-image: none;
    border: 1px solid #d9d9d9;
    border-radius: 2px;
    transition: all 0.3s;
    &:focus {
      border-color: #40a9ff;
      border-right-width: 1px !important;
      outline: 0;
      box-shadow: 0 0 0 2px rgb(24 144 255 / 20%);
    }
    &:hover {
      border-color: #40a9ff;
      border-right-width: 1px !important;
      + .close-outline-icon {
        display: block;
      }
    }
  }
  .close-outline-icon {
    display: none;
    cursor: pointer;
    position: absolute;
    right: 2px;
    top: 2px;
    padding: 5px 10px;
    height: 27px;
    width: 34px;
    background: white;
    line-height: 17px;
    border-radius: 10px;
    &:hover {
      display: block;
    }
  }
  .autocomplete-dropdown-container {
    position: absolute;
    background: white;
    width: 100%;
  }
  .blue-border {
    border: 1px solid #40a9ff;
  }
  .copyrightedText {
    font-weight: 600;
  }
`;


export const SelectedPlacesMapViewBox = styled.div`
  .selected-places-box {
    margin-bottom: 15px;
    span {
      display: flex;
      justify-content: space-between;
      padding: 2px 5px;
      margin: 0px;
      align-items: center;
      p {
        margin: 0px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        max-width: 190px;
      }
    }
  }
`;