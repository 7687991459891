import {Form,
  Button,
  DatePicker,
  Input,
  Card,
  Row,
  Col,
  Skeleton,
  Modal,
  Select,
  TimePicker,Table} from "antd";
import React, { useEffect, useState } from "react";
import { Loader } from "../_rides.styled";
import moment from "moment";
import {currentPage, URLS_V2, httpGet } from "utils";
import { Link } from 'react-router-dom';
import { EyeOutlined } from '@ant-design/icons';
const { RangePicker } = DatePicker;

const { Option } = Select;

const statusOptions = (
  <>
    <Option key={'true'} value={'true'}>
      {'Verified'}
    </Option>
    <Option key={'false'} value={'false'}>
      {'Not Verified'}
    </Option>
  </>
);

const OnGoingRides=(props)=>{
  const [onGoingRidesList, setOnGoingRidesList] = useState([]);
  const [totalRides, setTotalRides] = useState(0);
  const [isFetching, setIsFetching] = useState(false);
  const [clearFilterClicked, setClearFilterClicked]=useState(0)

 
  const filterParamsData = {
    startTime: '',
    stopTime: '',
    deviceSerial: '',
    taxiNumber: '',
    isPassengerPresent: '',
  };
  const [filterParams, setFilterParams] = useState(filterParamsData);
  const [searchForm]=Form.useForm()


  useEffect(() => {
    fetchRides();
    fetchRidesTotal();
  }, []);

  useEffect(()=>{
    if(clearFilterClicked>0){
      fetchRides();
      fetchRidesTotal();
    }
      },[clearFilterClicked])

  const fetchRidesTotal = () => {
    setIsFetching(true);
    const queryParams = new URLSearchParams();
    if (filterParams?.startTime)
      queryParams.append('start_time', moment(filterParams?.startTime).unix());
    if (filterParams?.stopTime)
      queryParams.append('stop_time', moment(filterParams?.stopTime).unix());
    if (filterParams?.deviceSerial)
      queryParams.append('device_serial', filterParams?.deviceSerial);
    if (filterParams?.taxiNumber)
      queryParams.append('taxi_number', filterParams?.taxiNumber);
    if (filterParams?.isPassengerPresent)
      queryParams.append(
        'is_passenger_present',
        filterParams?.isPassengerPresent
      );

    const url = `${URLS_V2?.RIDES?.RIDES_LIST_COUNT
      }/?${queryParams.toString()}`;

    httpGet(url)
      .then((res) => {
        setTotalRides(res.data?.ridesCount || 0);
        setIsFetching(false);
      })
      .catch((err) => {
        setIsFetching(false);
      });
  };

  const fetchRides = async ({
    skip = 0,
    limit = 10,
    sortField = 'ride_start_time',
    sortOrder = -1,
    startTime = filterParams?.startTime &&
    moment(filterParams?.startTime).unix(),
    stopTime = filterParams?.stopTime && moment(filterParams?.stopTime).unix(),
    deviceSerial = filterParams?.deviceSerial,
    taxiNumber = filterParams?.taxiNumber,
    isPassengerPresent = filterParams?.isPassengerPresent,
  } = {}) => {
    setIsFetching(true);
    const queryParams = new URLSearchParams();
    queryParams.append('skip', skip);
    queryParams.append('limit', limit);

    if (startTime) queryParams.append('start_time', startTime);
    if (stopTime) queryParams.append('stop_time', stopTime);
    if (deviceSerial) queryParams.append('device_serial', deviceSerial);
    if (taxiNumber) queryParams.append('taxi_number', taxiNumber);
    if (isPassengerPresent)
      queryParams.append('is_passenger_present', isPassengerPresent);
    if (sortField) queryParams.append('orderBy', sortField);
    if (sortOrder) queryParams.append('order', sortOrder === 'ascend' ? 1 : -1);
    const url = `${URLS_V2?.RIDES?.RIDES_LIST}/?${queryParams.toString()}`;
    const res = await httpGet(url);
    // setRidesList(res?.data || []);
    // setTotalRides(res?.data?.count || 0);
    const filteredData = res?.data?.filter((val) => {
      if (val?.ride_ended) {
        return val;
      }
    });

    const onGoingFilteredData = res?.data?.filter((val) => {
      return !val?.ride_ended;
    });

    setOnGoingRidesList(res?.data ? onGoingFilteredData : []);
    // setRidesTempList(res?.data ? filteredData : []);

    setIsFetching(false);
  };

  const handleStartEndTime = (value) => {
    if (value && value.length > 1) {
      setFilterParams({
        ...filterParams,
        startTime: value[0],
        stopTime: value[1],
      });
    } else {
      setFilterParams({
        ...filterParams,
        startTime: '',
        stopTime: '',
      });
    }
  };

  const handleSelectSearch = (selectedValue) => {
    setFilterParams({
      ...filterParams,
      isPassengerPresent: selectedValue,
    });
  };

  const handleTextSearch = (e) => {
    setFilterParams({
      ...filterParams,
      [e.target.name]: e.target.value,
    });
  };

  const handlePaginationChange = (pagination, filters, sorter) => {
    console.log(pagination, filters, sorter, "clockPage");
    const skip = pagination.current * pagination.pageSize - pagination.pageSize;
    const limit = pagination.pageSize;
    const sortField = sorter.field;
    const sortOrder = sorter.order;
    fetchRides({
      skip: skip,
      limit: limit,
      sortField: sortField,
      sortOrder: sortOrder,
    });
  };

  const applyFilterHandler=()=>{
    fetchRides();
    fetchRidesTotal();
  }
  const clearAllFilterHandler =()=>{
  searchForm.resetFields()
    setFilterParams(filterParamsData)
    setClearFilterClicked(clearFilterClicked+1)
  }

  const columns = [
    {
      title: ENROLL_LABELS.SR_NO,
      width: 80,
      render: (value, item, index) =>
        (currentPage(0, 10) - 1) * 100 + index + 1,
    },
    {
      key: 'device_serial',
      title: ENROLL_LABELS.DEVICE_SERIAL,
      dataIndex: 'device_serial',
      sorter: true,
    },
    {
      key: 'taxi_number',
      title: ENROLL_LABELS.TAXI_NUMBER,
      dataIndex: 'taxi_number',
      sorter: true,
    },
    {
      key: 'startTime',
      title: ENROLL_LABELS.START_TIME,
      dataIndex: 'start_time',
      defaultSortOrder: 'descend',
      sorter: true,
      render: (value) => moment.unix(value).format('DD-MM-YYYY hh:mm A'),
    },
    {
      key: 'stopTime',
      title: ENROLL_LABELS.END_TIME,
      dataIndex: 'stop_time',
      sorter: true,
      render: (value) => moment.unix(value).format('DD-MM-YYYY hh:mm A'),
    },
    {
      key: 'ride_time',
      title: ENROLL_LABELS.RIDE_TIME,
      dataIndex: 'ride_time',
      sorter: true,
      render: (data) => {
        return (function Actions() {
          const duration = moment.duration(data, 'seconds');
          const hours = Math.floor(duration.asHours());
          const minutes = duration.minutes();
          const seconds = duration.seconds();
          const convertedTime = `${hours}h : ${minutes}m : ${seconds}s`;
          return <>{data && data >= 1 ? convertedTime : '0'}</>;
        })();
      },
    },
    {
      key: '_id',
      title: ENROLL_LABELS.ACTIONS,
      dataIndex: '_id',
      width: 150,
      render: (_id, item) => {
        return (function Actions() {
          return (
            <div className="actions-btn"   >
              <Link to={`/v2/rides/${_id}`}>
                <Button
                  shape="circle"
                  className="edit-btn"
                  style={{ marginLeft: '0.5rem', cursor: "pointer" }}
                >
                  <EyeOutlined type="primary" className="preview-icon" />
                </Button>
              </Link>
            </div>
          );
        })();
      },
    },
    // {
    //   key: '_id',
    //   title: ENROLL_LABELS.TRACK_RIDE,
    //   dataIndex: '_id',
    //   align: 'center',
    //   render: () => {
    //     return (function Actions() {
    //       return (
    //         <>
    //           <Button
    //             shape="circle"
    //             className="edit-btn"
    //             onClick={() => {
    //               handleTrackRide();
    //             }}
    //           >
    //             <HeatMapOutlined type="primary" />
    //           </Button>
    //         </>
    //       );
    //     })();
    //   },
    // },
    // {
    //   key: 'is_passenger_present',
    //   title: ENROLL_LABELS.IS_RIDE_VERIFIED,
    //   dataIndex: 'is_passenger_present',
    //   sorter: true,
    //   render: (is_passenger_present) => {
    //     return (function Actions() {
    //       return (
    //         <>
    //           {is_passenger_present ? (
    //             <CheckSquareOutlined
    //               style={{ fontSize: '18px', color: '#52c41a' }}
    //             />
    //           ) : (
    //             <CloseSquareOutlined
    //               style={{
    //                 fontSize: '18px',
    //                 color: '#ff4d4f',
    //               }}
    //             />
    //           )}
    //         </>
    //       );
    //     })();
    //   },
    // },
  ];

    // const {onGoingRidesList,totalRides,isFetching,handlePaginationChange,columns}=props
    const defaultTitle = () => {
        return (
          <>
            <div className="cus-table-right-btn">
              {`Showing Records: ${onGoingRidesList?.length} / Total Records: ${totalRides}`}
            </div>
          </>
        );
      };
return(<>
<div className="main-table-body">
<div className="while-bg-box">
        <Form className="main-filter-form" form={searchForm}>
          <Row gutter={[16, 16]}>
            <Col md={6}>
              <Form.Item style={{ marginBottom: '0' }}>
                <RangePicker
                  style={{ width: '100%' }}
                  showTime={{ format: 'HH:mm' }}
                  format="YYYY-MM-DD HH:mm"
                   value={[filterParams?.startTime,filterParams?.stopTime]}
                  onChange={(startEndTime) => handleStartEndTime(startEndTime)}
                />
              </Form.Item>
            </Col>
            <Col md={6}>
              <Form.Item name="deviceSerial" style={{ marginBottom: '0' }}>
                <Input
                  type="text"
                  name="deviceSerial"
                  onChange={handleTextSearch}
                  placeholder="Search Device Serial..."
                />
              </Form.Item>
            </Col>
            <Col md={6}>
              <Form.Item name="taxiNumber" style={{ marginBottom: '0' }}>
                <Input
                  type="text"
                  name="taxiNumber"
                  onChange={handleTextSearch}
                  placeholder="Search Taxi Number..."
                />
              </Form.Item>
            </Col>
            <Col md={6}>
              <Form.Item
                name="is_passenger_present"
                style={{ marginBottom: '0' }}
              >
                <Select
                  showSearch
                  allowClear
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    option.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                  placeholder="Select Ride Verification Status"
                  onChange={handleSelectSearch}
                >
                  {statusOptions}
                </Select>
              </Form.Item>
            </Col>
          </Row>
        </Form>
        <div
         style={{ marginRight:"10px", marginTop:"20px"}}
         >
        <Button type='primary'  onClick={applyFilterHandler}>Apply Filters</Button>
        <Button type='danger' style={{marginLeft:"10px"}} onClick={clearAllFilterHandler}>Clear All</Button>
      </div>

      </div>
        <Table
          title={defaultTitle}
          loading={{
            indicator: <Loader>Fetching...</Loader>,
            spinning: isFetching,
          }}
          onChange={handlePaginationChange}
          columns={columns}
          dataSource={onGoingRidesList || []}
          size="small"
          pagination={{ defaultPageSize: 10, total: onGoingRidesList?.length }}
          scroll={{ x: 400 }}
        />
      </div>
</>)
}

export default OnGoingRides

const ENROLL_LABELS = {
  SR_NO: 'Sr. No.',
  DEVICE_SERIAL: 'Device Serial',
  TAXI_NUMBER: 'Taxi Number',
  START_TIME: 'Start Time',
  END_TIME: 'End Time',
  RIDE_TIME: 'Ride Time',
  TRACK_RIDE: 'Track Ride',
  ACTIONS: "Actions"
  //IS_RIDE_VERIFIED: 'Is Ride Verified?',
  // IMAGE: 'Image',
};