import styled from 'styled-components';

export const AddEditCampaignBox = styled.div`
  padding: 20px 20px;
  .select-company {
    display: flex;
    align-items: center;
    .ant-form-item {
      width: 100%;
    }
    .add-btn {
      margin-top: 7px;
    }
  }
  .collapse-panel {
    marign-bottom: 20px;
  }
  .save-btn {
    margin-top: 31px;
  }
  .draft-btn,
  .delete-btn,
  .inactive-btn {
    margin-top: 20px;
    margin-left: 5px;
  }
  .inactive-btn {
    color: #040404 !important;
    background: #ffc107 !important;
    border-color: #ffc107 !important;
  }
  .active-btn {
    color: #040404 !important;
    background: #4caf50 !important;
    border-color: #4caf50 !important;
  }
  .inactive-btn[disabled] {
    color: rgba(0, 0, 0, 0.25) !important;
    background: #f5f5f5 !important;
    border-color: #d9d9d9 !important;
    text-shadow: none;
    box-shadow: none;
  }
`;

export const CollapseBox = styled.div`
  margin-top: 20px;
`;

export const QrCodeDownloadBox = styled.div`
  font-size: 20px;
  display: flex;
  align-items: center;
  height: 100%;
`;

export const QRCodeBox = styled.div``;
