export const APP_PACKAGE_TYPE = {
  BINARYOS: {
    value: 'com.enterbinary.os',
    label: 'com.enterbinary.os (BINARYOS)',
  },
  DEVICE_MANAGER: {
    value: 'com.enterbinary.devicemanager',
    label: 'com.enterbinary.devicemanager (DEVICE MANAGER)',
  },
};

export const APP_PACKAGE_TYPE_LIST = [
  APP_PACKAGE_TYPE.BINARYOS,
  APP_PACKAGE_TYPE.DEVICE_MANAGER,
];

export const APK_FILE_SIZE = {
  APK_FILE_SIZE: 300000, //size is mentioned in KB
};

export const FB_COLLECTION_NAME = {
  APP_RELEASES: 'app_releases',
};

export const FB_DOCUMENT_NAME = {
  BINARYOS: 'binary_os',
  DEVICE_MANAGER: 'device_manager',
  UNISOC_BINARYOS: 'unisoc_binary_os',
  UNISOC_DEVICE_MANAGER: 'unisoc_device_manager',
};
