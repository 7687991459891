import React from 'react';
import { bool, object /*func*/ } from 'prop-types';
import { Row, Col, Collapse, Skeleton, Progress /*Slider*/ } from 'antd';
import { convertUnixToDateFormat } from 'utils';
import { CollapseBox } from './_device-info.styled';
// import { AudioMutedOutlined } from '@ant-design/icons';

const { Panel } = Collapse;

const DeviceLocation = (props) => {
  const {
    deviceDetailsFB,
    isFetching,
    deviceLocationDetails,
    // handleDeviceCommands,
  } = props;
  const renderLableColumnRows = (
    label,
    value,
    isProgressBar,
    rowGutter = 18,
    lableColSpacing = 6,
    valueColSpacing = 18
  ) => {
    return (
      <Row gutter={rowGutter} style={{ margin: '15px' }}>
        <Col className="gutter-row" xs={lableColSpacing}>
          <strong>{label}</strong>
        </Col>
        <Col className="gutter-row" xs={valueColSpacing}>
          <Skeleton
            loading={isFetching}
            active
            paragraph={{ rows: 1, width: '100%' }}
            title={false}
          >
            {isProgressBar ? <Progress percent={value} /> : value}
          </Skeleton>
        </Col>
      </Row>
    );
  };

  // const onChangeBrightNess = (value) => {
  //   const requestData = {
  //     brightness_level: value,
  //   };
  //   handleDeviceCommands(requestData);
  // };

  // const onChangeVolume = (value) => {
  //   const requestData = {
  //     volume_level: value,
  //   };
  //   handleDeviceCommands(requestData);
  // };

  return (
    <CollapseBox>
      <Collapse defaultActiveKey={['1']}>
        <Panel
          header={`Device Info ${deviceDetailsFB?.updated_at
              ? `(Updated at: ${convertUnixToDateFormat(
                deviceDetailsFB?.updated_at,
                'Do MMMM YYYY hh:mm:ss A'
              )})`
              : ''
            }`}
          key="1"
        >
          {/* {renderLableColumnRows(
            'App Language: ',
            deviceDetailsFB?.app_language
          )} */}
          {renderLableColumnRows(
            'Device Manager App Version: ',
            deviceLocationDetails?.device_manager_version || 0
          )}
          {/* {renderLableColumnRows(
            'Battery: ',
            deviceDetailsFB?.battery_percentage,
            true
          )} */}
          
          {/* <Row gutter={18} style={{ margin: '15px' }}>
            <Col className="gutter-row" xs={6}>
              <strong>{'Brightness: '}</strong>
            </Col>
            <Col className="gutter-row" xs={16}>
              <Skeleton
                loading={isFetching}
                active
                paragraph={{ rows: 1, width: '100%' }}
                title={false}
              >
                <Slider
                  key={
                    typeof deviceDetailsFB?.brightness_percentage === 'number'
                      ? 'b_' + deviceDetailsFB?.brightness_percentage
                      : 'b_' + parseInt(deviceDetailsFB?.brightness_percentage)
                  }
                  min={0}
                  max={100}
                  onAfterChange={onChangeBrightNess}
                  defaultValue={
                    typeof deviceDetailsFB?.brightness_percentage === 'number'
                      ? deviceDetailsFB?.brightness_percentage
                      : parseInt(deviceDetailsFB?.brightness_percentage)
                  }
                />
              </Skeleton>
            </Col>
            <Col className="gutter-row" xs={2}>
              {!isFetching && deviceDetailsFB?.brightness_percentage + '%'}
            </Col>
          </Row>
          <Row gutter={18} style={{ margin: '15px' }}>
            <Col className="gutter-row" xs={6}>
              <strong>{'Volume: '}</strong>
            </Col>
            <Col className="gutter-row" xs={16}>
              <Skeleton
                loading={isFetching}
                active
                paragraph={{ rows: 1, width: '100%' }}
                title={false}
              >
                <Slider
                  key={
                    deviceDetailsFB.is_mute
                      ? 'v_' + 0
                      : typeof deviceDetailsFB?.volume_percentage === 'number'
                      ? 'v_' + deviceDetailsFB.volume_percentage
                      : 'v_' + parseInt(deviceDetailsFB?.volume_percentage)
                  }
                  min={1}
                  max={100}
                  onAfterChange={onChangeVolume}
                  disabled={deviceDetailsFB.is_mute}
                  defaultValue={
                    deviceDetailsFB.is_mute
                      ? 0
                      : typeof deviceDetailsFB?.volume_percentage === 'number'
                      ? deviceDetailsFB.volume_percentage
                      : parseInt(deviceDetailsFB?.volume_percentage)
                  }
                />
              </Skeleton>
            </Col>
            <Col className="gutter-row" xs={2}>
              {!isFetching &&
                (deviceDetailsFB.is_mute ? (
                  <>
                    <AudioMutedOutlined
                      style={{
                        fontSize: '14px',
                        color: '#ff4d4f',
                        marginRight: '0.5rem',
                      }}
                    />
                  </>
                ) : (
                  deviceDetailsFB?.volume_percentage + '%'
                ))}
            </Col>
          </Row> */}
          {/* {renderLableColumnRows(
            'Last Charged At: ',
            deviceDetailsFB?.last_charging_status_change &&
              convertUnixToDateFormat(
                deviceDetailsFB?.last_charging_status_change,
                'Do MMMM YYYY hh:mm A'
              )
          )} */}
          {/* {renderLableColumnRows('Ride Status: ', deviceDetailsFB?.ride_status)} */}
          {/* {renderLableColumnRows(
            'WiFi: ',
            deviceDetailsFB?.is_wifi_on ? deviceDetailsFB?.isWiFiOn : 'Off'
          )}
          {renderLableColumnRows(
            'MUTE: ',
            deviceDetailsFB?.is_mute ? deviceDetailsFB?.isMute : 'Off'
          )}
          {renderLableColumnRows(
            'SLEEP: ',
            deviceDetailsFB?.is_sleep ? deviceDetailsFB?.isSleep : 'Off'
          )} */}
          {renderLableColumnRows(
            'Sim Serial: ',
            deviceLocationDetails?.sim_serial
          )}
          {/* {renderLableColumnRows(
            'Last Updated: ',
            deviceLocationDetails?.updatedAt &&
              formatDate(
                deviceLocationDetails?.updatedAt,
                'Do MMMM YYYY hh:mm:ss A'
              )
          )} */}
        </Panel>
      </Collapse>
    </CollapseBox>
  );
};

DeviceLocation.propTypes = {
  deviceDetailsFB: object,
  deviceLocationDetails: object,
  // handleDeviceCommands: func,
  isFetching: bool,
};

export default DeviceLocation;
