import React from 'react';
import { bool, object } from 'prop-types';
import { Collapse, Table } from 'antd';
import moment from 'moment';
import { CollapseBox, Loader } from './_device-rides.styled';

const { Panel } = Collapse;

const DeviceRides = (props) => {
  const { deviceDetails, isFetching } = props;

  const setAreaNameFromLocationString = (locationArray) => {
    let amount_to_remove = 1;
    if (locationArray.length > 2) {
      amount_to_remove = 2;
    }
    locationArray.splice(
      locationArray.length - amount_to_remove,
      amount_to_remove
    );
    return locationArray;
  };

  const columns = [
    {
      key: 'ride_start_time',
      title: ENROLL_LABELS.START_TIME,
      dataIndex: 'ride_start_time',
      defaultSortOrder: 'descend',
      sorter: true,
      render: (date) => {
        return (function Actions() {
          return (
            <>
              {(date && moment.unix(date).format('DD-MM-YYYY HH:mm:ss')) || ''}
            </>
          );
        })();
      },
    },
    {
      key: 'ride_end_time',
      title: ENROLL_LABELS.END_TIME,
      dataIndex: 'ride_end_time',
      sorter: true,
      render: (date) => {
        return (function Actions() {
          return (
            <>
              {(date && moment.unix(date).format('DD-MM-YYYY HH:mm:ss')) || ''}
            </>
          );
        })();
      },
    },
    {
      key: 'rideTime',
      title: ENROLL_LABELS.RIDE_TIME,
      dataIndex: 'rideTime',
      sorter: true,
      render: (data) => {
        return (function Actions() {
          const rideTime = data / 60;
          return (
            <>
              {(data &&
                `${
                  rideTime < 1
                    ? rideTime.toString().substring(0, 4)
                    : Math.trunc(rideTime)
                } mins`) ||
                ''}
            </>
          );
        })();
      },
    },
    {
      key: 'ride_pickup_location',
      title: ENROLL_LABELS.START_LOCATION,
      dataIndex: 'ride_pickup_location',
      render: (data, item) => {
        return (function Actions() {
          const ridePickupArea =
            (item?.ride_pickup_area &&
              setAreaNameFromLocationString(
                item?.ride_pickup_area.split(' - ')
              )) ||
            [];
          return (
            <>
              {ridePickupArea && ridePickupArea.length >= 1
                ? ridePickupArea.join(', ')
                : (data &&
                    `Lat:${
                      data.location ? data.location.coordinates[0] : ''
                    }, Lng:${
                      data.location ? data.location.coordinates[1] : ''
                    } `) ||
                  ''}
            </>
          );
        })();
      },
    },
    {
      key: 'ride_dropoff_location',
      title: ENROLL_LABELS.END_LOCATION,
      dataIndex: 'ride_dropoff_location',
      render: (data, item) => {
        const rideDropArea =
          (item?.ride_dropoff_area &&
            setAreaNameFromLocationString(
              item?.ride_dropoff_area.split(' - ')
            )) ||
          [];
        return (function Actions() {
          return (
            <>
              {rideDropArea && rideDropArea.length >= 1
                ? rideDropArea.join(', ')
                : (data &&
                    `Lat:${
                      data.location ? data.location.coordinates[0] : ''
                    }, Lng:${
                      data.location ? data.location.coordinates[1] : ''
                    }`) ||
                  ''}
            </>
          );
        })();
      },
    },
  ];

  return (
    <CollapseBox>
      <Collapse defaultActiveKey={['1']}>
        <Panel header="Device Rides" key="1">
          {/* <div className="filter-form">
              <div className="export-generate-btn">
                <div>Total Rides: {deviceDetails?.todaysRidesCount || 0}</div>
              </div>
            </div> */}
          <div className="main-table-body">
            <Table
              rowKey="_id" //this from variable dataSource
              loading={{
                indicator: <Loader>Fetching...</Loader>,
                spinning: isFetching,
              }}
              columns={columns}
              dataSource={deviceDetails?.todaysRide || []}
              size="small"
            />
          </div>
        </Panel>
      </Collapse>
    </CollapseBox>
  );
};

DeviceRides.propTypes = {
  deviceDetails: object,
  isFetching: bool,
};

export default DeviceRides;

const ENROLL_LABELS = {
  START_TIME: 'Start Time',
  END_TIME: 'End Time',
  RIDE_TIME: 'Ride Time',
  START_LOCATION: 'Start Location',
  END_LOCATION: 'End Location',
  // IMAGE: 'Image',
};
