import React, { useState, useEffect } from 'react';
import { bool, func } from 'prop-types';
import { httpPut, URLS, showErrorMsg, showSuccessMsg } from 'utils';
import { Button, Modal, Form, Row, Col, Input } from 'antd';
import { ChangePasswordModalBox } from './_change-password-modal.styled';

const ChangePasswordModal = (props) => {
  const [validateFieldsName, setValidateFieldsName] = useState([]);
  const [isPasswordChanging, setIsPasswordChanging] = useState(false);
  const [form] = Form.useForm();
  const { isModalVisible, closeModal } = props;
  const isDisabled = isPasswordChanging;

  useEffect(() => {
    form.resetFields();
  }, []);

  const handleValidateFieldNames = (name) => {
    const isFieldName = validateFieldsName.find(
      (fieldName) => fieldName === name
    );
    if (isFieldName) return 'onChange';
    return 'onBlur';
  };

  const handleUpdatePassword = () => {
    form.submit();
    form
      .validateFields()
      .then(async (values) => {
        const formData = new FormData();
        formData.append('password', values.current_password);
        formData.append('new_password', values.new_password);
        setIsPasswordChanging(true);
        const url = URLS?.AUTH?.CHANGE_PASSWORD;
        httpPut(url, formData)
          .then((res) => {
            if (res.status === 200) {
              showSuccessMsg('Password updated successfully!');
              form.resetFields();
              closeModal();
            } else if (res?.error) {
              showErrorMsg(res?.error);
            }
            setIsPasswordChanging(false);
          })
          .catch((err) => {
            showErrorMsg(err);
            setIsPasswordChanging(false);
          });
      })
      .catch((errorInfo) => errorInfo);
  };

  const handleOnCancel = () => {
    closeModal();
  };

  let modalfooterButtons = [
    <Button
      key="save"
      type="primary"
      onClick={handleUpdatePassword}
      disabled={isDisabled}
    >
      {isPasswordChanging ? 'Updating...' : 'Update Password'}
    </Button>,
    <Button key="cancel" onClick={handleOnCancel} disabled={isDisabled}>
      Cancel
    </Button>,
  ];

  return (
    <ChangePasswordModalBox>
      <Modal
        title={'Change Password'}
        visible={isModalVisible}
        onCancel={() => !isDisabled && handleOnCancel()}
        className="change-password-modal"
        maskClosable={false}
        footer={modalfooterButtons}
      >
        <Form form={form} layout="vertical">
          <Row gutter={16}>
            <Col className="gutter-row" xs={24}>
              <Form.Item
                name="current_password"
                label="Current Password"
                validateTrigger={handleValidateFieldNames('current_password')}
                rules={[
                  {
                    required: true,
                    message: 'Please enter current password!',
                  },
                  {
                    min: 6,
                    message: 'Password must be atleast 6 characters!',
                  },
                ]}
              >
                <Input.Password
                  placeholder="Current Password"
                  onBlur={() =>
                    setValidateFieldsName([
                      ...validateFieldsName,
                      'current_password',
                    ])
                  }
                />
              </Form.Item>
            </Col>
            <Col className="gutter-row" xs={24}>
              <Form.Item
                name="new_password"
                label="New Password"
                validateTrigger={handleValidateFieldNames('new_password')}
                rules={[
                  {
                    required: true,
                    message: 'Please enter new password!',
                  },
                  {
                    min: 6,
                    message: 'Password must be atleast 6 characters!',
                  },
                ]}
                hasFeedback
              >
                <Input.Password
                  placeholder="New Password"
                  onBlur={() =>
                    setValidateFieldsName([
                      ...validateFieldsName,
                      'new_password',
                    ])
                  }
                />
              </Form.Item>
            </Col>
            <Col className="gutter-row" xs={24}>
              <Form.Item
                name="confirm_password"
                label="Confirm Password"
                validateTrigger={handleValidateFieldNames('confirm_password')}
                dependencies={['new_password']}
                hasFeedback
                rules={[
                  {
                    required: true,
                    message: 'Please enter confirm password!',
                  },

                  ({ getFieldValue }) => ({
                    validator(_, value) {
                      if (!value || getFieldValue('new_password') === value) {
                        return Promise.resolve();
                      }
                      return Promise.reject(
                        new Error(
                          'The two passwords that you entered do not match!'
                        )
                      );
                    },
                  }),
                ]}
              >
                <Input.Password
                  placeholder="Confirm Password"
                  onBlur={() =>
                    setValidateFieldsName([
                      ...validateFieldsName,
                      'confirm_password',
                    ])
                  }
                />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Modal>
    </ChangePasswordModalBox>
  );
};

ChangePasswordModal.propTypes = {
  closeModal: func,
  isModalVisible: bool,
};

export default ChangePasswordModal;
