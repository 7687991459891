import React, { useState, useEffect } from 'react';
import { Table, Form, Button, Dropdown, Menu } from 'antd';
import moment from 'moment';
import { Link } from 'react-router-dom';
import { MODE_TYPES } from 'constants/common';
import { CSVLink } from 'react-csv';
import DeviceModal from 'components/device-modal';
import { EnrollDeviceBox, Loader } from './_devices.styled';
import { httpGet, httpPut, showErrorMsg, showSuccessMsg, URLS } from 'utils';
import {
  FormOutlined,
  DownOutlined,
  CloseOutlined,
  EyeOutlined,
} from '@ant-design/icons';
import CommonListTableWithButton from 'components/common-list-table-with-button';
import { DEVICE_BULK_ACTION_LIST } from 'constants/devices';

const Devices = () => {
  const [isFetching, setIsFetching] = useState(false);
  const [showBulkActionsRecords, setShowBulkActionsRecords] = useState(false);
  const [enrollDevicesList, setEnrollDevicesList] = useState([]);
  const [CSVDownload, setCSVDownload] = useState([]);
  const [activeMode, setActiveMode] = useState();
  const [deviceSerial, setDeviceSerial] = useState();
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [selectedDeviceSerialNumber, setSelectedDeviceSerialNumber] = useState(
    []
  );
  const [selectedDeviceDetails, setSelectedDeviceDetails] = useState([]);
  const [selectedBulkAction, setSelectedBulkAction] = useState([]);

  useEffect(() => {
    fetchEnrollDevices();
  }, []);

  const fetchEnrollDevices = () => {
    setIsFetching(true);
    const url = URLS?.DEVICES?.DEVICES_LIST;
    httpGet(url)
      .then((res) => {
        handleCSVData(res.docs || []);
        if (res?.status === 200) {
          if (res?.data && res?.data.length > 0) {
            const enrollDevicesData = res?.data.map((data) => {
              return {
                ...data,
                key: data?._id,
              };
            });
            setEnrollDevicesList(enrollDevicesData || []);
          }
        }
        setIsFetching(false);
      })
      .catch((err) => {
        setIsFetching(false);
      });
  };

  const handleModeType = ({ activeMode, deviceSerial }) => {
    setDeviceSerial(deviceSerial || '');
    setActiveMode(activeMode);
  };

  const handleDeviceModalCallback = () => {
    fetchEnrollDevices();
  };

  const handleCloseDeviceModal = () => {
    setDeviceSerial('');
    setActiveMode('');
  };

  const handleCSVData = (enrollDevicesData) => {
    let csvData = [];
    enrollDevicesData.forEach((doc) => {
      const data = doc.data() || {};
      const excelStructureData = {
        [ENROLL_LABELS.DEVICE_SERIAL]: data?.device_serial || '',
        [ENROLL_LABELS.SIM_SERIAL]: data?.sim_serial || '',
        [ENROLL_LABELS.TAXI_NUMBER]: data?.taxi_number || '',
        [ENROLL_LABELS.CREATED_AT]:
          (data?.created_at &&
            moment(data?.created_at.toDate().toDateString()).format(
              'DD-MMMM-YYYY'
            )) ||
          '',
      };
      csvData.push(excelStructureData);
    });
    setCSVDownload(csvData);
  };

  const columns = [
    {
      key: 'device_serial',
      title: ENROLL_LABELS.DEVICE_SERIAL,
      dataIndex: 'device_serial',
    },
    {
      key: 'morning_driver_name',
      title: ENROLL_LABELS.MORNING_DRIVER_NAME,
      dataIndex: 'morning_driver_name',
    },
    {
      key: 'morning_driver_mobile',
      title: ENROLL_LABELS.MORNING_DRIVER_MOBILE,
      dataIndex: 'morning_driver_mobile',
    },
    {
      key: 'evening_driver_name',
      title: ENROLL_LABELS.EVENING_DRIVER_NAME,
      dataIndex: 'evening_driver_name',
    },
    {
      key: 'evening_driver_mobile',
      title: ENROLL_LABELS.EVENING_DRIVER_MOBILE,
      dataIndex: 'evening_driver_mobile',
    },
    {
      key: 'sim_serial',
      title: ENROLL_LABELS.SIM_SERIAL,
      dataIndex: 'sim_serial',
    },
    {
      key: 'taxi_number',
      title: ENROLL_LABELS.TAXI_NUMBER,
      dataIndex: 'taxi_number',
    },
    {
      key: 'created_at',
      title: ENROLL_LABELS.CREATED_AT,
      dataIndex: 'created_at',
      render: (date) => {
        return (function Actions() {
          return (
            <>{(date && moment(new Date(date)).format('DD-MM-YYYY')) || ''}</>
          );
        })();
      },
    },
    {
      key: 'is_device_active',
      title: ENROLL_LABELS.ACTIVE,
      dataIndex: 'is_device_active',
      render: (is_active) => {
        return (function Actions() {
          return <div>{is_active ? <p>Active</p> : <p>Inactive</p>}</div>;
        })();
      },
    },
    {
      key: 'device_serial',
      title: ENROLL_LABELS.ACTIONS,
      dataIndex: 'device_serial',
      width: 90,
      render: (deviceSerial) => {
        return (function Actions() {
          return (
            <div className="actions-btn">
              <Button
                shape="circle"
                className="edit-btn"
                onClick={() => {
                  handleModeType({
                    activeMode: MODE_TYPES.EDIT,
                    deviceSerial,
                  });
                }}
              >
                <FormOutlined type="primary" className="edit-icon" />
              </Button>

              <Link to={`/devices/${deviceSerial}`}>
                <Button
                  shape="circle"
                  className="edit-btn"
                  style={{ marginLeft: '0.5rem' }}
                >
                  <EyeOutlined type="primary" className="preview-icon" />
                </Button>
              </Link>
            </div>
          );
        })();
      },
    },
  ];

  const bulkTableColumns = [
    {
      key: 'device_serial',
      title: ENROLL_LABELS.DEVICE_SERIAL,
      dataIndex: 'device_serial',
    },
    {
      key: 'morning_driver_name',
      title: ENROLL_LABELS.MORNING_DRIVER_NAME,
      dataIndex: 'morning_driver_name',
    },
    {
      key: 'sim_serial',
      title: ENROLL_LABELS.SIM_SERIAL,
      dataIndex: 'sim_serial',
    },
    {
      key: 'is_device_active',
      title: ENROLL_LABELS.ACTIVE,
      dataIndex: 'is_device_active',
      render: (is_active) => {
        return (function Actions() {
          return <div>{is_active ? <p>Active</p> : <p>Inactive</p>}</div>;
        })();
      },
    },
    {
      key: '_id',
      title: ENROLL_LABELS.ACTIONS,
      dataIndex: '_id',
      render: (_id) => {
        return (function Actions() {
          return (
            <div className="actions-btn" key={_id}>
              <Button
                danger
                shape="circle"
                className="close-btn"
                onClick={() => removeDeviceFromSelection(_id)}
              >
                <CloseOutlined
                  type="primary"
                  className="close-icon"
                  twoToneColor="#ff4d4f"
                />
              </Button>
            </div>
          );
        })();
      },
    },
  ];

  const removeDeviceFromSelection = (selectedId) => {
    const newSelectedRowKeys = selectedRowKeys.filter(
      (deviceId) => deviceId !== selectedId
    );
    const newSelectedDeviceDetails = selectedDeviceDetails.filter(
      (device) => device._id !== selectedId
    );
    const newSelectedDeviceSerialNumbers = newSelectedDeviceDetails.map(
      (item) => item.device_serial
    );
    setSelectedRowKeys(newSelectedRowKeys);
    setSelectedDeviceDetails(newSelectedDeviceDetails);
    setSelectedDeviceSerialNumber(newSelectedDeviceSerialNumbers);
    if (newSelectedRowKeys.length <= 0) {
      setShowBulkActionsRecords(false);
    }
  };

  const handleBulkActionRequest = () => {
    setIsFetching(true);
    const url = URLS?.DEVICES?.BULK_ACTION_STATUS_CHANGE;
    const data = {
      devices: selectedDeviceSerialNumber,
      status: selectedBulkAction,
    };
    httpPut(url, data)
      .then((res) => {
        if (res.status === 200) {
          showSuccessMsg('Device status updated successfully!');
          fetchEnrollDevices();
        } else if (res?.error) {
          showErrorMsg(res?.error);
        }
        setIsFetching(false);
        setSelectedRowKeys([]);
        setSelectedDeviceDetails([]);
        setSelectedDeviceSerialNumber([]);
      })
      .catch((err) => {
        showErrorMsg(err);
        setIsFetching(false);
      });
  };

  const handelOnCancelBulkRequest = () => {
    setShowBulkActionsRecords(false);
    setSelectedRowKeys([]);
    setSelectedDeviceDetails([]);
    setSelectedDeviceSerialNumber([]);
  };

  const hasSelected = selectedRowKeys.length > 0;

  const onSelectedRowKeysChange = (selectedRowKey, selectedRowItem) => {
    setSelectedRowKeys(selectedRowKey);
    setSelectedDeviceDetails(selectedRowItem);
    if (selectedRowItem && selectedRowItem.length >= 1) {
      const getSelectedDeviceSerialNumbers = selectedRowItem.map(
        (item) => item.device_serial
      );
      setSelectedDeviceSerialNumber(getSelectedDeviceSerialNumbers);
    }
  };

  const handleBulkActionMenuClick = (selectedBulkItem) => {
    setSelectedBulkAction(selectedBulkItem);
    setShowBulkActionsRecords(true);
  };

  const handleBulkIssueCommandToDevice = () => {
    setShowBulkActionsRecords(false);
    handleBulkActionRequest();
  };

  const menu = (
    <Menu>
      {DEVICE_BULK_ACTION_LIST &&
        DEVICE_BULK_ACTION_LIST.length > 0 &&
        DEVICE_BULK_ACTION_LIST.map((item) => (
          <Menu.Item
            key={item.value}
            icon={React.createElement(item.icon)}
            onClick={() => handleBulkActionMenuClick(item.value)}
          >
            {item.label}
          </Menu.Item>
        ))}
    </Menu>
  );

  return (
    <EnrollDeviceBox>
      <div className="table-header">
        <h3 className="table-title">Devices</h3>
        <div className="table-right-side">
          <div className="export-generate-btn">
            <Form.Item>
              {CSVDownload && CSVDownload.length > 0 ? (
                <CSVLink
                  data={CSVDownload}
                  headers={headers}
                  filename={'enroll-devices.csv'}
                >
                  <Button type="primary">Export as Excel</Button>
                </CSVLink>
              ) : (
                <Button type="primary" disabled={true}>
                  Export Excel
                </Button>
              )}
            </Form.Item>
          </div>
          <div className="bulk-action-btn">
            <Dropdown
              overlay={menu}
              disabled={!hasSelected}
              trigger={['click']}
            >
              <Button>
                {'Bulk Actions'} <DownOutlined />
              </Button>
            </Dropdown>
          </div>
        </div>
      </div>
      {selectedDeviceDetails.length >= 1 && showBulkActionsRecords && (
        <CommonListTableWithButton
          tableTitle={'Confirm Device status change to ' + selectedBulkAction}
          columns={bulkTableColumns}
          dataSource={selectedDeviceDetails}
          submitCallBack={handleBulkIssueCommandToDevice}
          cancelCallBack={() => handelOnCancelBulkRequest()}
        />
      )}
      <div className="main-table-body">
        <Table
          loading={{
            indicator: <Loader>Fetching...</Loader>,
            spinning: isFetching,
          }}
          rowSelection={{
            selectedRowKeys,
            preserveSelectedRowKeys: true,
            onChange: onSelectedRowKeysChange,
          }}
          columns={columns}
          dataSource={enrollDevicesList || []}
          size="small"
          pagination={false}
        />
      </div>
      {activeMode && deviceSerial && (
        <DeviceModal
          closeModal={handleCloseDeviceModal}
          docId={deviceSerial}
          activeMode={activeMode}
          callback={handleDeviceModalCallback}
        />
      )}
    </EnrollDeviceBox>
  );
};

export default Devices;

const ENROLL_LABELS = {
  DEVICE_SERIAL: 'Device Serial',
  MORNING_DRIVER_NAME: 'Morning Driver Name',
  MORNING_DRIVER_MOBILE: 'Morning Driver Number',
  EVENING_DRIVER_NAME: 'Evening Driver Name',
  EVENING_DRIVER_MOBILE: 'Evening Driver Number',
  SIM_SERIAL: 'Sim Serial',
  TAXI_NUMBER: 'Taxi Number',
  CREATED_AT: 'Created at',
  ACTIVE: 'Active/Inactive',
  ACTIONS: 'Actions',
};

const headers = [
  { label: ENROLL_LABELS.DEVICE_SERIAL, key: ENROLL_LABELS.DEVICE_SERIAL },
  {
    label: ENROLL_LABELS.MORNING_DRIVER_NAME,
    key: ENROLL_LABELS.MORNING_DRIVER_NAME,
  },
  {
    label: ENROLL_LABELS.MORNING_DRIVER_MOBILE,
    key: ENROLL_LABELS.MORNING_DRIVER_MOBILE,
  },
  {
    label: ENROLL_LABELS.EVENING_DRIVER_NAME,
    key: ENROLL_LABELS.EVENING_DRIVER_NAME,
  },
  {
    label: ENROLL_LABELS.EVENING_DRIVER_MOBILE,
    key: ENROLL_LABELS.EVENING_DRIVER_MOBILE,
  },
  { label: ENROLL_LABELS.SIM_SERIAL, key: ENROLL_LABELS.SIM_SERIAL },
  { label: ENROLL_LABELS.ACTIVE, key: ENROLL_LABELS.ACTIVE },
  { label: ENROLL_LABELS.TAXI_NUMBER, key: ENROLL_LABELS.TAXI_NUMBER },
  { label: ENROLL_LABELS.CREATED_AT, key: ENROLL_LABELS.CREATED_AT },
  { label: ENROLL_LABELS.ACTIONS, key: ENROLL_LABELS.ACTIONS },
];
