import React from 'react';
import SingleRideMapBox from 'components/v2/ride-map';
import { RideMapBox } from './_ride-map.styled';

const RideMapView = () => {
  return (
    <RideMapBox>
      <div className="table-header">
        <h3 className="table-title">Ride Map View</h3>
      </div>
      <div className="map-box">
        <SingleRideMapBox />
      </div>
    </RideMapBox>
  );
};

export default RideMapView;
