import styled from 'styled-components';

export const CollapseBox = styled.div`
  margin-top: 20px;
  .slot-box {
    display: flex;
    margin-bottom: 10px;
    align-items: center;
    .slot-label {
      margin-right: 20px;
      width: 100px;
      text-transform: capitalize;
    }
  }
`;
