import React from 'react';
import { object } from 'prop-types';
import { Collapse } from 'antd';
import { CollapseBox } from './_device-location.styled';
import SingleDeviceMapBox from 'components/single-device-mapbox';

const { Panel } = Collapse;

const DeviceInfo = (props) => {
  const { deviceDetailsFB } = props;

  return (
    <CollapseBox>
      <Collapse defaultActiveKey={['1']}>
        <Panel header="Device Location" key="1">
          <SingleDeviceMapBox location={deviceDetailsFB} />
        </Panel>
      </Collapse>
    </CollapseBox>
  );
};

DeviceInfo.propTypes = {
  deviceDetailsFB: object,
};

export default DeviceInfo;
