import styled from 'styled-components';

export const CollapseBox = styled.div`
  margin: 10px;

  .export-generate-btn {
    .ant-form-item {
      margin-bottom: 0px;
    }

  .filter-form {
    margin: 10px;

    background: white;
    padding: 15px;
    .main-filter-form {
      display: flex;
      width: 100%;
      justify-content: space-between;
      .left-form {
        display: flex;
        flex-wrap: wrap;
      }
      input {
        font-size: 14px;
      }
    }
  }
`;

export const Loader = styled.div`
  color: grey;
  font-size: 14px;
`;
