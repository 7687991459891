import styled from 'styled-components';

export const MainHeaderBox = styled.div`
  .main-header {
    background: white;
    display: flex;
    align-items: center;
    justify-content: space-between;
    /* .logout-btn {
      padding-right: 20px;
    } */
    .trigger {
      margin-left: 20px;
      font-size: 20px;
      color: grey;
    }
    .dropdown-button {
      border: unset;
    }
  }
`;
