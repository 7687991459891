import React, { useEffect, useState } from 'react';
import { string, func } from 'prop-types';
import { Modal } from 'antd';
import ReactPlayer from 'react-player';
import TabletBorder from 'assets/images/tablet-border.png';
import SmsTabletBorder from 'assets/images/sms-tablet-border.png';
import {
  PreviewBox,
  ModalContentBox,
  ModalPlaceholderBox,
  ModalBodyBox,
} from './_preview.styled';
import { MEDIA_TYPES, CAMPAIGN_TYPES } from 'constants/campaign';
import './_modal.css';

const Preview = (props) => {
  const [isVideoPlay, setIsVideoPlay] = useState(true);
  const {
    modalViewType,
    handleOk,
    handleCancel,
    selectMediaImage,
    selectedCampaignType,
    selectMediaVideo,
  } = props;

  useEffect(() => {}, []);

  const onVideoEnd = () => {
    setIsVideoPlay(false);
    setTimeout(() => {
      setIsVideoPlay(true);
    }, 10000);
  };

  return (
    <PreviewBox>
      <Modal
        title="Tablet Preview"
        visible={Boolean(modalViewType)}
        onOk={handleOk}
        onCancel={handleCancel}
        width="100%"
        footer={false}
      >
        <ModalBodyBox>
          <ModalPlaceholderBox>
            <div className="image">
              <img src={selectMediaImage} alt="img" />
            </div>

            <img src={TabletBorder} alt="tablet" />
          </ModalPlaceholderBox>
          <ModalContentBox>
            {(modalViewType === MEDIA_TYPES.VIDEO ||
              (modalViewType === MEDIA_TYPES.VIDEO_IMAGE && isVideoPlay)) && (
              <>
                <div className="video-box" />
                <div className="video">
                  <ReactPlayer
                    url={selectMediaVideo}
                    width="100%"
                    height="100%"
                    playing
                    onEnded={onVideoEnd}
                    controls
                  />
                </div>
                <img src={TabletBorder} alt="tablet" />
              </>
            )}
            {(modalViewType === MEDIA_TYPES.IMAGE ||
              (modalViewType === MEDIA_TYPES.VIDEO_IMAGE && !isVideoPlay)) && (
              <>
                {selectedCampaignType ===
                  CAMPAIGN_TYPES.CAPTURE_LEADS.value && (
                  <>
                    <div className="lead-image">
                      <img src={selectMediaImage} alt="img" />
                    </div>

                    <img src={SmsTabletBorder} alt="tablet" />
                  </>
                )}
                {(selectedCampaignType ===
                  CAMPAIGN_TYPES.BRAND_AWARENESS.value ||
                  selectedCampaignType ===
                    CAMPAIGN_TYPES.WEBSITE_VISITS.value) && (
                  <>
                    <div className="image">
                      <img src={selectMediaImage} alt="img" />
                    </div>

                    <img src={TabletBorder} alt="tablet" />
                  </>
                )}
              </>
            )}
          </ModalContentBox>
        </ModalBodyBox>
      </Modal>
    </PreviewBox>
  );
};

Preview.defaultProps = {
  handleCancel: () => {},
  handleOk: () => {},
  modalViewType: '',
  selectMediaImage: '',
  selectMediaVideo: '',
  selectedCampaignType: '',
};

Preview.propTypes = {
  handleCancel: func,
  handleOk: func,
  modalViewType: string,
  selectMediaImage: string,
  selectMediaVideo: string,
  selectedCampaignType: string,
};

export default Preview;
