import styled from 'styled-components';

export const AppSettingsBox = styled.div`
  .profile-content {
    padding: 20px 10px;
    background-color: white;
    margin-bottom: 20px;
  }

  .while-bg-box {
    margin: 10px;
    background: white;
    padding: 15px;
  }

  .table-header {
    margin: 10px;
    background: white;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 15px;
    border-radius: 4px;
    overflow: hidden;
    h3 {
      margin-bottom: 0px;
    }
    .table-right-side {
      display: flex;
      .export-generate-btn {
        .ant-form-item {
          margin-bottom: 0px;
        }
      }
    }
  }
`;
