import styled from 'styled-components';

export const UsersBox = styled.div`
  padding: 20px 20px;
  .add-user-btn {
    margin: 20px 20px;
  }
`;

export const Loader = styled.div`
  color: grey;
  font-size: 14px;
`;
