import styled from 'styled-components';

export const CampaignsBox = styled.div`
  .table-header {
    margin: 10px;
    background: white;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 15px;
    border-radius: 4px;
    overflow: hidden;
    h3 {
      margin-bottom: 0px;
    }
  }

  .while-bg-box {
    margin: 10px;
    background: white;
    padding: 15px;
  }

  // .main-filter-form {
  //   display: flex;
  //   width: 100%;
  //   justify-content: space-between;
  //   .left-form {
  //     display: flex;
  //     flex-wrap: wrap;
  //   }
  //   input {
  //     font-size: 14px;
  //   }
  // }

  .main-table-body {
    padding-left: 10px;
    padding-right: 10px;
  }
`;

export const Loader = styled.div`
  color: grey;
  font-size: 14px;
`;
