import React, { useState } from 'react';
// import moment from 'moment';
import { oneOfType, func, array, bool, string } from 'prop-types';
import { useParams } from 'react-router-dom';
import {
  Row,
  Col,
  Collapse,
  Form,
  Input,
  Button,
  Spin,
  Popconfirm,
} from 'antd';
import { httpPut, httpDelete, URLS, showErrorMsg, showSuccessMsg } from 'utils';
import { MEDIA_TYPES } from 'constants/campaign';
import {
  UploadOutlined,
  EyeOutlined,
  DeleteOutlined,
  LoadingOutlined,
} from '@ant-design/icons';
import { CollapseBox } from './_group-ads-details.styled';
import Preview from '../preview';

const { TextArea } = Input;
const { Panel } = Collapse;

const GroupAdsDetails = (props) => {
  const {
    handleValidateFieldNames,
    validateFieldsName,
    isGroupAdsImageUpdating,
    selectGroupAdImage,
    selectedCampaignType,
    setIsGroupAdsImageUpdating,
    setSelectGroupAdImage,
    setValidateFieldsName,
  } = props;

  const params = useParams();
  const [modalViewType, setModalViewType] = useState('');

  const closeModal = () => {
    setModalViewType(false);
  };

  const handlePreviewType = ({ type }) => {
    setModalViewType(type);
  };

  const handleImageFile = async (e) => {
    let isSuccess = true;
    const file = e.target.files[0];
    if (
      file.type !== 'image/apng' &&
      file.type !== 'image/avif' &&
      file.type !== 'image/gif' &&
      file.type !== 'image/jpeg' &&
      file.type !== 'image/png' &&
      file.type !== 'image/svg+xml' &&
      file.type !== 'image/webp'
    ) {
      showErrorMsg(
        `Only .apng, .avif, .gif, .jpg, .jpeg, .jfif, .pjpeg, .pjp, .png, .svg, .webp file types are accepted.`
      );
      return;
    }
    // const imageDimensions = await getImageDimensions(file);
    // if (!imageDimensions) return;
    // const { height, width } = imageDimensions || {};
    // if (width === 1920 && height === 1200) {
    //   isSuccess = true;
    // }

    if (isSuccess) {
      setIsGroupAdsImageUpdating(true);
      const url = URLS?.CAMPAIGN?.UPLOAD_GROUP_AD_BG_IMAGE.replace(
        '#ID#',
        params?.id
      );
      const formData = new FormData();
      formData.append('files', e.target.files[0]);
      httpPut(url, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
        .then((res) => {
          if (res.status === 200) {
            showSuccessMsg('Group ads background image saved successfully!');
            setSelectGroupAdImage(res?.data?.media_image_url || '');
          } else if (res?.error) {
            showErrorMsg(res?.error);
          }
          setIsGroupAdsImageUpdating(false);
        })
        .catch((err) => {
          showErrorMsg(err);
          setIsGroupAdsImageUpdating(false);
        });
    } else {
      showErrorMsg('Image resolution must be 1920x1200');
    }
  };

  // const getImageDimensions = (file) => {
  //   return new Promise((resolve) => {
  //     let images = new Image();
  //     images.onload = () => {
  //       resolve({ height: images.height, width: images.width });
  //     };
  //     images.onerror = (error) => {
  //       showErrorMsg(error);
  //       resolve('');
  //     };

  //     let fileReader = new FileReader();
  //     fileReader.onload = () => (images.src = fileReader.result);
  //     fileReader.onerror = (error) => {
  //       showErrorMsg(error);
  //       resolve('');
  //     };
  //     if (file) {
  //       fileReader.readAsDataURL(file);
  //     }
  //   });
  // };

  const handleDeleteFile = ({ fileUrl, type }) => {
    setIsGroupAdsImageUpdating(true);
    const url = URLS?.CAMPAIGN?.DELETE_GC_FILE.replace('#ID#', params?.id)
      .replace('#FILE_URL#', encodeURIComponent(fileUrl))
      .replace('#FILE_TYPE#', type);
    httpDelete(url)
      .then((res) => {
        if (res.status === 200) {
          showSuccessMsg(`${type} file deleted successfully!`);
          setSelectGroupAdImage('');
        } else if (res?.error) {
          showErrorMsg(res?.error);
        }
        setIsGroupAdsImageUpdating(false);
      })
      .catch((err) => {
        showErrorMsg(err);
        setIsGroupAdsImageUpdating(false);
      });
  };

  return (
    <CollapseBox>
      {modalViewType && (
        <Preview
          handleCancel={closeModal}
          modalViewType={modalViewType}
          setModalViewType={setModalViewType}
          handleOk={closeModal}
          selectMediaImage={selectGroupAdImage}
          selectedCampaignType={selectedCampaignType}
        />
      )}
      <Collapse defaultActiveKey={['1']}>
        <Panel header="Group Ads Details" key="1">
          <Row gutter={16}>
            <Col className="gutter-row" xs={24} sm={12}>
              <Form.Item
                name="group_title"
                label="Title"
                validateTrigger={handleValidateFieldNames('group_title')}
                rules={[
                  {
                    required: true,
                    message: 'Please enter title!',
                  },
                ]}
              >
                <Input
                  type="text"
                  placeholder="Title"
                  onBlur={() =>
                    setValidateFieldsName([
                      ...validateFieldsName,
                      'group_title',
                    ])
                  }
                />
              </Form.Item>
            </Col>
            <Col className="gutter-row" xs={24} sm={12}>
              <Form.Item
                name="group_description"
                label="Description"
                validateTrigger={handleValidateFieldNames('group_description')}
                rules={[
                  {
                    required: true,
                    message: 'Please enter description!',
                  },
                ]}
              >
                <TextArea
                  rows={2}
                  placeholder="Description"
                  onBlur={() =>
                    setValidateFieldsName([
                      ...validateFieldsName,
                      'group_description',
                    ])
                  }
                />
              </Form.Item>
            </Col>
            <Col className="gutter-row" xs={24} sm={12}>
              <div className="media-btns">
                {isGroupAdsImageUpdating ? (
                  <Button type="primary" disabled className="loader-btn">
                    <Spin
                      indicator={
                        <LoadingOutlined
                          style={{
                            fontSize: 16,
                            marginRight: 10,
                            color: '#808080b8',
                          }}
                          spin
                        />
                      }
                    />
                    Loading...
                  </Button>
                ) : (
                  <>
                    {selectGroupAdImage ? (
                      <div className="block-box">
                        <Button
                          type="primary"
                          className="preview-btn btn-mr-rt"
                          onClick={() =>
                            handlePreviewType({ type: MEDIA_TYPES.IMAGE })
                          }
                        >
                          <EyeOutlined />
                          Preview Image
                        </Button>
                        <Popconfirm
                          title="Sure to delete image file?"
                          onConfirm={() =>
                            handleDeleteFile({
                              fileUrl: selectGroupAdImage,
                              type: MEDIA_TYPES.IMAGE,
                            })
                          }
                        >
                          <Button
                            danger
                            shape="circle"
                            htmlType="submit"
                            className="delete-btn"
                          >
                            <DeleteOutlined />
                          </Button>
                        </Popconfirm>
                      </div>
                    ) : (
                      <div className="custom-upload-btn">
                        <label htmlFor="group_background_image_url">
                          <div>
                            <UploadOutlined />
                            Upload Group BG Image
                          </div>
                          <input
                            id="group_background_image_url"
                            type="file"
                            name="group_background_image_url"
                            style={{ display: 'none' }}
                            onChange={handleImageFile}
                            accept="image/x-png,image/gif,image/jpeg,image/webp"
                          />
                        </label>
                      </div>
                    )}
                  </>
                )}
              </div>
            </Col>
          </Row>
        </Panel>
      </Collapse>
    </CollapseBox>
  );
};

GroupAdsDetails.propTypes = {
  handleValidateFieldNames: func,
  isGroupAdsImageUpdating: bool,
  selectGroupAdImage: string,
  selectedCampaignType: string,
  setIsGroupAdsImageUpdating: func,
  setSelectGroupAdImage: func,
  setValidateFieldsName: func,
  validateFieldsName: oneOfType([array]),
};

export default GroupAdsDetails;
