import styled from 'styled-components';
import { Spin } from 'antd';

export const WatchContentModalBox = styled.div`
  padding: 20px 20px;
`;

export const UploadImageBox = styled.label`
  .upload-image {
    width: 150px;
    height: 150px;
    margin-right: 8px;
    margin-bottom: 8px;
    text-align: center;
    vertical-align: top;
    background-color: #fafafa;
    border: 1px dashed #d9d9d9;
    border-radius: 2px;
    cursor: pointer;
    transition: border-color 0.3s;
    display: flex;
    flex-direction: column;
    justify-content: center;
    .icon {
      margin-bottom: 10px;
    }
    &:hover {
      border-color: #1890ff;
    }
  }
  .not-allowed {
    cursor: not-allowed;
  }
`;

export const PreviewImageBox = styled.div`
  width: 150px;
  height: 150px;
  position: relative;
  padding: 10px;
  border: 1px dashed #d9d9d9;
  border-radius: 2px;
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 2px;
  }
  .uploaded-pdf {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    height: 100%;
    p {
      margin: 0px;
      overflow-wrap: anywhere;
    }
    img {
      width: 50px;
      object-fit: contain;
    }
  }
  .not-allowed {
    cursor: not-allowed;
  }
  .loading-div {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    height: 100%;
    align-items: center;
    .loading-icon {
      margin-bottom: 10px;
    }
    background: #f1f1f1;
  }
  .icons-div {
    position: absolute;
    color: white;
    width: 100%;
    height: 100%;
    top: 0px;
    left: 0px;
    padding: 10px;
    .icons {
      a {
        color: white;
      }
      opacity: 0;
      background: rgb(0 0 0 / 47%);
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      height: 100%;
      transition: all 0.3s ease-out;
      div {
        padding: 10px;
        cursor: pointer;
      }
    }
  }
  &:hover {
    .icons-div {
      .icons {
        opacity: 1;
        transition: all 0.3s ease-out;
      }
    }
  }
`;

export const CenteredSpin = styled(Spin)`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 200px; /* Adjust the height as per your needs */
`;
