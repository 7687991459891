import React, { useRef, useState } from 'react';
import axios from 'axios';
import { object, func, bool } from 'prop-types';
import { Col, Button, Spin, Popconfirm, Tooltip, Typography } from 'antd';
import {
  // generateGCStorageManagementToken,
  // getGCloudStorageAuthTokenLocalStorage,
  // httpDelete,
  httpGet,
  httpPost,
  showErrorMsg,
  URLS_V2,
} from 'utils';
import { MEDIA_TYPES } from 'constants/campaign';
import {
  UploadOutlined,
  EyeOutlined,
  DeleteOutlined,
  LoadingOutlined,
} from '@ant-design/icons';
import Preview from 'components/watch-content-modal/preview';
import {
  MUSIC_CONTENT_AUDIO_SIZE,
  WATCH_CONTENT_IMAGE_DIMENSIONS,
  WATCH_CONTENT_VIDEO_DIMENSIONS,
} from 'constants/watch-content';
import MediaPreview from 'components/v2/image viewer/MediaPreview';
// import { MODE_TYPES } from 'constants/common';

const { Text } = Typography;

const MusicContentMedia = (props) => {
  const {
    selectMediaImageObject,
    setSelectMediaImageObject,
    setSelectMediaAudioObject,
    setIsMediaImageUpdating,
    isMediaImageUpdating,
    isMediaAudioUpdating,
    setIsMediaAudioUpdating,
    selectMediaAudioObject,
    formDisabled,
    activeMode,
    mediaInitialStructure,
    imageUrl,
    setImageUrl,
    audioUrl,
    setAudioUrl
  } = props;

  const [modalViewType, setModalViewType] = useState('');
  
  const [isImageModalOpen, setIsImageModalOpen] = useState(false);
  const audioPlayerRef = useRef(null);

  // const apiRequestFailedLimit = 2;
  // let apiRequestFailedCount = 0;

  const handlePreviewAudioType = ({ type }) => {
    setModalViewType(type);
    setIsImageModalOpen(true);

    if (type == 'Audio') {
      if (audioPlayerRef?.current?.audioEl !== 'undefined') {
        audioPlayerRef?.current?.audioEl?.current?.play();
      }
    }
  };

  const handlePreviewType = ({ type }) => {
    setModalViewType(type);
    setIsImageModalOpen(true);
  };

  // const uploadMediaToBucket = (file, type) => {
  //   const mediaUploadURL = `${URLS?.WATCH_CONTENT?.UPLOAD_WATCH_CONTENT_MEDIA}?uploadType=media&key=AIzaSyBhn8AEiFg137VExqVzXAm8NJEaKJBCYyQ&name=temp/${file.name}`;
  //   const authToken = getGCloudStorageAuthTokenLocalStorage(); // AMA auth token
  //   console.log('ddhdhdh Harshita', authToken);
  //   const formData = new FormData();
  //   formData.append('file', file);
  //   httpPost(mediaUploadURL, formData, {
  //     headers: {
  //       // Authorization: 'Bearer ' + authToken,
  //       'Content-Type': 'multipart/form-data',
  //     },
  //   })
  //     .then((res) => {
  //       if (res?.id && res?.mediaLink) {
  //         if (type === MEDIA_TYPES.IMAGE) {
  //           setSelectMediaImageObject({
  //             name: file?.name,
  //             url: URL.createObjectURL(file),
  //             isUploaded: true,
  //           });
  //           setIsMediaImageUpdating(false);
  //         } else if (type === MEDIA_TYPES.VIDEO) {
  //           setSelectMediaAudioObject({
  //             name: file?.name,
  //             url: URL.createObjectURL(file),
  //             isUploaded: true,
  //           });
  //           setIsMediaAudioUpdating(false);
  //         }
  //       } else if (res?.error && res?.error?.code === 401) {
  //         const { code, errors } = res?.error || {};
  //         if (
  //           apiRequestFailedCount < apiRequestFailedLimit &&
  //           code === 401 &&
  //           (errors?.[0]?.reason === 'authError' ||
  //             errors?.[0]?.reason === 'CREDENTIALS_MISSING')
  //         ) {
  //           try {
  //             generateGCStorageManagementToken();
  //             setTimeout(() => {
  //               uploadMediaToBucket(file, type);
  //             }, 1000);
  //             apiRequestFailedCount = apiRequestFailedCount + 1;
  //           } catch (authError) {
  //             setIsMediaAudioUpdating(false);
  //             console.log('System is facing error, Please try again later');
  //             throw authError;
  //           }
  //         } else {
  //           showErrorMsg('System is facing error, Please try again later');
  //         }
  //       }
  //     })
  //     .catch((apiExp) => {
  //       const { code, errors } = apiExp?.result?.error || {};
  //       if (
  //         apiRequestFailedCount < apiRequestFailedLimit &&
  //         code === 401 &&
  //         (errors?.[0]?.reason === 'authError' ||
  //           errors?.[0]?.reason === 'CREDENTIALS_MISSING')
  //       ) {
  //         try {
  //           generateGCStorageManagementToken();
  //           setTimeout(() => {
  //             uploadMediaToBucket(file, type);
  //           }, 1000);
  //           apiRequestFailedCount = apiRequestFailedCount + 1;
  //         } catch (authError) {
  //           console.log('System is facing error, Please try again later');
  //           throw authError;
  //         }
  //       } else {
  //         showErrorMsg('System is facing error, Please try again later');
  //       }
  //     });
  // };

  // const deleteMediaFromBucket = ({ file, type }) => {
  //   let objectPath = `temp/${file.name}`;
  //   if (activeMode === MODE_TYPES.EDIT || activeMode === MODE_TYPES.VIEW) {
  //     objectPath = `${file.dest}`;
  //   }
  //   objectPath = window.encodeURIComponent(objectPath);
  //   const mediaDeleteURL = `${URLS?.WATCH_CONTENT?.DELETE_WATCH_CONTENT_MEDIA}/${objectPath}`;
  //   const authToken = getGCloudStorageAuthTokenLocalStorage(); // AMA auth token
  //   httpDelete(mediaDeleteURL, {
  //     headers: {
  //       Authorization: 'Bearer ' + authToken,
  //       'Content-Type': file.type,
  //     },
  //   })
  //     .then((res) => {
  //       if (res?.error && res?.error?.code === 401) {
  //         const { code, errors } = res?.error || {};
  //         if (
  //           apiRequestFailedCount < apiRequestFailedLimit &&
  //           code === 401 &&
  //           (errors?.[0]?.reason === 'authError' ||
  //             errors?.[0]?.reason === 'CREDENTIALS_MISSING')
  //         ) {
  //           try {
  //             generateGCStorageManagementToken();
  //             setTimeout(() => {
  //               deleteMediaFromBucket({ file, type });
  //             }, 1000);
  //             apiRequestFailedCount = apiRequestFailedCount + 1;
  //           } catch (authError) {
  //             setIsMediaAudioUpdating(false);
  //             console.log('System is facing error, Please try again later');
  //             throw authError;
  //           }
  //         } else {
  //           showErrorMsg('System is facing error, Please try again later');
  //         }
  //       } else {
  //         if (type === MEDIA_TYPES.IMAGE) {
  //           setSelectMediaImageObject(mediaInitialStructure);
  //           setIsMediaImageUpdating(false);
  //         } else if (type === MEDIA_TYPES.VIDEO) {
  //           setSelectMediaAudioObject(mediaInitialStructure);
  //           setIsMediaAudioUpdating(false);
  //         }
  //       }
  //     })
  //     .catch((apiExp) => {
  //       const { code, errors } = apiExp?.result?.error || {};
  //       if (
  //         apiRequestFailedCount < apiRequestFailedLimit &&
  //         code === 401 &&
  //         (errors?.[0]?.reason === 'authError' ||
  //           errors?.[0]?.reason === 'CREDENTIALS_MISSING')
  //       ) {
  //         try {
  //           generateGCStorageManagementToken();
  //           setTimeout(() => {
  //             uploadMediaToBucket(file, type);
  //           }, 1000);
  //           apiRequestFailedCount = apiRequestFailedCount + 1;
  //         } catch (authError) {
  //           console.log('System is facing error, Please try again later');
  //           throw authError;
  //         }
  //       } else {
  //         showErrorMsg('System is facing error, Please try again later');
  //       }
  //     });
  // };

  const uploadMediaToBucket = async (file, type) => {
    //setAPKUploading(true);
    let url = URLS_V2?.UPLOAD_MEDIA?.UPLOAD_FILES;

    let formData = new FormData();
    if (type == 'Image') {
      formData.append('musicImage', file);
    }
    if (type == 'Audio') {
      formData.append('musicFile', file);
    }

    httpPost(url, formData)
      .then((res) => {
        if (res?.status == 200) {
          if (type == 'Image') {
            setSelectMediaImageObject(file);
            setImageUrl(res?.data?.image_Url);
            setIsMediaImageUpdating(false);
          }
          if (type == 'Audio') {
            setSelectMediaAudioObject(file);
            setAudioUrl(res?.data?.audio_url);
            setIsMediaAudioUpdating(false);
          }
        }
      })
      //   .then(async (res) => {

      //     if (res.status === 200) {
      //       try {
      //         await axios.put(res.data.signedUrl, file, {
      //           headers: {
      //             'Content-Type': file.type,
      //           },
      //         });
      //         const baseUrl = 'https://storage.googleapis.com';
      //         const bucketName ='binaryos-312113.appspot.com'
      //         // .env.REACT_APP_CLOUD_STORAGE_MANAGEMENT_BUCKET; // Replace with your actual bucket name
      //         const filePath = `temp/${file.name}`; // Replace with the desired folder path
      //         const fileUrl = `${baseUrl}/${bucketName}/${filePath}`;
      //         if (fileUrl) {
      //           if (type === MEDIA_TYPES.IMAGE) {
      //             setSelectMediaImageObject(
      //             //   {
      //             //   name: file?.name,
      //             //   url: fileUrl,
      //             //   isUploaded: true,
      //             // }

      //             file
      //           );
      //             setIsMediaImageUpdating(false);
      //           } else if (type === MEDIA_TYPES.VIDEO) {
      //             setSelectMediaAudioObject(
      //               file
      //             //   {
      //             //   name: file?.name,
      //             //   url: fileUrl,
      //             //   isUploaded: true,
      //             // }
      //           );
      //             setIsMediaAudioUpdating(false);
      //           }
      //         }
      //       } catch (error) {
      //         showErrorMsg(error);
      //         setIsMediaAudioUpdating(false);
      //         setIsMediaImageUpdating(false);
      //       }
      //     }
      //   })
      .catch((err) => {
        showErrorMsg(err);
        if (type == 'Audio') {
          setIsMediaAudioUpdating(false);
        }
        if (type == 'Image') {
          setIsMediaImageUpdating(false);
        }
      });
  };

  const deleteMediaFromBucket = ({ file, type }) => {
    if (type == 'Image') {
      setSelectMediaImageObject('');
      setIsMediaImageUpdating(false);
    }
    if(type=='Audio'){
        setSelectMediaAudioObject('')
        setIsMediaAudioUpdating(false)
    }

    // let url = `${URLS_V2?.APP_RELEASE_MANAGER?.GET_SIGNEDURL}?filename=${file}&action=delete`;
    // httpGet(url)
    //   .then(async (res) => {
    //     if (res.status === 200) {
    //       try {
    //         await axios.delete(res.data.signedUrl);
    //         if (type === MEDIA_TYPES.IMAGE) {
    //           setSelectMediaImageObject(mediaInitialStructure);
    //           setIsMediaImageUpdating(false);
    //         } else if (type === MEDIA_TYPES.VIDEO) {
    //           setSelectMediaAudioObject(mediaInitialStructure);
    //           setIsMediaAudioUpdating(false);
    //         }
    //       } catch (error) {
    //         showErrorMsg(error);
    //         setIsMediaImageUpdating(false);
    //         setIsMediaAudioUpdating(false);
    //       }
    //     }
    //   })
    //   .catch((err) => {
    //     showErrorMsg(err);
    //     setIsMediaImageUpdating(false);
    //     setIsMediaAudioUpdating(false);
    //   });
  };

  const handleImageFile = async (e) => {
    const file = e.target.files[0];

    setIsMediaImageUpdating(true);

    if (
      file.type !== 'image/apng' &&
      file.type !== 'image/jpeg' &&
      file.type !== 'image/png'
    ) {
      showErrorMsg(`Only .jpg, .jpeg, .pjpeg, .png, file types are accepted.`);
      setIsMediaImageUpdating(false);
      return;
    }

    const imageSize =
      file.size / 1024 > WATCH_CONTENT_IMAGE_DIMENSIONS.FILE_SIZE;
    if (imageSize) {
      showErrorMsg(
        `Thumb size must be smaller then ${WATCH_CONTENT_IMAGE_DIMENSIONS.FILE_SIZE} KB`
      );
      setIsMediaImageUpdating(false);
      return;
    }

    // const imageDimensions = await getImageDimensions(file);
    await getImageDimensions(file);
    // if (
    //   imageDimensions.width !== WATCH_CONTENT_IMAGE_DIMENSIONS.FILE_WIDTH ||
    //   imageDimensions.height !== WATCH_CONTENT_IMAGE_DIMENSIONS.FILE_HEIGHT
    // ) {
    //   showErrorMsg(
    //     `Thumb does not have optimal dimensions. Recommended image dimensions are ${WATCH_CONTENT_IMAGE_DIMENSIONS.FILE_WIDTH} X ${WATCH_CONTENT_IMAGE_DIMENSIONS.FILE_HEIGHT}.`
    //   );
    //   setIsMediaImageUpdating(false);
    //   return;
    // }

    uploadMediaToBucket(file, MEDIA_TYPES.IMAGE);
  };

  const getImageDimensions = (file) => {
    return new Promise((resolve) => {
      let images = new Image();
      images.onload = () => {
        resolve({ height: images.height, width: images.width });
      };
      images.onerror = (error) => {
        showErrorMsg(error);
        resolve('');
      };

      let fileReader = new FileReader();
      fileReader.onload = () => (images.src = fileReader.result);
      fileReader.onerror = (error) => {
        showErrorMsg(error);
        resolve('');
      };
      if (file) {
        fileReader.readAsDataURL(file);
      }
    });
  };

  const handleVideoFile = async (e) => {
    setIsMediaAudioUpdating(true);
    const file = e.target.files[0];

    if (
      file.type !== 'audio/mp3' &&
      file.type !== 'audio/wav' &&
      file.type !== 'audio/mpeg'
      //   file.type !== 'video/mp4' &&
      //   file.type !== 'video/mpeg' &&
      //   file.type !== 'video/webm' &&
      //   file.type !== 'video/x-m4v'
    ) {
      showErrorMsg(
        `Only .mp3, .wav file types are accepted.`

        // `Only .mp4, .m4a, .m4p, .m4b, .m4r, .m4v, .m1v,	.mov,
        //  .webm, .m4v file types are accepted.`
      );
      setIsMediaAudioUpdating(false);
      return;
    }

    const videoSize = file.size / 1024 > MUSIC_CONTENT_AUDIO_SIZE.FILE_SIZE;
    if (videoSize) {
      showErrorMsg(
        `Audio size must be smaller then ${
          MUSIC_CONTENT_AUDIO_SIZE.FILE_SIZE / 1000
        } MB`
      );
      setIsMediaAudioUpdating(false);
      return;
    }

    uploadMediaToBucket(file, MEDIA_TYPES.AUDIO);
  };

  const returnVideoUploadNote = () => {
    return (
      <>
        <div style={{ color: '#fff' }}>
          <Text style={{ color: '#fff !important' }}>{`Max file size: ${
            WATCH_CONTENT_VIDEO_DIMENSIONS.FILE_SIZE / 1000
          } MB`}</Text>
        </div>
        {/* <div style={{ color: '#fff' }}>
          <Text>{`Resolution: ${WATCH_CONTENT_VIDEO_DIMENSIONS.FILE_WIDTH} X ${WATCH_CONTENT_VIDEO_DIMENSIONS.FILE_HEIGHT}`}</Text>
        </div> */}
      </>
    );
  };

  const returnImageUploadNote = () => {
    return (
      <>
        <div style={{ color: '#fff' }}>
          <Text
            style={{ color: '#fff !important' }}
          >{`Max file size: ${WATCH_CONTENT_IMAGE_DIMENSIONS.FILE_SIZE} KB`}</Text>
        </div>
        {/* <div style={{ color: '#fff' }}>
          <Text>{`Resolution: ${WATCH_CONTENT_IMAGE_DIMENSIONS.FILE_WIDTH} X ${WATCH_CONTENT_IMAGE_DIMENSIONS.FILE_HEIGHT}`}</Text>
        </div> */}
      </>
    );
  };


  return (
    <>
      <MediaPreview
        modalViewType={modalViewType}
        fileUrl={
          (modalViewType == 'Image' && imageUrl) ||
          (modalViewType == 'Audio' && audioUrl)
        }
        isModalOpen={isImageModalOpen}
        setIsModalOpen={setIsImageModalOpen}
        audioPlayerRef={audioPlayerRef}
        //   handleCancel={closeModal}
        //   modalViewType={modalViewType}
        //   setModalViewType={setModalViewType}
        //   handleOk={closeModal}
        //   selectMediaImageObject={selectMediaImageObject}
        //   selectMediaAudioObject={selectMediaAudioObject}
      />

      <Col className="gutter-row" xs={24} sm={12}>
        <>
          {isMediaImageUpdating ? (
            <Button type="primary" disabled className="loader-btn">
              <Spin
                indicator={
                  <LoadingOutlined
                    style={{
                      fontSize: 16,
                      color: '#808080b8',
                    }}
                    spin
                  />
                }
              />
              Uploading...
            </Button>
          ) : (
            <>
              {selectMediaImageObject ? (
                <div className="ant-row ant-form-item">
                  <div>
                    <Button
                      type="primary"
                      className="preview-btn btn-mr-rt"
                      onClick={() =>
                        handlePreviewType({ type: MEDIA_TYPES.IMAGE })
                      }
                    >
                      <EyeOutlined />
                      Preview Image
                    </Button>
                    {activeMode !== 'View' && (
                      <Popconfirm
                        title="Sure to delete image file?"
                        onConfirm={() =>
                          deleteMediaFromBucket({
                            type: MEDIA_TYPES.IMAGE,
                            // file: selectMediaImageObject.name,
                          })
                        }
                      >
                        <Button
                          danger
                          shape="circle"
                          htmlType="submit"
                          className="delete-btn"
                          style={{ marginLeft: '1rem' }}
                        >
                          <DeleteOutlined />
                        </Button>
                      </Popconfirm>
                    )}
                  </div>
                </div>
              ) : (
                <div className="ant-row ant-form-item">
                  <label htmlFor="media_image_url" style={{ width: 0 }}>
                    <Tooltip title={returnImageUploadNote} color={'#fff'}>
                      <div className="ant-btn">
                        <UploadOutlined />
                        {'Upload Thumb'}
                      </div>
                    </Tooltip>
                    <input
                      id="media_image_url"
                      type="file"
                      name="media_image_url"
                      style={{ display: 'none' }}
                      onChange={handleImageFile}
                      accept="image/x-png,image/jpeg,image/webp"
                    />
                  </label>
                </div>
              )}
            </>
          )}
        </>
      </Col>
      <Col className="gutter-row" xs={24} sm={12}>
        <>
          {isMediaAudioUpdating ? (
            <Button type="primary" disabled className="loader-btn btn-mr-rt">
              <Spin
                indicator={
                  <LoadingOutlined
                    style={{
                      fontSize: 16,
                      marginRight: 10,
                      color: '#808080b8',
                    }}
                    spin
                  />
                }
              />
              Uploading...
            </Button>
          ) : (
            <>
              {selectMediaAudioObject ? (
                <div className="ant-row ant-form-item">
                  <div>
                    <Button
                      type="primary"
                      className="preview-btn btn-mr-rt"
                      onClick={() => handlePreviewAudioType({ type: 'Audio' })}
                    >
                      <EyeOutlined />
                      {'Listen Audio'}
                    </Button>
                    {activeMode !== 'View' && (
                      <Popconfirm
                        title="Sure to delete video file?"
                        onConfirm={() =>
                          deleteMediaFromBucket({
                            type: MEDIA_TYPES.AUDIO,
                            file: selectMediaAudioObject,
                          })
                        }
                      >
                        <Button
                          danger
                          shape="circle"
                          htmlType="submit"
                          className="video-delete-btn"
                          style={{ marginLeft: '1rem' }}
                        >
                          <DeleteOutlined />
                        </Button>
                      </Popconfirm>
                    )}
                  </div>
                </div>
              ) : (
                <div className="ant-row ant-form-item">
                  <label htmlFor="media_video_url" style={{ width: 0 }}>
                    <Tooltip title={returnVideoUploadNote} color={'#fff'}>
                      <div className="ant-btn">
                        <UploadOutlined />
                        {'Upload Audio'}
                      </div>
                    </Tooltip>
                    <input
                      id="media_video_url"
                      type="file"
                      name="media_video_url"
                      style={{ display: 'none' }}
                      onChange={handleVideoFile}
                      accept="/audio/mp3/wav/"
                    />
                  </label>
                </div>
              )}
            </>
          )}
        </>
      </Col>
    </>
  );
};

// MusicContentMedia.propTypes = {
//   // activeMode: string,
//   formDisabled: bool,
//   isMediaImageUpdating: bool,
//   isMediaAudioUpdating: bool,
//   mediaInitialStructure: object,
//   selectMediaImageObject: object,
//   selectMediaAudioObject: object,
//   setIsMediaImageUpdating: func,
//   setIsMediaAudioUpdating: func,
//   setSelectMediaImageObject: func,
//   setSelectMediaAudioObject: func,
// };

export default MusicContentMedia;
