import React, { useState, useEffect } from 'react';
import {
  Dropdown,
  Menu,
  Skeleton,
  Button,
  Row,
  Col,
  Popconfirm,
  Card,
} from 'antd';
import { useParams, useHistory } from 'react-router-dom';
import {
  DeviceDetailsPageBox,
  // Loader,
} from './_device-detail-page.styled';
import {
  // FormOutlined,
  WifiOutlined,
  MobileOutlined,
  AudioOutlined,
  AudioMutedOutlined,
  UsbOutlined,
  UnlockOutlined,
  LockOutlined,
  BulbOutlined,
  SoundOutlined,
  DownOutlined,
} from '@ant-design/icons';
import {
  URLS,
  formatDate,
  showErrorMsg,
  httpPut,
  showSuccessMsg,
  httpGet,
} from 'utils';
import { firebaseDb } from 'configs/firebase';
import { DEVICE_BULK_ACTION_LIST } from 'constants/devices';
import DeviceInfo from './device-info';
import DeviceLocation from './device-location';
import RidesInfo from './rides-info';
import StatisticCardBlock from 'components/statistic-card-block';
import DeviceRides from './device-rides';

const DeviceDetailsPage = () => {
  const params = useParams();
  const [deviceDetailsFB, setDeviceDetailsFB] = useState({});
  const [deviceDetails, setDeviceDetails] = useState({});
  const [isFetchingFB, setIsFetchingFB] = useState(true);
  const [isFetching, setIsFetching] = useState(true);
  let history = useHistory();

  console.log(deviceDetailsFB, "deviceDet");

  useEffect(() => {
    if (!params?.device_serial) {
      // redirect to the back page from here
      handleHistoryToGoBack();
    }
    fetchDeviceAndRideDetails(params?.device_serial);
    fetchDeviceDetailsFromFBRealTime(params?.device_serial);
  }, []);

  const fetchDeviceAndRideDetails = (deviceSerial) => {
    setIsFetching(true);
    const url = URLS?.DEVICES?.GET_DEVICE_AND_RIDE_DETAILS_BY_ID.replace(
      '#DEVICE_SERIAL#',
      deviceSerial
    );
    httpGet(url)
      .then((res) => {
        if (res?.status === 200) {
          if (res?.data) {
            setDeviceDetails(res?.data || []);
          }
        }
        setIsFetching(false);
      })
      .catch((err) => {
        setIsFetching(false);
      });
  };

  const handleHistoryToGoBack = () => {
    // redirect to the back page from here
    showErrorMsg('Device details not found');
    history.goBack();
  };

  const muteIconRender = (value) => {
    return (
      <>
        {value ? (
          <>
            <AudioMutedOutlined
              style={{
                fontSize: '18px',
                color: '#ff4d4f',
                marginRight: '0.5rem',
              }}
            />
            {`Mute`}
          </>
        ) : (
          <>
            <AudioOutlined
              style={{
                fontSize: '18px',
                color: '#52c41a',
                marginRight: '0.5rem',
              }}
            />
            {`Unmute`}
          </>
        )}
      </>
    );
  };

  const wifiIconRender = (value, wifiName) => {
    return (
      <>
        <WifiOutlined
          style={{
            fontSize: '18px',
            color: value ? '#52c41a' : '#ff4d4f',
            marginRight: '0.5rem',
          }}
        />
        {value ? wifiName : 'Off'}
      </>
    );
  };
  const chargingIconRender = (value) => {
    return (
      <>
        <UsbOutlined
          style={{
            fontSize: '18px',
            color: value ? '#52c41a' : '#ff4d4f',
            marginRight: '0.5rem',
          }}
        />
        {value ? 'Charging' : 'Not Charging'}
      </>
    );
  };
  const sleepIconRender = (value) => {
    return (
      <>
        <MobileOutlined
          style={{
            fontSize: '18px',
            color: value ? '#ff4d4f' : '#52c41a',
            marginRight: '0.5rem',
          }}
        />
        {value ? 'Sleep' : 'Working'}
      </>
    );
  };

  const screenOnIconRender = (value) => {
    return (
      <>
        {value ? (
          <>
            <UnlockOutlined
              style={{
                fontSize: '18px',
                color: value ? '#52c41a' : '#ff4d4f',
                marginRight: '0.5rem',
              }}
            />
            {'Unlocked'}
          </>
        ) : (
          <>
            <LockOutlined
              style={{
                fontSize: '18px',
                color: value ? '#52c41a' : '#ff4d4f',
                marginRight: '0.5rem',
              }}
            />
            {'Locked'}
          </>
        )}
      </>
    );
  };

  const batteryIconRender = (value) => {
    return (
      <>
        <UsbOutlined
          style={{
            fontSize: '1rem',
            color: '#faad14',
            marginRight: '0.5rem',
          }}
        />
        {`${value?.includes('%') ? value : value + '%'}`}
      </>
    );
  };

  const brightnessIconRender = (value) => {
    return (
      <>
        <BulbOutlined
          style={{
            fontSize: '1rem',
            color: '#faad14',
            marginRight: '0.5rem',
          }}
        />
        {`${value && value?.includes('%') ? value : value + '%'}`}
      </>
    );
  };

  const volumeIconRender = (value) => {
    return (
      <>
        <SoundOutlined
          style={{
            fontSize: '1rem',
            color: '#faad14',
            marginRight: '0.5rem',
          }}
        />
        {`${value.includes('%') ? value : value + '%'}`}
      </>
    );
  };

  const fetchDeviceDetailsFromFBRealTime = (deviceSerial) => {
    setIsFetchingFB(true);
    const deviceStatusColRef = firebaseDb.ref('device_status');
    const deviceNodeRef = deviceStatusColRef.child(deviceSerial);

    deviceNodeRef.on('value', (response) => {
      const firebaseDeviceResp = response.val();
      if (!response.exists() || !firebaseDeviceResp) {
        handleHistoryToGoBack();
      }
      let tempDeviceDetails = {};

      const {
        device_serial,
        current_lat,
        current_lng,
        is_charging,
        is_mute,
        is_screen_on,
        is_sleep,
        is_WifiOn,
        required_wifi_name,
        is_device_active,
        updated_at,
        last_charging_status_change,
        battery_percent,
        brightness_level,
        volume_level,
      } = firebaseDeviceResp || {};

      tempDeviceDetails = {
        ...firebaseDeviceResp,
        key: device_serial,
        position: {
          lat: current_lat || '',
          lng: current_lng || '',
        },
        batteryPercent: battery_percent && batteryIconRender(battery_percent),
        brightnessLevel:
          brightness_level && brightnessIconRender(brightness_level),
        volumeLevel: volume_level && volumeIconRender(volume_level),
        isCharging: chargingIconRender(is_charging),
        isMute: muteIconRender(is_mute),
        isScreenOn: screenOnIconRender(is_screen_on),
        isSleep: sleepIconRender(is_sleep),
        isWiFiOn: wifiIconRender(is_WifiOn, required_wifi_name),
        isDeviceActive: is_device_active ? 'Active' : 'Inactive',
        updatedAt: updated_at ? formatDate(updated_at) : '',
        lastChargedAt: last_charging_status_change
          ? formatDate(last_charging_status_change)
          : '',
      };
      setDeviceDetailsFB(tempDeviceDetails);
      setTimeout(() => {
        setIsFetchingFB(false);
      }, 1000);
    });
  };

  const renderStatisticDivBlock = (title, value) => {
    return (
      <>
        <h3>{title}</h3>
        <Skeleton
          loading={isFetchingFB}
          active
          paragraph={{ rows: 1, width: '100%' }}
          title={false}
        >
          <div>{value}</div>
        </Skeleton>
      </>
    );
  };

  const menu = (
    <Menu>
      {DEVICE_BULK_ACTION_LIST &&
        DEVICE_BULK_ACTION_LIST.length > 0 &&
        DEVICE_BULK_ACTION_LIST.map((item) => (
          <Popconfirm
            title={<>{`Are you sure you want to ${item.label} this device?`}</>}
            onConfirm={() =>
              handleBulkActionRequest(
                deviceDetailsFB?.device_serial,
                item.value
              )
            }
            key={item.value}
          >
            <Menu.Item key={item.value} icon={React.createElement(item.icon)}>
              {item.label}
            </Menu.Item>
          </Popconfirm>
        ))}
    </Menu>
  );

  const handleBulkActionRequest = (deviceSerial, actionType) => {
    if (deviceSerial) {
      setIsFetching(true);
      const url = URLS?.DEVICES?.BULK_ACTION_STATUS_CHANGE;
      const data = {
        devices: [deviceSerial],
        status: actionType,
      };
      httpPut(url, data)
        .then((res) => {
          if (res.status === 200) {
            showSuccessMsg('Device status updated successfully!');
            fetchDeviceDetailsFromFBRealTime(deviceSerial);
          } else if (res?.error) {
            showErrorMsg(res?.error);
          }
          setIsFetching(false);
        })
        .catch((err) => {
          showErrorMsg(err);
          setIsFetching(false);
        });
    }
  };

  const renderRidesStatisticsCard = (
    cardTitle,
    totalRide,
    totalRideTime,
    totalRideAvgTime
  ) => {
    return (
      <Card title={cardTitle} bordered={false}>
        <Skeleton
          loading={isFetching}
          active
          paragraph={{ rows: 2, width: '100%' }}
          title={false}
        >
          <Row gutter={32}>
            <Col span={6}>
              <StatisticCardBlock
                title={'total rides'}
                value={(totalRide && totalRide.toString()) || '0'}
                valueStyle={{ color: '#3f8600' }}
              />
            </Col>
            <Col span={9}>
              <StatisticCardBlock
                title="total ride time minutes"
                value={(totalRideTime && totalRideTime.toString()) || '0'}
                precision={0}
                valueStyle={{ color: '#3f8600' }}
              />
            </Col>
            <Col span={9}>
              <StatisticCardBlock
                title="avg per day minutes"
                value={(totalRideAvgTime && totalRideAvgTime.toString()) || '0'}
                precision={0}
                valueStyle={{ color: '#3f8600' }}
              />
            </Col>
          </Row>
        </Skeleton>
      </Card>
    );
  };

  return (
    <DeviceDetailsPageBox>
      <div className="table-header">
        <div>
          <h3 className="table-title">{deviceDetailsFB?.device_serial}</h3>
          <label className="table-title">{deviceDetailsFB?.sim_serial}</label>
        </div>
        <div className="table-right-side">
          <div className="export-generate-btn">
            <Dropdown overlay={menu} trigger={['click']}>
              <Button>
                {'Action'} <DownOutlined />
              </Button>
            </Dropdown>
          </div>
        </div>
      </div>
      <div className="site-statistic-demo-card c-m-10">
        <Row gutter={14}>
          <Col span={8}>
            {renderRidesStatisticsCard(
              'Last 12 Hours',
              deviceDetails?.last12HRRides?.total_ride,
              deviceDetails?.last12HRRides?.total_ride_time / 60,
              deviceDetails?.last12HRRides?.avg_ride_time / 60
            )}
          </Col>
          <Col span={8}>
            {renderRidesStatisticsCard(
              'Last 7 Days',
              deviceDetails?.last7DaysRides?.total_ride,
              deviceDetails?.last7DaysRides?.total_ride_time / 60,
              deviceDetails?.last7DaysRides?.avg_ride_time / 60
            )}
          </Col>
          <Col span={8}>
            {renderRidesStatisticsCard(
              'Last 30 Days',
              deviceDetails?.last30DaysRides?.total_ride,
              deviceDetails?.last30DaysRides?.total_ride_time / 60,
              deviceDetails?.last30DaysRides?.avg_ride_time / 60
            )}
          </Col>
        </Row>
      </div>
      <div className="device-details-header">
        <div>
          {renderStatisticDivBlock('Battery', deviceDetailsFB?.batteryPercent)}
        </div>
        <div className="vr-solid" />
        <div>
          {renderStatisticDivBlock(
            'Brightness',
            deviceDetailsFB?.brightnessLevel
          )}
        </div>
        <div className="vr-solid" />
        <div>
          {renderStatisticDivBlock('Charging', deviceDetailsFB?.isCharging)}
        </div>
        <div className="vr-solid" />
        <div>{renderStatisticDivBlock('Is Mute', deviceDetailsFB?.isMute)}</div>
        <div className="vr-solid" />
        <div>
          {renderStatisticDivBlock('Screen On', deviceDetailsFB?.isScreenOn)}
        </div>
        <div className="vr-solid" />
        <div>
          {renderStatisticDivBlock('Is Sleep', deviceDetailsFB?.isSleep)}
        </div>
        <div className="vr-solid" />
        <div>{renderStatisticDivBlock('WiFi', deviceDetailsFB?.isWiFiOn)}</div>
      </div>
      <div>
        <Row gutter={16}>
          <Col className="gutter-row" xs={24} sm={12}>
            <DeviceInfo
              deviceDetailsFB={deviceDetailsFB}
              isFetching={isFetchingFB}
            />
            <RidesInfo deviceDetails={deviceDetails} isFetching={isFetching} />
          </Col>
          <Col className="gutter-row" xs={24} sm={12}>
            <DeviceLocation
              deviceDetailsFB={deviceDetailsFB}
              isFetching={isFetchingFB}
            />
          </Col>
        </Row>
        <Row>
          <Col className="gutter-row" xs={24} sm={24}>
            <DeviceRides
              deviceDetails={deviceDetails}
              isFetching={isFetching}
            />
          </Col>
        </Row>
      </div>
    </DeviceDetailsPageBox>
  );
};

export default DeviceDetailsPage;
