import React, { useEffect, useState } from 'react';
import { Table, Button, Row, Col, Popconfirm, Spin } from 'antd';
import { DeviceStatusListBox, Loader } from '../_devices-status.styled';
import {
  currentPage,
  formatDate,
  httpGet,
  URLS_V2,
  httpDelete,
  showSuccessMsg,
  showErrorMsg,
} from 'utils';

import { func, object, string } from 'prop-types';
import { APP_PACKAGE_TYPE } from 'constants/app-release-manage';
import CommonBreadCrumb from 'components/common-breadcrumb';
import {
  CheckCircleOutlined,
  CloseCircleOutlined,
  DownloadOutlined,
  EyeOutlined,
  PlusOutlined,
  DeleteOutlined,
  LoadingOutlined,
} from '@ant-design/icons';
import AppReleaseUnisocDetailsPreviewModal from '../binary-device-manager-details-preview-modal';

const BinaryDeviceAppReleaseHistoryList = (props) => {
  const breakCrumbList = [
    { label: 'Home', link: '/dashboard' },
    { label: 'Binary Device Release Manager' },
  ];

  const {
    packageName,
    setPackageName,
    setReleaseFormVisible,
    appCurrentVersion,
    renderCurrentVersion,
    setAppCurrentVersion,
  } = props;

  const [appReleaseHistoryList, setAppReleaseHistoryList] = useState([]);
  const [totalRecords, setTotalRecords] = useState(0);
  const [selectedRecordsDetails, setSelectedRecordsDetails] = useState({});
  const [isFetching, setIsFetching] = useState(false);
  const [modalVisible, setModalVisible] = useState(false);
  const [deletingReleaseId, setDeletingReleaseId] = useState('');

  const fetchAppReleaseHistory = ({ skip = 0, limit = 100, type = '' }) => {
    setIsFetching(true);
    const queryParams = new URLSearchParams();
    queryParams.append('skip', skip);
    queryParams.append('limit', limit);
    queryParams.append('type', type);
    queryParams.append('device_type', 'UNISOC');

    const url = `${
      URLS_V2?.APP_RELEASE_MANAGER?.GET_APP_RELEASE_HISTORY_LIST
    }?${queryParams.toString()}`;
    httpGet(url)
      .then((res) => {
        if (res?.status === 200) {
          const tempAppReleaseHistoryList = [];
          if (res?.data && res?.data.length > 0) {
            res?.data.map((data) => {
              const createdAt = formatDate(data?.created_at);
              tempAppReleaseHistoryList.push({
                ...data,
                key: data?._id,
                created_at: createdAt,
              });
            });
            setTotalRecords(res?.total || 0);
          }
          setAppReleaseHistoryList(tempAppReleaseHistoryList || []);
        }
        setIsFetching(false);
      })
      .catch(() => {
        setIsFetching(false);
      });
  };

  const fetchCurrentAppVersion = ({ type = '' }) => {
    if (type) {
      const queryParams = new URLSearchParams();
      queryParams.append('type', type);
      queryParams.append('device_type', 'UNISOC');

      const url = `${
        URLS_V2?.APP_RELEASE_MANAGER?.GET_CURRENT_APP_VERSION
      }?${queryParams.toString()}`;

      httpGet(url)
        .then((res) => {
          if (res?.status === 200 && res?.data) {
            setAppCurrentVersion(res?.data);
          }
        })
        .catch(() => {
          console.log('App Current version not available');
        });
    }
  };

  const columns = [
    {
      title: ENROLL_LABELS.SR_NO,
      width: 80,
      render: (value, item, index) =>
        (currentPage(0, 100) - 1) * 100 + index + 1,
    },
    {
      key: 'type',
      title: ENROLL_LABELS.PACKAGE_NAME,
      dataIndex: 'type',
    },
    {
      key: 'version_name',
      title: ENROLL_LABELS.VERSION_NAME,
      dataIndex: 'version_name',
    },
    {
      key: 'version_code',
      title: ENROLL_LABELS.VERSION_CODE,
      dataIndex: 'version_code',
    },
    {
      key: 'updated_device_count',
      title: ENROLL_LABELS.UPDATED_DEVICE_COUNT,
      dataIndex: 'updated_device_count',
    },
    // {
    //   key: 'running_device_count',
    //   title: ENROLL_LABELS.RUNNING_DEVICE_COUNT,
    //   dataIndex: 'running_device_count',
    // },
    {
      key: 'is_current',
      title: ENROLL_LABELS.IS_CURRENT,
      dataIndex: 'is_current',
      render: (is_current) => {
        return (function Actions() {
          return (
            <div className="actions-btn">
              {is_current ? (
                <CheckCircleOutlined
                  style={{
                    fontSize: '18px',
                    color: '#52c41a',
                  }}
                />
              ) : (
                <CloseCircleOutlined
                  style={{
                    fontSize: '18px',
                    color: '#ff4d4f',
                  }}
                />
              )}
            </div>
          );
        })();
      },
    },
    {
      key: 'created_at',
      title: ENROLL_LABELS.CREATED_AT,
      dataIndex: 'created_at',
    },
    {
      key: '_id',
      title: ENROLL_LABELS.ACTIONS,
      dataIndex: '_id',
      width: 100,
      render: (_id, item) => {
        return (function Actions() {
          return (
            <div className="actions-btn">
              <Button
                shape="circle"
                className="edit-btn m-r-5"
                onClick={() => handlePreviewModal(item)}
              >
                <EyeOutlined type="primary" className="edit-icon" />
              </Button>
              <a href={item?.apk?.url || item?.url} download>
                <Button shape="circle" className="edit-btn">
                  <DownloadOutlined type="primary" className="edit-icon" />
                </Button>
              </a>
              <Popconfirm
                title="Sure to delete?"
                onConfirm={() => handleDelete(item)}
                disabled={deletingReleaseId === _id}
              >
                <Button
                  shape="circle"
                  className="delete-btn"
                  disabled={deletingReleaseId === _id}
                >
                  {deletingReleaseId === _id ? (
                    <Spin indicator={<LoadingOutlined spin />} />
                  ) : (
                    <DeleteOutlined type="primary" className="delete-icon" />
                  )}
                </Button>
              </Popconfirm>
            </div>
          );
        })();
      },
    },
  ];

  useEffect(() => {
    setPackageName(APP_PACKAGE_TYPE.DEVICE_MANAGER.value);
    fetchAppReleaseHistory({
      skip: 0,
      limit: 10,
      type: APP_PACKAGE_TYPE.DEVICE_MANAGER.value,
    });
    fetchCurrentAppVersion({
      type: APP_PACKAGE_TYPE.DEVICE_MANAGER.value,
    });
  }, []);

  const defaultTitle = () => {
    return (
      <>
        <div className="cus-table-right-btn">
          {`Showing Records: ${appReleaseHistoryList.length} / Total Records: ${totalRecords}`}
        </div>
      </>
    );
  };

  const handleReleaseClick = () => {
    setReleaseFormVisible(true);
  };

  const handlePaginationChange = (pagination) => {
    const skip = pagination.current * pagination.pageSize - pagination.pageSize;
    const limit = pagination.pageSize;
    fetchAppReleaseHistory({
      skip: skip,
      limit: limit,
      type: packageName,
    });
  };

  const handlePreviewModal = (selectedItem) => {
    setModalVisible(true);
    setSelectedRecordsDetails(selectedItem);
  };

  const handleDelete = (item) => {
    setDeletingReleaseId(item._id);
    const url = `${URLS_V2?.APP_RELEASE_MANAGER?.DELETE_APP_RELEASE}?apk_url=${item.apk.url}`;
    httpDelete(url)
      .then((res) => {
        if (res.status === 200) {
          showSuccessMsg('Release deleted successfully!');
          fetchAppReleaseHistory({
            skip: 0,
            limit: 10,
            type: APP_PACKAGE_TYPE.DEVICE_MANAGER.value,
          });
        } else if (res?.error) {
          showErrorMsg(res?.error);
        }
        setDeletingReleaseId('');
      })
      .catch((err) => {
        showErrorMsg(err);
        setDeletingReleaseId('');
      });
  };

  const handleCloseModal = () => {
    setModalVisible(false);
    setSelectedRecordsDetails({});
  };

  return (
    <DeviceStatusListBox>
      <CommonBreadCrumb breadCrumbs={breakCrumbList} />
      <div className="while-bg-box cus-table-header">
        <h3 className="table-title">Binary Device Release Manager</h3>
      </div>
      <div className="while-bg-box">
        <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
          <Row>
            {packageName && (
              <Col md={12}>
                <Button type="primary" onClick={handleReleaseClick}>
                  <PlusOutlined />
                  Create New Release
                </Button>
              </Col>
            )}
          </Row>
        </div>
      </div>

      {packageName && (
        <>
          {renderCurrentVersion(appCurrentVersion)}
          <div className="main-table-body">
            <Table
              title={defaultTitle}
              rowClassName={(record, index) =>
                record.is_current ? 'green-5' : ''
              }
              loading={{
                indicator: <Loader>Fetching...</Loader>,
                spinning: isFetching,
              }}
              columns={columns}
              dataSource={appReleaseHistoryList || []}
              size="small"
              onChange={handlePaginationChange}
              pagination={{ defaultPageSize: 100, total: totalRecords }}
              scroll={{ x: 900 }}
            />
          </div>
        </>
      )}

      {modalVisible && (
        <AppReleaseUnisocDetailsPreviewModal
          handleCloseModal={handleCloseModal}
          modalVisible={modalVisible}
          sectionName="App Release Preview"
          selectedRecordsDetails={selectedRecordsDetails}
        />
      )}
    </DeviceStatusListBox>
  );
};

BinaryDeviceAppReleaseHistoryList.propTypes = {
  appCurrentVersion: object,
  packageName: string,
  // form: oneOfType([object, array]),
  renderCurrentVersion: func,
  setAppCurrentVersion: func,
  setPackageName: func,
  setReleaseFormVisible: func,
};

export default BinaryDeviceAppReleaseHistoryList;

const ENROLL_LABELS = {
  SR_NO: 'Sr. No.',
  VERSION_NAME: 'Version Name',
  VERSION_CODE: 'Version Code',
  UPDATED_DEVICE_COUNT: 'No Of Devices Updated',
  // RUNNING_DEVICE_COUNT: 'No Of Devices Running',
  PACKAGE_NAME: 'Type',
  IS_CURRENT: 'Is Current',
  CREATED_AT: 'Created At',
  ACTIONS: 'Actions',
};
