import React, { useState, useEffect } from 'react';
import { string, func } from 'prop-types';
import { MODE_TYPES } from 'constants/common';
import { Button, Modal, Form, Row, Col, Input, Switch } from 'antd';
import { DeviceModalBox } from './_device-modal.styled';
import { httpPut, httpGet, URLS, showErrorMsg, showSuccessMsg } from 'utils';
const DeviceModal = (props) => {
  // const formData = new FormData();
  const [validateFieldsName, setValidateFieldsName] = useState([]);
  const [isDeviceActive, setIsDeviceActive] = useState(false);
  const [isDeviceUpdating, setIsDeviceUpdating] = useState(false);
  const [isFetchingDevice, setIsFetchingDevice] = useState(false);
  const [form] = Form.useForm();
  const { activeMode, closeModal, docId, callback } = props;
  const isDisabled = isDeviceUpdating || isFetchingDevice;
  const deviceSerial = docId;
  useEffect(() => {
    form.resetFields();
    if (activeMode === MODE_TYPES.EDIT) {
      setIsFetchingDevice(true);
      const url = URLS?.DEVICES?.GET_DEVICE_BY_ID.replace(
        '#DEVICE_SERIAL#',
        deviceSerial
      );
      httpGet(url)
        .then((res) => {
          console.log('res', res);
          setIsDeviceActive(res.data.is_device_active);
          console.log('isDeviceActive', isDeviceActive);
          if (res.status === 200) {
            if (
              res?.data &&
              Object.entries(res?.data) &&
              Object.entries(res?.data).length > 0
            ) {
              Object.entries(res?.data).forEach(([key, value]) => {
                form.setFieldsValue({
                  [key]: value,
                });
              });
            }
          } else if (res?.error) {
            showErrorMsg(res?.error);
          }
          setIsFetchingDevice(false);
        })

        .catch((err) => {
          setIsFetchingDevice(false);
        });
    }
  }, []);

  const handleValidateFieldNames = (name) => {
    const isFieldName = validateFieldsName.find(
      (fieldName) => fieldName === name
    );
    if (isFieldName) return 'onChange';
    return 'onBlur';
  };

  const handleUpdateDevice = () => {
    form.submit();
    form
      .validateFields()
      .then(async (values) => {
        const params = {
          device_serial: values.device_serial || '',
          sim_serial: values.sim_serial || '',
          morning_driver_name: values.morning_driver_name || '',
          morning_driver_mobile: values.morning_driver_mobile || '',
          evening_driver_name: values.evening_driver_name || '',
          evening_driver_mobile: values.evening_driver_mobile || '',
          is_device_active: values.is_device_active || false,
          taxi_number: values.taxi_number || '',
        };
        setIsDeviceUpdating(true);
        console.log('hello');
        console.log('docId', docId);
        console.log('params', params);
        const url = URLS?.DEVICES?.UPDATE_DEVICE.replace(
          '#DEVICE_SERIAL#',
          deviceSerial
        );
        httpPut(url, params)
          .then((res) => {
            if (res.status === 200) {
              showSuccessMsg('Device updated successfully!');
              setIsDeviceUpdating(false);
              form.resetFields();
              handleOnCancel();
              callback();
            } else if (res?.error) {
              showErrorMsg(res?.error);
            }
            setIsDeviceUpdating(false);
          })
          .catch((err) => {
            showErrorMsg(err);
            setIsDeviceUpdating(false);
          });
      })
      .catch((errorInfo) => errorInfo);
  };

  const handleOnCancel = () => {
    closeModal();
  };

  let modalfooterButtons = [
    <Button
      key="save"
      type="primary"
      onClick={handleUpdateDevice}
      disabled={isDisabled}
    >
      {isDeviceUpdating ? 'Updating...' : 'Update Device'}
    </Button>,
    <Button key="cancel" onClick={handleOnCancel} disabled={isDisabled}>
      Cancel
    </Button>,
  ];

  return (
    <DeviceModalBox>
      <Modal
        title={`${isFetchingDevice ? 'Fetching data...' : 'Edit Device'}`}
        visible={Boolean(activeMode)}
        onCancel={() => !isDisabled && handleOnCancel()}
        className="change-device-modal"
        maskClosable={false}
        footer={modalfooterButtons}
      >
        <Form form={form} layout="vertical">
          <Row gutter={16}>
            <Col className="gutter-row" xs={24} sm={12}>
              <Form.Item name="device_serial" label="Device Serial">
                <Input disabled={true} placeholder="Device Serial" />
              </Form.Item>
            </Col>
            <Col className="gutter-row" xs={24} sm={12}>
              <Form.Item
                name="sim_serial"
                label="Sim Serial"
                validateTrigger={handleValidateFieldNames('sim_serial')}
                rules={[
                  {
                    required: true,
                    message: 'Please enter Sim Serial!',
                  },
                  {
                    min: 4,
                    max: 4,
                    message: 'Only 4 digits allowed!',
                  },
                ]}
              >
                <Input
                  placeholder="Sim Serial"
                  onBlur={() =>
                    setValidateFieldsName([...validateFieldsName, 'sim_serial'])
                  }
                />
              </Form.Item>
            </Col>
            <Col className="gutter-row" xs={24} sm={12}>
              <Form.Item
                name="morning_driver_name"
                label="Morning Driver Name"
                validateTrigger={handleValidateFieldNames(
                  'morning_driver_name'
                )}
              >
                <Input
                  placeholder="Morning Driver Name"
                  onBlur={() =>
                    setValidateFieldsName([
                      ...validateFieldsName,
                      'morning_driver_name',
                    ])
                  }
                />
              </Form.Item>
            </Col>
            <Col className="gutter-row" xs={24} sm={12}>
              <Form.Item
                name="morning_driver_mobile"
                label="Morning Driver Number"
                validateTrigger={handleValidateFieldNames(
                  'morning_driver_mobile'
                )}
              >
                <Input
                  placeholder="Morning Driver Number"
                  onBlur={() =>
                    setValidateFieldsName([
                      ...validateFieldsName,
                      'morning_driver_mobile',
                    ])
                  }
                />
              </Form.Item>
            </Col>
            <Col className="gutter-row" xs={24} sm={12}>
              <Form.Item
                name="evening_driver_name"
                label="Evening Driver Name"
                validateTrigger={handleValidateFieldNames(
                  'evening_driver_name'
                )}
              >
                <Input
                  placeholder="Evening Driver Name"
                  onBlur={() =>
                    setValidateFieldsName([
                      ...validateFieldsName,
                      'evening_driver_name',
                    ])
                  }
                />
              </Form.Item>
            </Col>
            <Col className="gutter-row" xs={24} sm={12}>
              <Form.Item
                name="evening_driver_mobile"
                label="Evening Driver Number"
                validateTrigger={handleValidateFieldNames(
                  'evening_driver_mobile'
                )}
              >
                <Input
                  placeholder="Evening Driver Number"
                  onBlur={() =>
                    setValidateFieldsName([
                      ...validateFieldsName,
                      'evening_driver_mobile',
                    ])
                  }
                />
              </Form.Item>
            </Col>
            <Form.Item
              name="taxi_number"
              label="Taxi Number"
              validateTrigger={handleValidateFieldNames('taxi_number')}
            >
              <Input
                placeholder="Taxi Number"
                onBlur={() =>
                  setValidateFieldsName([...validateFieldsName, 'taxi_number'])
                }
              />
            </Form.Item>
          </Row>
          <Form.Item name="is_device_active">
            <Switch
              checkedChildren="Active"
              unCheckedChildren="Inactive"
              onChange={() => setIsDeviceActive(!isDeviceActive)}
              checked={isDeviceActive}
            />
          </Form.Item>
        </Form>
      </Modal>
    </DeviceModalBox>
  );
};

DeviceModal.propTypes = {
  activeMode: string,
  callback: func,
  closeModal: func,
  docId: string,
};

export default DeviceModal;
