import React, { useState, useEffect } from 'react';
import 'antd/dist/antd.css';
import {
  // Table,
  Form,
  Button,
  DatePicker,
  Input,
  Card,
  Row,
  Col,
  Skeleton,
  Modal,
  Select,
  TimePicker,
} from 'antd';
import './index.css';
import { DownloadOutlined, EyeOutlined } from '@ant-design/icons';
import moment from 'moment';
import { CSVLink } from 'react-csv';
import {
  EnrollDeviceBox,
  //  Loader
} from './_rides.styled';
import { currentPage, httpGet, URLS_V2 } from 'utils';
import StatisticCardBlock from 'components/statistic-card-block';
import CommonBreadCrumb from 'components/common-breadcrumb';
import CompletedRides from './Tabs/CompletedRides';
import OnGoingRides from './Tabs/OnGoingRides';
import { Link } from 'react-router-dom';
// import dayjs from 'dayjs';
// import customParseFormat from 'dayjs/plugin/customParseFormat';
// dayjs.extend(customParseFormat);

// import { cos } from '@amcharts/amcharts4/.internal/core/utils/Math';
// import { map } from 'lodash';
const { RangePicker } = DatePicker;

const { Option } = Select;

const statusOptions = (
  <>
    <Option key={'true'} value={'true'}>
      {'Verified'}
    </Option>
    <Option key={'false'} value={'false'}>
      {'Not Verified'}
    </Option>
  </>
);



const RidesList = () => {
  const breakCrumbList = [
    { label: 'Home', link: '/dashboard' },
    { label: 'Rides' },
  ];
  const [rideStatus, setRideStatus] = useState('Completed');
  const [isFetching, setIsFetching] = useState(false);
  const ref = React.useRef(null);
  const [ridesList, setRidesList] = useState([]);
  const [ridesTempList, setRidesTempList] = useState([]);
  const [onGoingRidesList, setOnGoingRidesList] = useState([]);
  const [modalBySerial, setModalBySerial] = useState([]);
  const [rideStatistics, setRideStatistics] = useState({});
  const [totalRides, setTotalRides] = useState(0);
  const [CSVDownload, setCSVDownload] = useState([]);
  // const [isFetchingCSV, setIsFetchingCSV] = useState(false);
  const [isFetchRideStats, setIsFetchRideStats] = useState(false);
  const [selectedModalRange, setSelectedModalRange] = useState([]);
  const [customRange, setCustomRange] = useState(null);
  const [open, setOpen] = useState(false);
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [ModalSelectValue, setModalSelectValue] = useState([]);
  const [timePickerData, setTimePickerData] = useState([]);
  const [isRangePickerCleared, setIsRangePickerCleared] = useState(false)
  const [clearFilterClicked, setClearFilterClicked]=useState(0)
  const [searchForm]=Form.useForm()


  const filterParamsData = {
    startTime: '',
    stopTime: '',
    deviceSerial: '',
    taxiNumber: '',
    isPassengerPresent: '',
  };
  const [filterParams, setFilterParams] = useState(filterParamsData);
  const [dayRange, setDayRange] = useState('7');

  useEffect(()=>{
if(clearFilterClicked>0){
  fetchRidesStatistics();
  fetchRides();
  fetchRidesTotal();
}
  },[clearFilterClicked])

  useEffect(async () => {
    const url = `${URLS_V2?.DEVICES?.DEVICES_LIST}`;
    const res = await httpGet(url);
    const modalDataBySerial = res?.data?.map((value, index) => {
      return {
        label: `${value?.device_serial} - ${value?.taxi_number}`,
        value: value?.device_serial,
      };
    });
    setModalBySerial(modalDataBySerial);
  }, [])
  

  useEffect(() => {
    fetchRidesStatistics();
    fetchRides();
    fetchRidesTotal();
  }, []);

  const applyFilterHandler=()=>{
    fetchRidesStatistics();
    fetchRides();
    fetchRidesTotal();
  }
  const clearAllFilterHandler =()=>{
  searchForm.resetFields()
    setFilterParams(filterParamsData)
    setClearFilterClicked(clearFilterClicked+1)
  }

  const fetchRidesStatistics = async () => {
    setIsFetchRideStats(true);
    const url = `${URLS_V2?.RIDES?.RIDES_STATISTICS_RIDESTATS}`;
    const res = await httpGet(url);

    if (res?.status === 200) {
      setRideStatistics(res?.data || {});
    }
    setIsFetchRideStats(false);
  };


  const fetchRides = async ({
    skip = 0,
    limit = 10,
    sortField = 'ride_start_time',
    sortOrder = -1,
    startTime = filterParams?.startTime &&
    moment(filterParams?.startTime).unix(),
    stopTime = filterParams?.stopTime && moment(filterParams?.stopTime).unix(),
    deviceSerial = filterParams?.deviceSerial,
    taxiNumber = filterParams?.taxiNumber,
    isPassengerPresent = filterParams?.isPassengerPresent,
  } = {}) => {
    setIsFetching(true);
    const queryParams = new URLSearchParams();
    queryParams.append('skip', skip);
    queryParams.append('limit', limit);

    if (startTime) queryParams.append('start_time', startTime);
    if (stopTime) queryParams.append('stop_time', stopTime);
    if (deviceSerial) queryParams.append('device_serial', deviceSerial);
    if (taxiNumber) queryParams.append('taxi_number', taxiNumber);
    if (isPassengerPresent)
      queryParams.append('is_passenger_present', isPassengerPresent);
    if (sortField) queryParams.append('orderBy', sortField);
    if (sortOrder) queryParams.append('order', sortOrder === 'ascend' ? 1 : -1);
    const url = `${URLS_V2?.RIDES?.RIDES_LIST}/?${queryParams.toString()}`;
    const res = await httpGet(url);
    setRidesList(res?.data || []);
    // setTotalRides(res?.data?.count || 0);
    const filteredData = res?.data?.filter((val) => {
      if (val?.ride_ended) {
        return val;
      }
    });

    const onGoingFilteredData = res?.data?.filter((val) => {
      return !val?.ride_ended;
    });

    setOnGoingRidesList(res?.data ? onGoingFilteredData : []);
    setRidesTempList(res?.data ? filteredData : []);

    setIsFetching(false);
  };

  const fetchRidesTotal = () => {
    setIsFetching(true);
    const queryParams = new URLSearchParams();
    if (filterParams?.startTime)
      queryParams.append('start_time', moment(filterParams?.startTime).unix());
    if (filterParams?.stopTime)
      queryParams.append('stop_time', moment(filterParams?.stopTime).unix());
    if (filterParams?.deviceSerial)
      queryParams.append('device_serial', filterParams?.deviceSerial);
    if (filterParams?.taxiNumber)
      queryParams.append('taxi_number', filterParams?.taxiNumber);
    if (filterParams?.isPassengerPresent)
      queryParams.append(
        'is_passenger_present',
        filterParams?.isPassengerPresent
      );

    const url = `${URLS_V2?.RIDES?.RIDES_LIST_COUNT
      }/?${queryParams.toString()}`;

    httpGet(url)
      .then((res) => {
        setTotalRides(res.data?.ridesCount || 0);
        setIsFetching(false);
      })
      .catch((err) => {
        setIsFetching(false);
      });
  };

  const handleStartEndTime = (value) => {
    if (value && value.length > 1) {
      setFilterParams({
        ...filterParams,
        startTime: value[0],
        stopTime: value[1],
      });
    } else {
      setFilterParams({
        ...filterParams,
        startTime: '',
        stopTime: '',
      });
      setRidesTempList(ridesList || []);
    }
  };

  const handleTextSearch = (e) => {
    setFilterParams({
      ...filterParams,
      [e.target.name]: e.target.value,
    });
  };

  const handleSelectSearch = (selectedValue) => {
    setFilterParams({
      ...filterParams,
      isPassengerPresent: selectedValue,
    });
  };

  const handleCSVData = (enrollDevicesData) => {
    let csvData = [];
    enrollDevicesData.forEach((data) => {
      const startDate = data.startTime;
      const endDate = data.stopTime;
      const excelStructureData = {
        [ENROLL_LABELS.DEVICE_SERIAL]: data?.device_serial || '',
        [ENROLL_LABELS.TAXI_NUMBER]: data?.taxi_number || '',
        [ENROLL_LABELS.START_TIME]: startDate || '',
        [ENROLL_LABELS.END_TIME]: endDate || '',
        [ENROLL_LABELS.RIDE_TIME]:
          data?.ride_time >= 1 ? Math.trunc(data?.ride_time) + ' min' : '0',
        // [ENROLL_LABELS.IS_RIDE_VERIFIED]: data?.is_passenger_present
        //   ? 'Verified'
        //   : 'Not Verified',
      };
      csvData.push(excelStructureData);
    });
    setCSVDownload(csvData);
  };

  const setAreaNameFromLocationString = (locationArray) => {
    let amount_to_remove = 1;
    if (locationArray.length > 2) {
      amount_to_remove = 2;
    }
    locationArray.splice(
      locationArray.length - amount_to_remove,
      amount_to_remove
    );
    return locationArray;
  };

  // const handleTrackRide = () => {
  //   window.open(`/v2/ridemap`, '_blank');
  // };

  const columns = [
    {
      title: ENROLL_LABELS.SR_NO,
      width: 80,
      render: (value, item, index) =>
        (currentPage(0, 10) - 1) * 100 + index + 1,
    },
    {
      key: 'device_serial',
      title: ENROLL_LABELS.DEVICE_SERIAL,
      dataIndex: 'device_serial',
      sorter: true,
    },
    {
      key: 'taxi_number',
      title: ENROLL_LABELS.TAXI_NUMBER,
      dataIndex: 'taxi_number',
      sorter: true,
    },
    {
      key: 'startTime',
      title: ENROLL_LABELS.START_TIME,
      dataIndex: 'start_time',
      defaultSortOrder: 'descend',
      sorter: true,
      render: (value) => moment.unix(value).format('DD-MM-YYYY hh:mm A'),
    },
    {
      key: 'stopTime',
      title: ENROLL_LABELS.END_TIME,
      dataIndex: 'stop_time',
      sorter: true,
      render: (value) => moment.unix(value).format('DD-MM-YYYY hh:mm A'),
    },
    {
      key: 'ride_time',
      title: ENROLL_LABELS.RIDE_TIME,
      dataIndex: 'ride_time',
      sorter: true,
      render: (data) => {
        return (function Actions() {
          const duration = moment.duration(data, 'seconds');
          const hours = Math.floor(duration.asHours());
          const minutes = duration.minutes();
          const seconds = duration.seconds();
          const convertedTime = `${hours}h : ${minutes}m : ${seconds}s`;
          return <>{data && data >= 1 ? convertedTime : '0'}</>;
        })();
      },
    },
    {
      key: '_id',
      title: ENROLL_LABELS.ACTIONS,
      dataIndex: '_id',
      width: 150,
      render: (_id, item) => {
        return (function Actions() {
          return (
            <div className="actions-btn"   >
              <Link to={`/v2/rides/${_id}`}>
                <Button
                  shape="circle"
                  className="edit-btn"
                  style={{ marginLeft: '0.5rem', cursor: "pointer" }}
                >
                  <EyeOutlined type="primary" className="preview-icon" />
                </Button>
              </Link>
            </div>
          );
        })();
      },
    },
    // {
    //   key: '_id',
    //   title: ENROLL_LABELS.TRACK_RIDE,
    //   dataIndex: '_id',
    //   align: 'center',
    //   render: () => {
    //     return (function Actions() {
    //       return (
    //         <>
    //           <Button
    //             shape="circle"
    //             className="edit-btn"
    //             onClick={() => {
    //               handleTrackRide();
    //             }}
    //           >
    //             <HeatMapOutlined type="primary" />
    //           </Button>
    //         </>
    //       );
    //     })();
    //   },
    // },
    // {
    //   key: 'is_passenger_present',
    //   title: ENROLL_LABELS.IS_RIDE_VERIFIED,
    //   dataIndex: 'is_passenger_present',
    //   sorter: true,
    //   render: (is_passenger_present) => {
    //     return (function Actions() {
    //       return (
    //         <>
    //           {is_passenger_present ? (
    //             <CheckSquareOutlined
    //               style={{ fontSize: '18px', color: '#52c41a' }}
    //             />
    //           ) : (
    //             <CloseSquareOutlined
    //               style={{
    //                 fontSize: '18px',
    //                 color: '#ff4d4f',
    //               }}
    //             />
    //           )}
    //         </>
    //       );
    //     })();
    //   },
    // },
  ];
  const handleExport = async ({
    limit = totalRides > 5000 || totalRides < 0 ? 5000 : totalRides,
    startTime = filterParams?.startTime &&
    moment(filterParams?.startTime).format('x'),
    stopTime = filterParams?.stopTime &&
    moment(filterParams?.stopTime).format('x'),
    deviceSerial = filterParams?.deviceSerial,
    taxiNumber = filterParams?.taxiNumber,
    isPassengerPresent = filterParams?.isPassengerPresent,
    sortField = filterParams?.sortField,
    sortOrder = filterParams?.sortOrder,
  } = {}) => {

    const exportStartTime = selectedModalRange[0]?.unix()
    const exportEndTime = selectedModalRange[1]?.unix()
    const minRideTime = timePickerData[0]
    const maxRideTime = timePickerData[1]
    handleCSVData([]);
    // setIsFetchingCSV(true);
    const queryParams = new URLSearchParams();
    queryParams.append('limit', limit);
    if (exportStartTime) queryParams.append('start_time', exportStartTime);
    if (exportEndTime) queryParams.append('stop_time', exportEndTime);
    if (ModalSelectValue) queryParams.append('device_serial', ModalSelectValue);
    if (minRideTime) queryParams.append('minRideTime', minRideTime);
    if (maxRideTime) queryParams.append('maxRideTime', maxRideTime);
    if (taxiNumber) queryParams.append('taxi_number', taxiNumber);
    if (isPassengerPresent)
      queryParams.append('is_passenger_present', isPassengerPresent);
    if (sortField) queryParams.append('orderBy', sortField);
    if (sortOrder) queryParams.append('order', sortOrder === 'ascend' ? 1 : -1);

    const url = `${URLS_V2?.RIDES?.RIDES_EXPORT_DETAILS}/?${queryParams.toString()}`;
    const res = await httpGet(url);

    const ridesData =
      (res?.data &&
        res?.data.map((doc) => {
          const formattedStartTime = doc?.start_time
            ? moment(doc.start_time).format('DD-MM-YYYY HH:mm:ss')
            : '';

          const formattedEndTime = doc?.stop_time
            ? moment(doc.stop_time).format('DD-MM-YYYY HH:mm:ss')
            : '';

          const ridePickupArea =
            doc && doc.ride_pickup_area
              ? setAreaNameFromLocationString(
                doc.ride_pickup_area.split(' - ')
              ).join(', ')
              : '';
          const rideDropArea =
            doc && doc.ride_dropoff_area
              ? setAreaNameFromLocationString(
                doc.ride_dropoff_area.split(' - ')
              ).join(', ')
              : '';

          return {
            ...doc,
            key: doc._id,
            startTime: formattedStartTime,
            stopTime: formattedEndTime,
            ridePickupArea: ridePickupArea,
            rideDropArea: rideDropArea,
            docId: doc._id,
          };
        })) ||
      [];
    handleCSVData(ridesData || []);
    // setIsFetchingCSV(false);
    ref.current.click();
  };
  const handlePaginationChange = (pagination, filters, sorter) => {
    console.log(pagination, filters, sorter, "clockPage");
    const skip = pagination.current * pagination.pageSize - pagination.pageSize;
    const limit = pagination.pageSize;
    const sortField = sorter.field;
    const sortOrder = sorter.order;
    fetchRides({
      skip: skip,
      limit: limit,
      sortField: sortField,
      sortOrder: sortOrder,
    });
  };

  const renderRidesStatisticsCard = (
    cardTitle,
    // totalRide,
    // totalRideTime,
    // totalRideAvgTime,
    data
  ) => {


    // const durationRideAvgTime = moment.duration(totalRideAvgTime, 'minutes');
    // const daysRideAvgTime = durationRideAvgTime.days();
    // const hoursRideAvgTime = durationRideAvgTime.hours();
    // const minutesRideAvgTime = durationRideAvgTime.minutes();
    // const formattedTotalRideAvgTime = `${daysRideAvgTime}d : ${hoursRideAvgTime}h : ${minutesRideAvgTime}m`;


    // const durationTotalRideTime = moment.duration(totalRideTime, 'minutes');
    // const daysTotalRideTime = durationTotalRideTime.days();
    // const hoursTotalRideTime = durationTotalRideTime.hours()
    // const minutesTotalRideTime = durationTotalRideTime.minutes();
    // const formattedTotalRideTime = `${daysTotalRideTime}d : ${hoursTotalRideTime}h : ${minutesTotalRideTime}m`;

    return (
      <div className='statistics-cards-data' style={{
        marginTop:"5px",
        marginBottom:"5px"
      }} >
        <Card title={cardTitle} bordered={false}>
          <Skeleton
            loading={isFetchRideStats}
            active
            paragraph={{ rows: 2, width: '100%' }}
            title={false}
          >
            <Row gutter={32}>
              {/* {Object.keys(data || {})?.map((key)=>(
                <Col span={6} key={key} >
                <StatisticCardBlock
                  title={key}
                  value={data?.[key] || 0}
                  valueStyle={{ color: '#3f8600' }}
                />
              </Col>
              ))} */}
              <Col span={9}>
                <StatisticCardBlock
                  title="Twenty Four Hours"
                  value={data?.lastTwentyFourHour}
                  precision={0}
                  valueStyle={{ color: "#3f8600" }}
                />
              </Col>
              <Col span={9}>
                <StatisticCardBlock
                  title="Seven Days"
                  value={data?.lastSevenDays}
                  precision={0}
                  valueStyle={{ color: '#3f8600' }}
                />
              </Col>
              <Col span={9}>
                <StatisticCardBlock
                  title="Fifteen Days"
                  value={data?.lastFifteenDays}
                  precision={0}
                  valueStyle={{ color: '#3f8600' }}
                />
              </Col>
              <Col span={9}>
                <StatisticCardBlock
                  title="Thirty Days"
                  value={data?.lastThirtyDays}
                  precision={0}
                  valueStyle={{ color: '#3f8600' }}
                />
              </Col>
            </Row>
          </Skeleton>
        </Card>
      </div>
    );
  };

  // const defaultTitle = () => {
  //   return (
  //     <>
  //       <div className="cus-table-right-btn">
  //         {`Showing Records: ${ridesTempList.length} / Total Records: ${totalRides}`}
  //       </div>
  //     </>
  //   );
  // };

  const showModal = () => {
    setOpen(true);
  };
  const handleOk = () => {
    setConfirmLoading(true);
    handleExport();
    setTimeout(() => {
      setOpen(false);
      setConfirmLoading(false);
    }, 2000);
  };
  const handleCancel = () => {
    setOpen(false);
  };

  const rangeSelectorChange = (value) => {
    setDayRange(value)
    setCustomRange(null)
    updateDateRange(value);
  }

  useEffect(() => {
    updateDateRange(dayRange)
  }, [])

  // ========= RangePicker========
  const updateDateRange = timeRange => {
    const endDate = moment().subtract(1, 'days')
    const startDate = moment().subtract(parseInt(timeRange), 'days')
    setSelectedModalRange([startDate, endDate]);
    setIsRangePickerCleared(false)
  };


  const handleModalRangePicker = (dates, dateStrings) => {
    setSelectedModalRange(dates);
    setCustomRange(dates);
    if (dates == null) {
      setIsRangePickerCleared(true)
    } else {
      setIsRangePickerCleared(false)
    }
  };


  // ========= MultiSelect =======

  const handleChange = (value) => {
    setModalSelectValue(value)
  };

  // =========== TimePicker =======

  const handleTimeChange = (value, formatString) => {
    setTimePickerData(formatString)
  };

  return (
    <EnrollDeviceBox>
      <CommonBreadCrumb breadCrumbs={breakCrumbList} />
      <div className="while-bg-box cus-table-header">
        <h3 className="table-title">Rides</h3>
        <div className="cus-table-right-btn">
          <div className="export-generate-btn">
            <Form.Item>
              <CSVLink
                data={CSVDownload}
                headers={headers}
                filename={'rides.csv'}
              >
                <span ref={ref}></span>
              </CSVLink>
              <Button
                type="primary"
                // onClick={handleExport}
                onClick={showModal}
                // loading={isFetchingCSV}
                icon={<DownloadOutlined />}
              >
                {/* {isFetchingCSV ? 'Exporting Data': 'Export'}  */}
                Export
              </Button>
            </Form.Item>
          </div>
        </div>
      </div>
      <Modal
        title="Export"
        visible={open}
        onOk={handleOk}
        confirmLoading={confirmLoading}
        onCancel={handleCancel}
        okText="Export"
      //  width={"100px"}
      >
        <div className="defaultMarginModal">
          <label>Range</label>
          <Select
            // defaultValue="lucy"
            style={{
              width: '100%',
            }}
            onChange={rangeSelectorChange}
            // defaultValue={'7'}
            value={customRange ? 'custom' : isRangePickerCleared ? 'Custom Range' : dayRange}

            options={
              [
                {
                  value: '7',
                  label: 'Last 7 Days',
                },
                {
                  value: '15',
                  label: 'Last 15 Days',
                },
                {
                  value: '30',
                  label: 'Last Month',
                },
                customRange && {
                  value: 'custom',
                  label: `Custom Range`,
                },
              ].filter(Boolean)
            }

          />
        </div>

        <div className="defaultMarginModal">
          <label>Custom Range</label>
          <RangePicker
            // disabledDate={disabledDate} 
            style={{ width: '100%' }}
            showTime={{ format: 'hh:mm a' }}
            format="DD/MM/YYYY hh:mm a"
            onChange={handleModalRangePicker}
            value={selectedModalRange}

          />
        </div>

        <div className="defaultMarginModal">
          <label>Serial Number</label>
          <Select
            mode="multiple"
            size={'middle'}
            placeholder="Please Select Serial Number"
            // defaultValue={['a10', 'c12']}
            onChange={handleChange}
            style={{
              width: '100%',
            }}
            // filterOption={(input, option) => (option?.label ?? '').includes(input)}
            filterOption={(input, option) =>
              (option?.label.toString().toLowerCase().includes(input.toLowerCase()))
            }
            options={modalBySerial}
            value={ModalSelectValue}
          />
        </div>

        <div className="defaultMarginModal">
          <label>Ride Time</label>
          <TimePicker.RangePicker
            format="mm"
            minuteStep={1}
            onChange={handleTimeChange}
            placeholder={['Min Time', 'Max Time']}
            style={{
              width: '100%',
            }}

          />
        </div>
      </Modal>

      <div className="site-statistic-demo-card ride-kpis">
        <Row gutter={14}>
          <Col sm={24} md={12} lg={8}>
            {renderRidesStatisticsCard(
              'Total Rides',
              // rideStatistics?.halfDay?.halfDay_Total_Ride,
              // rideStatistics?.halfDay?.halfDay_Ride_Time_seconds,
              // rideStatistics?.halfDay?.halfDay_Ride_TimeAvg_seconds,
              rideStatistics?.totalRides
            )}
          </Col>
          <Col sm={24} md={12} lg={8}>
            {renderRidesStatisticsCard(
              'Currently Active Cars',
              // rideStatistics?.oneDay?.oneDay_Total_Ride,
              // rideStatistics?.oneDay?.oneDay_Ride_Time_seconds,
              // rideStatistics?.oneDay?.oneDay_Ride_TimeAvg_seconds,
              rideStatistics?.currentlyActiveCars
            )}
          </Col>
          <Col sm={24} md={12} lg={8}>
            {renderRidesStatisticsCard(
              'Avg. Ride Time',
              // rideStatistics?.sevenDays?.sevenDays_Total_Ride,
              // rideStatistics?.sevenDays?.sevenDays_Ride_Time_seconds,
              // rideStatistics?.sevenDays?.sevenDays_Ride_TimeAvg_seconds,
              rideStatistics?.avgRideTime
            )}
          </Col>
          <Col sm={24} md={12} lg={8}>
            {renderRidesStatisticsCard(
              'Total Rides Time',
              // rideStatistics?.fifteenDaysResponse?.fifteenDays_Total_Ride,
              // rideStatistics?.fifteenDaysResponse
              //   ?.fifteenDays_Ride_Time_seconds,
              // rideStatistics?.fifteenDaysResponse
              //   ?.fifteenDays_Ride_TimeAvg_seconds,
              rideStatistics?.totalRideTime
            )}
          </Col>
          <Col sm={24} md={12} lg={8}>
            {renderRidesStatisticsCard(
              'Avg Rides Per Car Per Day',
              // rideStatistics?.thirtyDays?.thirtyDays_Total_Ride,
              // rideStatistics?.thirtyDays?.thirtyDays_Ride_Time_seconds,
              // rideStatistics?.thirtyDays?.thirtyDays_Ride_TimeAvg_seconds,
              rideStatistics?.avgRidePerTaxiPerDay
            )}
          </Col>
        </Row>
      </div>
      {/* <div className="site-statistic-demo-card ride-kpis">
        <Row gutter={14}>
          <Col sm={24} md={12} lg={8}>
            {renderRidesStatisticsCard(
              'Total Rides Time',
              rideStatistics?.totalRideTime
            )}
          </Col>
          <Col sm={24} md={12} lg={8}>
            {renderRidesStatisticsCard(
              'Avg Rides Per Car Per Day',
              rideStatistics?.avgRidePerTaxiPerDay
            )}
          </Col>
          <Col span={8}>
            {renderRidesStatisticsCard(
              'All  ',
              rideStatistics?.allRecordResponse?.allRecord_Total_Ride,
              rideStatistics?.allRecordResponse?.allRecord_Ride_Time_seconds,
              rideStatistics?.allRecordResponse?.allRecord_Ride_TimeAvg_seconds
            )}
          </Col>
        </Row>
      </div> */}
      <div className="while-bg-box">
        <Form className="main-filter-form" form={searchForm}>
          <Row gutter={[16, 16]}>
            <Col md={6}>
              <Form.Item style={{ marginBottom: '0' }}>
                <RangePicker
                  style={{ width: '100%' }}
                  showTime={{ format: 'HH:mm' }}
                  format="YYYY-MM-DD HH:mm"
                   value={[filterParams?.startTime,filterParams?.stopTime]}
                  onChange={(startEndTime) => handleStartEndTime(startEndTime)}
                />
              </Form.Item>
            </Col>
            <Col md={6}>
              <Form.Item name="deviceSerial" style={{ marginBottom: '0' }}>
                <Input
                  type="text"
                  name="deviceSerial"
                  onChange={handleTextSearch}
                  placeholder="Search Device Serial..."
                />
              </Form.Item>
            </Col>
            <Col md={6}>
              <Form.Item name="taxiNumber" style={{ marginBottom: '0' }}>
                <Input
                  type="text"
                  name="taxiNumber"
                  onChange={handleTextSearch}
                  placeholder="Search Taxi Number..."
                />
              </Form.Item>
            </Col>
            <Col md={6}>
              <Form.Item
                name="is_passenger_present"
                style={{ marginBottom: '0' }}
              >
                <Select
                  showSearch
                  allowClear
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    option.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                  placeholder="Select Ride Verification Status"
                  onChange={handleSelectSearch}
                >
                  {statusOptions}
                </Select>
              </Form.Item>
            </Col>
          </Row>
        </Form>
        <div
         style={{ marginRight:"10px", marginTop:"20px"}}
         >
        <Button type='primary'  onClick={applyFilterHandler}>Apply Filters</Button>
        <Button type='danger' style={{marginLeft:"10px"}} onClick={clearAllFilterHandler}>Clear All</Button>
      </div>

      </div>
      
      {/* <div className="tabs">
        <div>
          <div
            onClick={() => setRideStatus('Completed')}
            className={`completed-tab ${rideStatus === 'Completed' && 'highLighter'
              }`}
          >
            Completed Rides
          </div>
        </div>

        <div>
          <div
            onClick={() => setRideStatus('OnGoing')}
            className={`ongoing-tab ${rideStatus !== 'Completed' && 'highLighter'
              }`}
          >
            OnGoing Rides
          </div>
        </div>
      </div> */}
      
        <CompletedRides
          ridesTempList={ridesTempList}
          totalRides={totalRides}
          isFetching={isFetching}
          columns={columns}
          handlePaginationChange={handlePaginationChange}
        />
  
       
    </EnrollDeviceBox>
  );
};

export default RidesList;

const ENROLL_LABELS = {
  SR_NO: 'Sr. No.',
  DEVICE_SERIAL: 'Device Serial',
  TAXI_NUMBER: 'Taxi Number',
  START_TIME: 'Start Time',
  END_TIME: 'End Time',
  RIDE_TIME: 'Ride Time',
  TRACK_RIDE: 'Track Ride',
  ACTIONS: "Actions"
  //IS_RIDE_VERIFIED: 'Is Ride Verified?',
  // IMAGE: 'Image',
};

const headers = [
  { label: ENROLL_LABELS.DEVICE_SERIAL, key: ENROLL_LABELS.DEVICE_SERIAL },
  { label: ENROLL_LABELS.TAXI_NUMBER, key: ENROLL_LABELS.TAXI_NUMBER },
  { label: ENROLL_LABELS.START_TIME, key: ENROLL_LABELS.START_TIME },
  { label: ENROLL_LABELS.END_TIME, key: ENROLL_LABELS.END_TIME },
  { label: ENROLL_LABELS.RIDE_TIME, key: ENROLL_LABELS.RIDE_TIME },
  { label: ENROLL_LABELS.TRACK_RIDE, key: ENROLL_LABELS.TRACK_RIDE },
  { label: ENROLL_LABELS.ACTIONS, key: ENROLL_LABELS.ACTIONS }
  // {
  //   label: ENROLL_LABELS.IS_RIDE_VERIFIED,
  //   key: ENROLL_LABELS.IS_RIDE_VERIFIED,
  // },
  // { label: ENROLL_LABELS.IMAGE, key: ENROLL_LABELS.IMAGE },
];