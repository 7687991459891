const DOMAIN = process.env.REACT_APP_DOMAIN_URL;
const DOMAIN_V2 =
  process.env.REACT_APP_DOMAIN_URL_LIVE_V2 || 'https://console.enterbinary.com';
const VERSION = 'v1';
const VERSION_V2 = 'v2';

const ANDROID_MANAGEMENT_URL =
  process.env.REACT_APP_ANDROID_MANAGEMENT_API_URL ||
  'https://androidmanagement.googleapis.com';
const ANDROID_MANAGEMENT_API_VERSION = 'v1';

const CLOUD_STORAGE_MANAGEMENT_API_URL =
  process.env.REACT_APP_CLOUD_STORAGE_MANAGEMENT_API_URL ||
  'https://storage.googleapis.com';
const CLOUD_STORAGE_MANAGEMENT_API_VERSION = 'v1';
const CLOUD_STORAGE_MANAGEMENT_BUCKET =
  process.env.REACT_APP_CLOUD_STORAGE_MANAGEMENT_BUCKET || 'binaryos-staging';

const ENTERPRISE_NAME =
  process.env.REACT_APP_ANDROID_MANAGEMENT_ENTERPRISE_NAME || 'LC03e28flj';

const BASE_URL = `${DOMAIN}/api/${VERSION}`;
const BASE_URL_V2 = `${DOMAIN}/api/${VERSION_V2}`;
const DOMAIN_V2_BASE_URL = `${DOMAIN_V2}/api/${VERSION_V2}`;
const ANDROID_MANAGEMENT_BASE_URL = `${ANDROID_MANAGEMENT_URL}/${ANDROID_MANAGEMENT_API_VERSION}/enterprises`;

export const URLS = {
  AUTH: {
    REGISTER: `${BASE_URL}/auth/register`,
    SIGN_IN: `${BASE_URL}/auth/signin`,
    PROFILE: `${BASE_URL}/auth/profile`,
    CHANGE_PASSWORD: `${BASE_URL}/auth/change-password`,
    USERS_LIST: `${BASE_URL}/auth/users`,
    DELETE_USER: `${BASE_URL}/deleteUser/#ID#`,
  },

  ANDROID_MANAGEMENT: {
    // ENROLLED_DEVICES_LIST: `${BASE_URL}/android-management/enrolled-devices`,
    GENERATE_AUTH_TOKEN: `${BASE_URL}/generate-auth-token`,
    ENROLLED_DEVICES_LIST: `${ANDROID_MANAGEMENT_BASE_URL}/${ENTERPRISE_NAME}/devices`,
    GET_ENROLLED_DEVICES_DETAIL: `${ANDROID_MANAGEMENT_BASE_URL}/${ENTERPRISE_NAME}/devices`,
    GET_ENROLLED_DEVICES_OPERATION_DETAILS: `${ANDROID_MANAGEMENT_BASE_URL}/${ENTERPRISE_NAME}/devices`,
  },

  GOOGLE_CLOUD_STORAGE_MANAGEMENT: {
    // ENROLLED_DEVICES_LIST: `${BASE_URL}/android-management/enrolled-devices`,
    GENERATE_AUTH_TOKEN: `${BASE_URL_V2}/generate-cloud-storage-auth-token`,
  },
  INVITED_USER: {
    ADD_INVITED_USER: `${BASE_URL}/InvitedUser`,
    INVITED_USERS_LIST: `${BASE_URL}/InvitedUsersList`,
  },
  CAMPAIGN: {
    CAMPAIGNS_LIST: `${BASE_URL}/campaigns`,
    ADD_CAMPAIGN: `${BASE_URL}/campaigns`,
    UPDATE_CAMPAIGN: `${BASE_URL}/campaigns/#ID#`,
    GET_CAMPAIGN_BY_ID: `${BASE_URL}/campaigns/#ID#`,
    UPDATE_NO_OF_TIMES_VISITED_BY_QR: `${BASE_URL}/campaign-qr/#ID#`,
    UPLOAD_MEDIA_IMAGE: `${BASE_URL}/campaign-media-image/#ID#`,
    UPLOAD_MEDIA_VIDEO: `${BASE_URL}/campaign-media-video/#ID#`,
    UPLOAD_GROUP_ADS_MEDIA_VIDEO: `${BASE_URL}/campaign-group-ads-video/#ID#`,
    UPLOAD_GROUP_ADS_MEDIA_THUMBNAIL: `${BASE_URL}/campaign-group-ads-thumbnail/#ID#`,
    UPLOAD_TEMPLATE: `${BASE_URL}/campaign-template/#ID#`,
    DELETE_GC_FILE: `${BASE_URL}/campaign-delete-gc-file/#ID#/#FILE_URL#/#FILE_TYPE#`,
    DELETE_CAMPAIGN_BY_ID: `${BASE_URL}/campaigns/#ID#`,
    DELETE_BOOKEDINVENTORY_BY_CAMPAIGN_ID: `${BASE_URL}/bookedInventoryByCampaignId/#ID#`,
    ADD_USERID_TO_CAMPAIGN: `${BASE_URL}/add-userId-to-campaign/#CAMPAIGN_ID#`,
    DROPDOWN_CAMPAIGNS_LIST: `${BASE_URL}/auth/campaigns`,
    CALCULATE_CUSTOM_GEOFENCE_DATA: `${BASE_URL}/campaign/geo-fence-count`,
    UPLOAD_GROUP_AD_BG_IMAGE: `${BASE_URL}/campaign-media-image/#ID#`,
    DUPLICATE_CAMPAIGN: `${BASE_URL}/duplicate/campaign`,
  },

  COMPANY: {
    COMPANIES_LIST: `${BASE_URL}/getCampaignCompanies`,
    ADD_COMPANY: `${BASE_URL}/createCampaignCompany`,
  },

  AD_INVENTORY_CONFIG: {
    GET_AD_INVENTORY_CONFIG: `${BASE_URL}/ad-inventory-config`,
    UPDATE_AD_INVENTORY_CONFIG: `${BASE_URL}/ad-inventory-config`, //no need to send id
  },

  READING: {
    READINGS_LIST: `${BASE_URL}/readings`,
    GET_READING_BY_ID: `${BASE_URL}/readings/#ID#`,
    ADD_READING: `${BASE_URL}/readings`,
    UPDATE_READING: `${BASE_URL}/readings/#ID#`,
    DELETE_READING: `${BASE_URL}/readings/#ID#`,
  },

  GAME: {
    GAMES_LIST: `${BASE_URL}/games`,
    GET_GAME_BY_ID: `${BASE_URL}/games/#ID#`,
    ADD_GAME: `${BASE_URL}/games`,
    UPDATE_GAME: `${BASE_URL}/games/#ID#`,
    DELETE_GAME: `${BASE_URL}/games/#ID#`,
  },

  DISCOVER: {
    DISCOVERS_LIST: `${BASE_URL}/discovers`,
    GET_DISCOVER_BY_ID: `${BASE_URL}/discovers/#ID#`,
    ADD_DISCOVER: `${BASE_URL}/discovers`,
    UPDATE_DISCOVER: `${BASE_URL}/discovers/#ID#`,
    DELETE_DISCOVER: `${BASE_URL}/discovers/#ID#`,
  },

  WATCH_CONTENT: {
    WATCH_CONTENTS_LIST: `${BASE_URL_V2}/watches`,
    GET_WATCH_CONTENT_BY_ID: `${BASE_URL_V2}/watches/#ID#`,
    ADD_WATCH_CONTENT: `${BASE_URL_V2}/watches`,
    UPDATE_WATCH_CONTENT: `${BASE_URL_V2}/watches/#ID#`,
    DELETE_WATCH_CONTENT: `${BASE_URL_V2}/watches/#ID#`,
    FETCH_WATCH_CONTENT_CATEGORIES: `${BASE_URL_V2}/watch/dropdown/categories`,
    UPLOAD_WATCH_CONTENT_MEDIA: `${CLOUD_STORAGE_MANAGEMENT_API_URL}/upload/storage/${CLOUD_STORAGE_MANAGEMENT_API_VERSION}/b/${CLOUD_STORAGE_MANAGEMENT_BUCKET}/o`,
    DELETE_WATCH_CONTENT_MEDIA: `${CLOUD_STORAGE_MANAGEMENT_API_URL}/storage/${CLOUD_STORAGE_MANAGEMENT_API_VERSION}/b/${CLOUD_STORAGE_MANAGEMENT_BUCKET}/o`,
  },

  WATCH_CATEGORY: {
    WATCH_CATEGORIES_LIST: `${BASE_URL_V2}/watch/categories`,
    GET_WATCH_CATEGORY_BY_ID: `${BASE_URL_V2}/watch/categories/#ID#`,
    ADD_WATCH_CATEGORY: `${BASE_URL_V2}/watch/categories`,
    UPDATE_WATCH_CATEGORY: `${BASE_URL_V2}/watch/categories/#ID#`,
    DELETE_WATCH_CATEGORY: `${BASE_URL_V2}/watch/categories/#ID#`,
  },

  DEVICES: {
    DEVICES_LIST: `${BASE_URL}/devices`,
    GET_DEVICE_BY_ID: `${BASE_URL}/devices/#DEVICE_SERIAL#`,
    UPDATE_DEVICE: `${BASE_URL}/devices/#DEVICE_SERIAL#`,
    BULK_ACTION_STATUS_CHANGE: `${DOMAIN_V2_BASE_URL}/device/status/change`,
    GET_DEVICE_AND_RIDE_DETAILS_BY_ID: `${BASE_URL}/devices/#DEVICE_SERIAL#/details`,
  },
  ENROLLED_DEVICES: {
    ENROLLED_DEVICES_LIST: `${BASE_URL}/auth/devices`,
  },
  RIDES: {
    RIDES_LIST: `${BASE_URL}/rides`,
    RIDES_COUNT: `${BASE_URL}/ridescount`,
    RIDES_STATISTICS: `${BASE_URL}/ride/statistics`,
  },
  SCAN_LOGS: {
    SCAN_LOG: `${BASE_URL}/scan-log`,
  },
  APP_BG_TILES_SETTINGS: {
    GET_SETTINGS: `${BASE_URL}/dashbaordSetting`,
    UPDATE_MAIN_BG_IMAGE: `${BASE_URL}/dashbaord/bgImage`,
    UPDATE_READ_TILES_SETTINGS: `${BASE_URL}/dashbaord/read/settings`,
    UPDATE_GAME_TILES_SETTINGS: `${BASE_URL}/dashbaord/play/settings`,
    UPDATE_DISCOVER_TILES_SETTINGS: `${BASE_URL}/dashbaord/discover/settings`,
    UPDATE_WATCH_TILES_SETTINGS: `${BASE_URL}/dashbaord/watch/settings`,
  },

  APP_SETTINGS: {
    SYNC_ENROLLED_DEVICES_DATA: `${BASE_URL}/auth/sync`,
  },

  DASHBOARD: {
    DASHBOARD_STATISTICS_COUNT: `${BASE_URL}/common/statistics`,
    DASHBOARD_RIDE_CHART: `${BASE_URL}/common/ride-chart`,
    DASHBOARD_DEVICE_CHART: `${BASE_URL}/common/device-chart`,
    DASHBOARD_ADS_CAMPAIGN_CHART: `${BASE_URL}/common/campaign-chart`,
  },
};

export const URLS_V2 = {
  DEVICES: {
    DEVICES_LIST: `${BASE_URL_V2}/deviceList`,
    UPDATE_DEVICE: `${BASE_URL_V2}/updateDevice`,
    GET_DEVICE_DETAILS: `${BASE_URL_V2}/deviceInfo`,
    DEVICE_RATINGS_LIST: `${BASE_URL_V2}/appRatingList`,
    ALL_DEVICE_RATINGS_LIST: `${BASE_URL_V2}/appRatingList`,
    DEVICE_RATINGS_STATISTICS: `${BASE_URL_V2}/appRatingCounts`,
    BULK_ACTION_STATUS_CHANGE: `${BASE_URL_V2}/consoleUser/updateStatus`,
    IMPORT_DEVICES: `${BASE_URL_V2}/bulkUpdate`,
    DEVICE_COMMAND_UPDATE: `${BASE_URL_V2}/addDeviceCommand`,
  },
  DEVICES_SOCKET: {
    LISTEN_DEVICE_DETAILS: `/auth/device-details`,
    EMIT_UPDATED_DEVICE_RIDE_DETAILS: `/auth/device-info-update`,
    EMIT_DEVICE_COMMANDS: `/auth/device-command-sent`,
    DEVICE_STATUS_UPDATE: `/auth/device-status-update`,
    LISTEN_DUMMY_DEVICE_SOCKET: `/location-dummy-update`,
  },
  RIDES: {
    RIDES_LIST: `${BASE_URL_V2}/rideList`,
    RIDES_LIST_48_HOURS: `${BASE_URL_V2}/last48HourRideCount`,
    RIDE_INFO: `${BASE_URL_V2}/rideInfo/#ID#`,
    GET_CONFIRMED_SERVED_CAMPAIGN:`${BASE_URL_V2}/getConfirmedServedCampaign/#ID#`,
    RIDES_LIST_COUNT: `${BASE_URL_V2}/rideCount`,
    RIDES_STATISTICS_COUNT: `${BASE_URL_V2}/rides/dashboard/reports`,
    RIDES_STATISTICS_RIDESTATS: `${BASE_URL_V2}/rideStats`,
    RIDES_EXPORT_DETAILS: `${BASE_URL_V2}/exportRideDetail`,
  },
  CAMPAIGNS: {
    CAMPAIGNS_LIST: `${BASE_URL_V2}/campaigns`,
    UPDATE_CAMPAIGN_STATUS: `${BASE_URL_V2}/campaignStatus/#CAMPAIGN_ID#`,
    CAMPAIGN_ACTIVE_STATUS:`${BASE_URL_V2}/campaignActiveStatus/#CAMPAIGN_ID#?is_active=false`,
    DELETE_CAMPAIGN: `${BASE_URL_V2}/campaign/deleteCampaign/#CAMPAIGN_ID#`,
    GET_SINGLE_CAMPAIGN: `${BASE_URL_V2}/campaignInfo/#CAMPAIGN_ID#`,
    CAMPAIGNS_TYPES: `${BASE_URL_V2}/campaign-types`,
    CREATE_CAMPAIGN: `${BASE_URL_V2}/campaign/createCampaign`,
    UPDATE_CAMPAIGN: `${BASE_URL_V2}/updatecampaign/#CAMPAIGN_ID#`,
    AD_DELIVERY_REPORT: `${BASE_URL_V2}/campaign/ad-delivery-report`,
    COMPANIES_LIST: `${BASE_URL_V2}/campaign/getCampaignCompanies`,
    ADD_NEW_COMPANY:`${BASE_URL_V2}/campaign/createCampaignCompany`,
    APPROVE_CAMPAIGN: `${BASE_URL_V2}/campaignApproveStatus/#CAMPAIGN_ID#`,
    UPLOAD_MEDIA_IMAGE: `${BASE_URL_V2}/campaign-media-image/#ID#`,
    UPLOAD_MEDIA_VIDEO: `${BASE_URL_V2}/campaign-media-video/#ID#`,
    GET_INDUSTRY_CAMPAIGN:`${BASE_URL_V2}/campaign/getCampaignIndustries`,
    GET_CAMPAIGN_TYPE:`${BASE_URL_V2}/campaign/getCampaignTypes`,
    GET_CAMPAIGNS_LIST:`${BASE_URL_V2}/campaign/getCampaignList`,
    GENERATE_QR:`${BASE_URL_V2}/generateRedirectQRCode`,
    UPDATE_CAMPAIGNS: `${BASE_URL_V2}/campaign/updateCampaign/#CAMPAIGN_ID#`,
    GET_CAMPAIGNS_DETAIL:`${BASE_URL_V2}/campaign/getCampaignDetail/#ID#`,
    DELETE_MEDIA_FILE:`${BASE_URL_V2}/campaignFilesDelete?#URL#`

  },
  APP_RELEASE_MANAGER: {
    UPLOAD_APP: `${BASE_URL_V2}/auth/version_upload`,
    CREATE_NEW_RELEASE: `${BASE_URL_V2}/auth/version_update`,
    GET_APP_RELEASE_HISTORY_LIST: `${BASE_URL_V2}/auth/app_releaseList`,
    GET_CURRENT_APP_VERSION: `${BASE_URL_V2}/auth/latest_app_download`,
    GET_SIGNEDURL: `${BASE_URL_V2}/auth/getSignedUrl`,
    DELETE_APP_RELEASE: `${BASE_URL_V2}/auth/deleteAPK`,
  },
  UNISOC_APP_RELEASE_MANAGER: {
    UPLOAD_APP: `${BASE_URL_V2}/auth/version_upload`,
    CREATE_NEW_RELEASE: `${BASE_URL_V2}/auth/version_update1`,
    GET_APP_RELEASE_HISTORY_LIST: `${BASE_URL_V2}/auth/app_releaseList1`,
    GET_CURRENT_APP_VERSION: `${BASE_URL_V2}/auth/latest_app_download1`,
    DELETE_APP_RELEASE: `${BASE_URL_V2}/auth/deleteAPK1`,
  },
  APP_RELEASE_SOCKET: {
    EMIT_APP_UPDATE: `/auth/app_version_update`,
    GET_APP_RELEASE_HISTORY_LIST: `${BASE_URL_V2}/auth/app_releases`,
    GET_CURRENT_APP_VERSION: `${BASE_URL_V2}/auth/latest_app`,
  },
  ROLES: {
    ROLES_LIST: `${BASE_URL_V2}/auth/role/permissions`,
    ROLES_TYPES_LIST: `${BASE_URL_V2}/auth/role/list-role-types`,
    ROLES_UI_MODULES_LIST: `${BASE_URL_V2}/auth/role/list-ui-modules`,
    ROLES_CAMPAIGN_LIST: `${BASE_URL_V2}/campaigns?selection=id,campaign_name`,
    CREATE_UPDATE_ROLE: `${BASE_URL_V2}/auth/role/permission`,
    UPDATE_ROLE_STATUS: `${BASE_URL_V2}/auth/role/change-permission-status`,
    GET_SINGLE_ROLE_DETAILS: `${BASE_URL_V2}/auth/role/permission`,
    DELETE_ROLE: `${BASE_URL_V2}/auth/role/permission`,
  },
  USERS: {
    ROLES_LIST: `${BASE_URL_V2}/auth/roles`,
    USERS_LIST: `${BASE_URL_V2}/auth/admin/users`,
    NEW_USERS_LIST: `${BASE_URL_V2}/auth/admin/usersList`,
    CREATE_UPDATE_USER: `${BASE_URL_V2}/auth/admin/user`,
    UPDATE_USER_STATUS: `${BASE_URL_V2}/auth/admin/change-user-status`,
    GET_SINGLE_USER_DETAILS: `${BASE_URL_V2}/auth/admin/user`,
    DELETE_USER: `${BASE_URL_V2}/auth/admin/user`,
    ADD_USER:`${BASE_URL_V2}/user/addUser`
  },
  READING: {
    READINGS_LIST: `${BASE_URL_V2}/readingList`,
    GET_READING_BY_ID: `${BASE_URL_V2}/readingInfo/#ID#`,
    ADD_READING: `${BASE_URL_V2}/addReading`,
    UPDATE_READING: `${BASE_URL_V2}/updateReading/#ID#`,
    DELETE_READING: `${BASE_URL_V2}/deleteReading/#ID#`,
  },
  GAME: {
    GAMES_LIST: `${BASE_URL_V2}/gameList`,
    GET_GAME_BY_ID: `${BASE_URL_V2}/gameInfo/#ID#`,
    ADD_GAME: `${BASE_URL_V2}/addGame`,
    UPDATE_GAME: `${BASE_URL_V2}/updateGame/#ID#`,
    DELETE_GAME: `${BASE_URL_V2}/deleteGame/#ID#`,
    GET_SIGNEDURL: `${BASE_URL_V2}/game/getSignedUrl`,
  },
  DISCOVER: {
    DISCOVERS_LIST: `${BASE_URL_V2}/discoverList`,
    GET_DISCOVER_BY_ID: `${BASE_URL_V2}/discoverInfo/#ID#`,
    ADD_DISCOVER: `${BASE_URL_V2}/addDiscover`,
    UPDATE_DISCOVER: `${BASE_URL_V2}/updateDiscover/#ID#`,
    DELETE_DISCOVER: `${BASE_URL_V2}/deleteDiscover/#ID#`,
  },
  WATCH_CONTENT: {
    WATCH_CONTENTS_LIST: `${BASE_URL_V2}/watchList`,
    GET_WATCH_CONTENT_BY_ID: `${BASE_URL_V2}/watchInfo/#ID#`,
    ADD_WATCH_CONTENT: `${BASE_URL_V2}/createWatch`,
    UPDATE_WATCH_CONTENT: `${BASE_URL_V2}/updateWatch/#ID#`,
    DELETE_WATCH_CONTENT: `${BASE_URL_V2}/deleteWatch/#ID#`,
    FETCH_WATCH_CONTENT_CATEGORIES: `${BASE_URL_V2}/watch/dropdown/categoryList`,
    UPLOAD_WATCH_CONTENT_MEDIA: `${BASE_URL_V2}/watch-media-image/#ID#`,
    UPLOAD_WATCH_VIDEO: `${BASE_URL_V2}/watch-media-video/#ID#`,
    GET_SIGNEDURL: `${BASE_URL_V2}/getSignedUrl`,
  },
  WATCH_CATEGORY: {
    WATCH_CATEGORIES_LIST: `${BASE_URL_V2}/watch/categorieList`,
    GET_WATCH_CATEGORY_BY_ID: `${BASE_URL_V2}/watch/categoryInfo/#ID#`,
    ADD_WATCH_CATEGORY: `${BASE_URL_V2}/watch/addCategory`,
    UPDATE_WATCH_CATEGORY: `${BASE_URL_V2}/watch/updateCategory/#ID#`,
    DELETE_WATCH_CATEGORY: `${BASE_URL_V2}/watch/deleteCategories/#ID#`,
  },
  MUSIC_CATEGORY:{
    MUSIC_CATEGORY_LIST:`${BASE_URL_V2}/media/music/musicCategoryList`,
    DELETE_CATEGORY:`${BASE_URL_V2}/media/music/deleteMusicCategoryInfo/#ID#`,
    ADD_NEW_CATEGORY:`${BASE_URL_V2}/media/music/createMusicCategory`,
    GET_CATEGORY:`${BASE_URL_V2}/media/music/getMusicCategoryInfo/#ID#`,
    UPDATE_CATEGORY:`${BASE_URL_V2}/media/music/updateMusicCategoryInfo/#ID#`
  },
  MUSIC_CONTENT:{
    GET_MUSIC_LIST:`${BASE_URL_V2}/media/music/musicList`,
    ADD_MUSIC:`${BASE_URL_V2}/media/music/createMusic`,
    DELETE_MUSIC:`${BASE_URL_V2}/media/music/deleteMusicInfo/#ID#`,
    GET_MUSIC_INFO:`${BASE_URL_V2}/media/music/getMusicInfo/#ID#`,
    UPDATE_MUSIC:`${BASE_URL_V2}/media/music/updateMusicInfo/#ID#`
  },
  UPLOAD_MEDIA:{
UPLOAD_FILES:`${BASE_URL_V2}/media/uploadfiles`
  }
};

export const GC_API = {
  BUCKET: {
    MEDIA_UPLOAD: `${CLOUD_STORAGE_MANAGEMENT_API_URL}/upload/storage/${CLOUD_STORAGE_MANAGEMENT_API_VERSION}/b/${CLOUD_STORAGE_MANAGEMENT_BUCKET}/o`,
    MEDIA_DELETE: `${CLOUD_STORAGE_MANAGEMENT_API_URL}/storage/${CLOUD_STORAGE_MANAGEMENT_API_VERSION}/b/${CLOUD_STORAGE_MANAGEMENT_BUCKET}/o`,
  },
};
