import React, { useState, useEffect } from 'react';
import {
  Table,
  Form,
  Button,
  DatePicker,
  Spin,
  Space,
  Input,
  Card,
  Row,
  Col,
  Skeleton,
} from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import moment from 'moment';
import { CSVLink } from 'react-csv';
// import RideImageModal from 'components/ride-image-modal';
import { EnrollDeviceBox, Loader } from './_rides.styled';
import { currentPage, httpGet, URLS } from 'utils';
import StatisticCardBlock from 'components/statistic-card-block';
// import { usePlacesWidget } from 'react-google-autocomplete';

const { RangePicker } = DatePicker;

const Rides = () => {
  const [isFetching, setIsFetching] = useState(false);
  // const [docId, setDocId] = useState('');
  const ref = React.useRef(null);
  // const pickupLocationInputRef = React.useRef(null); // commented the location filter code
  // const dropLocationInputRef = React.useRef(null); // commented the location filter code

  const [ridesList, setRidesList] = useState([]);
  const [ridesTempList, setRidesTempList] = useState([]);
  const [rideStatistics, setRideStatistics] = useState({});
  const [totalRides, setTotalRides] = useState(0);
  const [CSVDownload, setCSVDownload] = useState([]);
  const [isFetchingCSV, setIsFetchingCSV] = useState(false);
  const [isFetchRideStats, setIsFetchRideStats] = useState(false);
  const filterParamsData = {
    startTime: '',
    endTime: '',
    deviceSerial: '',
    binaryCode: '',
    // pickupLocation: '', // commented the location filter code
    // dropLocation: '', // commented the location filter code
  };
  const [filterParams, setFilterParams] = useState(filterParamsData);
  const [paginationData, setPaginationData] = useState({
    skip: 0,
    limit: 100,
  });

  // commented the location filter code start
  // react google auto suggestion configuration
  // const autoCompleteLocationConfig = {
  //   apiKey: process.env.REACT_APP_GOOGLE_MAP_KEY,
  //   options: {
  //     types: ['geocode'],
  //     componentRestrictions: { country: 'AE' }, // country name to get auto suggestion for specific country
  //   },
  // };
  // commented the location filter code end

  // commented the location filter code start
  // pickup location hook for the react google suggestion
  // const { ref: pickupAutoCompletedRef } = usePlacesWidget({
  //   ...autoCompleteLocationConfig,
  //   onPlaceSelected: (place) => {
  //     let pickupLocationValue = '';
  //     if (place?.formatted_address) {
  //       pickupLocationValue = place?.formatted_address;
  //     }
  //     if (place?.name) {
  //       pickupLocationValue = place?.name;
  //     }
  //     if (
  //       pickupLocationValue &&
  //       pickupLocationValue !== null &&
  //       pickupLocationValue !== ''
  //     ) {
  //       pickupLocationInputRef.current.setValue(pickupLocationValue);
  //       setFilterParams((params) => ({
  //         ...params,
  //         pickupLocation: pickupLocationValue,
  //       }));
  //     } else {
  //       pickupLocationInputRef.current.setValue(pickupLocationValue);
  //       setFilterParams((params) => ({
  //         ...params,
  //         pickupLocation: '',
  //       }));
  //     }
  //   },
  // });
  // commented the location filter end

  // commented the location filter code start
  // drop location hook for the react google suggestion
  // const { ref: dropAutoCompletedRef } = usePlacesWidget({
  //   ...autoCompleteLocationConfig,
  //   onPlaceSelected: (place) => {
  //     let dropLocationValue = '';
  //     if (place?.formatted_address) {
  //       dropLocationValue = place?.formatted_address;
  //     }
  //     if (place?.name) {
  //       dropLocationValue = place?.name;
  //     }
  //     if (
  //       dropLocationValue &&
  //       dropLocationValue !== null &&
  //       dropLocationValue !== ''
  //     ) {
  //       dropLocationInputRef.current.setValue(dropLocationValue);
  //       setFilterParams((params) => ({
  //         ...params,
  //         dropLocation: dropLocationValue,
  //       }));
  //     } else {
  //       dropLocationInputRef.current.setValue(dropLocationValue);
  //       setFilterParams((params) => ({
  //         ...params,
  //         dropLocation: '',
  //       }));
  //     }
  //   },
  // });
  // commented the location filter code end

  useEffect(() => {
    // fetchRides();
    // fetchRidesTotal();
    fetchRidesStatistics();
  }, []);

  const fetchRidesStatistics = async () => {
    setIsFetchRideStats(true);
    const url = `${URLS?.RIDES?.RIDES_STATISTICS}`;
    const res = await httpGet(url);

    if (res?.status === 200) {
      setRideStatistics(res?.data || {});
    }
    setIsFetchRideStats(false);
  };

  useEffect(() => {
    // let cloneRidesList = [...ridesList];
    // if (filterParams?.startTime && filterParams?.endTime) {
    //   const startTime = moment(filterParams.startTime).unix();
    //   const endTime = moment(filterParams.endTime).unix();
    //   fetchRides({ startTime: startTime, endTime: endTime });
    //   fetchRidesTotal();
    // } else {
    //   fetchRides();
    //   fetchRidesTotal();
    // }
    // if (filterParams?.deviceSerial) {
    //   fetchRides({ deviceSerial: filterParams?.deviceSerial });
    //   fetchRidesTotal();
    // } else {
    // }
    fetchRides();
    fetchRidesTotal();
    // commented the location filter code start
    // if (filterParams?.pickupLocation) {
    //   fetchRides({ pickupLocation: filterParams?.pickupLocation });
    //   fetchRidesTotal();
    // } else {
    //   fetchRides();
    //   fetchRidesTotal();
    // }
    // if (filterParams?.dropLocation) {
    //   fetchRides({ dropLocation: filterParams?.dropLocation });
    //   fetchRidesTotal();
    // } else {
    //   fetchRides();
    //   fetchRidesTotal();
    // }
    // commented the location filter code end

    // setRidesTempList(cloneRidesList || []);
  }, [filterParams]);

  const fetchRides = async ({
    skip = 0,
    limit = 100,
    sortField = 'ride_start_time',
    sortOrder = -1,
    startTime = filterParams && moment(filterParams?.startTime).unix(),
    endTime = filterParams && moment(filterParams?.endTime).unix(),
    deviceSerial = filterParams?.deviceSerial,
    // pickup_area = filterParams?.pickupLocation, // commented the location filter code
    // dropoff_area = filterParams?.dropLocation, // commented the location filter code
  } = {}) => {
    setIsFetching(true);
    const queryParams = new URLSearchParams();
    queryParams.append('skip', skip);
    queryParams.append('limit', limit);
    if (startTime) queryParams.append('startTime', startTime);
    if (endTime) queryParams.append('endTime', endTime);
    if (deviceSerial) queryParams.append('deviceSerial', deviceSerial);
    if (sortField) queryParams.append('order_by', sortField);
    if (sortOrder) queryParams.append('order', sortOrder === 'ascend' ? 1 : -1);
    // commented the location filter code start
    // if (pickup_area) queryParams.append('pickup_area', pickup_area);
    // if (dropoff_area) queryParams.append('dropoff_area', dropoff_area);
    // commented the location filter code end

    const url = `${URLS?.RIDES?.RIDES_LIST}/?${queryParams.toString()}`;
    // const url = URLS?.RIDES?.RIDES_LIST;
    const res = await httpGet(url);
    const ridesData =
      (res?.data &&
        res?.data.map((doc) => {
          const formattedStartTime = moment
            .unix(doc.ride_start_time)
            .format('DD-MM-YYYY HH:mm:ss');

          const formattedEndTime = moment
            .unix(doc.ride_end_time)
            .format('DD-MM-YYYY HH:mm:ss');

          // const rideTime =
          //   doc.ride_end_time && doc.ride_start_time
          //     ? moment
          //         .unix(doc.ride_end_time)
          //         .diff(moment.unix(doc.ride_start_time), 'minutes') < 1
          //       ? moment
          //           .unix(doc.ride_end_time)
          //           .diff(moment.unix(doc.ride_start_time), 'seconds') + ' secs'
          //       : moment
          //           .unix(doc.ride_end_time)
          //           .diff(moment.unix(doc.ride_start_time), 'minutes') + ' mins'
          //     : '';
          const rideTime =
            doc.ride_end_time && doc.ride_start_time
              ? moment
                  .unix(doc.ride_end_time)
                  .diff(moment.unix(doc.ride_start_time), 'seconds')
              : '';
          return {
            ...doc,
            key: doc._id,
            startTime: formattedStartTime,
            endTime: formattedEndTime,
            rideTime: rideTime,
            docId: doc._id,
          };
        })) ||
      [];
    setRidesList(ridesData || []);
    setRidesTempList(ridesData || []);
    setIsFetching(false);
  };

  const fetchRidesTotal = () => {
    setIsFetching(true);
    const queryParams = new URLSearchParams();
    if (filterParams?.startTime)
      queryParams.append('startTime', moment(filterParams?.startTime).unix());
    if (filterParams?.endTime)
      queryParams.append('endTime', moment(filterParams?.endTime).unix());
    if (filterParams?.deviceSerial)
      queryParams.append('deviceSerial', filterParams?.deviceSerial);
    // commented the location filter code start
    // if (filterParams?.pickupLocation)
    //   queryParams.append('pickup_area', filterParams?.pickupLocation);
    // if (filterParams?.dropLocation)
    //   queryParams.append('dropoff_area', filterParams?.dropLocation);
    // commented the location filter code end

    const url = `${URLS?.RIDES?.RIDES_COUNT}/?${queryParams.toString()}`;
    httpGet(url)
      .then((res) => {
        setTotalRides(res.data || 0);
        // setIsFetching(false);
      })
      .catch((err) => {
        // setIsFetching(false);
      });
  };

  const handleStartEndTime = (value) => {
    if (value && value.length > 1) {
      setFilterParams({
        ...filterParams,
        startTime: value[0],
        endTime: value[1],
      });
    } else {
      setFilterParams({
        ...filterParams,
        startTime: '',
        endTime: '',
      });
      setRidesTempList(ridesList || []);
    }
  };

  const handleTextSearch = (e) => {
    setFilterParams({
      ...filterParams,
      [e.target.name]: e.target.value,
    });
  };

  const handleCSVData = (enrollDevicesData) => {
    let csvData = [];
    enrollDevicesData.forEach((data) => {
      const startDate = data.startTime;
      const endDate = data.endTime;
      const rideTime = data?.rideTime / 60;
      const excelStructureData = {
        [ENROLL_LABELS.DEVICE_SERIAL]: data?.device_serial || '',
        [ENROLL_LABELS.START_TIME]: startDate || '',
        [ENROLL_LABELS.END_TIME]: endDate || '',
        [ENROLL_LABELS.RIDE_TIME]:
          rideTime < 1
            ? rideTime.toString().substring(0, 4)
            : Math.trunc(rideTime) + ' min' || '',
        [ENROLL_LABELS.START_LOCATION]:
          data?.ridePickupArea ||
          data?.ride_pickup_location?.location?.coordinates,
        [ENROLL_LABELS.END_LOCATION]:
          data?.rideDropArea ||
          data?.ride_dropoff_location?.location?.coordinates,
      };
      csvData.push(excelStructureData);
    });
    setCSVDownload(csvData);
  };

  // const handleViewImage = ({ docId }) => {
  //   setDocId(docId);
  // };

  // const closeRideImageModal = () => {
  //   setDocId('');
  // };

  const setAreaNameFromLocationString = (locationArray) => {
    let amount_to_remove = 1;
    if (locationArray.length > 2) {
      amount_to_remove = 2;
    }
    locationArray.splice(
      locationArray.length - amount_to_remove,
      amount_to_remove
    );
    return locationArray;
  };

  const columns = [
    {
      title: ENROLL_LABELS.SR_NO,
      width: 80,
      render: (value, item, index) =>
        (currentPage(paginationData.skip, paginationData.limit) - 1) *
          paginationData.limit +
        index +
        1,
    },
    {
      key: 'device_serial',
      title: ENROLL_LABELS.DEVICE_SERIAL,
      dataIndex: 'device_serial',
      sorter: true,
    },
    {
      key: 'ride_start_time',
      title: ENROLL_LABELS.START_TIME,
      dataIndex: 'ride_start_time',
      defaultSortOrder: 'descend',
      sorter: true,
      render: (date) => {
        return (function Actions() {
          return (
            <>
              {(date && moment.unix(date).format('DD-MM-YYYY HH:mm:ss')) || ''}
            </>
          );
        })();
      },
    },
    {
      key: 'ride_end_time',
      title: ENROLL_LABELS.END_TIME,
      dataIndex: 'ride_end_time',
      sorter: true,
      render: (date) => {
        return (function Actions() {
          return (
            <>
              {(date && moment.unix(date).format('DD-MM-YYYY HH:mm:ss')) || ''}
            </>
          );
        })();
      },
    },
    {
      key: 'rideTime',
      title: ENROLL_LABELS.RIDE_TIME,
      dataIndex: 'rideTime',
      sorter: true,
      render: (data) => {
        return (function Actions() {
          const rideTime = data / 60;
          return (
            <>
              {(data &&
                `${
                  rideTime < 1
                    ? rideTime.toString().substring(0, 4)
                    : Math.trunc(rideTime)
                } mins`) ||
                ''}
            </>
          );
        })();
      },
    },
    {
      key: 'ride_pickup_location',
      title: ENROLL_LABELS.START_LOCATION,
      dataIndex: 'ride_pickup_location',
      render: (data, item) => {
        return (function Actions() {
          const ridePickupArea =
            (item?.ride_pickup_area &&
              setAreaNameFromLocationString(
                item?.ride_pickup_area.split(' - ')
              )) ||
            [];
          return (
            <>
              {ridePickupArea && ridePickupArea.length >= 1
                ? ridePickupArea.join(', ')
                : (data &&
                    `Lat:${
                      data.location ? data.location.coordinates[0] : ''
                    }, Lng:${
                      data.location ? data.location.coordinates[1] : ''
                    } `) ||
                  ''}
            </>
          );
        })();
      },
    },
    {
      key: 'ride_dropoff_location',
      title: ENROLL_LABELS.END_LOCATION,
      dataIndex: 'ride_dropoff_location',
      render: (data, item) => {
        const rideDropArea =
          (item?.ride_dropoff_area &&
            setAreaNameFromLocationString(
              item?.ride_dropoff_area.split(' - ')
            )) ||
          [];
        return (function Actions() {
          return (
            <>
              {rideDropArea && rideDropArea.length >= 1
                ? rideDropArea.join(', ')
                : (data &&
                    `Lat:${
                      data.location ? data.location.coordinates[0] : ''
                    }, Lng:${
                      data.location ? data.location.coordinates[1] : ''
                    }`) ||
                  ''}
            </>
          );
        })();
      },
    },
    // {
    //   key: 'docId',
    //   title: ENROLL_LABELS.IMAGE,
    //   dataIndex: 'docId',
    //   render: (docId) => {
    //     return (function Actions() {
    //       return (
    //         <>
    //           <Button
    //             shape="circle"
    //             className="preview-btn btn-mr-rt"
    //             onClick={() => handleViewImage({ docId: docId })}
    //           >
    //             <EyeOutlined />
    //           </Button>
    //         </>
    //       );
    //     })();
    //   },
    // },
  ];
  const handleExport = async ({
    limit = totalRides > 5000 || totalRides < 0 ? 5000 : totalRides,
    startTime = filterParams && moment(filterParams?.startTime).unix(),
    endTime = filterParams && moment(filterParams?.endTime).unix(),
    deviceSerial = filterParams?.deviceSerial,
    sortField = filterParams?.sortField,
    sortOrder = filterParams?.sortOrder,
    // pickup_area = filterParams?.pickupLocation, // commented the location filter code
    // dropoff_area = filterParams?.dropLocation, // commented the location filter code
  } = {}) => {
    handleCSVData([]);
    setIsFetchingCSV(true);
    const queryParams = new URLSearchParams();
    queryParams.append('limit', limit);
    if (startTime) queryParams.append('startTime', startTime);
    if (endTime) queryParams.append('endTime', endTime);
    if (deviceSerial) queryParams.append('deviceSerial', deviceSerial);
    if (sortField) queryParams.append('order_by', sortField);
    if (sortOrder) queryParams.append('order', sortOrder === 'ascend' ? 1 : -1);
    // commented the location filter code start
    // if (pickup_area) queryParams.append('pickup_area', pickup_area);
    // if (dropoff_area) queryParams.append('dropoff_area', dropoff_area);
    // commented the location filter end

    const url = `${URLS?.RIDES?.RIDES_LIST}/?${queryParams.toString()}`;
    // const url = URLS?.RIDES?.RIDES_LIST;
    const res = await httpGet(url);
    const ridesData =
      (res?.data &&
        res?.data.map((doc) => {
          const formattedStartTime = moment
            .unix(doc.ride_start_time)
            .format('DD-MM-YYYY HH:mm:ss');
          const formattedEndTime = doc.ride_end_time
            ? moment.unix(doc.ride_end_time).format('DD-MM-YYYY HH:mm:ss')
            : '';
          const rideTime =
            doc.ride_end_time && doc.ride_start_time
              ? moment
                  .unix(doc.ride_end_time)
                  .diff(moment.unix(doc.ride_start_time), 'seconds')
              : '';

          const ridePickupArea =
            doc && doc.ride_pickup_area
              ? setAreaNameFromLocationString(
                  doc.ride_pickup_area.split(' - ')
                ).join(', ')
              : '';
          const rideDropArea =
            doc && doc.ride_dropoff_area
              ? setAreaNameFromLocationString(
                  doc.ride_dropoff_area.split(' - ')
                ).join(', ')
              : '';

          return {
            ...doc,
            key: doc._id,
            startTime: formattedStartTime,
            endTime: formattedEndTime,
            rideTime: rideTime,
            ridePickupArea: ridePickupArea,
            rideDropArea: rideDropArea,
            docId: doc._id,
          };
        })) ||
      [];
    handleCSVData(ridesData || []);
    setIsFetchingCSV(false);
    ref.current.click();
  };
  const handlePaginationChange = (pagination, filters, sorter) => {
    const skip = pagination.current * pagination.pageSize - pagination.pageSize;
    const limit = pagination.pageSize;
    const sortField = sorter.field;
    const sortOrder = sorter.order;
    fetchRides({
      skip: skip,
      limit: limit,
      sortField: sortField,
      sortOrder: sortOrder,
    });
    setPaginationData({
      skip: skip,
      limit: limit,
    });
  };

  const renderRidesStatisticsCard = (
    cardTitle,
    totalRide,
    totalRideTime,
    totalRideAvgTime
  ) => {
    return (
      <Card title={cardTitle} bordered={false}>
        <Skeleton
          loading={isFetchRideStats}
          active
          paragraph={{ rows: 2, width: '100%' }}
          title={false}
        >
          <Row gutter={32}>
            <Col span={6}>
              <StatisticCardBlock
                title={'total rides'}
                value={totalRide || 0}
                valueStyle={{ color: '#3f8600' }}
              />
            </Col>
            <Col span={9}>
              <StatisticCardBlock
                title="total ride time minutes"
                value={totalRideTime || 0}
                precision={0}
                valueStyle={{ color: '#3f8600' }}
              />
            </Col>
            <Col span={9}>
              <StatisticCardBlock
                title="avg per day minutes"
                value={totalRideAvgTime || 0}
                precision={0}
                valueStyle={{ color: '#3f8600' }}
              />
            </Col>
          </Row>
        </Skeleton>
      </Card>
    );
  };

  return (
    <EnrollDeviceBox>
      <div className="table-header">
        <h3 className="table-title">Rides</h3>
        <div className="table-right-side">
          <div className="export-generate-btn">
            <Form.Item>
              <CSVLink
                data={CSVDownload}
                headers={headers}
                filename={'rides.csv'}
              >
                <span ref={ref}></span>
              </CSVLink>
              {isFetchingCSV ? (
                <div className="file-export-loading">
                  <Spin
                    indicator={
                      <LoadingOutlined style={{ fontSize: 18 }} spin />
                    }
                  />
                  <div>Data Fetching...</div>
                </div>
              ) : (
                <Button type="primary" onClick={handleExport}>
                  Export To Excel
                </Button>
              )}
            </Form.Item>
          </div>
        </div>
      </div>
      <div className="site-statistic-demo-card ride-kpis">
        <Row gutter={14}>
          <Col span={8}>
            {renderRidesStatisticsCard(
              'Last 7 days',
              rideStatistics?.last7_days?.count,
              rideStatistics?.last7_days?.ridetime_seconds / 60,
              rideStatistics?.last7_days?.ridetime_avg / 60
            )}
          </Col>
          <Col span={8}>
            {renderRidesStatisticsCard(
              'Last 30 days',
              rideStatistics?.last30_days?.count,
              rideStatistics?.last30_days?.ridetime_seconds / 60,
              rideStatistics?.last30_days?.ridetime_avg / 60
            )}
          </Col>
          <Col span={8}>
            {renderRidesStatisticsCard(
              'All',
              rideStatistics?.total?.count,
              rideStatistics?.total?.ridetime_seconds / 60,
              rideStatistics?.total?.ridetime_avg / 60
            )}
          </Col>
        </Row>
      </div>
      <div className="filter-form">
        <Form layout="inline" className="main-filter-form">
          <div className="left-form">
            <Form.Item>
              <Space direction="vertical" size={12}>
                <RangePicker
                  showTime={{ format: 'HH:mm' }}
                  format="YYYY-MM-DD HH:mm"
                  onChange={(startEndTime) => handleStartEndTime(startEndTime)}
                />
              </Space>
            </Form.Item>
            <Form.Item name="deviceSerial">
              <Input
                type="text"
                name="deviceSerial"
                onChange={handleTextSearch}
                placeholder="Search Device Serial..."
              />
            </Form.Item>
            {/* commented the location filter code start */}
            {/* <Form.Item>
              <Input
                ref={(c) => {
                  pickupLocationInputRef.current = c;
                  if (c) pickupAutoCompletedRef.current = c.input;
                }}
                placeholder="Select Start Location"
                name="pickupLocation"
                onChange={(event) =>
                  event?.target?.value === '' && handleTextSearch(event)
                }
              />
            </Form.Item>
            <Form.Item>
              <Input
                ref={(c) => {
                  dropLocationInputRef.current = c;
                  if (c) dropAutoCompletedRef.current = c.input;
                }}
                name="dropLocation"
                placeholder="Select End Location"
                onChange={(event) =>
                  event?.target?.value === '' && handleTextSearch(event)
                }
              />
            </Form.Item> */}
            {/* commented the location filter code end */}
          </div>
          <div className="export-generate-btn">
            <div>
              Showing Rides: {ridesTempList.length || 0} / Total Rides:{' '}
              {totalRides}
            </div>
          </div>
        </Form>
      </div>
      <div className="main-table-body">
        <Table
          loading={{
            indicator: <Loader>Fetching...</Loader>,
            spinning: isFetching,
          }}
          onChange={handlePaginationChange}
          columns={columns}
          dataSource={ridesTempList || []}
          size="small"
          pagination={{ defaultPageSize: 100, total: totalRides }}
        />
      </div>
      {/* {docId && (
        <RideImageModal
          handleOnClose={closeRideImageModal}
          isRideImageModalVisible={docId}
        />
      )} */}
    </EnrollDeviceBox>
  );
};

export default Rides;

const ENROLL_LABELS = {
  SR_NO: 'Sr. No.',
  DEVICE_SERIAL: 'Device Serial',
  START_TIME: 'Start Time',
  END_TIME: 'End Time',
  RIDE_TIME: 'Ride Time',
  START_LOCATION: 'Start Location',
  END_LOCATION: 'End Location',
  // IMAGE: 'Image',
};

const headers = [
  { label: ENROLL_LABELS.DEVICE_SERIAL, key: ENROLL_LABELS.DEVICE_SERIAL },
  { label: ENROLL_LABELS.START_TIME, key: ENROLL_LABELS.START_TIME },
  { label: ENROLL_LABELS.END_TIME, key: ENROLL_LABELS.END_TIME },
  { label: ENROLL_LABELS.RIDE_TIME, key: ENROLL_LABELS.RIDE_TIME },
  { label: ENROLL_LABELS.START_LOCATION, key: ENROLL_LABELS.START_LOCATION },
  { label: ENROLL_LABELS.END_LOCATION, key: ENROLL_LABELS.END_LOCATION },
  // { label: ENROLL_LABELS.IMAGE, key: ENROLL_LABELS.IMAGE },
];
