import styled from 'styled-components';

export const PreviewBox = styled.div``;

export const ModalContentBox = styled.div`
  display: block;
  margin: 0px auto;
  max-width: 1280px;
  width: 100%;
  position: relative;
  .video-box {
    background: grey;
    border-radius: 50px;
    width: 98%;
    height: 98%;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }

  .video {
    position: absolute;
    max-width: 100%;
    display: block;
    width: 100%;
    height: 100%;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    z-index: 3;
    border-radius: 5px;
    overflow: hidden;
    img {
      width: 100%;
      height: 100%;
    }
    padding: 6.5% 4.7% 6.5% 4.9%;
  }

  .lead-image {
    position: absolute;
    max-width: 100%;
    display: block;
    width: 100%;
    height: 100%;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    z-index: 3;
    border-radius: 5px;
    overflow: hidden;
    img {
      width: 100%;
      height: 100%;
    }
    padding: 6.4% 3.87% 18.8% 4.26%;
  }
  .image {
    position: absolute;
    max-width: 100%;
    display: block;
    width: 100%;
    height: 100%;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    z-index: 3;
    border-radius: 5px;
    overflow: hidden;
    img {
      width: 100%;
      height: 100%;
    }
    padding: 6.5% 4.5%;
  }
  img {
    display: block;
    margin: 0px auto;
    max-width: 1280px;
    width: 100%;
    position: relative;
    z-index: 2;
  }
`;

export const ModalBodyBox = styled.div`
  position: relative;
`;

export const ModalPlaceholderBox = styled.div`
  display: block;
  margin: 0px auto;
  max-width: 1280px;
  width: 100%;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  .image {
    position: absolute;
    max-width: 100%;
    display: block;
    width: 100%;
    height: 100%;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    z-index: 3;
    border-radius: 5px;
    overflow: hidden;
    img {
      width: 100%;
      height: 100%;
    }
    padding: 6.5% 4.5%;
  }
  img {
    display: block;
    margin: 0px auto;
    max-width: 1280px;
    width: 100%;
    position: relative;
    z-index: 2;
  }
`;
