import React, { useState } from 'react';
import { message, Form, Button, Upload, Spin, Modal } from 'antd';
import CommonBreadCrumb from 'components/common-breadcrumb';
// import { func, object, string } from 'prop-types';
import {
  DownloadOutlined,
  ExclamationCircleOutlined,
  InboxOutlined,
} from '@ant-design/icons';
import { httpPost, showErrorMsg, URLS_V2 } from 'utils';
import {
  ALLOWED_IMPORT_DEVICE_MEDIA_SIZE,
  ALLOWED_IMPORT_DEVICE_MEDIA_MIMETYPES,
  SAMPLE_BULK_IMPORT_FILE_PATH,
} from 'constants/devices';
import ImportDeviceModal from 'components/v2/import-device-modal';

const { confirm } = Modal;

const ImportDevices = () => {
  const breakCrumbList = [
    { label: 'Home', link: '/dashboard' },
    { label: 'Devices', link: '/v2/devices' },
    { label: 'Import Devices' },
  ];

  const [form] = Form.useForm();
  const [mediaFile, setMediaFile] = useState({});
  const [mediaUploading, setMediaUploading] = useState(false);
  const [isFormSubmitting, setIsFormSubmitting] = useState(false);
  const [showImportDeviceModal, setShowImportDeviceModal] = useState(false);
  const [importDeviceResponse, setImportDeviceResponse] = useState({});

  const handleCancelClick = () => {
    form.resetFields();
  };

  const uploadDraggerProps = {
    beforeUpload: (file) => {
      setMediaUploading(true);
      let isError = false;
      const isValid = ALLOWED_IMPORT_DEVICE_MEDIA_MIMETYPES.includes(file.type);
      const fileSize =
        file.size / 1024 >
        ALLOWED_IMPORT_DEVICE_MEDIA_SIZE.ALLOWED_IMPORT_DEVICE_MEDIA_SIZE;

      if (!isValid) {
        isError = true;
        message.error(`${file.name} is not a valid file`);
        setMediaUploading(false);
      }

      if (fileSize) {
        isError = true;
        message.error(
          `${file.name} must be smaller then ${
            ALLOWED_IMPORT_DEVICE_MEDIA_SIZE.ALLOWED_IMPORT_DEVICE_MEDIA_SIZE *
            1024
          } MB`
        );
        setMediaUploading(false);
      }
      if (!isError) {
        setMediaFile(file);
        setMediaUploading(false);
      }
      return false;
    },
    fileList: mediaFile,
  };

  const normFile = (e) => {
    if (Array.isArray(e)) {
      return e;
    }
    return e?.fileList;
  };

  const showConfirm = () => {
    confirm({
      title: 'Do you want to perform this action?',
      icon: <ExclamationCircleOutlined />,
      centered: true,
      width: 500,
      content: 'This action will override data of devices.',
      okText: 'Proceed',
      onOk() {
        handleSubmitImportDevice();
      },
    });
  };

  const handleSubmitImportDevice = () => {
    setIsFormSubmitting(true);
    setShowImportDeviceModal(true);

    const url = URLS_V2?.DEVICES?.IMPORT_DEVICES;
    const formData = new FormData();
    formData.append('xlsx_devices_updater', mediaFile);

    httpPost(url, formData)
      .then((res) => {
        if (res?.status === 200) {
          setImportDeviceResponse(res?.data);
        } else {
          showErrorMsg(
            res?.error || 'Internal server error. Please try again later.'
          );
          setShowImportDeviceModal(false);
        }
        form.resetFields();
        setMediaFile({});
        setIsFormSubmitting(false);
      })
      .catch((err) => {
        setIsFormSubmitting(false);
        setShowImportDeviceModal(false);
        showErrorMsg(err);
      });
  };

  const handleCloseDeviceModal = () => {
    setShowImportDeviceModal(false);
    setIsFormSubmitting(false);
    setImportDeviceResponse({});
  };

  return (
    <>
      <CommonBreadCrumb breadCrumbs={breakCrumbList} />
      <div className="while-bg-box cus-table-header">
        <h3 className="table-title">Import Devices</h3>
        <a href={SAMPLE_BULK_IMPORT_FILE_PATH} download>
          <Button type="primary" className="edit-btn">
            <DownloadOutlined />
            {'Download Template'}
          </Button>
        </a>
      </div>

      <Form
        form={form}
        layout="vertical"
        className="main-filter-form"
        labelCol={{ span: 4 }}
        wrapperCol={{ span: 24 }}
        onFinish={showConfirm}
      >
        <div className="while-bg-box filter-form">
          <Form.Item label="Select File">
            <Spin spinning={mediaUploading}>
              <Form.Item
                name="dragger"
                valuePropName="fileList"
                getValueFromEvent={normFile}
                rules={[
                  {
                    required: true,
                    message: 'Please select file!',
                  },
                ]}
              >
                <Upload.Dragger
                  name="file"
                  {...uploadDraggerProps}
                  maxCount={1}
                  disabled={isFormSubmitting}
                >
                  <p className="ant-upload-drag-icon">
                    <InboxOutlined />
                  </p>
                  <p className="ant-upload-text">
                    Click or drag file to this area to upload
                  </p>
                  <p className="ant-upload-hint">
                    Support for a single or bulk upload.
                  </p>
                </Upload.Dragger>
              </Form.Item>
            </Spin>
          </Form.Item>
        </div>
        <div className="while-bg-box">
          <Button
            type="primary"
            htmlType="submit"
            disabled={mediaUploading || !mediaFile?.name}
            loading={isFormSubmitting}
          >
            Submit
          </Button>
          <Button
            type="secondary"
            htmlType="button"
            className="m-l-10"
            onClick={handleCancelClick}
            disabled={isFormSubmitting || mediaUploading}
          >
            Cancel
          </Button>
        </div>
      </Form>
      {showImportDeviceModal && (
        <ImportDeviceModal
          handleCloseModal={handleCloseDeviceModal}
          modalVisible={showImportDeviceModal}
          modalTitle={'Import Devices'}
          isFormSubmitting={isFormSubmitting}
          importDeviceResponse={importDeviceResponse}
        />
      )}
    </>
  );
};

// ImportDevices.propTypes = {
//   appCurrentVersion: object,
//   packageName: string,
//   renderCurrentVersion: func,
//   resetAllData: func,
// };

export default ImportDevices;
