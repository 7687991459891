import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { string, func } from 'prop-types';
import {
  Input,
  Modal,
  Form,
  Row,
  Col,
  Button,
  Spin,
  Upload,
  message,
  Typography,
} from 'antd';
import {
  InboxOutlined,
  PlusOutlined,
  DeleteOutlined,
  EyeOutlined,
} from '@ant-design/icons';
import { MODE_TYPES } from 'constants/common';
import { APK_FILE_SIZE } from 'constants/app-release-manage';
import {
  httpPost,
  httpPut,
  httpGet,
  URLS_V2,
  showErrorMsg,
  showSuccessMsg,
} from 'utils';
import validUrl from 'valid-url';
import {
  GameModalBox,
  UploadImageBox,
  PreviewImageBox,
  CenteredSpin,
} from './_game-modal.styled';
import { GAME_IMAGE_DIMENSIONS } from 'constants/game';

const logoImageInitialStructure = {
  base64: '',
  file: '',
  gcloudUrl: '',
};

const { Text } = Typography;

const GameModal = (props) => {
  const { modalTitle, activeMode, handleOk, closeModal, callback, gameId } =
    props;
  const [form] = Form.useForm();
  const [validateFieldsName, setvalidateFieldsName] = useState([]);
  const [logoImage, setLogoImage] = useState(logoImageInitialStructure);
  const [apkUploading, setAPKUploading] = useState(false);
  const [, setAPKFile] = useState();
  const [isPreviewLogoImg, setIsPreviewLogoImg] = useState(false);
  const [isGameAdding, setIsGameAdding] = useState(false);
  const [isGameUpdating, setIsGameUpdating] = useState(false);
  const [isFetchGame, setIsFetchingGame] = useState(false);
  const [isFormSubmitting, setIsFormSubmitting] = useState(false);

  const isDisabled = isGameAdding || isGameUpdating;

console.log(modalTitle,activeMode, )

  useEffect(() => {
    if (activeMode === MODE_TYPES.ADD) {
      form.resetFields();
      setLogoImage(logoImageInitialStructure);
    }
    if (!gameId) return;
    setIsFetchingGame(true);
    const url = URLS_V2?.GAME.GET_GAME_BY_ID.replace('#ID#', gameId);
    httpGet(url)
      .then((res) => {
        if (res.status === 200) {
          if (
            res?.data &&
            Object.entries(res?.data) &&
            Object.entries(res?.data).length > 0
          ) {
            Object.entries(res?.data).forEach(([key, value]) => {
              if (key === 'art_work') {
                setLogoImage({
                  ...logoImageInitialStructure,
                  gcloudUrl: value,
                });
              } else if (key === 'link') {
                form.setFieldsValue({
                  [key]: value,
                });
                // setAPKFile({
                //   name: value.split()[0],
                //   url: value,
                //   isUploaded: true,
                // });
                setIsFormSubmitting(true);
              } else {
                form.setFieldsValue({
                  [key]: value,
                });
              }
            });
          }
        } else if (res?.error) {
          showErrorMsg(res?.error);
        }
        setIsFetchingGame(false);
      })
      .catch((err) => {
        showErrorMsg(err);
        setIsFetchingGame(false);
      });
  }, [activeMode]);

  const handleValidateFieldNames = (name) => {
    const isFieldName = validateFieldsName.find(
      (fieldName) => fieldName === name
    );
    if (isFieldName) return 'onChange';
    return 'onBlur';
  };

  const getBase64 = (img, callback) => {
    const reader = new FileReader();
    reader.addEventListener('load', () => callback(reader.result));
    reader.readAsDataURL(img);
  };

  const handleLogoImgUpload = async (e) => {
    const file = e.target.files[0];

    if (file) {
      const isImageValid = await new Promise((resolve, reject) => {
        // check the file type - you can specify the types you'd like here:
        const isImg = file.type === 'image/jpeg' || file.type === 'image/jpg';
        if (!isImg) {
          showErrorMsg(
            `Invalid image extension. Image must be in .jpeg, or .jpg extension`
          );
          resolve(false);
          return;
        }

        // check the file size - you can specify the file size you'd like here:
        const imageSize = file.size / 1024 > GAME_IMAGE_DIMENSIONS.FILE_SIZE;
        if (imageSize) {
          showErrorMsg(
            `Image size must be smaller then ${GAME_IMAGE_DIMENSIONS.FILE_SIZE} KB`
          );
          resolve(false);
          return;
        }

        // check for dimensions
        var reader = new FileReader();
        // Read the contents of Image File.
        reader.readAsDataURL(file);
        reader.onload = (event) => {
          // Initiate the JavaScript Image object.
          var image = new Image();

          // Set the Base64 string return from FileReader as source.
          image.src = event.target.result;

          image.onload = function () {
            // const { height, width } = this;
            // if the aspect ratio is in our sweet spot, proceed - you can specify whatever checks for height and width you want
            // if (
            //   width !== GAME_IMAGE_DIMENSIONS.FILE_WIDTH ||
            //   height !== GAME_IMAGE_DIMENSIONS.FILE_HEIGHT
            // ) {
            //   showErrorMsg(
            //     `Image does not have optimal dimensions. Recommended image dimensions are ${GAME_IMAGE_DIMENSIONS.FILE_WIDTH} X ${GAME_IMAGE_DIMENSIONS.FILE_HEIGHT}.`
            //   );
            //   resolve(false);
            // } else {
            //   resolve(true);
            // }
            resolve(true);
          };
        };
      });
      if (isImageValid) {
        getBase64(file, (imageUrl) => {
          setLogoImage({
            ...logoImageInitialStructure,
            base64: imageUrl,
            file,
          });
        });
      }
    }
  };

  const handlePreviewLogoImg = ({ isVisible }) => {
    setIsPreviewLogoImg(isVisible);
  };

  const getImageUrl = logoImage.gcloudUrl || logoImage.base64;

  const handleLogoImgDelete = () => {
    setLogoImage(logoImageInitialStructure);
  };

  const normFile = (e) => {
    if (Array.isArray(e)) {
      return e;
    }
    return e?.fileList;
  };

  const uploadDraggerProps = {
    beforeUpload: async (file) => {
      let isError = false;
      const isAPK = file.type === 'application/vnd.android.package-archive';
      const fileSize = file.size / 1024 > APK_FILE_SIZE.APK_FILE_SIZE;

      if (!isAPK) {
        isError = true;
        message.error(`${file.name} is not a valid APK file`);
      }

      if (fileSize) {
        isError = true;
        message.error(
          `${file.name} must be smaller then ${
            APK_FILE_SIZE.APK_FILE_SIZE * 1024
          } MB`
        );
      }
      if (!isError) {
        await uploadMediaToBucket(file);
      }
      return isError;
    },
    onRemove: (file) => {
      deleteMediaFromBucket(file);
    },
  };

  const handleAddGame = () => {
    form.submit();
    form
      .validateFields()
      .then(async (values) => {
        const formData = new FormData();
        formData.append('art_work', logoImage.file);
        formData.append('name', values.name);
        formData.append('link', values.link);
        if (!logoImage.file) {
          showErrorMsg('Art work required!');
          return;
        }
        setIsGameAdding(true);
        httpPost(URLS_V2?.GAME.ADD_GAME, formData)
          .then((res) => {
            if (res.status === 200) {
              showSuccessMsg('Game added successfully!');
              resetAllData();
              callback();
              // updateFirebaseGlobalAppSettings(GLOBAL_APP_SETTINGS_KEYS.game);
            } else if (res?.error) {
              showErrorMsg(res?.error);
            }
            setIsGameAdding(false);
          })
          .catch((err) => {
            showErrorMsg(err);
            setIsGameAdding(false);
          });
      })
      .catch((errorInfo) => errorInfo);
  };

  const handleEditGame = () => {
    form.submit();
    form
      .validateFields()
      .then(async (values) => {
        const formData = new FormData();
        formData.append('art_work', logoImage.file);
        formData.append('name', values.name);
        formData.append('link', values.link);
        if (!logoImage.file && !logoImage.gcloudUrl) {
          showErrorMsg('Art work required!');
          return;
        }
        setIsGameUpdating(true);
        const url = URLS_V2?.GAME.UPDATE_GAME.replace('#ID#', gameId);
        httpPut(url, formData)
          .then((res) => {
            if (res.status === 200) {
              showSuccessMsg('Game updated successfully!');
              resetAllData();
              callback();
              // updateFirebaseGlobalAppSettings(GLOBAL_APP_SETTINGS_KEYS.game);
            } else if (res?.error) {
              showErrorMsg(res?.error);
            }
            setIsGameUpdating(false);
          })
          .catch((err) => {
            showErrorMsg(err);
            setIsGameUpdating(false);
          });
      })
      .catch((errorInfo) => errorInfo);
  };

  const resetAllData = () => {
    form.resetFields();
    setLogoImage(logoImageInitialStructure);
    closeModal();
  };
  const handleOnCloseModal = () => {
    if (isDisabled) return;
    resetAllData();
  };

  const uploadMediaToBucket = async (file) => {
    setAPKUploading(true);
    let url = `${URLS_V2?.GAME?.GET_SIGNEDURL}?filename=${file.name}&action=write`;
    httpGet(url)
      .then(async (res) => {
        if (res.status === 200) {
          try {
            await axios.put(res.data.signedUrl, file, {
              headers: {
                'Content-Type': file.type,
              },
            });
            const baseUrl = 'https://storage.googleapis.com';
            const bucketName =
              process.env.REACT_APP_CLOUD_STORAGE_MANAGEMENT_BUCKET; // Replace with your actual bucket name
            const filePath = `content/game/${file.name.replace(/\s+/g, '')}`; // Replace with the desired folder path
            const fileUrl = `${baseUrl}/${bucketName}/${filePath}`;
            setAPKFile({
              name: file?.name,
              url: fileUrl,
              isUploaded: true,
            });
            form.setFieldsValue({
              link: fileUrl,
            });
            setAPKUploading(false);
          } catch (error) {
            showErrorMsg(error);
            setAPKUploading(false);
          }
        }
      })
      .catch((err) => {
        showErrorMsg(err);
        setIsFormSubmitting(false);
      });
  };

  const deleteMediaFromBucket = (file) => {
    let url = `${URLS_V2?.GAME?.GET_SIGNEDURL}?filename=${file.name}&action=delete`;
    httpGet(url)
      .then(async (res) => {
        if (res.status === 200) {
          try {
            await axios.delete(res.data.signedUrl);
            setAPKUploading(false);
            form.setFieldsValue({
              link: '',
            });
          } catch (error) {
            showErrorMsg(error);
            setAPKUploading(false);
          }
        }
      })
      .catch((err) => {
        showErrorMsg(err);
        setIsFormSubmitting(false);
      });
  };

  let modalfooterButtons = [
    <Button
      key="add"
      type="primary"
      onClick={handleAddGame}
      disabled={isDisabled}
    >
      {isGameAdding ? 'Loading...' : 'Add Game'}
    </Button>,
    <Button key="close" onClick={handleOnCloseModal} disabled={isDisabled}>
      Close
    </Button>,
  ];

  if (activeMode === MODE_TYPES.EDIT) {
    modalfooterButtons = [
      <Button
        key="update"
        type="primary"
        onClick={handleEditGame}
        disabled={isDisabled}
      >
        {isGameUpdating ? 'Loading...' : 'Update Game'}
      </Button>,
      <Button key="close" onClick={handleOnCloseModal} disabled={isDisabled}>
        Close
      </Button>,
    ];
  }
  let getModalTitle = modalTitle;
  if (activeMode === MODE_TYPES.ADD) {
    getModalTitle = 'Add Game';
  }
  if (activeMode === MODE_TYPES.EDIT) {
    getModalTitle = 'Edit Game';
  }
  return (
    <div>
      <GameModalBox>
        <Modal
          title={getModalTitle}
          visible={Boolean(activeMode)}
          onOk={handleOk}
          onCancel={handleOnCloseModal}
          className="expense-modal"
          maskClosable={false}
          width={550}
          forceRender={true}
          footer={modalfooterButtons}
        >
          {!isFetchGame ? (
            <Form form={form} layout="vertical">
              <Row gutter={16}>
                <Col className="gutter-row" xs={24} sm={8}>
                  {getImageUrl ? (
                    <PreviewImageBox>
                      <img src={getImageUrl} alt="logo" />
                      <div className="icons-div">
                        <div className="icons">
                          <div>
                            <EyeOutlined
                              onClick={() => {
                                handlePreviewLogoImg({ isVisible: true });
                              }}
                            />
                          </div>
                          <div>
                            <DeleteOutlined onClick={handleLogoImgDelete} />
                          </div>
                        </div>
                      </div>
                      <Modal
                        visible={isPreviewLogoImg}
                        title={'Preview'}
                        footer={null}
                        onCancel={() =>
                          handlePreviewLogoImg({ isVisible: false })
                        }
                      >
                        <img
                          alt="logo"
                          style={{ width: '100%' }}
                          src={getImageUrl}
                        />
                      </Modal>
                    </PreviewImageBox>
                  ) : (
                    <UploadImageBox htmlFor="imageUrl">
                      <div className={`upload-image `}>
                        <>
                          <div className="icon">
                            <PlusOutlined />
                          </div>
                          <div>Upload Art Work</div>
                          <div>
                            <Text type="secondary">{`Max file size: ${
                              GAME_IMAGE_DIMENSIONS.FILE_SIZE / 1000
                            } MB`}</Text>
                          </div>
                          {/* <div>
                            <Text type="secondary">{`Resolution: ${GAME_IMAGE_DIMENSIONS.FILE_WIDTH} X ${GAME_IMAGE_DIMENSIONS.FILE_HEIGHT}`}</Text>
                          </div> */}
                        </>
                      </div>
                      <input
                        id="imageUrl"
                        type="file"
                        name="imageUrl"
                        onChange={handleLogoImgUpload}
                        style={{ visibility: 'hidden' }}
                        disabled={activeMode === MODE_TYPES.EDIT ? true : false}
                      />
                    </UploadImageBox>
                  )}
                  <Form.Item label="Upload APK" name="file">
                    <Spin spinning={apkUploading}>
                      <Form.Item
                        name="dragger"
                        valuePropName="fileList"
                        getValueFromEvent={normFile}
                        validateTrigger={handleValidateFieldNames('dragger')}
                        rules={[
                          {
                            required: false,
                            message: 'Please select file!',
                          },
                        ]}
                      >
                        <Upload.Dragger
                          name="file"
                          {...uploadDraggerProps}
                          maxCount={1}
                          disabled={isFormSubmitting}
                        >
                          <p className="ant-upload-drag-icon">
                            <InboxOutlined />
                          </p>
                          <p className="ant-upload-text">
                            Click or drag file to this area to upload
                          </p>
                          <p className="ant-upload-hint">
                            Support for a single or bulk upload.
                          </p>
                        </Upload.Dragger>
                      </Form.Item>
                    </Spin>
                  </Form.Item>
                </Col>
                <Col className="gutter-row" xs={24} sm={16}>
                  <Form.Item
                    name="name"
                    label="Name"
                    validateTrigger={handleValidateFieldNames('name')}
                    rules={[
                      {
                        required: true,
                        message: 'Please enter name!',
                      },
                    ]}
                  >
                    <Input
                      type="text"
                      placeholder="Name"
                      onBlur={() =>
                        setvalidateFieldsName([...validateFieldsName, 'name'])
                      }
                    />
                  </Form.Item>
                  <Form.Item
                    name="link"
                    label="Link"
                    validateTrigger={handleValidateFieldNames('link')}
                    rules={[
                      {
                        required: true,
                        message: 'Please enter redirect link!',
                      },
                      {
                        validator(_, value) {
                          if (value && !validUrl.isUri(value)) {
                            return Promise.reject('Please enter valid url');
                          }
                          return Promise.resolve();
                        },
                      },
                    ]}
                  >
                    <Input
                      type="text"
                      placeholder="Link"
                      onBlur={() =>
                        setvalidateFieldsName([...validateFieldsName, 'link'])
                      }
                      disabled={activeMode === MODE_TYPES.EDIT ? true : false}
                    />
                  </Form.Item>
                </Col>
              </Row>
            </Form>
          ) : (
            <CenteredSpin spinning={isFetchGame} tip="Loading..." />
          )}
        </Modal>
      </GameModalBox>
    </div>
  );
};

GameModal.propTypes = {
  activeMode: string,
  callback: func,
  closeModal: func,
  gameId: string,
  handleOk: func,
  modalTitle: string,
};

export default GameModal;
