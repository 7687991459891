import React, { useEffect, useState } from 'react';
import { Modal, Form, Button, Input, Select, Spin } from 'antd';
import { string, func, bool } from 'prop-types';
import {
  httpGet,
  httpPost,
  httpPut,
  showErrorMsg,
  showSuccessMsg,
  URLS_V2,
} from 'utils';
import { API_V2_ERROR_CODES, MODE_TYPES } from 'constants/common';

const { Option } = Select;

const AddEditUserRoleModal = (props) => {
  const {
    activeMode,
    handleCloseModal,
    handleSubmitSuccessModal,
    modalVisible,
    recordId,
    sectionName,
  } = props;

  const [form] = Form.useForm();

  const [validateFieldsName, setValidateFieldsName] = useState([]);
  const [rolesUIModules, setRolesUIModules] = useState([]);
  const [campaignList, setCampaignList] = useState([]);
  const [rolesType, setRoleTypes] = useState([]);
  const [isRoleTypeFetching, setIsRoleTypeFetching] = useState(false);
  const [isFormSubmitting, setIsFormSubmitting] = useState(false);
  const [isFetching, setIsFetching] = useState(false);

  useEffect(() => {
    fetchRolesTypeList();
    fetchRolesUIModules();
    fetchCampaignsList();
  }, []);

  useEffect(() => {
    if (activeMode === MODE_TYPES.UPDATE) {
      fetchSelectedRoleDetails();
    }
  }, [activeMode]);

  const modalTitle = `${activeMode} ${sectionName}`;

  const fetchSelectedRoleDetails = () => {
    setIsFormSubmitting(true);
    const url = `${URLS_V2?.ROLES?.GET_SINGLE_ROLE_DETAILS}?id=${recordId}`;
    httpGet(url)
      .then((res) => {
        if (res?.status === 200 && res?.data) {
          form.setFieldsValue(res?.data);
        }
        setIsFormSubmitting(false);
      })
      .catch((err) => {
        setIsFormSubmitting(false);
      });
  };

  const fetchRolesTypeList = () => {
    setIsRoleTypeFetching(true);
    const url = `${URLS_V2?.ROLES?.ROLES_TYPES_LIST}`;
    httpGet(url)
      .then((res) => {
        if (res?.status === 200) {
          let tempRoleTypesData = [];
          if (res?.data && res?.data.length > 0) {
            tempRoleTypesData = res?.data.map((data) => {
              return {
                ...data,
                key: data?._id,
              };
            });
          }
          setRoleTypes(tempRoleTypesData || []);
        }
        setIsRoleTypeFetching(false);
      })
      .catch((err) => {
        setIsRoleTypeFetching(false);
      });
  };

  const fetchRolesUIModules = () => {
    setIsFetching(true);

    const url = `${URLS_V2?.ROLES?.ROLES_UI_MODULES_LIST}`;
    httpGet(url)
      .then((res) => {
        if (res?.status === 200) {
          let tempUIModulesData = [];
          if (res?.data && res?.data.length > 0) {
            tempUIModulesData = res?.data.map((data) => {
              return {
                ...data,
                key: data?._id,
              };
            });
          }
          setRolesUIModules(tempUIModulesData || []);
        }
        setIsFetching(false);
      })
      .catch((err) => {
        setIsFetching(false);
      });
  };

  const fetchCampaignsList = () => {
    setIsFetching(true);
    const url = `${URLS_V2?.ROLES?.ROLES_CAMPAIGN_LIST}`;
    httpGet(url)
      .then((res) => {
        if (res?.status === 200) {
          let tempCampList = [];
          if (res?.data && res?.data.length > 0) {
            tempCampList = res?.data.map((data) => {
              return {
                ...data,
                key: data?._id,
              };
            });
          }
          setCampaignList(tempCampList || []);
        }
        setIsFetching(false);
      })
      .catch((err) => {
        setIsFetching(false);
      });
  };

  const handleValidateFieldNames = (name) => {
    const isFieldName = validateFieldsName.find(
      (fieldName) => fieldName === name
    );
    if (isFieldName) return 'onChange';
    return 'onBlur';
  };

  const handleCancelClick = () => {
    form.resetFields();
    handleCloseModal();
  };

  const handleSubmitModal = () => {
    const url = `${URLS_V2?.ROLES?.CREATE_UPDATE_ROLE}`;
    form.submit();
    form
      .validateFields()
      .then(async (values) => {
        setIsFormSubmitting(true);
        let method = httpPost;
        let msg = 'Role created successfully';
        if (activeMode === MODE_TYPES.UPDATE) {
          values = { ...values, id: recordId };
          method = httpPut;
          msg = 'Role updated successfully';
        }
        if (values?.role_type === 'superadmin') {
          values = { ...values, permissions: ['all'] };
        }
        method(url, values)
          .then((res) => {
            if (res.status === 200 || res.status === 201) {
              showSuccessMsg(msg);
              form.resetFields();
              handleSubmitSuccessModal();
            } else if (
              res?.status &&
              API_V2_ERROR_CODES.includes(res?.status)
            ) {
              showErrorMsg(res?.message || res?.error);
            }
            setIsFormSubmitting(false);
          })
          .catch((err) => {
            showErrorMsg(err);
            setIsFormSubmitting(false);
          });
      })
      .catch((errorInfo) => errorInfo);
  };

  let modalFooterButtons = [
    <Button
      key="add"
      type="primary"
      onClick={handleSubmitModal}
      disabled={isFormSubmitting}
    >
      {isFormSubmitting ? 'Loading...' : modalTitle}
    </Button>,
    <Button key="close" onClick={handleCancelClick} disabled={isFormSubmitting}>
      Close
    </Button>,
  ];

  return (
    <>
      <Modal
        title={modalTitle}
        visible={modalVisible}
        // onOk={handleOk}
        onCancel={handleCancelClick}
        // className="expense-modal"
        maskClosable={false}
        width={550}
        forceRender={true}
        footer={modalFooterButtons}
      >
        <Spin spinning={isFormSubmitting}>
          <Form form={form} layout="vertical">
            <div className="while-bg-box filter-form">
              <Form.Item
                name="title"
                label="Role Title"
                validateTrigger={handleValidateFieldNames('title')}
                rules={[
                  {
                    required: true,
                    message: 'Please enter role title!',
                  },
                ]}
              >
                <Input
                  style={{ width: '100%' }}
                  placeholder={'Role Title'}
                  onBlur={() =>
                    setValidateFieldsName([...validateFieldsName, 'title'])
                  }
                  disabled={isFormSubmitting}
                />
              </Form.Item>

              <Form.Item
                name="role_type"
                label="Role Type"
                validateTrigger={handleValidateFieldNames('role_type')}
                rules={[
                  {
                    required: true,
                    message: 'Please select role type!',
                  },
                ]}
              >
                <Select
                  showSearch
                  loading={isRoleTypeFetching}
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    option.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                  placeholder="Select Role Type"
                >
                  {rolesType?.length >= 1 &&
                    rolesType.map((data, index) => (
                      <Option key={index} value={data.value}>
                        {data.displayText}
                      </Option>
                    ))}
                </Select>
              </Form.Item>

              <Form.Item
                noStyle
                shouldUpdate={(prevValues, currentValues) =>
                  prevValues.role_type !== currentValues.role_type
                }
              >
                {({ getFieldValue }) =>
                  getFieldValue('role_type') &&
                  (getFieldValue('role_type') === 'consoleUser' ||
                    getFieldValue('role_type') === 'reportingUser') ? (
                    <Form.Item
                      name="permissions"
                      label="Grant Access To"
                      validateTrigger={handleValidateFieldNames('modules')}
                      rules={[
                        {
                          required: true,
                          message: 'Please select access to!',
                        },
                      ]}
                    >
                      <Select
                        showSearch={true}
                        mode="multiple"
                        loading={isFetching}
                        placeholder="Select Access"
                        optionFilterProp="children"
                        filterOption={(input, option) =>
                          option.children
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                        }
                      >
                        {getFieldValue('role_type') === 'consoleUser'
                          ? rolesUIModules?.length >= 1 &&
                            rolesUIModules.map((data, index) => (
                              <Option key={data.value} value={data.value}>
                                {data.displayText}
                              </Option>
                            ))
                          : campaignList?.length >= 1 &&
                            campaignList.map((data, index) => (
                              <Option key={data._id} value={data._id}>
                                {data.campaign_name}
                              </Option>
                            ))}
                      </Select>
                    </Form.Item>
                  ) : null
                }
              </Form.Item>
            </div>
          </Form>
        </Spin>
      </Modal>
    </>
  );
};

AddEditUserRoleModal.propTypes = {
  activeMode: string,
  handleCloseModal: func,
  handleSubmitSuccessModal: func,
  modalVisible: bool,
  recordId: string,
  sectionName: string,
};

export default AddEditUserRoleModal;
