import { Breadcrumb, Button, Popconfirm, Spin, Table } from 'antd'
import React, { useEffect, useState } from 'react'
import { Loader, MusicContentBox } from './MusicContent.styled'
import { httpDelete, httpGet, showErrorMsg, showSuccessMsg, URLS_V2 } from 'utils'
import moment from 'moment'
import { DeleteOutlined, EyeOutlined, FormOutlined, LoadingOutlined } from '@ant-design/icons'
import { API_V2_ERROR_CODES, MODE_TYPES } from 'constants/common'
import MusicContentModal from './MusicContentModal/MusicContentModal'

const MusicContent = () => {
  const [isFetchingMusicList, setIsFetchingMusicList] = useState(false);
const [musicList, setMusicList]=useState([])
const [activeMode, setActiveMode] = useState();
  const [recordId, setRecordId] = useState();
  const [deletingWatchId, setDeletingWatchId] = useState('');
  
useEffect(()=>{
  fetchMusicContentList()
},[])


const fetchMusicContentList=()=>{
  setIsFetchingMusicList(true);

let url= URLS_V2?.MUSIC_CONTENT?.GET_MUSIC_LIST
  httpGet(url)
  .then((res=>{
if(res?.status==200){
setMusicList(res?.data)
}
setIsFetchingMusicList(false);

  }))
  .catch((err)=>{
    setIsFetchingMusicList(false);

  })
}


  const columns = [
    {
      title: CONTENT_LABELS.SR_NO,
      // width: '4%',
      render: (value, item, index) => index + 1,
    },
    {
      key: 'title',
      title: CONTENT_LABELS.TITLE,
      dataIndex: 'title',
      // width: '15%',
    },
    {
      key: 'category',
      title: CONTENT_LABELS.CATEGORY,
      dataIndex: 'category',
      // width: '15%',
      render: (category) => {
        return (function Actions() {
          return <div>{category && category.title}</div>;
        })();
      },
    },
    {
      key: 'lang',
      title: CONTENT_LABELS.LANGUAGE,
      dataIndex: 'lang',
      // width: '15%',
    },
    {
      key: 'created_at',
      title: CONTENT_LABELS.CREATED_AT,
      dataIndex: 'created_at',
      // width: '15%',
      render: (created_at) => {
        return (function Actions() {
          return (
            <div className="created_at">
              {created_at && moment(created_at).format('Do MMMM YYYY')}
            </div>
          );
        })();
      },
    },
    {
      key: '_id',
      title: CONTENT_LABELS.ACTIONS,
      dataIndex: '_id',
      // width: '10%',
      align: 'center',
      render: (id) => {
        return (function Actions() {
          return (
            <div className="actions-btn">
              <Button
                shape="circle"
                className="edit-btn"
                onClick={() => {
                  handleModeType({
                    activeMode: MODE_TYPES.VIEW,
                    recordId: id,
                  });
                }}
              >
                <EyeOutlined type="primary" className="preview-icon" />
              </Button>
              <Button
                shape="circle"
                className="edit-btn"
                onClick={() => {
                  handleModeType({
                    activeMode: MODE_TYPES.EDIT,
                    recordId: id,
                  });
                }}
              >
                <FormOutlined type="primary" className="edit-icon" />
              </Button>
              <Popconfirm
                title="Sure to delete?"
                onConfirm={() => deleteWatch({ id })}
                disabled={deletingWatchId === id}
              >
                <Button
                  shape="circle"
                  className="delete-btn"
                  disabled={deletingWatchId === id}
                >
                  {deletingWatchId === id ? (
                    <Spin indicator={<LoadingOutlined spin />} />
                  ) : (
                    <DeleteOutlined type="primary" className="delete-icon" />
                  )}
                </Button>
              </Popconfirm>
            </div>
          );
        })();
      },
    },
  ];



  const handleModeType = ({ activeMode, recordId }) => {
    setRecordId(recordId || '');
    setActiveMode(activeMode);
  };

  const handleMusicCallback = () => {
    fetchMusicContentList();
    setRecordId('');
  };

  const closeActiveModeModal = () => {
    setActiveMode('');
  };

  const deleteWatch = ({ id }) => {
    setDeletingWatchId(id);
    const url = URLS_V2?.MUSIC_CONTENT?.DELETE_MUSIC.replace('#ID#', id);
    httpDelete(url)
      .then((res) => {
        if (res.status === 200) {
          // updateFirebaseGlobalAppSettings(
          //   GLOBAL_APP_SETTINGS_KEYS.watch_content
          // );
          showSuccessMsg('Content deleted successfully!');
          fetchMusicContentList();
        } else if (res?.status && API_V2_ERROR_CODES.includes(res?.status)) {
          showErrorMsg(res?.message);
        }
        setDeletingWatchId('');
      })
      .catch((err) => {
        showErrorMsg(err);
        setDeletingWatchId('');
      });
  };



  return (
    <MusicContentBox>
    <div className="header">
    <Breadcrumb>
      <Breadcrumb.Item>Content</Breadcrumb.Item>
      <Breadcrumb.Item>Music</Breadcrumb.Item>
      <Breadcrumb.Item>Content</Breadcrumb.Item>
    </Breadcrumb>
    <div className="claim-table-header">
      <h3 className="claim-table-title"> Content</h3>
      <Button
        type="primary"
        onClick={() => {
          handleModeType({ activeMode: MODE_TYPES.ADD });
        }}
      >
        Add New
      </Button>
    </div>
  </div>
  <div className="main-table-body">
        <Table
          loading={{
            indicator: <Loader>Fetching...</Loader>,
            spinning: isFetchingMusicList,
          }}
          columns={columns}
          dataSource={musicList || []}
          size="small"
          pagination={false}
        />
      </div>  
      <MusicContentModal
        activeMode={activeMode}
        closeModal={closeActiveModeModal}
        callback={handleMusicCallback}
        contentId={recordId}
      />
  </MusicContentBox>
  )
}

export default MusicContent


const CONTENT_LABELS = {
  SR_NO: 'Sr. No.',
  TITLE: 'Title',
  CATEGORY: 'Category',
  LANGUAGE: 'Language',
  CREATED_AT: 'Created At',
  ACTIONS: 'Actions',
};