import React, { useEffect, useRef, useState } from 'react';
import mapboxgl from '!mapbox-gl'; // eslint-disable-line import/no-webpack-loader-syntax
import MapboxDraw from '@mapbox/mapbox-gl-draw';
import '@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw.css';
import { v4 as uuidv4 } from 'uuid';
import {
  MapViewBox,
  LocationSearchMapViewBox,
  SelectedPlacesMapViewBox,
} from './_map-view.styled';
import { Col, Form, Input, Row, Tag, Modal, Button } from 'antd';
import { CloseOutlined } from '@ant-design/icons';
import * as turf from '@turf/turf';
import { uuid } from 'uuidv4';

mapboxgl.accessToken = 'pk.eyJ1IjoiYmluYXJ5YWRtaW4iLCJhIjoiY2t6Mmw4ODNhMDFsMjJ2cWYweTFyb2ZuZyJ9.iBSQIqW6qM_KMV9S_ufb-w';

const ENUMS = {
  IDLE: "IDLE",
  NO_DATA: "NO_DATA",
  DATA_PRESENT: "DATA_PRESENT"
}

const MapView = ({ selectedOpt, setSelectedOpt, editModeOff }) => {
  const [searchValue, setSearchValue] = useState('');
  const [regionData, setRegionData] = useState([]);
  const [center, setCenter] = useState([55.1848041, 25.1416843]);
  const [showDropdown, setShowDropdown] = useState(false);
  const [isFetchingRegion, setIsFetchingRegion] = useState(false);
  const [noDataFound, setNoDataFound] = useState(ENUMS.IDLE)
  const [geoJson, setGeoJson] = useState({})
  const [boundingBox, setBoundingBox] = useState([])
  const [customLocationName, setCustomLocationName] = useState('')
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [locationConfirm, setLocationConfirm] = useState(0)
  const [centerCordinate, setCenterCordinate] = useState([])
  const [modalForm] = Form.useForm();

  let map = useRef(null);
  let draw = useRef(null)

  //========== Ajay's Code =========

  const handleDrawCreate = (event) => {
    const newFeature = event.features[0];
    const geoJsonData = newFeature.geometry;
    const bbox = turf.bbox(geoJsonData);
    const updatedJson = {...geoJsonData,coordinates:[geoJsonData.coordinates?.[0].map((coord) => [
      parseFloat(coord[1]),
      parseFloat(coord[0]),
    ])]} 
    setGeoJson(updatedJson);
    setBoundingBox(bbox)
    const coordinates = geoJsonData.coordinates[0];
    const avgLatitude = coordinates.reduce((sum, coord) => sum + coord[1], 0) / coordinates.length;
    const avgLongitude = coordinates.reduce((sum, coord) => sum + coord[0], 0) / coordinates.length;
    const centerCoordinates = [avgLongitude, avgLatitude];
    setCenterCordinate(centerCoordinates)
    setIsModalOpen(true)
  };

  useEffect(() => {
    if (customLocationName.length > 0) {
      setSelectedOpt([...selectedOpt, { addresstype: "custom_address", boundingbox: boundingBox, display_name: customLocationName, geojson: geoJson, place_id: uuid(), color: getRandomColor(), lat: centerCordinate[0], lon: centerCordinate[1] }])
      setCustomLocationName("")
      setIsModalOpen(false)
    }

  }, [locationConfirm])


  //========== Ajay's Code =========


  useEffect(() => {
    const timer = setTimeout(() => {
      if (searchValue !== '') {
        handleApiCall();
      }
    }, 500);
    return () => clearTimeout(timer);
  }, [searchValue]);

  const handleApiCall = async () => {
    setIsFetchingRegion(true);
    try {
      const res = await fetch(
        `https://nominatim.openstreetmap.org/search.php?q=${searchValue},Dubai,UAE&polygon_geojson=1&format=json`
      );
      const data = await res.json();
      let filterGeoJsonType = data.filter((data) => {
        return (
          data.geojson.type === 'MultiPolygon' ||
          data.geojson.type === 'Polygon'
        );
      }).map((item) => {
        return {
          ...item,
          color: getRandomColor(),
          geojson: item.geojson.type === "MultiPolygon" ? { ...item?.geojson, type: "Polygon", coordinates: item?.geojson?.coordinates[0] } : {
            ...item?.geojson
          }
        }
      });
      setRegionData(filterGeoJsonType);
      setNoDataFound(filterGeoJsonType.length ? ENUMS.DATA_PRESENT : ENUMS.NO_DATA)

    } catch (error) {
      setRegionData([]);
    } finally {
      setIsFetchingRegion(false);
    }
  };

  const getRandomColor = () => {
    const letters = '0123456789ABCDEF';
    let color = '#';
    for (let i = 0; i < 6; i++) {
      color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
  }

  useEffect(() => {

    const transformer = (data) => {
      let coordinates;
      if (data?.geojson?.type === 'MultiPolygon') {
        coordinates = data?.geojson?.coordinates?.[0]?.[0]?.map((coord) => [
          parseFloat(coord[1]),
          parseFloat(coord[0]),
        ]);
      } else {
        coordinates = data?.geojson?.coordinates?.[0]?.map((coord) => [
          parseFloat(coord[1]),
          parseFloat(coord[0]),
        ]);
      }

      const workingData = {
        geoJson: {
          type: 'geojson',
          data: {
            type: 'Feature',
            geometry: {
              type: 'Polygon',
              coordinates: [coordinates],
            },
          },
        },
        color: data?.color
      }

      return workingData;
    };

    map.current = new mapboxgl.Map({
      container: 'map', // container ID
      style: 'mapbox://styles/binaryadmin/ckzco0u9b004314rwv3tzkcn8', // style URL
      center: center, // starting position
      zoom: 10, // starting zoom
    });

    if (map.current) {
      map.current.addControl(new mapboxgl.NavigationControl(), 'top-right');
      map.current.addControl(new mapboxgl.FullscreenControl());
    }
    if (selectedOpt?.length) {
      selectedOpt?.forEach((item) => {
        const transformedData = transformer(item);
        const id = uuidv4();
        map.current.on('load', () => {
          // data source containing GeoJSON data.
          map.current.addSource(id, transformedData.geoJson);

          //new layer to visualize the polygon.
          map.current.addLayer({
            id: id,
            type: 'fill',
            source: id, // reference to data source
            layout: {},
            paint: {
              'fill-color': item?.color,
              'fill-opacity': 0.5,
            },
          });
          map.current.addLayer({
            id: `${id}-outline`,
            type: 'line',
            source: id,
            layout: {},
            paint: {
              'line-color': item?.color,
              'line-width': 1,
            },
          });
        });
      });
    }

    // ========== Ajay's Code  ============

    if (!editModeOff) {
      // Initialize drawing tools (Mapbox Draw)
      draw.current = new MapboxDraw({
        displayControlsDefault: false,
        controls: {
          polygon: true,
        },
        defaultMode: 'draw_polygon'
      });

      map.current.addControl(draw.current);

      // Handle drawing events (e.g., onDrawCreate, onDrawUpdate)
      map.current.on('draw.create', handleDrawCreate);
      map.current.on('draw.update');

    }


    // ========== Ajay's Code  ============

    // Cleanup function to remove the map when the component is unmounted
    return () => {
      if (map.current) {
        map?.current?.remove();
      }
    };

    //eslint-disable-next-line
  }, [selectedOpt, center,editModeOff]);

  const handleSearch = (e) => {
    setSearchValue(e.target.value);
  };

  const handleSelect = (data) => {
    const placeIdArrays = selectedOpt.map((item) => item.place_id)
    if (!placeIdArrays.includes(data.place_id)) {

      const updatedData ={
        ...data,
        geojson:{
          ...data.geojson,
          coordinates:data?.geojson?.type === 'MultiPolygon' ? [[data?.geojson?.coordinates?.[0]?.[0]?.map((coord)=>[
            parseFloat(coord[1]),
            parseFloat(coord[0]),
          ])]]:[data?.geojson?.coordinates?.[0].map((coord)=>[
            parseFloat(coord[1]),
            parseFloat(coord[0]),
          ])]
        }
      }

      setSelectedOpt((prev) => [...prev, updatedData]);
      setCenter((prev) => [data?.lon || 55.1848041, data?.lat || 25.1416843]);
    }


    // =============== Ajay's Code ================

    // Additional logic (e.g., center the map on the selected place)
    setCenter([data?.lon || 55.1848041, data?.lat || 25.1416843]);

    // =============== Ajay's Code ================
  };

  const removeLocation = (data) => {
    const filtered = selectedOpt.filter((el) => el.place_id !== data?.place_id);
    setSelectedOpt(filtered);
  };

  const delayedBlur = () => {
    setTimeout(() => {
      if (searchValue === '') {
        setRegionData([]);
      }
      setShowDropdown(false);
    }, 400);
  };


  const handleModalSubmit = () => {
    setLocationConfirm(locationConfirm + 1)
    setIsModalOpen(false)
    modalForm.resetFields()
  }


  return (
    <MapViewBox>
      <Row gutter={16}>
        <Col className="gutter-row" xs={24} sm={24}>
          <LocationSearchMapViewBox>
            <Form.Item name="current_location" label="Current Location">
              <Input
                type="text"
                value={searchValue}
                onChange={handleSearch}
                placeholder="Search Places..."
                className="location-search-input"
                onFocus={() => setShowDropdown(true)}
                onBlur={delayedBlur}
                disabled={editModeOff}
              />
              <div
                className="close-outline-icon"
                onClick={() => {
                  setSearchValue('');
                }}
              >
                <CloseOutlined />
              </div>
              {showDropdown && (
                <div
                  className={`autocomplete-dropdown-container ${regionData.length > 0 ? 'blue-border' : ''
                    }`}
                >
                  {isFetchingRegion && (
                    <div
                      className="suggestion-item"
                      style={{
                        backgroundColor: '#ffffff',
                        cursor: 'pointer',
                        padding: '4px 10px',
                      }}
                    >
                      Loading...
                    </div>
                  )}
                  {noDataFound === ENUMS.DATA_PRESENT && !isFetchingRegion ? (
                    regionData?.map((item, index) => {
                      return (
                        <div
                          key={index}
                          className="suggestion-item"
                          style={{
                            backgroundColor: '#ffffff',
                            cursor: 'pointer',
                            padding: '4px 10px',
                          }}
                          onClick={() => handleSelect(item)}
                        >
                          <span>{item?.display_name}</span>
                        </div>
                      );
                    })
                  ) : ""}
                  {noDataFound === ENUMS.NO_DATA && !isFetchingRegion ? (
                    <div
                      className="suggestion-item"
                      style={{
                        backgroundColor: '#ffffff',
                        cursor: 'pointer',
                        padding: '4px 10px',
                      }}
                    > No Data found (Try searching the full name of the location) </div>
                  ) : ""}
                </div>
              )}
            </Form.Item>
          </LocationSearchMapViewBox>
          <SelectedPlacesMapViewBox>
            <Row gutter={16}>
              {selectedOpt?.map((data) => (
                <>
                  <Col className="gutter-row" sm={8}>
                    <div key={data.place_id} className="selected-places-box">
                      <Tag
                        closable={!editModeOff}
                        color="green"
                        onClose={() => removeLocation(data)}
                      >
                        <p>{data?.display_name}</p>
                      </Tag>
                    </div>
                  </Col>
                </>
              ))}
            </Row>
          </SelectedPlacesMapViewBox>
        </Col>
      </Row>

      <div id="map" style={{ height: '500px' }}></div>
      <Modal title="Location"
        visible={isModalOpen}
        footer={null}
        closable={false}
        maskClosable={false}
      >

        <Form
          layout='vertical'
          onFinish={handleModalSubmit}
          form={modalForm}
        >
          <Form.Item
            name="Enter Location"
            label="Enter Location Name"
            rules={[
              {
                required: true,
                message: 'Please enter location name',
              },
            ]}>
            <Input value={customLocationName} onChange={(e) => setCustomLocationName(e.target.value)}>
            </Input>
          </Form.Item>
          <div style={{ marginTop: "30px", display: "flex", justifyContent: "flex-end" }}>
            <Form.Item>
              <Button
                type="primary" htmlType="submit"
              >
                Save
              </Button>
            </Form.Item>
          </div>

        </Form>

      </Modal>

    </MapViewBox>
  );
};

export default MapView;
