import React from 'react';
import { bool, object, func } from 'prop-types';
import { Row, Col, Collapse, Skeleton, Progress, Slider } from 'antd';
import { formatDate } from 'utils';
import { CollapseBox } from './_device-info.styled';
import { AudioMutedOutlined } from '@ant-design/icons';

const { Panel } = Collapse;

const DeviceLocation = (props) => {
  const { deviceDetailsFB, isFetching, handleDeviceCommands } = props;

  const renderLableColumnRows = (
    label,
    value,
    isProgressBar,
    rowGutter = 18,
    lableColSpacing = 6,
    valueColSpacing = 18
  ) => {
    return (
      <Row gutter={rowGutter} style={{ margin: '15px' }}>
        <Col className="gutter-row" xs={lableColSpacing}>
          <strong>{label}</strong>
        </Col>
        <Col className="gutter-row" xs={valueColSpacing}>
          <Skeleton
            loading={isFetching}
            active
            paragraph={{ rows: 1, width: '100%' }}
            title={false}
          >
            {isProgressBar ? <Progress percent={value} /> : value}
          </Skeleton>
        </Col>
      </Row>
    );
  };

  const onChangeBrightNess = (value) => {
    const requestData = {
      brightness_level: value,
    };
    handleDeviceCommands(requestData);
  };

  const onChangeVolume = (value) => {
    const requestData = {
      volume_level: value,
    };
    handleDeviceCommands(requestData);
  };

  return (
    <CollapseBox>
      <Collapse defaultActiveKey={['1']}>
        <Panel header="Device Info" key="1">
          {renderLableColumnRows(
            'App Language: ',
            deviceDetailsFB?.app_language
          )}
          {renderLableColumnRows(
            'Device Manager App Version: ',
            deviceDetailsFB?.device_manager_version || 0
          )}
          {renderLableColumnRows(
            'Battery: ',
            deviceDetailsFB?.battery_percent,
            true
          )}
          <Row gutter={18} style={{ margin: '15px' }}>
            <Col className="gutter-row" xs={6}>
              <strong>{'Brightness: '}</strong>
            </Col>
            <Col className="gutter-row" xs={16}>
              <Skeleton
                loading={isFetching}
                active
                paragraph={{ rows: 1, width: '100%' }}
                title={false}
              >
                <Slider
                  key={
                    typeof deviceDetailsFB?.brightness_level === 'number'
                      ? 'b_' + deviceDetailsFB?.brightness_level
                      : 'b_' + parseInt(deviceDetailsFB?.brightness_level)
                  }
                  min={0}
                  max={100}
                  onAfterChange={onChangeBrightNess}
                  defaultValue={
                    typeof deviceDetailsFB?.brightness_level === 'number'
                      ? deviceDetailsFB?.brightness_level
                      : parseInt(deviceDetailsFB?.brightness_level)
                  }
                />
              </Skeleton>
            </Col>
            <Col className="gutter-row" xs={2}>
              {!isFetching && deviceDetailsFB?.brightness_level + '%'}
            </Col>
          </Row>
          <Row gutter={18} style={{ margin: '15px' }}>
            <Col className="gutter-row" xs={6}>
              <strong>{'Volume: '}</strong>
            </Col>
            <Col className="gutter-row" xs={16}>
              <Skeleton
                loading={isFetching}
                active
                paragraph={{ rows: 1, width: '100%' }}
                title={false}
              >
                <Slider
                  key={
                    deviceDetailsFB.is_mute
                      ? 'v_' + 0
                      : typeof deviceDetailsFB?.volume_level === 'number'
                      ? 'v_' + deviceDetailsFB.volume_level
                      : 'v_' + parseInt(deviceDetailsFB?.volume_level)
                  }
                  min={1}
                  max={100}
                  onAfterChange={onChangeVolume}
                  disabled={deviceDetailsFB.is_mute}
                  defaultValue={
                    deviceDetailsFB.is_mute
                      ? 0
                      : typeof deviceDetailsFB?.volume_level === 'number'
                      ? deviceDetailsFB.volume_level
                      : parseInt(deviceDetailsFB?.volume_level)
                  }
                />
              </Skeleton>
            </Col>
            <Col className="gutter-row" xs={2}>
              {!isFetching &&
                (deviceDetailsFB.is_mute ? (
                  <>
                    <AudioMutedOutlined
                      style={{
                        fontSize: '14px',
                        color: '#ff4d4f',
                        marginRight: '0.5rem',
                      }}
                    />
                  </>
                ) : (
                  deviceDetailsFB?.volume_level + '%'
                ))}
            </Col>
          </Row>
          {renderLableColumnRows(
            'Last Charged At: ',
            deviceDetailsFB?.lastChargedAt &&
              formatDate(deviceDetailsFB?.lastChargedAt, 'Do MMMM YYYY hh:mm A')
          )}
          {/* {renderLableColumnRows('Ride Status: ', deviceDetailsFB?.ride_status)} */}
          {renderLableColumnRows(
            'WiFi: ',
            deviceDetailsFB?.is_wifi_on
              ? deviceDetailsFB?.required_wifi_name
              : 'Off'
          )}
          {renderLableColumnRows('Sim Serial: ', deviceDetailsFB?.sim_serial)}
          {renderLableColumnRows(
            'Last Updated: ',
            deviceDetailsFB?.updated_at &&
              formatDate(deviceDetailsFB?.updated_at, 'Do MMMM YYYY hh:mm:ss A')
          )}
        </Panel>
      </Collapse>
    </CollapseBox>
  );
};

DeviceLocation.propTypes = {
  deviceDetailsFB: object,
  handleDeviceCommands: func,
  isFetching: bool,
};

export default DeviceLocation;
