import React, { useState, useEffect } from 'react';
import { Button, Breadcrumb, Table, Popconfirm, Spin } from 'antd';
import {
  httpDelete,
  httpGet,
  URLS_V2,
  showErrorMsg,
  showSuccessMsg,
} from 'utils';
import { DiscoverBox, Loader } from './_discover.styled';
import {
  EditOutlined,
  DeleteOutlined,
  LoadingOutlined,
} from '@ant-design/icons';
import moment from 'moment';
import DiscoverModel from 'components/v2/discover-modal';
import { MODE_TYPES } from 'constants/common';

const DiscoverList = () => {
  const [isFetchingDiscoverList, setIsFetchingDiscoverList] = useState(false);
  const [deletingDiscoverId, setDeletingDiscoverId] = useState('');
  const [discoverList, setDiscoverList] = useState([]);
  const [activeMode, setActiveMode] = useState();
  const [discoverId, setDiscoverId] = useState();
  const [orderPagination, setOrderPagination] = useState({
    skip: 0,
    limit: 10,
  });
  const [totalDiscover, setTotalDiscover] = useState(0);

  useEffect(() => {
    fetchDiscoverList(orderPagination);
  }, []);

  const fetchDiscoverList = (orderPaginationParams) => {
    setIsFetchingDiscoverList(true);
    const queryParams = new URLSearchParams();
    queryParams.append('skip', orderPaginationParams.skip);
    queryParams.append('limit', orderPaginationParams.limit);
    const url = `${
      URLS_V2?.DISCOVER?.DISCOVERS_LIST
    }?${queryParams.toString()}`;
    httpGet(url)
      .then((res) => {
        if (res?.status === 200) {
          if (res?.data && res?.data.length > 0) {
            const readingListData = res?.data.map((data) => {
              return {
                ...data,
                key: data?._id,
              };
            });
            setDiscoverList(readingListData || []);
            setTotalDiscover(res?.total || 0);
          }
        }
        setIsFetchingDiscoverList(false);
      })
      .catch(() => {
        setIsFetchingDiscoverList(false);
      });
  };

  const handlePaginationChange = (pagination, filters, sorter) => {
    const { current, pageSize } = pagination;
    const tempSearchPagination = {
      ...orderPagination,
      skip: current * pageSize - pageSize,
      limit: pageSize,
    };
    setOrderPagination(tempSearchPagination);
    fetchDiscoverList(tempSearchPagination);
  };

  const handleModeType = ({ activeMode, discoverId }) => {
    setDiscoverId(discoverId || '');
    setActiveMode(activeMode);
  };

  const closeActiveModeModal = () => {
    setActiveMode('');
  };

  const handleDiscoverCallback = () => {
    fetchDiscoverList(orderPagination);
    setDiscoverId('');
  };

  const deleteReading = ({ id }) => {
    setDeletingDiscoverId(id);
    const url = URLS_V2?.DISCOVER?.DELETE_DISCOVER.replace('#ID#', id);
    httpDelete(url)
      .then((res) => {
        if (res.status === 200) {
          //  updateFirebaseGlobalAppSettings(GLOBAL_APP_SETTINGS_KEYS.discover);
          showSuccessMsg('Discover deleted successfully!');
          fetchDiscoverList(orderPagination);
        } else if (res?.error) {
          showErrorMsg(res?.error);
        }
        setDeletingDiscoverId('');
      })
      .catch((err) => {
        showErrorMsg(err);
        setDeletingDiscoverId('');
      });
  };

  const columns = [
    {
      title: DISCOVER_LABELS.SR_NO,
      width: '4%',
      render: (value, item, index) => index + 1,
    },
    {
      key: 'art_work',
      title: DISCOVER_LABELS.ART_WORK,
      dataIndex: 'art_work',
      width: '10%',

      render: (art_work) => {
        return (function Actions() {
          return (
            <div className="logo">
              <img src={art_work} alt="art_work" width="50px" />
            </div>
          );
        })();
      },
    },
    {
      key: 'name',
      title: DISCOVER_LABELS.NAME,
      dataIndex: 'name',
      width: '15%',
    },
    {
      key: 'ar_name',
      title: DISCOVER_LABELS.AR_NAME,
      dataIndex: 'ar_name',
      width: '15%',
    },
    {
      key: 'description',
      title: DISCOVER_LABELS.DESCRIPTION,
      dataIndex: 'description',
      width: '15%',
      render: (description) => {
        return (function Actions() {
          return <div style={{ wordBreak: 'break-all' }}>{description}</div>;
        })();
      },
    },
    {
      key: 'ar_description',
      title: DISCOVER_LABELS.AR_DESCRIPTION,
      dataIndex: 'ar_description',
      width: '15%',
      render: (ar_description) => {
        return (function Actions() {
          return (
            <div style={{ wordBreak: 'break-all' }}> {ar_description} </div>
          );
        })();
      },
    },
    {
      key: 'created_at',
      title: DISCOVER_LABELS.CREATED_AT,
      dataIndex: 'created_at',
      width: '15%',
      render: (created_at) => {
        return (function Actions() {
          return (
            <div className="created_at">
              {created_at && moment(created_at).format('Do MMMM YYYY')}
            </div>
          );
        })();
      },
    },
    {
      key: '_id',
      title: DISCOVER_LABELS.ACTIONS,
      dataIndex: '_id',
      width: '10%',
      align: 'center',
      render: (id) => {
        return (function Actions() {
          return (
            <div className="actions-btn">
              <Button
                shape="circle"
                className="edit-btn"
                onClick={() => {
                  handleModeType({
                    activeMode: MODE_TYPES.EDIT,
                    discoverId: id,
                  });
                }}
              >
                <EditOutlined type="primary" className="edit-icon" />
              </Button>
              <Popconfirm
                title="Sure to delete?"
                onConfirm={() => deleteReading({ id })}
                disabled={deletingDiscoverId === id}
              >
                <Button
                  shape="circle"
                  className="delete-btn"
                  disabled={deletingDiscoverId === id}
                >
                  {deletingDiscoverId === id ? (
                    <Spin indicator={<LoadingOutlined spin />} />
                  ) : (
                    <DeleteOutlined type="primary" className="delete-icon" />
                  )}
                </Button>
              </Popconfirm>
            </div>
          );
        })();
      },
    },
  ];

  return (
    <DiscoverBox>
      <div className="header">
        <Breadcrumb>
          <Breadcrumb.Item>Content </Breadcrumb.Item>
          <Breadcrumb.Item>Discover </Breadcrumb.Item>
        </Breadcrumb>
        <div className="claim-table-header">
          <h3 className="claim-table-title"> Discover </h3>
          <Button
            type="primary"
            onClick={() => {
              handleModeType({ activeMode: MODE_TYPES.ADD });
            }}
          >
            Add New
          </Button>
        </div>
      </div>
      <div className="main-table-body">
        <Table
          loading={{
            indicator: <Loader>Fetching...</Loader>,
            spinning: isFetchingDiscoverList,
          }}
          columns={columns}
          dataSource={discoverList || []}
          size="small"
          onChange={handlePaginationChange}
          pagination={{
            defaultPageSize: 10,
            total: totalDiscover,
          }}
          scroll={{x:900}}
        />
      </div>
      <DiscoverModel
        activeMode={activeMode}
        closeModal={closeActiveModeModal}
        callback={handleDiscoverCallback}
        discoverId={discoverId}
      />
    </DiscoverBox>
  );
};

export default DiscoverList;

const DISCOVER_LABELS = {
  SR_NO: 'Sr. No.',
  ART_WORK: 'Art Work',
  NAME: 'Name (English)',
  AR_NAME: 'Name (Arabic)',
  DESCRIPTION: 'Description (English)',
  AR_DESCRIPTION: 'Description (Arabic)',
  CREATED_AT: 'Created At',
  ACTIONS: 'Actions',
};
