import React from 'react';
import { bool, object } from 'prop-types';
import { Row, Col, Collapse, Skeleton, Progress } from 'antd';
// import { formatDate } from 'utils';
import { CollapseBox } from './_binary-os-info.styled';

const { Panel } = Collapse;

const BinaryOsInfo = (props) => {
  const { deviceDetailsFB, isFetching } = props;

  const renderLableColumnRows = (
    label,
    value,
    isProgressBar,
    rowGutter = 18,
    lableColSpacing = 6,
    valueColSpacing = 18
  ) => {
    return (
      <Row gutter={rowGutter} style={{ margin: '15px' }}>
        <Col className="gutter-row" xs={lableColSpacing}>
          <strong>{label}</strong>
        </Col>
        <Col className="gutter-row" xs={valueColSpacing}>
          <Skeleton
            loading={isFetching}
            active
            paragraph={{ rows: 1, width: '100%' }}
            title={false}
          >
            {isProgressBar ? <Progress percent={value} /> : value}
          </Skeleton>
        </Col>
      </Row>
    );
  };

  return (
    <CollapseBox>
      <Collapse defaultActiveKey={['1']}>
        <Panel header="Binary OS Info" key="1">
          {renderLableColumnRows(
            'App Language: ',
            deviceDetailsFB?.app_language
          )}
          {renderLableColumnRows(
            'Binary OS App Version: ',
            deviceDetailsFB?.binary_os_app_version || 0
          )}{' '}
          {/* {renderLableColumnRows('Ride Id: ', deviceDetailsFB?.ride_id)}
          {renderLableColumnRows('Ride Status: ', deviceDetailsFB?.ride_status)} */}
          {renderLableColumnRows(
            'WiFi: ',
            deviceDetailsFB?.is_wifi_on
              ? deviceDetailsFB?.required_wifi_name
              : 'Off'
          )}
          {renderLableColumnRows(
            'Sleep: ',
            deviceDetailsFB?.is_sleep ? 'True' : 'False'
          )}
        </Panel>
      </Collapse>
    </CollapseBox>
  );
};

BinaryOsInfo.propTypes = {
  deviceDetailsFB: object,
  isFetching: bool,
};

export default BinaryOsInfo;
