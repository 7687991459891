import React, { useState, useEffect } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { Table, Button, Form, Input, DatePicker, Row, Col, Select } from 'antd';
import { currentPage, formatDate, httpGet, showSuccessMsg, URLS } from 'utils';
import { CopyOutlined, FormOutlined } from '@ant-design/icons';
import { CampaignsBox, Loader } from './_campaigns.styled';
import { debounce } from 'lodash';

const { RangePicker } = DatePicker;
const { Option } = Select;

const campaignStatusSelectOptions = (
  <>
    <Option key={'active'} value={'active'}>
      {'Active'}
    </Option>
    <Option key={'inactive'} value={'inactive'}>
      {'Inactive'}
    </Option>
  </>
);

const Campaigns = () => {
  const [isFetching, setIsFetching] = useState(false);
  const [campaignsList, setCampaignsList] = useState([]);
  const [totalCampaigns, setTotalCampaigns] = useState(0);
  const [searchPagination, setSearchPagination] = useState({
    skip: 0,
    limit: 100,
    order: 1,
    orderBy: 'campaignName',
    searchValue: {},
  });
  const history = useHistory();

  useEffect(() => {
    fetchCampaignsList(searchPagination);
  }, [searchPagination.searchValue]);

  const fetchCampaignsList = (searchPaginationParams) => {
    setIsFetching(true);
    const queryParams = new URLSearchParams();
    queryParams.append('skip', searchPaginationParams.skip);
    queryParams.append('limit', searchPaginationParams.limit);
    queryParams.append('order', searchPaginationParams.order);
    queryParams.append('orderBy', searchPaginationParams.orderBy);
    if (
      searchPaginationParams.searchValue &&
      Object.keys(searchPaginationParams.searchValue).length >= 1
    ) {
      Object.keys(searchPaginationParams.searchValue).map((key) => {
        queryParams.append(key, searchPaginationParams.searchValue[key]);
      });
    }
    const url = `${URLS?.CAMPAIGN?.CAMPAIGNS_LIST}?${queryParams.toString()}`;
    httpGet(url)
      .then((res) => {
        if (res?.status === 200) {
          const campaignListData =
            (res?.data &&
              res?.data.length > 0 &&
              res?.data.map((data) => {
                return {
                  ...data,
                  key: data?._id,
                  campaignName: data?.campaign_name || '',
                  companyName: data?.company_id?.company_name || '',
                  industryType: data?.industry_type || '',
                  campaignType: data?.campaign_type || '',
                  isActive: data?.is_active || '',
                };
              })) ||
            [];
          setCampaignsList(campaignListData || []);
          setTotalCampaigns(res?.total || 0);
        }
        setIsFetching(false);
      })
      .catch(() => {
        setIsFetching(false);
      });
  };

  const columns = [
    {
      title: CAMPAIGN_LABELS.SR_NO,
      width: 80,
      render: (value, item, index) =>
        (currentPage(searchPagination.skip, searchPagination.limit) - 1) *
          searchPagination.limit +
        index +
        1,
    },
    {
      key: 'campaignName',
      title: CAMPAIGN_LABELS.CAMPAIGN_NAME,
      dataIndex: 'campaignName',
      sorter: true,
      defaultSortOrder: 'ascend',
    },
    {
      key: 'companyName',
      title: CAMPAIGN_LABELS.COMPANY_NAME,
      dataIndex: 'companyName',
    },
    // {
    //   key: 'industryType',
    //   title: CAMPAIGN_LABELS.INDUSTRY_TYPE,
    //   dataIndex: 'industryType',
    //   sorter: true,
    // },
    {
      key: 'campaignType',
      title: CAMPAIGN_LABELS.CAMPAIGN_TYPE,
      dataIndex: 'campaignType',
    },
    {
      key: 'isActive',
      title: CAMPAIGN_LABELS.ACTIVE_INACTIVE,
      dataIndex: 'isActive',
      render: (isActive) => {
        return (function Actions() {
          return <div>{isActive ? <p>Active</p> : <p>Inactive</p>}</div>;
        })();
      },
    },
    {
      key: 'campaign_start_date',
      title: CAMPAIGN_LABELS.START_DATE,
      dataIndex: 'campaign_start_date',
      render: (campaign_start_date) => {
        return (function Actions() {
          return (
            <div className="campaign_start_date">
              {campaign_start_date &&
                formatDate(campaign_start_date, 'Do MMMM YYYY')}
            </div>
          );
        })();
      },
    },
    {
      key: 'campaign_end_date',
      title: CAMPAIGN_LABELS.END_DATE,
      dataIndex: 'campaign_end_date',
      render: (campaign_end_date) => {
        return (function Actions() {
          return (
            <div className="campaign_end_date">
              {campaign_end_date &&
                formatDate(campaign_end_date, 'Do MMMM YYYY')}
            </div>
          );
        })();
      },
    },
    {
      key: '_id',
      title: CAMPAIGN_LABELS.ACTIONS,
      dataIndex: '_id',
      render: (id) => {
        return (function Actions() {
          return (
            <div className="actions-btn">
              <Link to={`/campaigns/edit/${id}`}>
                <Button shape="circle" className="edit-btn">
                  <FormOutlined type="primary" className="edit-icon" />
                </Button>
              </Link>
              <Button
                shape="circle"
                className="m-l-5 edit-btn"
                onClick={() => createDuplicateCampaign(id)}
              >
                <CopyOutlined type="primary" className="edit-icon" />
              </Button>
            </div>
          );
        })();
      },
    },
  ];

  const handleTextSearch = (e) => {
    if (e.target.value) {
      setSearchPagination({
        ...searchPagination,
        searchValue: {
          ...searchPagination.searchValue,
          [e.target.name]: e.target.value,
        },
      });
    } else if (searchPagination.searchValue[e.target.name] !== undefined) {
      delete searchPagination.searchValue[e.target.name];
      setSearchPagination({
        ...searchPagination,
        searchValue: { ...searchPagination.searchValue },
      });
    }
  };

  const handleSelectSearch = (selectedValue) => {
    if (selectedValue) {
      setSearchPagination({
        ...searchPagination,
        searchValue: {
          ...searchPagination.searchValue,
          active: selectedValue === 'active' ? true : false,
        },
      });
    } else if (searchPagination.searchValue['active'] !== undefined) {
      delete searchPagination.searchValue['active'];
      setSearchPagination({
        ...searchPagination,
        searchValue: { ...searchPagination.searchValue },
      });
    }
  };

  const handleDateRangeSearch = (value) => {
    if (value && value.length > 1) {
      setSearchPagination({
        ...searchPagination,
        searchValue: {
          ...searchPagination.searchValue,
          fromDate: formatDate(value[0], 'YYYY-MM-DD'),
          toDate: formatDate(value[1], 'YYYY-MM-DD'),
        },
      });
    } else if (
      searchPagination.searchValue['fromDate'] !== undefined &&
      searchPagination.searchValue['toDate'] !== undefined
    ) {
      delete searchPagination.searchValue['fromDate'];
      delete searchPagination.searchValue['toDate'];
      setSearchPagination({
        ...searchPagination,
        searchValue: { ...searchPagination.searchValue },
      });
    }
  };

  const handlePaginationChange = (pagination, filters, sorter) => {
    const { current, pageSize } = pagination;
    const tempSearchPagination = {
      ...searchPagination,
      skip: current * pageSize - pageSize,
      limit: pageSize,
      order: sorter.order === 'descend' ? -1 : 1,
      orderBy: sorter.field,
    };
    setSearchPagination(tempSearchPagination);
    fetchCampaignsList(tempSearchPagination);
  };

  const createDuplicateCampaign = (campaignId) => {
    setIsFetching(true);
    const queryParams = new URLSearchParams();
    queryParams.append('id', campaignId);
    const url = `${
      URLS?.CAMPAIGN?.DUPLICATE_CAMPAIGN
    }?${queryParams.toString()}`;
    httpGet(url)
      .then((res) => {
        if (res?.status === 200) {
          const originalCampaignId = campaignId;
          const newCreatedCampaignId = res.data._id;
          showSuccessMsg('Campaign cloned successfully!');
          history.push({
            pathname: `/campaigns/edit/${newCreatedCampaignId}`,
            state: { originalCampaignId: originalCampaignId },
          });
        }
        setIsFetching(false);
      })
      .catch(() => {
        setIsFetching(false);
      });
  };

  return (
    <CampaignsBox>
      <div className="table-header">
        <h3 className="table-title">Campaigns</h3>
      </div>
      <div className="while-bg-box">
        <Form className="main-filter-form">
          <Row gutter={[16, 16]}>
            <Col md={8}>
              <Form.Item name="campaignName" style={{ marginBottom: '0' }}>
                <Input
                  type="text"
                  name="campaignName"
                  onChange={debounce((event) => handleTextSearch(event))}
                  placeholder="Search Campaign Name / Industry Type..."
                />
              </Form.Item>
            </Col>
            <Col md={8}>
              <Form.Item name="companyName" style={{ marginBottom: '0' }}>
                <Input
                  type="text"
                  name="companyName"
                  onChange={debounce((event) => handleTextSearch(event))}
                  placeholder="Search Company Name..."
                />
              </Form.Item>
            </Col>
            <Col md={8}></Col>
            <Col md={8}>
              <Form.Item
                name="campaignStartEndTime"
                style={{ marginBottom: '0' }}
              >
                <RangePicker
                  name="campaignStartEndTime"
                  format="YYYY-MM-DD"
                  style={{ width: '100%' }}
                  placeholder={['Campaign Start Date', 'Campaign End Date']}
                  onChange={(campaignStartEndTime) =>
                    handleDateRangeSearch(campaignStartEndTime)
                  }
                />
              </Form.Item>
            </Col>
            <Col md={8}>
              <Form.Item name="campaignStatus" style={{ marginBottom: '0' }}>
                <Select
                  showSearch
                  allowClear
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    option.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                  placeholder="Select Campaign Status"
                  onChange={handleSelectSearch}
                >
                  {campaignStatusSelectOptions}
                </Select>
              </Form.Item>
            </Col>
            <Col md={8} align="end" justify="end">
              <div>
                {`Showing Campaigns: ${
                  campaignsList.length || 0
                } / Total Campaigns: ${totalCampaigns}`}
              </div>
            </Col>
          </Row>
        </Form>
      </div>
      <div className="main-table-body">
        <Table
          loading={{
            indicator: <Loader>Fetching...</Loader>,
            spinning: isFetching,
          }}
          onChange={handlePaginationChange}
          columns={columns}
          dataSource={campaignsList || []}
          size="small"
          pagination={{ defaultPageSize: 100, total: totalCampaigns }}
        />
      </div>
    </CampaignsBox>
  );
};

export default Campaigns;

const CAMPAIGN_LABELS = {
  SR_NO: 'Sr. No.',
  ACTIVE_INACTIVE: 'Active/Inactive',
  CAMPAIGN_NAME: 'Campaign Name',
  COMPANY_NAME: 'Company Name',
  INDUSTRY_TYPE: 'Industry Type',
  CAMPAIGN_TYPE: 'Campaign Type',
  START_DATE: 'Start Date',
  END_DATE: 'End Date',
  ACTIONS: 'Actions',
};
