import {
  Col,
  DatePicker,
  //  Form,
  Input,
  Row,
  Select,
  //   Space,
} from 'antd';
// import CompanyModal from 'components/company-modal';
import React, { useEffect, useState } from 'react';
// import AddEditCampaign from 'views/campaigns/add-edit-campaign';
// import {
//     AddEditCampaignBox
//   } from '../add-edit-campaign/';
import './ViewCampaign.css';
import LocationsMap from 'components/v2/locations-map';
import { useParams } from 'react-router-dom';
import { URLS_V2, httpGet } from 'utils';
import { uuid } from 'uuidv4';
import {
  QRCodeBox,
  QrCodeDownloadBox,
} from '../add-edit-campaign/_add-edit-campaign.styled';
import { DownloadOutlined } from '@ant-design/icons';
import Media from 'views/campaigns/add-edit-campaign/media';
import moment from 'moment';
const { RangePicker } = DatePicker;

const { Option } = Select;
const QRCode = require('qrcode.react');

const ViewCampaign = () => {
  const [mapOpen, setMapOpen] = useState(false);
  const [isLocationAddedOrRemoved, setIsLocationAddedOrRemoved] = useState(
    Math.random()
  );
  const [locations, setLocations] = useState([]);
  const [data, setData] = useState([]);
  const params = useParams();
  const [selectMediaVideo, setSelectMediaVideo] = useState('');
  // const [isMediaVideoUpdating, setIsMediaVideoUpdating] = useState(false);
  const [selectMediaImage, setSelectMediaImage] = useState('');
  const [rangePickerDate, setRangePickerDate] = useState([]);

  useEffect(() => {
    if (params?.id) {
      const url = `${URLS_V2?.CAMPAIGNS?.GET_SINGLE_CAMPAIGN.replace(
        '#CAMPAIGN_ID#',
        params?.id
      )}`;
      httpGet(url).then((res) => {
        if (res?.status === 200) {
          setData(res?.data);
          const geofenceDetails = res?.data?.geofence_Campaign?.map(
            (val, index) => {
              return {
                lat: val?.coordinates?.[0]?.lat,
                long: val?.coordinates?.[0]?.long,
                location_name: val?.geofence_title,
                radius: val?.radius,
                // _id: val?._id,
                identifier: uuid(),
                location_type: 'Current Location',
              };
            }
          );
          setLocations(geofenceDetails);
          setMapOpen(true);
          setSelectMediaVideo(res?.data?.media_video_url);
          setSelectMediaImage(res?.data?.media_image_url);

          let startDateConvert = new Date(res?.data?.campaign_start_date * 1000);
          let endDateConvert = new Date(res?.data?.campaign_end_date * 1000);
let startDate = moment(startDateConvert).format('MM/DD/YYYY');
let endDate = moment(endDateConvert).format('MM/DD/YYYY');

          // const startDate = moment.unix(res?.data?.campaign_start_date / 1000).format('MM/DD/YYYY');
          // const endDate = moment.unix(res?.data?.campaign_end_date / 1000).format('MM/DD/YYYY');
          setRangePickerDate([`${startDate}`, `${endDate}`]);
        }
      });
    }
  }, []);

  const downloadCampaignQR = () => {
    const canvas = document.getElementById('campaign-qr');
    const pngUrl = canvas
      .toDataURL('image/png')
      .replace('image/png', 'image/octet-stream');
    let downloadLink = document.createElement('a');
    downloadLink.href = pngUrl;
    downloadLink.download = 'campaign-qr.png';
    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
  };

  return (
    <div className="campaign-view">
      {/* <AddEditCampaign></AddEditCampaign> */}

      <Row gutter={16}>
        <Col className="gutter-row" xs={24} sm={12} md={12}>
          <Row gutter={16}></Row>
          <div style={{ display: 'flex' }}>
            <Col className="gutter-row" xs={24} sm={12}>
              <label onClick={() => setIsLocationAddedOrRemoved(Math.random())}>
                Campaign Name
              </label>
              <Input
                type="text"
                placeholder={data?.campaign_name || 'Campaign Name'}
                className="field-properties"
                disabled={true}
              />
            </Col>
            <Col className="gutter-row" xs={24} sm={12}>
              <label>Company</label>
              <Select
                placeholder="Company Name"
                style={{ width: '100%' }}
                className="field-properties"
                disabled={true}
              >
                <Option></Option>
              </Select>
            </Col>
          </div>

          <div style={{ display: 'flex' }}>
            <Col className="gutter-row" xs={24} sm={12}>
              <label>Industry Type</label>

              <Select
                placeholder={data?.industry_type || ''}
                style={{ width: '100%' }}
                className="field-properties"
                disabled={true}
              >
                <Option></Option>
              </Select>
            </Col>
            <Col className="gutter-row" xs={24} sm={12}>
              <label>Campaign Type</label>

              <Select
                placeholder={data?.campaign_type || 'Campaign Type'}
                style={{ width: '100%' }}
                className="field-properties"
                disabled={true}
              >
                <Option></Option>
              </Select>
            </Col>
          </div>
          <div>
            <Col className="gutter-row" xs={24} sm={24}>
              <label>Redirected URL</label>
              <Input
                type="text"
                placeholder={data?.redirect_url || 'Redirected URL'}
                className="field-properties"
                disabled={true}
              />
            </Col>
          </div>
        </Col>
        <Col className="gutter-row" xs={24} sm={12} md={6}>
          <>
            <QRCodeBox>
              <QRCode
                id="campaign-qr"
                size={200}
                value={window.location.origin + '/campaign-qr/' + params?.id}
              />
            </QRCodeBox>
          </>
          <QrCodeDownloadBox>
            <div>
              <a onClick={downloadCampaignQR}>
                <DownloadOutlined /> Download QR
              </a>
            </div>
          </QrCodeDownloadBox>
        </Col>
      </Row>

      {/* ============   Alloted Spots   ============== */}

      <Row gutter={16}>
        <Col className="gutter-row" xs={24} sm={12} md={12}>
          <div className="alloted-spots-main">
            <div className="alloted-spots-text"> Alloted Spots</div>

            <Col className="gutter-row" xs={24} sm={24}>
              {/* <Space direction="vertical" size={12}> */}
              <label>Campaign Start and End Date</label>
              <RangePicker
                // value={campaignStartEndDate}
                showTime={{ format: 'HH:mm' }}
                format="YYYY-MM-DD HH:mm"
                // onChange={(startEndTime) =>
                //   handleStartEndTime(startEndTime)
                // }
                style={{ width: '100%' }}
                className="field-properties"
                disabled={true}
                placeholder={rangePickerDate}
              />
              {/* </Space> */}
            </Col>
            <Col className="gutter-row" xs={24} sm={24}>
              <label>Duration (in seconds)</label>
              <Input
                type="text"
                className="field-properties"
                disabled={true}
                placeholder={data?.duration || 'Duration'}
              ></Input>
            </Col>

            <Col className="gutter-row" xs={24} sm={24}>
              <label>Maximum Slots</label>
              <Input
                type="text"
                className="field-properties"
                disabled={true}
                placeholder={data?.maximum_daily_slots || 'Maximum Slots'}
              ></Input>
            </Col>

            <Col className="gutter-row" xs={24} sm={24}>
              <label>Per ride frequency</label>
              <Input
                type="text"
                className="field-properties"
                disabled={true}
                placeholder={data?.per_ride_frequency || 'Frequency'}
              ></Input>
            </Col>
          </div>
        </Col>
      </Row>

      {/* ============== Media ================ */}
      <Row gutter={16}>
        <Col className="gutter-row" xs={24} sm={12} md={12}>
          <div className="media-main">
            <Media
              selectMediaVideo={selectMediaVideo}
              showVideoCancelBtn={false}
              isUploadDisable={true}
            ></Media>
          </div>
        </Col>
      </Row>
      {/* ============== WhereToTarget ============= */}

      <Row gutter={16}>
        <Col className="gutter-row" xs={24} sm={24} md={24}>
          <div className="alloted-spots-main">
            <div className="alloted-spots-text"> Alloted Spots</div>
            <Col className="gutter-row" xs={24} sm={12}>
              <label> Current Location</label>
              <Input
                type="text"
                className="field-properties"
                disabled={true}
              ></Input>
            </Col>
          </div>

          {/* {suggestions.map((suggestion, index) => {
                            const className = suggestion.active
                              ? 'suggestion-item--active'
                              : 'suggestion-item';
                            // inline style for demonstration purpose
                            const style = suggestion.active
                              ? {
                                  backgroundColor: '#1890ff33',
                                  cursor: 'pointer',
                                  padding: '4px 10px',
                                }
                              : {
                                  backgroundColor: '#ffffff',
                                  cursor: 'pointer',
                                  padding: '4px 10px',
                                };
                            return (
                              <div
                                key={index}
                                {...getSuggestionItemProps(suggestion, {
                                  className,
                                  style,
                                })}
                              >
                                <span>{suggestion.description}</span>
                              </div>
                            );
                          })} */}
          {mapOpen && (
            <LocationsMap
              locations={locations}
              setLocations={setLocations}
              isLocationAddedOrRemoved={isLocationAddedOrRemoved}
            />
          )}
        </Col>
      </Row>
    </div>
  );
};

export default ViewCampaign;
