import firebase from 'firebase';
import 'firebase/app';
import 'firebase/auth';
import 'firebase/storage';

const app = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
  databaseURL: process.env.REACT_APP_FIREBASE_REALTIME_DATABASE,
};

const fire = firebase.initializeApp(app);
firebase.analytics();

export const auth = fire.auth();
export const fireStoreDb = fire.firestore();
export const firebaseDb = fire.database('https://binaryos-prod-v2.firebaseio.com');
export const firebaseDbOSProd = fire.database(
  process.env.REACT_APP_FIREBASE_REALTIME_DATABASE_OS_PROD ||
    'https://binary-os-prod.firebaseio.com'
); // for V2 device location and device status DB connection
export const mainFirebase = firebase.firestore;
export const firebaseStorage = firebase.storage();
export default app;
