import React, { useState } from 'react';
import axios from 'axios';
import { message, Form, Button, Input, InputNumber, Upload, Spin } from 'antd';
import CommonBreadCrumb from 'components/common-breadcrumb';
import { func, object, string } from 'prop-types';
import { InboxOutlined } from '@ant-design/icons';
import { APK_FILE_SIZE } from 'constants/app-release-manage';
import {
  httpPost,
  httpGet,
  showErrorMsg,
  showSuccessMsg,
  URLS_V2,
} from 'utils';

const { TextArea } = Input;

const CreateNewUnisocRelease = (props) => {
  const { packageName, resetAllData, appCurrentVersion, renderCurrentVersion } =
    props;
  const breakCrumbList = [
    { label: 'Home', link: '/dashboard' },
    {
      label: 'Binary Device Release Manager',
      link: '/v2/binary-device-manager',
    },
    { label: 'Create New Device Release' },
  ];

  const [form] = Form.useForm();
  const [APKFile, setAPKFile] = useState();
  const [apkUploading, setAPKUploading] = useState(false);
  const [validateFieldsName, setValidateFieldsName] = useState([]);
  const [isFormSubmitting, setIsFormSubmitting] = useState(false);

  const handleValidateFieldNames = (name) => {
    const isFieldName = validateFieldsName.find(
      (fieldName) => fieldName === name
    );
    if (isFieldName) return 'onChange';
    return 'onBlur';
  };

  const handleCancelClick = () => {
    form.resetFields();
    resetAllData();
  };

  const onFinish = (values) => {
    if (!APKFile) {
      showErrorMsg('APK file is required!');
      return;
    }
    let url = URLS_V2?.APP_RELEASE_MANAGER?.CREATE_NEW_RELEASE;
    const submitFormData = {
      type: packageName,
      version_code: values.version_code,
      version_name: values.version_name,
      description: values.description,
      apk: APKFile,
      device_type: 'UNISOC',
    };
    setIsFormSubmitting(true);
    httpPost(url, submitFormData)
      .then((res) => {
        if (res.status === 200) {
          showSuccessMsg(`APK uploaded successfully!`);
        }
        form.resetFields();
        resetAllData();
        setIsFormSubmitting(false);
      })
      .catch((err) => {
        showErrorMsg(err);
        setIsFormSubmitting(false);
      });
  };

  const uploadDraggerProps = {
    beforeUpload: async (file) => {
      let isError = false;
      const isAPK = file.type === 'application/vnd.android.package-archive';
      const fileSize = file.size / 1024 > APK_FILE_SIZE.APK_FILE_SIZE;

      if (!isAPK) {
        isError = true;
        message.error(`${file.name} is not a valid APK file`);
      }

      if (fileSize) {
        isError = true;
        message.error(
          `${file.name} must be smaller then ${
            APK_FILE_SIZE.APK_FILE_SIZE * 1024
          } MB`
        );
      }
      if (!isError) {
        await uploadMediaToBucket(file);
      }
      return isError;
    },
    onRemove: (file) => {
      deleteMediaFromBucket();
    },
  };

  const normFile = (e) => {
    if (Array.isArray(e)) {
      return e;
    }
    return e?.fileList;
  };

  const uploadMediaToBucket = async (file) => {
    setAPKUploading(true);
    let url = `${URLS_V2?.APP_RELEASE_MANAGER?.GET_SIGNEDURL}?filename=${file.name}&action=write`;
    httpGet(url)
      .then(async (res) => {
        if (res.status === 200) {
          try {
            await axios.put(res.data.signedUrl, file, {
              headers: {
                'Content-Type': file.type,
              },
            });
            const baseUrl = 'https://storage.googleapis.com';
            const bucketName =
              process.env.REACT_APP_CLOUD_STORAGE_MANAGEMENT_BUCKET; // Replace with your actual bucket name
            const filePath = `temp/${file.name}`; // Replace with the desired folder path
            const fileUrl = `${baseUrl}/${bucketName}/${filePath}`;
            setAPKFile({
              name: file?.name,
              url: fileUrl,
              isUploaded: true,
            });
            setAPKUploading(false);
          } catch (error) {
            showErrorMsg(error);
            setAPKUploading(false);
          }
        }
      })
      .catch((err) => {
        showErrorMsg(err);
        setIsFormSubmitting(false);
      });
  };

  const deleteMediaFromBucket = (file) => {
    let url = `${URLS_V2?.APP_RELEASE_MANAGER?.GET_SIGNEDURL}?filename=${file.name}&action=delete`;
    httpGet(url)
      .then(async (res) => {
        if (res.status === 200) {
          try {
            await axios.delete(res.data.signedUrl);
            setAPKUploading(false);
          } catch (error) {
            showErrorMsg(error);
            setAPKUploading(false);
          }
        }
      })
      .catch((err) => {
        showErrorMsg(err);
        setIsFormSubmitting(false);
      });
  };

  return (
    <>
      <CommonBreadCrumb breadCrumbs={breakCrumbList} />
      <div className="while-bg-box cus-table-header">
        <h3 className="table-title">Create New Release</h3>
      </div>
      {renderCurrentVersion(appCurrentVersion)}
      <Form
        form={form}
        layout="vertical"
        className="main-filter-form"
        labelCol={{ span: 4 }}
        wrapperCol={{ span: 12 }}
        onFinish={onFinish}
      >
        <div className="while-bg-box filter-form">
          <Form.Item name="type" label="Package Name">
            <span className="ant-form-text f-w-600">{packageName}</span>
          </Form.Item>
          <Form.Item
            name="version_code"
            label="Version Code"
            validateTrigger={handleValidateFieldNames('version_code')}
            rules={[
              {
                required: true,
                message: 'Please enter version code!',
              },
              {
                pattern: /^(?:\d*)$/,
                message: 'Version code should be a number',
              },
            ]}
          >
            <InputNumber
              min={1}
              max={99999999}
              style={{ width: '100%' }}
              placeholder={'Version Code'}
              onBlur={() =>
                setValidateFieldsName([...validateFieldsName, 'version_code'])
              }
              disabled={isFormSubmitting}
            />
          </Form.Item>

          <Form.Item
            name="version_name"
            label="Version Name"
            validateTrigger={handleValidateFieldNames('version_name')}
            rules={[
              {
                required: true,
                message: 'Please enter version name!',
              },
            ]}
          >
            <Input
              type="text"
              placeholder="Version Name"
              onBlur={() =>
                setValidateFieldsName([...validateFieldsName, 'version_name'])
              }
              disabled={isFormSubmitting}
            />
          </Form.Item>

          <Form.Item
            name="description"
            label="Description"
            validateTrigger={handleValidateFieldNames('description')}
            rules={[
              {
                required: true,
                message: 'Please enter description!',
              },
            ]}
          >
            <TextArea
              rows={4}
              placeholder="Description"
              onBlur={() =>
                setValidateFieldsName([...validateFieldsName, 'description'])
              }
              disabled={isFormSubmitting}
            />
          </Form.Item>
          <Form.Item label="Upload APK">
            <Spin spinning={apkUploading}>
              <Form.Item
                name="dragger"
                valuePropName="fileList"
                getValueFromEvent={normFile}
                validateTrigger={handleValidateFieldNames('dragger')}
                rules={[
                  {
                    required: true,
                    message: 'Please select file!',
                  },
                ]}
              >
                <Upload.Dragger
                  name="file"
                  {...uploadDraggerProps}
                  maxCount={1}
                  disabled={isFormSubmitting}
                >
                  <p className="ant-upload-drag-icon">
                    <InboxOutlined />
                  </p>
                  <p className="ant-upload-text">
                    Click or drag file to this area to upload
                  </p>
                  <p className="ant-upload-hint">
                    Support for a single or bulk upload.
                  </p>
                </Upload.Dragger>
              </Form.Item>
            </Spin>
          </Form.Item>
        </div>
        <div className="while-bg-box">
          {/* <Form.Item wrapperCol={{ span: 8, offset: 0 }}> */}
          <Button
            type="primary"
            htmlType="submit"
            disabled={apkUploading}
            loading={isFormSubmitting}
          >
            Submit
          </Button>
          <Button
            type="secondary"
            htmlType="button"
            className="m-l-10"
            onClick={handleCancelClick}
            disabled={isFormSubmitting || apkUploading}
          >
            Cancel
          </Button>
          {/* </Form.Item> */}
        </div>
      </Form>
    </>
  );
};

CreateNewUnisocRelease.propTypes = {
  appCurrentVersion: object,
  packageName: string,
  renderCurrentVersion: func,
  resetAllData: func,
};

export default CreateNewUnisocRelease;
