import styled from 'styled-components';

export const CollapseBox = styled.div`
  margin-top: 20px;
  .slot-box {
    display: flex;
    margin-bottom: 10px;
    align-items: center;
    .slot-label {
      margin-right: 20px;
      width: 100px;
      text-transform: capitalize;
    }
  }
  .media-btns {
    .loader-btn {
      height: 33px;
      width: 168px;
      margin-bottom: 20px;
    }
    .preview-btn {
      min-width: 168px;
    }
    .custom-upload-btn {
      display: flex;
      margin-bottom: 20px;
    }
    .custom-upload-btn label {
      cursor: pointer;
    }
    .video-block-box {
      padding-right: 10%;
      margin-bottom: 20px;
    }
    label {
      margin-right: 10px;
      background: white;
      border: 1px solid grey;
      padding: 5px 20px;
      border-radius: 2px;
      svg {
        margin-right: 10px;
      }
    }
    .btn-mr-rt {
      margin-right: 10px;
    }
  }
  .final-btn {
    margin-top: 20px;
    width: 100%;
  }
`;
