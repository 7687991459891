import React from 'react';
import { Table, Button } from 'antd';
import { array, func, string } from 'prop-types';

const CommonListTableWithButton = (props) => {
  const {
    tableTitle,
    columns,
    dataSource,
    submitCallBack,
    cancelCallBack,
    submitBtnTitle = 'Confirm',
    cancelBntTitle = 'Cancel',
  } = props;

  return (
    <div style={{ marginTop: '1rem', marginBottom: '1rem' }}>
      <div className="table-header">
        <h3 className="table-title">{tableTitle}</h3>
        <div className="table-right-side">
          <div style={{ marginRight: '1rem' }}>
            <Button type="primary" onClick={() => submitCallBack()}>
              {submitBtnTitle}
            </Button>
          </div>
          <div>
            <Button type="secondary" onClick={() => cancelCallBack()}>
              {cancelBntTitle}
            </Button>
          </div>
        </div>
      </div>
      <div className="main-table-body">
        <Table
          columns={columns}
          dataSource={dataSource}
          pagination={false}
          size="small"
          scroll={{ y: 300 }}
        />
      </div>
    </div>
  );
};

export default CommonListTableWithButton;

CommonListTableWithButton.propTypes = {
  cancelBntTitle: string,
  cancelCallBack: func,
  columns: array,
  dataSource: array,
  submitBtnTitle: string,
  submitCallBack: func,
  tableTitle: string,
};
