import { firebaseDb, fireStoreDb } from 'configs';
import {
  httpGet,
  URLS,
  showErrorMsg,
  setAMAAuthTokenLocalStorage,
  setGCloudStorageAuthTokenLocalStorage,
} from 'utils';
import moment from 'moment';

export const updateFirebaseGlobalAppSettings = (key) => {
  const globalSettingRef = firebaseDb.ref('global_settings');
  globalSettingRef.update({
    [key]: new Date().toISOString(),
  });
};

export const generateAndroidManagementToken = () => {
  const generateTokenURL = URLS.ANDROID_MANAGEMENT.GENERATE_AUTH_TOKEN;
  httpGet(generateTokenURL)
    .then((tokenRes) => {
      if (tokenRes?.status === 200) {
        setAMAAuthTokenLocalStorage(tokenRes?.data?.token);
      }
    })
    .catch((error) => {
      showErrorMsg('System is facing error, Please try again later');
      throw error;
    });
};

/**
 * delay the execution of script for particular time period
 * @param milliSeconds delay in milliseconds
 * @returns a null value after give time
 */
export const asyncDelay = async (milliSeconds = 1000) => {
  return new Promise((resolve, rej) => {
    try {
      setTimeout(() => {
        return resolve(null);
      }, milliSeconds);
    } catch (err) {
      rej(err);
    }
  });
};

/**
 * formatDate as per the format params
 * @param dateFormat date format default date format is 'DD MMMM YYYY hh:mm A'
 * @returns a formate date string
 */
export const formatDate = (dateString, dateFormat = 'DD MMMM YYYY hh:mm A') => {
  return moment(dateString).format(dateFormat);
};

export const formatTime=(dateString, dateFormat = 'hh:mm a')=>{

  return moment(dateString).format(dateFormat);

}

export const formatOnlyDate=(dateString, dateFormat = 'DD MMMM YYYY')=>{
  return moment(dateString).format(dateFormat);
}

export const dateFormatter=(dateString, dateFormat = 'DD MMMM YYYY hh:mm A') => {
  return moment.unix(dateString).format(dateFormat);
};

/**
 * convertUnixToDateFormat as per the format params
 * @param dateString date string is unix time stamp //16324645411
 * @param dateFormat date format default date format is 'DD MMMM YYYY hh:mm A'
 * @returns a formate date string
 */
export const convertUnixToDateFormat = (
  dateString,
  dateFormat = 'DD MMMM YYYY hh:mm A'
) => {
  return (
    (dateString && moment.unix(dateString).isValid()
      ? moment.unix(dateString).format(dateFormat)
      : 'N/A') || ''
  );
};

/**
 * calcHoursFromCurrentDateTime function used to calculate hours gap from given time string
 * @param dateString date string which used as a start time
 * @returns hours gap
 */
export const calcHoursFromCurrentDateTime = (dateString) => {
  return moment.duration(moment(new Date()).diff(moment(dateString))).asHours();
};

/**
 * calcMinsFromCurrentDateTime function used to calculate hours gap from given time string
 * @param dateString date string which used as a start time
 * @returns mins gap
 */
export const calcMinsFromCurrentDateTime = (dateString) => {
  return moment
    .duration(moment(new Date()).diff(moment(dateString)))
    .asMinutes();
};

export const generateGCStorageManagementToken = () => {
  const generateTokenURL =
    URLS?.GOOGLE_CLOUD_STORAGE_MANAGEMENT?.GENERATE_AUTH_TOKEN;
  httpGet(generateTokenURL)
    .then((tokenRes) => {
      if (tokenRes?.status === 200) {
        setGCloudStorageAuthTokenLocalStorage(tokenRes?.data?.token);
      }
    })
    .catch((error) => {
      showErrorMsg('System is facing error, Please try again later');
      throw error;
    });
};

export const getBase64 = (img, callback) => {
  const reader = new FileReader();
  reader.addEventListener('load', () => callback(reader.result));
  reader.readAsDataURL(img);
};

export const getSingleValueFromEncodedURL = (
  url,
  keyIndex,
  splitWith = '/'
) => {
  if (url) {
    const urlArray = url.split(splitWith);
    if (urlArray && urlArray.length >= 1) {
      return urlArray[keyIndex];
    }
  }
  return '';
};

/**
 * returnStringToSentenceCase convert any string to sentence case
 * i.e TEST to Test, tEsT to Test, TEST 20 to Test 20
 * @param {*} string any string
 * @returns
 */
export const returnStringToSentenceCase = (string) => {
  return string.replace(/\w\S*/g, function (txt) {
    return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
  });
};

/**
 * updateFirebaseCollectionTimestamp function lets you update the time stamp for
 * given collection in given fields name in firebase firestore DB
 * @param {string} collection collection name
 * @param {key} key key name where timestamp gets update
 * @return {void}
 */
export const updateFirebaseCollectionTimestamp = (collection, document) => {
  fireStoreDb.collection(collection).doc(document).set({
    updated_at: moment().unix(),
  });
};

/**
 * generateRandomPasswordString generate and return a strong string that can use for password
 */
export const generateRandomPasswordString = () => {
  //eslint-disable-next-line
  let characterList ="0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz!'^+%&/()=?_#${[]}|;:>÷<.*-@";
  let password = '';
  const characterListLength = characterList.length;
  const passwordLength = 8;
  for (let i = 0; i < passwordLength; i++) {
    const characterIndex = Math.round(Math.random() * characterListLength);
    password = password + characterList.charAt(characterIndex);
  }
  return password;
};

/**
 * currentPage function takes skip/start index and page size/limit per page
 * and return the current page for data table
 * @param {number} skip skip or start index
 * @param {number} pageSize total page size or limit per page
 * @returns current page
 */
export const currentPage = (skip, pageSize) => {
  return Math.ceil(skip / pageSize) + 1;
};
