import React, { useState, useEffect } from 'react';
import {
  Form,
  Row,
  Col,
  Select,
  Button,
  Card,
  Spin,
  // Input,
  // Button,
  // Popconfirm,
  // InputNumber,
} from 'antd';
import { CalculateGeofenceBox } from './_calc-geofence.styled';
import { httpGet, httpPost, URLS, showErrorMsg, showSuccessMsg } from 'utils';
import WhereToTarget from './where-to-target';
// import { DAYS } from 'constants/campaign';

const { Option } = Select;

const CalculateGeofence = () => {
  const cardDivRef = React.createRef();
  const [campaignLocations, setCampaignLocations] = useState([]);
  const [validateFieldsName, setValidateFieldsName] = useState([]);
  const [campaignsList, setCampaignsList] = useState([]);
  const [isCampaignFetching, setIsCampaignsListFetching] = useState(false);
  const [isExportingData, setIsExportingData] = useState(false);
  const [form] = Form.useForm();
  const [locations, setLocations] = useState([]);
  const [currentAddress, setCurrentAddress] = useState('');

  useEffect(() => {
    fetchCampaignList();
  }, []);

  useEffect(() => {
    if (isExportingData) {
      window.onbeforeunload = function () {
        return true;
      };
    }
    return () => {
      window.onbeforeunload = null;
    };
  }, [isExportingData]);

  const fetchCampaignList = () => {
    setIsCampaignsListFetching(true);
    httpGet(URLS.CAMPAIGN.DROPDOWN_CAMPAIGNS_LIST)
      .then((res) => {
        if (res.status === 200) {
          setCampaignsList(res?.data || []);
        } else if (res?.error) {
          showErrorMsg(res?.error);
        }
        setIsCampaignsListFetching(false);
      })
      .catch((err) => {
        showErrorMsg(err);
        setIsCampaignsListFetching(false);
      });
  };

  const handleValidateFieldNames = (name) => {
    const isFieldName =
      validateFieldsName &&
      validateFieldsName.find((fieldName) => fieldName === name);
    if (isFieldName) return 'onChange';
    return 'onBlur';
  };

  const handleFormSubmit = () => {
    window.scrollTo(0, 0);
    form.submit();
    form.validateFields().then(async (values) => {
      setIsExportingData(true);
      values = { ...values, locations };
      const url = URLS?.CAMPAIGN?.CALCULATE_CUSTOM_GEOFENCE_DATA;
      httpPost(url, values)
        .then((res) => {
          const link = document.createElement('a');
          link.href =
            'data:text/csv;charset=UTF-8,%EF%BB%BF' + res.replace(' ', '%20');
          link.setAttribute('download', 'geofence-data.csv');
          document.body.appendChild(link);
          link.click();
          // Clean up and remove the link
          link.parentNode.removeChild(link);
          showSuccessMsg('Data has been exported successfully');
          setIsExportingData(false);
          form.resetFields();
          setLocations([]);
          setCurrentAddress('');
          setCampaignLocations([]);
        })
        .catch((err) => {
          showErrorMsg(err);
          setIsExportingData(false);
        });
    });
  };

  const fetchLocationOnCampaignSelection = (campaignId) => {
    const selectedCampaignData = campaignsList.filter(
      (item) =>
        item._id === campaignId && item.campaign_class === 'Geofence Campaign'
    );
    if (selectedCampaignData?.[0]?.locations) {
      setCampaignLocations(selectedCampaignData?.[0]?.locations || []);
    }
  };

  return (
    <div ref={cardDivRef}>
      {isExportingData && (
        <div className="custom-full-page-backdrop">
          <Spin size="large" tip="Preparing Data..." spinning={true}></Spin>
        </div>
      )}
      <Card
        title="Calculate Custom Geofence"
        className="custom-card-layout-margin"
      >
        <CalculateGeofenceBox>
          {isCampaignFetching ? (
            'Fetching...'
          ) : (
            <Form form={form} layout="vertical">
              <Row>
                <Col xs={24} sm={24} md={24}>
                  <Form.Item
                    name="campaign_id"
                    label="Campaign"
                    rules={[
                      {
                        required: true,
                        message: 'Please select campaign!',
                      },
                    ]}
                  >
                    <Select
                      placeholder={
                        isCampaignFetching ? 'Fetching...' : 'Select Campaign'
                      }
                      showSearch
                      optionFilterProp="children"
                      filterOption={(input, option) =>
                        option.children
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      }
                      onChange={fetchLocationOnCampaignSelection}
                    >
                      {campaignsList &&
                        campaignsList.length > 0 &&
                        campaignsList.map((campaign, index) => (
                          <Option value={campaign?._id} key={index}>
                            {campaign?.campaign_name || ''}
                          </Option>
                        ))}
                    </Select>
                  </Form.Item>
                </Col>

                <Col xs={24} sm={24}>
                  <WhereToTarget
                    handleValidateFieldNames={handleValidateFieldNames}
                    setValidateFieldsName={setValidateFieldsName}
                    validateFieldsName={validateFieldsName}
                    locations={locations}
                    campaignLocations={campaignLocations}
                    setLocations={setLocations}
                    currentAddress={currentAddress}
                    setCurrentAddress={setCurrentAddress}
                    form={form}
                  />
                </Col>

                <Col xs={24} sm={24} md={24} className="m-t-20">
                  <Form.Item>
                    <Button
                      type="primary"
                      className="add-btn"
                      onClick={handleFormSubmit}
                      disabled={!locations?.length >= 1}
                    >
                      {'Calculate and Export'}
                    </Button>
                  </Form.Item>
                </Col>
              </Row>
            </Form>
          )}
        </CalculateGeofenceBox>
      </Card>
    </div>
  );
};

export default CalculateGeofence;
