export const WATCH_CONTENT_IMAGE_DIMENSIONS = {
  FILE_SIZE: 400, //size is mentioned in KB
  FILE_WIDTH: 572, //required logo width
  FILE_HEIGHT: 1000, //required logo height
};

export const WATCH_CONTENT_VIDEO_DIMENSIONS = {
  FILE_SIZE: 200000, //size is mentioned in KB
  FILE_WIDTH: 572, //required logo width
  FILE_HEIGHT: 1000, //required logo height
};

export const MUSIC_CONTENT_AUDIO_SIZE = {
  FILE_SIZE: 200000, //size is mentioned in KB
};

export const WATCH_CONTENT_LANGUAGE = {
  ENGLISH: { value: 'en', label: 'English' },
  ARABIC: { value: 'ar', label: 'Arabic' },
};

export const WATCH_CONTENT_LANGUAGE_LIST = [
  WATCH_CONTENT_LANGUAGE.ENGLISH,
  WATCH_CONTENT_LANGUAGE.ARABIC,
];
