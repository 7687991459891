import React, { useState } from 'react';
import { shape, string, bool, func } from 'prop-types';
import { Button, Modal, Form, Row, Col, Input } from 'antd';
import { CompanyModalBox } from './_company-modal.styled';

const CompanyModal = (props) => {
  const {
    form,
    modalTitle,
    isCompanyModalVisible,
    handleOnCancel,
    handleAddCompany,
    isCompanyAdding,
  } = props;
  const [validateFieldsName, setValidateFieldsName] = useState([]);

  const isDisabled = isCompanyAdding;

  const handleValidateFieldNames = (name) => {
    const isFieldName = validateFieldsName.find(
      (fieldName) => fieldName === name
    );
    if (isFieldName) return 'onChange';
    return 'onBlur';
  };

  let modalfooterButtons = [
    <Button
      key="save"
      type="primary"
      onClick={handleAddCompany}
      disabled={isDisabled}
    >
      {isCompanyAdding ? 'Saving...' : 'Save'}
    </Button>,
    <Button key="cancel" onClick={handleOnCancel} disabled={isDisabled}>
      Cancel
    </Button>,
  ];
  return (
    <CompanyModalBox>
      <Modal
        title={modalTitle}
        visible={isCompanyModalVisible}
        onCancel={() => !isDisabled && handleOnCancel()}
        className="company-modal"
        maskClosable={false}
        footer={modalfooterButtons}
      >
        <Form form={form} layout="vertical">
          <Row gutter={16}>
            <Col className="gutter-row" xs={24} sm={12}>
              <Form.Item
                name="company_name"
                label="Company Name"
                validateTrigger={handleValidateFieldNames('company_name')}
                rules={[
                  {
                    required: true,
                    message: 'Please enter company name!',
                  },
                ]}
              >
                <Input
                  type="text"
                  placeholder="Company name"
                  onBlur={() =>
                    setValidateFieldsName([
                      ...validateFieldsName,
                      'company_name',
                    ])
                  }
                />
              </Form.Item>
            </Col>
            <Col className="gutter-row" xs={24} sm={12}>
              <Form.Item
                name="contact_person_name"
                label="Contact person name"
                validateTrigger={handleValidateFieldNames(
                  'contact_person_name'
                )}
                rules={[
                  {
                    required: true,
                    message: 'Please enter Contact person name!',
                  },
                ]}
              >
                <Input
                  type="text"
                  placeholder="Contact person name"
                  onBlur={() =>
                    setValidateFieldsName([
                      ...validateFieldsName,
                      'contact_person_name',
                    ])
                  }
                />
              </Form.Item>
            </Col>
            <Col className="gutter-row" xs={24} sm={12}>
              <Form.Item
                name="email"
                label="Email"
                validateTrigger={handleValidateFieldNames('email')}
                rules={[
                  {
                    type: 'email',
                    message: 'The input is not valid E-mail!',
                  },
                  {
                    required: true,
                    message: 'Please enter E-mail!',
                  },
                ]}
              >
                <Input
                  type="text"
                  placeholder="something@example.com"
                  onBlur={() =>
                    setValidateFieldsName([...validateFieldsName, 'email'])
                  }
                />
              </Form.Item>
            </Col>
            <Col className="gutter-row" xs={24} sm={12}>
              <Form.Item
                name="phone_number"
                label="Phone number"
                validateTrigger={handleValidateFieldNames('phone_number')}
                rules={[
                  {
                    required: true,
                    message: 'Please enter phone number!',
                  },
                ]}
              >
                <Input
                  type="number"
                  placeholder="Phone number"
                  onBlur={() =>
                    setValidateFieldsName([
                      ...validateFieldsName,
                      'phone_number',
                    ])
                  }
                />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Modal>
    </CompanyModalBox>
  );
};

CompanyModal.propTypes = {
  form: shape({}),
  handleAddCompany: func,
  handleOnCancel: func,
  isCompanyAdding: bool,
  isCompanyModalVisible: bool,
  modalTitle: string,
};

export default CompanyModal;
