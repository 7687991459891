import React, { useState, useEffect } from 'react';
import { Row, Col, Form, Input, Button } from 'antd';
import ChangePasswordModal from 'components/change-password-modal';
import { httpPut, httpGet, URLS, showErrorMsg, showSuccessMsg } from 'utils';
import { ProfileBox } from './_profile.styled';

const Profile = () => {
  const [validateFieldsName, setValidateFieldsName] = useState([]);
  const [isProfileUpdating, setIsProfileUpdating] = useState(false);
  const [isPasswordModalOpen, setIsPasswordModalOpen] = useState(false);
  const [, setIsFetchingProfile] = useState(false);
  const [form] = Form.useForm();

  useEffect(() => {
    setIsFetchingProfile(true);
    const url = URLS?.AUTH?.PROFILE;
    httpGet(url)
      .then((res) => {
        if (res.status === 200) {
          if (
            res?.data &&
            Object.entries(res?.data) &&
            Object.entries(res?.data).length > 0
          ) {
            Object.entries(res?.data).forEach(([key, value]) => {
              form.setFieldsValue({
                [key]: value,
              });
            });
          }
        } else if (res?.error) {
          showErrorMsg(res?.error);
        }
        setIsFetchingProfile(false);
      })
      .catch((err) => {
        showErrorMsg(err);
        setIsFetchingProfile(false);
      });
  }, []);

  const openPasswordModal = () => {
    setIsPasswordModalOpen(true);
  };

  const closePasswordModal = () => {
    setIsPasswordModalOpen(false);
  };

  const handleValidateFieldNames = (name) => {
    const isFieldName = validateFieldsName.find(
      (fieldName) => fieldName === name
    );
    if (isFieldName) return 'onChange';
    return 'onBlur';
  };

  const handleEditProfile = () => {
    form.submit();
    form
      .validateFields()
      .then(async (values) => {
        const formData = new FormData();
        formData.append('name', values.name);
        formData.append('designation', values.designation);
        setIsProfileUpdating(true);
        const url = URLS?.AUTH.PROFILE;
        httpPut(url, formData)
          .then((res) => {
            if (res.status === 200) {
              showSuccessMsg('Profile saved successfully!');
            } else if (res?.error) {
              showErrorMsg(res?.error);
            }
            setIsProfileUpdating(false);
          })
          .catch((err) => {
            showErrorMsg(err);
            setIsProfileUpdating(false);
          });
      })
      .catch((errorInfo) => errorInfo);
  };

  return (
    <ProfileBox>
      <Form form={form} layout="vertical">
        <div className="profile-content">
          <Row gutter={16}>
            <Col className="gutter-row" xs={24} sm={12}>
              <Form.Item
                name="name"
                label="Name"
                validateTrigger={handleValidateFieldNames('name')}
                rules={[
                  {
                    required: true,
                    message: 'Please enter name!',
                  },
                ]}
              >
                <Input
                  type="text"
                  placeholder="Name"
                  onBlur={() =>
                    setValidateFieldsName([...validateFieldsName, 'name'])
                  }
                />
              </Form.Item>
            </Col>
            <Col className="gutter-row" xs={24} sm={12}>
              <Form.Item
                name="designation"
                label="Designation"
                validateTrigger={handleValidateFieldNames('designation')}
                rules={[
                  {
                    required: true,
                    message: 'Please enter designation!',
                  },
                ]}
              >
                <Input
                  type="text"
                  placeholder="Designation"
                  onBlur={() =>
                    setValidateFieldsName([
                      ...validateFieldsName,
                      'designation',
                    ])
                  }
                />
              </Form.Item>
            </Col>
            <Col className="gutter-row" xs={24} sm={12}>
              <Button onClick={openPasswordModal}>Change Password</Button>
            </Col>
          </Row>
        </div>
        <Button
          type="primary"
          disabled={isProfileUpdating}
          onClick={handleEditProfile}
        >
          {isProfileUpdating ? 'Loading...' : 'Save'}
        </Button>
      </Form>
      {isPasswordModalOpen && (
        <ChangePasswordModal
          isModalVisible={isPasswordModalOpen}
          closeModal={closePasswordModal}
        />
      )}
    </ProfileBox>
  );
};

export default Profile;
