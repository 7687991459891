import { Table } from "antd";
import React from "react";
import { Loader } from "../_rides.styled";

const CompletedRides=(props)=>{
    const {ridesTempList,totalRides,isFetching,handlePaginationChange,columns}=props

    const defaultTitle = () => {
        return (
          <>
            <div className="cus-table-right-btn">
              {`Showing Records: ${ridesTempList.length} / Total Records: ${totalRides}`}
            </div>
          </>
        );
      };

return(<>
<div className="main-table-body">
        <Table
          title={defaultTitle}
          loading={{
            indicator: <Loader>Fetching...</Loader>,
            spinning: isFetching,
          }}
          onChange={handlePaginationChange}
          columns={columns}
          dataSource={ridesTempList || []}
          size="small"
          pagination={{ defaultPageSize: 100, total:totalRides }}
          scroll={{ x: 400 }}
        />
      </div>
</>)
}

export default CompletedRides