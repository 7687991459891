import styled from 'styled-components';

export const LoginBox = styled.div`
  .login {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh;
    width: 100%;
    .login-content {
      width: 100%;
      max-width: 400px;
      border: 1px solid #d9d9d9;
      padding: 15px 25px 0px 25px;
      h1 {
        font-size: 20px;
        font-weight: 600;
        text-align: center;
        margin-bottom: 20px;
      }
      .login-form {
        .ant-input-affix-wrapper {
          color: #1890ff;
          padding: 0px 15px;
          height: 70px;
        }
        .ant-form-item-has-error .ant-input-prefix {
          color: #ff4d4f !important;
        }
        input {
          font-size: 16px;
        }
      }
      .remember-forgot {
        margin-top: -12px;
        .ant-form-item-control-input-content {
          display: flex;
          justify-content: flex-end;
          width: 100%;
        }
      }
    }
  }
`;
