import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Table, Button, Form, Input, DatePicker, Row, Col } from 'antd';
import { formatDate, httpGet, URLS_V2 } from 'utils';
import { EyeOutlined, FormOutlined } from '@ant-design/icons';
import { Loader } from './_campaignList.styled';
import { debounce } from 'lodash';
import { bool } from 'prop-types';

const { RangePicker } = DatePicker;

// const campaignStatusSelectOptions = (
//   <>
//     <Option key={'active'} value={'active'}>
//       {'Active'}
//     </Option>
//     <Option key={'inactive'} value={'inactive'}>
//       {'Inactive'}
//     </Option>
//   </>
// );

const CampaignsTableList = (props) => {
  const { isActive, isInActive, isUnapproved, isDraft } = props;
  const [isFetching, setIsFetching] = useState(false);
  const [campaignsList, setCampaignsList] = useState([]);
  const [totalCampaigns, setTotalCampaigns] = useState(0);
  const [searchPagination, setSearchPagination] = useState({
    skip: 0,
    limit: 100,
    order: 1,
    orderBy: 'campaignName',
    searchValue: {},
  });

  useEffect(() => {
    fetchCampaignsList(searchPagination);
  }, [searchPagination.searchValue]);

  const fetchCampaignsList = (searchPaginationParams) => {
    setIsFetching(true);
    const queryParams = new URLSearchParams();
    queryParams.append('skip', searchPaginationParams.skip);
    queryParams.append('limit', searchPaginationParams.limit);
    queryParams.append('order', searchPaginationParams.order);
    queryParams.append('orderBy', searchPaginationParams.orderBy);
    if (isInActive) {
      queryParams.append('is_active', !isInActive);
    } else if (isActive) {
      queryParams.append('is_active', isActive);
    } else if (isUnapproved) {
      queryParams.append('is_Unapproved', isUnapproved);
    } else if (isDraft) {
      queryParams.append('is_Draft', isDraft);
    }
    if (
      searchPaginationParams.searchValue &&
      Object.keys(searchPaginationParams.searchValue).length >= 1
    ) {
      //eslint-disable-next-line
      Object.keys(searchPaginationParams.searchValue).map((key) => {
        queryParams.append(key, searchPaginationParams.searchValue[key]);
      });
    }
    const url = `${
      URLS_V2?.CAMPAIGNS?.CAMPAIGNS_LIST
    }?${queryParams.toString()}`;
    httpGet(url)
      .then((res) => {
        if (res?.status === 200) {
          const campaignListData =
            (res?.data &&
              res?.data.length > 0 &&
              res?.data.map((data) => {
                return {
                  ...data,
                  key: data?._id,
                  campaignName: data?.campaign_name || '',
                  companyName: data?.company?.company_name || '',
                  industryType: data?.industry_type || '',
                  campaignType: data?.campaign_type || '',
                  isActive: data?.is_active || '',
                };
              })) ||
            [];
          setCampaignsList(campaignListData || []);
          setTotalCampaigns(res?.total || 0);
          setIsFetching(false);
        }
      })
      .catch(() => {
        setIsFetching(false);
      });
  };

  const columns = [
    {
      key: 'campaignName',
      title: CAMPAIGN_LABELS.CAMPAIGN_NAME,
      dataIndex: 'campaignName',
      sorter: true,
      defaultSortOrder: 'ascend',
    },
    {
      key: 'companyName',
      title: CAMPAIGN_LABELS.COMPANY_NAME,
      dataIndex: 'companyName',
    },
    {
      key: 'industryType',
      title: CAMPAIGN_LABELS.INDUSTRY_TYPE,
      dataIndex: 'industryType',
      sorter: true,
    },
    {
      key: 'campaignType',
      title: CAMPAIGN_LABELS.CAMPAIGN_TYPE,
      dataIndex: 'campaignType',
    },
    {
      key: 'isActive',
      title: CAMPAIGN_LABELS.ACTIVE_INACTIVE,
      dataIndex: 'isActive',
      render: (isActive) => {
        return (function Actions() {
          return <div>{isActive ? <p>Active</p> : <p>Inactive</p>}</div>;
        })();
      },
    },
    {
      key: 'created_at',
      title: CAMPAIGN_LABELS.CREATED_AT,
      dataIndex: 'created_at',
      render: (created_at) => {
        return (function Actions() {
          return (
            <div className="created_at">
              {created_at && formatDate(created_at, 'Do MMMM YYYY')}
            </div>
          );
        })();
      },
    },
    {
      key: '_id',
      title: CAMPAIGN_LABELS.ACTIONS,
      dataIndex: '_id',
      render: (id) => {
        return (function Actions() {
          return (
            <div className="actions-btn">
              <Link to={`/v2/campaigns/edit/${id}`}>
                <Button shape="circle" className="edit-btn">
                  <FormOutlined type="primary" className="edit-icon" />
                </Button>
              </Link>
            <Link to={`/v2/campaigns/view-campaign/${id}`}>
            <Button 
                  shape="circle"
                  className="edit-btn"
                  style={{ marginLeft: '0.5rem' }}
                >
                  <EyeOutlined type="primary" className="preview-icon" />
                </Button>
            </Link>
              
            </div>
          );
        })();
      },
    },
  ];

  const handleTextSearch = (e) => {
    if (e.target.value) {
      setSearchPagination({
        ...searchPagination,
        searchValue: {
          ...searchPagination.searchValue,
          [e.target.name]: e.target.value,
        },
      });
    } else if (searchPagination.searchValue[e.target.name] !== undefined) {
      delete searchPagination.searchValue[e.target.name];
      setSearchPagination({
        ...searchPagination,
        searchValue: { ...searchPagination.searchValue },
      });
    }
  };

  // const handleSelectSearch = (selectedValue) => {
  //   if (selectedValue) {
  //     setSearchPagination({
  //       ...searchPagination,
  //       searchValue: {
  //         ...searchPagination.searchValue,
  //         active: selectedValue === 'active' ? true : false,
  //       },
  //     });
  //   } else if (searchPagination.searchValue['active'] !== undefined) {
  //     delete searchPagination.searchValue['active'];
  //     setSearchPagination({
  //       ...searchPagination,
  //       searchValue: { ...searchPagination.searchValue },
  //     });
  //   }
  // };

  const handleDateRangeSearch = (value) => {
    if (value && value.length > 1) {
      setSearchPagination({
        ...searchPagination,
        searchValue: {
          ...searchPagination.searchValue,
          fromDate: formatDate(value[0], 'YYYY-MM-DD'),
          toDate: formatDate(value[1], 'YYYY-MM-DD'),
        },
      });
    } else if (
      searchPagination.searchValue['fromDate'] !== undefined &&
      searchPagination.searchValue['toDate'] !== undefined
    ) {
      delete searchPagination.searchValue['fromDate'];
      delete searchPagination.searchValue['toDate'];
      setSearchPagination({
        ...searchPagination,
        searchValue: { ...searchPagination.searchValue },
      });
    }
  };

  const handlePaginationChange = (pagination, filters, sorter) => {
    const { current, pageSize } = pagination;
    const tempSearchPagination = {
      ...searchPagination,
      skip: current * pageSize - pageSize,
      limit: pageSize,
      order: sorter.order === 'descend' ? -1 : 1,
      orderBy: sorter.field,
    };
    setSearchPagination(tempSearchPagination);
    fetchCampaignsList(tempSearchPagination);
  };

  const defaultTitle = () => {
    return (
      <>
        <div className="cus-table-right-btn">
          {`Showing Records: ${campaignsList.length} / Total Records: ${totalCampaigns}`}
        </div>
      </>
    );
  };

  return (
    <>
      <div className="while-bg-box">
        <Form className="main-filter-form">
          <Row gutter={[16, 16]}>
            <Col md={8}>
              <Form.Item name="campaignName" style={{ marginBottom: '0' }}>
                <Input
                  type="text"
                  name="campaignName"
                  onChange={debounce((event) => handleTextSearch(event))}
                  placeholder="Search Campaign Name / Industry Type..."
                />
              </Form.Item>
            </Col>
            <Col md={8}>
              <Form.Item name="companyName" style={{ marginBottom: '0' }}>
                <Input
                  type="text"
                  name="companyName"
                  onChange={debounce((event) => handleTextSearch(event))}
                  placeholder="Search Company Name..."
                />
              </Form.Item>
            </Col>
            <Col md={8}>
              <Form.Item
                name="campaignStartEndTime"
                style={{ marginBottom: '0' }}
              >
                <RangePicker
                  name="campaignStartEndTime"
                  format="YYYY-MM-DD"
                  style={{ width: '100%' }}
                  placeholder={['Campaign Start Date', 'Campaign End Date']}
                  onChange={(campaignStartEndTime) =>
                    handleDateRangeSearch(campaignStartEndTime)
                  }
                />
              </Form.Item>
            </Col>
            {/* <Col md={6}>
              <Form.Item name="campaignStatus" style={{ marginBottom: '0' }}>
                <Select
                  showSearch
                  allowClear
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    option.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                  placeholder="Select Campaign Status"
                  onChange={handleSelectSearch}
                >
                  {campaignStatusSelectOptions}
                </Select>
              </Form.Item>
            </Col> */}
          </Row>
        </Form>
      </div>
      <div className="main-table-body">
        <Table
          title={defaultTitle}
          loading={{
            indicator: <Loader>Fetching...</Loader>,
            spinning: isFetching,
          }}
          onChange={handlePaginationChange}
          columns={columns}
          dataSource={campaignsList || []}
          size="small"
          pagination={{ defaultPageSize: 100, total: totalCampaigns }}
        />
      </div>
    </>
  );
};

CampaignsTableList.propTypes = {
  isActive: bool,
  isDraft: bool,
  isInActive: bool,
  isUnapproved: bool,
};

export default CampaignsTableList;

const CAMPAIGN_LABELS = {
  ACTIVE_INACTIVE: 'Active/Inactive',
  CAMPAIGN_NAME: 'Campaign Name',
  COMPANY_NAME: 'Company Name',
  INDUSTRY_TYPE: 'Industry Type',
  CAMPAIGN_TYPE: 'Campaign Type',
  CREATED_AT: 'Created At',
  ACTIONS: 'Actions',
};
