export const READ_IMAGE_DIMENSIONS = {
  FILE_SIZE: 5000, //size is mentioned in KB
  FILE_WIDTH: 520, //required logo width
  FILE_HEIGHT: 520, //required logo height
};

export const READING_CATEGORY = {
  NEWS: 'News',
  SPORTS: 'Sports',
  TRAVEL: 'Travel',
  // POLITICS: 'Politics',
  ENTERTAINMENT: 'Entertainment',
  TECHNOLOGY: 'Technology',
};

export const READING_CATEGORIES_LIST = [
  READING_CATEGORY.NEWS,
  READING_CATEGORY.SPORTS,
  READING_CATEGORY.TRAVEL,
  // READING_CATEGORY.POLITICS,
  READING_CATEGORY.ENTERTAINMENT,
  READING_CATEGORY.TECHNOLOGY,
];

export const READING_LANGUAGE = {
  ENGLISH: { value: 'en', label: 'English' },
  ARABIC: { value: 'ar', label: 'Arabic' },
};

export const READING_LANGUAGE_LIST = [
  READING_LANGUAGE.ENGLISH,
  READING_LANGUAGE.ARABIC,
];
