import React, { useState, useEffect } from 'react';
import {
  Table,
  Form,
  Button,
  Dropdown,
  Menu,
  Tag,
  Row,
  Col,
  Switch,
  Popconfirm,
  Input,
  Badge,
} from 'antd';
// import moment from 'moment';
import { Link } from 'react-router-dom';
// import { MODE_TYPES } from 'constants/common';
import { CSVLink } from 'react-csv';
import DeviceModal from 'components/device-modal';
import CommonBreadCrumb from 'components/common-breadcrumb';
import {
  calcHoursFromCurrentDateTime,
  formatDate,
  httpGet,
  httpPut,
  showErrorMsg,
  showSuccessMsg,
  URLS,
  URLS_V2,
} from 'utils';
import {
  // FormOutlined,
  DownOutlined,
  CloseOutlined,
  EyeOutlined,
  DeleteOutlined,
  DownloadOutlined,
} from '@ant-design/icons';
import CommonListTableWithButton from 'components/common-list-table-with-button';
import { DEVICE_BULK_ACTION_LIST_V2 } from 'constants/devices';
import { debounce } from 'lodash';

const SocketTestList = () => {
  const breakCrumbList = [
    { label: 'Home', link: '/dashboard' },
    { label: 'Devices Socket Demo' },
  ];
  const ref = React.useRef(null);
  const [isFetching, setIsFetching] = useState(false);
  const [isFetchingCSV, setIsFetchingCSV] = useState(false);
  const [showBulkActionsRecords, setShowBulkActionsRecords] = useState(false);
  const [totalDevices, setTotalDevices] = useState(0);
  const [activeMode, setActiveMode] = useState();
  const [deviceSerial, setDeviceSerial] = useState();
  const [devicesList, setDevicesList] = useState([]);
  const [CSVDownload, setCSVDownload] = useState([]);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [selectedDeviceSerialNumber, setSelectedDeviceSerialNumber] = useState(
    []
  );
  const [selectedDeviceDetails, setSelectedDeviceDetails] = useState([]);
  const [selectedBulkAction, setSelectedBulkAction] = useState([]);
  const [orderPagination, setOrderPagination] = useState({
    skip: 0,
    limit: 100,
    order: 1,
    orderBy: 'device_serial',
  });
  const [searchParam, setSearchParam] = useState({
    device_serial: '',
    sim_serial: '',
    taxi_number: '',
  });

  useEffect(() => {
    fetchEnrollDevices(orderPagination);
  }, [searchParam]);

  const fetchEnrollDevices = (orderPaginationParams) => {
    setIsFetching(true);

    const queryParams = new URLSearchParams();
    queryParams.append('skip', orderPaginationParams.skip);
    queryParams.append('limit', orderPaginationParams.limit);
    queryParams.append('order', orderPaginationParams.order);
    queryParams.append('order_by', orderPaginationParams.orderBy);

    if (searchParam && Object.keys(searchParam).length >= 1) {
      Object.keys(searchParam).map((key) => {
        queryParams.append(key, searchParam[key]);
      });
    }

    const url = `${URLS_V2?.DEVICES?.DEVICES_LIST}?${queryParams.toString()}`;
    httpGet(url)
      .then((res) => {
        handleCSVData(res.docs || []);
        if (res?.status === 200) {
          let enrollDevicesData = [];
          if (res?.data && res?.data.length > 0) {
            enrollDevicesData = res?.data.map((data) => {
              return {
                ...data,
                key: data?._id,
              };
            });
          }
          setDevicesList(enrollDevicesData || []);
          setTotalDevices(res?.total || 0);
        }
        setIsFetching(false);
      })
      .catch((err) => {
        setIsFetching(false);
      });
  };

  // const handleModeType = ({ activeMode, deviceSerial }) => {
  //   setDeviceSerial(deviceSerial || '');
  //   setActiveMode(activeMode);
  // };

  const handleDeviceModalCallback = () => {
    fetchEnrollDevices(orderPagination);
  };

  const handleCloseDeviceModal = () => {
    setDeviceSerial('');
    setActiveMode('');
  };

  const handleCSVData = (devicesData) => {
    let csvData = [];
    devicesData.forEach((data) => {
      const excelStructureData = {
        [ENROLL_LABELS.DEVICE_SERIAL]: data?.device_serial || '',
        [ENROLL_LABELS.MORNING_DRIVER_NAME]: data?.morning_driver_name || '',
        [ENROLL_LABELS.MORNING_DRIVER_MOBILE]:
          data?.morning_driver_mobile || '',
        [ENROLL_LABELS.EVENING_DRIVER_NAME]: data?.evening_driver_name || '',
        [ENROLL_LABELS.EVENING_DRIVER_MOBILE]:
          data?.evening_driver_mobile || '',
        [ENROLL_LABELS.SIM_SERIAL]: data?.sim_serial || '',
        [ENROLL_LABELS.TAXI_NUMBER]: data?.taxi_number || '',
        [ENROLL_LABELS.ACTIVE]: data?.is_device_active ? 'Active' : 'Inactive',
      };
      csvData.push(excelStructureData);
    });
    setCSVDownload(csvData);
  };

  const columns = [
    {
      key: 'device_serial',
      title: ENROLL_LABELS.DEVICE_SERIAL,
      dataIndex: 'device_serial',
      sorter: true,
      defaultSortOrder: 'ascend',
      render: (device_serial, item) => {
        return (function Actions() {
          return (
            <div>
              {item?.updated_at &&
              calcHoursFromCurrentDateTime(item?.updated_at) <= 1 ? (
                <Badge status="success" title="Online" />
              ) : (
                <Badge status="error" title="Offline" />
              )}
              {device_serial}
            </div>
          );
        })();
      },
    },
    {
      key: 'sim_serial',
      title: ENROLL_LABELS.SIM_SERIAL,
      dataIndex: 'sim_serial',
      sorter: true,
    },
    {
      key: 'taxi_number',
      title: ENROLL_LABELS.TAXI_NUMBER,
      dataIndex: 'taxi_number',
      sorter: true,
    },
    {
      key: 'updated_at',
      title: ENROLL_LABELS.UPDATED_AT,
      dataIndex: 'updated_at',
      render: (date) => {
        return (function Actions() {
          return (
            <>{(date && formatDate(date, 'DD MMMM YYYY hh:mm:ss A')) || '-'}</>
          );
        })();
      },
    },
    {
      key: 'is_device_active',
      title: ENROLL_LABELS.ACTIVE,
      dataIndex: 'is_device_active',
      render: (is_active) => {
        return (function Actions() {
          return (
            <div>
              {is_active ? (
                <Tag color="success">Active</Tag>
              ) : (
                <Tag color="error">Inactive</Tag>
              )}
            </div>
          );
        })();
      },
    },
    {
      key: 'device_serial',
      title: ENROLL_LABELS.ACTIONS,
      dataIndex: 'device_serial',
      width: 150,
      render: (device_serial) => {
        return (function Actions() {
          return (
            <div className="actions-btn">
              {/* <Button
                shape="circle"
                className="edit-btn"
                onClick={() => {
                  handleModeType({
                    activeMode: MODE_TYPES.EDIT,
                    device_serial,
                  });
                }}
              >
                <FormOutlined type="primary" className="edit-icon" />
              </Button> */}

              <Link to={`/v2/sockets/${device_serial}`}>
                <Button
                  shape="circle"
                  className="edit-btn"
                  style={{ marginLeft: '0.5rem' }}
                >
                  <EyeOutlined type="primary" className="preview-icon" />
                </Button>
              </Link>

              <Popconfirm
                title={<>{`Are you sure you want to delete this device?`}</>}
                onConfirm={() => deleteItem(device_serial)}
              >
                <Button
                  shape="circle"
                  className="delete-btn"
                  style={{ marginLeft: '0.5rem' }}
                >
                  <DeleteOutlined type="primary" className="delete-icon" />
                </Button>
              </Popconfirm>
            </div>
          );
        })();
      },
    },
  ];

  const bulkTableColumns = [
    {
      key: 'device_serial',
      title: ENROLL_LABELS.DEVICE_SERIAL,
      dataIndex: 'device_serial',
    },
    {
      key: 'morning_driver_name',
      title: ENROLL_LABELS.MORNING_DRIVER_NAME,
      dataIndex: 'morning_driver_name',
    },
    {
      key: 'sim_serial',
      title: ENROLL_LABELS.SIM_SERIAL,
      dataIndex: 'sim_serial',
    },
    {
      key: 'is_device_active',
      title: ENROLL_LABELS.ACTIVE,
      dataIndex: 'is_device_active',
      render: (is_active) => {
        return (function Actions() {
          return <div>{is_active ? <p>Active</p> : <p>Inactive</p>}</div>;
        })();
      },
    },
    {
      key: '_id',
      title: ENROLL_LABELS.ACTIONS,
      dataIndex: '_id',
      render: (_id) => {
        return (function Actions() {
          return (
            <div className="actions-btn" key={_id}>
              <Button
                danger
                shape="circle"
                className="close-btn"
                onClick={() => removeDeviceFromSelection(_id)}
              >
                <CloseOutlined
                  type="primary"
                  className="close-icon"
                  twoToneColor="#ff4d4f"
                />
              </Button>
            </div>
          );
        })();
      },
    },
  ];

  const removeDeviceFromSelection = (selectedId) => {
    const newSelectedRowKeys = selectedRowKeys.filter(
      (deviceId) => deviceId !== selectedId
    );
    const newSelectedDeviceDetails = selectedDeviceDetails.filter(
      (device) => device._id !== selectedId
    );
    const newSelectedDeviceSerialNumbers = newSelectedDeviceDetails.map(
      (item) => item.device_serial
    );
    setSelectedRowKeys(newSelectedRowKeys);
    setSelectedDeviceDetails(newSelectedDeviceDetails);
    setSelectedDeviceSerialNumber(newSelectedDeviceSerialNumbers);
    if (newSelectedRowKeys.length <= 0) {
      setShowBulkActionsRecords(false);
    }
  };

  const handleBulkActionRequest = () => {
    setIsFetching(true);
    const url = URLS?.DEVICES?.BULK_ACTION_STATUS_CHANGE;
    const data = {
      devices: selectedDeviceSerialNumber,
      status: selectedBulkAction,
    };
    httpPut(url, data)
      .then((res) => {
        if (res.status === 200) {
          showSuccessMsg('Device status updated successfully!');
          fetchEnrollDevices(orderPagination);
        } else if (res?.error) {
          showErrorMsg(res?.error);
        }
        setIsFetching(false);
        setSelectedRowKeys([]);
        setSelectedDeviceDetails([]);
        setSelectedDeviceSerialNumber([]);
      })
      .catch((err) => {
        showErrorMsg(err);
        setIsFetching(false);
      });
  };

  const handelOnCancelBulkRequest = () => {
    setShowBulkActionsRecords(false);
    setSelectedRowKeys([]);
    setSelectedDeviceDetails([]);
    setSelectedDeviceSerialNumber([]);
  };

  const hasSelected = selectedRowKeys.length > 0;

  const onSelectedRowKeysChange = (selectedRowKey, selectedRowItem) => {
    setSelectedRowKeys(selectedRowKey);
    setSelectedDeviceDetails(selectedRowItem);
    if (selectedRowItem && selectedRowItem.length >= 1) {
      const getSelectedDeviceSerialNumbers = selectedRowItem.map(
        (item) => item.device_serial
      );
      setSelectedDeviceSerialNumber(getSelectedDeviceSerialNumbers);
    }
  };

  const handleBulkActionMenuClick = (selectedBulkItem) => {
    setSelectedBulkAction(selectedBulkItem);
    setShowBulkActionsRecords(true);
  };

  const handleBulkIssueCommandToDevice = () => {
    setShowBulkActionsRecords(false);
    handleBulkActionRequest();
  };

  const menu = (
    <Menu>
      {DEVICE_BULK_ACTION_LIST_V2 &&
        DEVICE_BULK_ACTION_LIST_V2.length > 0 &&
        DEVICE_BULK_ACTION_LIST_V2.map((item) => (
          <Menu.Item
            key={item.value}
            icon={React.createElement(item.icon)}
            onClick={() => handleBulkActionMenuClick(item.value)}
          >
            {item.label}
          </Menu.Item>
        ))}
    </Menu>
  );

  const deleteItem = (selectedDeviceSerial) => {
    setIsFetching(true);
    const data = {
      device_serial: selectedDeviceSerial,
      is_deleted: true,
    };
    updateDeviceDetails(data);
  };

  const onChangeActiveInactive = (selectedDeviceSerial, value) => {
    setIsFetching(true);
    const data = {
      device_serial: selectedDeviceSerial,
      is_device_active: value,
    };
    updateDeviceDetails(data);
  };

  const updateDeviceDetails = (data) => {
    const url = URLS_V2?.DEVICES?.UPDATE_DEVICE;
    httpPut(url, data)
      .then((res) => {
        if (res?.status === 200) {
          showSuccessMsg('Device status updated successfully!');
          fetchEnrollDevices(orderPagination);
        } else {
          showErrorMsg(
            'System is unable to complete request. Please try again later.'
          );
        }
        setIsFetching(false);
      })
      .catch((err) => {
        setIsFetching(false);
      });
  };

  const returnExpandableRow = (record) => {
    return (
      <Row gutter={[12, 24]}>
        <Col className="gutter-row" span={12}>
          <strong>{'Morning Driver Name: '}</strong>
          {record?.morning_driver_name || '-'}
        </Col>
        <Col className="gutter-row" span={12}>
          <strong>{'Morning Driver Number: '}</strong>
          {record?.morning_driver_mobile || '-'}
        </Col>
        <Col className="gutter-row" span={12}>
          <strong>{'Evening Driver Name: '}</strong>
          {record?.evening_driver_name || '-'}
        </Col>
        <Col className="gutter-row" span={12}>
          <strong>{'Evening Driver Number: '}</strong>
          {record?.evening_driver_mobile || '-'}
        </Col>
        <Col className="gutter-row" span={12}>
          <strong>{'Status: '}</strong>
          <Popconfirm
            title={
              <>{`Are you sure you want to update device status to ${
                record.is_device_active ? 'Inactive' : 'Active'
              }?`}</>
            }
            onConfirm={() =>
              onChangeActiveInactive(
                record.device_serial,
                !record.is_device_active
              )
            }
          >
            <Switch
              checked={record.is_device_active}
              className={`${
                record.is_device_active ? 'bg-success' : 'bg-danger'
              }`}
              checkedChildren="Active"
              unCheckedChildren="Inactive"
            />
          </Popconfirm>
        </Col>
      </Row>
    );
  };

  const handleTextSearch = (e) => {
    setSearchParam({
      ...searchParam,
      [e.target.name]: e.target.value,
    });
  };

  const handlePaginationChange = (pagination, filters, sorter) => {
    const { current, pageSize } = pagination;
    const tempSearchPagination = {
      ...orderPagination,
      skip: current * pageSize - pageSize,
      limit: pageSize,
      order: sorter.order === 'descend' ? -1 : 1,
      orderBy: sorter.field,
    };
    setOrderPagination(tempSearchPagination);
    fetchEnrollDevices(tempSearchPagination);
  };

  const handleExport = async () => {
    handleCSVData([]);
    setIsFetchingCSV(true);
    const url = `${URLS_V2?.DEVICES?.DEVICES_LIST}`;
    httpGet(url)
      .then((res) => {
        if (res?.status === 200) {
          handleCSVData(res?.data || []);
          ref.current.click();
          showSuccessMsg('Data exported successfully!');
        } else {
          showErrorMsg(
            'System is unable to complete request. Please try again later.'
          );
        }
        setIsFetchingCSV(false);
      })
      .catch((err) => {
        showErrorMsg(
          'System is unable to complete request. Please try again later.'
        );
        setIsFetchingCSV(false);
      });
  };

  const defaultTitle = () => {
    return (
      <div className="cus-table-right-btn">
        {`Showing Records: ${devicesList.length} / Total Records: ${totalDevices}`}
      </div>
    );
  };

  return (
    <>
      <CommonBreadCrumb breadCrumbs={breakCrumbList} />
      <div className="while-bg-box cus-table-header">
        <h3 className="table-title">Devices</h3>
        <div className="cus-table-right-btn">
          <div className="export-generate-btn">
            <Form.Item>
              <CSVLink
                data={CSVDownload}
                headers={headers}
                filename={'device-list.csv'}
              >
                <span ref={ref}></span>
              </CSVLink>
              <Button
                type="primary"
                onClick={handleExport}
                loading={isFetchingCSV}
                icon={<DownloadOutlined />}
              >
                {isFetchingCSV ? 'Exporting Data' : 'Export'}
              </Button>
            </Form.Item>
          </div>
          <div className="bulk-action-btn">
            <Dropdown
              overlay={menu}
              disabled={!hasSelected}
              trigger={['click']}
            >
              <Button>
                {'Bulk Actions'} <DownOutlined />
              </Button>
            </Dropdown>
          </div>
        </div>
      </div>
      <div className="while-bg-box">
        <Form className="main-filter-form">
          <Row gutter={[16, 16]}>
            <Col md={8}>
              <Form.Item name="device_serial" style={{ marginBottom: '0' }}>
                <Input
                  type="text"
                  name="device_serial"
                  onChange={debounce((event) => handleTextSearch(event))}
                  placeholder="Search Device Serial"
                />
              </Form.Item>
            </Col>
            <Col md={8}>
              <Form.Item name="sim_serial" style={{ marginBottom: '0' }}>
                <Input
                  type="text"
                  name="sim_serial"
                  onChange={debounce((event) => handleTextSearch(event))}
                  placeholder="Search Sim Serial"
                />
              </Form.Item>
            </Col>
            <Col md={8} align="end" justify="end">
              <Form.Item name="taxi_number" style={{ marginBottom: '0' }}>
                <Input
                  type="text"
                  name="taxi_number"
                  onChange={debounce((event) => handleTextSearch(event))}
                  placeholder="Search Taxi Number"
                />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </div>
      {selectedDeviceDetails.length >= 1 && showBulkActionsRecords && (
        <CommonListTableWithButton
          tableTitle={'Confirm Device status change to ' + selectedBulkAction}
          columns={bulkTableColumns}
          dataSource={selectedDeviceDetails}
          submitCallBack={handleBulkIssueCommandToDevice}
          cancelCallBack={() => handelOnCancelBulkRequest()}
        />
      )}
      <div className="main-table-body-cus">
        <Table
          title={defaultTitle}
          loading={{
            indicator: <div className="table-loader">Fetching...</div>,
            spinning: isFetching,
          }}
          rowSelection={{
            selectedRowKeys,
            preserveSelectedRowKeys: true,
            onChange: onSelectedRowKeysChange,
          }}
          expandable={{
            expandedRowRender: (record) => returnExpandableRow(record),
          }}
          columns={columns}
          dataSource={devicesList || []}
          size="small"
          onChange={handlePaginationChange}
          pagination={{
            defaultPageSize: 100,
            total: totalDevices,
          }}
        />
      </div>
      {activeMode && deviceSerial && (
        <DeviceModal
          closeModal={handleCloseDeviceModal}
          docId={deviceSerial}
          activeMode={activeMode}
          callback={handleDeviceModalCallback}
        />
      )}
    </>
  );
};

export default SocketTestList;

const ENROLL_LABELS = {
  DEVICE_SERIAL: 'Device Serial',
  MORNING_DRIVER_NAME: 'Morning Driver Name',
  MORNING_DRIVER_MOBILE: 'Morning Driver Number',
  EVENING_DRIVER_NAME: 'Evening Driver Name',
  EVENING_DRIVER_MOBILE: 'Evening Driver Number',
  SIM_SERIAL: 'Sim Serial',
  TAXI_NUMBER: 'Taxi Number',
  UPDATED_AT: 'Updated At',
  ACTIVE: 'Status',
  ACTIONS: 'Actions',
};

const headers = [
  { label: ENROLL_LABELS.DEVICE_SERIAL, key: ENROLL_LABELS.DEVICE_SERIAL },
  {
    label: ENROLL_LABELS.MORNING_DRIVER_NAME,
    key: ENROLL_LABELS.MORNING_DRIVER_NAME,
  },
  {
    label: ENROLL_LABELS.MORNING_DRIVER_MOBILE,
    key: ENROLL_LABELS.MORNING_DRIVER_MOBILE,
  },
  {
    label: ENROLL_LABELS.EVENING_DRIVER_NAME,
    key: ENROLL_LABELS.EVENING_DRIVER_NAME,
  },
  {
    label: ENROLL_LABELS.EVENING_DRIVER_MOBILE,
    key: ENROLL_LABELS.EVENING_DRIVER_MOBILE,
  },
  { label: ENROLL_LABELS.SIM_SERIAL, key: ENROLL_LABELS.SIM_SERIAL },
  { label: ENROLL_LABELS.TAXI_NUMBER, key: ENROLL_LABELS.TAXI_NUMBER },
  { label: ENROLL_LABELS.ACTIVE, key: ENROLL_LABELS.ACTIVE },
];
