import React, { useState } from 'react';
import { oneOfType, func, array, bool, string, object } from 'prop-types';
import {
  Row,
  Col,
  Collapse,
  Button,
  Form,
  Input,
  // Upload,
  Divider,
  Checkbox,
  Popconfirm,
  Spin,
} from 'antd';
import {
  DeleteOutlined,
  EyeOutlined,
  LoadingOutlined,
  MinusCircleOutlined,
  PlusOutlined,
  UploadOutlined,
} from '@ant-design/icons';
import { CollapseBox } from './_group-details-and-media.styled';
import { useParams } from 'react-router-dom';
import {
  // getUserAuthTokenLocalStorage,
  httpDelete,
  httpPut,
  showErrorMsg,
  showSuccessMsg,
  URLS,
} from 'utils';
import Preview from '../preview';
import { MEDIA_TYPES } from 'constants/campaign';

const { TextArea } = Input;
const { Panel } = Collapse;

const GroupDetailsAndMedia = (props) => {
  const { form, selectedCampaignType } = props;

  const params = useParams();

  // const authToken = getUserAuthTokenLocalStorage();

  const [modalViewType, setModalViewType] = useState('');
  const [previewImage, setPreviewImage] = useState('');
  const [previewVideo, setPreviewVideo] = useState('');
  const [isMediaUploading, setIsMediaUploading] = useState(undefined);

  const closeModal = () => {
    setModalViewType(false);
    setPreviewImage('');
    setPreviewVideo('');
  };

  const normFile = (e) => {
    if (Array.isArray(e)) {
      return e;
    }
    return e?.fileList;
  };

  // const videoUploadDraggerProps = {
  //   name: 'files',
  //   method: 'PUT',
  //   action: URLS?.CAMPAIGN?.UPLOAD_GROUP_ADS_MEDIA_VIDEO.replace(
  //     '#ID#',
  //     params?.id
  //   ),
  //   headers: {
  //     authorization: `Bearer ${authToken || ''}`,
  //   },
  //   beforeUpload: (file) => {
  //     let isValidImage = true;

  //     if (
  //       file.type !== 'video/mp4' &&
  //       file.type !== 'video/mpeg' &&
  //       file.type !== 'video/ogg' &&
  //       file.type !== 'video/quicktime' &&
  //       file.type !== 'video/webm' &&
  //       file.type !== 'video/x-m4v' &&
  //       file.type !== 'video/ms-asf' &&
  //       file.type !== 'video/x-msvideo' &&
  //       file.type !== 'video/x-ms-wmv'
  //     ) {
  //       isValidImage = false;
  //       showErrorMsg(
  //         'Only mp4, mpeg, ogg, quicktime, webm, x-m4v, ms-asf, x-msvideo file types are accepted'
  //       );
  //     }
  //     return isValidImage;
  //   },
  //   onChange: (info) => {
  //     if (info.file.status === 'done') {
  //       showSuccessMsg('File uploaded successfully');
  //     } else if (info.file.status === 'error') {
  //       showErrorMsg('File count not uploaded, please try again later');
  //     }
  //   },
  //   onPreview: async (file) => {
  //     if (file?.response?.data) {
  //       setModalViewType(MEDIA_TYPES.VIDEO);
  //       setPreviewVideo(file?.response?.data);
  //     }
  //   },
  //   onRemove: (file) => {
  //     if (file?.response?.data) {
  //       handleDeleteFile({
  //         fileUrl: file?.response?.data,
  //         type: MEDIA_TYPES.VIDEO,
  //       });
  //     }
  //   },
  // };

  const handleDeleteFile = (fileUrl, type, fieldKeyName, fieldName) => {
    setIsMediaUploading({ [fieldName + fieldKeyName]: true });
    const url = URLS?.CAMPAIGN?.DELETE_GC_FILE.replace('#ID#', params?.id)
      .replace('#FILE_URL#', encodeURIComponent(fileUrl))
      .replace('#FILE_TYPE#', type);
    httpDelete(url)
      .then((res) => {
        if (res.status === 200) {
          showSuccessMsg(
            `${
              type === MEDIA_TYPES.IMAGE ? 'Thumbnail' : 'Video'
            } file deleted successfully!`
          );
          form.setFields([
            {
              name: ['group_ads', fieldKeyName, fieldName],
              value: undefined,
            },
          ]);
        } else if (res?.error) {
          showErrorMsg(res?.error);
        }
        setIsMediaUploading({ [fieldName + fieldKeyName]: false });
      })
      .catch((err) => {
        showErrorMsg(err);
        setIsMediaUploading({ [fieldName + fieldKeyName]: false });
      });
  };

  const handleMediaPreview = (fileUrl, type) => {
    if (type === MEDIA_TYPES.IMAGE) {
      setPreviewImage(fileUrl);
    } else {
      setPreviewVideo(fileUrl);
    }
    setModalViewType(type);
  };

  const handleMediaThumbnail = async (fileObject, fieldKeyName, fieldName) => {
    let isSuccess = true;
    const file = fileObject.target.files[0];
    if (
      file.type !== 'image/jpg' &&
      file.type !== 'image/jpeg' &&
      file.type !== 'image/png'
    ) {
      showErrorMsg('Only jpg, jpeg, png file types are accepted.');
      return;
    }

    if (isSuccess) {
      setIsMediaUploading({ [fieldName + fieldKeyName]: true });
      const url = URLS?.CAMPAIGN?.UPLOAD_GROUP_AD_BG_IMAGE.replace(
        '#ID#',
        params?.id
      );
      const formData = new FormData();
      formData.append('files', file);
      httpPut(url, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
        .then((res) => {
          if (res.status === 200) {
            showSuccessMsg('Thumbnail saved successfully!');
            form.setFields([
              {
                name: ['group_ads', fieldKeyName, fieldName],
                value: res?.data?.media_image_url,
              },
            ]);
          } else if (res?.error) {
            showErrorMsg(res?.error);
          }
          setIsMediaUploading({ [fieldName + fieldKeyName]: false });
        })
        .catch((err) => {
          showErrorMsg(err);
          setIsMediaUploading({ [fieldName + fieldKeyName]: false });
        });
    } else {
      showErrorMsg('Internal server error. Please try again later!');
    }
  };

  const handleMediaVideoFile = async (fileObject, fieldKeyName, fieldName) => {
    let isSuccess = true;
    const file = fileObject.target.files[0];

    if (
      file.type !== 'video/mp4' &&
      file.type !== 'video/mpeg' &&
      file.type !== 'video/ogg' &&
      file.type !== 'video/quicktime' &&
      file.type !== 'video/webm' &&
      file.type !== 'video/x-m4v' &&
      file.type !== 'video/ms-asf' &&
      file.type !== 'video/x-msvideo' &&
      file.type !== 'video/x-ms-wmv'
    ) {
      showErrorMsg(
        'Only mp4, mpeg, ogg, quicktime, webm, x-m4v, ms-asf, x-msvideo file types are accepted'
      );
      return;
    }

    if (isSuccess) {
      setIsMediaUploading({ [fieldName + fieldKeyName]: true });
      const url = URLS?.CAMPAIGN?.UPLOAD_GROUP_ADS_MEDIA_VIDEO.replace(
        '#ID#',
        params?.id
      );
      const formData = new FormData();
      formData.append('files', file);
      httpPut(url, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
        .then((res) => {
          if (res.status === 200) {
            showSuccessMsg('Video saved successfully!');
            form.setFields([
              {
                name: ['group_ads', fieldKeyName, fieldName],
                value: res?.data,
              },
            ]);
          } else if (res?.error) {
            showErrorMsg(res?.error);
          }
          setIsMediaUploading({ [fieldName + fieldKeyName]: false });
        })
        .catch((err) => {
          showErrorMsg(err);
          setIsMediaUploading({ [fieldName + fieldKeyName]: false });
        });
    } else {
      showErrorMsg('Internal server error. Please try again later!');
    }
  };

  const renderLoadingButton = () => {
    return (
      <Button type="primary" disabled className="loader-btn">
        <Spin
          indicator={
            <LoadingOutlined
              style={{
                fontSize: 16,
                marginRight: 10,
                color: '#808080b8',
              }}
              spin
            />
          }
        />
        Loading...
      </Button>
    );
  };

  const renderPreviewMediaButton = (
    fileUrl,
    fileType,
    fieldNameKey,
    fieldName
  ) => {
    return (
      <>
        <Button
          type="primary"
          className="w-80"
          onClick={() => handleMediaPreview(fileUrl, fileType)}
        >
          <EyeOutlined />
          {`Preview ${fileType}`}
        </Button>
        <Popconfirm
          title="Sure to delete file?"
          onConfirm={() =>
            handleDeleteFile(fileUrl, fileType, fieldNameKey, fieldName)
          }
        >
          <Button
            danger
            shape="circle"
            htmlType="submit"
            className="w-10 m-l-5"
          >
            <DeleteOutlined />
          </Button>
        </Popconfirm>
      </>
    );
  };

  return (
    <CollapseBox>
      {modalViewType && (
        <Preview
          handleCancel={closeModal}
          modalViewType={modalViewType}
          handleOk={closeModal}
          selectMediaImage={previewImage}
          selectMediaVideo={previewVideo}
          selectedCampaignType={selectedCampaignType}
        />
      )}
      <Collapse defaultActiveKey={['1']}>
        <Panel header="Group Media Details" key="1">
          {/* <Row gutter={16}>
            <Col className="gutter-row" xs={24} sm={24}> */}
          <Form.List
            name="group_ads"
            rules={[
              () => ({
                validator: async (_, group_ads) => {
                  if (!group_ads || group_ads.length < 3) {
                    return Promise.reject(
                      new Error('At least 3 group ads media required!')
                    );
                  } else {
                    return Promise.resolve();
                  }
                },
              }),
            ]}
          >
            {(fields, { add, remove }) => (
              <>
                {fields.map(({ key, name, ...restField }) => (
                  <div key={name}>
                    <Divider orientation="left" plain>{`Group Ad ${
                      name + 1
                    }`}</Divider>
                    <Row gutter={16}>
                      <Col xs={22} sm={11} md={11}>
                        <Form.Item
                          {...restField}
                          name={[name, 'title']}
                          rules={[
                            {
                              required: true,
                              message: 'Title is required!',
                            },
                          ]}
                        >
                          <Input placeholder="Title" />
                        </Form.Item>
                      </Col>
                      <Col xs={22} sm={11} md={11}>
                        <Form.Item
                          {...restField}
                          name={[name, 'description']}
                          rules={[
                            {
                              required: true,
                              message: 'Please enter description!',
                            },
                          ]}
                        >
                          <TextArea rows={2} placeholder="Description" />
                        </Form.Item>
                      </Col>
                      <Col xs={22} sm={8} md={8}>
                        <Form.Item
                          {...restField}
                          name={[name, 'redirect_url']}
                          rules={[
                            {
                              required: true,
                              message: 'Redirect URL is required!',
                            },
                          ]}
                        >
                          <Input placeholder="Redirect URL" />
                        </Form.Item>
                      </Col>
                      <Col xs={22} sm={7} md={7}>
                        <Form.Item
                          {...restField}
                          name={[name, 'duration']}
                          rules={[
                            {
                              required: true,
                              message: 'Please enter duration!',
                            },
                            () => ({
                              validator(_, value) {
                                if (value) {
                                  if (value > 0) {
                                    return Promise.resolve();
                                  } else {
                                    return Promise.reject(
                                      new Error(
                                        'Duration must be greater than 0'
                                      )
                                    );
                                  }
                                }
                                return Promise.resolve();
                              },
                            }),
                          ]}
                        >
                          <Input
                            type="number"
                            placeholder="Duration (in seconds)"
                          />
                        </Form.Item>
                      </Col>
                      <Col xs={22} sm={6} md={6}>
                        <Form.Item
                          {...restField}
                          name={[name, 'is_default']}
                          valuePropName="checked"
                          rules={[
                            () => ({
                              validator(_, value) {
                                const getGroupAdsFieldsValue =
                                  form.getFieldValue('group_ads');
                                const isDefaultChecked =
                                  getGroupAdsFieldsValue.filter(
                                    (item) => item?.is_default
                                  );
                                if (
                                  isDefaultChecked &&
                                  isDefaultChecked?.length > 1
                                ) {
                                  return Promise.reject(
                                    new Error(
                                      'Only 1 group ad must be default!'
                                    )
                                  );
                                } else {
                                  return Promise.resolve();
                                }
                              },
                            }),
                          ]}
                        >
                          <Checkbox>Is Default</Checkbox>
                        </Form.Item>
                      </Col>
                      <Col xs={22} sm={11} md={11}>
                        <Form.Item
                          {...restField}
                          name={[name, 'thumbnail']}
                          valuePropName="fileList"
                          rules={[
                            {
                              required: true,
                              message: 'Thumbnail is required!',
                            },
                          ]}
                          getValueFromEvent={normFile}
                        >
                          {isMediaUploading?.[`thumbnail${name}`] ? (
                            renderLoadingButton()
                          ) : (
                            <>
                              {form.getFieldValue('group_ads')?.[name]
                                ?.thumbnail ? (
                                <>
                                  {renderPreviewMediaButton(
                                    form.getFieldValue('group_ads')?.[name]
                                      ?.thumbnail,
                                    MEDIA_TYPES.IMAGE,
                                    name,
                                    'thumbnail'
                                  )}
                                </>
                              ) : (
                                <>
                                  <label htmlFor="thumbnail">
                                    <div className="ant-btn">
                                      <UploadOutlined />
                                      {`  Click to upload Thumbnail`}
                                    </div>
                                    <input
                                      id="thumbnail"
                                      type="file"
                                      name="thumbnail"
                                      style={{ display: 'none' }}
                                      onChange={(fileObj) =>
                                        handleMediaThumbnail(
                                          fileObj,
                                          name,
                                          'thumbnail'
                                        )
                                      }
                                      accept="image/x-png,image/jpeg"
                                    />
                                  </label>
                                </>
                              )}
                            </>
                          )}
                        </Form.Item>
                      </Col>
                      <Col xs={22} sm={11} md={11}>
                        <Form.Item
                          {...restField}
                          name={[name, 'media_url']}
                          valuePropName="fileList"
                          rules={[
                            {
                              required: true,
                              message: 'Media is required!',
                            },
                          ]}
                          getValueFromEvent={normFile}
                        >
                          {isMediaUploading?.[`media_url${name}`] ? (
                            renderLoadingButton()
                          ) : (
                            <>
                              {form.getFieldValue('group_ads')?.[name]
                                ?.media_url ? (
                                <>
                                  {renderPreviewMediaButton(
                                    form.getFieldValue('group_ads')?.[name]
                                      ?.media_url,
                                    MEDIA_TYPES.VIDEO,
                                    name,
                                    'media_url'
                                  )}
                                </>
                              ) : (
                                <>
                                  <label htmlFor="media_url">
                                    <div className="ant-btn">
                                      <UploadOutlined />
                                      {`  Click to upload Video`}
                                    </div>
                                    <input
                                      id="media_url"
                                      type="file"
                                      name="media_url"
                                      style={{ display: 'none' }}
                                      onChange={(fileObj) =>
                                        handleMediaVideoFile(
                                          fileObj,
                                          name,
                                          'media_url'
                                        )
                                      }
                                      accept="video/mp4',video/mpeg',video/ogg',video/quicktime',video/webm',video/x-m4v',video/ms-asf',video/x-msvideo',video/x-ms-wmv'"
                                    />
                                  </label>
                                </>
                              )}
                            </>
                          )}
                        </Form.Item>
                      </Col>
                      {/* <Col xs={22} sm={11} md={11}>
                        <Form.Item
                          {...restField}
                          name={[name, 'media_urls']}
                          valuePropName="fileList"
                          rules={[
                            {
                              required: true,
                              message: 'Media is required!',
                            },
                          ]}
                          getValueFromEvent={normFile}
                        >
                          <Upload
                            name="media_url"
                            listType="picture"
                            maxCount={1}
                            {...videoUploadDraggerProps}
                          >
                            <Button icon={<UploadOutlined />}>
                              Click to upload
                            </Button>
                          </Upload>
                        </Form.Item>
                      </Col> */}
                      <MinusCircleOutlined
                        color="#ff4d4f"
                        onClick={() => remove(name)}
                      />
                    </Row>
                  </div>
                ))}
                <Form.Item>
                  <Button
                    type="dashed"
                    onClick={() => add()}
                    block
                    icon={<PlusOutlined />}
                  >
                    Add Group Media
                  </Button>
                </Form.Item>
              </>
            )}
          </Form.List>
          {/* </Col>
          </Row> */}
        </Panel>
      </Collapse>
    </CollapseBox>
  );
};

GroupDetailsAndMedia.propTypes = {
  form: oneOfType([object, array]),
  handleValidateFieldNames: func,
  isGroupAdsImageUpdating: bool,
  selectGroupAdImage: string,
  selectedCampaignType: string,
  setIsGroupAdsImageUpdating: func,
  setSelectGroupAdImage: func,
  setValidateFieldsName: func,
  validateFieldsName: oneOfType([array]),
};

export default GroupDetailsAndMedia;
