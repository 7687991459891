import React, { useState, useEffect } from 'react';
import { Button, Breadcrumb, Table, Popconfirm, Spin } from 'antd';
import {
  httpDelete,
  httpGet,
  URLS,
  showErrorMsg,
  showSuccessMsg,
  updateFirebaseGlobalAppSettings,
} from 'utils';
import { ReadingBox, Loader } from './_reading.styled';
import {
  // FormOutlined,
  DeleteOutlined,
  LoadingOutlined,
} from '@ant-design/icons';
import moment from 'moment';
import ReadingModal from 'components/reading-modal';
import { GLOBAL_APP_SETTINGS_KEYS, MODE_TYPES } from 'constants/common';
import { READING_LANGUAGE_LIST } from 'constants/reading';

const Reading = () => {
  const [isFetchingReadingList, setIsFetchingReadingList] = useState(false);
  const [deletingReadingId, setDeletingReadingId] = useState('');
  const [readingList, setReadingList] = useState([]);
  const [activeMode, setActiveMode] = useState();
  const [readingId, setReadingId] = useState();

  useEffect(() => {
    fetchReadingList();
  }, []);

  const fetchReadingList = () => {
    setIsFetchingReadingList(true);
    const url = URLS?.READING?.READINGS_LIST;
    httpGet(url)
      .then((res) => {
        if (res?.status === 200) {
          if (res?.data && res?.data.length > 0) {
            const readingListData = res?.data.map((data) => {
              return {
                ...data,
                key: data?._id,
              };
            });
            setReadingList(readingListData || []);
          }
        }
        setIsFetchingReadingList(false);
      })
      .catch(() => {
        setIsFetchingReadingList(false);
      });
  };

  const handleModeType = ({ activeMode, readingId }) => {
    setReadingId(readingId || '');
    setActiveMode(activeMode);
  };

  const closeActiveModeModal = () => {
    setActiveMode('');
  };

  const handleReadingCallback = () => {
    fetchReadingList();
    setReadingId('');
  };

  const deleteReading = ({ id }) => {
    setDeletingReadingId(id);
    const url = URLS?.READING.DELETE_READING.replace('#ID#', id);
    httpDelete(url)
      .then((res) => {
        if (res.status === 200) {
          updateFirebaseGlobalAppSettings(GLOBAL_APP_SETTINGS_KEYS.read);
          showSuccessMsg('Reading deleted successfully!');
          fetchReadingList();
        } else if (res?.error) {
          showErrorMsg(res?.error);
        }
        setDeletingReadingId('');
      })
      .catch((err) => {
        showErrorMsg(err);
        setDeletingReadingId('');
      });
  };

  const columns = [
    {
      title: READING_LABELS.SR_NO,
      width: '4%',
      render: (value, item, index) => index + 1,
    },
    {
      key: 'logo',
      title: READING_LABELS.LOGO,
      dataIndex: 'logo',
      width: '10%',
      render: (logo) => {
        return (function Actions() {
          return (
            <div className="logo">
              <img src={logo} alt="logo" width="50px" />
            </div>
          );
        })();
      },
    },
    {
      key: 'name',
      title: READING_LABELS.NAME,
      dataIndex: 'name',
      width: '15%',
    },
    {
      key: 'link',
      title: READING_LABELS.LINK,
      dataIndex: 'link',
      width: '15%',
    },
    {
      key: 'category',
      title: READING_LABELS.CATEGORY,
      dataIndex: 'category',
      width: '15%',
    },
    {
      key: 'lang',
      title: READING_LABELS.LANGUAGE,
      dataIndex: 'lang',
      width: '15%',
      render: (language) => {
        const languageItem = language
          ? READING_LANGUAGE_LIST.filter((item) => item.value === language)
          : [];
        return (function Actions() {
          return languageItem && languageItem.length >= 1
            ? languageItem[0].label
            : '';
        })();
      },
    },
    {
      key: 'created_at',
      title: READING_LABELS.CREATED_AT,
      dataIndex: 'created_at',
      width: '15%',
      render: (created_at) => {
        return (function Actions() {
          return (
            <div className="created_at">
              {created_at && moment(created_at).format('Do MMMM YYYY')}
            </div>
          );
        })();
      },
    },
    {
      key: '_id',
      title: READING_LABELS.ACTIONS,
      dataIndex: '_id',
      width: '10%',
      align: 'center',
      render: (id) => {
        return (function Actions() {
          return (
            <div className="actions-btn">
              {/* <Button
                shape="circle"
                className="edit-btn"
                onClick={() => {
                  handleModeType({
                    activeMode: MODE_TYPES.EDIT,
                    readingId: id,
                  });
                }}
              >
                <FormOutlined type="primary" className="edit-icon" />
              </Button> */}
              <Popconfirm
                title="Sure to delete?"
                onConfirm={() => deleteReading({ id })}
                disabled={deletingReadingId === id}
              >
                <Button
                  shape="circle"
                  className="delete-btn"
                  disabled={deletingReadingId === id}
                >
                  {deletingReadingId === id ? (
                    <Spin indicator={<LoadingOutlined spin />} />
                  ) : (
                    <DeleteOutlined type="primary" className="delete-icon" />
                  )}
                </Button>
              </Popconfirm>
            </div>
          );
        })();
      },
    },
  ];

  return (
    <ReadingBox>
      <div className="header">
        <Breadcrumb>
          <Breadcrumb.Item>Content </Breadcrumb.Item>
          <Breadcrumb.Item>Reading </Breadcrumb.Item>
        </Breadcrumb>
        <div className="claim-table-header">
          <h3 className="claim-table-title"> Reading </h3>
          <Button
            type="primary"
            onClick={() => {
              handleModeType({ activeMode: MODE_TYPES.ADD });
            }}
          >
            Add New
          </Button>
        </div>
      </div>
      <div className="main-table-body">
        <Table
          loading={{
            indicator: <Loader>Fetching...</Loader>,
            spinning: isFetchingReadingList,
          }}
          columns={columns}
          dataSource={readingList || []}
          size="small"
          pagination={false}
        />
      </div>
      <ReadingModal
        activeMode={activeMode}
        closeModal={closeActiveModeModal}
        callback={handleReadingCallback}
        readingId={readingId}
      />
    </ReadingBox>
  );
};

export default Reading;

const READING_LABELS = {
  SR_NO: 'Sr. No.',
  LOGO: 'Logo',
  NAME: 'Name',
  LINK: 'Link',
  CATEGORY: 'Category',
  LANGUAGE: 'Language',
  CREATED_AT: 'Created At',
  ACTIONS: 'Actions',
};
