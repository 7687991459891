import { DownloadOutlined, EyeOutlined } from '@ant-design/icons';
import { Button, Col, Form, Input, Row, Select, Table, } from 'antd';
import React, { useEffect, useState } from 'react';
import { CSVLink } from 'react-csv';
import { Link } from 'react-router-dom';
import { dateFormatter } from 'utils';

const { Option } = Select;

const data = [
  {
    device_serial: "123455",
    taxi_number: "FX0203",
    last_update_time: 1705550927,
    _id: "Qwe3dDu4inDfb8skSw3",
    sim_serial: "12345abcde"
  },
  {
    device_serial: "3452335",
    taxi_number: "FJ1453",
    last_update_time: 1705452927,
    _id: "Qwe3dDu4inDfb8sd3S4dkSw3",
    sim_serial: "67890fgh"
  }
]

const options = [
  {
    label: "Last 24 hrs",
    value: "24_HRS"
  },
  {
    label: "Last 72 hrs",
    value: "72_HRS"
  },
  {
    label: "Last 7 days",
    value: "7_DAYS"
  },
  {
    label: "Last 15 days",
    value: "15_DAYS"
  },
  {
    label: "Last 30 days",
    value: "30_DAYS"
  },
]

const DeviceIssueReport = () => {
  const [CSVDownload, setCSVDownload] = useState([]);
  const ref = React.useRef(null);
  const [isFetchingCSV, setIsFetchingCSV] = useState(false);

  const columns = [
    {
      key: 'device_serial',
      title: REPORTS_LABEL.DEVICE_SERIAL,
      dataIndex: 'device_serial',
    },
    {
      key: 'taxi_number',
      title: REPORTS_LABEL.TAXI_NUMBER,
      dataIndex: 'taxi_number',
    },
    {
      key: 'sim_serial',
      title: REPORTS_LABEL.SIM_SERIAL,
      dataIndex: 'sim_serial',
    },
    {
      key: 'last_update_time',
      title: REPORTS_LABEL.LAST_UPDATE_TIME,
      dataIndex: 'last_update_time',
      render: (value) => {
        return (function Actions() {
          return (
            <div className="created_at">
              {value && dateFormatter(value, 'Do MMMM YYYY')}
            </div>
          );
        })();
      },
    },
    {
      key: 'device_serial',
      title: REPORTS_LABEL.ACTION,
      dataIndex: 'device_serial',
      render: (id) => {
        return (function Actions() {
          return (
            <div className="actions-btn">
              <Link to={`/v2/devices/${id}`}>
                <Button
                  shape="circle"
                  className="edit-btn"
                  style={{ marginLeft: '0.5rem' }}
                >
                  <EyeOutlined type="primary" className="preview-icon" />
                </Button>
              </Link>
            </div>
          );
        })();
      },
    },
  ]

  const handleCSVData = (devicesData) => {
    let csvData = [];
    devicesData.forEach((data) => {
      const excelStructureData = {
        [REPORTS_LABEL.DEVICE_SERIAL]: data?.device_serial || '',
        [REPORTS_LABEL.TAXI_NUMBER]: data?.taxi_number || '',
        [REPORTS_LABEL.SIM_SERIAL]:data.sim_serial || "",
        [REPORTS_LABEL.LAST_UPDATE_TIME]: data?.last_update_time ? dateFormatter(data?.last_update_time, "'Do MMMM YYYY'") : '',
      };
      csvData.push(excelStructureData);
    });
    setCSVDownload(csvData);
  };

  const handleExport = () => {
    // handleCSVData(data || []);
    ref.current.click();
  }

  useEffect(() => {
    handleCSVData(data);
  }, [])

  return (
    <div>
      <div className="while-bg-box cus-table-header">
        <h3 className="table-title">Device Issue Report</h3>
        <div className="cus-table-right-btn">
          <div className="export-generate-btn">
            <Form.Item>
              <CSVLink
                data={CSVDownload}
                headers={headers}
                filename={'rides.csv'}
              >
                <span ref={ref}></span>
              </CSVLink>
              <Button
                type="primary"
                onClick={handleExport}
                // onClick={showModal}
                loading={isFetchingCSV}
                icon={<DownloadOutlined />}
              >
                {isFetchingCSV ? 'Exporting Data' : 'Export'}
              </Button>
            </Form.Item>
          </div>
        </div>
      </div>
      <div className='while-bg-box' >
        <Form>
          <Row gutter={[16, 16]}  >
            <Col md={6}>
              <Form.Item name="device_serial" style={{ marginBottom: '0' }}>
                <Input
                  type="text"
                  name="device_serial"
                  // onChange={handleSearch}
                  placeholder="Search Devices Serial"
                />
              </Form.Item>
            </Col>
            <Col md={6}>
              <Form.Item name="taxi_number" style={{ marginBottom: '0' }}>
                <Input
                  type="text"
                  name="taxi_number"
                  // onChange={handleSearch}
                  placeholder="Search Taxi Number"
                />
              </Form.Item>
            </Col>
            <Col md={6}>
              <Form.Item name="sim_serial" style={{ marginBottom: '0' }}>
                <Input
                  type="text"
                  name="sim_serial"
                  // onChange={handleSearch}
                  placeholder="Search Sim Serial"
                />
              </Form.Item>
            </Col>
            <Col md={6}>
              <Form.Item name="campaign_type" style={{ marginBottom: '0' }}>
                <Select
                  showSearch
                  allowClear
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    option.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                  placeholder="Select Time Period"
                >
                  {options?.map((item) => (
                    <Option key={item?.value} value={item?.value}>
                      {item?.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </div>

      <div>
        <Table
          //eslint-disable-next-line
          // dataSource={(searchedResults?.length && ( filterValues.device_serial || filterValues.taxi_number )) ? searchedResults : ( filterValues?.device_serial || filterValues?.taxi_number ) ? searchedResults : dataSource}
          dataSource={data}
          columns={columns}
        // onChange={handleOnChange}
        // loading={{
        //   indicator: <Loader className="table-loader">Fetching...</Loader>,
        //   spinning: isFetching,
        // }}
        scroll={{ x: 400 }}
        />;
      </div>
    </div>
  )
}

const REPORTS_LABEL = {
  DEVICE_SERIAL: 'Device Serial',
  SIM_SERIAL: "Sim Serial",
  TAXI_NUMBER: 'Taxi Number',
  LAST_UPDATE_TIME: "Last Update Time",
  ACTION: "Action"
};

const headers = [
  { label: REPORTS_LABEL.DEVICE_SERIAL, key: REPORTS_LABEL.DEVICE_SERIAL },
  {
    label: REPORTS_LABEL.TAXI_NUMBER,
    key: REPORTS_LABEL.TAXI_NUMBER,
  },
  {
    label: REPORTS_LABEL.LAST_UPDATE_TIME,
    key: REPORTS_LABEL.LAST_UPDATE_TIME,
  },
  {
    label: REPORTS_LABEL.SIM_SERIAL,
    key: REPORTS_LABEL.SIM_SERIAL,
  },
];

export default DeviceIssueReport;