import styled from 'styled-components';

export const DevicesMapBox = styled.div`
  .title {
    font-weight: 600;
    font-size: 34px;
    color: #364257;
    margin-bottom: 20px;
  }
  .map-box {
    margin: 10px;
  }
  .table-header {
    margin: 10px;
    background: white;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 15px;
    border-radius: 4px;
    overflow: hidden;
    h3 {
      margin-bottom: 0px;
    }
    .table-right-side {
      display: flex;
      .export-generate-btn {
        .ant-form-item {
          margin-bottom: 0px;
        }
      }
    }
  }
`;
