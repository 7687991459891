import React, { useState, useEffect } from 'react';
import moment from 'moment';
import { uuid } from 'uuidv4';
import {
  Form,
  Row,
  Col,
  Select,
  Input,
  Button,
  Popconfirm,
  InputNumber,
} from 'antd';
import CompanyModal from 'components/company-modal';
import { useHistory, useParams, useLocation } from 'react-router-dom';
import {
  CAMPAIGN_TYPES_LIST,
  CAMPAIGN_TYPES,
  INDUSTRY_TYPES_LIST,
  CAMPAIGN_CLASSES_LIST,
  MODE_OF_COMMUNICATION,
} from 'constants/campaign';
import {
  AddEditCampaignBox,
  QRCodeBox,
  QrCodeDownloadBox,
} from './_add-edit-campaign.styled';
import {
  httpDelete,
  httpGet,
  httpPost,
  httpPut,
  URLS,
  showErrorMsg,
  showSuccessMsg,
  updateFirebaseGlobalAppSettings,
} from 'utils';
import WhereToTarget from './where-to-target';
import Audience from './audience';
import TimeSlots from './time-slots';
import Media from './media';
import Communication from './communication';
import AllotedSpots from './alloted-spots';
import { DownloadOutlined } from '@ant-design/icons';
import validUrl from 'valid-url';
import { GLOBAL_APP_SETTINGS_KEYS } from 'constants/common';
import GroupAdsDetails from './group-ads-details';
import GroupDetailsAndMedia from './group-details-and-media';
// import { DAYS } from 'constants/campaign';

const QRCode = require('qrcode.react');

const { Option } = Select;

// const specificDayStructure = {
//   [DAYS.MONDAY.toLowerCase()]: { from: '', to: '' },
//   [DAYS.TUESDAY.toLowerCase()]: { from: '', to: '' },
//   [DAYS.WEDNESDAY.toLowerCase()]: { from: '', to: '' },
//   [DAYS.THURSDAY.toLowerCase()]: { from: '', to: '' },
//   [DAYS.FRIDAY.toLowerCase()]: { from: '', to: '' },
//   [DAYS.SATURDAY.toLowerCase()]: { from: '', to: '' },
//   [DAYS.SUNDAY.toLowerCase()]: { from: '', to: '' },
// };

const AddEditCampaign = () => {
  const [validateFieldsName, setValidateFieldsName] = useState([]);
  const [companiesList, setCompaniesList] = useState([]);
  const [timeSlots, setTimeSlots] = useState([]);
  const [isCompaniesListFetching, setIsCompaniesListFetching] = useState(false);
  const [isCompanyModalVisible, setIsCompanyModalVisible] = useState(false);
  const [isCustomFrequency, setIsCustomFrequency] = useState(false);
  const [isCompanyAdding, setIsCompanyAdding] = useState(false);
  const [isCampaignAdding, setIsCampaignAdding] = useState(false);
  const [isCampaignUpdating, setIsCampaignUpdating] = useState(false);
  const [isMediaImageUpdating, setIsMediaImageUpdating] = useState(false);
  const [isMediaVideoUpdating, setIsMediaVideoUpdating] = useState(false);
  const [isGroupAdsImageUpdating, setIsGroupAdsImageUpdating] = useState(false);
  const [isTemplateUpdating, setIsTemplateUpdating] = useState(false);
  const [modeOfCommunication, setModeOfCommunication] = useState(
    MODE_OF_COMMUNICATION.EMAIL
  );
  const [mobileText, setMobileText] = useState('');
  const [isFetchingCampaign, setIsFetchingCampaign] = useState(false);
  const [form] = Form.useForm();
  const [companyForm] = Form.useForm();
  const history = useHistory();
  const params = useParams();
  const routeLocation = useLocation();
  const [locations, setLocations] = useState([]);
  const [selectedCampaignType, setSelectedCampaignType] = useState('');
  const [selectedClassCampaign, setSelectedClassCampaign] = useState('');
  const [selectMediaImage, setSelectMediaImage] = useState('');
  const [selectMediaVideo, setSelectMediaVideo] = useState('');
  const [selectGroupAdImage, setSelectGroupAdImage] = useState('');
  const [selectTemplate, setSelectTemplate] = useState('');
  const [campaignStartEndDate, setCampaignStartEndDate] = useState('');
  const [isCampaignSavingAsDraft, setisCampaignSavingAsDraft] = useState(false);
  const [active, setActive] = useState(false);
  const [draft, setDraft] = useState();
  const [isCampaignDeleting, setIsCampaignDeleting] = useState();
  const [isCampaignDeactivating, setIsCampaignDeactivating] = useState();

  const allowRedirectURLInput = [
    CAMPAIGN_TYPES.WEBSITE_VISITS,
    CAMPAIGN_TYPES.BRAND_AWARENESS,
  ];

  const allowAutoClickInput = [CAMPAIGN_TYPES.BRAND_AWARENESS];

  useEffect(() => {
    const { originalCampaignId = '' } = routeLocation?.state || {};
    if (originalCampaignId) {
      window.onbeforeunload = function () {
        return true;
      };
    }
    return () => {
      if (originalCampaignId) {
        window.onbeforeunload = null;
      }
    };
  }, []);

  useEffect(() => {
    fetchCompanies();
    const { originalCampaignId = '' } = routeLocation?.state || {};
    if (originalCampaignId) {
      fetchDuplicateOriginalCampaignData({ id: params?.id });
    } else {
      if (params?.id) {
        fetchCampaign({ id: params?.id });
      } else {
        setSelectedCampaignType(CAMPAIGN_TYPES.BRAND_AWARENESS);
        form.setFieldsValue({
          campaign_type: CAMPAIGN_TYPES.BRAND_AWARENESS,
        });
      }
    }
  }, []);

  const fetchCampaign = ({ id } = {}) => {
    setIsFetchingCampaign(true);
    const url = URLS?.CAMPAIGN?.GET_CAMPAIGN_BY_ID.replace('#ID#', id);
    httpGet(url)
      .then((res) => {
        if (res.status === 200) {
          if (
            res?.data &&
            Object.entries(res?.data) &&
            Object.entries(res?.data).length > 0
          ) {
            Object.entries(res?.data).forEach(([key, value]) => {
              if (key === 'campaign_type') {
                setSelectedCampaignType(value);
              }
              if (key === 'campaign_class') {
                setSelectedClassCampaign(value);
              }
              if (key === 'is_active') {
                setDraft(value);
              }
              if (key === 'campaign_start_date') {
                const dateValue = (value && moment(value)) || '';
                if (dateValue) {
                  form.setFieldsValue({
                    campaign_start_date: [dateValue],
                  });
                }
              }
              if (key === 'campaign_end_date') {
                const dateValue = (value && moment(value)) || '';
                if (dateValue) {
                  form.setFieldsValue({
                    campaign_end_date: [dateValue],
                  });
                }
              }
              if (key === 'per_ride_frequency' && value && value > 0) {
                setIsCustomFrequency(true);
              }
              if (key === 'mode_of_communication') {
                setModeOfCommunication(value);
              }
              if (key === 'mobile_text') {
                setMobileText(value);
              }
              if (key === 'media_image_url') {
                setSelectMediaImage(value);
              }
              if (key === 'media_video_url') {
                setSelectMediaVideo(value);
              }
              if (key === 'template_url') {
                setSelectTemplate(value);
              }
              if (key === 'time_slots' && value) {
                setTimeSlots(value);
              }
              if (key === 'group_background_image_url') {
                setSelectGroupAdImage(value);
              }
              if (key === 'locations') {
                let newValues = [];
                if (value && value.length > 0) {
                  newValues = value.map((data) => {
                    return { ...data, identifier: uuid() };
                  });
                }
                setLocations(newValues || []);
              } else {
                form.setFieldsValue({
                  [key]: value,
                });
              }
            });
          }
        } else if (res?.error) {
          showErrorMsg(res?.error);
        }
        const getCampaignStartValue =
          form && form.getFieldValue('campaign_start_date');
        const getCampaignEndValue =
          form && form.getFieldValue('campaign_end_date');
        if (getCampaignStartValue && getCampaignEndValue) {
          setCampaignStartEndDate([
            moment(getCampaignStartValue),
            moment(getCampaignEndValue),
          ]);
        }
        setIsFetchingCampaign(false);
      })
      .catch((err) => {
        showErrorMsg(err);
        setIsFetchingCampaign(false);
      });
  };

  const fetchDuplicateOriginalCampaignData = ({ id } = {}) => {
    setIsFetchingCampaign(true);
    const url = `${URLS?.CAMPAIGN?.GET_CAMPAIGN_BY_ID.replace(
      '#ID#',
      id
    )}?isOriginal=true`;
    httpGet(url)
      .then((res) => {
        if (res.status === 200) {
          if (
            res?.data &&
            Object.entries(res?.data) &&
            Object.entries(res?.data).length > 0
          ) {
            Object.entries(res?.data).forEach(([key, value]) => {
              if (key === 'campaign_type') {
                setSelectedCampaignType(value);
              }
              if (key === 'campaign_class') {
                setSelectedClassCampaign(value);
              }
              if (key === 'is_active') {
                setDraft(value);
              }
              if (key === 'campaign_start_date') {
                const dateValue = (value && moment(value)) || '';
                if (dateValue) {
                  form.setFieldsValue({
                    campaign_start_date: [dateValue],
                  });
                }
              }
              if (key === 'campaign_end_date') {
                const dateValue = (value && moment(value)) || '';
                if (dateValue) {
                  form.setFieldsValue({
                    campaign_end_date: [dateValue],
                  });
                }
              }
              if (key === 'per_ride_frequency' && value && value > 0) {
                setIsCustomFrequency(true);
              }
              if (key === 'mode_of_communication') {
                setModeOfCommunication(value);
              }
              if (key === 'mobile_text') {
                setMobileText(value);
              }
              if (key === 'media_image_url') {
                setSelectMediaImage(value);
              }
              if (key === 'media_video_url') {
                setSelectMediaVideo(value);
              }
              if (key === 'template_url') {
                setSelectTemplate(value);
              }
              if (key === 'time_slots' && value) {
                setTimeSlots(value);
              } else {
                form.setFieldsValue({
                  [key]: value,
                });
              }
            });
          }
          if (
            res?.originalCampaignDetails &&
            Object.entries(res?.originalCampaignDetails) &&
            Object.entries(res?.originalCampaignDetails).length > 0
          ) {
            Object.entries(res?.originalCampaignDetails).forEach(
              ([key, value]) => {
                if (key === 'group_background_image_url') {
                  setSelectGroupAdImage(value);
                }
                if (key === 'locations') {
                  let newValues = [];
                  if (value && value.length > 0) {
                    newValues = value.map((data) => {
                      return { ...data, identifier: uuid() };
                    });
                  }
                  setLocations(newValues || []);
                }
                if (key === 'group_ads') {
                  form.setFieldsValue({
                    group_ads: value,
                  });
                }
              }
            );
          }
        } else if (res?.error) {
          showErrorMsg(res?.error);
        }
        const getCampaignStartValue =
          form && form.getFieldValue('campaign_start_date');
        const getCampaignEndValue =
          form && form.getFieldValue('campaign_end_date');
        if (getCampaignStartValue && getCampaignEndValue) {
          setCampaignStartEndDate([
            moment(getCampaignStartValue),
            moment(getCampaignEndValue),
          ]);
        }
        setIsFetchingCampaign(false);
      })
      .catch((err) => {
        showErrorMsg(err);
        setIsFetchingCampaign(false);
      });
  };

  const fetchCompanies = ({ company_id } = {}) => {
    setIsCompaniesListFetching(true);
    httpGet(URLS.COMPANY.COMPANIES_LIST)
      .then((res) => {
        if (res.status === 200) {
          setCompaniesList(res?.data || []);
          if (company_id) form.setFieldsValue({ company_id });
        } else if (res?.error) {
          showErrorMsg(res?.error);
        }
        setIsCompaniesListFetching(false);
      })
      .catch((err) => {
        showErrorMsg(err);
        setIsCompaniesListFetching(false);
      });
  };

  const handleValidateFieldNames = (name) => {
    const isFieldName =
      validateFieldsName &&
      validateFieldsName.find((fieldName) => fieldName === name);
    if (isFieldName) return 'onChange';
    return 'onBlur';
  };

  const openCompanyModal = () => {
    setIsCompanyModalVisible(true);
  };

  const closeCompanyModal = () => {
    setIsCompanyModalVisible(false);
  };

  const handleAddEditCampaign = ({ isDraft, msg } = {}) => {
    setActive(!isDraft);
    form.submit();
    form
      .validateFields()
      .then(async (values) => {
        if (params?.id) {
          if (values.campaign_type == CAMPAIGN_TYPES.CAPTURE_LEADS) {
            !selectMediaVideo && !selectMediaImage && !isDraft
              ? showErrorMsg('Please Upload Media')
              : handleEditCampaign({
                  values: values,
                  active: !isDraft,
                  msg: msg,
                });
          } else if (values.campaign_type == CAMPAIGN_TYPES.BRAND_AWARENESS) {
            !selectMediaVideo && !isDraft
              ? showErrorMsg('Please Upload Media')
              : handleEditCampaign({
                  values: values,
                  active: !isDraft,
                  msg: msg,
                });
          } else if (values.campaign_type == CAMPAIGN_TYPES.WEBSITE_VISITS) {
            !selectMediaVideo && !selectMediaImage && !isDraft
              ? showErrorMsg('Please Upload Media')
              : handleEditCampaign({
                  values: values,
                  active: !isDraft,
                  msg: msg,
                });
          } else if (values.campaign_type == CAMPAIGN_TYPES.GROUP_ADS.value) {
            const { group_ads } = values;
            if (!group_ads || group_ads?.length < 3) {
              showErrorMsg('At least 3 group ads media required!');
            } else if (!selectGroupAdImage && !isDraft) {
              showErrorMsg('Please Upload Group BG Image');
            } else {
              let updatedGroupAds = group_ads?.map((item) => {
                return {
                  ...item,
                  is_default:
                    item?.is_default !== undefined ? item?.is_default : false,
                };
              });
              values = { ...values, group_ads: updatedGroupAds };
              handleEditCampaign({
                values: values,
                active: !isDraft,
                msg: msg,
              });
            }
          } else if (!active) {
            handleEditCampaign({ values: values, active: !isDraft, msg: msg });
          }
        } else {
          handleAddCampaign(values, !isDraft);
        }
      })
      .catch((errorInfo) => errorInfo);
      console.log("check");
  };

  const handleEditCampaign = ({ values, active, msg } = {}) => {
    let campaignParams = {
      campaign_class: values.campaign_class || '',
      maximum_daily_slots: values?.maximum_daily_slots || 0,
      is_active: active,
      campaign_name: values?.campaign_name || '',
      campaign_type: values?.campaign_type || '',
      company_id: values?.company_id || '',
      industry_type: values?.industry_type || '',
      slots: values?.slots || 0,
      per_ride_frequency: values?.per_ride_frequency || 1,
      duration: values?.duration || 0,
      locations,
      redirect_url: values?.redirect_url || '',
      auto_click_frequency: values?.auto_click_frequency || 0,
      gender: values?.gender || '',
      age: values?.age || '',
      language: values?.language || '',
      is_every_day: false, // not implemented yet todo
      mode_of_communication: modeOfCommunication,
      mobile_text: values?.mobile_text,
      time_slots: timeSlots || [],
      media_image_url: selectMediaImage || '',
      media_video_url: selectMediaVideo || '',
      template_url: selectTemplate || '',
      campaign_start_date: '',
      campaign_end_date: '',
      group_ads: values?.group_ads || [],
      group_description: values?.group_description || '',
      group_title: values?.group_title || '',
      group_background_image_url: selectGroupAdImage || '',
    };
    if (campaignStartEndDate && campaignStartEndDate.length > 1) {
      campaignParams = {
        ...campaignParams,
        campaign_start_date: campaignStartEndDate[0].toDate(),
        campaign_end_date: campaignStartEndDate[1].toDate(),
      };
    }
    if (active === true) {
      setIsCampaignUpdating(true);
    } else {
      setisCampaignSavingAsDraft(true);
    }
    const url = URLS?.CAMPAIGN?.UPDATE_CAMPAIGN.replace('#ID#', params?.id);
    httpPut(url, campaignParams)
      .then((res) => {
        if (res.status === 200) {
          showSuccessMsg(msg);
          updateFirebaseGlobalAppSettings(GLOBAL_APP_SETTINGS_KEYS.campaign);
        } else if (res?.error) {
          showErrorMsg(res?.error);
        }
        setIsCampaignUpdating(false);
        setisCampaignSavingAsDraft(false);
      })
      .catch((err) => {
        showErrorMsg(err);
        setIsCampaignUpdating(false);
        setisCampaignSavingAsDraft(false);
      });
  };

  const handleAddCampaign = (values, active) => {
    const campaignParams = {
      campaign_class: values.campaign_class || '',
      is_active: active,
      campaign_name: values?.campaign_name || '',
      campaign_type: values?.campaign_type || '',
      company_id: values?.company_id || '',
      industry_type: values?.industry_type || '',
      slots: values?.slots || 0,
      maximum_daily_slots: values?.maximum_daily_slots || 0,
      per_ride_frequency: values?.per_ride_frequency || 1,
      duration: values?.duration || 0,
      redirect_url: values?.redirect_url || '',
      auto_click_frequency: values?.auto_click_frequency || 0,
    };
    setIsCampaignAdding(true);
    httpPost(URLS?.CAMPAIGN?.ADD_CAMPAIGN, campaignParams)
      .then((res) => {
        if (res.status === 200) {
          showSuccessMsg('Campaign added successfully!');
          closeCompanyModal();
          form.resetFields();
          updateFirebaseGlobalAppSettings(GLOBAL_APP_SETTINGS_KEYS.campaign);
          history.push(`edit/${res?.data?._id}`);
          fetchCampaign({ id: res?.data?._id });
        } else if (res?.error) {
          showErrorMsg(res?.error);
        }
        setIsCampaignAdding(false);
      })
      .catch((err) => {
        showErrorMsg(err);
        setIsCampaignAdding(false);
      });
  };

  const handleAddCompany = () => {
    companyForm.submit();
    companyForm
      .validateFields()
      .then((data) => {
        const params = {
          company_name: data?.company_name || '',
          contact_person_name: data?.contact_person_name || '',
          email: data?.email || '',
          phone_number: data?.phone_number || '',
        };
        setIsCompanyAdding(true);
        httpPost(URLS?.COMPANY?.ADD_COMPANY, params)
          .then((res) => {
            if (res.status === 200) {
              showSuccessMsg('Company added successfully!');
              closeCompanyModal();
              companyForm.resetFields();
              fetchCompanies({ company_id: res?.data?._id });
            } else if (res?.error) {
              showErrorMsg(res?.error);
            }
            setIsCompanyAdding(false);
          })
          .catch((err) => {
            showErrorMsg(err);
            setIsCompanyAdding(false);
          });
      })
      .catch((errorInfo) => errorInfo);
  };

  const downloadCampaignQR = () => {
    const canvas = document.getElementById('campaign-qr');
    const pngUrl = canvas
      .toDataURL('image/png')
      .replace('image/png', 'image/octet-stream');
    let downloadLink = document.createElement('a');
    downloadLink.href = pngUrl;
    downloadLink.download = 'campaign-qr.png';
    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
  };

  const handleCampaignType = () => {
    setSelectedCampaignType(form && form.getFieldValue('campaign_type'));
  };
  const handleClassCampaign = () => {
    setSelectedClassCampaign(form && form.getFieldValue('campaign_class'));
  };

  const handleDeleteCampaign = () => {
    setIsCampaignDeleting(true);
    const url = URLS?.CAMPAIGN?.DELETE_CAMPAIGN_BY_ID.replace(
      '#ID#',
      params?.id
    );
    httpDelete(url)
      .then((res) => {
        if (res.status === 200) {
          showSuccessMsg('Campaign deleted successfully!');
          updateFirebaseGlobalAppSettings(GLOBAL_APP_SETTINGS_KEYS.campaign);
          history.push('/campaigns');
        } else if (res?.error) {
          showErrorMsg(res?.error);
        }
        setIsCampaignDeleting('');
      })
      .catch((err) => {
        showErrorMsg(err);
        setIsCampaignDeleting('');
      });
  };
  const handleInactiveCampaign = () => {
    setIsCampaignDeactivating(true);
    handleAddEditCampaign({
      isDraft: true,
      msg: 'Campaign deactivated successfully',
    });
    const url = URLS?.CAMPAIGN?.DELETE_BOOKEDINVENTORY_BY_CAMPAIGN_ID.replace(
      '#ID#',
      params?.id
    );
    httpDelete(url)
      .then((res) => {
        if (res.status === 200) {
          updateFirebaseGlobalAppSettings(GLOBAL_APP_SETTINGS_KEYS.campaign);
          history.push('/campaigns');
        } else if (res?.error) {
          showErrorMsg(res?.error);
        }
        setIsCampaignDeactivating('');
      })
      .catch((err) => {
        showErrorMsg(err);
        setIsCampaignDeleting('');
      });
  };

  const handleRedirectURLChange = (redirectURLValue) => {
    if (
      selectedCampaignType === CAMPAIGN_TYPES.BRAND_AWARENESS &&
      !redirectURLValue
    ) {
      form.setFieldsValue({
        auto_click_frequency: 0,
      });
    }
  };

  return (
    <AddEditCampaignBox>
      {isFetchingCampaign ? (
        'Fetching...'
      ) : (
        <Form form={form} layout="vertical">
          <Row gutter={16}>
            <Col className="gutter-row" xs={16} sm={6}>
              <Form.Item
                name="campaign_class"
                label="Campaign Class"
                rules={[
                  {
                    required: true,
                    message: 'Please select Campaign Class!',
                  },
                ]}
              >
                <Select
                  showSearch
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    option.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                  placeholder="Select Campaign Class"
                  onChange={handleClassCampaign}
                  disabled={Boolean(params?.id)}
                >
                  {CAMPAIGN_CLASSES_LIST &&
                    CAMPAIGN_CLASSES_LIST.length > 0 &&
                    CAMPAIGN_CLASSES_LIST.map((data, index) => (
                      <Option key={index} value={data}>
                        {data}
                      </Option>
                    ))}
                </Select>
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col className="gutter-row" xs={24} sm={12} md={12}>
              <Row gutter={16}>
                <Col className="gutter-row" xs={24} sm={12}>
                  <Form.Item
                    name="campaign_name"
                    label="Campaign Name"
                    validateTrigger={handleValidateFieldNames('campaign_name')}
                    rules={[
                      {
                        required: true,
                        message: 'Please enter Campaign Name!',
                      },
                    ]}
                  >
                    <Input
                      type="text"
                      placeholder="Campaign Name"
                      onBlur={() =>
                        setValidateFieldsName([
                          ...validateFieldsName,
                          'campaign_name',
                        ])
                      }
                    />
                  </Form.Item>
                </Col>
                <Col className="gutter-row" xs={24} sm={12}>
                  <div className="select-company">
                    <Form.Item
                      name="company_id"
                      label="Company"
                      rules={[
                        {
                          required: true,
                          message: 'Please select company!',
                        },
                      ]}
                    >
                      <Select
                        placeholder={
                          isCompaniesListFetching
                            ? 'Fetching...'
                            : 'Select Company'
                        }
                        showSearch
                        optionFilterProp="children"
                        filterOption={(input, option) =>
                          option.children
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                        }
                      >
                        {companiesList &&
                          companiesList.length > 0 &&
                          companiesList.map((company, index) => (
                            <Option value={company?._id} key={index}>
                              {company?.company_name || ''}
                            </Option>
                          ))}
                      </Select>
                    </Form.Item>
                    <Button
                      type="primary"
                      className="add-btn"
                      onClick={openCompanyModal}
                    >
                      Add
                    </Button>
                    <CompanyModal
                      form={companyForm}
                      isCompanyModalVisible={isCompanyModalVisible}
                      modalTitle="Add company"
                      handleOnCancel={closeCompanyModal}
                      handleAddCompany={handleAddCompany}
                      isCompanyAdding={isCompanyAdding}
                    />
                  </div>
                </Col>
                <Col className="gutter-row" xs={24} sm={12}>
                  <Form.Item
                    name="industry_type"
                    label="Industry Type"
                    rules={[
                      {
                        required: true,
                        message: 'Please select Industry Type!',
                      },
                    ]}
                  >
                    <Select
                      showSearch
                      optionFilterProp="children"
                      filterOption={(input, option) =>
                        option.children
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      }
                      placeholder="Select Industry Type"
                    >
                      {INDUSTRY_TYPES_LIST &&
                        INDUSTRY_TYPES_LIST.length > 0 &&
                        INDUSTRY_TYPES_LIST.map((data, index) => (
                          <Option key={index} value={data}>
                            {data}
                          </Option>
                        ))}
                    </Select>
                  </Form.Item>
                </Col>
                <Col className="gutter-row" xs={24} sm={12}>
                  <Form.Item
                    name="campaign_type"
                    label="Campaign Type"
                    rules={[
                      {
                        required: true,
                        message: 'Please select campaign type!',
                      },
                    ]}
                  >
                    <Select
                      showSearch
                      optionFilterProp="children"
                      filterOption={(input, option) =>
                        option.children
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      }
                      placeholder="Select Campaign Type"
                      onChange={handleCampaignType}
                      disabled={Boolean(params?.id)}
                    >
                      {CAMPAIGN_TYPES_LIST &&
                        CAMPAIGN_TYPES_LIST.length > 0 &&
                        CAMPAIGN_TYPES_LIST.map((data, index) => (
                          <Option
                            key={index}
                            value={data?.value ? data?.value : data}
                          >
                            {data?.label ? data?.label : data}
                          </Option>
                        ))}
                    </Select>
                  </Form.Item>
                </Col>
                {allowRedirectURLInput.includes(selectedCampaignType) && (
                  <Col className="gutter-row" xs={24} sm={24}>
                    <Form.Item
                      name="redirect_url"
                      label="Redirect url"
                      validateTrigger={handleValidateFieldNames('redirect_url')}
                      rules={[
                        {
                          required:
                            selectedCampaignType ===
                            CAMPAIGN_TYPES.WEBSITE_VISITS,
                          message: 'Please enter redirect url!',
                        },
                        {
                          validator(_, value) {
                            if (value && !validUrl.isUri(value)) {
                              return Promise.reject('Please enter valid url');
                            }
                            return Promise.resolve();
                          },
                        },
                      ]}
                    >
                      <Input
                        type="text"
                        placeholder="Redirect Url"
                        onChange={(event) =>
                          handleRedirectURLChange(event?.target?.value)
                        }
                        onBlur={() =>
                          setValidateFieldsName([
                            ...validateFieldsName,
                            'redirect_url',
                          ])
                        }
                      />
                    </Form.Item>
                  </Col>
                )}
                {allowAutoClickInput.includes(selectedCampaignType) &&
                  params?.id && (
                    <Col className="gutter-row" xs={24} sm={12}>
                      <Form.Item
                        name="auto_click_frequency"
                        label="Auto Click frequency"
                        validateTrigger={handleValidateFieldNames(
                          'auto_click_frequency'
                        )}
                        rules={[
                          () => ({
                            validator(_, value) {
                              if (
                                value &&
                                value < 0 &&
                                form?.getFieldValue('redirect_url')
                              ) {
                                return Promise.reject(
                                  new Error(
                                    'Auto click frequency must be greater than 0'
                                  )
                                );
                              }
                              return Promise.resolve();
                            },
                          }),
                        ]}
                      >
                        <InputNumber
                          type="number"
                          min={0}
                          max={999}
                          defaultValue={0}
                          placeholder="Auto Click frequency"
                          value={
                            !form?.getFieldValue('redirect_url')
                              ? 0
                              : form?.getFieldValue('auto_click_frequency')
                          }
                          disabled={!form?.getFieldValue('redirect_url')}
                          onBlur={() =>
                            setValidateFieldsName([
                              ...validateFieldsName,
                              'auto_click_frequency',
                            ])
                          }
                        />
                      </Form.Item>
                    </Col>
                  )}
                {!params?.id && (
                  <Col className="gutter-row" xs={24} sm={12}>
                    <Popconfirm
                      title={
                        <>
                          Campaign type and Campaign Class can not be changed
                          once created. <br /> Are you sure you want to keep
                          campaign type as {selectedCampaignType} and campaign
                          class as {selectedClassCampaign} ?
                        </>
                      }
                      onConfirm={handleAddEditCampaign}
                    >
                      <Button
                        type="primary"
                        className="save-btn"
                        disabled={isCampaignAdding}
                      >
                        {isCampaignAdding && 'Loading...'}
                        {!isCampaignAdding &&
                          selectedCampaignType !==
                            CAMPAIGN_TYPES.WEBSITE_VISITS &&
                          'Save and continue'}
                        {!isCampaignAdding &&
                          selectedCampaignType ===
                            CAMPAIGN_TYPES.WEBSITE_VISITS &&
                          'Save and generate QR code'}
                      </Button>
                    </Popconfirm>
                  </Col>
                )}
              </Row>
            </Col>
            <Col className="gutter-row" xs={24} sm={12} md={6}>
              {params?.id &&
                selectedCampaignType === CAMPAIGN_TYPES.WEBSITE_VISITS && (
                  <>
                    <QRCodeBox>
                      <QRCode
                        id="campaign-qr"
                        size={200}
                        value={
                          window.location.origin + '/campaign-qr/' + params?.id
                        }
                      />
                    </QRCodeBox>
                  </>
                )}
            </Col>
            <Col className="gutter-row" xs={24} sm={12} md={6}>
              {params?.id &&
                selectedCampaignType === CAMPAIGN_TYPES.WEBSITE_VISITS && (
                  <QrCodeDownloadBox>
                    <div>
                      <a onClick={downloadCampaignQR}>
                        <DownloadOutlined /> Download QR
                      </a>
                    </div>
                  </QrCodeDownloadBox>
                )}
            </Col>

            {params?.id && (
              <>
                {selectedCampaignType === CAMPAIGN_TYPES.GROUP_ADS.value && (
                  <>
                    <Col className="gutter-row" xs={24} sm={12}>
                      <GroupAdsDetails
                        form={form}
                        selectedCampaignType={selectedCampaignType}
                        handleValidateFieldNames={handleValidateFieldNames}
                        validateFieldsName={validateFieldsName}
                        setValidateFieldsName={setValidateFieldsName}
                        isGroupAdsImageUpdating={isGroupAdsImageUpdating}
                        setIsGroupAdsImageUpdating={setIsGroupAdsImageUpdating}
                        selectGroupAdImage={selectGroupAdImage}
                        setSelectGroupAdImage={setSelectGroupAdImage}
                      />
                    </Col>
                    <Col className="gutter-row" xs={24} sm={12}>
                      <GroupDetailsAndMedia
                        form={form}
                        selectedCampaignType={selectedCampaignType}
                        handleValidateFieldNames={handleValidateFieldNames}
                        validateFieldsName={validateFieldsName}
                        setValidateFieldsName={setValidateFieldsName}
                        isGroupAdsImageUpdating={isGroupAdsImageUpdating}
                        setIsGroupAdsImageUpdating={setIsGroupAdsImageUpdating}
                        selectGroupAdImage={selectGroupAdImage}
                        setSelectGroupAdImage={setSelectGroupAdImage}
                      />
                    </Col>
                  </>
                )}
                <Col className="gutter-row" xs={24} sm={12}>
                  <AllotedSpots
                    form={form}
                    selectedClassCampaign={selectedClassCampaign}
                    handleValidateFieldNames={handleValidateFieldNames}
                    isActive={active}
                    validateFieldsName={validateFieldsName}
                    setValidateFieldsName={setValidateFieldsName}
                    setCampaignStartEndDate={setCampaignStartEndDate}
                    campaignStartEndDate={campaignStartEndDate}
                    setIsCustomFrequency={setIsCustomFrequency}
                    isCustomFrequency={isCustomFrequency}
                  />
                </Col>
                <Col className="gutter-row" xs={24} sm={12} />
                {selectedCampaignType !== CAMPAIGN_TYPES.GROUP_ADS.value && (
                  <Col className="gutter-row" xs={24} sm={12}>
                    <Media
                      selectedCampaignType={selectedCampaignType}
                      selectMediaImage={selectMediaImage}
                      setSelectMediaImage={setSelectMediaImage}
                      isMediaImageUpdating={isMediaImageUpdating}
                      setIsMediaImageUpdating={setIsMediaImageUpdating}
                      selectMediaVideo={selectMediaVideo}
                      isMediaVideoUpdating={isMediaVideoUpdating}
                      setIsMediaVideoUpdating={setIsMediaVideoUpdating}
                      setSelectMediaVideo={setSelectMediaVideo}
                    />
                  </Col>
                )}
                {selectedCampaignType === CAMPAIGN_TYPES.CAPTURE_LEADS && (
                  <>
                    <Col className="gutter-row" xs={24} sm={12} />
                    <Col className="gutter-row" xs={24} sm={12}>
                      <Communication
                        setModeOfCommunication={setModeOfCommunication}
                        modeOfCommunication={modeOfCommunication}
                        setSelectTemplate={setSelectTemplate}
                        setIsTemplateUpdating={setIsTemplateUpdating}
                        isTemplateUpdating={isTemplateUpdating}
                        selectTemplate={selectTemplate}
                        setMobileText={setMobileText}
                        mobileText={mobileText}
                      />
                    </Col>
                  </>
                )}
                <Col className="gutter-row" xs={24} sm={12} />
                <Col className="gutter-row" xs={24} sm={24}>
                  <WhereToTarget
                    handleValidateFieldNames={handleValidateFieldNames}
                    setValidateFieldsName={setValidateFieldsName}
                    validateFieldsName={validateFieldsName}
                    locations={locations}
                    setLocations={setLocations}
                    form={form}
                  />
                </Col>
                <Col className="gutter-row" xs={24} sm={12}>
                  <Audience
                    handleValidateFieldNames={handleValidateFieldNames}
                    setValidateFieldsName={setValidateFieldsName}
                    validateFieldsName={validateFieldsName}
                    form={form}
                  />
                </Col>
                <Col className="gutter-row" xs={24} sm={12} />
                <Col className="gutter-row" xs={24} sm={24}>
                  <TimeSlots
                    handleValidateFieldNames={handleValidateFieldNames}
                    setValidateFieldsName={setValidateFieldsName}
                    validateFieldsName={validateFieldsName}
                    form={form}
                    setTimeSlots={setTimeSlots}
                    timeSlots={timeSlots}
                    campaignStartEndDate={campaignStartEndDate}
                  />
                </Col>

                <Col className="gutter-row" xs={24} sm={24}>
                  <Button
                    type="primary"
                    className="draft-btn"
                    onClick={() =>
                      handleAddEditCampaign({
                        isDraft: true,
                        msg: 'Campaign Saved as Draft!',
                      })
                    }
                    disabled={!draft || isCampaignSavingAsDraft}
                  >
                    {isCampaignSavingAsDraft
                      ? 'Saving as Draft...'
                      : 'Save as draft'}
                  </Button>
                  <Button
                    type="primary"
                    className="save-btn"
                    onClick={() =>
                      handleAddEditCampaign({
                        isDraft: false,
                        msg: 'Campaign updated successfully!',
                      })
                    }
                    disabled={isCampaignUpdating}
                  >
                    {isCampaignUpdating ? 'Loading...' : 'Active'}
                  </Button>
                  <Popconfirm
                    title={<>Do you want to deactivate this campaign ?</>}
                    onConfirm={() => handleInactiveCampaign()}
                  >
                    <Button
                      type="primary"
                      className="inactive-btn"
                      disabled={!draft || isCampaignDeactivating}
                      danger
                    >
                      {isCampaignDeactivating
                        ? 'Deactivating...'
                        : 'Deactivate'}
                    </Button>
                  </Popconfirm>
                  <Popconfirm
                    title={<>Do you want to delete this campaign ?</>}
                    onConfirm={() => handleDeleteCampaign()}
                  >
                    <Button
                      type="primary"
                      className="delete-btn"
                      disabled={isCampaignDeleting}
                      danger
                    >
                      {isCampaignDeleting ? 'Deleting...' : 'Delete'}
                    </Button>
                  </Popconfirm>
                </Col>
              </>
            )}
          </Row>
        </Form>
      )}
    </AddEditCampaignBox>
  );
};

export default AddEditCampaign;
