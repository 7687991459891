import styled from 'styled-components';

export const MainLayoutBox = styled.div`
  .logo {
    img {
      margin: 15px 0px 21px 24px;
    }
  }

  .ant-layout-sider {
    flex: 0 0 250px !important;
    max-width: 250px !important;
    min-width: 250px !important;
    width: 250px !important;
    background: #000000;
  }

  .ant-layout-sider-collapsed {
    flex: 0 0 80px !important;
    max-width: 80px !important;
    min-width: 80px !important;
    width: 80px !important;
    background: #000000;
  }

  .main-sidebar {
    box-shadow: 0px 2px 8px rgb(0 0 0 / 15%);
    min-height: 100vh;
    background: #000000;
    position:fixed !important;
  }

  .ant-menu-item {
    background: black;
    margin: 0px;
    margin-bottom: 0px !important;
    a {
      color: hsla(0, 0%, 100%, 0.8);
    }
    color: hsla(0, 0%, 100%, 0.8);
    padding: 24px 0px;

    .anticon {
      color: hsla(0, 0%, 100%, 0.8);
    }
    &:hover {
      background: grey;
      color: white;
      a {
        color: white;
      }
      .anticon {
        color: white;
      }
    }
  }

  .ant-menu-submenu {
    background: black;
    margin: 0px;
    margin-bottom: 0px !important;
    color: hsla(0, 0%, 100%, 0.8);
    padding: 0px 0px;

    .anticon {
      color: hsla(0, 0%, 100%, 0.8);
    }
    .ant-menu-submenu-title {
      margin: 0px;
      background: black;
      padding: 24px;
      .ant-menu-submenu-arrow {
        color: hsla(0, 0%, 100%, 0.8);
      }
      &:hover {
        background: grey;
        color: white;
        .anticon {
          color: white;
        }
        .ant-menu-submenu-arrow {
          color: white;
        }
      }
    }
    &:hover {
      color: white;
      .ant-menu-submenu-arrow {
        color: white;
      }
    }
  }

  .ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
    background-color: rgb(59 74 99 / 92%);
  }
`;
