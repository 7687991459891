import {
  AndroidOutlined,
  BarChartOutlined,
  BookOutlined,
  // DashboardOutlined,
  // SettingOutlined,
  CarOutlined,
  DashboardOutlined,
  GlobalOutlined,
  ReconciliationOutlined,
  TeamOutlined,
  UserOutlined,
  // UsergroupAddOutlined,
  // ControlOutlined,
} from '@ant-design/icons';

export const ASIDE_NAVIGATION = [
  {
    id: '1',
    name: 'Dashboard',
    icon: DashboardOutlined,
    link: '/dashboard',
    permissionKey: 'DASHBOARD',
  },
  // {
  //   id: '2',
  //   name: 'Android API management',
  //   icon: SettingOutlined,
  //   isSubMenu: true,
  //   permissionKey: "ANDROID_API_MANAGEMENT",
  //   subMenu: [
  //     {
  //       id: '2-1',
  //       name: 'List Enroll Devices',
  //       link: '/enroll-devices',
  //     },
  //     {
  //       id: '2-2',
  //       name: 'Enroll New Device',
  //       link: '/enroll-new-device',
  //     },
  //   ],
  // },
  // {
  //   id: '3',
  //   name: 'Devices',
  //   icon: MobileOutlined,
  //   isSubMenu: true,
  //   permissionKey: 'Devices',
  //   subMenu: [
  //     {
  //       id: '3-1',
  //       name: 'Map View',
  //       link: '/devices-map',
  //     },
  //     {
  //       id: '3-2',
  //       name: 'Devices List',
  //       link: '/devices',
  //     },
  //     {
  //       id: '3-3',
  //       name: 'Devices Status',
  //       link: '/devices-status',
  //     },
  //   ],
  // },
  // {
  //   id: '4',
  //   name: 'Campaign',
  //   icon: BookOutlined,
  //   isSubMenu: true,
  //   permissionKey: 'Campaigns',
  //   subMenu: [
  //     {
  //       id: '4-1',
  //       name: 'All Campaigns',
  //       link: '/campaigns',
  //     },
  //     {
  //       id: '4-2',
  //       name: 'Create New Campaign',
  //       link: '/campaigns/add',
  //     },
  //     {
  //       id: '4-3',
  //       name: 'Calculate Geofence',
  //       link: '/campaigns/calc-geofence',
  //     },
  //   ],
  // },
  // {
  //   id: '5',
  //   name: 'Rides',
  //   icon: CarOutlined,
  //   link: '/rides',
  //   permissionKey: 'Rides',
  // },
  // {
  //   id: '6',
  //   name: 'Ad Inventory Config',
  //   icon: SettingOutlined,
  //   link: '/ad-inventory-config',
  //   permissionKey: 'AD_INVENTORY_CONFIGURATION',
  // },
  // {
  //   id: '7',
  //   name: 'Content',
  //   icon: ContainerOutlined,
  //   isSubMenu: true,
  //   permissionKey: 'CONTENT_MANAGEMENT',
  //   subMenu: [
  //     {
  //       id: '7-1',
  //       name: 'Reading',
  //       link: '/content/reading',
  //     },
  //     {
  //       id: '7-2',
  //       name: 'Games',
  //       link: '/content/games',
  //     },
  //     {
  //       id: '7-3',
  //       name: 'Discover',
  //       link: '/content/discover',
  //     },
  //     {
  //       id: '7-4',
  //       name: 'Watch',
  //       // link: '/content/watch',
  //       isSubMenu: true,
  //       permissionKey: 'CONTENT_MANAGEMENT',
  //       subMenu: [
  //         {
  //           id: '7-4-1',
  //           name: 'Category',
  //           link: '/content/watch/category',
  //         },
  //         {
  //           id: '7-4-2',
  //           name: 'Content',
  //           link: '/content/watch',
  //         },
  //       ],
  //     },
  //     {
  //       id: '7-5',
  //       name: 'App Tiles',
  //       link: '/content/app-tiles',
  //     },
  //   ],
  // },
  {
    id: '8',
    name: 'Profile',
    icon: UserOutlined,
    link: '/profile',
  },
  // {
  //   id: '9',
  //   name: 'Device Ratings',
  //   icon: CarryOutOutlined,
  //   link: '/devices-rating',
  //   permissionKey: 'DEVICE_RATINGS',
  // },
  // {
  //   id: '10',
  //   name: 'Users',
  //   icon: UsergroupAddOutlined,
  //   link: '/users',
  //   permissionKey: 'Users',
  // },
  // {
  //   id: '11',
  //   name: 'App Settings',
  //   icon: ControlOutlined,
  //   link: '/settings',
  // },
  // {
  //   id: '13',
  //   name: 'Version 2.0 Sections',
  //   icon: BookOutlined,
  //   isSubMenu: true,
  //   permissionKey: 'V2',
  //   subMenu: [
  //     {
  //       id: '13-1',
  //       name: 'Devices',
  //       permissionKey: 'DEVICESV2',
  //       subMenu: [
  //         {
  //           id: '13-1-1',
  //           name: 'Map View',
  //           link: '/v2/devices-map',
  //           permissionKey: 'DEVICE_MAP',
  //         },
  //         {
  //           id: '13-1-2',
  //           name: 'Devices List',
  //           link: '/v2/devices',
  //           permissionKey: 'Devices',
  //         },
  //         {
  //           id: '13-1-3',
  //           name: 'Device Ratings',
  //           link: '/v2/device-ratings',
  //           permissionKey: 'DEVICE_RATINGS',
  //         },
  //       ],
  //     },
  //     // {
  //     //   id: '13-2',
  //     //   name: 'Devices',
  //     //   link: '/v2/devices',
  //     //   permissionKey: 'Devices',
  //     // },
  //     // {
  //     //   id: '13-3',
  //     //   name: 'Device Ratings',
  //     //   link: '/v2/device-ratings',
  //     //   permissionKey: 'DEVICE_RATINGS',
  //     // },
  //     {
  //       id: '13-4',
  //       name: 'Rides',
  //       link: '/v2/rides',
  //       permissionKey: 'Rides',
  //     },
  //     {
  //       id: '13-5',
  //       name: 'Campaigns',
  //       link: '/v2/campaigns',
  //       permissionKey: 'Campaigns',
  //     },
  //     {
  //       id: '13-6',
  //       name: 'App Release Manager',
  //       permissionKey: 'APP_RELEASE_MANAGER',
  //       isSubMenu: true,
  //       subMenu: [
  //         {
  //           id: '13-6-1',
  //           name: 'Lenovo App Release',
  //           link: '/v2/release-manager',
  //           permissionKey: 'APP_RELEASE_MANAGER',
  //         },
  //         {
  //           id: '13-6-2',
  //           name: 'Unisoc App Release',
  //           link: '/v2/unisoc-release-manager',
  //           permissionKey: 'APP_RELEASE_MANAGER',
  //         },
  //       ],
  //     },
  //     {
  //       id: '13-7',
  //       name: 'Roles',
  //       link: '/v2/user-roles',
  //       // permissionKey: 'V2',
  //       permissionKey: 'USER_ROLES',
  //     },
  //     {
  //       id: '13-8',
  //       name: 'Users',
  //       link: '/v2/users',
  //       permissionKey: 'Users',
  //     },
  //     {
  //       id: '13-9',
  //       name: 'Ad Delivery Report',
  //       link: '/v2/ad-delivery-report',
  //       permissionKey: 'Campaigns',
  //     },
  //   ],
  // },
  // {
    // id: '14',
    // name: 'Version 2.1.0 Sections',
    // icon: BookOutlined,
    // isSubMenu: true,
    // permissionKey: 'V2',
    // subMenu: [
      {
        id: '14-1',
        name: 'Devices',
        permissionKey: 'DEVICES',
        isSubMenu:true,
        icon:AndroidOutlined,
        subMenu: [
          {
            id: '14-1-1',
            name: 'Map View',
            link: '/v2/devices-map',
            permissionKey: 'DEVICE_MAP',
          },
          {
            id: '14-1-2',
            name: 'Devices List',
            link: '/v2/devices',
            permissionKey: 'Devices',
          },
          {
            id: '14-1-3',
            name: 'Device Ratings',
            link: '/v2/device-ratings',
            permissionKey: 'DEVICE_RATINGS',
          },
        ],
      },
      {
        id: '14-2',
        name: 'Content',
        permissionKey: 'CONTENT',
        isSubMenu:true,
        icon:BookOutlined,
        subMenu: [
          {
            id: '14-2-1',
            name: 'Reading',
            link: '/v2/content/reading',
            permissionKey: 'READING',
          },
          {
            id: '14-2-2',
            name: 'Games',
            link: '/v2/content/games',
            permissionKey: 'GAMES',
          },
          {
            id: '14-2-3',
            name: 'Discover',
            link: '/v2/content/discover',
            permissionKey: 'DISCOVER',
          },
          {
            id: '14-2-4',
            name: 'Watch',
            permissionKey: 'WATCHV2',
            isSubMenu:true,
            subMenu: [
              {
                id: '14-2-4-1',
                name: 'Category',
                link: '/v2/content/watch/category',
                permissionKey: 'CATEGORY',
              },
              {
                id: '14-2-4-2',
                name: 'Content',
                link: '/v2/content/watch/content',
                permissionKey: 'CONTENT',
              },
            ],
          },
          {
            id: '14-2-5',
            name: 'Music',
            permissionKey: 'Music',
            isSubMenu:true,
            subMenu: [
              {
                id: '14-2-5-1',
                name: 'Category',
                link: '/v2/content/music/category',
                permissionKey: 'CATEGORY',
              },
              {
                id: '14-2-5-2',
                name: 'Content',
                link: '/v2/content/music/content',
                permissionKey: 'CONTENT',
              },
            ],
          },
        ],
      },
      // {
      //   id: '13-2',
      //   name: 'Devices',
      //   link: '/v2/devices',
      //   permissionKey: 'Devices',
      // },
      // {
      //   id: '13-3',
      //   name: 'Device Ratings',
      //   link: '/v2/device-ratings',
      //   permissionKey: 'DEVICE_RATINGS',
      // },
      // {
      //   id: '13-4',
      //   name: 'Rides',
      //   link: '/v2/rides',
      //   permissionKey: 'Rides',
      // },
      // {
      //   id: '13-5',
      //   name: 'Campaigns',
      //   link: '/v2/campaigns',
      //   permissionKey: 'Campaigns',
      // },
      {
        id: '14-3',
        name: 'App Release Manager',
        permissionKey: 'APP_RELEASE_MANAGER',
        isSubMenu: true,
        icon:ReconciliationOutlined,
        subMenu: [
          {
            id: '14-3-1',
            name: 'Binary OS',
            link: '/v2/binaryos-manager',
            permissionKey: 'BINARYOS_MANAGER',
          },
          {
            id: '14-3-2',
            name: 'Binary Device Manager',
            link: '/v2/binary-device-manager',
            permissionKey: 'BINARY_DEVICE_MANAGER',
          },
          // {
          //   id: '14-3-3',
          //   name: 'Unisoc Device Manager',
          //   link: '/v2/unisoc-device-manager',
          //   permissionKey: 'UNISOC_DEVICE_MANAGER',
          // },
        ],
      },
      {
        id: '14-4',
        name: 'Campaign',
        permissionKey: 'APP_RELEASE_MANAGER',
        isSubMenu: true,
        icon:GlobalOutlined,
        subMenu: [
          // {
          //   id: '14-4-1',
          //   name: 'All Campaigns',
          //   link: '/v2/campaignlist',
          //   permissionKey: 'ALL_CAMPAIGNS',
          // },
          // {
          //   id: '14-4-2',
          //   name: 'Create New Campaign',
          //   link: '/v2/campaigns/add',
          //   permissionKey: 'CREATE_NEW_CAMPAIGN',
          // },
          {
            id: '14-4-3',
            name: 'Active Campaigns List',
            link: '/v2/campaigns/active',
            permissionKey: 'ACTIVE_CAMPAIGN',
          },
          {
            id: '14-4-4',
            name: 'In-Active Campaigns List',
            link: '/v2/campaigns/in-active',
            permissionKey: 'IN_ACTIVE_CAMPAIGN',
          },
          {
            id: '14-4-5',
            name: 'Create Campaign',
            link: '/v2/campaigns/create-campaign',
            permissionKey: 'CREATE_CAMPAIGN',
          },
        ],
      },
      {
        id: '14-5',
        name: 'Rides',
        icon: CarOutlined,
        permissionKey: 'Rides',
        isSubMenu: true,
        subMenu: [
          // {
          //   id: '14-5-1',
          //   name: 'All Rides',
          //   link: '/v2/rides',
          //   permissionKey: 'ALL_RIDES',
          // },
          {
            id: '14-5-2',
            name: 'Completed Rides',
            link: '/v2/completed-rides',
            permissionKey: 'COMPLETED_RIDES',
          }, {
            id: '14-5-3',
            name: 'Ongoing Rides',
            link: '/v2/ongoing-rides',
            permissionKey: 'ONGOING_RIDES',
          },
          {
            id: '14-5-4',
            name: 'Rides Count(48 hours)',
            link: '/v2/ride-count',
            permissionKey: 'RIDE_COUNT',
          },
        ],
      },

      {
        id: '14-6',
        name: 'Users Management',
        icon: TeamOutlined,
        permissionKey: 'Users',
        isSubMenu: true,
        subMenu: [
          {
            id: '14-6-1',
            name: 'Users',
            link: '/v2/users',
            permissionKey: 'Users',
          },
          {
            id: '14-6-2',
            name: 'Roles',
            link: '/v2/user-roles',
            permissionKey: 'USER_ROLES',
          },
          // {
          //   id: '14-6-3',
          //   name: 'Rides Count(48 hours)',
          //   link: '/v2/ride-count',
          //   permissionKey: 'RIDE_COUNT',
          // },
        // ],
      // },
      // {
      //   id: '13-7',
      //   name: 'Roles',
      //   link: '/v2/user-roles',
      //   // permissionKey: 'V2',
      //   permissionKey: 'USER_ROLES',
      // },
      // {
      //   id: '13-8',
      //   name: 'Users',
      //   link: '/v2/users',
      //   permissionKey: 'Users',
      // },
      // {
      //   id: '13-9',
      //   name: 'Ad Delivery Report',
      //   link: '/v2/ad-delivery-report',
      //   permissionKey: 'Campaigns',
      // },
    ],
  },
  {
    id: '15',
    name: 'Reports',
    permissionKey: 'REPORTS',
    isSubMenu: true,
    icon:BarChartOutlined,
    subMenu: [
      {
        id: '15-1',
        name: 'Device Issue Report',
        link: '/v2/report/devices',
        permissionKey: 'USER_ISSUE_REPORT',
      },
    ]
  }
];
