import React, { useState } from 'react';
// import { httpGet, URLS } from 'utils';
import { string, bool, func, array } from 'prop-types';
import { Button, Modal, Form, Col, Input, Row, Select } from 'antd';
const { Option } = Select;

function UserModal(props) {
  const {
    userModalVisible,
    modalTitle,
    closeUserModal,
    isUserAdding,
    handleAddInvitedUser,
    campaignsList,
    isFetching,
  } = props;
  const [validateFieldsName, setValidateFieldsName] = useState([]);
  const [campaign, setCampaign] = useState();
  // const [email, setEmail] = useState(false);
  const [form] = Form.useForm();

  const handleCampaign = (values) => {
    setCampaign(values);
  };
  const handleValidateFieldNames = (name) => {
    const isFieldName = validateFieldsName.find(
      (fieldName) => fieldName === name
    );
    if (isFieldName) return 'onChange';
    return 'onBlur';
  };
  let modalfooterButtons = [
    <Button
      key="save"
      type="primary"
      onClick={() =>
        handleAddInvitedUser({
          email: form.getFieldValue('email'),
          campaign: campaign,
        })
      }
      // disabled={isDisabled}
    >
      {isUserAdding ? 'Saving...' : 'Save'}
    </Button>,
    <Button
      key="cancel"
      onClick={closeUserModal}
      //  disabled={isDisabled}
    >
      Cancel
    </Button>,
  ];

  return (
    <div>
      {' '}
      <Modal
        title={modalTitle}
        visible={userModalVisible}
        onCancel={() => closeUserModal()}
        className="user-modal"
        maskClosable={false}
        footer={modalfooterButtons}
      >
        <Form form={form} layout="vertical">
          <Row gutter={16}>
            <Col className="gutter-row" xs={24} sm={12}>
              <Form.Item
                name="email"
                label="Email"
                validateTrigger={handleValidateFieldNames('email')}
                rules={[
                  {
                    required: true,
                    message: 'Please enter email!',
                  },
                  {
                    type: 'email',
                    message: 'Please enter email!',
                  },
                ]}
              >
                <Input
                  type="text"
                  placeholder="Email"
                  onBlur={() =>
                    setValidateFieldsName([...validateFieldsName, 'email'])
                  }
                />
              </Form.Item>
            </Col>

            <Col className="gutter-row" xs={24} sm={12}>
              <Form.Item
                name="campaign"
                label="Campaign"
                validateTrigger={handleValidateFieldNames('campaign')}
                rules={[
                  {
                    required: true,
                    message: 'Please enter campaign!',
                  },
                ]}
              >
                <Select
                  mode="multiple"
                  allowClear
                  placeholder={isFetching ? 'Fetching...' : 'Select Campaign'}
                  showSearch
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    option.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                  onChange={handleCampaign}
                >
                  {campaignsList &&
                    campaignsList.length > 0 &&
                    campaignsList.map((campaign, index) => (
                      <Option value={campaign?.key} key={index}>
                        {campaign?.campaign_name || ''}
                      </Option>
                    ))}
                </Select>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Modal>
    </div>
  );
}
UserModal.propTypes = {
  campaignsList: array,
  closeUserModal: func,
  handleAddInvitedUser: func,
  isFetching: bool,
  isUserAdding: bool,
  modalTitle: string,
  userModalVisible: bool,
};
export default UserModal;
