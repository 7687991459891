import {
  Button,
  Checkbox,
  Col,
  Form,
  Input,
  Modal,
  Popconfirm,
  Row,
  Select,
  Tag,
} from 'antd';
import React, { useEffect, useState } from 'react';
import './new-view-campaigns.css';
import {
  URLS_V2,
  httpGet,
  httpPost,
  httpPut,
  showErrorMsg,
  showSuccessMsg,
} from 'utils';
import CompanyModal from 'components/company-modal';
import SpotsDetails from '../Spots Details/SpotsDetails';
import Media from '../Media/Media';
import MapView from '../MapView/MapView';
import { useParams } from 'react-router-dom';
import moment from 'moment';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';

const NewViewCampaigns = () => {
  const history = useHistory();
  const [companyForm] = Form.useForm();
  const [RedirectUrlForm] = Form.useForm();
  const [campaignForm] = Form.useForm();
  const [mediaNSpotsForm] = Form.useForm();
  const [companyData, setCompanyData] = useState([]);
  const [isCompanyModalVisible, setIsCompanyModalVisible] = useState(false);
  const [isCompanyAdding, setIsCompanyAdding] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isChecked, setIsChecked] = useState(false);
  const [companyUpdated, setCompanyUpdated] = useState(0);
  const [industryList, setIndustryList] = useState([]);
  const [campaignList, setCampaignList] = useState([]);
  const [showAllQrField, setShowAllQrField] = useState(false);
  const [redirectFieldData, setRedirectData] = useState('');
  const [redirectedtUrl, setRedirectedUrl] = useState('');
  const [generatedUrl, setGeneratedUrl] = useState('');
  const [isGeofenceChecked, setIsGeofenceChecked] = useState(false);
  const [campaignId, setCampaignId] = useState();
  const [redirectUrlPopup, setRedirectUrlPopup] = useState(false);
  const [campaignVideoUrl, setCampaignVideoUrl] = useState('');
  const [campaignImageUrl, setCampaignImageUrl] = useState('');
  const [uploadVideoWarning, setUploadVideoWarning] = useState(false);
  const [saveBtnUpdate, setSaveBtnUpdate] = useState(false);
  const [activeBtnUpdate, setActiveBtnUpdate] = useState(false);
  const [deactivationUpdating, setDeactivationUpdating] = useState(false);
  const [mediaForm, setMediaForm] = useState({
    campaign_video_duration: '',
    campaign_image_duration: '',
    campagin_video_volume: '80',
  });
  const [spotsForm, setSpotsForm] = useState({
    frequency_per_ride: '1',
    allotted_slots: '',
    campaign_start_date: '',
    campaign_end_date: '',
  });
  const [selectedOpt, setSelectedOpt] = useState([]);

  const [createCampaignForm, setCreateCampaignForm] = useState({
    campaign_name: '',
    campaign_company_id: '',
    campaign_industry_id: '',
    campaign_type_id: '',
  });

  const [qrData, setQrData] = useState('');
  const [apiData, setApiData] = useState({});
  const [editModeOff, setEditModeOff] = useState(true);

  const { id } = useParams();

  const getCampaignsData = async () => {
    try {
      const res = await httpGet(
        URLS_V2?.CAMPAIGNS?.GET_CAMPAIGNS_DETAIL.replace('#ID#', id)
      );
      setApiData(res.data);
    } catch (error) {
      showErrorMsg('Could Not Fetch The Data');
    }
  };

  useEffect(() => {
    if (id) {
      getCampaignsData();
    }
  }, []);

  const getRandomColor = () => {
    const letters = '0123456789ABCDEF';
    let color = '#';
    for (let i = 0; i < 6; i++) {
      color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
  };

  useEffect(() => {
    campaignForm.setFieldsValue({
      campaign_name: apiData?.campaign_name,
      campaign_company_id: apiData?.campaign_company?.company_name,
      campaign_industry_id: apiData?.campaign_industry?.industry_name,
      campaign_type_id: apiData?.campaign_type?.campaign_type_name,
    });

    setSpotsForm({
      frequency_per_ride: apiData?.per_ride_frequency,
      allotted_slots: apiData?.allotted_slots,
      campaign_start_date: apiData?.campaign_start_date || '',
      campaign_end_date: apiData?.campaign_end_date || '',
    });
    mediaNSpotsForm.setFieldsValue({
      'Maximum Alloted Slots': apiData?.allotted_slots,
      frequency_per_ride: apiData?.per_ride_frequency,
      'Campaign Start and End Date': [
        apiData?.campaign_start_date
          ? moment(apiData?.campaign_start_date * 1000)
          : '',
        apiData?.campaign_end_date
          ? moment(apiData?.campaign_end_date * 1000)
          : '',
      ],
    });
    setIsGeofenceChecked(!!apiData?.is_geofence_available);

    setMediaForm({
      campaign_video_duration: apiData?.campaign_video?.campaign_video_duration,
      campaign_image_duration: apiData?.campaign_image?.campaign_image_duration,
      campagin_video_volume: apiData?.campaign_video?.campagin_video_volume,
    });
    setCampaignImageUrl(apiData?.campaign_image?.campaign_image_url);
    setCampaignVideoUrl(apiData?.campaign_video?.campaign_video_url);
    setCampaignId(apiData?._id);

    if (apiData?.geo_fences_list?.length) {
      setSelectedOpt(
        apiData?.geo_fences_list?.map((item) => ({
          ...item,
          color: getRandomColor(),
        }))
      );
    }

    if (apiData?.is_redirect_url_available) {
      setRedirectedUrl(apiData?.is_redirect_url?.original_redirect_url);
      setGeneratedUrl(apiData?.is_redirect_url?.generated_redirect_url);
      setShowAllQrField(true);
      setQrData(apiData?.is_redirect_url?.generated_redirect_url);
      setIsChecked(apiData?.is_redirect_url_available);
    }
  }, [apiData]);

  const downloadQrCode = () => {
    const canvas = document.getElementById('Campaign-QrCode');

    const largerSize = 400;
    const largerCanvas = document.createElement('canvas');
    largerCanvas.width = largerSize;
    largerCanvas.height = largerSize;

    const largerContext = largerCanvas.getContext('2d');
    largerContext.drawImage(canvas, 0, 0, largerSize, largerSize);

    const pngUrl = largerCanvas.toDataURL('image/png', 1.0);
    let downloadLink = document.createElement('a');
    downloadLink.href = pngUrl;
    downloadLink.download = 'campaign-qr.png';
    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);

    // =========== Original Code  ===========

    // const canvas = document.getElementById('Campaign-QrCode');
    // const pngUrl = canvas
    //   .toDataURL('image/png')
    //   .replace('image/png', 'image/octet-stream');
    // let downloadLink = document.createElement('a');
    // downloadLink.href = pngUrl;
    // downloadLink.download = 'campaign-qr.png';
    // document.body.appendChild(downloadLink);
    // downloadLink.click();
    // document.body.removeChild(downloadLink);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
    RedirectUrlForm.resetFields();
    let redirectDataIndicator = '';
    setRedirectData(redirectDataIndicator);
    if (redirectDataIndicator.length < 1) {
      setIsChecked(false);
    } else {
      setIsChecked(true);
    }
  };
  const onRedirectUrlFinish = () => {
    if (redirectFieldData?.length > 0) {
      const redirectUrl = {
        redirect_url: redirectFieldData,
      };
      httpPost(URLS_V2?.CAMPAIGNS?.GENERATE_QR, redirectUrl)
        .then((res) => {
          if (res?.status === 201) {
            setIsModalOpen(false);
            setIsChecked(true);
            setQrData(res?.data?.generated_redirect_url || '');
            setShowAllQrField(true);
            setRedirectedUrl(res?.data?.original_redirect_url);
            setGeneratedUrl(res?.data?.generated_redirect_url);
          } else {
            showErrorMsg(res?.message);
          }
        })
        .catch((err) => {
          showErrorMsg(err?.message);
        });
    } else {
      showErrorMsg('Redirect Url Cannot be Empty');
    }
  };

  const QRCode = require('qrcode.react');

  let modalfooterButtons = [
    <Button
      key="save"
      type="primary"
      htmlType="submit"
      onClick={onRedirectUrlFinish}
      //   disabled={isDisabled}
    >
      Save
      {/* {isCompanyAdding ? 'Saving...' : 'Save'} */}
    </Button>,
    <Button key="cancel" onClick={handleCancel}>
      Cancel
    </Button>,
  ];

  const openCompanyModal = () => {
    setIsCompanyModalVisible(true);
  };
  const onFinish = (values) => {
    httpPost(URLS_V2?.CAMPAIGNS?.CREATE_CAMPAIGN, values)
      .then((res) => {
        if (res?.status === 201) {
          showSuccessMsg('Campaign Created Successfully');
          setCampaignId(res?.data?._id);
        } else {
          showErrorMsg(res?.message);
        }
      })
      .catch((err) => {
        showErrorMsg(err?.message);
      });
  };

  const filterOption = (input, option) =>
    (option?.label ?? '').toLowerCase().includes(input.toLowerCase());

  useEffect(() => {
    httpGet(URLS_V2?.CAMPAIGNS?.COMPANIES_LIST)
      .then((res) => {
        if (res.status === 200) {
          const companies = res?.data?.map((val) => {
            return { value: val?._id, label: val?.company_name };
          });
          setCompanyData(companies);
        }
        // else if (res?.error) {
        // //   showErrorMsg(res?.error);
        // }
      })
      .catch((err) => {
        showErrorMsg(err.message);
      });
  }, [companyUpdated]);

  useEffect(() => {
    httpGet(URLS_V2?.CAMPAIGNS?.GET_INDUSTRY_CAMPAIGN)
      .then((res) => {
        if (res.status === 200) {
          const industryData = res?.data?.map((val) => {
            return {
              label: val?.industry_title,
              value: val?._id,
            };
          });
          setIndustryList(industryData);
        }
        // else if (res?.error) {
        // //   showErrorMsg(res?.error);
        // }
      })
      .catch((err) => {
        showErrorMsg(err.message);
      });
  }, []);

  useEffect(() => {
    httpGet(URLS_V2?.CAMPAIGNS?.GET_CAMPAIGN_TYPE)
      .then((res) => {
        if (res.status === 200) {
          const campaignTypes = res?.data?.map((val) => {
            return {
              label: val?.campaign_type_title,
              value: val?._id,
            };
          });
          setCampaignList(campaignTypes);
        }
        // else if (res?.error) {
        // //   showErrorMsg(res?.error);
        // }
      })
      .catch((err) => {
        showErrorMsg(err.message);
      });
  }, []);
  const closeCompanyModal = () => {
    setIsCompanyModalVisible(false);
  };

  const handleAddCompany = () => {
    companyForm.submit();
    companyForm
      .validateFields()
      .then((data) => {
        const params = {
          company_name: data?.company_name || '',
          contact_person_name: data?.contact_person_name || '',
          email: data?.email || '',
          phone_number: data?.phone_number || '',
        };
        setIsCompanyAdding(true);
        httpPost(URLS_V2?.CAMPAIGNS?.ADD_NEW_COMPANY, params)
          .then((res) => {
            if (res.status === 201) {
              showSuccessMsg('Company added successfully!');
              closeCompanyModal();
              companyForm.resetFields();
              setCompanyUpdated(companyUpdated + 1);
            } else if (res?.error) {
              showErrorMsg(res?.error);
            } else {
              showErrorMsg(res?.message);
            }
            setIsCompanyAdding(false);
          })
          .catch((err) => {
            showErrorMsg(err);
            setIsCompanyAdding(false);
          });
      })
      .catch((errorInfo) => errorInfo);
  };

  const onchangeCampaignName = (e) => {
    setCreateCampaignForm({
      ...createCampaignForm,
      campaign_name: e.target.value,
    });
  };
  const onchangeCompany = (value) => {
    setCreateCampaignForm({
      ...createCampaignForm,
      campaign_company_id: value,
    });
  };
  const onchangeIndustry = (value) => {
    setCreateCampaignForm({
      ...createCampaignForm,
      campaign_industry_id: value,
    });
  };
  const onchangeCampaignType = (value) => {
    setCreateCampaignForm({ ...createCampaignForm, campaign_type_id: value });
  };

  // ================== Popup =============

  const showPopupconfirm = () => {
    if (!isChecked) {
      setIsModalOpen(true);
    }
    setIsChecked(!isChecked);
    if (generatedUrl?.length !== 0) {
      setRedirectUrlPopup(true);
    }
  };

  const popupHandleOk = () => {
    setRedirectedUrl('');
    setGeneratedUrl('');
    setQrData('');
    setIsChecked(false);
    setRedirectUrlPopup(false);
    setShowAllQrField(false);
    setRedirectData('');
    RedirectUrlForm.resetFields();
  };

  const popupHandleCancel = () => {
    setRedirectUrlPopup(false);
    setIsChecked(true);
  };

  const showGeofenceCampaign = () => {
    setIsGeofenceChecked(!isGeofenceChecked);
  };

  const SaveForLater = (type) => {
    setSaveBtnUpdate(true);
    if (
      parseInt(mediaForm?.campagin_video_volume) < 0 ||
      parseInt(mediaForm?.campagin_video_volume) > 100
    ) {
      showErrorMsg('Video Volume Should be between 0-100');
      setSaveBtnUpdate(false);
      return;
    }

    let startDate =
      typeof spotsForm.campaign_start_date === 'string'
        ? moment(spotsForm?.campaign_start_date, 'YYYY-MM-DD hh:mm a').unix()
        : spotsForm.campaign_start_date;

    let endDate =
      typeof spotsForm.campaign_end_date === 'string'
        ? moment(spotsForm?.campaign_end_date, 'YYYY-MM-DD hh:mm a').unix()
        : spotsForm.campaign_end_date;

    let data = {
      // is_active: false,
      campaign_status: 'DRAFT',
      per_ride_frequency: `${spotsForm?.frequency_per_ride}`,
      allotted_slots:
        spotsForm?.allotted_slots == 0 ? '' : `${spotsForm?.allotted_slots}`,
      campaign_start_date: startDate ? `${startDate}` : '',
      campaign_end_date: endDate ? `${endDate}` : '',
      is_redirect_url_available: `${isChecked}`,
      redirect_url: {
        original_redirect_url: generatedUrl,
        generated_redirect_url: redirectedtUrl,
        qr_code: 'wwefwifjwifjef',
      },
      campaign_video: {
        campaign_video_url: campaignVideoUrl,
        campaign_video_duration:
          mediaForm?.campaign_video_duration == 0
            ? ''
            : `${mediaForm?.campaign_video_duration}`,
        campagin_video_volume: `${mediaForm?.campagin_video_volume}`,
      },
      campaign_image: {
        campaign_image_duration:
          mediaForm?.campaign_image_duration == 0
            ? ''
            : `${mediaForm?.campaign_image_duration}`,
        campaign_image_url: campaignImageUrl,
      },
      is_geofence_available: `${isGeofenceChecked}`,
      geo_fences_list: selectedOpt?.map((item) => {
        return {
          place_id: item.place_id,
          display_name: item.display_name,
          type: item.type,
          latitude: item.lat,
          longitude: item.lon,
          boundingbox: item.boundingbox,
          address_type: item.address_type,
          geojson: item.geojson,
        };
      }),
    };
    const url = `${URLS_V2?.CAMPAIGNS?.UPDATE_CAMPAIGNS.replace(
      '#CAMPAIGN_ID#',
      campaignId
    )}`;

    httpPut(url, data)
      .then((res) => {
        if (res?.status === 200) {
          showSuccessMsg('Successfully Saved as Draft.');
          history.push('/v2/campaigns/in-active');
        } else {
          showErrorMsg(res?.message);
        }
        setSaveBtnUpdate(false);
      })
      .catch((err) => {
        showErrorMsg(err.message);
        setSaveBtnUpdate(false);
      });
  };

  const handleActivate = () => {
    // setActiveBtnUpdate(true);
    if (
      parseInt(mediaForm?.campagin_video_volume) < 0 ||
      parseInt(mediaForm?.campagin_video_volume) > 100
    ) {
      showErrorMsg('Video Volume Should be between 0-100');
      // setActiveBtnUpdate(false);
      return;
    }
    if (campaignVideoUrl?.length <= 0) {
      showErrorMsg('Video Required');
      setUploadVideoWarning(true);
      // setActiveBtnUpdate(false);
    } else {
      setActiveBtnUpdate(true);
      let startDate =
        typeof spotsForm.campaign_start_date === 'string'
          ? moment(spotsForm?.campaign_start_date, 'YYYY-MM-DD hh:mm a').unix()
          : spotsForm.campaign_start_date;

      let endDate =
        typeof spotsForm.campaign_end_date === 'string'
          ? moment(spotsForm?.campaign_end_date, 'YYYY-MM-DD hh:mm a').unix()
          : spotsForm.campaign_end_date;
      let data = {
        // is_active: true,
        // campaign_status: 'RUNNING',
        per_ride_frequency: spotsForm?.frequency_per_ride,
        allotted_slots: spotsForm?.allotted_slots,
        campaign_start_date: startDate,
        campaign_end_date: endDate,
        is_redirect_url_available: isChecked,
        redirect_url: {
          original_redirect_url: generatedUrl,
          generated_redirect_url: redirectedtUrl,
          qr_code: 'wwefwifjwifjef',
        },
        campaign_video: {
          campaign_video_url: campaignVideoUrl,
          campaign_video_duration: mediaForm?.campaign_video_duration,
          campagin_video_volume: mediaForm?.campagin_video_volume,
        },
        campaign_image: {
          campaign_image_duration: mediaForm?.campaign_image_duration,
          campaign_image_url: campaignImageUrl,
        },
        is_geofence_available: isGeofenceChecked,
        geo_fences_list: selectedOpt?.map((item) => {
          return {
            place_id: item.place_id,
            display_name: item.display_name,
            type: item.type,
            latitude: item.lat,
            longitude: item.lon,
            boundingbox: item.boundingbox,
            address_type: item.address_type,
            geojson: item.geojson,
          };
        }),
      };
      const url = `${URLS_V2?.CAMPAIGNS?.UPDATE_CAMPAIGNS.replace(
        '#CAMPAIGN_ID#',
        campaignId
      )}`;
      httpPut(url, data)
        .then((res) => {
          if (res.status === 200) {
            history.push('/v2/campaigns/active');
            showSuccessMsg('Campaign Activated Successfully');
          } else {
            showErrorMsg('Something went wrong!');
          }
          setActiveBtnUpdate(false);
        })
        .catch((err) => {
          showErrorMsg(err.message);
          setActiveBtnUpdate(false);
        });
    }
  };

  const handleDeactivate = () => {
    setDeactivationUpdating(true);
    const url = URLS_V2?.CAMPAIGNS?.CAMPAIGN_ACTIVE_STATUS.replace(
      '#CAMPAIGN_ID#',
      id
    );

    httpPut(url)
      .then((res) => {
        if (res?.status == 200) {
          showSuccessMsg('Campaign Deactivated SuccessFully!');
          history.push('/v2/campaigns/active');
        } else {
          showErrorMsg(res?.message);
        }
        setDeactivationUpdating(false);
      })
      .catch((err) => {
        showErrorMsg(err.msg);
        setDeactivationUpdating(false);
      });
  };

  return (
    <>
      <div className="create-campaign">
        <Form layout="vertical" onFinish={onFinish} form={campaignForm}>
          <Row gutter={[16, 20]} justify="space-between">
            <Col
              className="gutter-row"
              xs={{ span: 24, order: 2 }}
              sm={{ span: 24 }}
              md={{ span: 14, order: 1 }}
            >
              <Row gutter={16}>
                <Col className="gutter-row" xs={24} sm={12}>
                  <Form.Item
                    name="campaign_name"
                    label="Campaign Name"
                    // validateTrigger={handleValidateFieldNames('campaign_name')}
                    rules={[
                      {
                        required: true,
                        message: 'Please enter Campaign Name!',
                      },
                    ]}
                  >
                    <Input
                      type="text"
                      placeholder="Campaign Name"
                      rules={[
                        {
                          required: true,
                          message: 'Please Enter Campaign Name',
                        },
                      ]}
                      value={createCampaignForm?.campaign_name}
                      name="campaign_name"
                      onChange={onchangeCampaignName}
                      disabled={editModeOff}
                    />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={12} className="columns">
                  <div className="company-field">
                    <Col xs={18} className="columns">
                      <Form.Item
                        name="campaign_company_id"
                        label="Company"
                        rules={[
                          {
                            required: true,
                            message: 'Please select company!',
                          },
                        ]}
                      >
                        <Select
                          showSearch
                          placeholder="Select Company"
                          optionFilterProp="children"
                          onChange={onchangeCompany}
                          filterOption={filterOption}
                          options={companyData}
                          disabled={editModeOff}
                          // style={{ position: 'absolute', width:"100%"}}
                        />
                      </Form.Item>
                    </Col>
                    <Col xs={6} className="columns">
                      <Form.Item className="button-form">
                        <Button
                          type="primary"
                          className="add-button"
                          onClick={openCompanyModal}
                          style={{ width: '100%', padding: '0px 3px' }}
                          disabled={editModeOff}
                        >
                          Add
                        </Button>
                      </Form.Item>
                    </Col>
                  </div>
                </Col>

                <CompanyModal
                  form={companyForm}
                  isCompanyModalVisible={isCompanyModalVisible}
                  modalTitle="Add company"
                  handleOnCancel={closeCompanyModal}
                  handleAddCompany={handleAddCompany}
                  isCompanyAdding={isCompanyAdding}
                />
                <Col className="gutter-row" xs={24} sm={12}>
                  <Form.Item
                    name="campaign_industry_id"
                    label="Industry Type"
                    rules={[
                      {
                        required: true,
                        message: 'Please select Industry Type!',
                      },
                    ]}
                  >
                    <Select
                      showSearch
                      placeholder="Select Industry Type"
                      optionFilterProp="children"
                      onChange={onchangeIndustry}
                      filterOption={filterOption}
                      options={industryList}
                      disabled={editModeOff}
                    ></Select>
                  </Form.Item>
                </Col>
                <Col className="gutter-row" xs={24} sm={12}>
                  <Form.Item
                    name="campaign_type_id"
                    label="Campaign Type"
                    rules={[
                      {
                        required: true,
                        message: 'Please select campaign type!',
                      },
                    ]}
                  >
                    <Select
                      showSearch
                      placeholder="Select Campaign Type"
                      optionFilterProp="children"
                      onChange={onchangeCampaignType}
                      filterOption={filterOption}
                      options={campaignList}
                      disabled={editModeOff}
                    ></Select>
                  </Form.Item>
                </Col>

                <Col className="gutter-row" xs={24} sm={12}>
                  {/* {showRedirectField && ( */}
                  <div className="redirect-checkbox">
                    <Popconfirm
                      title="Are you sure want to remove the Redirect Url?"
                      description="Open Popconfirm with async logic"
                      visible={redirectUrlPopup}
                      onConfirm={popupHandleOk}
                      onCancel={popupHandleCancel}
                    >
                      <Checkbox
                        disabled={
                          isModalOpen || redirectUrlPopup || editModeOff
                        }
                        checked={isChecked}
                        onClick={showPopupconfirm}
                      >
                        <span style={{ fontWeight: 'bold', fontSize: '16px' }}>
                          Redirect Url
                        </span>
                      </Checkbox>
                    </Popconfirm>
                  </div>
                  {/* )} */}
                </Col>
              </Row>
            </Col>
            <Col md={{ span: 8, order: 2 }}>
              <Row gutter={16}>
                <Col span={24}>
                  {apiData?.is_active === true && (
                    <Tag color="success">active</Tag>
                  )}
                  {apiData?.is_active === false && (
                    <Tag color="error">In-Active</Tag>
                  )}
                </Col>
                <Col span={24}>
                  {apiData?.campaign_status ? (
                    <div style={{ fontWeight: 500, marginTop: '10px' }}>
                      Status :- <Tag>{apiData?.campaign_status}</Tag>
                    </div>
                  ) : (
                    ''
                  )}
                </Col>
              </Row>
            </Col>
          </Row>
        </Form>

        <Form layout="vertical" form={mediaNSpotsForm}>
          <Row gutter={16}>
            <Col className="gutter-row" xs={24} sm={24} md={12}>
              <Row gutter={16}>
                <Col className="gutter-row" xs={24} sm={24}>
                  <div style={{ marginTop: '30px' }}>
                    {showAllQrField && (
                      <>
                        <Form.Item name="Redirected URL" label="Redirected URL">
                          <Input
                            type="text"
                            placeholder={redirectedtUrl}
                            labelAlign="top"
                            wrapperCol={{ span: 24 }}
                            disabled={true}
                            // onChange={(e)=>setRedirectData(e.target.value)}
                          />
                        </Form.Item>

                        <Form.Item name="Generated URL" label="Generated URL">
                          <Input
                            type="text"
                            placeholder={generatedUrl}
                            disabled={true}
                            // onChange={(e)=>setRedirectData(e.target.value)}
                          />
                        </Form.Item>
                      </>
                    )}
                  </div>
                </Col>
              </Row>
            </Col>
            <Col className="gutter-row" xs={24} sm={24} md={12}>
              {showAllQrField && (
                <>
                  <div style={{ display: 'flex', justifyContent: 'center' }}>
                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                      <QRCode
                        id="Campaign-QrCode"
                        value={qrData || ''}
                        size={320}
                        includeMargin={true}
                      />
                      <Button
                        type="primary"
                        onClick={downloadQrCode}
                        style={{ textAlign: 'center', marginTop: '10px' }}
                      >
                        Download QR
                      </Button>
                    </div>
                  </div>
                </>
              )}
            </Col>
          </Row>
          <Modal
            title="Redirect Url"
            visible={isModalOpen}
            // onOk={handleOk}
            onCancel={handleCancel}
            footer={modalfooterButtons}
          >
            <Form layout="verticle" form={RedirectUrlForm}>
              <Form.Item
                name="Redirect URL"
                label="Redirect URL"
                // validateTrigger={handleValidateFieldNames('campaign_name')}
                rules={[
                  {
                    required: true,
                    message: 'Please Enter Redirect URL',
                  },
                  {
                    type: 'url',
                    message: 'This field must be a valid url.',
                  },
                ]}
              >
                <Input
                  type="text"
                  placeholder="Redirect URL"
                  rules={[
                    {
                      required: true,
                      message: 'Please Enter Redirect URL',
                    },
                    {
                      type: 'url',
                      message: 'This field must be a valid url.',
                    },
                  ]}
                  value={redirectFieldData}
                  onChange={(e) => setRedirectData(e.target.value)}
                  disabled={editModeOff}
                />
              </Form.Item>
            </Form>
          </Modal>
          {/* {showRedirectField && ( */}
          <>
            <SpotsDetails
              spotsForm={spotsForm}
              setSpotsForm={setSpotsForm}
              editModeOff={editModeOff}
              apiData={apiData}
            />

            <Media
              setCampaignId={setCampaignId}
              campaignId={campaignId}
              mediaForm={mediaForm}
              setMediaForm={setMediaForm}
              campaignImageUrl={campaignImageUrl}
              setCampaignImageUrl={setCampaignImageUrl}
              campaignVideoUrl={campaignVideoUrl}
              setCampaignVideoUrl={setCampaignVideoUrl}
              uploadVideoWarning={uploadVideoWarning}
              setUploadVideoWarning={setUploadVideoWarning}
              editModeOff={editModeOff}
            />

            <Checkbox
              checked={isGeofenceChecked}
              onClick={showGeofenceCampaign}
              style={{ marginTop: '40px' }}
              disabled={editModeOff}
            >
              Geofence Campaign
            </Checkbox>

            {isGeofenceChecked && (
              <div style={{ marginTop: '20px' }}>
                <MapView
                  selectedOpt={selectedOpt}
                  setSelectedOpt={setSelectedOpt}
                  editModeOff={editModeOff}
                />
              </div>
            )}

            <div style={{ marginTop: '40px' }}>
              {!editModeOff ? (
                <>
                  <Popconfirm
                    title="If you save it as a Draft, this Campaign will become In-Active."
                    onConfirm={() => SaveForLater('save')}
                    // onCancel={cancel}
                    okText="Okay"
                    cancelText="Cancel"
                  >
                    <Button
                      type="primary"
                      style={{ marginRight: '20px', marginBottom: '10px' }}
                      // onClick={}
                      htmlType="button"
                    >
                      {saveBtnUpdate ? 'Updating...' : 'Save as Draft'}
                    </Button>
                  </Popconfirm>
                  {/* <Form.Item> */}

                  <Button
                    type="primary"
                    style={{ marginRight: '20px', marginBottom: '10px' }}
                    htmlType="button"
                    onClick={handleActivate}
                  >
                    {activeBtnUpdate ? 'Updating...' : 'Activate'}
                  </Button>

                  {/* </Form.Item> */}
                  {
                    apiData?.is_active === true &&
                  <Popconfirm
                    title="Are you sure you want to Deactivate this Campaign?"
                    onConfirm={handleDeactivate}
                    // onCancel={cancel}
                    okText="Okay"
                    cancelText="Cancel"
                  >
                    <Button
                      type="danger"
                      htmlType="button"
                      style={{ marginRight: '20px', marginBottom: '10px' }}
                    >
                      {deactivationUpdating ? 'Updating...' : 'Deactivate'}
                    </Button>
                  </Popconfirm>}
                </>
              ) : (
                ''
              )}
              {editModeOff ? (
                <Popconfirm
                  title="Are you sure you want to edit this Campaign ?"
                  //  description=""
                  onConfirm={() => {
                    setEditModeOff(false);
                  }}
                  okText="Yes"
                  cancelText="No"
                >
                  <Button htmlType="button" type="primary">
                    Edit Campaign
                  </Button>
                </Popconfirm>
              ) : (
                ''
              )}
            </div>
          </>
          {/* )} */}
        </Form>
      </div>
      {/* <Modal
        visible={editModeModal}
        onOk={() => {
          setEditModeOff(false);
          setEditModeModal(false);
        }}
        onCancel={() => setEditModeModal(false)}
      >
        <div>Are you sure you want to edit this Campaign ?</div>
      </Modal> */}
    </>
  );
};

export default NewViewCampaigns;
