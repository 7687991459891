import React, { useEffect, useState } from 'react';
import { Card, Col, Row, Skeleton } from 'antd';
import StatisticCardBlock from 'components/statistic-card-block';
//eslint-disable-next-line
import { Chart } from "react-google-charts";
import { httpGet, URLS } from 'utils';
import { DashboardBox } from './_dashboard.styled';
// import DashboardRideChart from './dashboardRideChart';
// import DashboardDeviceChart from './dashboardDeviceChart';
// import DashboardCampaignChart from './dashboardCampaignChart';

const Dashboard = () => {
  const [isFetching, setIsFetching] = useState(false);
  // const [isRideChartFetching, setIsRideChartFetching] = useState(false);
  // const [isDeviceChartFetching, setIsDeviceChartFetching] = useState(false);
  // const [isCampaignChartFetching, setIsCampaignChartFetching] = useState(false);

  const [dashboardStateData, setDashboardStateData] = useState({});
  // const [rideChartData, setRideChartData] = useState([]);
  // const [deviceChartData, setDeviceChartData] = useState([]);
  // const [campaignChartData, setCampaignChartData] = useState([]);

  useEffect(() => {
    fetchDashboardStatistics();
    // fetchRideChartData();
    // fetchDeviceChartData();
    // fetchCampaignChartData();
  }, []);

  const fetchDashboardStatistics = () => {
    setIsFetching(true);
    const url = `${URLS?.DASHBOARD?.DASHBOARD_STATISTICS_COUNT}`;
    httpGet(url)
      .then((res) => {
        if (
          res?.status === 200 &&
          res?.data &&
          Object.keys(res?.data).length > 0
        ) {
          setDashboardStateData(res?.data || {});
        }
        setIsFetching(false);
      })
      .catch(() => {
        setIsFetching(false);
      });
  };

  // const fetchRideChartData = () => {
  //   setIsRideChartFetching(true);
  //   const url = `${URLS?.DASHBOARD?.DASHBOARD_RIDE_CHART}`;
  //   httpGet(url)
  //     .then((res) => {
  //       if (res?.status === 200 && res?.data) {
  //         const tempChartData = res?.data?.map((item) => {
  //           return { ...item, _id: new Date(item._id) };
  //         });
  //         setRideChartData(tempChartData || {});
  //       }
  //       setIsRideChartFetching(false);
  //     })
  //     .catch(() => {
  //       setIsRideChartFetching(false);
  //     });
  // };

  // const fetchDeviceChartData = () => {
  //   setIsDeviceChartFetching(true);
  //   const url = `${URLS?.DASHBOARD?.DASHBOARD_DEVICE_CHART}`;
  //   httpGet(url)
  //     .then((res) => {
  //       if (res?.status === 200 && res?.data) {
  //         setDeviceChartData(res?.data || {});
  //       }
  //       setIsDeviceChartFetching(false);
  //     })
  //     .catch(() => {
  //       setIsDeviceChartFetching(false);
  //     });
  // };

  // const fetchCampaignChartData = () => {
  //   setIsCampaignChartFetching(true);
  //   const url = `${URLS?.DASHBOARD?.DASHBOARD_ADS_CAMPAIGN_CHART}`;
  //   httpGet(url)
  //     .then((res) => {
  //       if (res?.status === 200 && res?.data) {
  //         const tempChartData = res?.data?.map((item) => {
  //           return {
  //             ...item,
  //             campaign_name: item?.campaignDetails?.campaign_name || '',
  //           };
  //         });
  //         setCampaignChartData(tempChartData || {});
  //       }
  //       setIsCampaignChartFetching(false);
  //     })
  //     .catch(() => {
  //       setIsCampaignChartFetching(false);
  //     });
  // };

  const renderRidesStatisticsCard = (
    cardTitle,
    totalCount,
    activeCount,
    inactiveCount
  ) => {
    return (
      <Card title={cardTitle} bordered={false}>
        <Skeleton
          loading={isFetching}
          active
          paragraph={{ rows: 2, width: '100%' }}
          title={false}
        >
          <Row gutter={16}>
            <Col span={8}>
              <StatisticCardBlock
                title={'Total'}
                value={totalCount || '0'}
                valueStyle={{ color: '#3f8600' }}
              />
            </Col>
            <Col span={8}>
              <StatisticCardBlock
                title="Active"
                value={activeCount || '0'}
                precision={0}
                valueStyle={{ color: '#3f8600' }}
              />
            </Col>
            <Col span={8}>
              <StatisticCardBlock
                title="Inactive"
                value={inactiveCount || '0'}
                precision={0}
                valueStyle={{ color: '#cf1322' }}
              />
            </Col>
          </Row>
        </Skeleton>
      </Card>
    );
  };

  const data = [
    [
      "Days",
      "Rides",
    ],
    [1, 400,],
    [2, 409,],
    [3, 390,],
    [4, 469,],
    [5, 459,],
    [6, 412,],
    [7, 429,],
    [8, 465,],
    [9, 419,],
    [10, 430,],
    [11, 490,],
    [12, 469.6,],
    [13, 400.8,],
    [14, 390.2,],
    [15, 410.2,],
    [16, 450.2,],
    [17, 412.2,],
    [18, 490.2,],
    [19, 415.2,],
    [20, 470.2,],
    [21, 410.2,],
    [22, 460.2,],
    [23, 410,],
    [24, 420,],
    [25, 480,],
    [26, 430,],
    [27, 501,],
    [28, 495,],
    [29, 470,],
    [30, 512,],
    [31, 530,],
  ];

  const options = {
    chart: {
      title: "Rides In A Month",
      subtitle:"December Rides"
    },
    series: {
      0: { color: '#e0440e' },
    },
  };

  const data2 = [
    ["Campaigns", "Clicks"],
    ["Campaigns1", 700],
    ["Campaigns2", 717],
    ["Campaigns3", 466],
    ["Campaigns4", 603],
    ["Campaigns5", 555],
  ]

  const options2 = {
    chart: {
      title: "Top Campaigns With Most Clicks per 1k Views",
      subtitle: "Clicks/ 1k Views",
    },
  };

  const data3 = [
    ["Devices", "Rides"],
    ["Device1", 30],
    ["Device2", 41],
    ["Device3", 36],
    ["Device4", 40],
    ["Device5", 35],
    ["Device6", 45],
    ["Device7", 32],
    ["Device8", 30],
    ["Device9", 39],
    ["Device10", 31],
  ]

  const options3 = {
    chart: {
      title: "Top 10 Devices With Most Rides In A Month",
    },
    series: {
      0: { color: '#e0440e' , backgroundColor: '#c7c7c7'},
      
    },
  };

  return (
    <DashboardBox>
      <div className="table-header">
        <h3 className="table-title">Dashboard</h3>
      </div>
      <div className="site-statistic-demo-card dashboard-kpis">
        <Row gutter={14}>
          <Col span={12}>
            {renderRidesStatisticsCard(
              'Campaigns',
              dashboardStateData?.TotalCampaignCount,
              dashboardStateData?.ActiveCampaigns,
              dashboardStateData?.InActiveCampaigns
            )}
          </Col>
          <Col span={12}>
            {renderRidesStatisticsCard(
              'Devices',
              dashboardStateData?.TotalDevices,
              dashboardStateData?.ActiveDevices,
              dashboardStateData?.InActiveDevices
            )}
          </Col>
        </Row>
        <div id="" ></div>
        {/* <DashboardRideChart
          data={rideChartData}
          dataFetching={isRideChartFetching}
        /> */}
        {/* <Row gutter={14}>
          <Col span={12}>
            <DashboardDeviceChart
              data={deviceChartData}
              dataFetching={isDeviceChartFetching}
            />
          </Col>
          <Col span={12}>
            <DashboardCampaignChart
              data={campaignChartData}
              dataFetching={isCampaignChartFetching}
            />
          </Col>
        </Row> */}

        {/* Rides In A Month */}
        <Card
          // title="Total Rides in a Month"
          style={{ width: '100%', height: 450, marginTop: '10px' }}
          bodyStyle={{ height: '90%', position: 'relative' }}
        >
          <Chart
            chartType="Line"
            width="100%"
            height="400px"
            data={data}
            options={options}
          />
        </Card>

        {/* Top Campaigns With Most Clicks per 1k Views */}
        <Card
          // title="Total Rides in a Month"
          style={{ width: '100%', height: 450, marginTop: '10px' }}
          bodyStyle={{ height: '90%', position: 'relative' }}
        >
          <Chart
            chartType="Bar"
            width="100%"
            height="400px"
            data={data2}
            options={options2}
          />
        </Card>


        {/*Top 10 Devices  With Most Rides*/}
        <Card
          style={{ width: '100%', height: 450, marginTop: '10px' }}
          bodyStyle={{ height: '90%', position: 'relative' }}
        >
          <Chart
            chartType="Bar"
            width="100%"
            height="400px"
            data={data3}
            options={options3}
          />
        </Card>

      </div>
    </DashboardBox>
  );
};

export default Dashboard;
