import React from 'react';
// import { oneOfType, array, func } from 'prop-types';
import { Form, Row, Col, Radio, Collapse } from 'antd';
import { GENDER_LIST, AGE_LIST, LANGUAGES_LIST } from 'constants/campaign';
import { CollapseBox } from './_audience.styled';

const { Panel } = Collapse;

const Audience = (props) => {
  return (
    <CollapseBox>
      <Collapse defaultActiveKey={['1']}>
        <Panel header="Select Audience" key="1">
          <Row gutter={16}>
            <Col className="gutter-row" xs={24}>
              <Form.Item name="gender" label="Gender">
                <Radio.Group defaultValue={1}>
                  {GENDER_LIST &&
                    GENDER_LIST.length > 0 &&
                    GENDER_LIST.map((data, index) => (
                      <Radio value={data} key={index}>
                        {data}
                      </Radio>
                    ))}
                </Radio.Group>
              </Form.Item>
            </Col>
            <Col className="gutter-row" xs={24}>
              <Form.Item name="age" label="Age group">
                <Radio.Group defaultValue={1}>
                  {AGE_LIST &&
                    AGE_LIST.length > 0 &&
                    AGE_LIST.map((data, index) => (
                      <Radio value={data} key={index}>
                        {data}
                      </Radio>
                    ))}
                </Radio.Group>
              </Form.Item>
            </Col>
            <Col className="gutter-row" xs={24}>
              <Form.Item label="Language" name="language">
                <Radio.Group name="language" defaultValue={1}>
                  {LANGUAGES_LIST &&
                    LANGUAGES_LIST.length > 0 &&
                    LANGUAGES_LIST.map((data, index) => (
                      <Radio value={data} key={index}>
                        {data}
                      </Radio>
                    ))}
                </Radio.Group>
              </Form.Item>
            </Col>
          </Row>
        </Panel>
      </Collapse>
    </CollapseBox>
  );
};

Audience.propTypes = {
  //   handleValidateFieldNames: func,
  //   setValidateFieldsName: func,
  //   validateFieldsName: oneOfType([array]),
};

export default Audience;
