import { Checkbox } from 'antd'
import React, { useEffect, useState } from 'react'

const CheckboxComponent = ({ data,setAccessModule }) => {
    const [ formValues,setFormValues ]=useState({
        read:data.read,
        write:data.write,
        module_name:data.module_name
    });

    const handleChange=(e)=>{
        setFormValues((prev)=>({ ...prev,[e.target.name]:e.target.checked }));
    }

    useEffect(()=>{
        setAccessModule(prev=>{
        if(prev.filter((prevItem)=>prevItem.module_name===formValues.module_name).length === 1 ){
            const updated = prev.map((prevItm)=>{
                if(prevItm.module_name===formValues.module_name){
                    return {
                        ...prevItm,
                        ...formValues,
                }
            }else{
                return prevItm
            }
            })
            return updated
        }else{
            return [...prev,{ ...formValues,}]
        }
    })
    },[formValues])

    return (
        <div key={data?.module_name} style={{ margin: "20px" }}  >
            <div style={{ fontSize: "20px" }} >
                <span>{data?.module_name}:-</span>
            </div>

            <span style={{ padding: "0px 15px" }} >
                <span>Read:  <Checkbox value="read" name='read' checked={formValues.read} onChange={handleChange} /></span>
            </span>

            <span style={{ padding: "0px 15px" }} >
                <span>Write: <Checkbox value="write" name='write' checked={formValues.write} onChange={handleChange} /></span>
            </span>

        </div>
    )
}

export default CheckboxComponent