export const MODE_TYPES = {
  ADD: 'Add',
  EDIT: 'Edit',
  VIEW: 'View',
  UPDATE: 'Update',
};

export const GLOBAL_APP_SETTINGS_KEYS = {
  main_page: 'update_main_page_data',
  read: 'update_read_data',
  game: 'update_game_data',
  discover: 'update_discover_data',
  watch_content: 'update_watch_content_data',
  watch_category: 'update_watch_category_data',
  volume: 'update_watch_data',
  brightness: 'update_watch_data',
  ad_volume: 'update_watch_data',
  campaign: 'update_campaign_data',
};

export const API_V2_ERROR_CODES = [400, 401, 404, 422, 500];
