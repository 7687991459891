import React, { useState } from 'react';
import axios from 'axios';
import { object, func, bool } from 'prop-types';
import { Col, Button, Spin, Popconfirm, Tooltip, Typography } from 'antd';
import { httpGet, showErrorMsg, URLS_V2 } from 'utils';
import { MEDIA_TYPES } from 'constants/campaign';
import {
  UploadOutlined,
  EyeOutlined,
  DeleteOutlined,
  LoadingOutlined,
} from '@ant-design/icons';
import Preview from '../preview';
import {
  WATCH_CONTENT_IMAGE_DIMENSIONS,
  WATCH_CONTENT_VIDEO_DIMENSIONS,
} from 'constants/watch-content';

const { Text } = Typography;

const Media = (props) => {
  const {
    selectMediaImageObject,
    setSelectMediaImageObject,
    setSelectMediaVideoObject,
    setIsMediaImageUpdating,
    isMediaImageUpdating,
    isMediaVideoUpdating,
    setIsMediaVideoUpdating,
    selectMediaVideoObject,
    formDisabled,
    mediaInitialStructure,
  } = props;

  const [modalViewType, setModalViewType] = useState('');

  const closeModal = () => {
    setModalViewType(false);
  };

  const handlePreviewType = ({ type }) => {
    setModalViewType(type);
  };

  const uploadMediaToBucket = async (file, type) => {
    //setAPKUploading(true);
    let url = `${URLS_V2?.WATCH_CONTENT?.GET_SIGNEDURL}?filename=${file.name}&action=write`;
    httpGet(url)
      .then(async (res) => {
        if (res.status === 200) {
          try {
            await axios.put(res.data.signedUrl, file, {
              headers: {
                'Content-Type': file.type,
              },
            });
            const baseUrl = 'https://storage.googleapis.com';
            const bucketName =
              process.env.REACT_APP_CLOUD_STORAGE_MANAGEMENT_BUCKET; // Replace with your actual bucket name
            const filePath = `content/watch/${file.name.replace(/\s+/g, '')}`; // Replace with the desired folder path
            const fileUrl = `${baseUrl}/${bucketName}/${filePath}`;
            if (fileUrl) {
              if (type === MEDIA_TYPES.IMAGE) {
                setSelectMediaImageObject({
                  name: file?.name,
                  url: fileUrl,
                  isUploaded: true,
                });
                setIsMediaImageUpdating(false);
              } else if (type === MEDIA_TYPES.VIDEO) {
                setSelectMediaVideoObject({
                  name: file?.name,
                  url: fileUrl,
                  isUploaded: true,
                });
                setIsMediaVideoUpdating(false);
              }
            }
          } catch (error) {
            showErrorMsg(error);
            setIsMediaVideoUpdating(false);
            setIsMediaImageUpdating(false);
          }
        }
      })
      .catch((err) => {
        showErrorMsg(err);
        setIsMediaVideoUpdating(false);
        setIsMediaImageUpdating(false);
      });
  };

  const deleteMediaFromBucket = ({ file, type }) => {
    let url = `${URLS_V2?.WATCH_CONTENT?.GET_SIGNEDURL}?filename=${
      file.url.split('/')[6]
    }&action=delete`;
    httpGet(url)
      .then(async (res) => {
        if (res.status === 200) {
          try {
            await axios.delete(res.data.signedUrl);
            if (type === MEDIA_TYPES.IMAGE) {
              setSelectMediaImageObject(mediaInitialStructure);
              setIsMediaImageUpdating(false);
            } else if (type === MEDIA_TYPES.VIDEO) {
              setSelectMediaVideoObject(mediaInitialStructure);
              setIsMediaVideoUpdating(false);
            }
          } catch (error) {
            showErrorMsg(error);
            setIsMediaImageUpdating(false);
            setIsMediaVideoUpdating(false);
          }
        }
      })
      .catch((err) => {
        showErrorMsg(err);
        setIsMediaImageUpdating(false);
        setIsMediaVideoUpdating(false);
      });
  };

  const handleImageFile = async (e) => {
    const file = e.target.files[0];

    setIsMediaImageUpdating(true);

    if (
      file.type !== 'image/apng' &&
      file.type !== 'image/jpeg' &&
      file.type !== 'image/png'
    ) {
      showErrorMsg(`Only .jpg, .jpeg, .pjpeg, .png, file types are accepted.`);
      setIsMediaImageUpdating(false);
      return;
    }

    const imageSize =
      file.size / 1024 > WATCH_CONTENT_IMAGE_DIMENSIONS.FILE_SIZE;
    if (imageSize) {
      showErrorMsg(
        `Thumb size must be smaller then ${WATCH_CONTENT_IMAGE_DIMENSIONS.FILE_SIZE} KB`
      );
      setIsMediaImageUpdating(false);
      return;
    }

    // const imageDimensions = await getImageDimensions(file);
    await getImageDimensions(file);
    // if (
    //   imageDimensions.width !== WATCH_CONTENT_IMAGE_DIMENSIONS.FILE_WIDTH ||
    //   imageDimensions.height !== WATCH_CONTENT_IMAGE_DIMENSIONS.FILE_HEIGHT
    // ) {
    //   showErrorMsg(
    //     `Thumb does not have optimal dimensions. Recommended image dimensions are ${WATCH_CONTENT_IMAGE_DIMENSIONS.FILE_WIDTH} X ${WATCH_CONTENT_IMAGE_DIMENSIONS.FILE_HEIGHT}.`
    //   );
    //   setIsMediaImageUpdating(false);
    //   return;
    // }

    uploadMediaToBucket(file, MEDIA_TYPES.IMAGE);
  };

  const getImageDimensions = (file) => {
    return new Promise((resolve) => {
      let images = new Image();
      images.onload = () => {
        resolve({ height: images.height, width: images.width });
      };
      images.onerror = (error) => {
        showErrorMsg(error);
        resolve('');
      };

      let fileReader = new FileReader();
      fileReader.onload = () => (images.src = fileReader.result);
      fileReader.onerror = (error) => {
        showErrorMsg(error);
        resolve('');
      };
      if (file) {
        fileReader.readAsDataURL(file);
      }
    });
  };

  const handleVideoFile = async (e) => {
    setIsMediaVideoUpdating(true);
    const file = e.target.files[0];
    if (
      file.type !== 'video/mp4' &&
      file.type !== 'video/mpeg' &&
      file.type !== 'video/webm' &&
      file.type !== 'video/x-m4v'
    ) {
      showErrorMsg(
        `Only .mp4, .m4a, .m4p, .m4b, .m4r, .m4v, .m1v,	.mov,
         .webm, .m4v file types are accepted.`
      );
      setIsMediaVideoUpdating(false);
      return;
    }

    const videoSize =
      file.size / 1024 > WATCH_CONTENT_VIDEO_DIMENSIONS.FILE_SIZE;
    if (videoSize) {
      showErrorMsg(
        `Video size must be smaller then ${
          WATCH_CONTENT_VIDEO_DIMENSIONS.FILE_SIZE / 1000
        } MB`
      );
      setIsMediaVideoUpdating(false);
      return;
    }

    uploadMediaToBucket(file, MEDIA_TYPES.VIDEO);
  };

  const returnVideoUploadNote = () => {
    return (
      <>
        <div style={{ color: '#fff' }}>
          <Text style={{ color: '#fff !important' }}>{`Max file size: ${
            WATCH_CONTENT_VIDEO_DIMENSIONS.FILE_SIZE / 1000
          } MB`}</Text>
        </div>
        {/* <div style={{ color: '#fff' }}>
          <Text>{`Resolution: ${WATCH_CONTENT_VIDEO_DIMENSIONS.FILE_WIDTH} X ${WATCH_CONTENT_VIDEO_DIMENSIONS.FILE_HEIGHT}`}</Text>
        </div> */}
      </>
    );
  };

  const returnImageUploadNote = () => {
    return (
      <>
        <div style={{ color: '#fff' }}>
          <Text
            style={{ color: '#fff !important' }}
          >{`Max file size: ${WATCH_CONTENT_IMAGE_DIMENSIONS.FILE_SIZE} KB`}</Text>
        </div>
        {/* <div style={{ color: '#fff' }}>
          <Text>{`Resolution: ${WATCH_CONTENT_IMAGE_DIMENSIONS.FILE_WIDTH} X ${WATCH_CONTENT_IMAGE_DIMENSIONS.FILE_HEIGHT}`}</Text>
        </div> */}
      </>
    );
  };

  return (
    <>
      {modalViewType && (
        <Preview
          handleCancel={closeModal}
          modalViewType={modalViewType}
          setModalViewType={setModalViewType}
          handleOk={closeModal}
          selectMediaImageObject={selectMediaImageObject}
          selectMediaVideoObject={selectMediaVideoObject}
        />
      )}
      {/* <Collapse defaultActiveKey={['1']}>
        <Panel header="Media" key="1"> */}
      {/* <Row gutter={16}> */}
      <Col className="gutter-row" xs={24} sm={12}>
        <>
          {isMediaImageUpdating ? (
            <Button type="primary" disabled className="loader-btn">
              <Spin
                indicator={
                  <LoadingOutlined
                    style={{
                      fontSize: 16,
                      color: '#808080b8',
                    }}
                    spin
                  />
                }
              />
              Uploading...
            </Button>
          ) : (
            <>
              {selectMediaImageObject && selectMediaImageObject?.url ? (
                <div className="ant-row ant-form-item">
                  <div>
                    <Button
                      type="primary"
                      className="preview-btn btn-mr-rt"
                      onClick={() =>
                        handlePreviewType({ type: MEDIA_TYPES.IMAGE })
                      }
                    >
                      <EyeOutlined />
                      Preview Image
                    </Button>
                    {!formDisabled && (
                      <Popconfirm
                        title="Sure to delete image file?"
                        onConfirm={() =>
                          deleteMediaFromBucket({
                            type: MEDIA_TYPES.IMAGE,
                            file: selectMediaImageObject,
                          })
                        }
                      >
                        <Button
                          danger
                          shape="circle"
                          htmlType="submit"
                          className="delete-btn"
                          style={{ marginLeft: '1rem' }}
                        >
                          <DeleteOutlined />
                        </Button>
                      </Popconfirm>
                    )}
                  </div>
                </div>
              ) : (
                <div className="ant-row ant-form-item">
                  <label htmlFor="media_image_url" style={{ width: 0 }}>
                    <Tooltip title={returnImageUploadNote} color={'#fff'}>
                      <div className="ant-btn">
                        <UploadOutlined />
                        {'Upload Thumb'}
                      </div>
                    </Tooltip>
                    <input
                      id="media_image_url"
                      type="file"
                      name="media_image_url"
                      style={{ display: 'none' }}
                      onChange={handleImageFile}
                      accept="image/x-png,image/jpeg,image/webp"
                    />
                  </label>
                </div>
              )}
            </>
          )}
        </>
      </Col>
      <Col className="gutter-row" xs={24} sm={12}>
        <>
          {isMediaVideoUpdating ? (
            <Button type="primary" disabled className="loader-btn btn-mr-rt">
              <Spin
                indicator={
                  <LoadingOutlined
                    style={{
                      fontSize: 16,
                      marginRight: 10,
                      color: '#808080b8',
                    }}
                    spin
                  />
                }
              />
              Uploading...
            </Button>
          ) : (
            <>
              {selectMediaVideoObject && selectMediaVideoObject?.url ? (
                <div className="ant-row ant-form-item">
                  <div>
                    <Button
                      type="primary"
                      className="preview-btn btn-mr-rt"
                      onClick={() =>
                        handlePreviewType({ type: MEDIA_TYPES.VIDEO })
                      }
                    >
                      <EyeOutlined />
                      {'Preview Video'}
                    </Button>
                    {!formDisabled && (
                      <Popconfirm
                        title="Sure to delete video file?"
                        onConfirm={() =>
                          deleteMediaFromBucket({
                            type: MEDIA_TYPES.VIDEO,
                            file: selectMediaVideoObject,
                          })
                        }
                      >
                        <Button
                          danger
                          shape="circle"
                          htmlType="submit"
                          className="video-delete-btn"
                          style={{ marginLeft: '1rem' }}
                        >
                          <DeleteOutlined />
                        </Button>
                      </Popconfirm>
                    )}
                  </div>
                </div>
              ) : (
                <div className="ant-row ant-form-item">
                  <label htmlFor="media_video_url" style={{ width: 0 }}>
                    <Tooltip title={returnVideoUploadNote} color={'#fff'}>
                      <div className="ant-btn">
                        <UploadOutlined />
                        {'Upload Video'}
                      </div>
                    </Tooltip>
                    <input
                      id="media_video_url"
                      type="file"
                      name="media_video_url"
                      style={{ display: 'none' }}
                      onChange={handleVideoFile}
                      accept="video/mp4,video/x-m4v,video/*"
                    />
                  </label>
                </div>
              )}
            </>
          )}
        </>
      </Col>
    </>
  );
};

Media.propTypes = {
  formDisabled: bool,
  isMediaImageUpdating: bool,
  isMediaVideoUpdating: bool,
  mediaInitialStructure: object,
  selectMediaImageObject: object,
  selectMediaVideoObject: object,
  setIsMediaImageUpdating: func,
  setIsMediaVideoUpdating: func,
  setSelectMediaImageObject: func,
  setSelectMediaVideoObject: func,
};

export default Media;
