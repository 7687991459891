/* eslint-disable react/prop-types */
import React, { useState, useRef, useEffect } from 'react';
import YellowCar from '../../assets/images/yellow-car.png';
import moment from 'moment';
import GreenCar from '../../assets/images/green-car.png';
import { MapBoxWindow } from './_device-map.styled';
import mapboxgl from '!mapbox-gl'; // eslint-disable-line import/no-webpack-loader-syntax
import 'mapbox-gl/dist/mapbox-gl.css';

mapboxgl.accessToken =
  process.env.REACT_APP_MAPBOX_PUBLIC_KEY ||
  'pk.eyJ1IjoiYmluYXJ5YWRtaW4iLCJhIjoiY2t6Mmw4ODNhMDFsMjJ2cWYweTFyb2ZuZyJ9.iBSQIqW6qM_KMV9S_ufb-w';

const DeviceMap = (props) => {
  const { locations } = props || {};

  const mapContainer = useRef(null);
  const map = useRef(null);
  const [initialCenter] = useState({
    lng: 55.2708,
    lat: 25.2048,
  });
  const [zoom] = useState(12);
  const [mapSourceLoaded, setMapSourceLoaded] = useState(false);

  const carImages = [
    { url: YellowCar, id: 'YellowCar' },
    { url: GreenCar, id: 'GreenCar' },
  ];

  useEffect(() => {
    initMapbox();
    return () => map.current.remove();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const initMapbox = () => {
    if (!map.current) {
      // initialize map only once
      map.current = new mapboxgl.Map({
        container: mapContainer.current,
        style: 'mapbox://styles/binaryadmin/ckzco0u9b004314rwv3tzkcn8',
        center: initialCenter, // starting position [lng, lat]
        zoom: zoom,
      });
    }

    if (map.current) {
      map.current.addControl(new mapboxgl.NavigationControl(), 'top-right');
      map.current.addControl(new mapboxgl.FullscreenControl());
      // // disable map rotation using right click + drag
      // map.current.dragRotate.disable();
      // // disable map rotation using touch rotation gesture
      // map.current.touchZoomRotate.disableRotation();
    }
  };

  useEffect(() => {
    if (map.current && map.current.loaded()) {
      setLiveMapView();
    } else {
      map.current.on('load', () => {
        setLiveMapView();
      });
    }
  }, [map.current]); // eslint-disable-line react-hooks/exhaustive-deps

  const setLiveMapView = () => {
    if (!map.current) return;
    const geojson = getUpdatedLocationData(locations);

    // Add the layers to the map as a source.
    Promise.all(
      carImages.map(
        (img) =>
          new Promise((resolve, reject) => {
            map.current.loadImage(img.url, function (error, imgResolution) {
              if (error) return reject(error);
              if (!map.current.hasImage(img.id)) {
                map.current.addImage(img.id, imgResolution);
              }
              resolve();
            });
          })
      )
    ).then(() => {
      if (!map.current || map.current.getSource('live-view')) return;

      map.current.addSource('live-view', {
        type: 'geojson',
        data: geojson,
      });
      map.current.addLayer({
        id: 'live-view',
        type: 'symbol',
        source: 'live-view', // reference the data source
        layout: {
          'icon-image': '{icon}',
          'icon-allow-overlap': true,
          'icon-size': 0.5,
        },
      });
      setMapSourceLoaded(true);
    });

    map.current.on('click', 'live-view', (e) => {
      const coordinates = e.features[0].geometry.coordinates.slice();

      const description = `<h6> Device Serial: <b> ${
        e?.features[0]?.properties?.deviceSerial
      }</b>
        </h6>
        <h6> WiFi Name: <b>${e?.features[0]?.properties?.wifiName}</b> </h6>
      <h6> Battery: <b> ${
        e?.features[0]?.properties?.batteryPercent +
        ' (' +
        e?.features[0]?.properties?.isCharging +
        ')'
      }</b>
      </h6>
      <h6> Screen: <b>${e?.features[0]?.properties?.screenStatus}</b> </h6>
      <h6> Last Update: <b> ${
        e?.features[0]?.properties?.updatedAt &&
        moment(e?.features[0]?.properties?.updatedAt).format(
          'DD MMMM YYYY hh:mm A'
        )
      }</b></h6>`;

      // Ensure that if the map is zoomed out such that multiple
      // copies of the feature are visible, the popup appears
      // over the copy being pointed to.
      while (Math.abs(e.lngLat.lng - coordinates[0]) > 180) {
        coordinates[0] += e.lngLat.lng > coordinates[0] ? 360 : -360;
      }

      new mapboxgl.Popup()
        .setLngLat(coordinates)
        .setHTML(description)
        .addTo(map.current);
    });

    // Change the cursor to a pointer when the mouse is over the places layer.
    map.current.on('mouseenter', 'live-view', () => {
      map.current.getCanvas().style.cursor = 'pointer';
    });

    // Change it back to a pointer when it leaves.
    map.current.on('mouseleave', 'live-view', () => {
      map.current.getCanvas().style.cursor = '';
    });
  };

  useEffect(() => {
    if (
      locations &&
      locations.length >= 1 &&
      map.current.areTilesLoaded() &&
      map.current.getSource('live-view') &&
      mapSourceLoaded
    ) {
      const updatedLocationData = getUpdatedLocationData(locations);
      map.current.getSource('live-view').setData(updatedLocationData);
    }
  }, [locations, mapSourceLoaded]); // eslint-disable-line react-hooks/exhaustive-deps

  const getUpdatedLocationData = (locationsData) => {
    return {
      type: 'FeatureCollection',
      features: locationsData.map((location) => ({
        type: 'Feature',
        geometry: {
          type: 'Point',
          coordinates: [location?.position?.lng, location?.position?.lat],
        },
        properties: {
          batteryPercent: location?.batteryPercent,
          binaryCode: location?.binaryCode,
          deviceSerial: location?.deviceSerial,
          wifiName: location?.wifiName,
          isCharging: location?.isCharging,
          screenStatus: location?.screenStatus,
          updatedAt: location?.updatedAt,
          icon: 'GreenCar',
        },
      })),
    };
  };

  // const getCarIcon = (location) => {
  //   let deviceSerial = location.deviceSerial;
  //   let currentCarIcon = 'YellowCar';
  //   if (
  //     selectedCampaignData &&
  //     selectedCampaignData[0] &&
  //     selectedCampaignData[0]?.is_active &&
  //     activeDevices &&
  //     selectedCampaign &&
  //     activeDevices[selectedCampaign]
  //   ) {
  //     let devices = activeDevices[selectedCampaign];
  //     let objEntries = Object.entries(devices);
  //     objEntries.map((x) => {
  //       if (x[0] == deviceSerial && x[1].is_ad_running) {
  //         currentCarIcon = 'GreenCar';
  //       }
  //     });
  //   }
  //   return currentCarIcon;
  // };

  return (
    <MapBoxWindow>
      <div
        ref={mapContainer}
        className="map-container"
        style={{ height: `100%`, width: `100%` }}
      />
    </MapBoxWindow>
  );
};

export default DeviceMap;
