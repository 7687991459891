import styled from 'styled-components';

export const RidesDetailsMapBox = styled.div`
  height: 80vh;
  width: 100%;
  h5 {
    font: normal normal 300 16px Roboto;
    letter-spacing: 0px;
    color: #636465;
  }
  h5 > b {
    font-weight: 600;
  }
  h6 > b {
    font-weight: 600;
  }
  h6 {
    font-size: 14px;
    font: normal normal 300 14px Roboto;
    letter-spacing: 0px;
    color: #636465;
  }
  button.mapboxgl-popup-close-button {
    padding: 4px 8px 4px 8px;
  }
  .mapboxgl-popup-content{
    padding: 20px;
  }
`;

export const RidesDetailsInfoBox=styled.div`
    margin: 15px;

    .Ride-info-head{
      font: normal normal 600 2rem Roboto;
    }

    .m-1{
      margin:5px
    }

    .m-2{
      margin:10px
    }

    .ant-timeline-item-tail{
      border-left: 2px solid #a4a1a1 !important;
    }
`;
