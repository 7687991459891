import React, { useState } from 'react';
import { Button, Row, Col, Spin } from 'antd';
import {
  URLS,
  showErrorMsg,
  showSuccessMsg,
  httpGet,
  getUserLocalStorage,
  setUserLocalStorage,
  formatDate,
} from 'utils';
import { AppSettingsBox } from './_app-settings.styled';

const AppSettings = () => {
  const [isAMASync, setIsAMASync] = useState(false);

  const localUserData = getUserLocalStorage();

  const handleAMASyncData = () => {
    setIsAMASync(true);
    const url = URLS?.APP_SETTINGS.SYNC_ENROLLED_DEVICES_DATA;
    httpGet(url)
      .then((res) => {
        if (res.status === 200) {
          updateLocalUserData();
          showSuccessMsg('Data synced successfully!');
        } else if (res?.error) {
          showErrorMsg(res?.error);
        }
        setIsAMASync(false);
      })
      .catch((err) => {
        showErrorMsg(err);
        setIsAMASync(false);
      });
  };

  /**
   * updateLocalUserData function update the sync date time into the local storage
   */
  const updateLocalUserData = () => {
    localUserData.user_detail = {
      ...localUserData.user_detail,
      lastSyncEnrolledDevicesAt: new Date().toISOString(),
    };

    setUserLocalStorage(localUserData);
  };

  return (
    <AppSettingsBox>
      <div className="table-header">
        <h3 className="table-title">App Settings</h3>
      </div>
      <div className="while-bg-box">
        <Spin spinning={isAMASync}>
          <Row>
            <Col xs={24} sm={8}>
              <h3>{'Sync Android Management Data:'}</h3>
            </Col>
            <Col xs={24} sm={16}>
              <Button
                type="primary"
                disabled={isAMASync}
                onClick={handleAMASyncData}
              >
                {isAMASync ? 'Loading...' : 'Click here to sync'}
              </Button>
            </Col>
          </Row>
          {localUserData?.user_detail?.lastSyncEnrolledDevicesAt && (
            <Row>
              <Col xs={24} sm={12}>
                <label>{`Last Synced At: ${formatDate(
                  localUserData.user_detail.lastSyncEnrolledDevicesAt
                )}`}</label>
              </Col>
            </Row>
          )}
        </Spin>
      </div>
    </AppSettingsBox>
  );
};

export default AppSettings;
