import React from 'react';
import { bool, object } from 'prop-types';
import { Row, Col, Collapse, Skeleton } from 'antd';
import { CollapseBox } from './_rides-info.styled';

const { Panel } = Collapse;

const RidesInfo = (props) => {
  const { deviceDetails, isFetching } = props;

  const renderLableColumnRows = (
    label,
    value,
    rowGutter = 18,
    lableColSpacing = 6,
    valueColSpacing = 18
  ) => {
    return (
      <Row gutter={rowGutter} style={{ margin: '15px' }}>
        <Col className="gutter-row" xs={lableColSpacing}>
          <strong>{label}</strong>
        </Col>
        <Col className="gutter-row" xs={valueColSpacing}>
          <Skeleton
            loading={isFetching}
            active
            paragraph={{ rows: 1, width: '100%' }}
            title={false}
          >
            {value}
          </Skeleton>
        </Col>
      </Row>
    );
  };

  return (
    <CollapseBox>
      <Collapse defaultActiveKey={['1']}>
        <Panel header="Cab Info" key="1">
          {renderLableColumnRows('Taxi Number: ', deviceDetails?.taxi_number)}
          {renderLableColumnRows(
            'Morning Driver Name: ',
            deviceDetails?.morning_driver_name
          )}
          {renderLableColumnRows(
            'Morning Driver Mobile: ',
            deviceDetails?.morning_driver_mobile
          )}
          {renderLableColumnRows(
            'Evening Driver Name: ',
            deviceDetails?.evening_driver_name
          )}
          {renderLableColumnRows(
            'Evening Driver Mobile: ',
            deviceDetails?.evening_driver_mobile
          )}
        </Panel>
      </Collapse>
    </CollapseBox>
  );
};

RidesInfo.propTypes = {
  deviceDetails: object,
  isFetching: bool,
};

export default RidesInfo;
