import React from 'react';
import { Tabs } from 'antd';
import { CampaignsBox } from './_campaigns.styled';
import CommonBreadCrumb from 'components/common-breadcrumb';
import CampaignsTableList from './components/campaignList';
const { TabPane } = Tabs;

const CampaignsList = () => {
  const breakCrumbList = [
    { label: 'Home', link: '/dashboard' },
    { label: 'Campaigns' },
  ];


  return (
    <CampaignsBox>
      <CommonBreadCrumb breadCrumbs={breakCrumbList} />
      <Tabs defaultActiveKey="1">
        <TabPane tab="Active Campaigin" key="1">
          <CampaignsTableList isActive />
        </TabPane>
        <TabPane tab="Inactive Campaigin" key="2">
          <CampaignsTableList isInActive />
        </TabPane>
        <TabPane tab="Unapproved Campaign" key="3">
          <CampaignsTableList isUnapproved />
        </TabPane>
        <TabPane tab="Draft Campaign" key="4">
          <CampaignsTableList isDraft />
        </TabPane>
      </Tabs>
    </CampaignsBox>
  );
};

export default CampaignsList;
