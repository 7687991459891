import React, { useState, useContext } from 'react';
import { Form, Input, Button } from 'antd';
import { MailOutlined, LockOutlined } from '@ant-design/icons';
import { Link, useHistory } from 'react-router-dom';
import { LoginBox } from './_login.styled';
import {
  URLS,
  httpPost,
  showSuccessMsg,
  showErrorMsg,
  setUserLocalStorage,
} from 'utils';
import { setAuth, Context } from 'hooks';

const Login = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [emailValidateType, setEmailValidateType] = useState('onBlur');
  const [passwordValidateType, setPasswordValidateType] = useState('onBlur');
  const { dispatchAuth } = useContext(Context);
  const history = useHistory();

  const onFinish = async (values) => {
    const validationErrors = Object.values(values);
    if (validationErrors.find((e) => e === undefined)) return;
    setIsLoading(true);
    const url = URLS.AUTH.SIGN_IN;
    const params = {
      email: values?.email || '',
      password: values?.password || '',
    };
    httpPost(url, params)
      .then((res) => {
        if (res?.status === 200) {
          if (
            res?.data?.user_detail?.role?.role_type !== 'consoleUser' &&
            res?.data?.user_detail?.role?.role_type !== 'superadmin'
          ) {
            showErrorMsg('Incorrect email or password.');
          } else {
            showSuccessMsg('User login successfully');
            if (res?.data?.user_detail) {
              const { id, email } = res?.data?.user_detail || {};
              dispatchAuth(setAuth({ id, email }));
              setUserLocalStorage(res?.data || '');
            }
            history.push('/dashboard');
          }
        } else {
          showErrorMsg(res.error);
        }
        setIsLoading(false);
      })
      .catch((err) => {
        showErrorMsg(err);
        setIsLoading(false);
      });
  };

  return (
    <LoginBox>
      <div className="login">
        <div className="login-content">
          <h1>Log in to your account</h1>
          <Form
            name="normal_login"
            className="login-form"
            onFinish={onFinish}
            size="large"
          >
            <Form.Item
              name="email"
              validateTrigger={emailValidateType}
              rules={[
                {
                  type: 'email',
                  message: 'The input is not valid E-mail!',
                },
                {
                  required: true,
                  message: 'Please input your E-mail!',
                },
              ]}
            >
              <Input
                prefix={<MailOutlined className="site-form-item-icon" />}
                placeholder="Email"
                onBlur={() => setEmailValidateType('onChange')}
              />
            </Form.Item>
            <Form.Item
              name="password"
              validateTrigger={passwordValidateType}
              rules={[
                { required: true, message: 'Please input your Password!' },
              ]}
            >
              <Input.Password
                prefix={<LockOutlined className="site-form-item-icon" />}
                type="password"
                placeholder="Password"
                onBlur={() => setPasswordValidateType('onChange')}
              />
            </Form.Item>
            <Form.Item className="remember-forgot">
              <Link to="/forgot-password" className="login-form-forgot">
                Forgot password
              </Link>
            </Form.Item>
            <Form.Item>
              <Button
                type="primary"
                htmlType="submit"
                className="login-form-button"
                block
                disabled={isLoading}
              >
                {isLoading ? 'Loading...' : 'Login'}
              </Button>
            </Form.Item>
          </Form>
        </div>
      </div>
    </LoginBox>
  );
};

export default Login;
