import React, { useState, useEffect } from 'react';
import moment from 'moment';
import { uuid } from 'uuidv4';
import {
  Form,
  Row,
  Col,
  Select,
  Input,
  Button,
  Popconfirm,
  InputNumber,
  Checkbox,
} from 'antd';
import CompanyModal from 'components/company-modal';
import { useHistory, useParams } from 'react-router-dom';
import {
  CAMPAIGN_TYPES_LIST,
  CAMPAIGN_TYPES,
  INDUSTRY_TYPES_LIST,
  MODE_OF_COMMUNICATION,
} from 'constants/campaign';
import {
  AddEditCampaignBox,
  QRCodeBox,
  QrCodeDownloadBox,
} from './_add-edit-campaign.styled';
import {
  httpDelete,
  httpGet,
  httpPost,
  httpPut,
  URLS,
  showErrorMsg,
  showSuccessMsg,
  URLS_V2,
} from 'utils';
import WhereToTarget from './where-to-target';
import Media from './media';
import Communication from './communication';
import AllotedSpots from './alloted-spots';
import { DownloadOutlined } from '@ant-design/icons';
import validUrl from 'valid-url';
// import { DAYS } from 'constants/campaign';

const QRCode = require('qrcode.react');

const { Option } = Select;

// const specificDayStructure = {
//   [DAYS.MONDAY.toLowerCase()]: { from: '', to: '' },
//   [DAYS.TUESDAY.toLowerCase()]: { from: '', to: '' },
//   [DAYS.WEDNESDAY.toLowerCase()]: { from: '', to: '' },
//   [DAYS.THURSDAY.toLowerCase()]: { from: '', to: '' },
//   [DAYS.FRIDAY.toLowerCase()]: { from: '', to: '' },
//   [DAYS.SATURDAY.toLowerCase()]: { from: '', to: '' },
//   [DAYS.SUNDAY.toLowerCase()]: { from: '', to: '' },
// };

const AddEditCampaigns = () => {
  const [isGeoFence, setIsGeoFence] = useState(false);
  const [validateFieldsName, setValidateFieldsName] = useState([]);
  const [companiesList, setCompaniesList] = useState([]);
  const [timeSlots, setTimeSlots] = useState([]);
  const [isCompaniesListFetching, setIsCompaniesListFetching] = useState(false);
  const [isCompanyModalVisible, setIsCompanyModalVisible] = useState(false);
  const [isCustomFrequency, setIsCustomFrequency] = useState(false);
  const [isCompanyAdding, setIsCompanyAdding] = useState(false);
  const [isCampaignAdding, setIsCampaignAdding] = useState(false);
  const [isCampaignUpdating, setIsCampaignUpdating] = useState(false);
  const [isMediaImageUpdating, setIsMediaImageUpdating] = useState(false);
  const [isMediaVideoUpdating, setIsMediaVideoUpdating] = useState(false);
  const [isTemplateUpdating, setIsTemplateUpdating] = useState(false);
  const [modeOfCommunication, setModeOfCommunication] = useState(
    MODE_OF_COMMUNICATION.EMAIL
  );
  const [mobileText, setMobileText] = useState('');
  const [isFetchingCampaign, setIsFetchingCampaign] = useState(false);
  const [form] = Form.useForm();
  const [companyForm] = Form.useForm();
  const history = useHistory();
  const params = useParams();
  const [locations, setLocations] = useState([]);
  const [selectedCampaignType, setSelectedCampaignType] = useState('');
  // const [selectedClassCampaign, setSelectedClassCampaign] = useState('');
  const [selectMediaImage, setSelectMediaImage] = useState('');
  const [selectMediaVideo, setSelectMediaVideo] = useState('');
  const [selectTemplate, setSelectTemplate] = useState('');
  const [campaignStartEndDate, setCampaignStartEndDate] = useState('');
  const [, setisCampaignSavingAsDraft] = useState(false);
  const [active, setActive] = useState(false);
  const [draft, setDraft] = useState();
  const [isCampaignDeleting, setIsCampaignDeleting] = useState();
  const [isCampaignDeactivating, setIsCampaignDeactivating] = useState();
  const [isCampaignApproved, setIsCampaignApproved] = useState(false);

  const allowRedirectURLInput = [
    CAMPAIGN_TYPES.WEBSITE_VISITS.value,
    // CAMPAIGN_TYPES.BRAND_AWARENESS,
  ];

  // selectedCampaignType={selectedCampaignType}
  // selectMediaImage={selectMediaImage}
  // setSelectMediaImage={setSelectMediaImage}
  // isMediaImageUpdating={isMediaImageUpdating}
  // setIsMediaImageUpdating={setIsMediaImageUpdating}
  // selectMediaVideo={selectMediaVideo}
  // isMediaVideoUpdating={isMediaVideoUpdating}
  // setIsMediaVideoUpdating={setIsMediaVideoUpdating}
  // setSelectMediaVideo={setSelectMediaVideo}


  const allowAutoClickInput = [CAMPAIGN_TYPES.BRAND_AWARENESS.value];

  useEffect(() => {
    fetchCompanies();
    if (params?.id) {
      fetchCampaign({ id: params?.id });
    } else {
      setSelectedCampaignType(CAMPAIGN_TYPES.BRAND_AWARENESS.value);
      form.setFieldsValue({
        campaign_type: CAMPAIGN_TYPES.BRAND_AWARENESS.value,
      });
    }
  }, []);

  const fetchCampaign = ({ id } = {}) => {
    setIsFetchingCampaign(true);
    const url = `${URLS_V2?.CAMPAIGNS?.GET_SINGLE_CAMPAIGN.replace(
      '#CAMPAIGN_ID#',
      id
    )}`;
    httpGet(url)
      .then((res) => {
        if (res.status === 200) {
          if (
            res?.data &&
            Object.entries(res?.data) &&
            Object.entries(res?.data).length > 0
          ) {
            Object.entries(res?.data).forEach(([key, value]) => {
              if (key === 'campaign_type') {
                setSelectedCampaignType(value);
              }
              // if (key === 'campaign_class') {
              //   setSelectedClassCampaign(value);
              // }
              if (key === 'is_Draft') {
                setDraft(value);
              }
              if (key === 'is_active') {
                setIsCampaignDeactivating(value);
              }
              if (key === 'is_Unapproved') {
                setIsCampaignApproved(!value);
              }

              if (key === 'campaign_start_date') {
                const dateValue = (value && moment(value)) || '';
                if (dateValue) {
                  form.setFieldsValue({
                    campaign_start_date: [dateValue],
                  });
                }
              }
              if (key === 'campaign_end_date') {
                const dateValue = (value && moment(value)) || '';
                if (dateValue) {
                  form.setFieldsValue({
                    campaign_end_date: [dateValue],
                  });
                }
              }
              if (key === 'per_ride_frequency' && value && value > 0) {
                setIsCustomFrequency(true);
              }
              if (key === 'mode_of_communication') {
                setModeOfCommunication(value);
              }
              if (key === 'mobile_text') {
                setMobileText(value);
              }
              if (key === 'media_image_url') {
                setSelectMediaImage(value);
              }
              if (key === 'media_video_url') {
                setSelectMediaVideo(value);
              }
              if (key === 'template_url') {
                setSelectTemplate(value);
              }
              if (key === 'time_slots' && value) {
                setTimeSlots(value);
              }
              if (key === 'is_GeofenceCampaign' && value) {
                setIsGeoFence(value);
              }
              // if (key === 'geofence_Campaign' && value) {
              //   const geofenceDetails = value?.map((val, index) => {
              //     return {
              //       lat: val?.coordinates?.[0]?.lat,
              //       long: val?.coordinates?.[0]?.long,
              //       location_name: val?.geofence_title,
              //       radius: val?.radius,
              //       _id: val?._id,
              //       location_type: 'Current Location',
              //     };
              //   });

              //   setLocations(geofenceDetails);
              // }
              if (key === 'locations') {
                let newValues = [];
                if (value && value.length > 0) {
                  
                  newValues = value.map((data) => {
                    return { ...data, identifier: uuid() };
                  });
                }

                setLocations(newValues || []);
              } else {
                form.setFieldsValue({
                  [key]: value,
                });
              }
            });
          }
        } else if (res?.error) {
          showErrorMsg(res?.error);
        }
        const getCampaignStartValue =
          form && form.getFieldValue('campaign_start_date');
        const getCampaignEndValue =
          form && form.getFieldValue('campaign_end_date');
        if (getCampaignStartValue && getCampaignEndValue) {
          setCampaignStartEndDate([
            moment(getCampaignStartValue),
            moment(getCampaignEndValue),
          ]);
        }
        setIsFetchingCampaign(false);
      })
      .catch((err) => {
        showErrorMsg(err);
        setIsFetchingCampaign(false);
      });
  };

  const fetchCompanies = ({ company_id } = {}) => {
    setIsCompaniesListFetching(true);
    httpGet(URLS_V2?.CAMPAIGNS?.COMPANIES_LIST)
      .then((res) => {
        if (res.status === 200) {
          setCompaniesList(res?.data || []);
          if (company_id) form.setFieldsValue({ company_id });
        } else if (res?.error) {
          showErrorMsg(res?.error);
        }
        setIsCompaniesListFetching(false);
      })
      .catch((err) => {
        showErrorMsg(err);
        setIsCompaniesListFetching(false);
      });
  };

  const handleValidateFieldNames = (name) => {
    const isFieldName =
      validateFieldsName &&
      validateFieldsName.find((fieldName) => fieldName === name);
    if (isFieldName) return 'onChange';
    return 'onBlur';
  };

  const openCompanyModal = () => {
    setIsCompanyModalVisible(true);
  };

  const closeCompanyModal = () => {
    setIsCompanyModalVisible(false);
  };

  const handleAddEditCampaign = ({
    isDraft,
    msg,
    newDraft = false,
    approve = false,
  } = {}) => {
    setActive(!isDraft);
    form.submit();
    form
      .validateFields()
      .then(async (values) => {
        if (params?.id) {
          if (values.campaign_type == CAMPAIGN_TYPES.CAPTURE_LEADS.value) {
            !selectMediaVideo && !selectMediaImage && !isDraft
              ? showErrorMsg('Please Upload Media')
              : handleEditCampaign({
                  values: values,
                  active: !isDraft,
                  msg: msg,
                });
          } else if (
            values.campaign_type == CAMPAIGN_TYPES.BRAND_AWARENESS.value
          ) {
            !selectMediaVideo && !isDraft
              ? showErrorMsg('Please Upload Media')
              : handleEditCampaign({
                  values: values,
                  active: !isDraft,
                  msg: msg,
                  newDraft,
                  approve,
                });
          } else if (
            values.campaign_type == CAMPAIGN_TYPES.WEBSITE_VISITS.value
          ) {
            !selectMediaVideo && !selectMediaImage && !isDraft
              ? showErrorMsg('Please Upload Media')
              : handleEditCampaign({
                  values: values,
                  active: !isDraft,
                  msg: msg,
                  newDraft,
                  approve,
                });
          } else if (!active) {
            handleEditCampaign({
              values: values,
              active: !isDraft,
              msg: msg,
              newDraft,
              approve,
            });
          }
        } else {
          handleAddCampaign(values, !isDraft);
        }
      })
      .catch((errorInfo) => errorInfo);
  };

  const handleActivateCampaigne = () => {
    // form.submit();
    form.validateFields().then(async (values) => {
      handleActivate({
        values: values,
        // active: !isDraft,
        // msg: msg,
        // newDraft,
        // approve,
      });
    });
  };

  const handleActivate = ({ values, active, msg, newDraft, approve } = {}) => {
    const geofenceLocations = locations?.map((value) => {
      return {
        coordinates: [
          {
            lat: value?.lat,
            long: value?.long,
          },
        ],
        geofence_title: value?.location_name,
        radius: value?.radius,
      };
    });

    let campaignParams = {
      // campaign_class: values.campaign_class || '',
      maximum_daily_slots: values?.maximum_daily_slots || 0,
      is_active: active,
      campaign_name: values?.campaign_name || '',
      campaign_type: values?.campaign_type || '',
      company_id: values?.company_id || '',
      industry_type: values?.industry_type || '',
      slots: values?.slots || 0,
      per_ride_frequency: values?.per_ride_frequency || 1,
      duration: values?.duration || 0,
      locations,
      redirect_url: values?.redirect_url || '',
      auto_click_frequency: values?.auto_click_frequency || 0,
      gender: values?.gender || '',
      age: values?.age || '',
      // language: values?.language || '',
      is_every_day: false, // not implemented yet todo
      // mode_of_communication: modeOfCommunication,
      mobile_text: values?.mobile_text,
      time_slots: timeSlots || [],
      media_image_url: selectMediaImage || '',
      media_video_url: selectMediaVideo || '',
      template_url: selectTemplate || '',
      campaign_start_date: '',
      campaign_end_date: '',
      // is_Draft: newDraft,
      is_Unapproved: approve,
      is_GeofenceCampaign: isGeoFence,
      radius: locations?.[0]?.radius,
      geofence_Campaign: geofenceLocations,
    };

    if (campaignStartEndDate && campaignStartEndDate.length > 1) {
      campaignParams = {
        ...campaignParams,
        campaign_start_date: moment().unix(campaignStartEndDate[0]),
        campaign_end_date: moment().unix(campaignStartEndDate[1]),
      };
    }
    // if (active === true) {
    //   setIsCampaignUpdating(true);
    // } else {
    //   setisCampaignSavingAsDraft(true);
    // }
    const url = URLS_V2?.CAMPAIGNS?.UPDATE_CAMPAIGN.replace(
      '#CAMPAIGN_ID#',
      params?.id
    );
    httpPut(url, campaignParams)
      .then((res) => {
        if (res.status === 200) {
          // showSuccessMsg(msg);
          // setDraft(res?.data?.is_Draft);
          setIsCampaignApproved(res?.data?.is_Unapproved);
          // updateFirebaseGlobalAppSettings(GLOBAL_APP_SETTINGS_KEYS.campaign);
          history.push('/v2/campaigns');
        } else if (res?.error) {
          showErrorMsg(res?.error);
        }
        setIsCampaignUpdating(false);
        setisCampaignSavingAsDraft(false);
      })
      .catch((err) => {
        showErrorMsg(err);
        setIsCampaignUpdating(false);
        setisCampaignSavingAsDraft(false);
      });
  };

  const handleEditCampaign = ({
    values,
    active,
    msg,
    newDraft,
    approve,
  } = {}) => {
    const geofenceLocations = locations?.map((value) => {
      return {
        coordinates: [
          {
            lat: value?.lat,
            long: value?.long,
          },
        ],
        geofence_title: value?.location_name,
        radius: value?.radius,
      };
    });

    let campaignParams = {
      // campaign_class: values.campaign_class || '',
      maximum_daily_slots: values?.maximum_daily_slots || 0,
      is_active: active,
      campaign_name: values?.campaign_name || '',
      campaign_type: values?.campaign_type || '',
      company_id: values?.company_id || '',
      industry_type: values?.industry_type || '',
      slots: values?.slots || 0,
      per_ride_frequency: values?.per_ride_frequency || 1,
      duration: values?.duration || 0,
      locations,
      redirect_url: values?.redirect_url || '',
      auto_click_frequency: values?.auto_click_frequency || 0,
      gender: values?.gender || '',
      age: values?.age || '',
      // language: values?.language || '',
      is_every_day: false, // not implemented yet todo
      mode_of_communication: modeOfCommunication,
      mobile_text: values?.mobile_text,
      time_slots: timeSlots || [],
      media_image_url: selectMediaImage || '',
      media_video_url: selectMediaVideo || '',
      template_url: selectTemplate || '',
      campaign_start_date: '',
      campaign_end_date: '',
      is_Draft: newDraft,
      is_Unapproved: approve,
      is_GeofenceCampaign: isGeoFence,
      geofence_Campaign: geofenceLocations,
    };
    if (campaignStartEndDate && campaignStartEndDate.length > 1) {
      campaignParams = {
        ...campaignParams,
        campaign_start_date: moment().unix(campaignStartEndDate[0]),
        campaign_end_date: moment().unix(campaignStartEndDate[1]),
      };
    }
    if (active === true) {
      setIsCampaignUpdating(true);
    } else {
      setisCampaignSavingAsDraft(true);
    }
    const url = URLS_V2?.CAMPAIGNS?.UPDATE_CAMPAIGN.replace(
      '#CAMPAIGN_ID#',
      params?.id
    );
    httpPut(url, campaignParams)
      .then((res) => {
        if (res.status === 200) {
          showSuccessMsg(msg);
          setDraft(res?.data?.is_Draft);
          setIsCampaignApproved(res?.data?.is_Unapproved);
          // updateFirebaseGlobalAppSettings(GLOBAL_APP_SETTINGS_KEYS.campaign);
          history.push('/v2/campaigns');
        } else if (res?.error) {
          showErrorMsg(res?.error);
        }
        setIsCampaignUpdating(false);
        setisCampaignSavingAsDraft(false);
      })
      .catch((err) => {
        showErrorMsg(err);
        setIsCampaignUpdating(false);
        setisCampaignSavingAsDraft(false);
      });
  };

  const handleAddCampaign = (values, active) => {
    const campaignParams = {
      // campaign_class: values.campaign_class || '',
      is_active: active,
      campaign_name: values?.campaign_name || '',
      campaign_type: values?.campaign_type || '',
      company_id: values?.company_id || '',
      industry_type: values?.industry_type || '',
      slots: values?.slots || 0,
      maximum_daily_slots: values?.maximum_daily_slots || 0,
      per_ride_frequency: values?.per_ride_frequency || 1,
      duration: values?.duration || 0,
      redirect_url: values?.redirect_url || '',
      auto_click_frequency: values?.auto_click_frequency || 0,
    };
    setIsCampaignAdding(true);
    httpPost(URLS_V2?.CAMPAIGNS?.CREATE_CAMPAIGN, campaignParams)
      .then((res) => {
        if (res.status === 201) {
          showSuccessMsg('Campaign added successfully!');
          closeCompanyModal();
          form.resetFields();
          // updateFirebaseGlobalAppSettings(GLOBAL_APP_SETTINGS_KEYS.campaign);
          history.push(`edit/${res?.data?._id}`);
          fetchCampaign({ id: res?.data?._id });
        } else if (res?.error) {
          showErrorMsg(res?.error);
        }
        setIsCampaignAdding(false);
      })
      .catch((err) => {
        showErrorMsg(err);
        setIsCampaignAdding(false);
      });
  };

  const handleAddCompany = () => {
    companyForm.submit();
    companyForm
      .validateFields()
      .then((data) => {
        const params = {
          company_name: data?.company_name || '',
          contact_person_name: data?.contact_person_name || '',
          email: data?.email || '',
          phone_number: data?.phone_number || '',
        };
        setIsCompanyAdding(true);
        httpPost(URLS?.COMPANY?.ADD_COMPANY, params)
          .then((res) => {
            if (res.status === 200) {
              showSuccessMsg('Company added successfully!');
              closeCompanyModal();
              companyForm.resetFields();
              fetchCompanies({ company_id: res?.data?._id });
            } else if (res?.error) {
              showErrorMsg(res?.error);
            }
            setIsCompanyAdding(false);
          })
          .catch((err) => {
            showErrorMsg(err);
            setIsCompanyAdding(false);
          });
      })
      .catch((errorInfo) => errorInfo);
  };

  const downloadCampaignQR = () => {
    const canvas = document.getElementById('campaign-qr');
    const pngUrl = canvas
      .toDataURL('image/png')
      .replace('image/png', 'image/octet-stream');
    let downloadLink = document.createElement('a');
    downloadLink.href = pngUrl;
    downloadLink.download = 'campaign-qr.png';
    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
  };

  const handleCampaignType = () => {
    setSelectedCampaignType(form && form.getFieldValue('campaign_type'));
  };
  // const handleClassCampaign = () => {
  //   setSelectedClassCampaign(form && form.getFieldValue('campaign_class'));
  // };

  const handleDeleteCampaign = () => {
    setIsCampaignDeleting(true);
    const url = `${URLS_V2?.CAMPAIGNS?.DELETE_CAMPAIGN.replace(
      '#CAMPAIGN_ID#',
      params?.id
    )}`;
    httpDelete(url)
      .then((res) => {
        if (res.status === 200) {
          showSuccessMsg('Campaign deleted successfully!');
          // updateFirebaseGlobalAppSettings(GLOBAL_APP_SETTINGS_KEYS.campaign);
          history.push('/v2/campaigns');
        } else if (res?.error) {
          showErrorMsg(res?.error);
        }
        setIsCampaignDeleting('');
      })
      .catch((err) => {
        showErrorMsg(err);
        setIsCampaignDeleting('');
      });
  };

  // const handleapproveCampaign = () => {
  //   const url = `${URLS_V2?.CAMPAIGNS?.APPROVE_CAMPAIGN.replace(
  //     '#CAMPAIGN_ID#',
  //     params?.id
  //   )}?approveStatus=${false}`;
  //   httpPut(url)
  //     .then((res) => {
  //       if (res.status === 200) {
  //         showSuccessMsg('Campaign approved successfully!');
  //         // updateFirebaseGlobalAppSettings(GLOBAL_APP_SETTINGS_KEYS.campaign);
  //         history.push('/v2/campaigns');
  //       } else if (res?.error) {
  //         showErrorMsg(res?.error);
  //       }
  //       setIsCampaignApproved(isCampaignApproved);
  //     })
  //     .catch((err) => {
  //       showErrorMsg(err);
  //     });
  // };

  const handleInactiveCampaign = () => {
    setIsCampaignDeactivating(true);
    const url = `${URLS_V2?.CAMPAIGNS?.UPDATE_CAMPAIGN_STATUS.replace(
      '#CAMPAIGN_ID#',
      params?.id
    )}?status=${false}`;
    httpPut(url)
      .then((res) => {
        if (res.status === 200) {
          showSuccessMsg('Campaign deactivated successfully!');
          // const url =
          //   URLS?.CAMPAIGN?.DELETE_BOOKEDINVENTORY_BY_CAMPAIGN_ID.replace(
          //     '#ID#',
          //     params?.id
          //   );
          // httpDelete(url)
          //   .then((res) => {
          //     if (res.status === 200) {
          //       // updateFirebaseGlobalAppSettings(GLOBAL_APP_SETTINGS_KEYS.campaign);
          //       // history.push('/v2/campaigns');
          //     } else if (res?.error) {
          //       showErrorMsg(res?.error);
          //     }
          //     setIsCampaignDeactivating('');
          //   })
          //   .catch((err) => {
          //     showErrorMsg(err);
          //     setIsCampaignDeleting('');
          //   });
          // updateFirebaseGlobalAppSettings(GLOBAL_APP_SETTINGS_KEYS.campaign);
          setIsCampaignDeleting('');
          history.push('/v2/campaigns');
        } else if (res?.error) {
          showErrorMsg(res?.error);
        }
      })
      .catch((err) => {
        showErrorMsg(err);
      });
  };

  const handleRedirectURLChange = (redirectURLValue) => {
    if (
      selectedCampaignType === CAMPAIGN_TYPES.BRAND_AWARENESS.value &&
      !redirectURLValue
    ) {
      form.setFieldsValue({
        auto_click_frequency: 0,
      });
    }
  };

  const handleGeofenceCheckboxChange = () => {
    setIsGeoFence(!isGeoFence);
  };
  return (
    <AddEditCampaignBox>
      {isFetchingCampaign ? (
        'Fetching...'
      ) : (
        <Form form={form} layout="vertical">
          {/* <Row gutter={16}>
            <Col className="gutter-row" xs={16} sm={6}>
              <Form.Item
                name="campaign_class"
                label="Campaign Class"
                rules={[
                  {
                    required: true,
                    message: 'Please select Campaign Class!',
                  },
                ]}
              >
                <Select
                  showSearch
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    option.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                  placeholder="Select Campaign Class"
                  onChange={handleClassCampaign}
                  disabled={Boolean(params?.id)}
                >
                  {CAMPAIGN_CLASSES_LIST &&
                    CAMPAIGN_CLASSES_LIST.length > 0 &&
                    CAMPAIGN_CLASSES_LIST.map((data, index) => (
                      <Option key={index} value={data}>
                        {data}
                      </Option>
                    ))}
                </Select>
              </Form.Item>
            </Col>
          </Row> */}
          <Row gutter={16}>
            <Col className="gutter-row" xs={24} sm={12} md={12}>
              <Row gutter={16}>
                <Col className="gutter-row" xs={24} sm={12}>
                  <Form.Item
                    name="campaign_name"
                    label="Campaign Name"
                    validateTrigger={handleValidateFieldNames('campaign_name')}
                    rules={[
                      {
                        required: true,
                        message: 'Please enter Campaign Name!',
                      },
                    ]}
                  >
                    <Input
                      type="text"
                      placeholder="Campaign Name"
                      onBlur={() =>
                        setValidateFieldsName([
                          ...validateFieldsName,
                          'campaign_name',
                        ])
                      }
                    />
                  </Form.Item>
                </Col>
                <Col className="gutter-row" xs={24} sm={12}>
                  <div className="select-company">
                    <Form.Item
                      name="company_id"
                      label="Company"
                      rules={[
                        {
                          required: true,
                          message: 'Please select company!',
                        },
                      ]}
                    >
                      <Select
                        placeholder={
                          isCompaniesListFetching
                            ? 'Fetching...'
                            : 'Select Company'
                        }
                        showSearch
                        optionFilterProp="children"
                        filterOption={(input, option) =>
                          option.children
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                        }
                      >
                        {companiesList &&
                          companiesList.length > 0 &&
                          companiesList.map((company, index) => (
                            <Option value={company?._id} key={index}>
                              {company?.company_name || ''}
                            </Option>
                          ))}
                      </Select>
                    </Form.Item>
                    <Button
                      type="primary"
                      className="add-btn"
                      onClick={openCompanyModal}
                    >
                      Add
                    </Button>
                    <CompanyModal
                      form={companyForm}
                      isCompanyModalVisible={isCompanyModalVisible}
                      modalTitle="Add company"
                      handleOnCancel={closeCompanyModal}
                      handleAddCompany={handleAddCompany}
                      isCompanyAdding={isCompanyAdding}
                    />
                  </div>
                </Col>
                <Col className="gutter-row" xs={24} sm={12}>
                  <Form.Item
                    name="industry_type"
                    label="Industry Type"
                    rules={[
                      {
                        required: true,
                        message: 'Please select Industry Type!',
                      },
                    ]}
                  >
                    <Select
                      showSearch
                      optionFilterProp="children"
                      filterOption={(input, option) =>
                        option.children
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      }
                      placeholder="Select Industry Type"
                    >
                      {INDUSTRY_TYPES_LIST &&
                        INDUSTRY_TYPES_LIST.length > 0 &&
                        INDUSTRY_TYPES_LIST.map((data, index) => (
                          <Option key={index} value={data}>
                            {data}
                          </Option>
                        ))}
                    </Select>
                  </Form.Item>
                </Col>
                <Col className="gutter-row" xs={24} sm={12}>
                  <Form.Item
                    name="campaign_type"
                    label="Campaign Type"
                    rules={[
                      {
                        required: true,
                        message: 'Please select campaign type!',
                      },
                    ]}
                  >
                    <Select
                      showSearch
                      optionFilterProp="children"
                      filterOption={(input, option) =>
                        option.children
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      }
                      placeholder="Select Campaign Type"
                      onChange={handleCampaignType}
                      disabled={Boolean(params?.id)}
                    >
                      {CAMPAIGN_TYPES_LIST.length > 0 &&
                        CAMPAIGN_TYPES_LIST.map((data) => (
                          <Option
                            key={data.label}
                            value={data?.value ? data?.value : data}
                          >
                            {data?.label ? data?.label : data}
                          </Option>
                        ))}
                    </Select>
                  </Form.Item>
                </Col>
                {allowRedirectURLInput.includes(selectedCampaignType) && (
                  <Col className="gutter-row" xs={24} sm={24}>
                    <Form.Item
                      name="redirect_url"
                      label="Redirect url"
                      validateTrigger={handleValidateFieldNames('redirect_url')}
                      rules={[
                        {
                          required:
                            selectedCampaignType ===
                            CAMPAIGN_TYPES.WEBSITE_VISITS.value,
                          message: 'Please enter redirect url!',
                        },
                        {
                          validator(_, value) {
                            if (value && !validUrl.isUri(value)) {
                              return Promise.reject('Please enter valid url');
                            }
                            return Promise.resolve();
                          },
                        },
                      ]}
                    >
                      <Input
                        type="text"
                        placeholder="Redirect Url"
                        onChange={(event) =>
                          handleRedirectURLChange(event?.target?.value)
                        }
                        onBlur={() =>
                          setValidateFieldsName([
                            ...validateFieldsName,
                            'redirect_url',
                          ])
                        }
                      />
                    </Form.Item>
                  </Col>
                )}
                {allowAutoClickInput.includes(selectedCampaignType) &&
                  params?.id && (
                    <Col className="gutter-row" xs={24} sm={12}>
                      <Form.Item
                        name="auto_click_frequency"
                        label="Auto Click frequency"
                        validateTrigger={handleValidateFieldNames(
                          'auto_click_frequency'
                        )}
                        rules={[
                          () => ({
                            validator(_, value) {
                              if (
                                value &&
                                value < 0 &&
                                form?.getFieldValue('redirect_url')
                              ) {
                                return Promise.reject(
                                  new Error(
                                    'Auto click frequency must be greater than 0'
                                  )
                                );
                              }
                              return Promise.resolve();
                            },
                          }),
                        ]}
                      >
                        <InputNumber
                          type="number"
                          min={0}
                          max={999}
                          defaultValue={0}
                          placeholder="Auto Click frequency"
                          value={
                            !form?.getFieldValue('redirect_url')
                              ? 0
                              : form?.getFieldValue('auto_click_frequency')
                          }
                          disabled={!form?.getFieldValue('redirect_url')}
                          onBlur={() =>
                            setValidateFieldsName([
                              ...validateFieldsName,
                              'auto_click_frequency',
                            ])
                          }
                        />
                      </Form.Item>
                    </Col>
                  )}
                {!params?.id && (
                  <Col className="gutter-row" xs={24} sm={12}>
                    <Popconfirm
                      title={
                        <>
                          Campaign type and Campaign Class can not be changed
                          once created. <br /> Are you sure you want to keep
                          campaign type as {selectedCampaignType} and campaign
                          class ?
                        </>
                      }
                      onConfirm={handleAddEditCampaign}
                    >
                      <Button
                        type="primary"
                        className="save-btn"
                        disabled={isCampaignAdding}
                      >
                        {isCampaignAdding && 'Loading...'}
                        {!isCampaignAdding &&
                          selectedCampaignType !==
                            CAMPAIGN_TYPES.WEBSITE_VISITS.value &&
                          'Save and continue'}
                        {!isCampaignAdding &&
                          selectedCampaignType ===
                            CAMPAIGN_TYPES.WEBSITE_VISITS.value &&
                          'Save and generate QR code'}
                      </Button>
                    </Popconfirm>
                  </Col>
                )}
              </Row>
            </Col>
            <Col className="gutter-row" xs={24} sm={12} md={6}>
              {params?.id &&
                selectedCampaignType ===
                  CAMPAIGN_TYPES.WEBSITE_VISITS.value && (
                  <>
                    <QRCodeBox>
                      <QRCode
                        id="campaign-qr"
                        size={200}
                        value={
                          window.location.origin + '/campaign-qr/' + params?.id
                        }
                      />
                    </QRCodeBox>
                  </>
                )}
            </Col>
            <Col className="gutter-row" xs={24} sm={12} md={6}>
              {params?.id &&
                selectedCampaignType ===
                  CAMPAIGN_TYPES.WEBSITE_VISITS.value && (
                  <QrCodeDownloadBox>
                    <div>
                      <a onClick={downloadCampaignQR}>
                        <DownloadOutlined /> Download QR
                      </a>
                    </div>
                  </QrCodeDownloadBox>
                )}
            </Col>

            {params?.id && (
              <>
                <Col className="gutter-row" xs={24} sm={12}>
                  <AllotedSpots
                    form={form}
                    // selectedClassCampaign={selectedClassCampaign}
                    handleValidateFieldNames={handleValidateFieldNames}
                    isActive={active}
                    validateFieldsName={validateFieldsName}
                    setValidateFieldsName={setValidateFieldsName}
                    setCampaignStartEndDate={setCampaignStartEndDate}
                    campaignStartEndDate={campaignStartEndDate}
                    setIsCustomFrequency={setIsCustomFrequency}
                    isCustomFrequency={isCustomFrequency}
                  />
                </Col>
                <Col className="gutter-row" xs={24} sm={12} />
                <Col className="gutter-row" xs={24} sm={12}>
                  <Media
                    selectedCampaignType={selectedCampaignType}
                    selectMediaImage={selectMediaImage}
                    setSelectMediaImage={setSelectMediaImage}
                    isMediaImageUpdating={isMediaImageUpdating}
                    setIsMediaImageUpdating={setIsMediaImageUpdating}
                    selectMediaVideo={selectMediaVideo}
                    isMediaVideoUpdating={isMediaVideoUpdating}
                    setIsMediaVideoUpdating={setIsMediaVideoUpdating}
                    setSelectMediaVideo={setSelectMediaVideo}
                    showCancelBtn={true}
                    isUploadDisable={false}
                  />
                </Col>
                {selectedCampaignType === CAMPAIGN_TYPES.CAPTURE_LEADS && (
                  <>
                    <Col className="gutter-row" xs={24} sm={12} />
                    <Col className="gutter-row" xs={24} sm={12}>
                      <Communication
                        setModeOfCommunication={setModeOfCommunication}
                        modeOfCommunication={modeOfCommunication}
                        setSelectTemplate={setSelectTemplate}
                        setIsTemplateUpdating={setIsTemplateUpdating}
                        isTemplateUpdating={isTemplateUpdating}
                        selectTemplate={selectTemplate}
                        setMobileText={setMobileText}
                        mobileText={mobileText}
                      />
                    </Col>
                  </>
                )}
                <Col className="gutter-row" xs={24} sm={12} />
                <Col className="gutter-row" xs={24} sm={24}>
                  <Checkbox
                    defaultChecked={isGeoFence}
                    onChange={handleGeofenceCheckboxChange}
                  >
                    Geofence Campaign
                  </Checkbox>
                  {isGeoFence && (
                    <WhereToTarget
                      handleValidateFieldNames={handleValidateFieldNames}
                      setValidateFieldsName={setValidateFieldsName}
                      validateFieldsName={validateFieldsName}
                      locations={locations}
                      setLocations={setLocations}
                      form={form}
                    />
                  )}
                </Col>
                {/* <Col className="gutter-row" xs={24} sm={12}>
                  <Audience
                    handleValidateFieldNames={handleValidateFieldNames}
                    setValidateFieldsName={setValidateFieldsName}
                    validateFieldsName={validateFieldsName}
                    form={form}
                  />
                </Col>
                <Col className="gutter-row" xs={24} sm={12} />
                <Col className="gutter-row" xs={24} sm={24}>
                  <TimeSlots
                    handleValidateFieldNames={handleValidateFieldNames}
                    setValidateFieldsName={setValidateFieldsName}
                    validateFieldsName={validateFieldsName}
                    form={form}
                    setTimeSlots={setTimeSlots}
                    timeSlots={timeSlots}
                    campaignStartEndDate={campaignStartEndDate}
                  />
                </Col> */}

                <Col className="gutter-row" xs={24} sm={24}>
                  <Button
                    type="primary"
                    className="save-btn"
                    onClick={() =>
                      handleAddEditCampaign({
                        isDraft: draft,
                        msg: 'Campaign updated successfully!',
                        newDraft: draft,
                        approve: !isCampaignApproved,
                      })
                    }
                    disabled={isCampaignUpdating}
                  >
                    {isCampaignUpdating ? 'Submitting...' : 'Save as draft'}
                  </Button>

                  <Button
                    type="primary"
                    className="draft-btn"
                    onClick={() => {
                      handleActivateCampaigne();
                    }}
                  >
                    Activate
                  </Button>

                  {/* <Button
                    type="primary"
                    className="draft-btn"
                    onClick={() =>
                      handleAddEditCampaign({
                        isDraft: true,
                        msg: 'Campaign Sent for Approval!',
                        newDraft: false,
                        approve: !isCampaignApproved,
                      })
                    }
                    disabled={!draft}
                  >
                    Send For Approval
                  </Button> */}
                  <Popconfirm
                    title={<>Do you want to deactivate this campaign ?</>}
                    onConfirm={() => handleInactiveCampaign()}
                  >
                    <Button
                      type="primary"
                      className="inactive-btn"
                      disabled={!isCampaignDeactivating}
                      danger
                    >
                      Deactivate
                    </Button>
                  </Popconfirm>
                  {/* <Popconfirm
                    title={<>Do you want to approve this campaign ?</>}
                    onConfirm={() => handleapproveCampaign()}
                  >
                    <Button
                      type="primary"
                      className="active-btn"
                      disabled={isCampaignApproved}
                      danger
                    >
                      Approve
                    </Button>
                  </Popconfirm> */}
                  <Popconfirm
                    title={<>Do you want to delete this campaign ?</>}
                    onConfirm={() => handleDeleteCampaign()}
                  >
                    <Button
                      type="primary"
                      className="delete-btn"
                      disabled={isCampaignDeleting}
                      danger
                    >
                      {isCampaignDeleting ? 'Deleting...' : 'Delete'}
                    </Button>
                  </Popconfirm>
                </Col>
              </>
            )}
          </Row>
        </Form>
      )}
    </AddEditCampaignBox>
  );
};

export default AddEditCampaigns;
