import styled from 'styled-components';

export const BulkCommandProcessModalBox = styled.div`
  padding: 20px 20px;

  .ant-result-title:after {
    display: inline-block;
    animation: dotty steps(1, end) 3s infinite;
    content: '';
  }

  @keyframes dotty {
    0% {
      content: '';
    }
    25% {
      content: '.';
    }
    50% {
      content: '..';
    }
    75% {
      content: '...';
    }
    100% {
      content: '';
    }
  }
`;
