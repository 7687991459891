import React, { useState } from 'react';
import { Modal, Form, Button, Input, Spin, Tooltip } from 'antd';
import { string, func, bool } from 'prop-types';
import {
  generateRandomPasswordString,
  httpPut,
  showErrorMsg,
  showSuccessMsg,
  URLS_V2,
} from 'utils';
import { API_V2_ERROR_CODES } from 'constants/common';
import { SyncOutlined } from '@ant-design/icons';

const ChangePassword = (props) => {
  const {
    activeMode,
    handleCloseModal,
    handleSubmitSuccessModal,
    modalVisible,
    recordId,
    sectionName,
  } = props;

  const [form] = Form.useForm();

  const [validateFieldsName, setValidateFieldsName] = useState([]);
  const [isFormSubmitting, setIsFormSubmitting] = useState(false);

  const modalTitle = `${activeMode} ${sectionName}`;

  const handleValidateFieldNames = (name) => {
    const isFieldName = validateFieldsName.find(
      (fieldName) => fieldName === name
    );
    if (isFieldName) return 'onChange';
    return 'onBlur';
  };

  const handleCancelClick = () => {
    form.resetFields();
    handleCloseModal();
  };

  const handleSubmitModal = () => {
    const url = `${URLS_V2?.USERS?.CREATE_UPDATE_USER}`;
    form.submit();
    form
      .validateFields()
      .then(async (values) => {
        setIsFormSubmitting(true);
        values = { ...values, id: recordId };
        const msg = `${sectionName} updated successfully`;
        httpPut(url, values)
          .then((res) => {
            if (res.status === 200 || res.status === 201) {
              showSuccessMsg(msg);
              form.resetFields();
              handleSubmitSuccessModal();
            } else if (
              res?.status &&
              API_V2_ERROR_CODES.includes(res?.status)
            ) {
              showErrorMsg(res?.message);
            }
            setIsFormSubmitting(false);
          })
          .catch((err) => {
            showErrorMsg(err);
            setIsFormSubmitting(false);
          });
      })
      .catch((errorInfo) => errorInfo);
  };

  let modalFooterButtons = [
    <Button
      key="add"
      type="primary"
      onClick={handleSubmitModal}
      disabled={isFormSubmitting}
    >
      {isFormSubmitting ? 'Loading...' : 'Update Password'}
    </Button>,
    <Button key="close" onClick={handleCancelClick} disabled={isFormSubmitting}>
      Close
    </Button>,
  ];

  const handleGeneratePassword = () => {
    form.setFieldsValue({ password: generateRandomPasswordString() });
  };

  return (
    <>
      <Modal
        title={modalTitle}
        visible={modalVisible}
        // onOk={handleOk}
        onCancel={handleCancelClick}
        // className="expense-modal"
        maskClosable={false}
        width={550}
        forceRender={true}
        footer={modalFooterButtons}
      >
        <Spin spinning={isFormSubmitting}>
          <Form form={form} layout="vertical">
            <div className="while-bg-box filter-form">
              <Form.Item
                name="password"
                label="New Password"
                validateTrigger={handleValidateFieldNames('password')}
                rules={[
                  {
                    required: true,
                    message: 'Please enter new password!',
                  },
                ]}
              >
                <Input.Password
                  placeholder={'New Password'}
                  autoComplete="new-password"
                  onBlur={() =>
                    setValidateFieldsName([...validateFieldsName, 'password'])
                  }
                  disabled={isFormSubmitting}
                  addonAfter={
                    <Tooltip title="Click to generate a strong password!">
                      <SyncOutlined onClick={() => handleGeneratePassword()} />
                    </Tooltip>
                  }
                />
              </Form.Item>
            </div>
          </Form>
        </Spin>
      </Modal>
    </>
  );
};

ChangePassword.propTypes = {
  activeMode: string,
  handleCloseModal: func,
  handleSubmitSuccessModal: func,
  modalVisible: bool,
  recordId: string,
  sectionName: string,
};

export default ChangePassword;
