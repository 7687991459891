import React from 'react';
import { Breadcrumb } from 'antd';
import { Link } from 'react-router-dom';
import { array } from 'prop-types';

const CommonBreadCrumb = (props) => {
  const { breadCrumbs } = props;

  const renderBreadCrumbWithLink = (data, index) => (
    <Breadcrumb.Item key={index}>
      <Link to={data?.link || '/dashboard'}>{data?.label || ''}</Link>
    </Breadcrumb.Item>
  );

  const renderBreadCrumb = (data, index) => (
    <Breadcrumb.Item key={index}>{data?.label || ''}</Breadcrumb.Item>
  );

  return breadCrumbs && breadCrumbs.length >= 1 ? (
    <Breadcrumb style={{ margin: '16px 10px' }}>
      {breadCrumbs.map((item, index) =>
        item && item?.link
          ? renderBreadCrumbWithLink(item, index)
          : renderBreadCrumb(item, index)
      )}
    </Breadcrumb>
  ) : null;
};

CommonBreadCrumb.propTypes = {
  breadCrumbs: array,
};

export default CommonBreadCrumb;
