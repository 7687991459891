import React, { useState, useEffect, useRef } from 'react';
import { Table, Form, Button, Tag, InputNumber } from 'antd';
// import { MODE_TYPES } from 'constants/common';
import moment from 'moment';
import { DeviceStatusListBox, Loader } from './_devices-status.styled';
import {
  // FormOutlined,
  WifiOutlined,
  MobileOutlined,
  AudioOutlined,
  AudioMutedOutlined,
  UsbOutlined,
  UnlockOutlined,
  LockOutlined,
  BulbOutlined,
  SoundOutlined,
} from '@ant-design/icons';
import { formatDate } from 'utils';
import { firebaseDb } from '../../configs/firebase';
import { DEVICE_ISSUES, DEVICE_ISSUE_LIST } from 'constants/devices';

const DevicesStatus = () => {
  const [deviceStatusList, setDeviceStatusList] = useState([]);
  // const [, setDeviceStatusList] = useState([]);
  const [isFetching, setIsFetching] = useState(true);
  const filterParamsData = {
    minInternetUpdateValue: 1, // in Hour
    minChargeValue: 6, // in Hour
  };
  const [filterParams, setFilterParams] = useState(filterParamsData);
  const initialTimeStamp = useRef(Date.now());
  const refreshList = useRef(true);
  const mapTimeDelayedInSecs = 10;

  useEffect(() => {
    fetchDeviceStatusList();
  }, [filterParams]);

  const muteIconRender = (value) => {
    return (
      <>
        {value ? (
          <AudioMutedOutlined
            style={{
              fontSize: '18px',
              color: '#ff4d4f',
            }}
          />
        ) : (
          <AudioOutlined
            style={{
              fontSize: '18px',
              color: '#52c41a',
            }}
          />
        )}
      </>
    );
  };

  const wifiIconRender = (value) => {
    return (
      <WifiOutlined
        style={{
          fontSize: '18px',
          color: value ? '#52c41a' : '#ff4d4f',
        }}
      />
    );
  };
  const chargingIconRender = (value) => {
    return (
      <UsbOutlined
        style={{
          fontSize: '18px',
          color: value ? '#52c41a' : '#ff4d4f',
        }}
      />
    );
  };
  const sleepIconRender = (value) => {
    return (
      <MobileOutlined
        style={{
          fontSize: '18px',
          color: value ? '#ff4d4f' : '#52c41a',
        }}
      />
    );
  };

  const screenOnIconRender = (value) => {
    return (
      <>
        {value ? (
          <UnlockOutlined
            style={{
              fontSize: '18px',
              color: value ? '#52c41a' : '#ff4d4f',
            }}
          />
        ) : (
          <LockOutlined
            style={{
              fontSize: '18px',
              color: value ? '#52c41a' : '#ff4d4f',
            }}
          />
        )}
      </>
    );
  };

  const batteryIconRender = (value) => {
    return (
      <>
        <UsbOutlined
          style={{
            fontSize: '1rem',
            color: '#faad14',
            marginRight: '0.5rem',
          }}
        />
        {`${value.includes('%') ? value : value + '%'}`}
      </>
    );
  };

  const brightnessIconRender = (value) => {
    return (
      <>
        <BulbOutlined
          style={{
            fontSize: '1rem',
            color: '#faad14',
            marginRight: '0.5rem',
          }}
        />
        {`${value.includes('%') ? value : value + '%'}`}
      </>
    );
  };

  const volumeIconRender = (value) => {
    return (
      <>
        <SoundOutlined
          style={{
            fontSize: '1rem',
            color: '#faad14',
            marginRight: '0.5rem',
          }}
        />
        {`${value.includes('%') ? value : value + '%'}`}
      </>
    );
  };

  const handleNumberSearch = (fieldName, value) => {
    setFilterParams({
      ...filterParams,
      [fieldName]: value,
    });
    refreshList.current = true;
  };

  const setDeviceIssue = (isCharging, updatedAt, lastChargedAt) => {
    let issueStatus = [];

    let updatedDate = moment(updatedAt);
    let lastChargeDate = moment(lastChargedAt);
    const currentDate = moment(new Date());

    const updateAtHoursDiff = currentDate.diff(updatedDate, 'hours');
    const lastChargeHoursDiff = currentDate.diff(lastChargeDate, 'hours');

    if (
      isCharging &&
      updateAtHoursDiff >= filterParams?.minInternetUpdateValue
    ) {
      issueStatus.push(DEVICE_ISSUES.NETWORK_ISSUE);
    }
    if (!isCharging && lastChargeHoursDiff >= filterParams?.minChargeValue) {
      issueStatus.push(DEVICE_ISSUES.CHARGING_ISSUE);
    }
    if (issueStatus.length === 0) {
      issueStatus.push(DEVICE_ISSUES.ALL_GOOD);
    }
    return issueStatus;
  };

  const fetchDeviceStatusList = () => {
    setIsFetching(true);
    const ref = firebaseDb.ref('device_status');
    ref.on('value', (response) => {
      const deviceStatusListData = response.val();
      let currentTimeStamp = Date.now();
      // set delayed before data manipulation and setState
      if (
        (currentTimeStamp - initialTimeStamp.current) / 1000 >=
          mapTimeDelayedInSecs ||
        refreshList.current
      ) {
        const tempList = [];
        for (let data in deviceStatusListData) {
          const {
            device_serial,
            current_lat,
            current_lng,
            is_charging,
            is_mute,
            is_screen_on,
            is_sleep,
            is_WifiOn,
            is_device_active,
            updated_at,
            last_charging_status_change,
            battery_percent,
            brightness_level,
            volume_level,
          } = deviceStatusListData[data] || {};

          const deviceStatusStructure = {
            ...deviceStatusListData[data],
            key: device_serial,
            position: {
              lat: current_lat || '',
              lng: current_lng || '',
            },
            // deviceInfo: {
            //   batteryPercent: battery_percent,
            //   brightnessLevel: brightness_level,
            //   volumeLevel: volume_level,
            // },
            battery_percent: battery_percent,
            batteryPercent: batteryIconRender(battery_percent),
            brightnessLevel: brightnessIconRender(brightness_level),
            volumeLevel: volumeIconRender(volume_level),
            isCharging: chargingIconRender(is_charging),
            isMute: muteIconRender(is_mute),
            isScreenOn: screenOnIconRender(is_screen_on),
            isSleep: sleepIconRender(is_sleep),
            isWiFiOn: wifiIconRender(is_WifiOn),
            isDeviceActive: is_device_active ? 'Active' : 'Inactive',
            updatedAt: updated_at ? formatDate(updated_at) : '',
            deviceIssue: setDeviceIssue(
              is_charging,
              updated_at,
              last_charging_status_change
            ),
            lastChargedAt: last_charging_status_change
              ? formatDate(last_charging_status_change)
              : '',
          };
          tempList.push(deviceStatusStructure);
        }
        initialTimeStamp.current = Date.now();
        refreshList.current = false;
        setDeviceStatusList(tempList);
        setTimeout(() => {
          setIsFetching(false);
        }, 1000);
      }
    });
  };

  const updateDeviceStatusList = () => {
    refreshList.current = true;
    fetchDeviceStatusList();
  };

  const columns = [
    {
      key: 'device_serial',
      title: ENROLL_LABELS.DEVICE_SERIAL,
      dataIndex: 'device_serial',
      fixed: 'left',
    },
    {
      key: 'sim_serial',
      title: ENROLL_LABELS.SIM_SERIAL,
      dataIndex: 'sim_serial',
    },
    {
      key: 'app_version',
      title: ENROLL_LABELS.APP_VERSION,
      dataIndex: 'app_version',
      width: 70,
    },
    {
      key: 'app_language',
      title: ENROLL_LABELS.APP_LANGUAGE,
      dataIndex: 'app_language',
      width: 80,
    },
    {
      key: 'position',
      title: ENROLL_LABELS.POSITION,
      dataIndex: 'position',
      render: (position) => {
        return (function Actions() {
          return (
            <>
              <div className="actions-btn">
                {`Lat:${position?.lat}, Lng:${position?.lng}`}
              </div>
            </>
          );
        })();
      },
    },
    {
      key: 'ride_status',
      title: ENROLL_LABELS.RIDE_STATUS,
      dataIndex: 'ride_status',
    },
    // {
    //   key: 'deviceInfo',
    //   title: ENROLL_LABELS.DEVICE_INFO,
    //   dataIndex: 'deviceInfo',
    //   render: (deviceInfo) => {
    //     return (function Actions() {
    //       return (
    //         <>
    //           <div>
    //             <BulbOutlined
    //               style={{
    //                 fontSize: '1rem',
    //                 color: '#faad14',
    //                 marginRight: '1rem',
    //               }}
    //             />
    //             {deviceInfo?.brightnessLevel}
    //           </div>
    //           <div>
    //             <SoundOutlined
    //               style={{
    //                 fontSize: '1rem',
    //                 color: '#faad14',
    //                 marginRight: '1rem',
    //               }}
    //             />
    //             {deviceInfo?.volumeLevel}
    //           </div>
    //           <div>
    //             <UsbOutlined
    //               style={{
    //                 fontSize: '1rem',
    //                 color: '#faad14',
    //                 marginRight: '1rem',
    //               }}
    //             />
    //             {deviceInfo?.batteryPercent}
    //           </div>
    //         </>
    //       );
    //     })();
    //   },
    // },
    {
      key: 'battery_percent',
      title: ENROLL_LABELS.BATTERY_PERCENT,
      dataIndex: 'batteryPercent',
      width: 80,
      defaultSortOrder: 'ascend',
      sorter: (a, b) => a.battery_percent - b.battery_percent,
    },
    {
      key: 'brightness_level',
      title: ENROLL_LABELS.BRIGHTNESS_LEVEL,
      dataIndex: 'brightnessLevel',
      width: 90,
    },
    {
      key: 'volume_level',
      title: ENROLL_LABELS.VOLUME_LEVEL,
      dataIndex: 'volumeLevel',
      width: 80,
    },
    {
      key: 'wifi_status',
      title: ENROLL_LABELS.WIFI_STATUS,
      dataIndex: 'isWiFiOn',
      width: 55,
    },
    {
      key: 'is_charging',
      title: ENROLL_LABELS.IS_CHARGING,
      dataIndex: 'isCharging',
      width: 75,
    },
    {
      key: 'is_mute',
      title: ENROLL_LABELS.IS_MUTE,
      dataIndex: 'isMute',
      width: 65,
    },
    {
      key: 'is_screen_on',
      title: ENROLL_LABELS.IS_SCREEN_ON,
      dataIndex: 'isScreenOn',
      width: 70,
    },
    {
      key: 'is_sleep',
      title: ENROLL_LABELS.IS_SLEEP,
      dataIndex: 'isSleep',
      width: 70,
    },
    {
      key: 'last_charge_at',
      title: ENROLL_LABELS.LAST_CHARGE_AT,
      dataIndex: 'lastChargedAt',
    },
    {
      key: 'updated_at',
      title: ENROLL_LABELS.UPDATED_AT,
      dataIndex: 'updatedAt',
    },
    {
      key: 'issue',
      title: ENROLL_LABELS.DEVICE_ISSUE,
      dataIndex: 'deviceIssue',
      filters: DEVICE_ISSUE_LIST,
      filterMultiple: true,
      onFilter: (value, record) => {
        const foundIndex = record.deviceIssue.findIndex(
          (item) => item.text === value
        );
        return foundIndex !== -1;
      },
      render: (deviceIssue) => {
        return (function Actions() {
          return deviceIssue.map((issue) => {
            return (
              <Tag color={issue?.color} key={issue?.text}>
                {issue?.text?.toUpperCase()}
              </Tag>
            );
          });
        })();
      },
    },
    {
      key: 'active',
      title: ENROLL_LABELS.ACTIVE,
      dataIndex: 'isDeviceActive',
      width: 80,
    },
  ];

  return (
    <DeviceStatusListBox>
      <div className="table-header">
        <h3 className="table-title">Devices Status</h3>
        <div className="table-right-side">
          <div className="export-generate-btn">
            <Form.Item>
              <Button onClick={updateDeviceStatusList} type="primary">
                Update
              </Button>
            </Form.Item>
          </div>
        </div>
      </div>
      <div className="filter-form">
        <Form layout="inline" className="main-filter-form">
          <div className="left-form">
            <div className="device-input">
              <Form.Item
                name="minInternetUpdateValue"
                label="Min Internet Response Time (In Hours)"
              >
                <InputNumber
                  type="number"
                  min={1}
                  max={99}
                  defaultValue={filterParams.minInternetUpdateValue}
                  placeholder="Min Internet Response Time (In Hours)"
                  onChange={(value) =>
                    handleNumberSearch('minInternetUpdateValue', value)
                  }
                />
                {/* <Input
                type="text"
                name="minInternetUpdateValue"
                defaultValue={filterParams.minInternetUpdateValue}
                placeholder="Please enter Hours to filter data"
              /> */}
              </Form.Item>
              <Form.Item
                name="minChargeValue"
                label="Min Charging Response Time (In Hours)"
              >
                <InputNumber
                  type="number"
                  min={1}
                  max={99}
                  name="minChargeValue"
                  defaultValue={filterParams.minChargeValue}
                  placeholder="Min Charging Response Time (In Hours)"
                  onChange={(value) =>
                    handleNumberSearch('minChargeValue', value)
                  }
                />
                {/* <Input
                type="text"
                name="minChargeValue"
                onChange={handleNumberSearch}
                defaultValue={filterParams.minChargeValue}
                placeholder="Please enter Hours to filter data"
              /> */}
              </Form.Item>
            </div>
            <div>{`Last updated at: ${formatDate(
              initialTimeStamp.current
            )}`}</div>
          </div>
        </Form>
      </div>
      <div className="main-table-body">
        <Table
          rowClassName={(record, index) =>
            record.battery_percent <= 6 ? 'battery-low' : 'battery-normal'
          }
          scroll={{ x: 1200 }}
          loading={{
            indicator: <Loader>Fetching...</Loader>,
            spinning: isFetching,
          }}
          columns={columns}
          dataSource={deviceStatusList || []}
          // dataSource={[]}
          size="small"
          pagination={false}
        />
      </div>
    </DeviceStatusListBox>
  );
};

export default DevicesStatus;

const ENROLL_LABELS = {
  DEVICE_SERIAL: 'Device Serial',
  SIM_SERIAL: 'Sim Serial',
  APP_VERSION: 'App Version',
  APP_LANGUAGE: 'App Language',
  DEVICE_INFO: 'Device Info',
  BATTERY_PERCENT: 'Battery Percent',
  BRIGHTNESS_LEVEL: 'Brightness Level',
  VOLUME_LEVEL: 'Volume Level',
  POSITION: 'Position',
  RIDE_STATUS: 'Ride Status',
  WIFI_STATUS: 'Wifi',
  IS_CHARGING: 'Charging',
  IS_MUTE: 'Mute',
  IS_SCREEN_ON: 'Screen',
  IS_SLEEP: 'Sleep',
  LAST_CHARGE_AT: 'Last Charge At',
  UPDATED_AT: 'Updated at',
  ACTIVE: 'Status',
  DEVICE_ISSUE: 'Device Issue',
  ACTIONS: 'Actions',
};
