import React, { useContext } from 'react';
import { useHistory, Link } from 'react-router-dom';
import { func, bool } from 'prop-types';
import { Context } from 'hooks/store';
import { Layout, Button, Avatar, Menu, Dropdown } from 'antd';
import {
  // MenuUnfoldOutlined,
  // MenuFoldOutlined,
  UserOutlined,
  DownOutlined,
  MenuOutlined,
} from '@ant-design/icons';
import { removeUser } from 'utils';
import { MainHeaderBox } from './_header.styled';

const { Header } = Layout;

const MainHeader = (props) => {
  const { collapsed, toggleSideMenu } = props;
  const { authState } = useContext(Context);
  const history = useHistory();

  const handleLogout = () => {
    removeUser();
    history.push('/login');
  };

  const dropdownMenu = (
    <Menu>
      <Menu.Item key="1">
        <Link to="/profile" className="login-form-forgot">
          <Button type="default" onClick={handleLogout} block>
            Profile
          </Button>
        </Link>
      </Menu.Item>

      <Menu.Item key="2">
        <Button type="danger" onClick={handleLogout} block>
          Logout
        </Button>
      </Menu.Item>
    </Menu>
  );

  return (
    <MainHeaderBox>
      <Header className="main-header" style={{ padding: 0 }}>
        {React.createElement(
          MenuOutlined,
          {
            className: 'trigger',
            onClick: toggleSideMenu,
          }
        )}
        <div className="logout-btn">
          {/* <Avatar
            icon={<UserOutlined />}
            // style={{
            //   backgroundColor: `${
            //     authState?.auth?.uid ? '#2ecc71' : '#ffffff'
            //   }`,
            // }}
          /> */}
          <Dropdown overlay={dropdownMenu}>
            <Button className="dropdown-button">
              <Avatar icon={<UserOutlined />} style={{ marginRight: '10px' }} />
              {authState?.auth?.displayName || authState?.auth?.email}
              <DownOutlined />
            </Button>
          </Dropdown>
        </div>
      </Header>
    </MainHeaderBox>
  );
};

MainHeader.propTypes = {
  collapsed: bool,
  toggleSideMenu: func,
};

export default MainHeader;
