import { Button, Col, Form, Input, Modal, Row,Typography } from 'antd';
import React, { useEffect, useState } from 'react';
import { MusicCategoryModalBox,PreviewImageBox,UploadImageBox } from './MusicCategoryModal.style';
import { DeleteOutlined, EyeOutlined, PlusOutlined } from '@ant-design/icons';
import { WATCH_CATEGORY_IMAGE_DIMENSIONS } from 'constants/watch-category';
import { getBase64, httpGet, httpPost, httpPut, showErrorMsg, showSuccessMsg, URLS_V2 } from 'utils';
import { API_V2_ERROR_CODES } from 'constants/common';
const { TextArea } = Input;
const { Text } = Typography;

const logoImageInitialStructure = {
  base64: '',
  file: '',
  gCloudUrl: '',
};

const MusicCategoryModal = ({
  activeMode,
  closeModal,
  callback,
  categoryId,
}) => {
  const [form] = Form.useForm();

  const [logoImage, setLogoImage] = useState(logoImageInitialStructure);
  const [isFormSubmitting, setIsFormSubmitting] = useState(false);
  const [isPreviewLogoImg, setIsPreviewLogoImg] = useState(false);
  const [validateFieldsName, setValidateFieldsName] = useState([]);

  const getImageUrl = logoImage.gCloudUrl || logoImage.base64;

  
  
useEffect(()=>{
  if(categoryId.length>0){
    if(activeMode=='Edit'){
      getCategoryDetails()
    }
  }
}, [activeMode])

  const getCategoryDetails=()=>{
let url=URLS_V2?.MUSIC_CATEGORY?.GET_CATEGORY.replace("#ID#", categoryId)
httpGet(url)
.then(res=>{
if(res?.status==200){
  
res?.data && Object.entries(res?.data).forEach(([key,value])=>{
  if(key=="categoryImage"){
    setLogoImage({
      ...logoImageInitialStructure,
      file:value,
      gCloudUrl: value,
    });
  }else{
    form.setFieldsValue({
      [key]:value
    })
  }
})
}
}).catch((err)=>{

})
  }


  const resetAllData = () => {
    form.resetFields();
    setLogoImage(logoImageInitialStructure);
    closeModal();
  };

  const handleOnCloseModal = () => {
    if (isFormSubmitting) return;
    resetAllData();
  };

  const handlePreviewLogoImg = ({ isVisible }) => {
    setIsPreviewLogoImg(isVisible);
  };

  const handleLogoImgUpload = async (e) => {
    const file = e.target.files[0];

    if (file) {
      const isImageValid = await new Promise((resolve, reject) => {
        // check the file type - you can specify the types you'd like here:
        const isImg =
          file.type === 'image/jpeg' ||
          file.type === 'image/jpg' ||
          file.type === 'image/png';

        if (!isImg) {
          showErrorMsg(
            `Invalid image extension. Image must be in .png, .jpeg, or .jpg extension`
          );
          resolve(false);
          return;
        }

        // check the file size - you can specify the file size you'd like here:
        const imageSize =
          file.size / 1024 > WATCH_CATEGORY_IMAGE_DIMENSIONS.FILE_SIZE;
        if (imageSize) {
          showErrorMsg(
            `Image size must be smaller then ${WATCH_CATEGORY_IMAGE_DIMENSIONS.FILE_SIZE} KB`
          );
          resolve(false);
          return;
        }

        // check for dimensions
        var reader = new FileReader();
        // Read the contents of Image File.
        reader.readAsDataURL(file);
        reader.onload = (event) => {
          // Initiate the JavaScript Image object.
          var image = new Image();

          // Set the Base64 string return from FileReader as source.
          image.src = event.target.result;

          image.onload = function () {
            const { height, width } = this;
            // if the aspect ratio is in our sweet spot, proceed - you can specify whatever checks for height and width you want
            if (
              width !== WATCH_CATEGORY_IMAGE_DIMENSIONS.FILE_WIDTH ||
              height !== WATCH_CATEGORY_IMAGE_DIMENSIONS.FILE_HEIGHT
            ) {
              // showErrorMsg(
              //   `Image does not have optimal dimensions. Recommended image dimensions are ${WATCH_CATEGORY_IMAGE_DIMENSIONS.FILE_WIDTH} X ${WATCH_CATEGORY_IMAGE_DIMENSIONS.FILE_HEIGHT}.`
              // );
              resolve(true);
            } else {
              resolve(true);
            }
          };
        };
      });
      if (isImageValid) {
        getBase64(file, (imageUrl) => {
          setLogoImage({
            ...logoImageInitialStructure,
            base64: imageUrl,
            file,
          });
        });
      }
    }
  };

  const handleLogoImgDelete = () => {
    setLogoImage(logoImageInitialStructure);
  };

  const handleValidateFieldNames = (name) => {
    const isFieldName = validateFieldsName.find(
      (fieldName) => fieldName === name
    );
    if (isFieldName) return 'onChange';
    return 'onBlur';
  };

const handleAddEditCategory=()=>{
  let url;
  let apiMethod;
  if(activeMode=='Edit'){
    url =URLS_V2?.MUSIC_CATEGORY?.UPDATE_CATEGORY.replace("#ID#", categoryId);
    apiMethod=httpPut;
  }else{
    url =URLS_V2?.MUSIC_CATEGORY?.ADD_NEW_CATEGORY;
    apiMethod=httpPost;
  }

form.submit();
form.validateFields()
      .then(async (values) => {
        const formData = new FormData();
        formData.append('categoryImage', logoImage.file);
        formData.append('title', values.title);
        formData.append('ar_title', values.ar_title);
        formData.append('desc', values.desc);
        formData.append('ar_desc', values.ar_desc);
        if (!logoImage.file && !logoImage.gCloudUrl) {
          showErrorMsg('Category image required!');
          return;
        }
        setIsFormSubmitting(true);
        apiMethod(url, formData)
          .then((res) => {
            if (res.status === 200) {
              if(activeMode=='Edit'){
                showSuccessMsg(`Category successfully Updated!`);
              }else{
                showSuccessMsg(`Category successfully Added!`);
              }
              resetAllData();
              callback();
              // updateFirebaseGlobalAppSettings(
              //   GLOBAL_APP_SETTINGS_KEYS.watch_category
              // );
            } else if (
              res?.status &&
              API_V2_ERROR_CODES.includes(res?.status)
            ) {
              showErrorMsg(res?.message);
            }
            setIsFormSubmitting(false);
          })
          .catch((err) => {
            showErrorMsg(err);
            setIsFormSubmitting(false);
          });
      })
      .catch((errorInfo) => errorInfo);
}


let modalFooterButtons = [
  <Button
    key="add"
    type="primary"
    onClick={handleAddEditCategory}
    disabled={isFormSubmitting}
  >
    {isFormSubmitting ? 'Loading...' : activeMode=='Edit'? 'Update Category' :'Add Category'}
  </Button>,
  <Button
    key="close"
    onClick={handleOnCloseModal}
    disabled={isFormSubmitting}
  >
    Close
  </Button>,
];



  return (
    <div>
      <MusicCategoryModalBox>
      <Modal
        title={'Add Category'}
        visible={Boolean(activeMode)}
        // onOk={handleOk}
        onCancel={handleOnCloseModal}
        className="expense-modal"
        maskClosable={false}
        width={550}
        forceRender={true}
        footer={modalFooterButtons}
      >
        <Form
          form={form}
          layout="vertical"
        >
          <Row gutter={16}>
            <Col className="gutter-row" xs={24} sm={8}>
                {getImageUrl ? (
                  <PreviewImageBox>
                    <img src={getImageUrl} alt="logo" />
                    <div className="icons-div">
                      <div className="icons">
                        <div>
                          <EyeOutlined
                            onClick={() => {
                              handlePreviewLogoImg({ isVisible: true });
                            }}
                          />
                        </div>
                        <div>
                          <DeleteOutlined
                           onClick={handleLogoImgDelete}
                            />
                        </div>
                      </div>
                    </div>
                    <Modal
                      visible={isPreviewLogoImg}
                      title={'Preview'}
                      footer={null}
                      onCancel={() =>
                        handlePreviewLogoImg({ isVisible: false })
                      }
                    >
                      <img
                        alt="logo"
                        style={{ width: '100%' }}
                        src={getImageUrl}
                      />
                    </Modal>
                  </PreviewImageBox>
                ) : (
                  <UploadImageBox htmlFor="imageUrl">
                    <div className={`upload-image `}>
                      <>
                        <div className="icon">
                          <PlusOutlined />
                        </div>
                        <div>Category Image</div>
                        <div>
                          <Text type="secondary">{`Max file size: ${WATCH_CATEGORY_IMAGE_DIMENSIONS.FILE_SIZE} KB`}</Text>
                        </div>
                        {/* <div>
                          <Text type="secondary">{`Resolution: ${WATCH_CATEGORY_IMAGE_DIMENSIONS.FILE_WIDTH} X ${WATCH_CATEGORY_IMAGE_DIMENSIONS.FILE_HEIGHT}`}</Text>
                        </div> */}
                      </>
                      {/* )} */}
                    </div>
                    <input
                      id="imageUrl"
                      type="file"
                      name="imageUrl"
                      onChange={handleLogoImgUpload}
                      style={{ visibility: 'hidden' }}
                    />
                  </UploadImageBox>
                )}
              </Col>
            <Col className="gutter-row" xs={24} sm={16}>
              <Form.Item
                name="title"
                label="Title (English)"
                validateTrigger={handleValidateFieldNames('title')}
                rules={[
                  {
                    required: true,
                    message: 'Please enter title!',
                  },
                ]}
              >
                <Input
                  type="text"
                  placeholder="Title (English)"
                  onBlur={() =>
                    setValidateFieldsName([...validateFieldsName, 'title'])
                  }
                />
              </Form.Item>
              <Form.Item
                name="ar_title"
                label="Title (Arabic)"
                validateTrigger={handleValidateFieldNames('ar_title')}
                rules={[
                  {
                    required: true,
                    message: 'Please enter title!',
                  },
                ]}
              >
                <Input
                  type="text"
                  placeholder="Title (Arabic)"
                  onBlur={() =>
                    setValidateFieldsName([...validateFieldsName, 'ar_title'])
                  }
                />
              </Form.Item>
            </Col>
            <Col className="gutter-row" xs={24}>
              <Form.Item
                name="desc"
                label="Description (English)"
                validateTrigger={handleValidateFieldNames('desc')}
                rules={[
                  {
                    required: true,
                    message: 'Please enter description!',
                  },
                ]}
              >
                <TextArea
                  rows={4}
                  placeholder="Description (English)"
                  onBlur={() =>
                    setValidateFieldsName([...validateFieldsName, 'desc'])
                  }
                />
              </Form.Item>
              <Form.Item
                name="ar_desc"
                label="Description (Arabic)"
                validateTrigger={handleValidateFieldNames('ar_desc')}
                rules={[
                  {
                    required: true,
                    message: 'Please enter description!',
                  },
                ]}
              >
                <TextArea
                  rows={4}
                  placeholder="Description (Arabic)"
                  onBlur={() =>
                    setValidateFieldsName([...validateFieldsName, 'ar_desc'])
                  }
                />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Modal>
      </MusicCategoryModalBox>

    </div>
  );
};

export default MusicCategoryModal;
