import styled from 'styled-components';

export const PreviewBox = styled.div``;

export const ModalContentBox = styled.div`
  display: block;
  margin: 0px auto;
  max-width: 1280px;
  width: 100%;
  position: relative;
  .video-box {
    background: grey;
    border-radius: 50px;
    width: 98%;
    height: 98%;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }

  .video {
    position: absolute;
    max-width: 100%;
    display: block;
    width: 100%;
    height: 100%;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    z-index: 3;
    border-radius: 5px;
    overflow: hidden;
    img {
      width: 100%;
      height: 100%;
    }
    padding: 6.5% 4.7% 6.5% 4.9%;
  }

  .lead-image {
    position: absolute;
    max-width: 100%;
    display: block;
    width: 100%;
    height: 100%;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    z-index: 3;
    border-radius: 5px;
    overflow: hidden;
    img {
      width: 100%;
      height: 100%;
    }
    padding: 6.4% 3.87% 18.8% 4.26%;
  }
  .image {
    position: absolute;
    max-width: 100%;
    display: block;
    width: 100%;
    height: 100%;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    z-index: 3;
    border-radius: 5px;
    overflow: hidden;
    img {
      width: 100%;
      height: 100%;
    }
    padding: 6.5% 4.5%;
  }
  img {
    display: block;
    margin: 0px auto;
    max-width: 1280px;
    width: 100%;
    position: relative;
    z-index: 2;
  }
`;

export const ModalBodyBox = styled.div`
  position: relative;
`;

export const ModalPlaceholderBox = styled.div`
  display: block;
  margin: 0px auto;
  max-width: 1280px;
  width: 100%;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  .image {
    position: absolute;
    max-width: 100%;
    display: block;
    width: 100%;
    height: 100%;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    z-index: 3;
    border-radius: 5px;
    overflow: hidden;
    img {
      width: 100%;
      height: 100%;
    }
    padding: 6.5% 4.5%;
  }
  img {
    display: block;
    margin: 0px auto;
    max-width: 1280px;
    width: 100%;
    position: relative;
    z-index: 2;
  }
`;

export const PreviewImageBox = styled.div`
  width: 150px;
  height: 150px;
  position: relative;
  padding: 10px;
  border: 1px dashed #d9d9d9;
  border-radius: 2px;
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 2px;
  }
  .uploaded-pdf {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    height: 100%;
    p {
      margin: 0px;
      overflow-wrap: anywhere;
    }
    img {
      width: 50px;
      object-fit: contain;
    }
  }
  .not-allowed {
    cursor: not-allowed;
  }
  .loading-div {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    height: 100%;
    align-items: center;
    .loading-icon {
      margin-bottom: 10px;
    }
    background: #f1f1f1;
  }
  .icons-div {
    position: absolute;
    color: white;
    width: 100%;
    height: 100%;
    top: 0px;
    left: 0px;
    padding: 10px;
    .icons {
      a {
        color: white;
      }
      opacity: 0;
      background: rgb(0 0 0 / 47%);
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      height: 100%;
      transition: all 0.3s ease-out;
      div {
        padding: 10px;
        cursor: pointer;
      }
    }
  }
  &:hover {
    .icons-div {
      .icons {
        opacity: 1;
        transition: all 0.3s ease-out;
      }
    }
  }
`;
