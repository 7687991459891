import React, { useState } from 'react';
import { bool, func, object } from 'prop-types';
import { Row, Col, Collapse, Form, Skeleton, Input, Button } from 'antd';
import { CollapseBox } from './_rides-info.styled';
import { FormOutlined } from '@ant-design/icons';

const { Panel } = Collapse;

const RidesInfo = (props) => {
  const {
    deviceDetails,
    isFetching,
    handleRideInfoFormSubmit,
    rideInfoInlineEdit,
    setRideInfoInlineEdit,
    rideInfoForm,
  } = props;

  const [validateFieldsName, setValidateFieldsName] = useState([]);

  const renderLableColumnRows = (
    label,
    value,
    rowGutter = 18,
    lableColSpacing = 10,
    valueColSpacing = 14
  ) => {
    return (
      <Row gutter={rowGutter} style={{ margin: '15px' }}>
        <Col className="gutter-row" xs={lableColSpacing}>
          <strong>{label}</strong>
        </Col>
        <Col className="gutter-row" xs={valueColSpacing}>
          <Skeleton
            loading={isFetching}
            active
            paragraph={{ rows: 1, width: '100%' }}
            title={false}
          >
            {value}
          </Skeleton>
        </Col>
      </Row>
    );
  };

  const genExtra = () => {
    return rideInfoInlineEdit ? (
      <>
        <Button
          type="primary"
          htmlType="submit"
          onClick={() => handleRideInfoFormSubmit()}
        >
          Submit
        </Button>
        <Button
          className="m-l-5"
          htmlType="button"
          onClick={() => {
            setRideInfoInlineEdit(!rideInfoInlineEdit);
          }}
        >
          Cancel
        </Button>
      </>
    ) : (
      <FormOutlined
        onClick={() => {
          setRideInfoInlineEdit(!rideInfoInlineEdit);
        }}
      />
    );
  };

  const handleValidateFieldNames = (name) => {
    const isFieldName = validateFieldsName.find(
      (fieldName) => fieldName === name
    );
    if (isFieldName) return 'onChange';
    return 'onBlur';
  };

  return (
    <CollapseBox>
      <Collapse defaultActiveKey={['1']}>
        <Panel
          collapsible="header"
          header="Cab Info"
          key="1"
          extra={!isFetching ? genExtra() : <></>}
        >
          {rideInfoInlineEdit ? (
            <Form
              form={rideInfoForm}
              layout="horizontal"
              labelCol={{ span: 10 }}
              wrapperCol={{ span: 14 }}
              initialValues={{
                taxi_number: deviceDetails?.taxi_number || '',
                morning_driver_name: deviceDetails?.morning_driver_name || '',
                morning_driver_mobile:
                  deviceDetails?.morning_driver_mobile || '',
                evening_driver_name: deviceDetails?.evening_driver_name || '',
                evening_driver_mobile:
                  deviceDetails?.evening_driver_mobile || '',
              }}
            >
              <Row gutter={16}>
                <Col className="gutter-row" xs={24} sm={24}>
                  <Form.Item
                    name="taxi_number"
                    label="Taxi Number"
                    validateTrigger={handleValidateFieldNames('taxi_number')}
                    rules={[
                      {
                        required: true,
                        message: 'Please enter taxi number!',
                      },
                    ]}
                  >
                    <Input
                      type="text"
                      placeholder="Taxi Number"
                      onBlur={() =>
                        setValidateFieldsName([
                          ...validateFieldsName,
                          'taxi_number',
                        ])
                      }
                    />
                  </Form.Item>
                  <Form.Item
                    name="morning_driver_name"
                    label="Morning Driver Name"
                    // validateTrigger={handleValidateFieldNames(
                    //   'morning_driver_name'
                    // )}
                    // rules={[
                    //   {
                    //     required: true,
                    //     message: 'Please enter morning driver name!',
                    //   },
                    // ]}
                  >
                    <Input
                      type="text"
                      placeholder="Morning Driver Name"
                      // onBlur={() =>
                      //   setValidateFieldsName([
                      //     ...validateFieldsName,
                      //     'morning_driver_name',
                      //   ])
                      // }
                    />
                  </Form.Item>
                  <Form.Item
                    name="morning_driver_mobile"
                    label="Morning Driver Mobile"
                    validateTrigger={handleValidateFieldNames(
                      'morning_driver_mobile'
                    )}
                    rules={[
                      // {
                      //   required: true,
                      //   message: 'Please enter morning driver mobile!',
                      // },
                      {
                        pattern: new RegExp(/^[0-9]+$/),
                        message: 'Please enter correct phone number!',
                      },
                    ]}
                  >
                    <Input
                      prefix="+971"
                      placeholder="Morning Driver Mobile"
                      style={{ width: '100%' }}
                      onBlur={() =>
                        setValidateFieldsName([
                          ...validateFieldsName,
                          'morning_driver_mobile',
                        ])
                      }
                    />
                  </Form.Item>
                  <Form.Item
                    name="evening_driver_name"
                    label="Evening Driver Name"
                    validateTrigger={handleValidateFieldNames(
                      'evening_driver_name'
                    )}
                    // rules={[
                    //   {
                    //     required: true,
                    //     message: 'Please enter evening driver name!',
                    //   },
                    // ]}
                  >
                    <Input
                      type="text"
                      placeholder="Evening Driver Name"
                      onBlur={() =>
                        setValidateFieldsName([
                          ...validateFieldsName,
                          'evening_driver_name',
                        ])
                      }
                    />
                  </Form.Item>
                  <Form.Item
                    name="evening_driver_mobile"
                    label="Evening Driver Mobile"
                    validateTrigger={handleValidateFieldNames(
                      'evening_driver_mobile'
                    )}
                    rules={[
                      // {
                      //   required: true,
                      //   message: 'Please enter evening driver mobile!',
                      // },
                      {
                        pattern: new RegExp(/^[0-9]+$/),
                        message: 'Please enter correct phone number!',
                      },
                    ]}
                  >
                    <Input
                      prefix="+971"
                      style={{ width: '100%' }}
                      placeholder="Evening Driver Mobile"
                      onBlur={() =>
                        setValidateFieldsName([
                          ...validateFieldsName,
                          'evening_driver_mobile',
                        ])
                      }
                    />
                  </Form.Item>
                </Col>
              </Row>
            </Form>
          ) : (
            <>
              {renderLableColumnRows(
                'Taxi Number: ',
                deviceDetails?.taxi_number
              )}
              {renderLableColumnRows(
                'Morning Driver Name: ',
                deviceDetails?.morning_driver_name
              )}
              {renderLableColumnRows(
                'Morning Driver Mobile: ',
                deviceDetails?.morning_driver_mobile
              )}
              {renderLableColumnRows(
                'Evening Driver Name: ',
                deviceDetails?.evening_driver_name
              )}
              {renderLableColumnRows(
                'Evening Driver Mobile: ',
                deviceDetails?.evening_driver_mobile
              )}
            </>
          )}
        </Panel>
      </Collapse>
    </CollapseBox>
  );
};

RidesInfo.propTypes = {
  deviceDetails: object,
  handleRideInfoFormSubmit: func,
  isFetching: bool,
  // isRideInfoFormSubmitting: bool,
  rideInfoForm: object,
  rideInfoInlineEdit: bool,
  // setIsRideInfoFormSubmitting: func,
  setRideInfoInlineEdit: func,
};

export default RidesInfo;
