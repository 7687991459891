import React, { useEffect, useState } from 'react';
import { Modal, Form, Button, Input, Select, Spin, Tooltip } from 'antd';
import { string, func, bool } from 'prop-types';
import {
  generateRandomPasswordString,
} from 'utils';
import { MODE_TYPES } from 'constants/common';
import { SyncOutlined } from '@ant-design/icons';

const { Option } = Select;

const AddEditUserModal = (props) => {
  const {
    activeMode,
    handleCloseModal,
    modalVisible,
    sectionName,
  } = props;

  const optionList=[
    {label:"Dashboard", value:"Dashboard"},
    {label:"Devices", value:"Devices"},
    {label:"Content", value:"Content"},
    {label:"App Release Manager", value:"App Release Manager"},
    {label:"Campaign", value:"Campaign"},
    {label:"Rides", value:"Rides"},
    {label:"User Management", value:"User Management"},
    {label:"Reports", value:"Reports"}
  ]

  const [form] = Form.useForm();

  const [validateFieldsName, setValidateFieldsName] = useState([]);
  const [rolesList, setRolesList] = useState([]);
  const [isFormSubmitting, setIsFormSubmitting] = useState(false);

  // useEffect(() => {
  //   fetchRolesList();
  // }, []);

  useEffect(() => {
    if (activeMode === MODE_TYPES.UPDATE) {
      fetchSelectedUserDetails();
    }
  }, [activeMode]);

  const modalTitle = `${activeMode} ${sectionName}`;

  const fetchSelectedUserDetails = () => {
    // setIsFormSubmitting(true);
    // const url = `${URLS_V2?.USERS?.GET_SINGLE_USER_DETAILS}?id=${recordId}`;
    // httpGet(url)
    //   .then((res) => {
    //     if (res?.status === 200 && res?.data) {
    //       form.setFieldsValue(res?.data);
    //     }
    //     setIsFormSubmitting(false);
    //   })
    //   .catch((err) => {
    //     setIsFormSubmitting(false);
    //   });
  };

  // const fetchRolesList = () => {
  //   // const url = `${URLS_V2?.USERS?.ROLES_LIST}`;
  //   // httpGet(url)
  //   //   .then((res) => {
  //   //     if (res?.status === 200) {
  //   //       let tempRoleTypesData = [];
  //   //       if (res?.data && res?.data.length > 0) {
  //   //         tempRoleTypesData = res?.data.map((data) => {
  //   //           return {
  //   //             ...data,
  //   //             key: data?._id,
  //   //           };
  //   //         });
  //   //       }
  //   //       setRolesList(tempRoleTypesData || []);
  //   //     }
  //   //   })
  //   //   .catch((err) => {
  //   //     console.log('error in fetch roles', err);
  //   //   });

  //   setRolesList([{label:"Admin", value:"Admin"}])
  // };



  const handleValidateFieldNames = (name) => {
    const isFieldName = validateFieldsName.find(
      (fieldName) => fieldName === name
    );
    if (isFieldName) return 'onChange';
    return 'onBlur';
  };

  const handleCancelClick = () => {
    form.resetFields();
    handleCloseModal();
  };

  const handleSubmitModal = () => {
    // const url = `${URLS_V2?.USERS?.CREATE_UPDATE_USER}`;
    // form.submit();
    // form
    //   .validateFields()
    //   .then(async (values) => {
    //     setIsFormSubmitting(true);
    //     let method = httpPost;
    //     let msg = `${sectionName} created successfully`;
    //     if (activeMode === MODE_TYPES.UPDATE) {
    //       values = { ...values, id: recordId };
    //       method = httpPut;
    //       msg = `${sectionName} updated successfully`;
    //     }
    //     method(url, values)
    //       .then((res) => {
    //         if (res.status === 200 || res.status === 201) {
    //           showSuccessMsg(msg);
    //           form.resetFields();
    //           handleSubmitSuccessModal();
    //         } else if (
    //           res?.status &&
    //           API_V2_ERROR_CODES.includes(res?.status)
    //         ) {
    //           showErrorMsg(res?.message || res?.error);
    //         }
    //         setIsFormSubmitting(false);
    //       })
    //       .catch((err) => {
    //         showErrorMsg(err);
    //         setIsFormSubmitting(false);
    //       });
    //   })
    //   .catch((errorInfo) => errorInfo);
  };

  let modalFooterButtons = [
    <Button
      key="add"
      type="primary"
      onClick={handleSubmitModal}
      disabled={isFormSubmitting}
    >
      {isFormSubmitting ? 'Loading...' : modalTitle}
    </Button>,
    <Button key="close" onClick={handleCancelClick} disabled={isFormSubmitting}>
      Close
    </Button>,
  ];

  const handleGeneratePassword = () => {
    form.setFieldsValue({ password: generateRandomPasswordString() });
  };

  return (
    <>
      <Modal
        title={modalTitle}
        visible={modalVisible}
        // onOk={handleOk}
        onCancel={handleCancelClick}
        // className="expense-modal"
        maskClosable={false}
        width={550}
        forceRender={true}
        footer={modalFooterButtons}
      >
        <Spin spinning={isFormSubmitting}>
          <Form form={form} layout="vertical">
            <div className="while-bg-box filter-form">
              <Form.Item
                name="name"
                label="User Name"
                autoComplete="off"
                validateTrigger={handleValidateFieldNames('name')}
                rules={[
                  {
                    required: true,
                    message: 'Please enter user name!',
                  },
                ]}
              >
                <Input
                  style={{ width: '100%' }}
                  placeholder={'User Name'}
                  onBlur={() =>
                    setValidateFieldsName([...validateFieldsName, 'name'])
                  }
                  disabled={isFormSubmitting}
                />
              </Form.Item>{' '}
              <Form.Item
                name="email"
                label="User Email"
                autoComplete="off"
                validateTrigger={handleValidateFieldNames('email')}
                rules={[
                  {
                    required: true,
                    message: 'Please enter user email!',
                  },
                ]}
              >
                <Input
                  style={{ width: '100%' }}
                  placeholder={'User Email'}
                  onBlur={() =>
                    setValidateFieldsName([...validateFieldsName, 'email'])
                  }
                  disabled={isFormSubmitting}
                />
              </Form.Item>
              {activeMode === MODE_TYPES.ADD && (
                <Form.Item
                  name="password"
                  label="Password"
                  validateTrigger={handleValidateFieldNames('password')}
                  rules={[
                    {
                      required: true,
                      message: 'Please enter password!',
                    },
                  ]}
                >
                  <Input.Password
                    placeholder={'Password'}
                    autoComplete="new-password"
                    onBlur={() =>
                      setValidateFieldsName([...validateFieldsName, 'password'])
                    }
                    disabled={isFormSubmitting}
                    addonAfter={
                      <Tooltip title="Click to generate a strong password!">
                        <SyncOutlined
                          onClick={() => handleGeneratePassword()}
                        />
                      </Tooltip>
                    }
                  />
                </Form.Item>
              )}
              <Form.Item
                name="role"
                label="Console Modules"
                validateTrigger={handleValidateFieldNames('role')}
                rules={[
                  {
                    required: true,
                    message: 'Please select role!',
                  },
                ]}
              >
                <Select
                mode="multiple"
                size={'middle'}
                  showSearch
                  allowClear
                  optionFilterProp="children"
                  options={optionList}
                  filterOption={(input, option) =>
                    option.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                  placeholder="Select Role"
                >
                  {/* {
                    optionList.map((data, index) => (
                      <Option key={index} value={data._id}>
                        {data.label}
                      </Option>
                    ))} */}
                </Select>
              </Form.Item>
            </div>
          </Form>
        </Spin>
      </Modal>
    </>
  );
};

AddEditUserModal.propTypes = {
  activeMode: string,
  handleCloseModal: func,
  // handleSubmitSuccessModal: func,
  modalVisible: bool,
  // recordId: string,
  sectionName: string,
};

export default AddEditUserModal;
