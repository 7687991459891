import styled from 'styled-components';

export const ReadingBox = styled.div`
  .header {
    margin: 20px;
    background: white;
  }
  .main-table-body {
    margin: 20px;
  }
  .capitalize {
    text-transform: capitalize;
  }
  .ant-breadcrumb {
    padding: 15px 15px 0px 15px;
  }
  .ant-table-container table > thead > tr:first-child th:last-child {
    text-align: right;
  }
  .claim-table-header {
    display: flex;
    justify-content: space-between;
    padding: 10px 15px 15px 15px;
    .claim-table-title {
      font-weight: 600;
      font-size: 18px;
      margin-bottom: 0px;
      display: flex;
      align-items: center;
    }
  }

  .actions-btn {
    text-align: center;
  }
  .edit-btn {
    margin-right: 10px;
  }
`;

export const Loader = styled.div`
  color: grey;
  font-size: 14px;
`;
