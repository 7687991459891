import React, { useState, useEffect } from 'react';
import { httpGet, httpPut, URLS, showErrorMsg, showSuccessMsg } from 'utils';
import { Form, Row, Col, Input, Modal, Divider, Button } from 'antd';
import { PlusOutlined, DeleteOutlined, EyeOutlined } from '@ant-design/icons';
import {
  AppTilesBox,
  UploadImageBox,
  PreviewImageBox,
} from './_app-tiles-config.styled';

const { TextArea } = Input;

const logoImageInitialStructure = {
  base64: '',
  file: '',
  gcloudUrl: '',
};

const AppTiles = () => {
  const [readForm] = Form.useForm();
  const [gameForm] = Form.useForm();
  const [discoverForm] = Form.useForm();
  const [watchForm] = Form.useForm();

  const [validateFieldsName, setValidateFieldsName] = useState([]);
  const [mainImage, setMainImage] = useState(logoImageInitialStructure);
  const [readImage, setReadImage] = useState(logoImageInitialStructure);
  const [gameImage, setGameImage] = useState(logoImageInitialStructure);
  const [discoverImage, setDiscoverImage] = useState(logoImageInitialStructure);
  const [watchImage, setWatchImage] = useState(logoImageInitialStructure);
  const [isSettingsFetching, setIsSettingsFetching] = useState(false);
  const [isPreviewMainImg, setIsPreviewMainImg] = useState(false);
  const [isPreviewReadImg, setIsPreviewReadImg] = useState(false);
  const [isPreviewGameImg, setIsPreviewGameImg] = useState(false);
  const [isPreviewDiscoverImg, setIsPreviewDiscoverImg] = useState(false);
  const [isPreviewWatchImg, setIsPreviewWatchImg] = useState(false);
  const [contentUpdating, setContentUpdating] = useState(false);
  const [currentContentUpdatingType, setCurrentContentUpdatingType] =
    useState('');

  const mainImageFileSize = 200; //size is mentioned in KB
  const mainImageFileWidth = 1100; //required logo width
  const mainImageFileHeight = 700; //required logo height

  const tilesImagesFileSize = 200; //size is mentioned in KB
  const tilesImagesFileWidth = 276; //required logo width
  const tilesImagesFileHeight = 573; //required logo height

  useEffect(() => {
    fetchAppTiles();
  }, []);

  const getBase64 = (img, callback) => {
    const reader = new FileReader();
    reader.addEventListener('load', () => callback(reader.result));
    reader.readAsDataURL(img);
  };

  const handleLogoImgUpload = async (e, type) => {
    const file = e.target.files[0];
    let requiredFileSize = tilesImagesFileSize;
    let requiredFileWidth = tilesImagesFileWidth;
    let requiredFileHeight = tilesImagesFileHeight;

    if (type === 'main') {
      requiredFileSize = mainImageFileSize;
      requiredFileWidth = mainImageFileWidth;
      requiredFileHeight = mainImageFileHeight;
    }

    if (file) {
      const isImageValid = await new Promise((resolve, reject) => {
        // check the file type - you can specify the types you'd like here:
        const isImg =
          file.type === 'image/jpeg' ||
          file.type === 'image/jpg' ||
          file.type === 'image/png';

        if (!isImg) {
          showErrorMsg(
            `Invalid image extension. Image must be in .png, .jpeg, or .jpg extension`
          );
          resolve(false);
          return;
        }

        // check the file size - you can specify the file size you'd like here:
        const imageSize = file.size / 1024 > requiredFileSize;
        if (imageSize) {
          showErrorMsg(`Image size must be smaller then ${requiredFileSize}KB`);
          resolve(false);
          return;
        }

        // check for dimensions
        var reader = new FileReader();
        // Read the contents of Image File.
        reader.readAsDataURL(file);
        reader.onload = (event) => {
          // Initiate the JavaScript Image object.
          var image = new Image();

          // Set the Base64 string return from FileReader as source.
          image.src = event.target.result;

          image.onload = function () {
            const { height, width } = this;
            // if the aspect ratio is in our sweet spot, proceed - you can specify whatever checks for height and width you want
            if (width !== requiredFileWidth || height !== requiredFileHeight) {
              showErrorMsg(
                `Image does not have optimal dimensions. Recommended image dimensions are ${requiredFileWidth} X ${requiredFileHeight}.`
              );
              resolve(false);
            } else {
              resolve(true);
            }
          };
        };
      });

      if (isImageValid) {
        getBase64(file, (imageUrl) => {
          if (type === 'main') {
            setMainImage({
              ...logoImageInitialStructure,
              base64: imageUrl,
              file,
            });
          } else if (type === 'read') {
            setReadImage({
              ...logoImageInitialStructure,
              base64: imageUrl,
              file,
            });
          } else if (type === 'game') {
            setGameImage({
              ...logoImageInitialStructure,
              base64: imageUrl,
              file,
            });
          } else if (type === 'discover') {
            setDiscoverImage({
              ...logoImageInitialStructure,
              base64: imageUrl,
              file,
            });
          } else if (type === 'watch') {
            setWatchImage({
              ...logoImageInitialStructure,
              base64: imageUrl,
              file,
            });
          }
        });
      }
    }
  };

  const handlePreviewLogoImg = ({ isVisible, type }) => {
    if (type === 'main') {
      setIsPreviewMainImg(isVisible);
    } else if (type === 'read') {
      setIsPreviewReadImg(isVisible);
    } else if (type === 'game') {
      setIsPreviewGameImg(isVisible);
    } else if (type === 'discover') {
      setIsPreviewDiscoverImg(isVisible);
    } else if (type === 'watch') {
      setIsPreviewWatchImg(isVisible);
    }
  };

  const getMainImageUrl = mainImage.gcloudUrl || mainImage.base64;
  const getReadImageUrl = readImage.gcloudUrl || readImage.base64;
  const getGameImageUrl = gameImage.gcloudUrl || gameImage.base64;
  const getDiscoverImageUrl = discoverImage.gcloudUrl || discoverImage.base64;
  const getWatchImageUrl = watchImage.gcloudUrl || watchImage.base64;

  const handleLogoImgDelete = (type) => {
    if (type === 'main') {
      setMainImage(logoImageInitialStructure);
    } else if (type === 'read') {
      setReadImage(logoImageInitialStructure);
    } else if (type === 'game') {
      setGameImage(logoImageInitialStructure);
    } else if (type === 'discover') {
      setDiscoverImage(logoImageInitialStructure);
    } else if (type === 'watch') {
      setWatchImage(logoImageInitialStructure);
    }
  };

  const fetchAppTiles = () => {
    setIsSettingsFetching(true);
    const url = URLS?.APP_BG_TILES_SETTINGS?.GET_SETTINGS;

    httpGet(url)
      .then((res) => {
        if (res?.status === 200) {
          if (res?.data && Object.entries(res?.data)) {
            if (res?.data?.main_image && Object.entries(res.data.main_image)) {
              setMainImage({
                ...logoImageInitialStructure,
                gcloudUrl: res?.data?.main_image,
              });
            }
            if (
              res?.data?.read &&
              Object.entries(res?.data?.read) &&
              Object.entries(res?.data?.read).length > 0
            ) {
              Object.entries(res?.data?.read).forEach(([key, value]) => {
                if (key === 'image') {
                  setReadImage({
                    ...logoImageInitialStructure,
                    gcloudUrl: value,
                  });
                } else {
                  readForm.setFieldsValue({
                    [['read_' + key]]: value,
                  });
                }
              });
            }
            if (
              res?.data?.play &&
              Object.entries(res?.data?.play) &&
              Object.entries(res?.data?.play).length > 0
            ) {
              Object.entries(res?.data?.play).forEach(([key, value]) => {
                if (key === 'image') {
                  setGameImage({
                    ...logoImageInitialStructure,
                    gcloudUrl: value,
                  });
                } else {
                  gameForm.setFieldsValue({
                    [['game_' + key]]: value,
                  });
                }
              });
            }
            if (
              res?.data?.discover &&
              Object.entries(res?.data?.discover) &&
              Object.entries(res?.data?.discover).length > 0
            ) {
              Object.entries(res?.data?.discover).forEach(([key, value]) => {
                if (key === 'image') {
                  setDiscoverImage({
                    ...logoImageInitialStructure,
                    gcloudUrl: value,
                  });
                } else {
                  discoverForm.setFieldsValue({
                    [['discover_' + key]]: value,
                  });
                }
              });
            }
            if (
              res?.data?.watch &&
              Object.entries(res?.data?.watch) &&
              Object.entries(res?.data?.watch).length > 0
            ) {
              Object.entries(res?.data?.watch).forEach(([key, value]) => {
                if (key === 'image') {
                  setWatchImage({
                    ...logoImageInitialStructure,
                    gcloudUrl: value,
                  });
                } else {
                  watchForm.setFieldsValue({
                    [['watch_' + key]]: value,
                  });
                }
              });
            }
          }
        }
        setIsSettingsFetching(false);
      })
      .catch(() => {
        setIsSettingsFetching(false);
      });
  };

  const handleValidateFieldNames = (name) => {
    const isFieldName = validateFieldsName.find(
      (fieldName) => fieldName === name
    );
    if (isFieldName) return 'onChange';
    return 'onBlur';
  };

  const handleUpdateMainImgSettings = () => {
    setCurrentContentUpdatingType('main');
    const formData = new FormData();
    if (!mainImage.file && !mainImage.gcloudUrl) {
      showErrorMsg('main background image required!');
      return;
    }
    if (typeof mainImage.file === 'object') {
      formData.append('main_image', mainImage.file);
    }
    setContentUpdating(true);
    let url = URLS?.APP_BG_TILES_SETTINGS?.UPDATE_MAIN_BG_IMAGE;
    httpPut(url, formData)
      .then((res) => {
        if (res.status === 200) {
          showSuccessMsg('Settings updated successfully!');
        } else if (res?.error) {
          showErrorMsg(res?.error);
        }
        setContentUpdating(false);
      })
      .catch((err) => {
        showErrorMsg(err);
        setContentUpdating(false);
      });
  };

  const handleUpdateAppTilesSettings = (submittedForm, formType) => {
    setCurrentContentUpdatingType(formType);
    submittedForm.submit();
    submittedForm
      .validateFields()
      .then(async (values) => {
        let url = '';
        const formData = new FormData();
        formData.append('title', values[formType + '_title']);
        formData.append('ar_title', values[formType + '_ar_title']);
        formData.append('desc', values[formType + '_desc']);
        formData.append('ar_desc', values[formType + '_ar_desc']);

        if (formType === 'read') {
          url = URLS?.APP_BG_TILES_SETTINGS?.UPDATE_READ_TILES_SETTINGS;

          if (!readImage.file && !readImage.gcloudUrl) {
            showErrorMsg(formType + ' image required!');
            return;
          }
          if (typeof readImage.file === 'object') {
            formData.append('image', readImage.file);
          }
        } else if (formType === 'game') {
          url = URLS?.APP_BG_TILES_SETTINGS?.UPDATE_GAME_TILES_SETTINGS;

          if (!gameImage.file && !gameImage.gcloudUrl) {
            showErrorMsg(formType + ' image required!');
            return;
          }
          if (typeof gameImage.file === 'object') {
            formData.append('image', gameImage.file);
          }
        } else if (formType === 'discover') {
          url = URLS?.APP_BG_TILES_SETTINGS?.UPDATE_DISCOVER_TILES_SETTINGS;

          if (!discoverImage.file && !discoverImage.gcloudUrl) {
            showErrorMsg(formType + ' image required!');
            return;
          }
          if (typeof discoverImage.file === 'object') {
            formData.append('image', discoverImage.file);
          }
        } else if (formType === 'watch') {
          url = URLS?.APP_BG_TILES_SETTINGS?.UPDATE_WATCH_TILES_SETTINGS;

          if (!watchImage.file && !watchImage.gcloudUrl) {
            showErrorMsg(formType + ' image required!');
            return;
          }
          if (typeof watchImage.file === 'object') {
            formData.append('image', watchImage.file);
          }
        }
        setContentUpdating(true);
        if (url) {
          httpPut(url, formData)
            .then((res) => {
              if (res.status === 200) {
                showSuccessMsg('Settings updated successfully!');
              } else if (res?.error) {
                showErrorMsg(res?.error);
              }
              setContentUpdating(false);
            })
            .catch((err) => {
              showErrorMsg(err);
              setContentUpdating(false);
            });
        }
      })
      .catch((errorInfo) => errorInfo);
  };

  const renderPreviewImageBox = (imageURL, isPreview, type) => (
    <PreviewImageBox>
      <img src={imageURL} alt="logo" />
      <div className="icons-div">
        <div className="icons">
          <div>
            <EyeOutlined
              onClick={() => {
                handlePreviewLogoImg({
                  isVisible: true,
                  type: type,
                });
              }}
            />
          </div>
          <div>
            {!(contentUpdating && currentContentUpdatingType === type) && (
              <DeleteOutlined onClick={() => handleLogoImgDelete(type)} />
            )}
          </div>
        </div>
      </div>
      <Modal
        visible={isPreview}
        title={'Preview'}
        footer={null}
        onCancel={() => handlePreviewLogoImg({ isVisible: false, type: type })}
      >
        <img alt="logo" style={{ width: '100%' }} src={imageURL} />
      </Modal>
    </PreviewImageBox>
  );

  const renderUploadImageBox = (boxTitle, type) => (
    <UploadImageBox htmlFor={`${type}-image-upload`}>
      <div className={`upload-image `}>
        <div className="icon">
          <PlusOutlined />
        </div>
        <div>{boxTitle}</div>
      </div>
      <input
        id={`${type}-image-upload`}
        type="file"
        name={`${type}-image-upload`}
        onChange={(e) => handleLogoImgUpload(e, type)}
        style={{ visibility: 'hidden' }}
      />
    </UploadImageBox>
  );

  return (
    <AppTilesBox>
      <div className="table-header">
        <h3 className="table-title">
          {'Application Tiles BackGround Settings'}
        </h3>
        <div className="table-right-side"></div>
      </div>
      <div className="main-table-body">
        {isSettingsFetching ? (
          'Fetching...'
        ) : (
          <>
            <Row gutter={8}>
              <Col className="gutter-row" xs={6} sm={8}>
                {getMainImageUrl
                  ? renderPreviewImageBox(
                      getMainImageUrl,
                      isPreviewMainImg,
                      'main'
                    )
                  : renderUploadImageBox(
                      'Upload Main Background Image',
                      'main'
                    )}
              </Col>
              <Col className="gutter-row" xs={24} sm={24}>
                <Button
                  type="primary"
                  className="save-btn"
                  onClick={handleUpdateMainImgSettings}
                  disabled={
                    contentUpdating && currentContentUpdatingType === 'main'
                  }
                >
                  {contentUpdating &&
                    currentContentUpdatingType === 'main' &&
                    'Loading...'}
                  {'Update'}
                </Button>
              </Col>
            </Row>
            <Row gutter={24}>
              <Col xs={12} sm={12}>
                <Form form={readForm} layout="vertical">
                  <Divider orientation="left">Read</Divider>
                  <Row gutter={24}>
                    <Col className="gutter-row" xs={8} sm={8}>
                      {getReadImageUrl
                        ? renderPreviewImageBox(
                            getReadImageUrl,
                            isPreviewReadImg,
                            'read'
                          )
                        : renderUploadImageBox(
                            'Upload Reading Tile Image',
                            'read'
                          )}
                    </Col>
                    <Col className="gutter-row" xs={16} sm={16}>
                      <Form.Item
                        name="read_title"
                        label="Title (English)"
                        validateTrigger={handleValidateFieldNames('read_title')}
                        rules={[
                          {
                            required: true,
                            message: 'Please enter title!',
                          },
                        ]}
                      >
                        <Input
                          type="text"
                          placeholder="Title (English)"
                          disabled={
                            contentUpdating &&
                            currentContentUpdatingType === 'read'
                          }
                          onBlur={() =>
                            setValidateFieldsName([
                              ...validateFieldsName,
                              'read_title',
                            ])
                          }
                        />
                      </Form.Item>
                      <Form.Item
                        name="read_ar_title"
                        label="Title (Arabic)"
                        validateTrigger={handleValidateFieldNames(
                          'read_ar_title'
                        )}
                        rules={[
                          {
                            required: true,
                            message: 'Please enter title!',
                          },
                        ]}
                      >
                        <Input
                          type="text"
                          placeholder="Title (Arabic)"
                          disabled={
                            contentUpdating &&
                            currentContentUpdatingType === 'read'
                          }
                          onBlur={() =>
                            setValidateFieldsName([
                              ...validateFieldsName,
                              'read_ar_title',
                            ])
                          }
                        />
                      </Form.Item>
                    </Col>
                    <Col className="gutter-row" xs={24} sm={24}>
                      <Form.Item
                        name="read_desc"
                        label="Description (English)"
                        validateTrigger={handleValidateFieldNames('read_desc')}
                        rules={[
                          {
                            required: true,
                            message: 'Please enter description!',
                          },
                        ]}
                      >
                        <TextArea
                          rows={4}
                          placeholder="Description (English)"
                          disabled={
                            contentUpdating &&
                            currentContentUpdatingType === 'read'
                          }
                          onBlur={() =>
                            setValidateFieldsName([
                              ...validateFieldsName,
                              'read_desc',
                            ])
                          }
                        />
                      </Form.Item>
                      <Form.Item
                        name="read_ar_desc"
                        label="Description (Arabic)"
                        validateTrigger={handleValidateFieldNames(
                          'read_ar_desc'
                        )}
                        rules={[
                          {
                            required: true,
                            message: 'Please enter description!',
                          },
                        ]}
                      >
                        <TextArea
                          rows={4}
                          placeholder="Description (Arabic)"
                          disabled={
                            contentUpdating &&
                            currentContentUpdatingType === 'read'
                          }
                          onBlur={() =>
                            setValidateFieldsName([
                              ...validateFieldsName,
                              'read_ar_desc',
                            ])
                          }
                        />
                      </Form.Item>
                    </Col>
                    <Col className="gutter-row" xs={24} sm={24}>
                      <Button
                        type="primary"
                        className="save-btn"
                        onClick={() =>
                          handleUpdateAppTilesSettings(readForm, 'read')
                        }
                        disabled={
                          contentUpdating &&
                          currentContentUpdatingType === 'read'
                        }
                      >
                        {contentUpdating &&
                          currentContentUpdatingType === 'read' &&
                          'Loading...'}
                        {'Update'}
                      </Button>
                    </Col>
                  </Row>
                </Form>
              </Col>
              <Col xs={12} sm={12}>
                <Form form={gameForm} layout="vertical">
                  <Divider orientation="left">Game</Divider>
                  <Row gutter={24}>
                    <Col className="gutter-row" xs={8} sm={8}>
                      {getGameImageUrl
                        ? renderPreviewImageBox(
                            getGameImageUrl,
                            isPreviewGameImg,
                            'game'
                          )
                        : renderUploadImageBox(
                            'Upload Game Tile Image',
                            'game'
                          )}
                    </Col>
                    <Col className="gutter-row" xs={16} sm={16}>
                      <Form.Item
                        name="game_title"
                        label="Title (English)"
                        validateTrigger={handleValidateFieldNames('game_title')}
                        rules={[
                          {
                            required: true,
                            message: 'Please enter title!',
                          },
                        ]}
                      >
                        <Input
                          type="text"
                          placeholder="Title (English)"
                          disabled={
                            contentUpdating &&
                            currentContentUpdatingType === 'game'
                          }
                          onBlur={() =>
                            setValidateFieldsName([
                              ...validateFieldsName,
                              'game_title',
                            ])
                          }
                        />
                      </Form.Item>
                      <Form.Item
                        name="game_ar_title"
                        label="Title (Arabic)"
                        validateTrigger={handleValidateFieldNames(
                          'game_ar_title'
                        )}
                        rules={[
                          {
                            required: true,
                            message: 'Please enter title!',
                          },
                        ]}
                      >
                        <Input
                          type="text"
                          placeholder="Title (Arabic)"
                          disabled={
                            contentUpdating &&
                            currentContentUpdatingType === 'game'
                          }
                          onBlur={() =>
                            setValidateFieldsName([
                              ...validateFieldsName,
                              'game_ar_title',
                            ])
                          }
                        />
                      </Form.Item>
                    </Col>
                    <Col className="gutter-row" xs={24} sm={24}>
                      <Form.Item
                        name="game_desc"
                        label="Description (English)"
                        validateTrigger={handleValidateFieldNames('game_desc')}
                        rules={[
                          {
                            required: true,
                            message: 'Please enter description!',
                          },
                        ]}
                      >
                        <TextArea
                          rows={4}
                          placeholder="Description (English)"
                          disabled={
                            contentUpdating &&
                            currentContentUpdatingType === 'game'
                          }
                          onBlur={() =>
                            setValidateFieldsName([
                              ...validateFieldsName,
                              'game_desc',
                            ])
                          }
                        />
                      </Form.Item>
                      <Form.Item
                        name="game_ar_desc"
                        label="Description (Arabic)"
                        validateTrigger={handleValidateFieldNames(
                          'game_ar_desc'
                        )}
                        rules={[
                          {
                            required: true,
                            message: 'Please enter description!',
                          },
                        ]}
                      >
                        <TextArea
                          rows={4}
                          placeholder="Description (Arabic)"
                          disabled={
                            contentUpdating &&
                            currentContentUpdatingType === 'game'
                          }
                          onBlur={() =>
                            setValidateFieldsName([
                              ...validateFieldsName,
                              'game_ar_desc',
                            ])
                          }
                        />
                      </Form.Item>
                    </Col>
                    <Col className="gutter-row" xs={24} sm={24}>
                      <Button
                        type="primary"
                        className="save-btn"
                        onClick={() =>
                          handleUpdateAppTilesSettings(gameForm, 'game')
                        }
                        disabled={
                          contentUpdating &&
                          currentContentUpdatingType === 'game'
                        }
                      >
                        {contentUpdating &&
                          currentContentUpdatingType === 'game' &&
                          'Loading...'}
                        {'Update'}
                      </Button>
                    </Col>
                  </Row>
                </Form>
              </Col>
            </Row>
            <Row gutter={24}>
              <Col xs={12} sm={12}>
                <Form form={discoverForm} layout="vertical">
                  <Divider orientation="left">Discover</Divider>
                  <Row gutter={24}>
                    <Col className="gutter-row" xs={8} sm={8}>
                      {getDiscoverImageUrl
                        ? renderPreviewImageBox(
                            getDiscoverImageUrl,
                            isPreviewDiscoverImg,
                            'discover'
                          )
                        : renderUploadImageBox(
                            'Upload Discover Tile Image',
                            'discover'
                          )}
                    </Col>
                    <Col className="gutter-row" xs={16} sm={16}>
                      <Form.Item
                        name="discover_title"
                        label="Title (English)"
                        validateTrigger={handleValidateFieldNames(
                          'discover_title'
                        )}
                        rules={[
                          {
                            required: true,
                            message: 'Please enter name!',
                          },
                        ]}
                      >
                        <Input
                          type="text"
                          placeholder="Title (English)"
                          disabled={
                            contentUpdating &&
                            currentContentUpdatingType === 'discover'
                          }
                          onBlur={() =>
                            setValidateFieldsName([
                              ...validateFieldsName,
                              'discover_title',
                            ])
                          }
                        />
                      </Form.Item>
                      <Form.Item
                        name="discover_ar_title"
                        label="Title (Arabic)"
                        validateTrigger={handleValidateFieldNames(
                          'discover_ar_title'
                        )}
                        rules={[
                          {
                            required: true,
                            message: 'Please enter name!',
                          },
                        ]}
                      >
                        <Input
                          type="text"
                          placeholder="Title (Arabic)"
                          disabled={
                            contentUpdating &&
                            currentContentUpdatingType === 'discover'
                          }
                          onBlur={() =>
                            setValidateFieldsName([
                              ...validateFieldsName,
                              'discover_ar_title',
                            ])
                          }
                        />
                      </Form.Item>
                    </Col>
                    <Col className="gutter-row" xs={24} sm={24}>
                      <Form.Item
                        name="discover_desc"
                        label="Description (English)"
                        validateTrigger={handleValidateFieldNames(
                          'discover_desc'
                        )}
                        rules={[
                          {
                            required: true,
                            message: 'Please enter description!',
                          },
                        ]}
                      >
                        <TextArea
                          rows={4}
                          placeholder="Description (English)"
                          disabled={
                            contentUpdating &&
                            currentContentUpdatingType === 'discover'
                          }
                          onBlur={() =>
                            setValidateFieldsName([
                              ...validateFieldsName,
                              'discover_desc',
                            ])
                          }
                        />
                      </Form.Item>
                      <Form.Item
                        name="discover_ar_desc"
                        label="Description (Arabic)"
                        validateTrigger={handleValidateFieldNames(
                          'discover_ar_desc'
                        )}
                        rules={[
                          {
                            required: true,
                            message: 'Please enter description!',
                          },
                        ]}
                      >
                        <TextArea
                          rows={4}
                          placeholder="Description (Arabic)"
                          disabled={
                            contentUpdating &&
                            currentContentUpdatingType === 'discover'
                          }
                          onBlur={() =>
                            setValidateFieldsName([
                              ...validateFieldsName,
                              'discover_ar_desc',
                            ])
                          }
                        />
                      </Form.Item>
                    </Col>
                    <Col className="gutter-row" xs={24} sm={24}>
                      <Button
                        type="primary"
                        className="save-btn"
                        onClick={() =>
                          handleUpdateAppTilesSettings(discoverForm, 'discover')
                        }
                        disabled={
                          contentUpdating &&
                          currentContentUpdatingType === 'discover'
                        }
                      >
                        {contentUpdating &&
                          currentContentUpdatingType === 'discover' &&
                          'Loading...'}
                        {'Update'}
                      </Button>
                    </Col>
                  </Row>
                </Form>
              </Col>
              <Col xs={12} sm={12}>
                <Form form={watchForm} layout="vertical">
                  <Divider orientation="left">Watch</Divider>
                  <Row gutter={24}>
                    <Col className="gutter-row" xs={8} sm={8}>
                      {getWatchImageUrl
                        ? renderPreviewImageBox(
                            getWatchImageUrl,
                            isPreviewWatchImg,
                            'watch'
                          )
                        : renderUploadImageBox(
                            'Upload Watch Tile Image',
                            'watch'
                          )}
                    </Col>
                    <Col className="gutter-row" xs={16} sm={16}>
                      <Form.Item
                        name="watch_title"
                        label="Title (English)"
                        validateTrigger={handleValidateFieldNames(
                          'watch_title'
                        )}
                        rules={[
                          {
                            required: true,
                            message: 'Please enter title!',
                          },
                        ]}
                      >
                        <Input
                          type="text"
                          placeholder="Title (English)"
                          disabled={
                            contentUpdating &&
                            currentContentUpdatingType === 'watch'
                          }
                          onBlur={() =>
                            setValidateFieldsName([
                              ...validateFieldsName,
                              'watch_title',
                            ])
                          }
                        />
                      </Form.Item>
                      <Form.Item
                        name="watch_ar_title"
                        label="Title (Arabic)"
                        validateTrigger={handleValidateFieldNames(
                          'watch_ar_title'
                        )}
                        rules={[
                          {
                            required: true,
                            message: 'Please enter title!',
                          },
                        ]}
                      >
                        <Input
                          type="text"
                          placeholder="Title (Arabic)"
                          disabled={
                            contentUpdating &&
                            currentContentUpdatingType === 'watch'
                          }
                          onBlur={() =>
                            setValidateFieldsName([
                              ...validateFieldsName,
                              'watch_ar_title',
                            ])
                          }
                        />
                      </Form.Item>
                    </Col>
                    <Col className="gutter-row" xs={24} sm={24}>
                      <Form.Item
                        name="watch_desc"
                        label="Description (English)"
                        validateTrigger={handleValidateFieldNames('watch_desc')}
                        rules={[
                          {
                            required: true,
                            message: 'Please enter description!',
                          },
                        ]}
                      >
                        <TextArea
                          rows={4}
                          placeholder="Description (English)"
                          disabled={
                            contentUpdating &&
                            currentContentUpdatingType === 'watch'
                          }
                          onBlur={() =>
                            setValidateFieldsName([
                              ...validateFieldsName,
                              'watch_desc',
                            ])
                          }
                        />
                      </Form.Item>
                      <Form.Item
                        name="watch_ar_desc"
                        label="Description (Arabic)"
                        validateTrigger={handleValidateFieldNames(
                          'watch_ar_desc'
                        )}
                        rules={[
                          {
                            required: true,
                            message: 'Please enter description!',
                          },
                        ]}
                      >
                        <TextArea
                          rows={4}
                          placeholder="Description (Arabic)"
                          disabled={
                            contentUpdating &&
                            currentContentUpdatingType === 'watch'
                          }
                          onBlur={() =>
                            setValidateFieldsName([
                              ...validateFieldsName,
                              'watch_ar_desc',
                            ])
                          }
                        />
                      </Form.Item>
                    </Col>
                    <Col className="gutter-row" xs={24} sm={24}>
                      <Button
                        type="primary"
                        className="save-btn"
                        onClick={() =>
                          handleUpdateAppTilesSettings(watchForm, 'watch')
                        }
                        disabled={
                          contentUpdating &&
                          currentContentUpdatingType === 'watch'
                        }
                      >
                        {contentUpdating &&
                          currentContentUpdatingType === 'watch' &&
                          'Loading...'}
                        {'Update'}
                      </Button>
                    </Col>
                  </Row>
                </Form>
              </Col>
            </Row>
          </>
        )}
      </div>
    </AppTilesBox>
  );
};

export default AppTiles;
