import React, { useState, useEffect } from 'react';
import { Button, Breadcrumb, Table, Popconfirm, Spin } from 'antd';
import {
  httpDelete,
  httpGet,
  URLS,
  showErrorMsg,
  showSuccessMsg,
  updateFirebaseGlobalAppSettings,
} from 'utils';
import { ReadingBox, Loader } from './_watch-category.styled';
import {
  // FormOutlined,
  DeleteOutlined,
  LoadingOutlined,
} from '@ant-design/icons';
import moment from 'moment';
import WatchCategoryModal from 'components/watch-category-modal';
import {
  API_V2_ERROR_CODES,
  GLOBAL_APP_SETTINGS_KEYS,
  MODE_TYPES,
} from 'constants/common';

const moduleName = 'Category'; // main module name used to set in modal title, button name or success/error messages etc...

const WatchCategory = () => {
  const [isFetchingList, setIsFetchingList] = useState(false);
  const [deletingRecordId, setDeletingRecordId] = useState('');
  const [dataList, setDataList] = useState([]);
  const [activeMode, setActiveMode] = useState();
  const [recordId, setRecordId] = useState();

  useEffect(() => {
    fetchWatchCategoryList();
  }, []);

  const fetchWatchCategoryList = () => {
    setIsFetchingList(true);
    const url = URLS?.WATCH_CATEGORY?.WATCH_CATEGORIES_LIST;
    httpGet(url)
      .then((res) => {
        if (res?.status === 200) {
          let readingListData = [];
          if (res?.data && res?.data.length > 0) {
            readingListData = res?.data.map((data) => {
              return {
                ...data,
                key: data?._id,
              };
            });
          }
         
          setDataList(readingListData || []);
        } else if (res?.status && API_V2_ERROR_CODES.includes(res?.status)) {
          showErrorMsg(res?.message);
        }
        setIsFetchingList(false);
      })
      .catch(() => {
        setIsFetchingList(false);
      });
  };

  const handleModeType = ({ activeMode, categoryId }) => {
    setRecordId(categoryId || '');
    setActiveMode(activeMode);
  };

  const closeActiveModeModal = () => {
    setActiveMode('');
  };

  const handleWatchCallback = () => {
    fetchWatchCategoryList();
    setRecordId('');
  };

  const deleteRecord = ({ id }) => {
    setDeletingRecordId(id);
    const url = URLS?.WATCH_CATEGORY?.DELETE_WATCH_CATEGORY.replace('#ID#', id);
    httpDelete(url)
      .then((res) => {
        if (res.status === 200) {
          updateFirebaseGlobalAppSettings(
            GLOBAL_APP_SETTINGS_KEYS.watch_category
          );
          showSuccessMsg(`${moduleName} deleted successfully!`);
          fetchWatchCategoryList();
        } else if (res?.status && API_V2_ERROR_CODES.includes(res?.status)) {
          if (res?.status === 400) {
            showErrorMsg(
              'This record could not be deleted. Category is associated with child records.'
            );
          } else {
            showErrorMsg(res?.message);
          }
        }
        setDeletingRecordId('');
      })
      .catch((err) => {
        showErrorMsg(err);
        setDeletingRecordId('');
      });
  };

  const columns = [
    {
      title: WATCH_CATEGORIES_LABEL.SR_NO,
      width: '4%',
      render: (value, item, index) => index + 1,
    },
    {
      key: 'image',
      title: WATCH_CATEGORIES_LABEL.IMAGE,
      dataIndex: 'image',
      width: '10%',

      render: (image, item) => {
        return (function Actions() {
          return (
            <div className="logo">
              <img src={image} alt={item?.title} width="50px" />
            </div>
          );
        })();
      },
    },
    {
      key: 'title',
      title: WATCH_CATEGORIES_LABEL.TITLE,
      dataIndex: 'title',
      width: '15%',
    },
    {
      key: 'ar_title',
      title: WATCH_CATEGORIES_LABEL.AR_TITLE,
      dataIndex: 'ar_title',
      width: '15%',
    },
    {
      key: 'desc',
      title: WATCH_CATEGORIES_LABEL.DESCRIPTION,
      dataIndex: 'desc',
      width: '15%',
      render: (description) => {
        return (function Actions() {
          return <div style={{ wordBreak: 'break-all' }}>{description}</div>;
        })();
      },
    },
    {
      key: 'ar_desc',
      title: WATCH_CATEGORIES_LABEL.AR_DESCRIPTION,
      dataIndex: 'ar_desc',
      width: '15%',
      render: (ar_description) => {
        return (function Actions() {
          return <div style={{ wordBreak: 'break-all' }}>{ar_description}</div>;
        })();
      },
    },
    {
      key: 'created_at',
      title: WATCH_CATEGORIES_LABEL.CREATED_AT,
      dataIndex: 'created_at',
      width: '15%',
      render: (created_at) => {
        return (function Actions() {
          return (
            <div className="created_at">
              {created_at && moment(created_at).format('Do MMMM YYYY')}
            </div>
          );
        })();
      },
    },
    {
      key: '_id',
      title: WATCH_CATEGORIES_LABEL.ACTIONS,
      dataIndex: '_id',
      width: '10%',
      align: 'center',
      render: (id) => {
        return (function Actions() {
          return (
            <div className="actions-btn">
              {/* <Button
                shape="circle"
                className="edit-btn"
                onClick={() => {
                  handleModeType({
                    activeMode: MODE_TYPES.EDIT,
                    categoryId: id,
                  });
                }}
              >
                <FormOutlined type="primary" className="edit-icon" />
              </Button> */}
              <Popconfirm
                title="Sure to delete?"
                onConfirm={() => deleteRecord({ id })}
                disabled={deletingRecordId === id}
              >
                <Button
                  shape="circle"
                  className="delete-btn"
                  disabled={deletingRecordId === id}
                >
                  {deletingRecordId === id ? (
                    <Spin indicator={<LoadingOutlined spin />} />
                  ) : (
                    <DeleteOutlined type="primary" className="delete-icon" />
                  )}
                </Button>
              </Popconfirm>
            </div>
          );
        })();
      },
    },
  ];

  return (
    <ReadingBox>
      <div className="header">
        <Breadcrumb>
          <Breadcrumb.Item>Content </Breadcrumb.Item>
          <Breadcrumb.Item>Watch </Breadcrumb.Item>
          <Breadcrumb.Item>{moduleName} </Breadcrumb.Item>
        </Breadcrumb>
        <div className="claim-table-header">
          <h3 className="claim-table-title">{moduleName}</h3>
          <Button
            type="primary"
            onClick={() => {
              handleModeType({ activeMode: MODE_TYPES.ADD });
            }}
          >
            Add New
          </Button>
        </div>
      </div>
      <div className="main-table-body">
        <Table
          loading={{
            indicator: <Loader>Fetching...</Loader>,
            spinning: isFetchingList,
          }}
          columns={columns}
          dataSource={dataList || []}
          size="small"
          pagination={false}
        />
      </div>
        <WatchCategoryModal
          activeMode={activeMode}
          closeModal={closeActiveModeModal}
          callback={handleWatchCallback}
          categoryId={recordId}
        />
    </ReadingBox>
  );
};

export default WatchCategory;

const WATCH_CATEGORIES_LABEL = {
  SR_NO: 'Sr. No.',
  IMAGE: 'Image',
  TITLE: 'Title (English)',
  AR_TITLE: 'Title (Arabic)',
  DESCRIPTION: 'Description (English)',
  AR_DESCRIPTION: 'Description (Arabic)',
  CREATED_AT: 'Created At',
  ACTIONS: 'Actions',
};
