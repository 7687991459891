import React from 'react';
import './SpotsDetails.css';
import { Button, Col, DatePicker, Form, Input, Row } from 'antd';
import moment from 'moment';

const { RangePicker } = DatePicker;

const SpotsDetails = (props) => {
  const { spotsForm, setSpotsForm, editModeOff } = props

  const spotsChangeHandler = (e) => {
    setSpotsForm({ ...spotsForm, [e.target.name]: e.target.value })
  }

  const rangePickerOnchange = (value, dateString) => {
    setSpotsForm({
      ...spotsForm, campaign_start_date: dateString[0], campaign_end_date: dateString[1]
    })
  }


  return (
    <div className="Spots-Deatils">
      <Row gutter={16}>
        <Col className="gutter-row" xs={24} sm={20} md={12}>
          <div
            style={{
              padding: '10px 0px 10px 20px',
              fontWeight: 'bold',
              fontSize: '20px',
              backgroundColor: 'white',
              borderBottom: '1px solid #D9D9D9',
            }}
          >
            Spots Details
          </div>
          <div style={{ backgroundColor: 'white', padding: '20px' }}>
            <Col className="gutter-row" xs={24} sm={24} md={24}>
              <div
                style={{
                  display: 'flex',
                  //   alignItems: 'center',
                  fontWeight: 'bold',
                  marginBottom: '40px',
                  //   justifyContent:"space-between"
                }}
              >
                <Col xs={12} sm={12} md={12}>
                  <div><div>Approx Daily Inventory</div> <div>N/A Mass Spots Per Day</div></div>
                </Col>
                <Col xs={12} sm={12} md={12}>
                  <div><div>Approx Monthly Inventory</div> <div>N/A Mass Spots Per 30 Day</div></div>
                </Col>
              </div>

              <Form.Item
                name="Campaign Start and End Date"
                label="Campaign Start and End Date"
                rules={[
                  {
                    required: true,
                    message: 'Please enter Start and End Date!',
                  },
                ]}
              >
                <RangePicker
                  disabledDate={(current) => {
                    let customDate = moment().format("YYYY-MM-DD");
                    return current && current < moment(customDate, "YYYY-MM-DD");
                  }}

                  showTime={{
                    format: 'hh:mm a',
                  }}
                  format="YYYY-MM-DD hh:mm a"
                  style={{ width: '100%' }}
                  className="field-properties"
                  onChange={rangePickerOnchange}
                  disabled={editModeOff}
                />
              </Form.Item>

              <Form.Item
                name="Maximum Alloted Slots"
                label="Maximum Alloted Slots"
                rules={[
                  {
                    required: true,
                    message: 'Please enter alloted spots',
                  },
                ]}
              >
                <Input
                  placeholder="Alloted Spots"
                  type="number"
                  rules={[
                    {
                      required: true,
                      message: 'Please enter alloted spots',
                    },
                  ]}
                  name="allotted_slots"
                  value={spotsForm?.allotted_slots}
                  onChange={spotsChangeHandler}
                  disabled={editModeOff}
                />
              </Form.Item>
              <Form.Item
                name='frequency_per_ride'
                label="Frequency (Per Ride)"
              // validateTrigger={handleValidateFieldNames('campaign_name')}
              // rules={[
              //   {
              //     required: true,
              //     message: 'Enter Frequency',
              //   },
              // ]}
              >
                <Input
                  type="number"
                  defaultValue={1}
                  // rules={[
                  //   {
                  //     required: true,
                  //     message: 'Enter Frequency',
                  //   },
                  // ]}
                  name='frequency_per_ride'
                  value={spotsForm?.frequency_per_ride}
                  onChange={spotsChangeHandler}
                  disabled={editModeOff}
                />
              </Form.Item>
              <Form.Item>
                <Button type="primary" disabled={editModeOff} >Check Inventory</Button>
              </Form.Item>

            </Col>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default SpotsDetails;
