import React, { useState } from 'react';
import CommonBreadCrumb from 'components/common-breadcrumb';
import { Form, Input, Button, Row, Col, Checkbox } from 'antd';
import { UserOutlined, LockOutlined, MailOutlined } from '@ant-design/icons';
import './CreateUser.css';
import { httpPost, showErrorMsg, showSuccessMsg, URLS_V2 } from 'utils';

const CreateUser = () => {
  const breakCrumbList = [
    { label: 'Home', link: '/dashboard' },
    { label: 'Users', link: '/v2/users' },
    { label: 'Create User' },
  ];

  const [form] = Form.useForm();
  const [permissions, setPermissions] = useState({});

  const onFinish = (values) => {
    // console.log(values, 'forum');
    const formValues = form.getFieldsValue(); 
    console.log('Form values on submit:', formValues);

    let data = {
      email: values?.email,
      password: values?.password,
      first_Name: values?.firstname,
      last_Name: values?.lastname,
      console_Access: {
        modules: [
          {
            module_name: 'Device',
            is_read: true,
            is_write: true,
          },
        ],
      },
    };

    for (let x in values) {
      if (typeof values[x] == 'object') {
        data?.console_Access?.modules?.push({
          module_name: x,
          is_read: values[x].includes('read') ? true : false,
          is_write: values[x].includes('write') ? true : false,
        });
      }
    }

    httpPost(URLS_V2.USERS.ADD_USER, data)
      .then((res) => {
        if (res?.status == 201) {
          showSuccessMsg('User Created Successfully!');
        }
      })
      .catch((err) => {
        showErrorMsg(err.message);
      });
  console.log('Updated form values:after', form.getFieldsValue());

  };

  const handleWriteCheck = (selected) => {
    const value=Object.values(selected)
if(typeof value[0]=='object'){
    const writeExtracted=value[0]

    if(writeExtracted.includes('write')){

        const key=Object.keys(selected)
        const updatedValues = {
            ...form.getFieldsValue(),
            [key[0]]:['read', 'write'] 
          };
          form.setFieldsValue(updatedValues);
    }
}
  };


  return (
    <div style={{ padding: '10px' }}>
      <CommonBreadCrumb breadCrumbs={breakCrumbList} />
      <Form form={form} layout="vertical" onFinish={onFinish} onValuesChange={(changedValues, allValues) => handleWriteCheck(changedValues)}>
        <Row gutter={16}>
          <Col xs={24} sm={24} md={12} lg={12} xl={12}>
            <Form.Item
              label="First Name"
              name="firstname"
              rules={[
                { required: true, message: 'Please enter your first name' },
                { max: 50, message: 'First name cannot exceed 50 characters' },
              ]}
            >
              <Input prefix={<UserOutlined />} placeholder="First Name" />
            </Form.Item>
          </Col>
          <Col xs={24} sm={24} md={12} lg={12} xl={12}>
            <Form.Item
              label="Last Name"
              name="lastname"
              rules={[
                { required: true, message: 'Please enter your last name' },
                { max: 50, message: 'Last name cannot exceed 50 characters' },
              ]}
            >
              <Input prefix={<UserOutlined />} placeholder="Last Name" />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col xs={24} sm={24} md={12} lg={12} xl={12}>
            <Form.Item
              label="Email"
              name="email"
              rules={[
                { required: true, message: 'Please enter your email' },
                { type: 'email', message: 'Please enter a valid email' },
              ]}
            >
              <Input prefix={<MailOutlined />} placeholder="Email" />
            </Form.Item>
          </Col>
          <Col xs={24} sm={24} md={12} lg={12} xl={12}>
            <Form.Item
              label="Password"
              name="password"
              rules={[
                { required: true, message: 'Please enter your password' },
                { min: 6, message: 'Password must be at least 6 characters' },
              ]}
            >
              <Input
                prefix={<LockOutlined />}
                type="password"
                placeholder="Password"
              />
            </Form.Item>
          </Col>
        </Row>

        <div>
          <Row gutter={16}>
            <Col xs={24} sm={24} md={24} lg={24} xl={24}>
              <Row>
                <Col
                  xs={24}
                  sm={24}
                  md={24}
                  lg={24}
                  xl={24}
                  style={{ paddingLeft: 'none' }}
                >
                  <div
                    style={{
                      background: 'white',
                      padding: '10px 10px',
                      marginRight: '10px',
                    }}
                  >
                    <Row>
                      <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                        <div className="module-heading">Console Module</div>
                      </Col>
                    </Row>
                    <Row>
                      <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                        <Form.Item label="Dashboard" name="dashboard">
                          <Checkbox.Group>
                            <Row>
                              <Col span={12}>
                                <Form.Item name="read" valuePropName="checked"  >
                                  <Checkbox value="read" >Read</Checkbox>
                                </Form.Item>
                              </Col>
                              <Col span={12}>
                                <Form.Item name="write" valuePropName="checked">
                                  <Checkbox
                                    value="write"
                                  >
                                    Write
                                  </Checkbox>
                                </Form.Item>
                              </Col>
                            </Row>
                          </Checkbox.Group>
                        </Form.Item>
                      </Col>

                      <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                        <Form.Item label="Devices" name="Devices">
                          <Checkbox.Group>
                            <Row>
                              <Col span={12}>
                                <Form.Item name="read" valuePropName="checked">
                                  <Checkbox  value="read">Read</Checkbox>
                                </Form.Item>
                              </Col>
                              <Col span={12}>
                                <Form.Item name="write" valuePropName="checked">
                                  <Checkbox 
                                  value="write"
                                   >Write</Checkbox>
                                </Form.Item>
                              </Col>
                            </Row>
                          </Checkbox.Group>
                        </Form.Item>
                      </Col>
                    </Row>

                    <Row>
                      <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                        <Form.Item label="Content" name="Content">
                          <Checkbox.Group>
                            <Row>
                              <Col span={12}>
                                <Form.Item name="read" valuePropName="checked">
                                  <Checkbox value="read">Read</Checkbox>
                                </Form.Item>
                              </Col>
                              <Col span={12}>
                                <Form.Item name="write" valuePropName="checked">
                                  <Checkbox value="write">Write</Checkbox>
                                </Form.Item>
                              </Col>
                            </Row>
                          </Checkbox.Group>
                        </Form.Item>
                      </Col>

                      <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                        <Form.Item
                          label="App Release Manager"
                          name="App Release Manager"
                        >
                          <Checkbox.Group>
                            <Row>
                              <Col span={12}>
                                <Form.Item name="read" valuePropName="checked">
                                  <Checkbox value="read">Read</Checkbox>
                                </Form.Item>
                              </Col>
                              <Col span={12}>
                                <Form.Item name="write" valuePropName="checked">
                                  <Checkbox value="write">Write</Checkbox>
                                </Form.Item>
                              </Col>
                            </Row>
                          </Checkbox.Group>
                        </Form.Item>
                      </Col>
                    </Row>

                    <Row>
                      <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                        <Form.Item label="Campaign" name="Campaign">
                          <Checkbox.Group>
                            <Row>
                              <Col span={12}>
                                <Form.Item name="read" valuePropName="checked">
                                  <Checkbox value="read">Read</Checkbox>
                                </Form.Item>
                              </Col>
                              <Col span={12}>
                                <Form.Item name="write" valuePropName="checked">
                                  <Checkbox value="write">Write</Checkbox>
                                </Form.Item>
                              </Col>
                            </Row>
                          </Checkbox.Group>
                        </Form.Item>
                      </Col>

                      <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                        <Form.Item label="Rides" name="Rides">
                          <Checkbox.Group>
                            <Row>
                              <Col span={12}>
                                <Form.Item name="read" valuePropName="checked">
                                  <Checkbox value="read">Read</Checkbox>
                                </Form.Item>
                              </Col>
                              <Col span={12}>
                                <Form.Item name="write" valuePropName="checked">
                                  <Checkbox value="write">Write</Checkbox>
                                </Form.Item>
                              </Col>
                            </Row>
                          </Checkbox.Group>
                        </Form.Item>
                      </Col>
                    </Row>

                    <Row>
                      <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                        <Form.Item
                          label="User Management"
                          name="User Management"
                        >
                          <Checkbox.Group>
                            <Row>
                              <Col span={12}>
                                <Form.Item name="read" valuePropName="checked">
                                  <Checkbox value="read">Read</Checkbox>
                                </Form.Item>
                              </Col>
                              <Col span={12}>
                                <Form.Item name="write" valuePropName="checked">
                                  <Checkbox value="write">Write</Checkbox>
                                </Form.Item>
                              </Col>
                            </Row>
                          </Checkbox.Group>
                        </Form.Item>
                      </Col>

                      <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                        <Form.Item label="Reports" name="Reports">
                          <Checkbox.Group>
                            <Row>
                              <Col span={12}>
                                <Form.Item name="read" valuePropName="checked">
                                  <Checkbox value="read">Read</Checkbox>
                                </Form.Item>
                              </Col>
                              <Col span={12}>
                                <Form.Item name="write" valuePropName="checked">
                                  <Checkbox value="write">Write</Checkbox>
                                </Form.Item>
                              </Col>
                            </Row>
                          </Checkbox.Group>
                        </Form.Item>
                      </Col>
                    </Row>
                  </div>
                </Col>

                {/* /////////// */}
{/* 
                <Col
                  xs={12}
                  sm={12}
                  md={12}
                  lg={12}
                  xl={12}
                  style={{ paddingLeft: 'none' }}
                >
                  <div
                    style={{
                      background: 'white',
                      padding: '10px 10px',
                      marginLeft: '10px',
                    }}
                  >
                    <Row>
                      <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                        <div className="module-heading">Reporting Module</div>
                      </Col>
                    </Row>
                    <Row>
                      <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                        <Form.Item label="Live View" name="Live View">
                          <Checkbox.Group>
                            <Row>
                              <Col span={12}>
                                <Form.Item name="read" valuePropName="checked">
                                  <Checkbox value="read">Read</Checkbox>
                                </Form.Item>
                              </Col>
                              <Col span={12}>
                                <Form.Item name="write" valuePropName="checked">
                                  <Checkbox value="write">Write</Checkbox>
                                </Form.Item>
                              </Col>
                            </Row>
                          </Checkbox.Group>
                        </Form.Item>
                      </Col>

                      <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                        <Form.Item label="Spots Report" name="Spots Report">
                          <Checkbox.Group>
                            <Row>
                              <Col span={12}>
                                <Form.Item name="read" valuePropName="checked">
                                  <Checkbox value="read">Read</Checkbox>
                                </Form.Item>
                              </Col>
                              <Col span={12}>
                                <Form.Item name="write" valuePropName="checked">
                                  <Checkbox value="write">Write</Checkbox>
                                </Form.Item>
                              </Col>
                            </Row>
                          </Checkbox.Group>
                        </Form.Item>
                      </Col>
                    </Row>

                    <Row>
                      <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                        <Form.Item label="Clicks Report" name="Clicks Report">
                          <Checkbox.Group>
                            <Row>
                              <Col span={12}>
                                <Form.Item name="read" valuePropName="checked">
                                  <Checkbox value="read">Read</Checkbox>
                                </Form.Item>
                              </Col>
                              <Col span={12}>
                                <Form.Item name="write" valuePropName="checked">
                                  <Checkbox value="write">Write</Checkbox>
                                </Form.Item>
                              </Col>
                            </Row>
                          </Checkbox.Group>
                        </Form.Item>
                      </Col>

                      <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                        <Form.Item label="Leads Report" name="Leads Report">
                          <Checkbox.Group>
                            <Row>
                              <Col span={12}>
                                <Form.Item name="read" valuePropName="checked">
                                  <Checkbox value="read">Read</Checkbox>
                                </Form.Item>
                              </Col>
                              <Col span={12}>
                                <Form.Item name="write" valuePropName="checked">
                                  <Checkbox value="write">Write</Checkbox>
                                </Form.Item>
                              </Col>
                            </Row>
                          </Checkbox.Group>
                        </Form.Item>
                      </Col>
                    </Row>

                    <Row style={{ opacity: 0 }}>
                      <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                        <Form.Item label="Reporting4" name="Reporting4">
                          <Checkbox.Group>
                            <Row>
                              <Col span={12}>
                                <Form.Item name="read" valuePropName="checked">
                                  <Checkbox value="read">Read</Checkbox>
                                </Form.Item>
                              </Col>
                              <Col span={12}>
                                <Form.Item name="write" valuePropName="checked">
                                  <Checkbox value="write">Write</Checkbox>
                                </Form.Item>
                              </Col>
                            </Row>
                          </Checkbox.Group>
                        </Form.Item>
                      </Col>

                      <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                        <Form.Item label="Reporting3" name="Reporting3">
                          <Checkbox.Group>
                            <Row>
                              <Col span={12}>
                                <Form.Item name="read" valuePropName="checked">
                                  <Checkbox value="read">Read</Checkbox>
                                </Form.Item>
                              </Col>
                              <Col span={12}>
                                <Form.Item name="write" valuePropName="checked">
                                  <Checkbox value="write">Write</Checkbox>
                                </Form.Item>
                              </Col>
                            </Row>
                          </Checkbox.Group>
                        </Form.Item>
                      </Col>
                    </Row>

                    <Row style={{ opacity: 0 }}>
                      <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                        <Form.Item label="Reporting2" name="Reporting2">
                          <Checkbox.Group>
                            <Row>
                              <Col span={12}>
                                <Form.Item name="read" valuePropName="checked">
                                  <Checkbox value="read">Read</Checkbox>
                                </Form.Item>
                              </Col>
                              <Col span={12}>
                                <Form.Item name="write" valuePropName="checked">
                                  <Checkbox value="write">Write</Checkbox>
                                </Form.Item>
                              </Col>
                            </Row>
                          </Checkbox.Group>
                        </Form.Item>
                      </Col>

                      <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                        <Form.Item label="Reporting1" name="Reporting1">
                          <Checkbox.Group>
                            <Row>
                              <Col span={12}>
                                <Form.Item name="read" valuePropName="checked">
                                  <Checkbox value="read">Read</Checkbox>
                                </Form.Item>
                              </Col>
                              <Col span={12}>
                                <Form.Item name="write" valuePropName="checked">
                                  <Checkbox value="write">Write</Checkbox>
                                </Form.Item>
                              </Col>
                            </Row>
                          </Checkbox.Group>
                        </Form.Item>
                      </Col>
                    </Row>
                  </div>
                </Col> */}
              </Row>
            </Col>
          </Row>
        </div>
        <Row gutter={16}>
          <Col xs={24} sm={24} md={12} lg={12} xl={12}>
            <Form.Item>
              <Button htmlType="submit" type="primary" className="submit-btn">
                Submit
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </div>
  );
};

export default CreateUser;
