import React, { useEffect, useRef, useState } from 'react';
import mapboxgl from '!mapbox-gl'; // eslint-disable-line import/no-webpack-loader-syntax
import 'mapbox-gl/dist/mapbox-gl.css';
import { RidesDetailsMapBox, RidesDetailsInfoBox } from './_rides-details.styled';
import { URLS_V2, dateFormatter, httpGet } from 'utils';
import { useParams, useHistory } from 'react-router-dom';
import { Timeline } from 'antd';
import { CheckCircleOutlined, CloseCircleOutlined } from '@ant-design/icons';

mapboxgl.accessToken = 'pk.eyJ1IjoiYmluYXJ5YWRtaW4iLCJhIjoiY2t6Mmw4ODNhMDFsMjJ2cWYweTFyb2ZuZyJ9.iBSQIqW6qM_KMV9S_ufb-w';

const RidesDetails = () => {
  const mapContainer = useRef(null);
  const map = useRef(null);
  const [center, setCenter] = useState([55.1848041, 25.1416843]);
  const { rideId } = useParams();
  const history = useHistory();

  const [data, setData] = useState({});

  useEffect(() => {
    // if(!map.current){
    map.current = new mapboxgl.Map({
      container: mapContainer.current,
      style: 'mapbox://styles/binaryadmin/ckzco0u9b004314rwv3tzkcn8',
      center: center,
      zoom: 10,
    });
    // }

    if (map.current) {
      map.current.addControl(new mapboxgl.NavigationControl(), 'top-right');
      map.current.addControl(new mapboxgl.FullscreenControl());
    }

    if (map?.current && Object.keys(data || {})?.length) {

      map?.current?.on('load', () => {

        if (data?.polyLine_Data && data?.polyLine_Data?.length) {

          const mData = data?.polyLine_Data?.map((item) => {
            return [item?.lng, item?.lat]
          });

          map.current.addSource("ride-path", {
            type: 'geojson',
            data: {
              type: "FeatureCollection",
              features: [
                {
                  type: "Feature",
                  properties: {},
                  geometry: {
                    coordinates: mData,
                    type: "LineString"
                  }
                },
              ]
            }
          });

          map.current.addLayer({
            id: 'ride-path-layer',
            source: 'ride-path',
            type: 'line',
            paint: {
              'line-width': 2,
              'line-color': "#f74505"
            }
          });
        }

        if (data?.pickup_location?.coordinates?.length) {

          const startPopup = new mapboxgl.Popup({ offset: 25 }).setHTML(
            `<div><b>Ride started at ${dateFormatter(data?.start_time,)}</b></div>`
          );

          new mapboxgl.Marker({
            color: '#377003'
          })
            .setLngLat([data?.pickup_location?.coordinates[1], data?.pickup_location?.coordinates[0]])
            .setPopup(startPopup)
            .addTo(map?.current);
        }

        data?.campaings_served?.length && data?.campaings_served?.forEach((item) => {
          let campaignPopup;
          if (item?.campaign_name) {
            campaignPopup = new mapboxgl.Popup({ offset: 25 }).setHTML(
              `<div>
                  <div><b>Campaign name:- ${item?.campaign_name}</b><div>
                  <div><b>Campaign Title:- ${item?.campaign_type_title}</b><div>
                  <div><b>Campaign Type:- ${item?.served_type}</b><div>
                  <div><b>Geofence Available:- ${item?.is_geofence_available}</b><div>
                  <div><b>Served Time:- ${dateFormatter(item?.served_time)}</b><div>
                  <div>
                      <button id="show-details" class="ant-btn">show details</button>
                  </div>
                <div>`
            )
          }

          campaignPopup.on('open', () => {
            const showDetailsButton = document.getElementById('show-details');
            if (showDetailsButton) {
              showDetailsButton.style.fontSize = "11px"
              showDetailsButton.style.marginTop = "5px"
              showDetailsButton.addEventListener('click', () => {
                history.push(`/v2/campaigns/details/${item?._id}`)
              });
            }
          });


          new mapboxgl.Marker({
            color: '#f6c824'
          })
            .setLngLat([item?.served_location?.coordinates[1], item?.served_location?.coordinates[0]])
            .setPopup(campaignPopup)
            .addTo(map?.current);
        })

        if (data?.dropoff_location?.coordinates?.length) {
          const endPopup = new mapboxgl.Popup({ offset: 25 }).setHTML(
            `<div><b>Ride ended at ${dateFormatter(data?.stop_time,)}</b></div>`
          );

          new mapboxgl.Marker({
            color: '#db4141'
          })
            .setLngLat([data?.dropoff_location?.coordinates?.[1], data?.dropoff_location?.coordinates?.[0]])
            .setPopup(endPopup)
            .addTo(map?.current);
        }
      }
      );
    }

    return () => {
      // if(map?.current){
      map?.current?.remove()
      // }
    };
  }, [data,]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    getRideData();
  }, []);

  const getRideData = async () => {
    try {
      const url = `${URLS_V2.RIDES.RIDE_INFO.replace("#ID#", rideId)}`;
      const res = await httpGet(url);

      setData(res?.data?.[0]);
      setCenter([res?.data?.[0]?.dropoff_location?.coordinates[1], res?.data?.[0]?.dropoff_location?.coordinates[0]]);
    } catch (error) {
      console.log("error", error);
    }
  };


  return (
    <div>
      <RidesDetailsMapBox>
        <div
          ref={mapContainer}
          className="map-container"
          style={{ height: `100%`, width: `100%` }}
        />
      </RidesDetailsMapBox>
      <RidesDetailsInfoBox>
        <div className='Ride-info-head' >Ride Timeline:</div>

        {Object.keys(data || {})?.length ? <Timeline mode="alternate" >

          {data?.start_time ? <Timeline.Item color='green'>
            <div>
              <span style={{ color: "green", cursor: "pointer" }} onClick={() => map?.current?.flyTo({ center: [data?.pickup_location?.coordinates[1], data?.pickup_location?.coordinates[0]], zoom: 10 })} ><b>Ride Started at:</b> {dateFormatter(data?.start_time)}</span>
            </div>
          </Timeline.Item> : ""}

          {data?.campaings_served?.length ? data?.campaings_served?.map((item) => (
            <Timeline.Item color='#f6c824' key={item?.campaign_name} >
              <div style={{ cursor: "pointer" }} onClick={() => map?.current?.flyTo({ center: [item?.served_location?.coordinates[1], item?.served_location?.coordinates[0]], zoom: 10 })}  >
                <span style={{ color: "#f6c824" }} ><b>Campaign served</b></span>
                <div>
                  <span><b>Campaign Name:</b> {item?.campaign_name}</span>
                </div>
                <div>
                  <span><b>Campaign Type:</b> {item?.served_type} </span>
                </div>
                <div>
                  <span><b>Confirmed Delivery:</b> {item?.is_delivery_confirmed ? <CheckCircleOutlined style={{ color: 'green' }} /> : <CloseCircleOutlined style={{ color: 'red' }} />} </span>
                </div>
                <div>
                  <span><b>Campaign Duration:</b> {item?.served_duration} </span>
                </div>
                {
                  item?.served_time && <div>
                    <span><b>Served Time:</b> {dateFormatter(item?.served_time)} </span>
                  </div>
                }
              </div>
            </Timeline.Item>)
          ) : ''}

          {data?.stop_time ? <Timeline.Item color='red' >
            <div>
              <span style={{ color: "red", cursor: "pointer" }} onClick={() => map?.current?.flyTo({ center: [data?.dropoff_location?.coordinates[1], data?.dropoff_location?.coordinates[0]] })} ><b>Ride Ended at:</b> {dateFormatter(data?.stop_time)}</span>
            </div>
          </Timeline.Item> : ""}

        </Timeline> : ""}
      </RidesDetailsInfoBox>
    </div>
  )
}

export default RidesDetails