import React from 'react';
import { Table, Button } from 'antd';
import { array, func, string } from 'prop-types';

const CommonListTableWithButton = (props) => {
  const {
    tableTitle,
    columns,
    dataSource,
    submitCallBack,
    cancelCallBack,
    submitBtnTitle = 'Confirm',
    cancelBntTitle = 'Cancel',
  } = props;

  return (
    <>
      <div className="table-header while-bg-box m-b-0">
        <h3 className="table-title">{tableTitle}</h3>
        <div className="table-right-side">
          <Button type="primary" onClick={() => submitCallBack()}>
            {submitBtnTitle}
          </Button>
          <Button
            className="m-l-15"
            type="secondary"
            onClick={() => cancelCallBack()}
          >
            {cancelBntTitle}
          </Button>
        </div>
      </div>
      <div className="main-table-body while-bg-box m-t-0">
        <Table
          columns={columns}
          dataSource={dataSource}
          pagination={false}
          size="small"
          // scroll={{ y: 300 }}
        />
      </div>
    </>
  );
};

export default CommonListTableWithButton;

CommonListTableWithButton.propTypes = {
  cancelBntTitle: string,
  cancelCallBack: func,
  columns: array,
  dataSource: array,
  submitBtnTitle: string,
  submitCallBack: func,
  tableTitle: string,
};
