import React, { useState, useEffect } from 'react';
import {
  Table,
  Form,
  Button,
  Dropdown,
  Menu,
  Row,
  Col,
  Switch,
  Popconfirm,
  Input,
  Badge,
  Select,
  Checkbox,
} from 'antd';
// import moment from 'moment';
import { Link } from 'react-router-dom';
// import { MODE_TYPES } from 'constants/common';
import { CSVLink } from 'react-csv';
import CommonBreadCrumb from 'components/common-breadcrumb';
import {
  calcMinsFromCurrentDateTime,
  currentPage,
  formatDate,
  httpGet,
  httpPut,
  showErrorMsg,
  showSuccessMsg,
  URLS_V2,
} from 'utils';
import {
  // FormOutlined,
  DownOutlined,
  CloseOutlined,
  EyeOutlined,
  DeleteOutlined,
  DownloadOutlined,
  CloudUploadOutlined,
  RollbackOutlined,
} from '@ant-design/icons';
import CommonListTableWithButton from 'components/v2/common-list-table-with-button';
import {
  DEVICE_LIST_BULK_ACTION_LIST_V2,
  ONLINE_OFFLINE_THRESHOLD,
} from 'constants/devices';
import { debounce } from 'lodash';

const { Option } = Select;

const statusOptions = (
  <>
    <Option key={'active'} value={'active'}>
      {'Active'}
    </Option>
    <Option key={'inactive'} value={'inactive'}>
      {'Inactive'}
    </Option>
    {/* <Option key={'deleted'} value={'deleted'}>
      {'Deleted'}
    </Option> */}
  </>
);

const DevicesList = () => {
  const breakCrumbList = [
    { label: 'Home', link: '/dashboard' },
    { label: 'Devices' },
  ];
  const ref = React.useRef(null);
  const [isFetching, setIsFetching] = useState(false);
  const [isFetchingCSV, setIsFetchingCSV] = useState(false);
  const [serverSyncTime, setServerSyncTime] = useState('');
  const [showBulkActionsRecords, setShowBulkActionsRecords] = useState(false);
  const [totalDevices, setTotalDevices] = useState(0);
  const [devicesList, setDevicesList] = useState([]);
  const [CSVDownload, setCSVDownload] = useState([]);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [selectedDeviceSerialNumber, setSelectedDeviceSerialNumber] = useState(
    []
  );
  const [selectedDeviceDetails, setSelectedDeviceDetails] = useState([]);
  const [selectedBulkAction, setSelectedBulkAction] = useState('');
  const [orderPagination, setOrderPagination] = useState({
    skip: 0,
    limit: 10,
    order: 1,
    orderBy: 'device_serial',
  });
  const [searchParam, setSearchParam] = useState({
    device_serial: '',
    sim_serial: '',
    taxi_number: '',
    is_deleted:false
    // filter_by: '',
  });

  useEffect(() => {
    fetchEnrollDevices(orderPagination);
  }, [searchParam]);

  const fetchEnrollDevices = (orderPaginationParams) => {
    setIsFetching(true);

    const queryParams = new URLSearchParams();
    queryParams.append('skip', orderPaginationParams.skip);
    queryParams.append('limit', orderPaginationParams.limit);
    queryParams.append('order', orderPaginationParams.order);
    queryParams.append('order_by', orderPaginationParams.orderBy);

    if (searchParam && Object.keys(searchParam).length >= 1) {
      Object.keys(searchParam).map((key) => {
        queryParams.append(key, searchParam[key]);
      });
    }

    const url = `${URLS_V2?.DEVICES?.DEVICES_LIST}?${queryParams.toString()}`;
    httpGet(url)
      .then((res) => {
        handleCSVData(res.docs || []);
        if (res?.status === 200) {
          let enrollDevicesData = [];
          if (res?.data && res?.data.length > 0) {
            enrollDevicesData = res?.data.map((data) => {
              return {
                ...data,
                key: data?._id,
                simSerial: data?.device_update_info?.sim_serial,
                serverSyncedAt: data?.device_update_info?.server_synced_at,
                installed_binary_os_version:
                  data?.device_update_info?.binary_os_app_version || '-',
                installed_device_manager_version:
                  data?.device_update_info?.device_manager_version || '-',
              };
            });
          }
          setDevicesList(enrollDevicesData || []);
          setTotalDevices(res?.total || 0);
          setServerSyncTime(res?.server_synced_at);
        }
        setIsFetching(false);
      })
      .catch((err) => {
        setIsFetching(false);
      });
  };

  const handleCSVData = (devicesData) => {
    let csvData = [];
    devicesData.forEach((data) => {
      const excelStructureData = {
        [ENROLL_LABELS.DEVICE_SERIAL]: data?.device_serial || '',
        [ENROLL_LABELS.MORNING_DRIVER_NAME]: data?.morning_driver_name || '',
        [ENROLL_LABELS.MORNING_DRIVER_MOBILE]:
          data?.morning_driver_mobile || '',
        [ENROLL_LABELS.EVENING_DRIVER_NAME]: data?.evening_driver_name || '',
        [ENROLL_LABELS.EVENING_DRIVER_MOBILE]:
          data?.evening_driver_mobile || '',
        [ENROLL_LABELS.SIM_SERIAL]: data?.sim_serial || '',
        [ENROLL_LABELS.TAXI_NUMBER]: data?.taxi_number || '',
        [ENROLL_LABELS.ACTIVE]: data?.is_device_active ? 'Active' : 'Inactive',
      };
      csvData.push(excelStructureData);
    });
    setCSVDownload(csvData);
  };

  const columns = [
    {
      title: ENROLL_LABELS.SR_NO,
      width: 80,
      render: (value, item, index) =>
        (currentPage(orderPagination.skip, orderPagination.limit) - 1) *
        orderPagination.limit +
        index +
        1,
    },
    {
      key: 'device_serial',
      title: ENROLL_LABELS.DEVICE_SERIAL,
      dataIndex: 'device_serial',
      sorter: true,
      defaultSortOrder: 'ascend',
      render: (device_serial, item) => {
        return (function Actions() {
          return (
            <div>
              {item?.updatedAt &&
                calcMinsFromCurrentDateTime(item?.updatedAt) <=
                ONLINE_OFFLINE_THRESHOLD ? (
                <Badge status="success" title="Online" />
              ) : (
                <Badge status="error" title="Offline" />
              )}
              {device_serial}
            </div>
          );
        })();
      },
    },
    {
      key: 'sim_serial',
      title: ENROLL_LABELS.SIM_SERIAL,
      dataIndex: 'sim_serial',
      sorter: true,
    },
    {
      key: 'taxi_number',
      title: ENROLL_LABELS.TAXI_NUMBER,
      dataIndex: 'taxi_number',
      sorter: true,
    },
    {
      key: 'device_updated_at',
      title: ENROLL_LABELS.UPDATED_AT,
      dataIndex: 'device_updated_at',
      sorter: true,
      render: (date) => {
        return (function Actions() {
          return <>{(date && formatDate(date)) || '-'}</>;
        })();
      },
    },
    {
      title: ENROLL_LABELS.APP_VERSION,
      children: [
        {
          key: 'binary_os_app_version',
          title: ENROLL_LABELS.BINARY_OS_APP_VERSION,
          dataIndex: 'installed_binary_os_version',
          sorter: true,
        },
        {
          key: 'device_manager_version',
          title: ENROLL_LABELS.DEVICE_MANAGER_APP_VERSION,
          dataIndex: 'installed_device_manager_version',
          sorter: true,
        },
      ],
    },
    {
      key: 'is_device_active',
      title: ENROLL_LABELS.ACTIVE,
      dataIndex: 'is_device_active',
      render: (is_active, item) => {
        return (function Actions() {
          return (
            <div>
              <Switch
                checked={is_active}
                onChange={(checked) =>
                  handleDeviceStatus(checked, item.device_serial)
                }
              />
            </div>
          );
        })();
      },
    },
    {
      key: 'device_serial',
      title: ENROLL_LABELS.ACTIONS,
      dataIndex: 'device_serial',
      width: 150,
      render: (device_serial, item) => {
        return (function Actions() {
          return (
            <div className="actions-btn">
              <Link to={`/v2/devices/${device_serial}`}>
                <Button
                  shape="circle"
                  className="edit-btn"
                  style={{ marginLeft: '0.5rem' }}
                >
                  <EyeOutlined type="primary" className="preview-icon" />
                </Button>
              </Link>

              <Popconfirm
                title={<>{`Are you sure you want to delete this device?`}</>}
                onConfirm={() => deleteItem(device_serial, !item.is_deleted)}
              >
                <Button
                  shape="circle"
                  className="delete-btn"
                  style={{ marginLeft: '0.5rem' }}
                >
                  {item.is_deleted ? (
                    <RollbackOutlined type="primary" className="delete-icon" />
                  ) : (
                    <DeleteOutlined type="primary" className="delete-icon" />
                  )}
                </Button>
              </Popconfirm>
            </div>
          );
        })();
      },
    },
  ];

  const bulkTableColumns = [
    {
      key: 'device_serial',
      title: ENROLL_LABELS.DEVICE_SERIAL,
      dataIndex: 'device_serial',
    },
    {
      key: 'is_device_active',
      title: ENROLL_LABELS.ACTIVE,
      dataIndex: 'is_device_active',
      render: (is_active) => {
        return (function Actions() {
          return <div>{is_active ? <p>Active</p> : <p>Inactive</p>}</div>;
        })();
      },
    },
    {
      key: '_id',
      title: ENROLL_LABELS.ACTIONS,
      dataIndex: '_id',
      render: (_id) => {
        return (function Actions() {
          return (
            <div className="actions-btn" key={_id}>
              <Button
                danger
                shape="circle"
                className="close-btn"
                onClick={() => removeDeviceFromSelection(_id)}
              >
                <CloseOutlined
                  type="primary"
                  className="close-icon"
                  twoToneColor="#ff4d4f"
                />
              </Button>
            </div>
          );
        })();
      },
    },
  ];

  const removeDeviceFromSelection = (selectedId) => {
    const newSelectedRowKeys = selectedRowKeys.filter(
      (deviceId) => deviceId !== selectedId
    );
    const newSelectedDeviceDetails = selectedDeviceDetails.filter(
      (device) => device._id !== selectedId
    );
    const newSelectedDeviceSerialNumbers = newSelectedDeviceDetails.map(
      (item) => item.device_serial
    );
    setSelectedRowKeys(newSelectedRowKeys);
    setSelectedDeviceDetails(newSelectedDeviceDetails);
    setSelectedDeviceSerialNumber(newSelectedDeviceSerialNumbers);
    if (newSelectedRowKeys.length <= 0) {
      setShowBulkActionsRecords(false);
    }
  };

  const handleBulkActionRequest = (
    deviceSerials = selectedDeviceSerialNumber,
    status = selectedBulkAction
  ) => {
    setIsFetching(true);
    const url = URLS_V2?.DEVICES?.BULK_ACTION_STATUS_CHANGE;
    const data = {
      devices: deviceSerials,
      status: status,
    };
    httpPut(url, data)
      .then((res) => {
        if (res.status === 200) {
          showSuccessMsg('Device status updated successfully!');
          fetchEnrollDevices(orderPagination);
          // selectedDeviceSerialNumber.map((deviceSerial) =>
          //   updateFirebaseCollectionTimestamp(
          //     DEVICE_V2_TIMESTAMP_FB_COLLECTION_NAME.DEVICE_DETAILS,
          //     deviceSerial
          //   )
          // );
        } else if (res?.error) {
          showErrorMsg(res?.error);
        }
        setIsFetching(false);
        setSelectedRowKeys([]);
        setSelectedDeviceDetails([]);
        setSelectedDeviceSerialNumber([]);
      })
      .catch((err) => {
        showErrorMsg(err);
        setIsFetching(false);
      });
  };

  const handleDeviceStatus = (checked, device_serial) => {
    handleBulkActionRequest(device_serial, checked ? true : false);
  };

  const handelOnCancelBulkRequest = () => {
    setShowBulkActionsRecords(false);
    setSelectedRowKeys([]);
    setSelectedDeviceDetails([]);
    setSelectedDeviceSerialNumber([]);
  };

  const hasSelected = selectedRowKeys.length > 0;

  const onSelectedRowKeysChange = (selectedRowKey, selectedRowItem) => {
    setSelectedRowKeys(selectedRowKey);
    setSelectedDeviceDetails(selectedRowItem);
    if (selectedRowItem && selectedRowItem.length >= 1) {
      const getSelectedDeviceSerialNumbers = selectedRowItem.map(
        (item) => item.device_serial
      );
      setSelectedDeviceSerialNumber(getSelectedDeviceSerialNumbers);
    }
  };

  const handleBulkActionMenuClick = (selectedBulkItem) => {
    setSelectedBulkAction(selectedBulkItem);
    setShowBulkActionsRecords(true);
  };

  const handleBulkIssueCommandToDevice = () => {
    setShowBulkActionsRecords(false);
    handleBulkActionRequest();
  };

  const menu = (
    <Menu>
      {DEVICE_LIST_BULK_ACTION_LIST_V2 &&
        DEVICE_LIST_BULK_ACTION_LIST_V2.length > 0 &&
        DEVICE_LIST_BULK_ACTION_LIST_V2.map((item) => (
          <Menu.Item
            key={item.value}
            icon={React.createElement(item.icon)}
            onClick={() => handleBulkActionMenuClick(item.command)}
          >
            {item.label}
          </Menu.Item>
        ))}
    </Menu>
  );

  const deleteItem = (selectedDeviceSerial, value) => {
    setIsFetching(true);
    const data = {
      device_serial: selectedDeviceSerial,
      is_deleted: value,
    };
    updateDeviceDetails(data);
  };

  const onChangeActiveInactive = (selectedDeviceSerial, value) => {
    setIsFetching(true);
    handleBulkActionRequest(
      selectedDeviceSerial,
      value === 'active' ? true : false
    );
  };

  const updateDeviceDetails = (data) => {
    const url = URLS_V2?.DEVICES?.UPDATE_DEVICE;
    httpPut(url, data)
      .then((res) => {
        if (res?.status === 200) {
          showSuccessMsg('Device status updated successfully!');
          fetchEnrollDevices(orderPagination);
        } else {
          showErrorMsg(
            'System is unable to complete request. Please try again later.'
          );
        }
        setIsFetching(false);
      })
      .catch((err) => {
        setIsFetching(false);
      });
  };

  const returnExpandableRow = (record) => {
    return (
      <Row gutter={[12, 24]}>
        <Col className="gutter-row" span={12}>
          <strong>{'Morning Driver Name: '}</strong>
          {record?.morning_driver_name || '-'}
        </Col>
        <Col className="gutter-row" span={12}>
          <strong>{'Morning Driver Number: '}</strong>
          {record?.morning_driver_mobile || '-'}
        </Col>
        <Col className="gutter-row" span={12}>
          <strong>{'Evening Driver Name: '}</strong>
          {record?.evening_driver_name || '-'}
        </Col>
        <Col className="gutter-row" span={12}>
          <strong>{'Evening Driver Number: '}</strong>
          {record?.evening_driver_mobile || '-'}
        </Col>
        <Col className="gutter-row" span={12}>
          <strong>{'Status: '}</strong>
          <Popconfirm
            title={
              <>{`Are you sure you want to update device status to ${record.is_device_active ? 'Inactive' : 'Active'
                }?`}</>
            }
            onConfirm={() =>
              onChangeActiveInactive(
                record.device_serial,
                record.is_device_active ? 'inactive' : 'active'
              )
            }
          >
            <Switch
              checked={record.is_device_active}
              className={`${record.is_device_active ? 'bg-success' : 'bg-danger'
                }`}
              checkedChildren="Active"
              unCheckedChildren="Inactive"
            />
          </Popconfirm>
        </Col>
      </Row>
    );
  };

  const handleTextSearch = (e) => {
    setSearchParam({
      ...searchParam,
      [e.target.name]: e.target.value,
    });
  };

  const handleSelectSearch = (selectedValue) => {
    // let tempFilterParams = '';

    // if (
    //   selectedValue.includes('active') &&
    //   selectedValue.includes('inactive') &&
    //   selectedValue.includes('deleted')
    // ) {
    //   tempFilterParams = 'is_device_active:all,is_deleted:true';
    // } else if (
    //   selectedValue.includes('active') &&
    //   selectedValue.includes('inactive')
    // ) {
    //   tempFilterParams = 'is_device_active:all';
    // } else if (
    //   selectedValue.includes('active') &&
    //   selectedValue.includes('deleted')
    // ) {
    //   tempFilterParams = 'is_device_active:true,is_deleted:true';
    // } else if (
    //   selectedValue.includes('inactive') &&
    //   selectedValue.includes('deleted')
    // ) {
    //   tempFilterParams = 'is_device_active:false,is_deleted:true';
    // } else if (selectedValue.includes('active')) {
    //   tempFilterParams = 'is_device_active:true';
    // } else if (selectedValue.includes('inactive')) {
    //   tempFilterParams = 'is_device_active:false';
    // } else if (selectedValue.includes('deleted')) {
    //   tempFilterParams = 'is_deleted:true';
    // }
    if (selectedValue) {
      setSearchParam({
        ...searchParam,
        is_device_active: selectedValue === "active" ? true : false
      });
    } else {
      const obj = structuredClone(searchParam);
      delete obj.is_device_active
      setSearchParam({
        ...obj,
      });
    }
  };

  const handleChangeDelete=(e)=>{
    setSearchParam({
      ...searchParam,
      [e.target.name]:e?.target?.checked,
    });
  };

  const handlePaginationChange = (pagination, filters, sorter) => {
    const { current, pageSize } = pagination;
    const tempSearchPagination = {
      ...orderPagination,
      skip: current * pageSize - pageSize,
      limit: pageSize,
      order: sorter.order === 'descend' ? -1 : 1,
      orderBy: sorter.field,
    };
    setOrderPagination(tempSearchPagination);
    fetchEnrollDevices(tempSearchPagination);
  };

  const handleExport = async () => {
    handleCSVData([]);
    setIsFetchingCSV(true);
    const url = `${URLS_V2?.DEVICES?.DEVICES_LIST}`;
    httpGet(url)
      .then((res) => {
        if (res?.status === 200) {
          handleCSVData(res?.data || []);
          ref.current.click();
          showSuccessMsg('Data exported successfully!');
        } else {
          showErrorMsg(
            'System is unable to complete request. Please try again later.'
          );
        }
        setIsFetchingCSV(false);
      })
      .catch((err) => {
        showErrorMsg(
          'System is unable to complete request. Please try again later.'
        );
        setIsFetchingCSV(false);
      });
  };

  const defaultTitle = () => {
    return (
      <>
        <div className="cus-table-left-right-btn">
          <div>
            {serverSyncTime &&
              `Records Last Sync At: ${formatDate(serverSyncTime)} `}
          </div>
          <div>
            {`Showing Records: ${devicesList.length} / Total Records: ${totalDevices}`}
          </div>
        </div>
      </>
    );
  };

  return (
    <>
      <CommonBreadCrumb breadCrumbs={breakCrumbList} />
      <div className="while-bg-box cus-table-header">
        <h3 className="table-title">Devices</h3>
        <div className="cus-table-right-btn">
          <div className="export-generate-btn">
            <Form.Item>
              <Link to={`/v2/import-devices`}>
                <Button type="primary" icon={<CloudUploadOutlined />}>
                  {'Import Devices'}
                </Button>
              </Link>
            </Form.Item>
          </div>
          <div className="export-generate-btn m-l-15">
            <Form.Item>
              <CSVLink
                data={CSVDownload}
                headers={headers}
                filename={'device-list.csv'}
              >
                <span ref={ref}></span>
              </CSVLink>
              <Button
                type="primary"
                onClick={handleExport}
                loading={isFetchingCSV}
                icon={<DownloadOutlined />}
              >
                {isFetchingCSV ? 'Exporting Data' : 'Export'}
              </Button>
            </Form.Item>
          </div>
          <div className="bulk-action-btn m-l-15">
            <Dropdown
              overlay={menu}
              disabled={!hasSelected}
              trigger={['click']}
            >
              <Button>
                {'Bulk Actions'} <DownOutlined />
              </Button>
            </Dropdown>
          </div>
        </div>
      </div>
      <div className="while-bg-box">
        <Form className="main-filter-form">
          <Row gutter={[16, 16]}>
            <Col md={6}>
              <Form.Item name="device_serial" style={{ marginBottom: '0' }}>
                <Input
                  type="text"
                  name="device_serial"
                  onChange={debounce((event) => handleTextSearch(event))}
                  placeholder="Search Device Serial"
                />
              </Form.Item>
            </Col>
            <Col md={6}>
              <Form.Item name="sim_serial" style={{ marginBottom: '0' }}>
                <Input
                  type="text"
                  name="sim_serial"
                  onChange={debounce((event) => handleTextSearch(event))}
                  placeholder="Search Sim Serial"
                />
              </Form.Item>
            </Col>
            <Col md={6} align="end" justify="end">
              <Form.Item name="taxi_number" style={{ marginBottom: '0' }}>
                <Input
                  type="text"
                  name="taxi_number"
                  onChange={debounce((event) => handleTextSearch(event))}
                  placeholder="Search Taxi Number"
                />
              </Form.Item>
            </Col>
            <Col md={6}>
              <Form.Item name="device_status" style={{ marginBottom: '0' }}>
                <Select
                  showSearch
                  allowClear
                  // mode="multiple"
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    option.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                  placeholder="Select Device Status"
                  onChange={handleSelectSearch}
                >
                  {statusOptions}
                </Select>
              </Form.Item>
            </Col>
            <Col md={6}>
              <Form.Item style={{ marginBottom: '0' }}>
                <Checkbox onChange={handleChangeDelete} name="is_deleted" >Show Deleted</Checkbox>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </div>
      {selectedDeviceDetails.length >= 1 && showBulkActionsRecords && (
        <CommonListTableWithButton
          tableTitle={'Confirm Device status change to ' + selectedBulkAction}
          columns={bulkTableColumns}
          dataSource={selectedDeviceDetails}
          submitCallBack={handleBulkIssueCommandToDevice}
          cancelCallBack={() => handelOnCancelBulkRequest()}
        />
      )}
      <div className="main-table-body-cus">
        <Table
          title={defaultTitle}
          loading={{
            indicator: <div className="table-loader">Fetching...</div>,
            spinning: isFetching,
          }}
          rowSelection={{
            selectedRowKeys,
            preserveSelectedRowKeys: true,
            onChange: onSelectedRowKeysChange,
          }}
          expandable={{
            expandedRowRender: (record) => returnExpandableRow(record),
          }}
          columns={columns}
          dataSource={devicesList || []}
          size="small"
          onChange={handlePaginationChange}
          pagination={{
            defaultPageSize: 10,
            total: totalDevices,
          }}
          scroll={{ x: 400 }}
        />
      </div>
    </>
  );
};

export default DevicesList;

const ENROLL_LABELS = {
  SR_NO: 'Sr. No.',
  DEVICE_SERIAL: 'Device Serial',
  MORNING_DRIVER_NAME: 'Morning Driver Name',
  MORNING_DRIVER_MOBILE: 'Morning Driver Number',
  EVENING_DRIVER_NAME: 'Evening Driver Name',
  EVENING_DRIVER_MOBILE: 'Evening Driver Number',
  SIM_SERIAL: 'Sim Serial',
  TAXI_NUMBER: 'Taxi Number',
  UPDATED_AT: 'Updated At',
  APP_VERSION: 'Installed App Versions',
  BINARY_OS_APP_VERSION: 'Binary OS',
  DEVICE_MANAGER_APP_VERSION: 'Device Manager',
  LAST_SYNC_AT: 'Sync At',
  ACTIVE: 'Status',
  ACTIONS: 'Actions',
};

const headers = [
  { label: ENROLL_LABELS.DEVICE_SERIAL, key: ENROLL_LABELS.DEVICE_SERIAL },
  {
    label: ENROLL_LABELS.MORNING_DRIVER_NAME,
    key: ENROLL_LABELS.MORNING_DRIVER_NAME,
  },
  {
    label: ENROLL_LABELS.MORNING_DRIVER_MOBILE,
    key: ENROLL_LABELS.MORNING_DRIVER_MOBILE,
  },
  {
    label: ENROLL_LABELS.EVENING_DRIVER_NAME,
    key: ENROLL_LABELS.EVENING_DRIVER_NAME,
  },
  {
    label: ENROLL_LABELS.EVENING_DRIVER_MOBILE,
    key: ENROLL_LABELS.EVENING_DRIVER_MOBILE,
  },
  { label: ENROLL_LABELS.SIM_SERIAL, key: ENROLL_LABELS.SIM_SERIAL },
  { label: ENROLL_LABELS.TAXI_NUMBER, key: ENROLL_LABELS.TAXI_NUMBER },
  { label: ENROLL_LABELS.ACTIVE, key: ENROLL_LABELS.ACTIVE },
];
