import styled from 'styled-components';

export const DeviceDetailsPageBox = styled.div`
  .table-header {
    margin: 10px;
    background: white;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 15px;
    border-radius: 4px;
    overflow: hidden;
    h3 {
      margin-bottom: 0px;
    }
    .table-right-side {
      display: flex;
      .export-generate-btn {
        .ant-form-item {
          margin-bottom: 0px;
        }
      }
    }
  }
  .device-details-header {
    margin: 10px;
    background: white;
    display: flex;
    align-items: center;
    padding: 15px;
    border-radius: 4px;
    overflow: hidden;
    justify-content: space-evenly;
    text-align: center;
  }

  .vr-solid {
    border-left: 3px solid #bbb;
    height: 50px;
  }

  .main-table-body {
    padding: 0px 10px;
  }
  .compliance-details {
    display: flex;
    flex-flow: column;
  }
  .c-m-10 {
    margin: 10px;
  }
`;

export const Loader = styled.div`
  color: grey;
  font-size: 14px;
`;
