import styled from 'styled-components';

export const DeviceStatusListBox = styled.div`
  .table-header {
    margin: 10px;
    background: white;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 15px;
    border-radius: 4px;
    overflow: hidden;
    h3 {
      margin-bottom: 0px;
    }
    .table-right-side {
      display: flex;
      .export-generate-btn {
        .ant-form-item {
          margin-bottom: 0px;
        }
      }
      .bulk-action-btn {
        margin-left: 1rem;
      }
    }
  }

  .battery-low {
    background-color: #ffccc7;
  }

  .main-table-body {
    padding: 0px 10px;
  }

  .filter-form {
    margin: 10px;
    background: white;
    padding: 15px;
    .main-filter-form {
      display: flex;
      width: 100%;
      justify-content: space-between;
      .left-form {
        display: flex;
        width: 100%;
        justify-content: space-between;
        align-items: center;
      }
      .device-input {
        display: flex;
      }
    }
  }

  .file-export-loading {
    display: flex;
    align-items: center;
    div {
      font-weight: 500;
      color: #1890ff;
      margin-left: 10px;
    }
  }

  .while-bg-box {
    margin: 10px;
    background: white;
    padding: 15px;
  }

  li.ant-pagination-item {
    display: none;
  }

  li.ant-pagination-item-active {
    display: inline-block;
  }

  li.ant-pagination-jump-prev.ant-pagination-jump-prev-custom-icon {
    display: none;
  }

  li.ant-pagination-jump-next.ant-pagination-jump-next-custom-icon {
    display: none;
  }
`;

export const Loader = styled.div`
  color: grey;
  font-size: 14px;
`;
