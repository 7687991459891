import React, { useState, useEffect } from 'react';
import { Button, Table } from 'antd';
import CommonBreadCrumb from 'components/common-breadcrumb';
import { currentPage, httpGet, URLS_V2 } from 'utils';
import { EnvironmentOutlined } from '@ant-design/icons';

const AdDeliveryReportList = () => {
  const breakCrumbList = [
    { label: 'Home', link: '/dashboard' },
    { label: 'Ad Delivery Report' },
  ];
  const [adDeliveryList, setAdDeliveryList] = useState([]);
  const [totalRecords, setTotalRecords] = useState(0);
  const [isFetching, setIsFetching] = useState(false);
  const [orderPagination, setOrderPagination] = useState({
    skip: 0,
    limit: 100,
    order: 1,
    orderBy: 'title',
  });
  const [searchParam] = useState({
    title: '',
    role_type: '',
  });

  useEffect(() => {
    fetchAdDeliveryReportList();
  }, [searchParam]);

  const fetchAdDeliveryReportList = (
    orderPaginationParams = orderPagination
  ) => {
    setIsFetching(true);

    const url = `${URLS_V2?.CAMPAIGNS?.AD_DELIVERY_REPORT}`;
    httpGet(url)
      .then((res) => {
        if (res?.status === 200) {
          let tempListData = [];
          if (res?.data && res?.data.length > 0) {
            tempListData = res?.data.map((data) => {
              return {
                ...data,
                key: data?._id,
              };
            });
          }
          setAdDeliveryList(tempListData || []);
          setTotalRecords(res?.count || 0);
        }
        setIsFetching(false);
      })
      .catch((err) => {
        setIsFetching(false);
      });
  };

  const columns = [
    {
      title: DATA_TABLE_LABELS.SR_NO,
      width: 80,
      render: (value, item, index) =>
        (currentPage(orderPagination.skip, orderPagination.limit) - 1) *
          orderPagination.limit +
        index +
        1,
    },
    {
      key: 'device_serial',
      title: DATA_TABLE_LABELS.DEVICE_SERIAL,
      dataIndex: 'device_serial',
      // sorter: true,
      defaultSortOrder: 'ascend',
    },
    {
      key: 'campaign_name',
      title: DATA_TABLE_LABELS.CAMPAIGN_NAME,
      dataIndex: 'campaign_name',
      // sorter: true,
    },
    {
      key: 'campaign_type',
      title: DATA_TABLE_LABELS.CAMPAIGN_TYPE,
      dataIndex: 'campaign_type',
      // sorter: true,
    },
    {
      key: 'campaign_class',
      title: DATA_TABLE_LABELS.CAMPAIGN_CLASS,
      dataIndex: 'campaign_class',
      // sorter: true,
    },
    {
      key: 'location',
      title: DATA_TABLE_LABELS.AD_LOCATION,
      dataIndex: 'location',
      render: (location) => {
        return (function Actions() {
          return (
            <div>
              {location?.coordinates?.[0] && location?.coordinates?.[1] ? (
                <a
                  href={`https://maps.google.com/?q=${location?.coordinates?.[1]},${location?.coordinates?.[0]}`}
                  rel="noreferrer"
                  target="_blank"
                >
                  <Button
                    shape="circle"
                    className="edit-btn"
                    style={{ marginLeft: '0.5rem' }}
                  >
                    <EnvironmentOutlined
                      type="primary"
                      className="preview-icon"
                    />
                  </Button>
                </a>
              ) : (
                'No Location Found'
              )}
            </div>
          );
        })();
      },
    },
  ];

  const handlePaginationChange = (pagination, filters, sorter) => {
    const { current, pageSize } = pagination;
    const tempSearchPagination = {
      ...orderPagination,
      skip: current * pageSize - pageSize,
      limit: pageSize,
      order: sorter.order === 'descend' ? -1 : 1,
      orderBy: sorter.field,
    };
    setOrderPagination(tempSearchPagination);
    fetchAdDeliveryReportList(tempSearchPagination);
  };

  const defaultTitle = () => {
    return (
      <div className="cus-table-right-btn">
        {`Showing Records: ${adDeliveryList.length} / Total Records: ${totalRecords}`}
      </div>
    );
  };

  return (
    <>
      <CommonBreadCrumb breadCrumbs={breakCrumbList} />
      <div className="while-bg-box cus-table-header">
        <h3 className="table-title">Ad Delivery Report</h3>
      </div>
      <div className="main-table-body-cus">
        <Table
          title={defaultTitle}
          loading={{
            indicator: <div className="table-loader">Fetching...</div>,
            spinning: isFetching,
          }}
          columns={columns}
          dataSource={adDeliveryList || []}
          size="small"
          onChange={handlePaginationChange}
          pagination={{
            defaultPageSize: 100,
            total: totalRecords,
          }}
        />
      </div>
    </>
  );
};

export default AdDeliveryReportList;

const DATA_TABLE_LABELS = {
  SR_NO: 'Sr. No.',
  DEVICE_SERIAL: 'Device Serial',
  CAMPAIGN_NAME: 'Campaign Name',
  CAMPAIGN_TYPE: 'Campaign Type',
  CAMPAIGN_CLASS: 'Campaign Class',
  AD_LOCATION: 'Location',
  // ACTIONS: 'Actions',
};
