import React from 'react';
import { string, object, func } from 'prop-types';
import { Modal } from 'antd';
import ReactPlayer from 'react-player';
import {
  PreviewImageBox,
} from './_preview.styled';
import { MEDIA_TYPES } from 'constants/campaign';
import './_modal.css';

const Preview = (props) => {
  const {
    modalViewType,
    handleOk,
    handleCancel,
    selectMediaImageObject,
    selectMediaVideoObject,
    selectMediaImage,
    selectMediaVideo
  } = props;

  return (
    <PreviewImageBox>
      <Modal
        title="Media Preview"
        visible={Boolean(modalViewType)}
        onOk={handleOk}
        onCancel={handleCancel}
        // width="100%"
        footer={false}
      >
        {modalViewType === MEDIA_TYPES.VIDEO &&
        
        selectMediaVideo && (
            <div className="video">
              <ReactPlayer
                url={selectMediaVideo}
                width="100%"
                height="100%"
                playing
                controls
              />
            </div>
          )}
        {modalViewType === MEDIA_TYPES.IMAGE && selectMediaImage && (
          <div className="lead-image" style={{ textAlign: 'center' }}>
            <img src={selectMediaImage} alt="img" style={{ width: '100%' }} />
          </div>
        )}
      </Modal>
    </PreviewImageBox>
  );
};

Preview.defaultProps = {
  handleCancel: () => {},
  handleOk: () => {},
  modalViewType: '',
  selectMediaImageObject: {},
  selectMediaVideoObject: {},
};

Preview.propTypes = {
  handleCancel: func,
  handleOk: func,
  modalViewType: string,
  selectMediaImageObject: object,
  selectMediaVideoObject: object,
};

export default Preview;
