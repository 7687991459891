import React, { useState, useEffect } from 'react';
import { Button, Breadcrumb, Table, Popconfirm, Spin } from 'antd';
import {
  httpDelete,
  httpGet,
  URLS_V2,
  showErrorMsg,
  showSuccessMsg,
} from 'utils';
import { GameBox, Loader } from './_game.styled';
import {
  EditOutlined,
  DeleteOutlined,
  LoadingOutlined,
} from '@ant-design/icons';
import moment from 'moment';
import GameModal from 'components/v2/game-modal';
import { MODE_TYPES } from 'constants/common';

const GamesList = () => {
  const [isFetchingGameList, setIsFetchingGameList] = useState(false);
  const [deletingGameId, setDeletingGameId] = useState('');
  const [gameList, setGameList] = useState([]);
  const [activeMode, setActiveMode] = useState();
  const [gameId, setGameId] = useState();
  const [totalGames, setTotalGames] = useState(0);
  const [orderPagination, setOrderPagination] = useState({
    skip: 0,
    limit: 10,
  });

  useEffect(() => {
    fetchGameList(orderPagination);
  }, []);

  const fetchGameList = (orderPaginationParams) => {
    setIsFetchingGameList(true);
    const queryParams = new URLSearchParams();
    queryParams.append('skip', orderPaginationParams.skip);
    queryParams.append('limit', orderPaginationParams.limit);
    const url = `${URLS_V2?.GAME.GAMES_LIST}?${queryParams.toString()}`;
    httpGet(url)
      .then((res) => {
        if (res?.status === 200) {
          if (res?.data && res?.data.length > 0) {
            const gameListData = res?.data.map((data) => {
              return {
                ...data,
                key: data?._id,
              };
            });
            setGameList(gameListData || []);
            setTotalGames(res?.total || 0);
          }
        }
        setIsFetchingGameList(false);
      })
      .catch(() => {
        setIsFetchingGameList(false);
      });
  };

  const handlePaginationChange = (pagination, filters, sorter) => {
    const { current, pageSize } = pagination;
    const tempSearchPagination = {
      ...orderPagination,
      skip: current * pageSize - pageSize,
      limit: pageSize,
    };
    setOrderPagination(tempSearchPagination);
    fetchGameList(tempSearchPagination);
  };

  const handleModeType = ({ activeMode, gameId }) => {
    setGameId(gameId || '');
    setActiveMode(activeMode);
  };

  const closeActiveModeModal = () => {
    setActiveMode('');
  };

  const handleGameCallback = () => {
    fetchGameList(orderPagination);
    setGameId('');
  };

  const deleteGame = ({ id }) => {
    setDeletingGameId(id);
    const url = URLS_V2?.GAME.DELETE_GAME.replace('#ID#', id);
    httpDelete(url)
      .then((res) => {
        if (res.status === 200) {
          // updateFirebaseGlobalAppSettings(GLOBAL_APP_SETTINGS_KEYS.game);
          showSuccessMsg('Game deleted successfully!');
          fetchGameList(orderPagination);
        } else if (res?.error) {
          showErrorMsg(res?.error);
        }
        setDeletingGameId('');
      })
      .catch((err) => {
        showErrorMsg(err);
        setDeletingGameId('');
      });
  };

  const columns = [
    {
      title: GAME_LABELS.SR_NO,
      width: '4%',
      render: (value, item, index) => index + 1,
    },
    {
      key: 'art_work',
      title: GAME_LABELS.ART_WORK,
      dataIndex: 'art_work',
      width: '10%',

      render: (art_work) => {
        return (function Actions() {
          return (
            <div className="logo">
              <img src={art_work} alt="art_work" width="50px" />
            </div>
          );
        })();
      },
    },
    {
      key: 'name',
      title: GAME_LABELS.NAME,
      dataIndex: 'name',
      width: '15%',
    },
    {
      key: 'link',
      title: GAME_LABELS.LINK,
      dataIndex: 'link',
      width: '15%',
    },
    {
      key: 'created_at',
      title: GAME_LABELS.CREATED_AT,
      dataIndex: 'created_at',
      width: '15%',
      render: (created_at) => {
        return (function Actions() {
          return (
            <div className="created_at">
              {created_at && moment(created_at).format('Do MMMM YYYY')}
            </div>
          );
        })();
      },
    },
    {
      key: '_id',
      title: GAME_LABELS.ACTIONS,
      dataIndex: '_id',
      width: '10%',
      align: 'center',
      render: (id) => {
        return (function Actions() {
          return (
            <div className="actions-btn">
              <Button
                shape="circle"
                className="edit-btn"
                onClick={() => {
                  handleModeType({
                    activeMode: MODE_TYPES.EDIT,
                    gameId: id,
                  });
                }}
              >
                <EditOutlined type="primary" className="edit-icon" />
              </Button>
              <Popconfirm
                title="Sure to delete?"
                onConfirm={() => deleteGame({ id })}
                disabled={deletingGameId === id}
              >
                <Button
                  shape="circle"
                  className="delete-btn"
                  disabled={deletingGameId === id}
                >
                  {deletingGameId === id ? (
                    <Spin indicator={<LoadingOutlined spin />} />
                  ) : (
                    <DeleteOutlined type="primary" className="delete-icon" />
                  )}
                </Button>
              </Popconfirm>
            </div>
          );
        })();
      },
    },
  ];

  return (
    <GameBox>
      <div className="header">
        <Breadcrumb>
          <Breadcrumb.Item>Content </Breadcrumb.Item>
          <Breadcrumb.Item>Games </Breadcrumb.Item>
        </Breadcrumb>
        <div className="claim-table-header">
          <h3 className="claim-table-title"> Games </h3>
          <Button
            type="primary"
            onClick={() => {
              handleModeType({ activeMode: MODE_TYPES.ADD });
            }}
          >
            Add New
          </Button>
        </div>
      </div>
      <div className="main-table-body">
        <Table
          loading={{
            indicator: <Loader>Fetching...</Loader>,
            spinning: isFetchingGameList,
          }}
          columns={columns}
          dataSource={gameList || []}
          size="small"
          onChange={handlePaginationChange}
          pagination={{
            defaultPageSize: 10,
            total: totalGames,
          }}
          scroll={{x:900}}
        />
      </div>
      <GameModal
        activeMode={activeMode}
        closeModal={closeActiveModeModal}
        callback={handleGameCallback}
        gameId={gameId}
      />
    </GameBox>
  );
};

export default GamesList;

const GAME_LABELS = {
  SR_NO: 'Sr. No.',
  ART_WORK: 'Art Work',
  NAME: 'Name',
  LINK: 'Link',
  CREATED_AT: 'Created At',
  ACTIONS: 'Actions',
};
