import React, { useState } from 'react';
import { Modal, Button, Form, Row, Col, Select } from 'antd';
import { string, func, bool, array } from 'prop-types';
const { Option } = Select;
function UserEditModal(props) {
  const {
    userModalVisible,
    closeUserModal,
    docId,
    modalTitle,
    campaignsList,
    isFetching,
    callback,
  } = props;
  const [campaign, setCampaign] = useState();

  let modalfooterButtons = [
    <Button
      key="save"
      type="primary"
      onClick={() => callback({ campaigns: campaign, id: docId })}
    >
      Save
    </Button>,
    <Button key="cancel" onClick={closeUserModal}>
      Cancel
    </Button>,
  ];
  const handleCampaign = (values) => {
    setCampaign(values);
  };
  return (
    <div>
      <Modal
        title={modalTitle}
        visible={userModalVisible}
        onCancel={() => closeUserModal()}
        className="user-modal"
        maskClosable={false}
        footer={modalfooterButtons}
      >
        <Form layout="vertical">
          <Row gutter={16}>
            <Col className="gutter-row" xs={24} sm={12}>
              <Form.Item
                name="campaign"
                label="Campaign"
                rules={[
                  {
                    required: true,
                    message: 'Please enter campaign!',
                  },
                ]}
              >
                <Select
                  mode="multiple"
                  allowClear
                  placeholder={isFetching ? 'Fetching...' : 'Select Campaign'}
                  showSearch
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    option.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                  onChange={handleCampaign}
                >
                  {campaignsList &&
                    campaignsList.length > 0 &&
                    campaignsList.map((campaign, index) => {
                      const set = new Set(campaign.accessible_to);
                      if (!set.has(docId)) {
                        return (
                          <Option value={campaign?.key} key={index}>
                            {campaign?.campaign_name || ''}
                          </Option>
                        );
                      }
                    })}
                </Select>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Modal>
    </div>
  );
}

export default UserEditModal;
UserEditModal.propTypes = {
  callback: func,
  campaignsList: array,
  closeUserModal: func,
  docId: string,
  isFetching: bool,
  modalTitle: string,
  userModalVisible: bool,
};
