import React from 'react';
import { Statistic } from 'antd';
import { string, object, number } from 'prop-types';

const StatisticCardBlock = (props) => {
  const { title, value, precision, valueStyle} = props;

  let fieldProps = { valueStyle };

  if (precision >= 0) {
    fieldProps.precision = precision;
  }


  return (
    <Statistic
      title={title}
      titleFontSize={4}
      valueStyle={valueStyle}
      value={value || '-'}
      {...fieldProps}
      className="custom-statistic"
    />
  );
};

StatisticCardBlock.propTypes = {
  precision: number,
  title: string,
  value: string,
  valueStyle: object,
};

export default StatisticCardBlock;
