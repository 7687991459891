import React, { useState, useEffect } from 'react';
import { string, func } from 'prop-types';
import { Input, Modal, Select, Form, Row, Col, Button } from 'antd';
import { MODE_TYPES, API_V2_ERROR_CODES } from 'constants/common';
import {
  httpPost,
  httpPut,
  httpGet,
  URLS_V2,
  showErrorMsg,
  showSuccessMsg,
} from 'utils';
import {
  WatchContentModalBox,
  CenteredSpin,
} from './_watch-content-modal.styled';
import Media from './media';
import { WATCH_CONTENT_LANGUAGE_LIST } from 'constants/watch-content';

const { Option } = Select;

const moduleName = 'Content'; // main module name used to set in modal title, button name or success/error messages etc...

const mediaInitialStructure = {
  name: '',
  size: '',
  type: '',
  url: '',
  isUploaded: false,
};

const WatchContentModal = (props) => {
  const { modalTitle, activeMode, handleOk, closeModal, callback, contentId } =
    props;
  const [form] = Form.useForm();
  const [validateFieldsName, setValidateFieldsName] = useState([]);
  const [categoriesList, setCategoriesList] = useState([]);
  const [isCategoriesListFetching, setIsCategoriesListFetching] =
    useState(false);
  const [isFormSubmitting, setIsFormSubmitting] = useState(false);
  const [isMediaImageUpdating, setIsMediaImageUpdating] = useState(false);
  const [isMediaVideoUpdating, setIsMediaVideoUpdating] = useState(false);
  const [isFetchWatch, setIsFetchingWatch] = useState(false);
  // const [selectMediaImage, setSelectMediaImage] = useState('');
  // const [selectMediaVideo, setSelectMediaVideo] = useState('');
  const [selectMediaImageObject, setSelectMediaImageObject] = useState(
    mediaInitialStructure
  );
  const [selectMediaVideoObject, setSelectMediaVideoObject] = useState(
    mediaInitialStructure
  );

  let getModalTitle = modalTitle || `Add ${moduleName}`;
  let modalButtonName = `Add ${moduleName}`;
  let formDisabled = false;
  if (activeMode === MODE_TYPES.EDIT) {
    getModalTitle = `Add ${moduleName}`;
    modalButtonName = `Update ${moduleName}`;
  }
  if (activeMode === MODE_TYPES.VIEW) {
    getModalTitle = `View ${moduleName}`;
    formDisabled = true;
  }

  useEffect(() => {
    fetchCategories();
    if (activeMode === MODE_TYPES.ADD) {
      form.resetFields();
    }
    if (!contentId) return;
    setIsFetchingWatch(true);
    const url = URLS_V2?.WATCH_CONTENT?.GET_WATCH_CONTENT_BY_ID.replace(
      '#ID#',
      contentId
    );
    httpGet(url)
      .then((res) => {
        if (res.status === 200) {
          if (
            res?.data &&
            Object.entries(res?.data) &&
            Object.entries(res?.data).length > 0
          ) {
            Object.entries(res?.data).forEach(([key, value]) => {
              if (key === 'image') {
                setSelectMediaImageObject({
                  ...mediaInitialStructure,
                  url: value,
                  name: value.split('/')[6],
                });
              } else if (key === 'video') {
                setSelectMediaVideoObject({
                  ...mediaInitialStructure,
                  url: value,
                  name: value.split('/')[6],
                });
              } else {
                form.setFieldsValue({
                  [key]: value,
                });
              }
            });
          }
        } else if (res?.status && API_V2_ERROR_CODES.includes(res?.status)) {
          showErrorMsg(res?.message);
        }
        setIsFetchingWatch(false);
      })
      .catch((err) => {
        showErrorMsg(err);
        setIsFetchingWatch(false);
      });
  }, [activeMode]);

  const handleValidateFieldNames = (name) => {
    const isFieldName = validateFieldsName.find(
      (fieldName) => fieldName === name
    );
    if (isFieldName) return 'onChange';
    return 'onBlur';
  };

  const handleAddEditContent = () => {
    let apiMethod = httpPost;
    let successMessage = 'added';
    let url = URLS_V2?.WATCH_CONTENT?.ADD_WATCH_CONTENT;
    if (contentId) {
      successMessage = 'updated';
      url = URLS_V2?.WATCH_CONTENT?.UPDATE_WATCH_CONTENT.replace(
        '#ID#',
        contentId
      );
      apiMethod = httpPut;
    }
    form.submit();
    form
      .validateFields()
      .then(async (values) => {
        if (!selectMediaImageObject.name) {
          showErrorMsg('Thumb is required!');
          return;
        }
        if (!selectMediaVideoObject.name) {
          showErrorMsg('Video is required!');
          return;
        }
        const submitFormData = {
          title: values.title,
          category_id: values.category_id,
          lang: values.lang,
          image: selectMediaImageObject,
          video: selectMediaVideoObject,
        };
        setIsFormSubmitting(true);
        apiMethod(url, submitFormData)
          .then((res) => {
            if (res.status === 200) {
              showSuccessMsg(`${moduleName} ${successMessage} successfully!`);
              resetAllData();
              callback();
              // updateFirebaseGlobalAppSettings(
              //   GLOBAL_APP_SETTINGS_KEYS.watch_content
              // );
            } else if (
              res?.status &&
              API_V2_ERROR_CODES.includes(res?.status)
            ) {
              showErrorMsg(res?.message);
            }
            setIsFormSubmitting(false);
          })
          .catch((err) => {
            showErrorMsg(err);
            setIsFormSubmitting(false);
          });
      })
      .catch((errorInfo) => errorInfo);
  };

  const fetchCategories = ({ category_id } = {}) => {
    setIsCategoriesListFetching(true);
    httpGet(URLS_V2?.WATCH_CONTENT?.FETCH_WATCH_CONTENT_CATEGORIES)
      .then((res) => {
        if (res.status === 200) {
          setCategoriesList(res?.data || []);
          if (category_id) form.setFieldsValue({ category_id });
        } else if (res?.status && API_V2_ERROR_CODES.includes(res?.status)) {
          showErrorMsg(res?.message);
        }
        setIsCategoriesListFetching(false);
      })
      .catch((err) => {
        showErrorMsg(err);
        setIsCategoriesListFetching(false);
      });
  };

  const resetAllData = () => {
    form.resetFields();
    setSelectMediaImageObject({});
    setSelectMediaVideoObject({});
    setValidateFieldsName([]);
    setCategoriesList([]);
    setIsCategoriesListFetching(false);
    setIsFormSubmitting(false);
    setIsMediaImageUpdating(false);
    setIsMediaVideoUpdating(false);
    closeModal();
  };
  const handleOnCloseModal = () => {
    if (isFormSubmitting) return;
    resetAllData();
  };

  let modalFooterButtons = [
    <Button
      key="add"
      type="primary"
      onClick={handleAddEditContent}
      disabled={
        isFormSubmitting || isMediaImageUpdating || isMediaVideoUpdating
      }
    >
      {isFormSubmitting ? 'Loading...' : modalButtonName}
    </Button>,
    <Button
      key="close"
      onClick={handleOnCloseModal}
      disabled={isFormSubmitting}
    >
      Close
    </Button>,
  ];

  return (
    <div>
      <WatchContentModalBox>
        <Modal
          title={getModalTitle}
          visible={Boolean(activeMode)}
          onOk={handleOk}
          onCancel={handleOnCloseModal}
          className="expense-modal"
          maskClosable={false}
          width={550}
          forceRender={true}
          footer={!formDisabled && modalFooterButtons}
        >
          {!isFetchWatch ? (
            <Form form={form} layout="vertical">
              <Row gutter={16}>
                <Col className="gutter-row" xs={24} sm={12}>
                  <Form.Item
                    name="title"
                    label="Title"
                    validateTrigger={handleValidateFieldNames('title')}
                    rules={[
                      {
                        required: true,
                        message: 'Please enter title!',
                      },
                    ]}
                  >
                    <Input
                      type="text"
                      placeholder="Title"
                      disabled={formDisabled}
                      onBlur={() =>
                        setValidateFieldsName([...validateFieldsName, 'title'])
                      }
                    />
                  </Form.Item>
                </Col>
                <Col className="gutter-row" xs={24} sm={12}>
                  <Form.Item
                    name="category_id"
                    label="Category"
                    rules={[
                      {
                        required: true,
                        message: 'Please select category!',
                      },
                    ]}
                  >
                    <Select
                      disabled={formDisabled}
                      placeholder={
                        isCategoriesListFetching
                          ? 'Fetching...'
                          : 'Select Category'
                      }
                      showSearch
                      optionFilterProp="children"
                      filterOption={(input, option) =>
                        option.children
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      }
                    >
                      {categoriesList &&
                        categoriesList.length > 0 &&
                        categoriesList.map((category, index) => (
                          <Option value={category?._id} key={index}>
                            {category?.title || ''}
                          </Option>
                        ))}
                    </Select>
                  </Form.Item>
                </Col>
                <Media
                  isMediaImageUpdating={isMediaImageUpdating}
                  setIsMediaImageUpdating={setIsMediaImageUpdating}
                  isMediaVideoUpdating={isMediaVideoUpdating}
                  setIsMediaVideoUpdating={setIsMediaVideoUpdating}
                  setSelectMediaVideoObject={setSelectMediaVideoObject}
                  setSelectMediaImageObject={setSelectMediaImageObject}
                  selectMediaImageObject={selectMediaImageObject}
                  selectMediaVideoObject={selectMediaVideoObject}
                  formDisabled={formDisabled}
                  activeMode={activeMode}
                  mediaInitialStructure={mediaInitialStructure}
                  contentId={contentId}
                />
                <Col className="gutter-row" xs={24}>
                  <Form.Item
                    disabled={formDisabled}
                    name="lang"
                    label="Language"
                    rules={[
                      {
                        required: true,
                        message: 'Please select language!',
                      },
                    ]}
                  >
                    <Select
                      placeholder="Select language"
                      disabled={formDisabled}
                    >
                      {WATCH_CONTENT_LANGUAGE_LIST &&
                        WATCH_CONTENT_LANGUAGE_LIST.length > 0 &&
                        WATCH_CONTENT_LANGUAGE_LIST.map(
                          (languageItem, index) => (
                            <Option value={languageItem.value} key={index}>
                              {languageItem.label || ''}
                            </Option>
                          )
                        )}
                    </Select>
                  </Form.Item>
                </Col>
              </Row>
            </Form>
          ) : (
            <CenteredSpin spinning={isFetchWatch} tip="Loading..." />
          )}
        </Modal>
      </WatchContentModalBox>
    </div>
  );
};

WatchContentModal.propTypes = {
  activeMode: string,
  callback: func,
  closeModal: func,
  contentId: string,
  handleOk: func,
  modalTitle: string,
};

export default WatchContentModal;
