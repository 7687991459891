import React from 'react';
import { bool, object } from 'prop-types';
import { Row, Col, Collapse, Skeleton } from 'antd';
import { CollapseBox } from './_hardware-info.styled';

const { Panel } = Collapse;

const HardwareInfo = (props) => {
  const { hardwareInfo, isFetching } = props;

  const renderLableColumnRows = (
    label,
    value,
    rowGutter = 18,
    lableColSpacing = 6,
    valueColSpacing = 18
  ) => {
    return (
      <Row gutter={rowGutter} style={{ margin: '10px' }}>
        <Col className="gutter-row" xs={lableColSpacing}>
          <strong>{label}</strong>
        </Col>
        <Col className="gutter-row" xs={valueColSpacing}>
          <Skeleton
            loading={isFetching}
            active
            paragraph={{ rows: 1, width: '100%' }}
            title={false}
          >
            {value}
          </Skeleton>
        </Col>
      </Row>
    );
  };

  return (
    <CollapseBox>
      <Collapse defaultActiveKey={['1']}>
        <Panel header="Managed Device Hardware Info" key="1">
          {renderLableColumnRows('Brand: ', hardwareInfo?.brand)}
          {renderLableColumnRows(
            'Baseband Version: ',
            hardwareInfo?.deviceBasebandVersion
          )}
          {renderLableColumnRows('Hardware: ', hardwareInfo?.hardware)}
          {renderLableColumnRows('Manufacturer: ', hardwareInfo?.Manufacturer)}
          {renderLableColumnRows('Model: ', hardwareInfo?.model)}
          {renderLableColumnRows('Serial Number: ', hardwareInfo?.serialNumber)}
        </Panel>
      </Collapse>
    </CollapseBox>
  );
};

HardwareInfo.propTypes = {
  hardwareInfo: object,
  isFetching: bool,
};

export default HardwareInfo;
