import React from 'react';
import { array, bool, func, number } from 'prop-types';
import { 
  Collapse, 
  // Form, 
  // Select, 
  Table } from 'antd';
import { CollapseBox, Loader } from './_device-rides.styled';
// import { CheckSquareOutlined, CloseSquareOutlined } from '@ant-design/icons';
import moment from 'moment';
import { currentPage } from 'utils';

const { Panel } = Collapse;

// const { Option } = Select;

// const statusOptions = (
//   <>
//     <Option key={'today'} value={'today'}>
//       {'Today'}
//     </Option>
//     <Option key={'last_24_hrs'} value={'last_24_hrs'}>
//       {'Last 24 Hours'}
//     </Option>
//     <Option key={'last_7_days'} value={'last_7_days'}>
//       {'Last 7 Days'}
//     </Option>
//     <Option key={'last_30_days'} value={'last_30_days'}>
//       {'Last 30 Days'}
//     </Option>
//   </>
// );

const DeviceRides = (props) => {
  const {
    deviceRideList,
    isFetchingDeviceRides,
    totalDeviceRides,
    handlePaginationChange,
  } = props;

  const columns = [
    {
      title: ENROLL_LABELS.SR_NO,
      width: 80,
      render: (value, item, index) =>
        (currentPage(0, 10) - 1) * 100 + index + 1,
    },
    {
      key: 'device_serial',
      title: ENROLL_LABELS.DEVICE_SERIAL,
      dataIndex: 'device_serial',
    },
    {
      key: 'taxi_number',
      title: ENROLL_LABELS.TAXI_NUMBER,
      dataIndex: 'taxi_number',
    },
    {
      key: 'start_time',
      title: ENROLL_LABELS.START_TIME,
      dataIndex: 'start_time',
      defaultSortOrder: 'descend',
      sorter: true,
      render: (date) => {
        return (function Actions() {
          return (
            <>{(date && moment.unix(date).format('DD-MM-YYYY hh:mm a')) || ''}</>
          );
        })();
      },
    },
    {
      key: 'stop_time',
      title: ENROLL_LABELS.END_TIME,
      dataIndex: 'stop_time',
      sorter: true,
      render: (date) => {
        return (function Actions() {
          return (
            <>{(date && moment.unix(date).format('DD-MM-YYYY hh:mm a')) || ''}</>
          );
        })();
      },
    },
    {
      key: 'ride_time',
      title: ENROLL_LABELS.RIDE_TIME,
      dataIndex: 'ride_time',
      sorter: true,
      render: (data) => {
        return (function Actions() {
          const duration = moment.duration(data, 'seconds');
          const hours = Math.floor(duration.asHours());
          const minutes = duration.minutes();
          const seconds = duration.seconds();
          const convertedTime = `${hours}h : ${minutes}m : ${seconds}s`;
          return <>{data && data >= 1 ? convertedTime : '0'}</>;
        })();
      },
    },
    // {
    //   key: 'is_passenger_present',
    //   title: ENROLL_LABELS.IS_RIDE_VERIFIED,
    //   dataIndex: 'is_passenger_present',
    //   sorter: true,
    //   render: (is_passenger_present) => {
    //     return (function Actions() {
    //       return (
    //         <>
    //           {is_passenger_present ? (
    //             <CheckSquareOutlined
    //               style={{ fontSize: '18px', color: '#52c41a' }}
    //             />
    //           ) : (
    //             <CloseSquareOutlined
    //               style={{
    //                 fontSize: '18px',
    //                 color: '#ff4d4f',
    //               }}
    //             />
    //           )}
    //         </>
    //       );
    //     })();
    //   },
    // },
  ];

  const defaultTitle = () => {
    return (
      <>
        <div
          className="cus-table-left-right-btn"
          style={{ alignItems: 'center' }}
        >
          {/* <Form className="main-filter-form"> */}
            {/* <Form.Item
              label="Filter By"
              name="is_passenger_present"
              style={{ marginBottom: 0 }}
            >
              <Select
                showSearch
                style={{ width: 100 }}
                defaultValue={'today'}
                optionFilterProp="children"
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                  0
                }
                placeholder="Select Ride Status"
                onChange={handleSelectSearch}
              >
                {statusOptions}
              </Select>
            </Form.Item> */}
          {/* </Form> */}
          <div>
            {`Showing Records: ${deviceRideList.length} / Total Records: ${totalDeviceRides}`}
          </div>
        </div>
      </>
    );
  };

  return (
    <CollapseBox>
      <Collapse defaultActiveKey={['1']}>
        <Panel header="Device Rides" key="1">
          <div className="main-table-body">
            <Table
              rowKey="_id" //this from variable dataSource
              title={defaultTitle}
              loading={{
                indicator: <Loader>Fetching...</Loader>,
                spinning: isFetchingDeviceRides,
              }}
              columns={columns}
              dataSource={deviceRideList || []}
              size="small"
              pagination={{ defaultPageSize: 100, total: totalDeviceRides }}
              onChange={handlePaginationChange}
              scroll={{x:550}}
            />
          </div>
        </Panel>
      </Collapse>
    </CollapseBox>
  );
};

DeviceRides.propTypes = {
  //eslint-disable-next-line
  deviceRideList: array,
  handlePaginationChange: func,
    //eslint-disable-next-line
  handleSelectSearch: func,
  isFetchingDeviceRides: bool,
  totalDeviceRides: number,
};

export default DeviceRides;

const ENROLL_LABELS = {
  SR_NO: 'Sr. No.',
DEVICE_SERIAL: 'Device Serial',
  TAXI_NUMBER: 'Taxi Number',
  START_TIME: 'Start Time',
  END_TIME: 'End Time',
  RIDE_TIME: 'Ride Time',
  // IS_RIDE_VERIFIED: 'Is Ride Verified?',
  // IMAGE: 'Image',
};
