import React, { useEffect, useState } from 'react';
import { string, func, bool, object } from 'prop-types';
import { Modal, Button, Steps, Result, Table, Alert } from 'antd';
import {
  LoadingOutlined,
  CloudUploadOutlined,
  CheckCircleOutlined,
  SyncOutlined,
  CloseCircleOutlined,
} from '@ant-design/icons';

import { ImportDeviceModalBox } from './_import-device-modal.styled';

const { Step } = Steps;

const ImportDeviceModal = (props) => {
  const {
    handleCloseModal,
    modalTitle,
    modalVisible,
    isFormSubmitting,
    importDeviceResponse,
  } = props;

  const [currentStep, setCurrentStep] = useState(0);

  useEffect(() => {
    window.onbeforeunload = function () {
      return true;
    };

    return () => {
      window.onbeforeunload = null;
    };
  }, []);

  useEffect(() => {
    if (!isFormSubmitting) {
      setTimeout(() => {
        updateStep(1);
      }, 2000);
    }
  }, [isFormSubmitting]);

  let modalFooterButtons = [
    <Button key="close" onClick={handleCloseModal}>
      Close
    </Button>,
  ];

  const columns = [
    {
      title: DEVICE_REPORT_LABELS.ROW_ID,
      dataIndex: 'row',
      key: 'row',
    },
    {
      title: DEVICE_REPORT_LABELS.DEVICE_SERIAL,
      dataIndex: 'device_serial',
      key: 'device_serial',
    },
    {
      title: DEVICE_REPORT_LABELS.REASON,
      dataIndex: 'reason',
      key: 'reason',
    },
    {
      title: DEVICE_REPORT_LABELS.STATUS,
      dataIndex: 'status',
      key: 'status',
      render: () => {
        return (function Actions() {
          return (
            <div>
              <CloseCircleOutlined
                style={{ fontSize: '18px', color: '#ff4d4f' }}
              />
            </div>
          );
        })();
      },
    },
  ];

  const updateStep = (step) => {
    setCurrentStep(step);
  };

  return (
    <>
      <ImportDeviceModalBox>
        <Modal
          title={modalTitle}
          visible={modalVisible}
          onCancel={handleCloseModal}
          className="expense-modal"
          maskClosable={false}
          width={850}
          forceRender={true}
          footer={currentStep >= 1 && modalFooterButtons}
        >
          <Steps current={currentStep}>
            <Step
              status={currentStep >= 0 ? 'finish' : 'wait'}
              title="Import Records"
              icon={
                currentStep === 0 ? (
                  <LoadingOutlined />
                ) : (
                  <CloudUploadOutlined />
                )
              }
            />
            <Step
              status={currentStep === 1 ? 'finish' : 'wait'}
              title="Finish"
              icon={<CheckCircleOutlined />}
            />
          </Steps>
          {currentStep === 0 ? (
            <Result
              icon={<SyncOutlined spin />}
              status="success"
              title={`Currently records being importing...`}
            />
          ) : currentStep === 1 ? (
            <>
              {importDeviceResponse?.rejectedDeviceList?.length >= 1 ? (
                <div className="while-bg-box m-t-5">
                  <Alert
                    message="Some records Failed to import"
                    description={`${importDeviceResponse?.rejectedCounts} records out of ${importDeviceResponse?.totalCounts} failed to import to system. please check them to re-import them later`}
                    type="error"
                    showIcon
                  />
                  <Table
                    className="m-t-20"
                    columns={columns}
                    dataSource={importDeviceResponse?.rejectedDeviceList}
                    pagination={false}
                    scroll={{ y: 400 }}
                  />
                </div>
              ) : (
                <Result
                  status="success"
                  title={`All ${importDeviceResponse?.totalCounts} records Imported successfully`}
                />
              )}
            </>
          ) : null}
        </Modal>
      </ImportDeviceModalBox>
    </>
  );
};

ImportDeviceModal.propTypes = {
  // callback: func,
  handleCloseModal: func,
  importDeviceResponse: object,
  isFormSubmitting: bool,
  modalTitle: string,
  modalVisible: bool,
};

export default ImportDeviceModal;

const DEVICE_REPORT_LABELS = {
  ROW_ID: 'Row Id',
  DEVICE_SERIAL: 'Device Serial',
  STATUS: 'Status',
  REASON: 'Reason',
};
