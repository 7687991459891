import styled from 'styled-components';

export const DashboardBox = styled.div`
  .table-header {
    margin: 10px;
    background: white;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 15px;
    border-radius: 4px;
    overflow: hidden;
    h3 {
      margin-bottom: 0px;
    }
  }

  .dashboard-kpis {
    margin: 10px;
  }

  .white-box {
    margin-top: 10px;
    background: white;
    padding: 15px;
    border-radius: 4px;
  }

  .chart-box {
    height: 750px;
    width: 750px;
  }

  .chart-loader-container {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    background: rgba(255, 255, 255, 0.6);
    color: #fff;
    z-index: 99;
  }
`;
