import React from 'react';
import { string, func, bool } from 'prop-types';
import { useParams } from 'react-router-dom';
import {
  Row,
  Col,
  Collapse,
  Radio,
  Form,
  Input,
  Button,
  Spin,
  Popconfirm,
} from 'antd';
import {
  MODE_OF_COMMUNICATION_LIST,
  MODE_OF_COMMUNICATION,
} from 'constants/campaign';
import {
  UploadOutlined,
  EyeOutlined,
  LoadingOutlined,
  DeleteOutlined,
} from '@ant-design/icons';
import { CollapseBox } from './_communication.styled';
import { httpPut, httpDelete, URLS, showErrorMsg, showSuccessMsg } from 'utils';

const { Panel } = Collapse;
const { TextArea } = Input;

const Communication = (props) => {
  const {
    modeOfCommunication,
    setModeOfCommunication,
    mobileText,
    setIsTemplateUpdating,
    setSelectTemplate,
    selectTemplate,
    isTemplateUpdating,
  } = props;
  const params = useParams();

  const handleTemplateFile = (e) => {
    const file = e.target.files[0];
    if (file.type !== 'text/html') {
      showErrorMsg('Only .html file type is accepted');
      return;
    }
    setIsTemplateUpdating(true);
    const url = URLS?.CAMPAIGN?.UPLOAD_TEMPLATE.replace('#ID#', params?.id);
    const formData = new FormData();
    formData.append('files', e.target.files[0]);
    httpPut(url, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
      .then((res) => {
        if (res.status === 200) {
          showSuccessMsg('Campaign template saved successfully!');
          setSelectTemplate(res?.data?.template_url || '');
        } else if (res?.error) {
          showErrorMsg(res?.error);
        }
        setIsTemplateUpdating(false);
      })
      .catch((err) => {
        showErrorMsg(err);
        setIsTemplateUpdating(false);
      });
  };

  const handleDeleteFile = ({ fileUrl, type }) => {
    if (type === 'Template') {
      setIsTemplateUpdating(true);
    }

    const url = URLS?.CAMPAIGN?.DELETE_GC_FILE.replace('#ID#', params?.id)
      .replace('#FILE_URL#', encodeURIComponent(fileUrl))
      .replace('#FILE_TYPE#', type);
    httpDelete(url)
      .then((res) => {
        if (res.status === 200) {
          showSuccessMsg(`${type} file deleted successfully!`);
          if (type === 'Template') {
            setSelectTemplate('');
          }
        } else if (res?.error) {
          showErrorMsg(res?.error);
        }
        setIsTemplateUpdating(false);
      })
      .catch((err) => {
        showErrorMsg(err);
        setIsTemplateUpdating(false);
      });
  };

  return (
    <CollapseBox>
      <Collapse defaultActiveKey={['1']}>
        <Panel header="Mode of Communication" key="1">
          <Row gutter={16}>
            <Col className="gutter-row" xs={24}>
              <div className="communication-btns">
                <Form.Item
                  name="mode_of_communication"
                  label="Mode of Communication"
                >
                  <Radio.Group defaultValue={modeOfCommunication}>
                    {MODE_OF_COMMUNICATION_LIST &&
                      MODE_OF_COMMUNICATION_LIST.length > 0 &&
                      MODE_OF_COMMUNICATION_LIST.map((data, index) => (
                        <Radio
                          value={data}
                          key={index}
                          onClick={() => {
                            setModeOfCommunication(data);
                          }}
                        >
                          {data}
                        </Radio>
                      ))}
                  </Radio.Group>
                </Form.Item>

                {modeOfCommunication === MODE_OF_COMMUNICATION.EMAIL && (
                  <div className="template-upload-btn">
                    {isTemplateUpdating ? (
                      <Button type="primary" disabled className="loader-btn">
                        <Spin
                          indicator={
                            <LoadingOutlined
                              style={{
                                fontSize: 16,
                                marginRight: 10,
                                color: '#808080b8',
                              }}
                              spin
                            />
                          }
                        />
                        Loading...
                      </Button>
                    ) : (
                      <>
                        {selectTemplate ? (
                          <div className="block-box">
                            <a
                              href={selectTemplate}
                              target="_blank"
                              rel="noreferrer"
                            >
                              <Button
                                type="primary"
                                className="preview-btn btn-mr-rt"
                              >
                                <EyeOutlined />
                                View Template
                              </Button>
                            </a>
                            <Popconfirm
                              title="Sure to delete template file?"
                              onConfirm={() =>
                                handleDeleteFile({
                                  fileUrl: selectTemplate,
                                  type: 'Template',
                                })
                              }
                            >
                              <Button
                                danger
                                shape="circle"
                                htmlType="submit"
                                className="delete-btn"
                              >
                                <DeleteOutlined />
                              </Button>
                            </Popconfirm>
                          </div>
                        ) : (
                          <div className="custom-upload-btn">
                            <label htmlFor="html_template_file">
                              <div>
                                <UploadOutlined />
                                Upload Template
                              </div>
                              <input
                                id="html_template_file"
                                type="file"
                                name="html_template_file"
                                style={{ display: 'none' }}
                                onChange={handleTemplateFile}
                                accept=".html"
                              />
                            </label>
                          </div>
                        )}
                      </>
                    )}
                  </div>
                )}
                {modeOfCommunication === MODE_OF_COMMUNICATION.MOBILE && (
                  <Form.Item
                    name="mobile_text"
                    rules={[
                      {
                        validator(_, value) {
                          if (value && value.length > 160) {
                            return Promise.reject(
                              'Maximum 160 characters are allowed'
                            );
                          }
                          return Promise.resolve();
                        },
                      },
                    ]}
                  >
                    <TextArea rows={4} value={mobileText} />
                  </Form.Item>
                )}
              </div>
            </Col>
          </Row>
        </Panel>
      </Collapse>
    </CollapseBox>
  );
};

Communication.propTypes = {
  isTemplateUpdating: bool,
  mobileText: string,
  modeOfCommunication: string,
  selectTemplate: string,
  setIsTemplateUpdating: func,
  setModeOfCommunication: func,
  setSelectTemplate: func,
};

export default Communication;
