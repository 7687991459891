import React, { useEffect, useState } from 'react';
import { Col, Form, Input, Row, Table } from "antd";
import { Loader } from '../_rides.styled';
import { URLS_V2, httpGet } from 'utils';

const RidesCount = () => {
  const [dataSource, setDataSource] = useState([]);
  const [isFetching, setIsFetching] = useState(false);
  const [searchedResults, setSearchedResults] = useState([]);
  const [filterValues, setFilterValues] = useState({
    device_serial: "",
    taxi_number: "",
  });

  const columns = [
    {
      key: 'device_serial',
      title: ENROLL_LABELS.DEVICE_SERIAL,
      dataIndex: 'device_serial',
    },
    {
      key: 'taxi_number',
      title: ENROLL_LABELS.TAXI_NUMBER,
      dataIndex: 'taxi_number',
    },
    {
      key: 'count',
      title: ENROLL_LABELS.COUNT,
      dataIndex: 'endRideCount',
      sorter: true,
    },
  ]

  const getDevicesListAndRidesList = async () => {
    setIsFetching(true);
    try {
      const url = `${URLS_V2?.RIDES?.RIDES_LIST_48_HOURS}`;
      const res = await httpGet(url);

      // const url2 = `${URLS_V2?.RIDES?.RIDES_LIST}`;
      // const res2 = await httpGet(url2);

      const updated = res?.data.map((item) => {
        return {
          ...item,
          count: 0
        }
      });
      if (res?.data?.length) {
        handleCount(updated);
      }
    } catch (error) {
      console.log("error", error);
    } finally {
      setIsFetching(false);
    }
  }


  const handleCount = (updatedDeviceList, ridesList) => {
    const unixOfFortyEightHours = 48 * 60 * 60 * 1000;
    const currenDateUnix = new Date().getTime();

    ridesList?.data?.forEach((item) => {
      if (item?.stop_time && currenDateUnix - (item?.stop_time*1000) <= unixOfFortyEightHours) {
        updatedDeviceList?.forEach((el) => {
          if (item?.device_serial === el?.device_serial) {
            el.count += 1;
          }
        });
      }
    })
    setDataSource(updatedDeviceList);
  }

  useEffect(() => {
    getDevicesListAndRidesList();
  }, []);

  const handleOnChange = (pagination, filters, sorter) => {
    if (sorter?.field === "count") {
      if (sorter?.order === "ascend") {
        const sorted = dataSource?.sort((a, b) => a?.count - b?.count);
        setDataSource(sorted);
      } else if (sorter?.order === "descend") {
        const sorted = dataSource?.sort((a, b) => b?.count - a?.count);
        setDataSource(sorted);
      }
    }
  }

  const handleSearch = (e) => {
    setFilterValues((prev) => ({ ...prev, [e.target.name]: e.target.value }));
  }

  useEffect(() => {
    if (filterValues?.device_serial && filterValues?.taxi_number) {
      const filtered = dataSource?.filter((item) => {
        return item?.device_serial?.toLowerCase()?.includes(filterValues?.device_serial?.toLowerCase());
      })
      setSearchedResults(filtered?.filter((item) => {
        return item?.taxi_number?.toLowerCase()?.includes(filterValues?.taxi_number?.toLowerCase());
      }));

    } else if (filterValues?.device_serial) {
      const filtered = dataSource?.filter((item) => {
        return item?.device_serial?.toLowerCase()?.includes(filterValues?.device_serial?.toLowerCase());
      })
      setSearchedResults(filtered);
    } else if (filterValues?.taxi_number) {
      const filtered = dataSource?.filter((item) => {
        return item?.taxi_number?.toLowerCase()?.includes(filterValues?.taxi_number?.toLowerCase());
      })
      setSearchedResults(filtered);
    } else {
      setSearchedResults([]);
    }
  }, [filterValues]);

  return (
    <div>
      <div className='while-bg-box' >
        <Form>
          <Row gutter={[16, 16]}  >
            <Col md={6}>
              <Form.Item name="device_serial" style={{ marginBottom: '0' }}>
                <Input
                  type="text"
                  name="device_serial"
                  onChange={handleSearch}
                  placeholder="Search Devices Serial"
                />
              </Form.Item>
            </Col>
            <Col md={6}>
              <Form.Item name="taxi_number" style={{ marginBottom: '0' }}>
                <Input
                  type="text"
                  name="taxi_number"
                  onChange={handleSearch}
                  placeholder="Search Taxi Number"
                />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </div>

      <div>
        <Table
          title={() => <div style={{ fontSize: "1.5rem", fontWeight: "500" }} >Ride Count for last 48 hours </div>}
          //eslint-disable-next-line
          dataSource={(searchedResults?.length && (filterValues.device_serial || filterValues.taxi_number)) ? searchedResults : (filterValues?.device_serial || filterValues?.taxi_number) ? searchedResults : dataSource}
          columns={columns}
          onChange={handleOnChange}
          loading={{
            indicator: <Loader className="table-loader">Fetching...</Loader>,
            spinning: isFetching,
          }}
          scroll={{ x: 400 }}
        />;
      </div>
    </div>
  )
}

const ENROLL_LABELS = {
  DEVICE_SERIAL: 'Device Serial',
  TAXI_NUMBER: 'Taxi Number',
  START_TIME: 'Start Time',
  END_TIME: 'End Time',
  COUNT: 'Count'
};

export default RidesCount;