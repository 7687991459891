import React, { useState, useEffect } from 'react';
import {
  Button,
  Col,
  Form,
  Row,
  Skeleton,
  Table,
  Tooltip,
  Card,
  Select,
  Input,
} from 'antd';
import {
  FrownOutlined,
  DownloadOutlined,
  MehOutlined,
  SmileOutlined,
} from '@ant-design/icons';
import { CSVLink } from 'react-csv';
import {
  currentPage,
  formatDate,
  formatOnlyDate,
  formatTime,
  httpGet,
  showErrorMsg,
  showSuccessMsg,
  URLS_V2,
} from 'utils';
import CommonBreadCrumb from 'components/common-breadcrumb';
import StatisticCardBlock from 'components/statistic-card-block';
import { debounce } from 'lodash';

const { Option } = Select;

const ratingsOptions = (
  <>
    <Option key={'happy'} value={'HAPPY'}>
      {'Happy'}
    </Option>
    <Option key={'normal'} value={'NORMAL'}>
      {'Normal'}
    </Option>
    <Option key={'sad'} value={'SAD'}>
      {'Sad'}
    </Option>
  </>
);

const sadReasonsOptions = (
  <>
    <Option key={'delays'} value={'DELAYS'}>
      {'Delays'}
    </Option>
    <Option key={'driver'} value={'DRIVER'}>
      {'Driver'}
    </Option>
    <Option key={'car'} value={'CAR'}>
      {'Car'}
    </Option>
    <Option key={'screen'} value={'SCREEN'}>
      {'Screen'}
    </Option>
    <Option key={'other'} value={'OTHER'}>
      {'Other'}
    </Option>
  </>
);

const DeviceRatingList = () => {
  const ref = React.useRef(null);
  const breakCrumbList = [
    { label: 'Home', link: '/dashboard' },
    { label: 'Device Ratings' },
  ];
  const [isFetching, setIsFetching] = useState(true);
  const [isFetchingCSV, setIsFetchingCSV] = useState(false);
  const [fetchingStatistics, setFetchingStatistics] = useState(false);
  const [CSVDownload, setCSVDownload] = useState([]);
  const [deviceRatingsStatistics, setDeviceRatingsStatistics] = useState([]);
  const [devicesRatingList, setDevicesRatingList] = useState([]);
  const [totalRecords, setTotalRecords] = useState(0);
  const [orderPagination, setOrderPagination] = useState({
    skip: 0,
    limit: 100,
    order: 1,
    orderBy: 'device_serial',
  });
  const [searchParam, setSearchParam] = useState({
    device_serial: '',
    rating: '',
  });

  useEffect(() => {
    fetchDevicesRatingList(orderPagination);
  }, [searchParam]);

  useEffect(() => {
    fetchDeviceRatingsStatistics();
  }, []);

  const fetchDevicesRatingList = (orderPaginationParams) => {
    setIsFetching(true);
console.log(orderPaginationParams, "kilopo");

    const queryParams = new URLSearchParams();
    queryParams.append('skip', orderPaginationParams.skip);
    queryParams.append('limit', orderPaginationParams.limit);
    queryParams.append('order', orderPaginationParams.order);
    queryParams.append('order_by', orderPaginationParams.orderBy);
    queryParams.append('page', orderPaginationParams.page);

    if (searchParam && Object.keys(searchParam).length >= 1) {
      Object.keys(searchParam).forEach((key) => {
        if (searchParam[key]) {
          queryParams.append(key, searchParam[key]);
        }
      });
    }

    const url = `${
      URLS_V2?.DEVICES?.DEVICE_RATINGS_LIST
    }?${queryParams.toString()}`;

    httpGet(url)
      .then((res) => {
console.log(res, "jhie")

        handleCSVData(res.docs || []);
        if (res?.status === 200) {
          let deviceRatingsListData = [];
          if (res?.data && res?.data.length > 0) {
            deviceRatingsListData = res?.data.map((data) => {
              return {
                ...data,
                key: data?._id,
              };
            });
          }
          setDevicesRatingList(deviceRatingsListData || []);
          setTotalRecords(res?.count || 0);
        }
        setIsFetching(false);
      })
      .catch((err) => {
        setIsFetching(false);
      });
  };

  const fetchDeviceRatingsStatistics = async () => {
    try {
      setFetchingStatistics(true);
      const url = `${URLS_V2?.DEVICES?.DEVICE_RATINGS_STATISTICS}`;
      const res = await httpGet(url);
  
      if (res?.status === 200) {
        setDeviceRatingsStatistics(res?.data || {});
      }
      setFetchingStatistics(false);
    } catch (error) {
      console.log("error",error)
    }finally{
      setFetchingStatistics(false);
    }
  };

  const handlePaginationChange = (pagination, filters, sorter) => {
    const { current, pageSize } = pagination;
  // console.log(pagination, "pagin");
    const tempSearchPagination = {
      ...orderPagination,
      page: String(current),
      skip: current * pageSize - pageSize,
      limit: pageSize,
      order: sorter.order === 'descend' ? -1 : 1,
      orderBy: sorter.field,
    };
    setOrderPagination(tempSearchPagination);
    fetchDevicesRatingList(tempSearchPagination);
  };

  const defaultTitle = () => {
    return (
      <div className="cus-table-right-btn">
        {`Showing Records: ${devicesRatingList.length} / Total Records: ${totalRecords}`}
      </div>
    );
  };

  const TABLE_LABELS = {
    SR_NO: 'Serial No.',
    DEVICE_SERIAL: 'Device Serial',
    TAXI_NUMBER:"Taxi Number",
    RATING:'Rating',
    REASON: 'Sad Reason',
    RATED_TIME: 'Time',
  };

  const columns = [
    {
      title: TABLE_LABELS.SR_NO,
      width: 80,
      render: (value, item, index) =>
        (currentPage(orderPagination.skip, orderPagination.limit) - 1) *
          orderPagination.limit +
        index +
        1,
    },
    {
      key: 'device_serial',
      title: TABLE_LABELS.DEVICE_SERIAL,
      dataIndex: 'device_serial',
      fixed: 'left',
    },
    {
      key: 'taxi_number',
      title: TABLE_LABELS.TAXI_NUMBER,
      dataIndex: 'taxi_number',
    },
    {
      key: 'rating',
      title: TABLE_LABELS.RATING,
      dataIndex: 'rating',
      align: 'center',
      render: (data, item) => {
        return (function Actions() {
          return (
            <>
              <Tooltip title={item?.sad_reason ? item?.sad_reason : undefined}>
                {item.rating === 'HAPPY' && (
                  <SmileOutlined
                    style={{ fontSize: '24px', color: '#25B864' }}
                  />
                )}
                {item.rating === 'NORMAL' && (
                  <MehOutlined style={{ fontSize: '24px', color: '#FAAD14' }} />
                )}
                {item.rating === 'SAD' && (
                  <FrownOutlined
                    style={{ fontSize: '24px', color: '#FF4D4F' }}
                  />
                )}
              </Tooltip>
            </>
          );
        })();
      },
    },
    {
      key: 'sad_reason',
      title: TABLE_LABELS.REASON,
      dataIndex: 'sad_reason',
    },
    {
      key: 'rated_at',
      title: TABLE_LABELS.RATED_TIME,
      dataIndex: 'rated_at',
      render: (date) => {
        return (function Actions() {
          return <>{(date && formatDate(date)) || '-'}</>;
        })();
      },
    },
  ];

  // const handlePaginationChange = (pagination, filters, sorter) => {
  //   fetchDevicesRatingList(pagination.current || 1);
  // };

  const handleExport = async () => {
    handleCSVData([]);
    setIsFetchingCSV(true);
    const url = `${URLS_V2?.DEVICES?.ALL_DEVICE_RATINGS_LIST}`;
    httpGet(url)
      .then((res) => {
        if (res?.status === 200) {
          handleCSVData(res?.data || []);
          ref.current.click();
          showSuccessMsg('Data exported successfully!');
        } else {
          showErrorMsg(
            'System is unable to complete request. Please try again later.'
          );
        }
        setIsFetchingCSV(false);
      })
      .catch((err) => {
        showErrorMsg(
          'System is unable to complete request. Please try again later.'
        );
        setIsFetchingCSV(false);
      });
  };

  const handleCSVData = (devicesData) => {
    let csvData = [];
    devicesData.forEach((data, index) => {
      
      const excelStructureData = {
        [ENROLL_LABELS.SR_NO]: index+1,
        [ENROLL_LABELS.DEVICE_SERIAL]: data?.device_serial || 'N/A',
        [ENROLL_LABELS.RATING]: data?.rating || 'N/A',
        [ENROLL_LABELS.REASON]: data?.sad_reason || 'N/A',
        [ENROLL_LABELS.RATED_DATE]: data.rated_at ? formatOnlyDate(data?.rated_at) : 'N/A',
        [ENROLL_LABELS.RATED_TIME]: data.rated_at ? formatTime(data?.rated_at) : 'N/A',
        [ENROLL_LABELS.RIDE_ID]: data.ride_id || 'N/A',
        [ENROLL_LABELS.LOCATION]: `${data?.location?.coordinates[0]}, ${data?.location?.coordinates[1]}`|| 'N/A',
        [ENROLL_LABELS.TAXI_NUMBER]:data?.taxi_number || 'N/A'
      };
      csvData.push(excelStructureData);
    });
    setCSVDownload(csvData);
  };
  const renderStatisticsCards = (
    cardTitle,
    totalCount,
    totalHappyCount,
    totalNormalCount,
    totalSadCount
  ) => {
    return (
      <Card title={cardTitle} bordered={false}>
        <Skeleton
          loading={fetchingStatistics}
          active
          paragraph={{ rows: 1, width: '100%' }}
          title={false}
        >
          <Row gutter={32}>
            <Col span={6}>
              <StatisticCardBlock
                title={'Total'}
                value={totalCount || 0}
                valueStyle={{ color: '#3f8600' }}
              />
            </Col>
            <Col span={6}>
              <StatisticCardBlock
                title="HAPPY"
                value={totalHappyCount || 0}
                precision={0}
                valueStyle={{ color: '#3f8600' }}
              />
            </Col>
            <Col span={6}>
              <StatisticCardBlock
                title="NORMAL"
                value={totalNormalCount || 0}
                precision={0}
                valueStyle={{ color: '#3f8600' }}
              />
            </Col>
            <Col span={6}>
              <StatisticCardBlock
                title="SAD"
                value={totalSadCount || 0}
                precision={0}
                valueStyle={{ color: '#3f8600' }}
              />
            </Col>
          </Row>
        </Skeleton>
      </Card>
    );
  };

  const handleTextSearch = (e) => {
    setSearchParam({
      ...searchParam,
      [e.target.name]: e.target.value,
    });
  };

  const handleSelectSearch = (fieldName, fieldValue) => {
    setSearchParam({
      ...searchParam,
      [fieldName]: fieldValue,
    });
  };

  return (
    <>
      <CommonBreadCrumb breadCrumbs={breakCrumbList} />
      <div className="while-bg-box cus-table-header">
        <h3 className="table-title">Device Ratings</h3>
        <div className="cus-table-right-btn">
          <div className="export-generate-btn">
            <Form.Item>
              <CSVLink
                data={CSVDownload}
                headers={headers}
                filename={'device-ratings.csv'}
              >
                <span ref={ref}></span>
              </CSVLink>
              <Button
                type="primary"
                onClick={handleExport}
                loading={isFetchingCSV}
                icon={<DownloadOutlined />}
              >
                {isFetchingCSV ? 'Exporting Data' : 'Export'}
              </Button>
            </Form.Item>
          </div>
        </div>
      </div>
      <div className="site-statistic-demo-card bg-box">
        <Row gutter={[14,14]}>
          <Col sm={24} md={12} lg={8} >
            {renderStatisticsCards(
              'Last 7 days',
              deviceRatingsStatistics?.last7_days_count,
              deviceRatingsStatistics?.last7_days_happy_count,
              deviceRatingsStatistics?.last7_days_normal_count,
              deviceRatingsStatistics?.last7_days_sad_count
            )}
          </Col>
          <Col sm={24} md={12} lg={8} >
            {renderStatisticsCards(
              'Last 30 days',
              deviceRatingsStatistics?.last30_days_count,
              deviceRatingsStatistics?.last30_days_happy_count,
              deviceRatingsStatistics?.last30_days_normal_count,
              deviceRatingsStatistics?.last30_days_sad_count
            )}
          </Col>
          <Col sm={24} md={12} lg={8}>
            {renderStatisticsCards(
              'All',
              deviceRatingsStatistics?.all,
              deviceRatingsStatistics?.happy,
              deviceRatingsStatistics?.sad,
              deviceRatingsStatistics?.normal
            )}
          </Col>
        </Row>
      </div>
      <div className="while-bg-box">
        <Form className="main-filter-form">
          <Row gutter={[16, 16]}>
            <Col md={8}>
              <Form.Item name="device_serial" style={{ marginBottom: '0' }}>
                <Input
                  type="text"
                  name="device_serial"
                  onChange={debounce((event) => handleTextSearch(event))}
                  placeholder="Search Device Serial"
                />
              </Form.Item>
            </Col>
            <Col md={8}>
              <Form.Item name="rating" style={{ marginBottom: '0' }}>
                <Select
                  showSearch
                  allowClear
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    option.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                  placeholder="Select Rating"
                  onChange={(value) => handleSelectSearch('rating', value)}
                >
                  {ratingsOptions}
                </Select>
              </Form.Item>
            </Col>
            <Col md={8}>
              <Form.Item name="sad_reason" style={{ marginBottom: '0' }}>
                <Select
                  showSearch
                  allowClear
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    option.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                  placeholder="Select Sad Reason"
                  onChange={(value) => handleSelectSearch('sad_reason', value)}
                >
                  {sadReasonsOptions}
                </Select>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </div>
      <div className="main-table-body-cus">
        <Table
          title={defaultTitle}
          loading={{
            indicator: <div className="table-loader">Fetching...</div>,
            spinning: isFetching,
          }}
          // rowSelection={{
          //   selectedRowKeys,
          //   preserveSelectedRowKeys: true,
          //   onChange: onSelectedRowKeysChange,
          // }}
          // expandable={{
          //   expandedRowRender: (record) => returnExpandableRow(record),
          // }}
          columns={columns}
          dataSource={devicesRatingList || []}
          size="small"
          onChange={handlePaginationChange}
          pagination={{
            defaultPageSize: 100,
            total: totalRecords,
          }}
          scroll={{ x: 500 }}
        />
      </div>
    </>
  );
};

export default DeviceRatingList;

const ENROLL_LABELS = {
  SR_NO: 'Serial No.',
  DEVICE_SERIAL: 'Device Serial',
  RATING: 'Rating',
  REASON: 'Sad Reason',
  RATED_DATE: 'Rated Date',
  RATED_TIME: 'Rated Time',
  RIDE_ID: "Ride Id",
  LOCATION: "Location Cordinates",
TAXI_NUMBER:"Taxi Number"
};

const headers = [
  {label:ENROLL_LABELS.SR_NO, key:ENROLL_LABELS.SR_NO},
  { label: ENROLL_LABELS.DEVICE_SERIAL, key: ENROLL_LABELS.DEVICE_SERIAL },
  { label: ENROLL_LABELS.TAXI_NUMBER, key: ENROLL_LABELS.TAXI_NUMBER },
  { label: ENROLL_LABELS.RATING, key: ENROLL_LABELS.RATING },
  { label: ENROLL_LABELS.REASON, key: ENROLL_LABELS.REASON },
  { label: ENROLL_LABELS.RATED_DATE, key: ENROLL_LABELS.RATED_DATE },
  { label: ENROLL_LABELS.RATED_TIME, key: ENROLL_LABELS.RATED_TIME },
  {label:ENROLL_LABELS.RIDE_ID, key:ENROLL_LABELS.RIDE_ID},
  {label:ENROLL_LABELS.LOCATION, key:ENROLL_LABELS.LOCATION},

];
