import React, { useState, useEffect } from 'react';
import {
  httpPost,
  httpGet,
  URLS,
  showErrorMsg,
  httpDelete,
  showSuccessMsg,
  httpPut,
} from 'utils';
import { UsersBox, Loader } from './_users.styled';
import { Button, Table } from 'antd';
import { DeleteOutlined, FormOutlined } from '@ant-design/icons';

import UserModal from 'components/user-modal/index';
import UserEditModal from 'components/user-edit-modal';

function Users() {
  const [isFetching, setIsFetching] = useState(false);
  const [campaignsList, setCampaignsList] = useState([]);
  const [isUserAdding, setIsUserAdding] = useState(false);
  const [existingUsersList, setExistingUsersList] = useState([]);
  const [invitedUsersList, setInvitedUsersList] = useState([]);
  const [userModalVisible, setUserModalVisible] = useState(false);
  const [ExistingUserModalVisible, setExistingUserModalVisible] =
    useState(false);
  const [isCampaignFetching, setIsCampaignFetching] = useState(false);
  const [isExistingUsersFetching, setIsExistingUsersFetching] = useState(false);
  const [existingUserId, setExistingUserId] = useState();

  useEffect(() => {
    fetchCampaignsList();
    fetchInvitedUsers();
    fetchExistingUsers();
  }, []);

  const fetchCampaignsList = () => {
    setIsCampaignFetching(true);
    const url = URLS.CAMPAIGN.CAMPAIGNS_LIST;
    httpGet(url)
      .then((res) => {
        if (res?.status === 200) {
          if (res?.data && res?.data.length > 0) {
            const campaignListData = res?.data.map((data) => {
              return {
                ...data,
                key: data?._id,
                campaign_name: data?.campaign_name || '',
              };
            });
            setCampaignsList(campaignListData || []);
          }
        }
        setIsCampaignFetching(false);
      })
      .catch(() => {
        setIsCampaignFetching(false);
      });
  };
  function generatePassword() {
    var length = 8,
      charset =
        'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789',
      retVal = '';
    for (var i = 0, n = charset.length; i < length; ++i) {
      retVal += charset.charAt(Math.floor(Math.random() * n));
    }
    return retVal;
  }
  const handleEditExistingUserModalCallback = ({ id, campaigns } = {}) => {
    let errorMsg = '';
    let successMsg = '';
    campaigns &&
      campaigns.length >= 1 &&
      campaigns.forEach((campaign, index) => {
        // Giving Access To Campaigns
        const giveAccessToCampaignUrl =
          URLS?.CAMPAIGN.ADD_USERID_TO_CAMPAIGN.replace(
            '#CAMPAIGN_ID#',
            campaign
          );
        httpPut(giveAccessToCampaignUrl, {
          accessible_to: id,
        })
          .then((res) => {
            if (res.status == 200) {
              fetchCampaignsList();
              fetchExistingUsers();
            }
            if (res?.error) {
              showErrorMsg(res?.error);
            }
            if (res?.status === 422 && res?.message) {
              errorMsg = res?.message;
              if (campaigns.length - 1 === index) {
                showErrorMsg(errorMsg);
              }
            }
            if (res?.status === 200 && res?.message) {
              successMsg = res?.message;
              if (campaigns.length - 1 === index) {
                showSuccessMsg(successMsg);
              }
            }
          })
          .catch((err) => {
            showErrorMsg(err);
          });
      });
    closeExistingUserModal();
  };
  const handleAddInvitedUser = ({ email, campaign } = {}) => {
    const params = {
      email: email || '',
      campaigns: campaign || '',
      verification_code: generatePassword() || '',
    };
    setIsUserAdding(true);
    httpPost(URLS?.INVITED_USER.ADD_INVITED_USER, params)
      .then((res) => {
        if (res.status === 200) {
          // showSuccessMsg(`Link sent to ${email} successfully!`);
          closeUserModal();
          fetchInvitedUsers();
        } else if (res?.error) {
          showErrorMsg(res?.error);
        }
        setIsUserAdding(false);
      })
      .catch((err) => {
        showErrorMsg(err);
        setIsUserAdding(false);
      });
  };

  const fetchInvitedUsers = () => {
    setIsFetching(true);
    const url = URLS?.INVITED_USER.INVITED_USERS_LIST;
    httpGet(url)
      .then((res) => {
        if (res?.status === 200) {
          if (res?.data && res?.data.length > 0) {
            const InvitedUsersList = res?.data.map((data) => {
              return {
                ...data,
                key: data?._id,
              };
            });
            setInvitedUsersList(InvitedUsersList || []);
          }
        }
        setIsFetching(false);
      })
      .catch((err) => {
        showErrorMsg(err);
        setIsFetching(false);
      });
  };
  const deleteExistingUser = (id) => {
    const deleteUserUrl = URLS?.AUTH.DELETE_USER.replace('#ID#', id);
    httpDelete(deleteUserUrl)
      .then((res) => {
        if (res?.status === 200) {
          showSuccessMsg('User Deleted Successfully');
          fetchExistingUsers();
        } else if (res?.error) {
          showErrorMsg(res?.error);
        }
      })
      .catch((err) => {
        showErrorMsg(err);
      });
  };

  const fetchExistingUsers = () => {
    setIsExistingUsersFetching(true);
    const url = URLS?.AUTH.USERS_LIST;
    httpGet(url)
      .then((res) => {
        if (res?.status === 200) {
          if (res?.data && res?.data.length > 0) {
            const existingUsersList = res?.data.map((data) => {
              return {
                ...data,
                key: data?._id,
              };
            });
            setExistingUsersList(existingUsersList || []);
          }
        }
        setIsExistingUsersFetching(false);
      })
      .catch((err) => {
        showErrorMsg(err);
        setIsExistingUsersFetching(false);
      });
  };
  const handleModeType = ({ activeMode, id }) => {
    setExistingUserId(id || '');
    setExistingUserModalVisible(activeMode);
  };
  const columns = [
    {
      key: 'email',
      title: USERS_LABELS.EMAIL,
      dataIndex: 'email',
    },
    {
      key: 'campaigns',
      title: USERS_LABELS.CAMPAIGNS,
      dataIndex: 'campaigns',
      render: (id) => {
        return (function Actions() {
          const accCampaigns = [];

          campaignsList.map((campaign) => {
            id.map((_id) => {
              if (campaign.key == _id) {
                accCampaigns.push(campaign.campaign_name);
              }
            });
          });
          const uniqueValue = accCampaigns.filter(
            (item, i, ar) => ar.indexOf(item) === i
          );
          return <>{uniqueValue.join(', ')}</>;
        })();
      },
    },
    {
      key: 'verification_code',
      title: USERS_LABELS.INVITE_LINK,
      dataIndex: 'verification_code',
      render: (code, row) => {
        let link = `https://binaryreporting.netlify.app/signup?email=${row.email}&code=${code}`;
        return (function Actions() {
          return (
            <div>
              <Button
                style={{ marginLeft: '10px' }}
                onClick={() => {
                  navigator.clipboard.writeText(link);
                  showSuccessMsg('Link copied successfully');
                }}
              >
                Copy Link
              </Button>
            </div>
          );
        })();
      },
    },
  ];
  const existingUsersColumn = [
    {
      key: 'email',
      title: EXISTING_USERS_LABELS.EMAIL,
      dataIndex: 'email',
    },
    {
      key: '_id',
      title: EXISTING_USERS_LABELS.CAMPAIGNS,
      dataIndex: '_id',
      render: (_id) => {
        return (function Actions() {
          if (
            existingUsersList.filter((x) => x._id == _id)[0].user_type ==
            'superadmin'
          ) {
            return <span style={{ paddingRight: '1%' }}>Superadmin</span>;
          } else {
            const accCampaigns = [];
            campaignsList.map((campaign) => {
              campaign.accessible_to.map((accessibleUser) => {
                if (accessibleUser == _id) {
                  accCampaigns.push(campaign.campaign_name);
                }
              });
            });
            const uniqueValue = accCampaigns.filter(
              (item, i, ar) => ar.indexOf(item) === i
            );
            return <>{uniqueValue.join(', ')}</>;
          }
        })();
      },
    },
    {
      title: EXISTING_USERS_LABELS.EDIT,

      render: (id, row) => {
        return (function Actions() {
          if (
            existingUsersList.filter((x) => x._id == row._id)[0].user_type ==
            'superadmin'
          ) {
            return <></>;
          } else {
            return (
              <Button
                onClick={() =>
                  handleModeType({
                    activeMode: true,
                    id: row._id,
                  })
                }
              >
                <FormOutlined></FormOutlined>
              </Button>
            );
          }
        })();
      },
    },
    {
      title: EXISTING_USERS_LABELS.DELETE,

      render: (id, row) => {
        return (function Actions() {
          if (
            existingUsersList.filter((x) => x._id == row._id)[0].user_type ==
            'superadmin'
          ) {
            return <></>;
          } else {
            return (
              <Button onClick={() => deleteExistingUser(row._id)}>
                <DeleteOutlined></DeleteOutlined>
              </Button>
            );
          }
        })();
      },
    },
  ];
  const openAddUserModal = () => {
    setUserModalVisible(true);
  };

  const closeUserModal = () => {
    setUserModalVisible(false);
  };
  const closeExistingUserModal = () => {
    setExistingUserId('');
    setExistingUserModalVisible(false);
  };

  return (
    <UsersBox>
      <div className="table-header">
        <h2 className="table-title">Invited Users</h2>
        <div className="table-right-side"></div>
      </div>
      <div className="add-user-btn">
        <Button type="primary" onClick={openAddUserModal}>
          Invite User
        </Button>
        <UserModal
          campaignsList={campaignsList}
          isFetching={isCampaignFetching}
          handleAddInvitedUser={handleAddInvitedUser}
          isUserAdding={isUserAdding}
          userModalVisible={userModalVisible}
          modalTitle="Add User"
          closeUserModal={closeUserModal}
        />
      </div>

      <div className="main-table-body">
        <Table
          loading={{
            indicator: <Loader>Fetching...</Loader>,
            spinning: isFetching,
          }}
          columns={columns}
          dataSource={invitedUsersList || []}
          size="small"
          pagination={false}
        />
      </div>
      <br />
      <h2 className="table-title">Existing Users</h2>
      <div className="main-table-body">
        <Table
          loading={{
            indicator: <Loader>Fetching...</Loader>,
            spinning: isExistingUsersFetching,
          }}
          columns={existingUsersColumn}
          dataSource={existingUsersList || []}
          size="small"
          pagination={false}
        />

        <UserEditModal
          callback={handleEditExistingUserModalCallback}
          isFetching={isCampaignFetching}
          campaignsList={campaignsList}
          userModalVisible={ExistingUserModalVisible}
          modalTitle="Edit User"
          docId={existingUserId}
          closeUserModal={closeExistingUserModal}
        />
      </div>
    </UsersBox>
  );
}

export default Users;
const USERS_LABELS = {
  EMAIL: 'Email',
  CAMPAIGNS: 'Campaigns ',
  INVITE_LINK: 'Invite Link',
};
const EXISTING_USERS_LABELS = {
  EMAIL: 'Email',
  CAMPAIGNS: 'Campaigns ',
  EDIT: 'Edit',
  DELETE: 'Delete',
};
