/**
 * set user data in local storage
 */
export const setUserLocalStorage = (userData) => {
  if (userData) {
    localStorage.setItem('user', JSON.stringify(userData));
  }
};

/**
 * set user data from local storage
 */
export const getUserLocalStorage = () => {
  const user = JSON.parse(localStorage.getItem('user'));
  const isValidatedToken = validateToken(user);
  if (isValidatedToken) {
    return user || '';
  } else {
    return '';
  }
};

/**
 * validate token
 */
export const validateToken = (user) => {
  if (user?.token) {
    const splittedToken = user?.token.split('.');
    if (splittedToken.length > 1) {
      // const decodedToken = JSON.parse(atob(splittedToken[1]));
      // if (Date.now() > (decodedToken?.exp || 0) * 1000) {
      //   removeUser();
      // } else {
      return true;
      // }
    }
  }
  return false;
};

/**
 * remove user
 */
export const removeUser = () => {
  localStorage.removeItem('user');
};

/**
 * set android managment data in local storage
 */
export const setAndroidManagementLocalStorage = (androidManagement) => {
  if (androidManagement) {
    localStorage.setItem(
      'androidManagement ',
      JSON.stringify(androidManagement)
    );
  }
};

/**
 * set android management data from local storage
 */
export const getandroidManagementLocalStorage = () => {
  const androidManagement = JSON.parse(
    localStorage.getItem('androidManagement')
  );
  return androidManagement || '';
};

/**
 * set android management auth token data in local storage
 */
export const setAMAAuthTokenLocalStorage = (authTokenAMA) => {
  if (authTokenAMA) {
    localStorage.setItem('AuthTokenAMA', JSON.stringify(authTokenAMA));
  }
};

/**
 * get android management auth token data from the local storage
 */
export const getAMAAuthTokenLocalStorage = () => {
  const authTokenAMA = JSON.parse(localStorage.getItem('AuthTokenAMA'));
  return authTokenAMA || '';
};

/**
 * set google cloud storage management auth token data in local storage
 */
export const setGCloudStorageAuthTokenLocalStorage = (authTokenGCStorage) => {
  if (authTokenGCStorage) {
    localStorage.setItem(
      'AuthTokenGCStorage',
      JSON.stringify(authTokenGCStorage)
    );
  }
};

/**
 * get google cloud storage management auth token data from the local storage
 */
export const getGCloudStorageAuthTokenLocalStorage = () => {
  const authTokenGCStorage = JSON.parse(
    localStorage.getItem('AuthTokenGCStorage')
  );
  return authTokenGCStorage || '';
};

/**
 * get logged in user auth token from local storage
 */
export const getUserAuthTokenLocalStorage = () => {
  const user = JSON.parse(localStorage.getItem('user'));
  const isValidatedToken = validateToken(user);
  if (isValidatedToken && user?.token) {
    return user.token || '';
  } else {
    return '';
  }
};
