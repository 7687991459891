import styled from 'styled-components';

export const ProfileBox = styled.div`
  padding: 20px;
  .profile-content {
    padding: 20px 10px;
    background-color: white;
    margin-bottom: 20px;
  }
`;
