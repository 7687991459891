import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import Store from 'hooks/store';
import ReactDOM from 'react-dom';
import App from './App';
import { ThemeProvider } from 'styled-components';
import reportWebVitals from './reportWebVitals';
import 'antd/dist/antd.css';
import 'assets/css/admin-style.css';

ReactDOM.render(
  <Store>
    <ThemeProvider theme={{}}>
      <Router>
        <App />
      </Router>
    </ThemeProvider>
  </Store>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
