/* eslint-disable react/prop-types */
import React, { useState, useRef, useEffect } from 'react';
import moment from 'moment';
import YellowCar from 'assets/images/yellow-car.png';
import GreenCar from '../../assets/images/green-car.png';
import { MapBoxWindow } from './_single-device-mapbox.styled';
import mapboxgl from '!mapbox-gl'; // eslint-disable-line import/no-webpack-loader-syntax
import 'mapbox-gl/dist/mapbox-gl.css';

mapboxgl.accessToken =
  process.env.REACT_APP_MAPBOX_PUBLIC_KEY ||
  'pk.eyJ1IjoiYmluYXJ5YWRtaW4iLCJhIjoiY2t6Mmw4ODNhMDFsMjJ2cWYweTFyb2ZuZyJ9.iBSQIqW6qM_KMV9S_ufb-w';

const SingleDeviceMapBox = (props) => {
  const { location } = props || {};
  // const { activeDevices } = props || {};
  // const { selectedCampaign } = props || {};

  const mapContainer = useRef(null);
  const map = useRef(null);
  const [initialCenter] = useState({
    lng: location?.current_lng || 55.2708,
    lat: location?.current_lat || 25.2048,
  });
  const [zoom] = useState(12);
  const [mapSourceLoaded, setMapSourceLoaded] = useState(false);

  const carImages = [
    { url: YellowCar, id: 'YellowCar' },
    { url: GreenCar, id: 'GreenCar' },
  ];

  useEffect(() => {
    if (!map.current) {
      map.current = new mapboxgl.Map({
        container: mapContainer.current,
        style: 'mapbox://styles/binaryadmin/ckzco0u9b004314rwv3tzkcn8',
        center: initialCenter, // starting position [lng, lat]
        zoom: zoom,
      });
    } // initialize map only once

    if (map.current) {
      map.current.addControl(new mapboxgl.NavigationControl(), 'top-right');
      map.current.addControl(new mapboxgl.FullscreenControl());
      // // disable map rotation using right click + drag
      // map.current.dragRotate.disable();
      // // disable map rotation using touch rotation gesture
      // map.current.touchZoomRotate.disableRotation();
    }

    return () => map.current.remove();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (map.current && map.current.loaded()) {
      setMapBoxSourceAndData(location);
    } else {
      map.current.on('load', () => {
        setMapBoxSourceAndData(location);
      });
    }
  }, [map.current]); // eslint-disable-line react-hooks/exhaustive-deps

  const setMapBoxSourceAndData = (locationData) => {
    const geojson = getUpdatedLocationData(locationData);

    // Add the layers to the map as a source.
    Promise.all(
      carImages.map(
        (img) =>
          new Promise((resolve, reject) => {
            map.current.loadImage(img.url, function (error, imgResolution) {
              if (error) return reject(error);
              if (!map.current.hasImage(img.id)) {
                map.current.addImage(img.id, imgResolution);
              }
              resolve();
            });
          })
      )
    ).then(() => {
      if (!map.current || map.current.getSource('live-view')) return;

      if (!map.current.getSource('live-view')) {
        map.current.addSource('live-view', {
          type: 'geojson',
          data: geojson,
        });
      }

      map.current.addLayer({
        id: 'live-view',
        type: 'symbol',
        source: 'live-view', // reference the data source
        layout: {
          'icon-image': '{icon}',
          'icon-allow-overlap': true,
          'icon-size': 0.5,
          'icon-rotate': ['get', 'bearing'],
          'icon-rotation-alignment': 'map',
          'icon-ignore-placement': true,
        },
      });
      setMapSourceLoaded(true);
    });

    map.current.on('click', 'live-view', (e) => {
      const coordinates = e.features[0].geometry.coordinates.slice();
      const description = `<h6> Device Serial: <b> ${
        e?.features[0]?.properties?.device_serial
      }</b></h6><h6> Last Update: <b> ${
        e?.features[0]?.properties?.updated_at &&
        moment(e?.features[0]?.properties?.updated_at).format(
          'DD MMMM YYYY hh:mm A'
        )
      }</b></h6>`;

      // Ensure that if the map is zoomed out such that multiple
      // copies of the feature are visible, the popup appears
      // over the copy being pointed to.
      while (Math.abs(e.lngLat.lng - coordinates[0]) > 180) {
        coordinates[0] += e.lngLat.lng > coordinates[0] ? 360 : -360;
      }

      new mapboxgl.Popup()
        .setLngLat(coordinates)
        .setHTML(description)
        .addTo(map.current);
    });

    // Change the cursor to a pointer when the mouse is over the places layer.
    map.current.on('mouseenter', 'live-view', () => {
      map.current.getCanvas().style.cursor = 'pointer';
    });

    // Change it back to a pointer when it leaves.
    map.current.on('mouseleave', 'live-view', () => {
      map.current.getCanvas().style.cursor = '';
    });
  };

  useEffect(() => {
    if (location && Object.keys(location).length >= 1 && mapSourceLoaded) {
      const updatedLocationData = getUpdatedLocationData(location);
      map.current.getSource('live-view').setData(updatedLocationData);
      if (location?.current_lng && location?.current_lat) {
        map.current.flyTo({
          center: [location?.current_lng, location?.current_lat],
          speed: 0.5,
        });
      }
    }
  }, [location, mapSourceLoaded]); // eslint-disable-line react-hooks/exhaustive-deps

  const getUpdatedLocationData = (locationsData) => {
    return {
      type: 'FeatureCollection',
      features: [
        {
          type: 'Feature',
          geometry: {
            type: 'Point',
            coordinates: [
              locationsData?.current_lng,
              locationsData?.current_lat,
            ],
          },
          properties: {
            ...(locationsData?.batteryPercent && {
              batteryPercent: locationsData?.batteryPercent > 1 || 1,
            }),
            ...(locationsData?.binaryCode && {
              binaryCode: locationsData?.binaryCode,
            }),
            ...(locationsData?.device_serial && {
              device_serial: locationsData?.device_serial,
            }),
            ...(locationsData?.wifiName && {
              wifiName: locationsData?.wifiName,
            }),
            ...(locationsData?.isCharging && {
              isCharging: locationsData?.isCharging,
            }),
            ...(locationsData?.screenStatus && {
              screenStatus: locationsData?.screenStatus,
            }),
            ...(locationsData?.updated_at && {
              updated_at: locationsData?.updated_at,
            }),
            ...(locationsData?.bearing && {
              bearing: locationsData?.bearing,
            }),
            icon: getCarIcon(locationsData),
          },
        },
      ],
    };
  };

  const getCarIcon = (locationData) => {
    // const { deviceSerial } = locationData;
    let currentCarIcon = 'YellowCar';
    // if (activeDevices && selectedCampaign && activeDevices[selectedCampaign]) {
    //   let devices = activeDevices[selectedCampaign];
    //   let objEntries = Object.entries(devices);
    //   objEntries.map((x) => {
    //     if (x[0] == deviceSerial && x[1].is_ad_running) {
    //       currentCarIcon = 'GreenCar';
    //     }
    //   });
    // }
    return currentCarIcon;
  };

  return (
    <MapBoxWindow>
      <div
        ref={mapContainer}
        className="map-container"
        style={{ height: `100%`, width: `100%` }}
      />
    </MapBoxWindow>
  );
};

export default SingleDeviceMapBox;
