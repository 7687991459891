import React, { useState, useEffect, useContext } from 'react';
import {
  Dropdown,
  Menu,
  Skeleton,
  Button,
  Row,
  Col,
  Popconfirm,
  Card,
  Form,
  Switch,
  Badge,
  Tag,
} from 'antd';
import { useParams, useHistory } from 'react-router-dom';
import { DeviceDetailPageBox } from './_device-detail-page.styled';
import {
  WifiOutlined,
  MobileOutlined,
  AudioOutlined,
  AudioMutedOutlined,
  UsbOutlined,
  UnlockOutlined,
  LockOutlined,
  BulbOutlined,
  SoundOutlined,
  DownOutlined,
} from '@ant-design/icons';
import {
  formatDate,
  showErrorMsg,
  httpGet,
  URLS_V2,
  getUserAuthTokenLocalStorage,
  showSuccessMsg,
  calcHoursFromCurrentDateTime,
} from 'utils';
import { DEVICE_BULK_ACTION_LIST_V2 } from 'constants/devices';
import DeviceInfo from './device-info';
import DeviceLocation from './device-location';
import RidesInfo from './rides-info';
import StatisticCardBlock from 'components/statistic-card-block';
import DeviceRides from './device-rides';
import CommonBreadCrumb from 'components/common-breadcrumb';
import SocketContext from 'SocketContext';
import BinaryOsInfo from './binary-os-info';

const SocketDeviceDetailPage = () => {
  const { socket } = useContext(SocketContext);

  const authToken = getUserAuthTokenLocalStorage();

  const params = useParams();
  const [rideInfoForm] = Form.useForm();

  const [deviceDetailsFB, setDeviceDetailsFB] = useState({});
  const [deviceDetails, setDeviceDetails] = useState({});
  const [isFetchingFB, setIsFetchingFB] = useState(true);
  const [isFetching, setIsFetching] = useState(true);
  const [isRideInfoFormSubmitting, setIsRideInfoFormSubmitting] =
    useState(false);
  const [rideInfoInlineEdit, setRideInfoInlineEdit] = useState(false);
  const [deviceSerial, setDeviceSerial] = useState('');

  let history = useHistory();

  useEffect(() => {
    if (!params?.device_serial) {
      // redirect to the back page from here
      handleHistoryToGoBack();
    } else {
      const deviceSerialNo = params?.device_serial;
      setDeviceSerial(deviceSerialNo);
      fetchDeviceAndRideDetails(deviceSerialNo);
      fetchDeviceStatusDetails(deviceSerialNo);

      socket.on('device_details' + deviceSerialNo, deviceDetailsListener);

      socket.on(
        'device_command_received' + deviceSerialNo,
        deviceCommandListener
      );

      socket.on(
        'device_info_changed' + deviceSerialNo,
        deviceInfoChangesListener
      );

      // setInterval(() => {
      //   socket.emit('request', {
      //     endPoint: '/auth/device-details',
      //     authToken: authToken,
      //     device_serial: deviceSerialNo,
      //   });
      // }, 10000);

      return () => {
        socket.off('device_details' + deviceSerialNo, deviceDetailsListener);
        socket.off(
          'device_command_received' + deviceSerialNo,
          deviceCommandListener
        );
        socket.off(
          'device_info_changed' + deviceSerialNo,
          deviceInfoChangesListener
        );
      };
    }
  }, []);

  const deviceDetailsListener = (data) => {
    if (
      data &&
      data?.responseData &&
      Object.keys(data?.responseData).length >= 1
    ) {
      setDeviceDetailsFB((prev) => ({
        ...modifyDeviceDetails(prev, data?.responseData),
      }));
      setIsFetchingFB(false);
    }
  };

  const deviceCommandListener = (data) => {
    if (data?.status === 200) {
      showSuccessMsg('Command executed successfully!');
    } else {
      showErrorMsg('Internal server error. Please try again later');
    }
  };

  const deviceInfoChangesListener = (data) => {
    if (data?.status === 200) {
      setDeviceDetails({
        ...deviceDetails,
        ...data?.responseData,
      });
      setRideInfoInlineEdit(false);
      setIsRideInfoFormSubmitting(false);
      showSuccessMsg('Device details updated successfully!');
    } else {
      showErrorMsg('Internal server error. Please try again later');
    }
  };

  const fetchDeviceStatusDetails = (deviceSerialNo) => {
    setIsFetchingFB(true);
    socket.emit('onJoin', {
      device_serial: deviceSerialNo,
    });
    socket.emit('request', {
      device_serial: deviceSerialNo,
      authToken: authToken,
      endPoint: URLS_V2?.DEVICES_SOCKET?.LISTEN_DEVICE_DETAILS,
    });
  };

  const breakCrumbList = [
    { label: 'Home', link: '/dashboard' },
    { label: 'Devices', link: '/v2/devices' },
    { label: deviceSerial || '' },
  ];

  const modifyDeviceDetails = (prevState, currentData) => {
    return {
      ...prevState,
      ...currentData,
      current_lat:
        currentData?.location?.coordinates?.[0] !== undefined
          ? currentData?.location?.coordinates?.[0]
          : prevState?.current_lat,
      current_lng:
        currentData?.location?.coordinates?.[1] !== undefined
          ? currentData?.location?.coordinates?.[1]
          : prevState?.current_lng,
      ...(currentData?.battery_percent !== undefined && {
        batteryPercent: batteryIconRender(
          currentData?.battery_percent.toString()
        ),
      }),
      ...(currentData?.brightness_level !== undefined && {
        brightnessLevel: brightnessIconRender(
          currentData?.brightness_level.toString()
        ),
      }),
      ...(currentData?.volume_level !== undefined && {
        volumeLevel: volumeIconRender(currentData?.volume_level.toString()),
      }),
      ...(currentData?.is_charging !== undefined && {
        isCharging: chargingIconRender(
          currentData?.is_charging,
          currentData?.battery_percent.toString()
        ),
      }),
      ...(currentData?.is_mute !== undefined && {
        isMute: muteIconRender(currentData?.is_mute),
      }),
      ...(currentData?.is_screen_on !== undefined && {
        isScreenOn: screenOnIconRender(currentData?.is_screen_on),
      }),
      ...(currentData?.is_sleep !== undefined && {
        isSleep: sleepIconRender(currentData?.is_sleep),
      }),
      ...(currentData?.is_wifi_on !== undefined && {
        isWiFiOn: wifiIconRender(
          currentData?.is_wifi_on,
          currentData?.required_wifi_name
        ),
      }),
      // isBlueToothOn: bluetoothIconRender(
      //   currentData?.is_bluetooth_on ? currentData?.is_bluetooth_on : false
      // ),
      ...(currentData?.last_charging_status_change !== undefined && {
        lastChargedAt: formatDate(currentData?.last_charging_status_change),
      }),
    };
  };

  const fetchDeviceAndRideDetails = (deviceSerialNo) => {
    setIsFetching(true);
    const url = `${URLS_V2?.DEVICES?.GET_DEVICE_DETAILS}?device_serial=${deviceSerialNo}`;
    httpGet(url)
      .then((res) => {
        if (res?.status === 200) {
          if (res?.data) {
            setDeviceDetails(res?.data || []);
          }
        } else if (res?.status === 404) {
          handleHistoryToGoBack();
        }
        setIsFetching(false);
      })
      .catch((err) => {
        setIsFetching(false);
      });
  };

  const handleHistoryToGoBack = () => {
    // redirect to the back page from here
    showErrorMsg('Device details not found');
    history.goBack();
  };

  const muteIconRender = (value) => {
    return (
      <>
        {value ? (
          <>
            <AudioMutedOutlined
              style={{
                fontSize: '18px',
                color: '#ff4d4f',
                marginRight: '0.5rem',
              }}
            />
            {`Mute`}
          </>
        ) : (
          <>
            <AudioOutlined
              style={{
                fontSize: '18px',
                color: '#52c41a',
                marginRight: '0.5rem',
              }}
            />
            {`Unmute`}
          </>
        )}
      </>
    );
  };

  const wifiIconRender = (value, wifiName) => {
    return (
      <>
        <WifiOutlined
          style={{
            fontSize: '18px',
            color: value ? '#52c41a' : '#ff4d4f',
            marginRight: '0.5rem',
          }}
        />
        {value ? wifiName : 'Off'}
      </>
    );
  };

  // const activeInactiveIconRender = (value) => {
  //   return (
  //     <>
  //       {value ? (
  //         <Tag color="success">Active</Tag>
  //       ) : (
  //         <Tag color="error">Inactive</Tag>
  //       )}
  //     </>
  //   );
  // };

  // const bluetoothIconRender = (value) => {
  //   return (
  //     <>
  //       <img src={BlueToothIcon} />
  //     </>
  //   );
  // };
  const chargingIconRender = (value, relatedValue = null) => {
    return (
      <>
        <UsbOutlined
          style={{
            fontSize: '18px',
            color: value ? '#52c41a' : '#ff4d4f',
            marginRight: '0.5rem',
          }}
        />
        {value ? 'Charging' : 'Not Charging'}
        <span className="m-l-5">
          {relatedValue &&
            `(${
              relatedValue?.includes('%') ? relatedValue : relatedValue + '%'
            })`}
        </span>
      </>
    );
  };
  const sleepIconRender = (value) => {
    return (
      <>
        <MobileOutlined
          style={{
            fontSize: '18px',
            color: value ? '#ff4d4f' : '#52c41a',
            marginRight: '0.5rem',
          }}
        />
        {value ? 'Sleep' : 'Working'}
      </>
    );
  };

  const screenOnIconRender = (value) => {
    return (
      <>
        {value ? (
          <>
            <UnlockOutlined
              style={{
                fontSize: '18px',
                color: value ? '#52c41a' : '#ff4d4f',
                marginRight: '0.5rem',
              }}
            />
            {'Unlocked'}
          </>
        ) : (
          <>
            <LockOutlined
              style={{
                fontSize: '18px',
                color: value ? '#52c41a' : '#ff4d4f',
                marginRight: '0.5rem',
              }}
            />
            {'Locked'}
          </>
        )}
      </>
    );
  };

  const batteryIconRender = (value) => {
    return (
      <>
        <UsbOutlined
          style={{
            fontSize: '1rem',
            color: '#faad14',
            marginRight: '0.5rem',
          }}
        />
        {`${value?.includes('%') ? value : value + '%'}`}
      </>
    );
  };

  const brightnessIconRender = (value) => {
    return (
      <>
        <BulbOutlined
          style={{
            fontSize: '1rem',
            color: '#faad14',
            marginRight: '0.5rem',
          }}
        />
        {`${value >= 0 && value?.includes('%') ? value : value + '%'}`}
      </>
    );
  };

  const volumeIconRender = (value) => {
    return (
      <>
        <SoundOutlined
          style={{
            fontSize: '1rem',
            color: '#faad14',
            marginRight: '0.5rem',
          }}
        />
        {`${value >= 0 && value.includes('%') ? value : value + '%'}`}
      </>
    );
  };

  const handleRideInfoFormSubmit = () => {
    rideInfoForm.submit();
    rideInfoForm
      .validateFields()
      .then(async (values) => {
        setIsRideInfoFormSubmitting(true);
        emitDeviceInfoUpdateSocket(values);
      })
      .catch((errorInfo) => errorInfo);
  };

  const emitDeviceInfoUpdateSocket = (values) => {
    socket.emit('request', {
      authToken: authToken,
      endPoint: URLS_V2?.DEVICES_SOCKET?.EMIT_UPDATED_DEVICE_RIDE_DETAILS,
      device_serial: deviceSerial,
      ...values,
    });
  };

  const renderStatisticDivBlock = (
    title,
    value,
    isEditable = false,
    command = ''
  ) => {
    return (
      <>
        <h3>{title}</h3>
        <Skeleton
          loading={isFetchingFB}
          active
          paragraph={{ rows: 1, width: '100%' }}
          title={false}
        >
          {isEditable ? (
            <div
              style={{
                cursor: 'pointer',
              }}
            >
              <Popconfirm
                title={<>{`Are you sure you want to perform this action?`}</>}
                onConfirm={() => handleDeviceCommands(command)}
                key={value}
              >
                <Switch checked={value} />
              </Popconfirm>
            </div>
          ) : (
            <div>{value}</div>
          )}
        </Skeleton>
      </>
    );
  };

  const menu = (
    <Menu>
      {DEVICE_BULK_ACTION_LIST_V2 &&
        DEVICE_BULK_ACTION_LIST_V2.length > 0 &&
        DEVICE_BULK_ACTION_LIST_V2.map((item) => (
          <Popconfirm
            title={<>{`Are you sure you want to perform this action?`}</>}
            onConfirm={() => handleDeviceCommands(item.command)}
            key={item.value}
          >
            <Menu.Item key={item.value} icon={React.createElement(item?.icon)}>
              {item.label}
            </Menu.Item>
          </Popconfirm>
        ))}
    </Menu>
  );

  const handleDeviceCommands = (commandType) => {
    socket.emit('request', {
      authToken: authToken,
      device_serial: deviceSerial,
      endPoint: URLS_V2?.DEVICES_SOCKET?.EMIT_DEVICE_COMMANDS,
      type: 'individual',
      ...commandType,
    });
  };

  const renderRidesStatisticsCard = (
    cardTitle,
    totalRide,
    totalRideTime,
    totalRideAvgTime
  ) => {
    return (
      <Card title={cardTitle} bordered={false}>
        <Skeleton
          loading={isFetching}
          active
          paragraph={{ rows: 2, width: '100%' }}
          title={false}
        >
          <Row gutter={32}>
            <Col span={6}>
              <StatisticCardBlock
                title={'total rides'}
                value={(totalRide && totalRide.toString()) || '0'}
                valueStyle={{ color: '#3f8600' }}
              />
            </Col>
            <Col span={9}>
              <StatisticCardBlock
                title="total ride time minutes"
                value={(totalRideTime && totalRideTime.toString()) || '0'}
                precision={0}
                valueStyle={{ color: '#3f8600' }}
              />
            </Col>
            <Col span={9}>
              <StatisticCardBlock
                title="avg per day minutes"
                value={(totalRideAvgTime && totalRideAvgTime.toString()) || '0'}
                precision={0}
                valueStyle={{ color: '#3f8600' }}
              />
            </Col>
          </Row>
        </Skeleton>
      </Card>
    );
  };

  const updateDeviceStatusInfo = (deviceCurrentStatus) => {
    const requestData = {
      is_device_active: !deviceCurrentStatus,
    };
    emitDeviceInfoUpdateSocket(requestData);
  };

  return (
    <DeviceDetailPageBox>
      <CommonBreadCrumb breadCrumbs={breakCrumbList} />
      <div className="table-header">
        <div>
          <h2 className="table-title m-b-0 d-d-title">
            {deviceDetailsFB?.updated_at &&
            calcHoursFromCurrentDateTime(deviceDetailsFB?.updated_at) <= 1 ? (
              <Badge
                status="success"
                title="Online"
                className="details-badge"
              />
            ) : (
              <Badge status="error" title="Offline" className="details-badge" />
            )}
            {deviceSerial}
          </h2>
          <label className="table-title">{deviceDetailsFB?.sim_serial}</label>
        </div>
        <div className="table-right-side">
          <div className="export-generate-btn">
            {!isFetching && (
              <>
                {deviceDetailsFB?.updated_at &&
                calcHoursFromCurrentDateTime(deviceDetailsFB?.updated_at) <=
                  1 ? (
                  <Tag className="m-r-10" color="success">
                    Online
                  </Tag>
                ) : (
                  <Tag className="m-r-10" color="error">
                    Offline
                  </Tag>
                )}
                {/* <span className="m-r-10" color="font-dark">
                  Active/Inactive:
                </span> */}
                {/* {deviceDetails.is_device_active ? (
                ) : (
                  <Typography.Text className="m-r-10" color="error">
                    Inactive
                  </Typography.Text>
                )} */}
                <Popconfirm
                  title={<>{`Are you sure you want to perform this action?`}</>}
                  onConfirm={() =>
                    updateDeviceStatusInfo(deviceDetails.is_device_active)
                  }
                  key={deviceDetails.is_device_active}
                >
                  <Switch
                    checked={deviceDetails.is_device_active}
                    className={`${
                      deviceDetails.is_device_active
                        ? 'bg-success'
                        : 'bg-danger'
                    }`}
                    checkedChildren="Active"
                    unCheckedChildren="Inactive"
                  />
                </Popconfirm>
                <Dropdown overlay={menu} trigger={['click']} className="m-l-10">
                  <Button>
                    {'Commands'} <DownOutlined />
                  </Button>
                </Dropdown>
              </>
            )}
          </div>
        </div>
      </div>
      <div className="site-statistic-demo-card c-m-10">
        <Row gutter={14}>
          <Col span={8}>
            {renderRidesStatisticsCard(
              'Last 12 Hours',
              deviceDetails?.last12HRRides?.total_ride,
              deviceDetails?.last12HRRides?.total_ride_time / 60,
              deviceDetails?.last12HRRides?.avg_ride_time / 60
            )}
          </Col>
          <Col span={8}>
            {renderRidesStatisticsCard(
              'Last 7 Days',
              deviceDetails?.last7DaysRides?.total_ride,
              deviceDetails?.last7DaysRides?.total_ride_time / 60,
              deviceDetails?.last7DaysRides?.avg_ride_time / 60
            )}
          </Col>
          <Col span={8}>
            {renderRidesStatisticsCard(
              'Last 30 Days',
              deviceDetails?.last30DaysRides?.total_ride,
              deviceDetails?.last30DaysRides?.total_ride_time / 60,
              deviceDetails?.last30DaysRides?.avg_ride_time / 60
            )}
          </Col>
        </Row>
      </div>
      <div className="device-details-header">
        {/* <div>
          {renderStatisticDivBlock('Battery', deviceDetailsFB?.batteryPercent)}
        </div> */}
        {/* <div className="vr-solid" /> */}
        <div>
          {renderStatisticDivBlock('Charging', deviceDetailsFB?.isCharging)}
        </div>
        <div className="vr-solid" />
        <div>
          {renderStatisticDivBlock('Screen', deviceDetailsFB?.isScreenOn)}
        </div>
        {/* <div className="vr-solid" /> */}
        {/* <div>
          {renderStatisticDivBlock(
            'Brightness',
            deviceDetailsFB?.brightnessLevel
          )}
        </div> */}
        <div className="vr-solid" />
        <div>
          {renderStatisticDivBlock('Sleep', deviceDetailsFB?.is_sleep, true, {
            is_sleep: !deviceDetailsFB?.is_sleep,
          })}
        </div>
        <div className="vr-solid" />
        <div>
          {renderStatisticDivBlock('Mute', deviceDetailsFB?.is_mute, true, {
            is_mute: !deviceDetailsFB?.is_mute,
          })}
        </div>
        <div className="vr-solid" />
        <div>
          {renderStatisticDivBlock('WiFi', deviceDetailsFB?.is_wifi_on, true, {
            is_wifi_on: !deviceDetailsFB?.is_wifi_on,
          })}
        </div>
        {/* <div className="vr-solid" />
        <div>
          {renderStatisticDivBlock(
            'Bluetooth',
            deviceDetailsFB?.isBlueToothOn,
            {
              is_bluetooth_on: !deviceDetailsFB?.is_bluetooth_on,
            }
          )}
        </div> */}
        {/* <div className="vr-solid" />
        <div>
          {renderStatisticDivBlock('Status', deviceDetailsFB?.isDeviceActive, {
            is_device_active: !deviceDetailsFB?.is_device_active,
          })}
        </div> */}
      </div>
      <div>
        <Row gutter={16}>
          <Col className="gutter-row" xs={24} sm={12}>
            <DeviceInfo
              handleDeviceCommands={handleDeviceCommands}
              deviceDetailsFB={deviceDetailsFB}
              isFetching={isFetchingFB}
            />
            <BinaryOsInfo
              handleDeviceCommands={handleDeviceCommands}
              deviceDetailsFB={deviceDetailsFB}
              isFetching={isFetchingFB}
            />
            <RidesInfo
              deviceDetails={deviceDetails}
              isFetching={isFetching}
              handleRideInfoFormSubmit={handleRideInfoFormSubmit}
              isRideInfoFormSubmitting={isRideInfoFormSubmitting}
              setIsRideInfoFormSubmitting={setIsRideInfoFormSubmitting}
              rideInfoInlineEdit={rideInfoInlineEdit}
              setRideInfoInlineEdit={setRideInfoInlineEdit}
              rideInfoForm={rideInfoForm}
            />
          </Col>
          <Col className="gutter-row" xs={24} sm={12}>
            <DeviceLocation
              deviceDetailsFB={deviceDetailsFB}
              isFetching={isFetchingFB}
            />
          </Col>
        </Row>
        <Row>
          <Col className="gutter-row" xs={24} sm={24}>
            <DeviceRides
              deviceDetails={deviceDetails}
              isFetching={isFetching}
            />
          </Col>
        </Row>
      </div>
    </DeviceDetailPageBox>
  );
};

export default SocketDeviceDetailPage;
