import React, { useState } from 'react';
import { oneOfType, array, func, object, string } from 'prop-types';
import { CloseOutlined } from '@ant-design/icons';
import LocationsMap from 'components/locations-map';
import { showErrorMsg } from 'utils';
import { Form, Row, Col, Input, Collapse, Tag, Select } from 'antd';
import { CollapseBox } from '../_calc-geofence.styled';
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from 'react-places-autocomplete';
import {
  WhereToTargetBox,
  LocationSearchBox,
  SelectedPlacesBox,
} from './_where-to-target.styled';
import { uuid } from 'uuidv4';

const { Panel } = Collapse;
const { Option } = Select;

const LOCATIONS_LIMIT = 19;

const WhereToTarget = (props) => {
  const {
    handleValidateFieldNames,
    setValidateFieldsName,
    validateFieldsName,
    locations,
    setLocations,
    currentAddress,
    setCurrentAddress,
    campaignLocations,
  } = props;
  const [isLocationAddedOrRemoved, setIsLocationAddedOrRemoved] = useState(
    Math.random()
  );

  const handleChange = (newAddress) => {
    handleDifferentAddresses({ selectedAddress: newAddress });
  };

  const handleSelectAddress = async (selectedAddress) => {
    const isProceed = handleDifferentAddresses({
      selectedAddress,
    });
    if (!isProceed) {
      showErrorMsg(`Maximum ${LOCATIONS_LIMIT + 1} locations can add`);
      return;
    }
    const result = await geocodeByAddress(selectedAddress);
    if (result.length > 0) {
      const latLang = await getLatLng(result[0]);
      const newData = {
        location_type: 'Current Location',
        location_name: selectedAddress,
        long: latLang?.lng,
        lat: latLang?.lat,
        radius: 1000,
        identifier: uuid(),
      };
      setLocations([...locations, newData]);
      setIsLocationAddedOrRemoved(Math.random());
    }
  };

  const removeLocation = (selectedLocation) => {
    if (locations && locations.length > 0) {
      const newLocations = locations.filter(
        (data) => data?.identifier !== selectedLocation?.identifier
      );
      setLocations(newLocations);
      setIsLocationAddedOrRemoved(Math.random());
    }
  };

  const clearInputLocation = () => {
    handleDifferentAddresses({ selectedAddress: '' });
  };

  const handleDifferentAddresses = ({ selectedAddress }) => {
    setCurrentAddress(selectedAddress || '');
    return !(
      formattedLocations &&
      formattedLocations?.custom_selected_locations.length > LOCATIONS_LIMIT
    );
  };

  const getFormattedLocations = () => {
    const locationsStructure = {
      custom_selected_locations: [],
    };
    if (locations && locations.length > 0) {
      locations.forEach((data) => {
        locationsStructure.custom_selected_locations.push(data);
      });
    }
    return locationsStructure;
  };

  const handleCampaignLocationSelection = (locationId) => {
    const selectedLocations = campaignLocations.filter(
      (item) => item._id === locationId
    );
    if (selectedLocations?.[0]) {
      const newData = {
        ...selectedLocations[0],
        // radius: 1000,
        identifier: uuid(),
      };
      setLocations([...locations, newData]);
    }
  };

  const formattedLocations = getFormattedLocations();
  return (
    <WhereToTargetBox>
      <CollapseBox>
        <Collapse defaultActiveKey={['1']}>
          <Panel header="Pick a custom location" key="1">
            <Row gutter={16}>
              {campaignLocations && campaignLocations.length >= 1 && (
                <>
                  <Col className="gutter-row" xs={12} sm={12}>
                    <Form.Item label="Select Campaign Locations">
                      <Select
                        showSearch
                        allowClear
                        value={[]}
                        style={{ width: '100%' }}
                        optionFilterProp="children"
                        filterOption={(input, option) =>
                          option.children
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                        }
                        placeholder="Select Campaign Location"
                        onChange={handleCampaignLocationSelection}
                      >
                        {campaignLocations
                          .filter(
                            (item) =>
                              !locations.find(
                                (locItem) => locItem?._id === item?._id
                              )
                          )
                          .map((item) => (
                            <Option key={item._id} value={item._id}>
                              {item.location_name}
                            </Option>
                          ))}
                      </Select>
                    </Form.Item>
                  </Col>
                </>
              )}
              <Col className="gutter-row" xs={12} sm={12}>
                <PlacesAutocomplete
                  searchOptions={{ componentRestrictions: { country: 'AE' } }}
                  value={currentAddress}
                  onChange={(address) => handleChange(address)}
                  onSelect={(address) => handleSelectAddress(address)}
                >
                  {({
                    getInputProps,
                    suggestions,
                    getSuggestionItemProps,
                    loading,
                  }) => (
                    <LocationSearchBox>
                      <Form.Item
                        name="locations"
                        label="Select Locations"
                        validateTrigger={handleValidateFieldNames('locations')}
                      >
                        <Input
                          type="text"
                          onBlur={() =>
                            setValidateFieldsName([
                              ...validateFieldsName,
                              'locations',
                            ])
                          }
                          {...getInputProps({
                            placeholder: 'Search Places...',
                            className: 'location-search-input',
                          })}
                        />
                        <div
                          className="close-outline-icon"
                          onClick={() => {
                            clearInputLocation();
                          }}
                        >
                          <CloseOutlined />
                        </div>
                        <div
                          className={`autocomplete-dropdown-container ${
                            suggestions.length > 0 ? 'blue-border' : ''
                          }`}
                        >
                          {loading && <div>Loading...</div>}
                          {suggestions.map((suggestion, index) => {
                            const className = suggestion.active
                              ? 'suggestion-item--active'
                              : 'suggestion-item';
                            // inline style for demonstration purpose
                            const style = suggestion.active
                              ? {
                                  backgroundColor: '#1890ff33',
                                  cursor: 'pointer',
                                  padding: '4px 10px',
                                }
                              : {
                                  backgroundColor: '#ffffff',
                                  cursor: 'pointer',
                                  padding: '4px 10px',
                                };
                            return (
                              <div
                                key={index}
                                {...getSuggestionItemProps(suggestion, {
                                  className,
                                  style,
                                })}
                              >
                                <span>{suggestion.description}</span>
                              </div>
                            );
                          })}
                        </div>
                      </Form.Item>
                    </LocationSearchBox>
                  )}
                </PlacesAutocomplete>
              </Col>
              <Col className="gutter-row" xs={24} sm={24}>
                <SelectedPlacesBox>
                  {formattedLocations &&
                    formattedLocations?.custom_selected_locations.length > 0 &&
                    formattedLocations?.custom_selected_locations.map(
                      (data, index) => (
                        <div key={index} className="selected-places-box">
                          <Tag
                            closable
                            color="green"
                            onClose={() => removeLocation(data)}
                          >
                            <p>{data?.location_name}</p>
                          </Tag>
                        </div>
                      )
                    )}
                </SelectedPlacesBox>
              </Col>
            </Row>
            {locations && locations.length > 0 && (
              <LocationsMap
                locations={locations}
                setLocations={setLocations}
                isLocationAddedOrRemoved={isLocationAddedOrRemoved}
              />
            )}
          </Panel>
        </Collapse>
      </CollapseBox>
    </WhereToTargetBox>
  );
};

WhereToTarget.propTypes = {
  campaignLocations: array,
  currentAddress: string,
  form: oneOfType([object, array]),
  handleValidateFieldNames: func,
  locations: oneOfType([array]),
  setCurrentAddress: func,
  setLocations: func,
  setValidateFieldsName: func,
  validateFieldsName: oneOfType([array]),
};

export default WhereToTarget;
