import styled from 'styled-components';

export const CollapseBox = styled.div`
  margin-top: 20px;
  .communication-btns {
    button {
      margin-right: 10px;
    }
  }
  .template-upload-btn {
    display: flex;
    .loader-btn {
      height: 33px;
      width: 168px;
      margin-bottom: 20px;
    }
    .preview-btn {
      min-width: 168px;
    }
    .custom-upload-btn {
      display: flex;
      margin-bottom: 20px;
    }
    label {
      margin-right: 10px;
      background: white;
      border: 1px solid grey;
      padding: 5px 20px;
      border-radius: 2px;
      svg {
        margin-right: 10px;
      }
    }
  }
`;
