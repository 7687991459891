import React, { useState } from 'react';
import { uuid } from 'uuidv4';
import { oneOfType, array, func, object } from 'prop-types';
import { CloseOutlined } from '@ant-design/icons';
import { LOCATION_TYPES } from 'constants/locations';
import LocationsMap from 'components/v2/locations-map';
import { showErrorMsg } from 'utils';
import { Form, Row, Col, Input, Collapse, Tag } from 'antd';
import { CollapseBox } from '../_add-edit-campaign.styled';
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from 'react-places-autocomplete';
import {
  WhereToTargetBox,
  LocationSearchBox,
  SelectedPlacesBox,
} from './_where-to-target.styled';
const { Panel } = Collapse;

const LOCATIONS_LIMIT = 19;

const WhereToTarget = (props) => {
  const {
    handleValidateFieldNames,
    setValidateFieldsName,
    validateFieldsName,
    locations,
    setLocations,
  } = props;

  const [currentAddress, setCurrentAddress] = useState('');
  // const [pickUpAddress, setPickUpAddress] = useState('');
  // const [dropOffAddress, setDropOffAddress] = useState('');
  const [isLocationAddedOrRemoved, setIsLocationAddedOrRemoved] = useState(
    Math.random()
  );

  const handleChange = (newAddress, { locationType }) => {
    handleDifferentAddresses({ locationType, selectedAddress: newAddress });
  };

  const handleSelectAddress = async (selectedAddress, { locationType }) => {
    const isProceed = handleDifferentAddresses({
      locationType,
      selectedAddress,
    });
    if (!isProceed) {
      showErrorMsg(`Maximum ${LOCATIONS_LIMIT + 1} locations can add`);
      return;
    }
    const result = await geocodeByAddress(selectedAddress);
    if (result.length > 0) {
      const latlong = await getLatLng(result[0]);
      const newData = {
        location_type: locationType,
        location_name: selectedAddress,
        long: latlong?.lng,
        lat: latlong?.lat,
        radius: 1000,
        identifier: uuid(),
      };
      setLocations([...locations, newData]);
      setIsLocationAddedOrRemoved(Math.random());
    }
  };

  const removeLocation = (selectedLocation) => {
    if (locations && locations.length > 0) {
      const newLocations = locations.filter(
        (data) => data?.identifier !== selectedLocation?.identifier
      );
      setLocations(newLocations);
      setIsLocationAddedOrRemoved(Math.random());
    }
  };

  const clearInputLocation = ({ locationType }) => {
    handleDifferentAddresses({ locationType, selectedAddress: '' });
  };

  const handleDifferentAddresses = ({ locationType, selectedAddress }) => {
    if (locationType === LOCATION_TYPES.PICK_UP_LOCATION) {
      // setPickUpAddress(selectedAddress || '');
      if (
        formattedLocations &&
        formattedLocations?.pick_up_locations.length > LOCATIONS_LIMIT
      ) {
        return false;
      }
    }
    if (locationType === LOCATION_TYPES.DROPOFF_LOCATION) {
      // setDropOffAddress(selectedAddress || '');
      if (
        formattedLocations &&
        formattedLocations?.dropoff_locations.length > LOCATIONS_LIMIT
      ) {
        return false;
      }
    }
    if (locationType === LOCATION_TYPES.CURRENT_LOCATION) {
      setCurrentAddress(selectedAddress || '');
      if (
        formattedLocations &&
        formattedLocations?.current_locations.length > LOCATIONS_LIMIT
      ) {
        return false;
      }
    }
    return true;
  };

  const getFormattedLocations = () => {
    const locationsStructure = {
      pick_up_locations: [],
      dropoff_locations: [],
      current_locations: [],
    };
    if (locations && locations.length > 0) {
      locations.forEach((data) => {
        if (data?.location_type === LOCATION_TYPES.PICK_UP_LOCATION) {
          locationsStructure.pick_up_locations.push(data);
        }
        if (data?.location_type === LOCATION_TYPES.DROPOFF_LOCATION) {
          locationsStructure.dropoff_locations.push(data);
        }
        if (data?.location_type === LOCATION_TYPES.CURRENT_LOCATION) {
          locationsStructure.current_locations.push(data);
        }
      });
    }
    return locationsStructure;
  };

  const formattedLocations = getFormattedLocations();
  return (
    <WhereToTargetBox>
      <CollapseBox>
        <Collapse defaultActiveKey={['1']}>
          <Panel header="Where to target the Ads" key="1">
            <Row gutter={16}>
              {/* <Col className="gutter-row" xs={24} sm={8}>
                <PlacesAutocomplete
                  value={pickUpAddress}
                  onChange={(address) =>
                    handleChange(address, {
                      locationType: LOCATION_TYPES.PICK_UP_LOCATION,
                    })
                  }
                  onSelect={(address) =>
                    handleSelectAddress(address, {
                      locationType: LOCATION_TYPES.PICK_UP_LOCATION,
                    })
                  }
                >
                  {({
                    getInputProps,
                    suggestions,
                    getSuggestionItemProps,
                    loading,
                  }) => (
                    <LocationSearchBox>
                      <Form.Item
                        name="pick_up_location"
                        label="PickUp Location"
                        validateTrigger={handleValidateFieldNames(
                          'pick_up_location'
                        )}
                      >
                        <Input
                          type="text"
                          onBlur={() =>
                            setValidateFieldsName([
                              ...validateFieldsName,
                              'pick_up_location',
                            ])
                          }
                          {...getInputProps({
                            placeholder: 'Search Places...',
                            className: 'location-search-input',
                          })}
                        />
                        <div
                          className="close-outline-icon"
                          onClick={() => {
                            clearInputLocation({
                              locationType: LOCATION_TYPES.PICK_UP_LOCATION,
                            });
                          }}
                        >
                          <CloseOutlined />
                        </div>
                        <div
                          className={`autocomplete-dropdown-container ${
                            suggestions.length > 0 ? 'blue-border' : ''
                          }`}
                        >
                          {loading && <div>Loading...</div>}
                          {suggestions.map((suggestion, index) => {
                            const className = suggestion.active
                              ? 'suggestion-item--active'
                              : 'suggestion-item';
                            // inline style for demonstration purpose
                            const style = suggestion.active
                              ? {
                                  backgroundColor: '#1890ff33',
                                  cursor: 'pointer',
                                  padding: '4px 10px',
                                }
                              : {
                                  backgroundColor: '#ffffff',
                                  cursor: 'pointer',
                                  padding: '4px 10px',
                                };
                            return (
                              <div
                                key={index}
                                {...getSuggestionItemProps(suggestion, {
                                  className,
                                  style,
                                })}
                              >
                                <span>{suggestion.description}</span>
                              </div>
                            );
                          })}
                        </div>
                      </Form.Item>
                    </LocationSearchBox>
                  )}
                </PlacesAutocomplete>
                <SelectedPlacesBox>
                  {formattedLocations &&
                    formattedLocations?.pick_up_locations.length > 0 &&
                    formattedLocations?.pick_up_locations.map((data, index) => (
                      <div key={index} className="selected-places-box">
                        <Tag
                          closable
                          color="magenta"
                          onClose={() => removeLocation(data)}
                        >
                          <p>{data?.location_name}</p>
                        </Tag>
                      </div>
                    ))}
                </SelectedPlacesBox>
              </Col> */}
              {/* <Col className="gutter-row" xs={24} sm={8}>
                <PlacesAutocomplete
                  value={dropOffAddress}
                  onChange={(address) =>
                    handleChange(address, {
                      locationType: LOCATION_TYPES.DROPOFF_LOCATION,
                    })
                  }
                  onSelect={(address) =>
                    handleSelectAddress(address, {
                      locationType: LOCATION_TYPES.DROPOFF_LOCATION,
                    })
                  }
                >
                  {({
                    getInputProps,
                    suggestions,
                    getSuggestionItemProps,
                    loading,
                  }) => (
                    <LocationSearchBox>
                      <Form.Item
                        name="dropoff_location"
                        label="Dropoff Location"
                        validateTrigger={handleValidateFieldNames(
                          'dropoff_location'
                        )}
                      >
                        <Input
                          type="text"
                          onBlur={() =>
                            setValidateFieldsName([
                              ...validateFieldsName,
                              'dropoff_location',
                            ])
                          }
                          {...getInputProps({
                            placeholder: 'Search Places...',
                            className: 'location-search-input',
                          })}
                        />
                        <div
                          className="close-outline-icon"
                          onClick={() => {
                            clearInputLocation({
                              locationType: LOCATION_TYPES.DROPOFF_LOCATION,
                            });
                          }}
                        >
                          <CloseOutlined />
                        </div>
                        <div
                          className={`autocomplete-dropdown-container ${
                            suggestions.length > 0 ? 'blue-border' : ''
                          }`}
                        >
                          {loading && <div>Loading...</div>}
                          {suggestions.map((suggestion, index) => {
                            const className = suggestion.active
                              ? 'suggestion-item--active'
                              : 'suggestion-item';
                            // inline style for demonstration purpose
                            const style = suggestion.active
                              ? {
                                  backgroundColor: '#1890ff33',
                                  cursor: 'pointer',
                                  padding: '4px 10px',
                                }
                              : {
                                  backgroundColor: '#ffffff',
                                  cursor: 'pointer',
                                  padding: '4px 10px',
                                };
                            return (
                              <div
                                key={index}
                                {...getSuggestionItemProps(suggestion, {
                                  className,
                                  style,
                                })}
                              >
                                <span>{suggestion.description}</span>
                              </div>
                            );
                          })}
                        </div>
                      </Form.Item>
                    </LocationSearchBox>
                  )}
                </PlacesAutocomplete>
                <SelectedPlacesBox>
                  {formattedLocations &&
                    formattedLocations?.dropoff_locations.length > 0 &&
                    formattedLocations?.dropoff_locations.map((data, index) => (
                      <div key={index} className="selected-places-box">
                        <Tag
                          closable
                          color="gold"
                          onClose={() => removeLocation(data)}
                        >
                          <p>{data?.location_name}</p>
                        </Tag>
                      </div>
                    ))}
                </SelectedPlacesBox>
              </Col> */}
              <Col className="gutter-row" xs={24} sm={8}>
                <PlacesAutocomplete
                  value={currentAddress}
                  onChange={(address) =>
                    handleChange(address, {
                      locationType: LOCATION_TYPES.CURRENT_LOCATION,
                    })
                  }
                  onSelect={(address) =>
                    handleSelectAddress(address, {
                      locationType: LOCATION_TYPES.CURRENT_LOCATION,
                    })
                  }
                >
                  {({
                    getInputProps,
                    suggestions,
                    getSuggestionItemProps,
                    loading,
                  }) => (
                    <LocationSearchBox>
                      <Form.Item
                        name="current_location"
                        label="Current Location"
                        validateTrigger={handleValidateFieldNames(
                          'current_location'
                        )}
                      >
                        <Input
                          type="text"
                          onBlur={() =>
                            setValidateFieldsName([
                              ...validateFieldsName,
                              'current_location',
                            ])
                          }
                          {...getInputProps({
                            placeholder: 'Search Places...',
                            className: 'location-search-input',
                          })}
                        />
                        <div
                          className="close-outline-icon"
                          onClick={() => {
                            clearInputLocation({
                              locationType: LOCATION_TYPES.CURRENT_LOCATION,
                            });
                          }}
                        >
                          <CloseOutlined />
                        </div>
                        <div
                          className={`autocomplete-dropdown-container ${
                            suggestions.length > 0 ? 'blue-border' : ''
                          }`}
                        >
                          {loading && <div>Loading...</div>}
                          {suggestions.map((suggestion, index) => {
                            const className = suggestion.active
                              ? 'suggestion-item--active'
                              : 'suggestion-item';
                            // inline style for demonstration purpose
                            const style = suggestion.active
                              ? {
                                  backgroundColor: '#1890ff33',
                                  cursor: 'pointer',
                                  padding: '4px 10px',
                                }
                              : {
                                  backgroundColor: '#ffffff',
                                  cursor: 'pointer',
                                  padding: '4px 10px',
                                };
                            return (
                              <div
                                key={index}
                                {...getSuggestionItemProps(suggestion, {
                                  className,
                                  style,
                                })}
                              >
                                <span>{suggestion.description}</span>
                              </div>
                            );
                          })}
                        </div>
                      </Form.Item>
                    </LocationSearchBox>
                  )}
                </PlacesAutocomplete>
                <SelectedPlacesBox>
                  {formattedLocations &&
                    formattedLocations?.current_locations.length > 0 &&
                    formattedLocations?.current_locations.map((data, index) => (
                      <div key={index} className="selected-places-box">
                        <Tag
                          closable
                          color="green"
                          onClose={() => removeLocation(data)}
                        >
                          <p>{data?.location_name}</p>
                        </Tag>
                      </div>
                    ))}
                </SelectedPlacesBox>
              </Col>
            </Row>
            {locations && locations.length > 0 && (
              <LocationsMap
                locations={locations}
                setLocations={setLocations}
                isLocationAddedOrRemoved={isLocationAddedOrRemoved}
              />
            )}
          </Panel>
        </Collapse>
      </CollapseBox>
    </WhereToTargetBox>
  );
};

WhereToTarget.propTypes = {
  form: oneOfType([object, array]),
  handleValidateFieldNames: func,
  locations: oneOfType([array]),
  setLocations: func,
  setValidateFieldsName: func,
  validateFieldsName: oneOfType([array]),
};

export default WhereToTarget;
