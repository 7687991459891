import React, { useState, useEffect } from 'react';
import { Popconfirm, Dropdown, Menu, Skeleton, Button, Row, Col } from 'antd';
// import StatisticCardBlock from 'components/statistic-card-block';
import { useParams, useHistory } from 'react-router-dom';
import {
  URLS,
  showErrorMsg,
  showSuccessMsg,
  getAMAAuthTokenLocalStorage,
  generateAndroidManagementToken,
  // showSuccessMsg,
  formatDate,
  getSingleValueFromEncodedURL,
} from 'utils';
import {
  SyncOutlined,
  // CheckSquareOutlined,
  // CloseSquareOutlined,
  DownOutlined,
} from '@ant-design/icons';
import { EnrollDeviceDetailPageBox } from './_enroll-device-detail-page.styled';
import {
  AMA_COMMAND_LIST,
  AMA_COMMAND_DURATION,
} from 'constants/enrolled-device';
import HardwareInfo from './hardware-info';
import DeviceInfo from './device-info';
import DeviceOperationList from './device-operation-list';

const EnrollDeviceDetailPage = () => {
  const params = useParams();
  let history = useHistory();

  const [isFetching, setIsFetching] = useState(false);
  const [enrollDeviceDetails, setEnrollDeviceDetails] = useState({});

  const apiKey =
    process.env.REACT_APP_ANDROID_MANAGEMENT_API_KEY ||
    'AIzaSyCVhMeBzCjfhBbSWenrG3aBGkXCkbvMjNc';
  const discoveryDocs = [
    'https://androidmanagement.googleapis.com/$discovery/rest?version=v1',
  ];

  const apiRequestFailedLimit = 2;
  let apiRequestFailedCount = 0;

  useEffect(() => {
    handleClientLoad();
  }, []);

  const handleClientLoad = () => {
    setIsFetching(true);
    if (window.gapi) {
      window.gapi.load('client', initClient);
    } else {
      setTimeout(handleClientLoad, 1000);
    }
  };

  const initClient = () => {
    window.gapi.client
      .init({
        apiKey: apiKey,
        discoveryDocs: discoveryDocs,
      })
      .then(() => {
        fetchDeviceDetails();
      })
      .catch(() => {
        setIsFetching(false);
        showErrorMsg('System is facing error, Please try again later');
      });
  };

  const fetchDeviceDetails = () => {
    setIsFetching(true);

    const authToken = getAMAAuthTokenLocalStorage(); // AMA auth token
    const deviceAMAId = params.ama_id;

    const deviceDetailURL = `${URLS.ANDROID_MANAGEMENT.GET_ENROLLED_DEVICES_DETAIL}/${deviceAMAId}`;

    window.gapi.client
      .request({
        path: deviceDetailURL,
        method: 'GET',
        headers: { Authorization: 'Bearer ' + authToken },
      })
      .then((resp) => {
        setEnrollDeviceDetails(resp?.result);
        setIsFetching(false);
      })
      .catch((apiExp) => {
        const { code, status, details, message } = apiExp?.result?.error || {};
        if (
          apiRequestFailedCount < apiRequestFailedLimit &&
          code === 401 &&
          status === 'UNAUTHENTICATED' &&
          (details?.[0]?.reason === 'ACCESS_TOKEN_EXPIRED' ||
            details?.[0]?.reason === 'CREDENTIALS_MISSING')
        ) {
          try {
            generateAndroidManagementToken();
            setTimeout(fetchDeviceDetails, 1000);
            apiRequestFailedCount = apiRequestFailedCount + 1;
          } catch (authError) {
            setIsFetching(false);
            console.log('System is facing error, Please try again later');
            throw authError;
          }
        } else if (code === 404 && status === 'NOT_FOUND') {
          showErrorMsg(message);
          history.push('/enroll-devices');
        } else {
          setIsFetching(false);
          showErrorMsg('System is facing error, Please try again later');
        }
      });
  };

  const resetDevice = (deviceAMAId) => {
    if (deviceAMAId) {
      sendCommandToDevice(deviceAMAId);
    }
  };

  const sendCommandToDevice = (deviceAMAId) => {
    setIsFetching(true);
    const command = AMA_COMMAND_LIST.REBOOT;
    const duration = AMA_COMMAND_DURATION[AMA_COMMAND_LIST.REBOOT];
    const issueDeviceCommandURL = `${URLS.ANDROID_MANAGEMENT.ENROLLED_DEVICES_LIST}/${deviceAMAId}:issueCommand`;
    const authToken = getAMAAuthTokenLocalStorage(); // AMA auth token
    window.gapi.client
      .request({
        path: issueDeviceCommandURL,
        method: 'POST',
        body: {
          type: command,
          duration: duration,
        },
        headers: { Authorization: 'Bearer ' + authToken },
      })
      .then(() => {
        setIsFetching(false);
        showSuccessMsg(command + ' command issued successfully');
        setTimeout(fetchDeviceDetails, 1000);
      })
      .catch((apiExp) => {
        const { code, status, details } = apiExp?.result?.error || {};
        if (
          apiRequestFailedCount < apiRequestFailedLimit &&
          code === 401 &&
          status === 'UNAUTHENTICATED' &&
          details?.[0]?.reason === 'ACCESS_TOKEN_EXPIRED'
        ) {
          try {
            generateAndroidManagementToken();
            setTimeout(() => {
              sendCommandToDevice(deviceAMAId);
            }, 1000);
            apiRequestFailedCount = apiRequestFailedCount + 1;
            setIsFetching(false);
          } catch (authError) {
            setIsFetching(false);
            console.log('System is facing error, Please try again later');
            throw authError;
          }
        } else {
          setIsFetching(false);
          showErrorMsg('System is facing error, Please try again later');
        }
      });
  };

  const renderStatisticDivBlock = (title, value) => {
    return (
      <>
        <h3>{title}</h3>
        <Skeleton
          loading={isFetching}
          active
          paragraph={{ rows: 1, width: '100%' }}
          title={false}
        >
          <div>{value}</div>
        </Skeleton>
      </>
    );
  };

  const menu = (
    <Menu>
      <Popconfirm
        title={
          <>{`Are you sure you want to ${AMA_COMMAND_LIST.REBOOT} this device?`}</>
        }
        onConfirm={() => resetDevice(params.ama_id)}
      >
        <Menu.Item key={AMA_COMMAND_LIST.REBOOT} icon={<SyncOutlined />}>
          {AMA_COMMAND_LIST.REBOOT}
        </Menu.Item>
      </Popconfirm>
    </Menu>
  );

  return (
    <EnrollDeviceDetailPageBox>
      <div className="table-header">
        <div>
          <h3 className="table-title">
            {enrollDeviceDetails?.hardwareInfo?.serialNumber}
          </h3>
          <label className="table-title">
            {enrollDeviceDetails?.hardwareInfo?.model}
          </label>
        </div>
        <div className="table-right-side">
          <div className="export-generate-btn">
            <Dropdown overlay={menu} trigger={['click']}>
              <Button>
                {'Action'} <DownOutlined />
              </Button>
            </Dropdown>
          </div>
        </div>
      </div>
      <div className="device-details-header">
        <div>
          {renderStatisticDivBlock(
            'Serial Number',
            enrollDeviceDetails?.hardwareInfo?.serialNumber
          )}
        </div>
        <div className="vr-solid" />
        <div>
          {renderStatisticDivBlock('API Level', enrollDeviceDetails?.apiLevel)}
        </div>
        <div className="vr-solid" />
        <div>
          {renderStatisticDivBlock(
            'Applied Policy Name',
            getSingleValueFromEncodedURL(enrollDeviceDetails?.policyName, 3)
          )}
        </div>
        <div className="vr-solid" />
        <div>
          {renderStatisticDivBlock(
            'Last Policy Sync',
            formatDate(
              enrollDeviceDetails?.lastPolicySyncTime,
              'Do MMMM YYYY hh:mm A'
            )
          )}
        </div>
        <div className="vr-solid" />
        <div>
          {renderStatisticDivBlock(
            'Enrollment Time',
            formatDate(
              enrollDeviceDetails?.enrollmentTime,
              'Do MMMM YYYY hh:mm A'
            )
          )}
        </div>
        <div className="vr-solid" />
        <div>
          {renderStatisticDivBlock(
            'Last Reporting Time',
            formatDate(
              enrollDeviceDetails?.lastStatusReportTime,
              'Do MMMM YYYY hh:mm A'
            )
          )}
        </div>
        <div className="vr-solid" />
        <div>
          {renderStatisticDivBlock('Status', enrollDeviceDetails?.state)}
        </div>
      </div>
      <div>
        <Row gutter={16}>
          <Col className="gutter-row" xs={24} sm={12}>
            <HardwareInfo
              hardwareInfo={enrollDeviceDetails?.hardwareInfo}
              isFetching={isFetching}
            />
          </Col>
          <Col className="gutter-row" xs={24} sm={12}>
            <DeviceInfo
              hardwareInfo={enrollDeviceDetails}
              isFetching={isFetching}
            />
          </Col>
        </Row>
        <Row>
          <Col className="gutter-row" xs={24} sm={24}>
            <DeviceOperationList deviceAMAId={params?.ama_id} />
          </Col>
        </Row>
      </div>
    </EnrollDeviceDetailPageBox>
  );
};

export default EnrollDeviceDetailPage;
