import styled from 'styled-components';

export const EnrollDeviceBox = styled.div`

  .table-header {
    margin: 10px;
    background: white;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 15px;
    border-radius: 4px;
    overflow: hidden;
    h3 {
      margin-bottom: 0px;
    }
    .table-right-side {
      display: flex;
      .export-generate-btn {
        .ant-form-item {
          margin-bottom: 0px;
        }
      }
    }
  }

  .main-table-body {
    padding: 0px 10px;
  }

  .filter-form {
    margin: 10px;

    background: white;
    padding: 15px;
    .main-filter-form {
      display: flex;
      width: 100%;
      justify-content: space-between;
      .left-form {
        display: flex;
        flex-wrap: wrap;
      }
      input {
        font-size: 14px;
      }
    }
  }
  .file-export-loading {
    display: flex;
    align-items: center;
    div {
      font-weight: 500;
      color: #1890ff;
      margin-left: 10px;
    }
  }
  .ride-kpis {
    margin: 10px;
  }
  .ant-statistic-title {
    font-weight: 700;
  }
  .ant-statistic-content-suffix {
    font-size: 16px;
  }
  .ant-statistic-content-value-int {
    font-size: 22px;
    font-weight: 500;
  }
`;

export const Loader = styled.div`
  color: grey;
  font-size: 14px;
`;
