/* eslint-disable react/prop-types */
import React, { Fragment, useState, useRef, useEffect } from 'react';
import { compose, withProps } from 'recompose';
import {
  withScriptjs,
  withGoogleMap,
  GoogleMap,
  Marker,
  Circle,
} from 'react-google-maps';
import { showErrorMsg } from 'utils';
import { LOCATION_TYPES } from 'constants/locations';
import GeoCode from 'react-geocode';
import InfoBox from 'react-google-maps/lib/components/addons/InfoBox';
import { InfoWindowBox } from './_locations-map.styled';

GeoCode.setApiKey(process.env.REACT_APP_GOOGLE_MAP_KEY);

const LocationsMap = compose(
  withProps({
    googleMapURL: `https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_GOOGLE_MAP_KEY}&v=3.exp&libraries=geometry,drawing,places`,
    loadingElement: <div style={{ height: `100%` }} />,
    containerElement: <div style={{ height: `500px` }} />,
    mapElement: <div style={{ height: `100%` }} />,
  }),
  withScriptjs,
  withGoogleMap
)((props) => {
  let refCircle = useRef([]);
  let refMap = useRef(null);

  const { locations, setLocations, isLocationAddedOrRemoved } = props || {};
  const [locationData, setLocationData] = useState({
    showInfoBox: false,
    selectedPlace: '',
  });
  const [initialCenter, setInitialCenter] = useState({
    lat: 25.2048,
    lng: 55.2708,
  });

  useEffect(() => {
    fitBounds();
  }, []);

  useEffect(() => {
    fitBounds();
  }, [isLocationAddedOrRemoved]);

  const onMarkerClick = ({ marker }) => {
    setLocationData({
      showInfoBox: true,
      selectedPlace: marker,
    });
  };

  const handleLocatonMarker = async ({ event, marker }) => {
    const lat = event?.latLng?.lat();
    const long = event?.latLng?.lng();
    const response = await GeoCode.fromLatLng(lat, long);
    if (response && response?.results && response?.results.length > 0) {
      const address = response?.results[0].formatted_address;
      const newLocation = { ...marker, lat, long, location_name: address };
      const locationsCopy = [...locations];
      const index = locationsCopy.findIndex(
        (data) => data?.identifier === newLocation.identifier
      );
      locationsCopy[index] = newLocation;
      setInitialCenter({ lat, lng: long });
      setLocations(locationsCopy);
      setLocationData({
        ...locationData,
        selectedPlace: newLocation,
        showInfoBox: true,
      });
    }
  };

  const handleCircleRadius = ({ marker, refIndex }) => {
    let radius = refCircle.current[refIndex].getRadius();
    if (radius < 10 || radius > 10000) {
      if (radius < 10) {
        radius = 10;
      }
      if (radius > 1000) {
        radius = 1000;
      }
      showErrorMsg('Radius should be between 10meter to 10km');
    }
    if (!radius) return;
    const newLocation = { ...marker, radius };
    const locationsCopy = [...locations];
    const index = locationsCopy.findIndex(
      (data) => data?.identifier === newLocation.identifier
    );

    locationsCopy[index] = newLocation;
    setLocations(locationsCopy);
    setLocationData({
      ...locationData,
      selectedPlace: newLocation,
      showInfoBox: true,
    });
  };

  const handleLocationMarkerOnDragStart = () => {
    setLocationData({ ...locationData, showInfoBox: false });
  };

  const handleCloseInfoBox = () => {
    setLocationData({
      showInfoBox: false,
      selectedPlace: '',
    });
  };

  const fitBounds = () => {
    const bounds = new window.google.maps.LatLngBounds();
    locations.map((location) => {
      let myLatLng = {
        lat: parseFloat(location.lat),
        lng: parseFloat(location.long),
      };
      bounds.extend(myLatLng);
      return location;
    });
    refMap.fitBounds(bounds);
  };

  return (
    <GoogleMap
      defaultZoom={12}
      defaultCenter={initialCenter}
      ref={(ref) => {
        refMap = ref;
      }}
      options={{ streetViewControl: false }}
    >
      {locations &&
        locations.length > 0 &&
        locations.map((marker, index) => {
          let selectedColor = 'red';
          if (marker?.location_type === LOCATION_TYPES.PICK_UP_LOCATION) {
            selectedColor = '#c41d7f';
          } else if (
            marker?.location_type === LOCATION_TYPES.DROPOFF_LOCATION
          ) {
            selectedColor = '#d48806';
          } else if (
            marker?.location_type === LOCATION_TYPES.CURRENT_LOCATION
          ) {
            selectedColor = '#389e0d';
          }

          return (
            <Fragment key={index}>
              <Marker
                key={index}
                onClick={() => onMarkerClick({ marker })}
                draggable={true}
                onDragStart={handleLocationMarkerOnDragStart}
                onDragEnd={(event) => handleLocatonMarker({ event, marker })}
                position={{
                  lat: parseFloat(marker?.lat),
                  lng: parseFloat(marker?.long),
                }}
              >
                <InfoBox
                  defaultVisible={false}
                  boxStyle={{
                    width: '150px',
                  }}
                  visible={
                    locationData?.showInfoBox &&
                    locationData?.selectedPlace?.identifier ===
                      marker?.identifier
                  }
                  options={{ closeBoxURL: ``, enableEventPropagation: false }}
                >
                  <InfoWindowBox>
                    <div className="info-close">
                      <h5>
                        <b style={{ color: selectedColor }}>
                          {locationData?.selectedPlace?.location_type}
                        </b>
                      </h5>
                      <div className="button-box" onClick={handleCloseInfoBox}>
                        x
                      </div>
                    </div>

                    <h6>
                      <b> {locationData?.selectedPlace?.location_name}</b>
                    </h6>
                    <h6>
                      <b>
                        Radius:{` `}
                        {(locationData?.selectedPlace?.radius &&
                          locationData?.selectedPlace?.radius.toFixed(2)) ||
                          0}
                        {locationData?.selectedPlace?.radius > 1
                          ? 'meters'
                          : 'meter'}
                      </b>
                    </h6>
                  </InfoWindowBox>
                </InfoBox>
              </Marker>
              <Circle
                ref={(ref) => (refCircle.current[index] = ref)}
                defaultCenter={{
                  lat: parseFloat(marker?.lat),
                  lng: parseFloat(marker?.long),
                }}
                defaultEditable={true}
                radius={parseFloat(marker?.radius)}
                center={{
                  lat: parseFloat(marker?.lat),
                  lng: parseFloat(marker?.long),
                }}
                onRadiusChanged={() =>
                  handleCircleRadius({ marker, refIndex: index })
                }
                options={{
                  strokeColor: selectedColor,
                  fillColor: selectedColor,
                  strokeWeight: 1,
                }}
              />
            </Fragment>
          );
        })}
    </GoogleMap>
  );
});

export default LocationsMap;
