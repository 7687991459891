import React, { useState, useEffect, useRef } from 'react';
import DeviceMap from 'components/v2/device-map';
import { DevicesMapBox } from './_devices-map.styled';
import { convertUnixToDateFormat } from 'utils';
import { firebaseDb } from 'configs';

const DevicesLocationMap = () => {
  const [deviceLocationList, setDeviceLocationList] = useState([]);
  
  const initialTimeStamp = useRef(Date.now());
  const isDataLoadedOnce = useRef(false);

  const mapTimeDelayedInSecs = 10;

  useEffect(() => {
    const deviceLocationColRef = firebaseDb.ref('device_location_updates');
    deviceLocationColRef.on('value', fetchDeviceStatusList);

    return () => {
      // Unsubscribe from database updates when component unmounts
      deviceLocationColRef.off('value',fetchDeviceStatusList );
    };
  
  }, []);

  const fetchDeviceStatusList = (response) => {
      const deviceStatusListData = response.val();
console.log(deviceStatusListData, "sko")

      const tempList = [];
      Object.keys(deviceStatusListData || {}).map((item) => {
        const deviceStatusStructure = {
          // batteryPercent: deviceStatusListData[data].battery_percent || '',
          // binaryCode: deviceStatusListData[data].binary_code || '',
          ...deviceStatusListData[item],
          deviceSerial: deviceStatusListData[item]?.device_serial || '',
          position: {
            lat: deviceStatusListData[item]?.latitude || '',
            lng: deviceStatusListData[item]?.longitude || '',
          },
          // isCharging:
          //   deviceStatusListData[data].is_charging == true
          //     ? 'Charging'
          //     : 'Not Charging',
          // screenStatus:
          //   deviceStatusListData[data].is_screen_on == true ? 'On' : 'Off',
          // wifiName: deviceStatusListData[data].required_wifi_name
          //   ? deviceStatusListData[data].required_wifi_name
          //   : '',
          updatedAt: deviceStatusListData[item]?.updated_at
            ? convertUnixToDateFormat(deviceStatusListData[item]?.updated_at)
            : '-',
        };
        tempList.push(deviceStatusStructure);
      });
      let currentTimeStamp = Date.now();
      if (
        (currentTimeStamp - initialTimeStamp.current) / 1000 >=
          mapTimeDelayedInSecs ||
        !isDataLoadedOnce.current
      ) {
        initialTimeStamp.current = Date.now();
        isDataLoadedOnce.current = true;
        setDeviceLocationList(tempList);
      }
  };
  
// console.log(deviceLocationList, "sko")
  return (
    <DevicesMapBox>
      <div className="table-header">
        <h3 className="table-title">Device Map View</h3>
      </div>
      <div className="map-box">
        {deviceLocationList && deviceLocationList.length > 0 ? (
          <DeviceMap locations={deviceLocationList} />
        ) : (
          <DeviceMap locations={[]} />
        )}
      </div>
    </DevicesMapBox>
  );
};

export default DevicesLocationMap;
