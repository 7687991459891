import React, { useEffect, useState } from 'react'
import { CenteredSpin, MusicContentModalBox } from './MusicContentModal.styled'
import { Button, Col, Form, Input, Modal, Row, Select } from 'antd'
import Media from 'components/watch-content-modal/media';
import { WATCH_CONTENT_LANGUAGE_LIST } from 'constants/watch-content';
import { httpGet, httpPost, httpPut, showErrorMsg, showSuccessMsg, URLS_V2 } from 'utils';
import { API_V2_ERROR_CODES } from 'constants/common';
import MusicContentMedia from './MusicContentMedia';




const { Option } = Select;


const mediaInitialStructure = {
  name: '',
  size: '',
  type: '',
  url: '',
  isUploaded: false,
};

const MusicContentModal = (props) => {
  const { modalTitle, activeMode, handleOk, closeModal, callback, contentId } =props;

  const [isFormSubmitting, setIsFormSubmitting] = useState(false);
  const [isFetchWatch, setIsFetchingWatch] = useState(false);
  const [validateFieldsName, setValidateFieldsName] = useState([]);
  const [isCategoriesListFetching, setIsCategoriesListFetching]=useState(false);
  const [isMediaImageUpdating, setIsMediaImageUpdating] = useState(false);
  const [isMediaAudioUpdating, setIsMediaAudioUpdating] = useState(false);
  const [selectMediaImageObject, setSelectMediaImageObject] = useState('');
  const [selectMediaAudioObject, setSelectMediaAudioObject] = useState('');
  const [categoriesList, setCategoriesList]=useState([])
  const [imageUrl, setImageUrl] = useState('');
  const [audioUrl, setAudioUrl] = useState('');
  const [form] = Form.useForm();


let getModalTitle="Add Music"
let formDisabled = false;

useEffect(()=>{
fetchCategoryListApi()
},[])

useEffect(()=>{
  if(activeMode == 'View' || activeMode =='Edit'){
    fetchMusicInfo()
  }
},[contentId])


const fetchMusicInfo=()=>{

  let url= URLS_V2?.MUSIC_CONTENT?.GET_MUSIC_INFO.replace('#ID#', contentId)

  httpGet(url)
  .then((res)=>{
if(res?.status==200){
form.setFieldsValue({'title':res?.data?.title})
form.setFieldsValue({'lang':res?.data?.lang})
form.setFieldsValue({'category_id':res?.data?.categoryId})
setImageUrl(res?.data?.musicImage)
setAudioUrl(res?.data?.musicFile)
setSelectMediaImageObject(res?.data?.musicImage)
setSelectMediaAudioObject(res?.data?.musicFile)
formDisabled=true
}

  })
  .catch((err)=>{

  })
}


const resetAllData = () => {
  form.resetFields();
  setSelectMediaImageObject({});
  setSelectMediaAudioObject({});
  setValidateFieldsName([]);
  // setCategoriesList([]);
  setIsCategoriesListFetching(false);
  setIsFormSubmitting(false);
  setIsMediaImageUpdating(false);
  setIsMediaAudioUpdating(false);
  closeModal();
  setSelectMediaImageObject('')
  setSelectMediaAudioObject('')  
};

const handleOnCloseModal = () => {
  if (isFormSubmitting) return;
  resetAllData();
  setSelectMediaImageObject('')
  setSelectMediaAudioObject('')
};


const handleValidateFieldNames = (name) => {
  const isFieldName = validateFieldsName.find(
    (fieldName) => fieldName === name
  );
  if (isFieldName) return 'onChange';
  return 'onBlur';
};

const handleAddEditContent=()=>{

  let apiMethod=httpPost
  let url;
  if(activeMode == 'Add'){
    url= URLS_V2?.MUSIC_CONTENT?.ADD_MUSIC
  }
  if(activeMode == 'Edit'){
    apiMethod=httpPut
    url= URLS_V2?.MUSIC_CONTENT?.UPDATE_MUSIC.replace('#ID#',contentId)
  }

  form.submit()
  form
      .validateFields()
      .then(async (values) => {
        if (!selectMediaImageObject) {
          showErrorMsg('Thumb is required!');
          return;
        }
        if (!selectMediaAudioObject) {
          showErrorMsg('Audio is required!');
          return;
        }

console.log(values, "kndjhdiuhio");

       let formData= new FormData();
formData.append("title",values.title)
formData.append("categoryId",values.category_id)
formData.append("lang",values.lang)
formData.append("musicImage",selectMediaImageObject)
formData.append("musicFile",selectMediaAudioObject)


        setIsFormSubmitting(true);
        apiMethod(url, formData)
          .then((res) => {
            if (res.status === 200) {
              showSuccessMsg(`Music Added successfully!`);
              resetAllData();
              callback();
              // updateFirebaseGlobalAppSettings(
              //   GLOBAL_APP_SETTINGS_KEYS.watch_content
              // );
            } else if (
              res?.status &&
              API_V2_ERROR_CODES.includes(res?.status)
            ) {
              showErrorMsg(res?.message);
            }
            setIsFormSubmitting(false);
          })
          .catch((err) => {
            showErrorMsg(err);
            setIsFormSubmitting(false);
          });
      })
      .catch((errorInfo) => errorInfo);

}


const fetchCategoryListApi=()=>{

  let url=URLS_V2?.MUSIC_CATEGORY?.MUSIC_CATEGORY_LIST

  httpGet(url)
  .then((res)=>{
    if(res?.status==200){
setCategoriesList(res?.data)
    }
  })
  .catch((err)=>{
    
  })
}



let modalFooterButtons = [
  <Button
    key="add"
    type="primary"
    onClick={handleAddEditContent}
    disabled={
      isFormSubmitting || isMediaImageUpdating || isMediaAudioUpdating
    }
  >
    {isFormSubmitting ? 'Loading...' : activeMode == "Add" ? 'Add Music' : 'Update Music'}
  </Button>,
  <Button
    key="close"
    onClick={handleOnCloseModal}
    disabled={isFormSubmitting}
  >
    Close
  </Button>,
];


  return (
    <div>
      <MusicContentModalBox>
      <Modal
          title={activeMode=='Add' && getModalTitle || activeMode =='View' && 'View Music' || activeMode =='Edit' && 'Update Music' }
          visible={Boolean(activeMode)}
          onOk={handleOk}
          onCancel={handleOnCloseModal}
          className="expense-modal"
          maskClosable={false}
          width={550}
          forceRender={true}
          footer={activeMode !== 'View' && modalFooterButtons}
        >
{!isFetchWatch ? (
            <Form form={form} layout="vertical">
              <Row gutter={16}>
                <Col className="gutter-row" xs={24} sm={12}>
                  <Form.Item
                    name="title"
                    label="Title"
                    validateTrigger={handleValidateFieldNames('title')}
                    rules={[
                      {
                        required: true,
                        message: 'Please enter title!',
                      },
                    ]}
                  >
                    <Input
                      type="text"
                      placeholder="Title"
                      disabled={activeMode == 'View'}
                      onBlur={() =>
                        setValidateFieldsName([...validateFieldsName, 'title'])
                      }
                    />
                  </Form.Item>
                </Col>
                <Col className="gutter-row" xs={24} sm={12}>
                  <Form.Item
                    name="category_id"
                    label="Category"
                    rules={[
                      {
                        required: true,
                        message: 'Please select category!',
                      },
                    ]}
                  >
                    <Select
                      disabled={activeMode == 'View'}
                      placeholder={
                        isCategoriesListFetching
                          ? 'Fetching...'
                          : 'Select Category'
                      }
                      showSearch
                      optionFilterProp="children"
                      filterOption={(input, option) =>
                        option.children
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      }
                    >
                      {categoriesList &&
                        categoriesList.length > 0 &&
                        categoriesList.map((category, index) => (
                          <Option value={category?._id} key={index}>
                            {category?.title || ''}
                          </Option>
                        ))}
                    </Select>
                  </Form.Item>
                </Col>
                <MusicContentMedia
                  isMediaImageUpdating={isMediaImageUpdating}
                  setIsMediaImageUpdating={setIsMediaImageUpdating}
                  isMediaAudioUpdating={isMediaAudioUpdating}
                  setIsMediaAudioUpdating={setIsMediaAudioUpdating}
                  setSelectMediaAudioObject={setSelectMediaAudioObject}
                  setSelectMediaImageObject={setSelectMediaImageObject}
                  selectMediaImageObject={selectMediaImageObject}
                  selectMediaAudioObject={selectMediaAudioObject}
                  formDisabled={formDisabled}
                  activeMode={activeMode}
                  mediaInitialStructure={mediaInitialStructure}
                  contentId={contentId}
                  imageUrl={imageUrl}
                 setImageUrl={setImageUrl}
                 audioUrl={audioUrl}
                 setAudioUrl={setAudioUrl}
                />
                <Col className="gutter-row" xs={24}>
                  <Form.Item
                    disabled={activeMode == 'View'}
                    name="lang"
                    label="Language"
                    rules={[
                      {
                        required: true,
                        message: 'Please select language!',
                      },
                    ]}
                  >
                    <Select
                      placeholder="Select language"
                      disabled={activeMode == 'View'}
                    >
                      {WATCH_CONTENT_LANGUAGE_LIST &&
                        WATCH_CONTENT_LANGUAGE_LIST.length > 0 &&
                        WATCH_CONTENT_LANGUAGE_LIST.map(
                          (languageItem, index) => (
                            <Option value={languageItem.value} key={index}>
                              {languageItem.label || ''}
                            </Option>
                          )
                        )}
                    </Select>
                  </Form.Item>
                </Col>
              </Row>
            </Form>
          ) : (
            <CenteredSpin spinning={isFetchWatch} tip="Loading..." />
          )}



          </Modal>
      </MusicContentModalBox>
    </div>
  )
}

export default MusicContentModal
