import React, { useState, useEffect } from 'react';
import { oneOfType, array, object } from 'prop-types';
import { Layout, Menu, } from 'antd';
import { Link } from 'react-router-dom';
import MainHeader from './header';
import { ASIDE_NAVIGATION } from 'constants/aside-navigation';
import { MainLayoutBox } from './_main.styled';
import logo from '../../../assets/images/logo.png';
import { getUserLocalStorage } from 'utils';
import { CloseOutlined } from '@ant-design/icons';
import "./main.css";

const { Sider, Content } = Layout;

const Main = (props) => {
  const { children } = props;
  const [collapsed, setCollapsed] = useState(false);
  const [selectedTab, setSelectedTab] = useState('');
  const [childSelectedTab, setChildSelectedTab] = useState('');
  const [navigationClickTracker, setNavigationClickTracker]=useState(0)

  const userData = getUserLocalStorage();

  const anchorTagMenuItems = ['Devices Status', 'Device Ratings'];

  const toggleSideMenu = () => {
  document.body.classList.toggle('body-offcanvas-class');
    setCollapsed(!collapsed);
  };

  useEffect(() => {
    let selectedTab = '';
    let childSelectedTab = '';
    ASIDE_NAVIGATION.forEach(({ link, id, isSubMenu, subMenu }) => {
      if (link === window.location.pathname) {
        selectedTab = id;
      } else if (isSubMenu && subMenu && subMenu.length > 0) {
        subMenu.forEach((menu) => {
          if (menu.link === window.location.pathname) {
            selectedTab = menu.id;
            childSelectedTab = menu.id;
          }else if(menu?.isSubMenu && menu?.subMenu && menu?.subMenu?.length > 0){
            menu.subMenu.forEach((nested)=>{
              if (nested.link === window.location.pathname) {
                selectedTab = nested.id
                childSelectedTab=menu.id
              }
            })
          }
        });
      }
    });
    setChildSelectedTab(childSelectedTab);
    setSelectedTab(selectedTab);
    if(navigationClickTracker>0){
    document.body.classList.remove('body-offcanvas-class');
    setCollapsed(false)
    }
    const sideNavigationCount=navigationClickTracker+1
    setNavigationClickTracker(sideNavigationCount)

  }, [window.location.pathname]);

  const renderSubMenu = (menuData) => {
    const { icon, name, id, subMenu, permissionKey } = menuData || {};

    return (
      <Menu.SubMenu
        icon={icon && React.createElement(icon)}
        title={name}
        key={id}
      >
        {subMenu &&
          subMenu.length > 0 &&
          subMenu.map((subMenuData) =>
            userData?.user_detail?.role?.role_type === 'superadmin' ||
            (permissionKey === 'V2' &&
              userData?.user_detail?.role?.permissions.includes(
                subMenuData.permissionKey
              )) ||
            userData?.user_detail?.role?.permissions.includes(permissionKey) ? (
              subMenuData?.subMenu ? (
                <React.Fragment key={subMenuData.id}>
                  {renderSubMenu(subMenuData)}
                </React.Fragment>
              ) : (
                renderMenuItem(subMenuData)
              )
            ) : null
          )}
      </Menu.SubMenu>
    );
  };

  const renderMenuItem = (menuData) => {
    const { icon, name, id, link } = menuData || {};
    return (
      <Menu.Item icon={icon && React.createElement(icon)} key={id}>
        {link ? (
          anchorTagMenuItems.includes(link) ? (
            <a href={link}>{name}</a>
          ) : (
            <Link to={link}>{name}</Link>
          )
        ) : (
          menuData?.name
        )}
      </Menu.Item>
    );
  };

  return (
    <MainLayoutBox>
      <Layout className='main-layout'>
        <Sider
          trigger={null}
          collapsible
          collapsed={!collapsed}
          className="main-sidebar"
        >
          <div className='offcanvasHeader'>
          <div className="logo"><Link to="/dashboard" >
            <img src={logo} width="38%" alt="binary-logo" />
            </Link>
          </div>
          
          <CloseOutlined className='offcanvasCloseBtn'  style={{ color:"white",fontSize:"20px" }} onClick={toggleSideMenu} />
          </div>
          <Menu
          className='sideBarMenu'
            mode="inline"
            selectedKeys={[selectedTab]}
            defaultOpenKeys={[childSelectedTab]}
          >
            {ASIDE_NAVIGATION &&
              ASIDE_NAVIGATION.length > 0 &&
              ASIDE_NAVIGATION.map((data) => {
                const { id, link, isSubMenu, permissionKey } = data || {};
                if (link === '/profile') return null;
                return userData?.user_detail?.role?.role_type ===
                  'superadmin' ||
                  permissionKey === 'V2' ||
                  userData?.user_detail?.role?.permissions.includes(
                    permissionKey
                  ) ? (
                  <React.Fragment key={id}>
                    {isSubMenu ? renderSubMenu(data) : renderMenuItem(data)}
                  </React.Fragment>
                ) : null;
              })}
          </Menu>
        </Sider>
        <div className='offCanvasLayer'></div>
        <Layout className="site-layout">
          <div style={{position:"fixed", width:"100%", zIndex:10}}>
          <MainHeader collapsed={collapsed} toggleSideMenu={toggleSideMenu} />

          </div>
          <div style={{ marginTop:"60px"}}>
          <Content className="site-layout-background"> {children}</Content>
          </div>
        </Layout>
      </Layout>
    </MainLayoutBox>
  );
};

Main.propTypes = {
  children: oneOfType([array, object]),
};

export default Main;
