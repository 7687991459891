import React from 'react';
import { Modal, Button, Row, Col } from 'antd';
import { string, func, bool, object } from 'prop-types';

const AppReleaseUnisocDetailsPreviewModal = (props) => {
  const {
    handleCloseModal,
    modalVisible,
    sectionName,
    selectedRecordsDetails,
  } = props;

  const modalTitle = `${sectionName}`;

  const handleCancelClick = () => {
    handleCloseModal();
  };

  let modalFooterButtons = [
    <Button key="close" onClick={handleCancelClick}>
      Close
    </Button>,
  ];

  const renderLableColumnRows = (
    label,
    value,
    rowGutter = 18,
    lableColSpacing = 6,
    valueColSpacing = 18,
    customStyle = {}
  ) => {
    return (
      <Row gutter={rowGutter} style={{ margin: '15px' }}>
        <Col className="gutter-row" xs={lableColSpacing}>
          <strong>{label}</strong>
        </Col>
        <Col className="gutter-row" xs={valueColSpacing}>
          <span style={customStyle}>{value}</span>
        </Col>
      </Row>
    );
  };
  return (
    <>
      <Modal
        title={modalTitle}
        visible={modalVisible}
        // onOk={handleOk}
        onCancel={handleCancelClick}
        // className="expense-modal"
        maskClosable={false}
        width={700}
        forceRender={true}
        footer={modalFooterButtons}
      >
        {renderLableColumnRows(
          'Version Name: ',
          selectedRecordsDetails?.version_name
        )}
        {renderLableColumnRows(
          'Version Code: ',
          selectedRecordsDetails?.version_code
        )}
        {renderLableColumnRows(
          'Description: ',
          selectedRecordsDetails?.description,
          18,
          6,
          18,
          { whiteSpace: 'pre-wrap' }
        )}
      </Modal>
    </>
  );
};

AppReleaseUnisocDetailsPreviewModal.propTypes = {
  handleCloseModal: func,
  modalVisible: bool,
  sectionName: string,
  selectedRecordsDetails: object,
};

export default AppReleaseUnisocDetailsPreviewModal;
