import React, { useState } from 'react';
import { string, func, bool } from 'prop-types';
import { Row, Col, Collapse, Button, Spin, Popconfirm } from 'antd';
import { useParams } from 'react-router-dom';
import { httpPut, httpDelete, URLS, showErrorMsg, showSuccessMsg } from 'utils';
import { CAMPAIGN_TYPES, MEDIA_TYPES } from 'constants/campaign';
import { UploadOutlined, EyeOutlined, DeleteOutlined } from '@ant-design/icons';
import { LoadingOutlined } from '@ant-design/icons';
import { CollapseBox } from './_media.styled';
import Preview from '../preview';

const { Panel } = Collapse;

const Media = (props) => {
  const {
    selectedCampaignType,
    selectMediaImage,
    setSelectMediaImage,
    setSelectMediaVideo,
    setIsMediaImageUpdating,
    isMediaImageUpdating,
    isMediaVideoUpdating,
    setIsMediaVideoUpdating,
    selectMediaVideo,
    showCancelBtn,
    isUploadDisable
  } = props;
  const params = useParams();
  const [modalViewType, setModalViewType] = useState('');

  const closeModal = () => {
    setModalViewType(false);
  };

  const handlePreviewType = ({ type }) => {
    setModalViewType(type);
  };

  const handleImageFile = async (e) => {
    let isSuccess = false;
    const file = e.target.files[0];
    if (
      file.type !== 'image/apng' &&
      file.type !== 'image/avif' &&
      file.type !== 'image/gif' &&
      file.type !== 'image/jpeg' &&
      file.type !== 'image/png' &&
      file.type !== 'image/svg+xml' &&
      file.type !== 'image/webp'
    ) {
      showErrorMsg(
        `Only .apng, .avif, .gif, .jpg, .jpeg, .jfif, .pjpeg, .pjp, .png, .svg, .webp file types are accepted.`
      );
      return;
    }
    const imageDimenstions = await getImageDimensions(file);
    if (!imageDimenstions) return;
    const { height, width } = imageDimenstions || {};
    if (
      width === 1920 &&
      height === 1200 &&
      (selectedCampaignType === CAMPAIGN_TYPES.BRAND_AWARENESS ||
        selectedCampaignType === CAMPAIGN_TYPES.WEBSITE_VISITS)
    ) {
      isSuccess = true;
    } else if (
      width === 1920 &&
      height === 900 &&
      selectedCampaignType === CAMPAIGN_TYPES.CAPTURE_LEADS
    ) {
      isSuccess = true;
    }

    if (isSuccess) {
      setIsMediaImageUpdating(true);
      const url = URLS?.CAMPAIGN?.UPLOAD_MEDIA_IMAGE.replace(
        '#ID#',
        params?.id
      );
      const formData = new FormData();
      formData.append('files', e.target.files[0]);
      httpPut(url, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
        .then((res) => {
          if (res.status === 200) {
            showSuccessMsg('Campaign image saved successfully!');
            setSelectMediaImage(res?.data?.media_image_url || '');
          } else if (res?.error) {
            showErrorMsg(res?.error);
          }
          setIsMediaImageUpdating(false);
        })
        .catch((err) => {
          showErrorMsg(err);
          setIsMediaImageUpdating(false);
        });
    } else {
      document.getElementById('media_image_url').value = '';
      if (
        selectedCampaignType === CAMPAIGN_TYPES.BRAND_AWARENESS ||
        selectedCampaignType === CAMPAIGN_TYPES.WEBSITE_VISITS
      ) {
        showErrorMsg('Image resolution must be 1920x1200');
      } else if (selectedCampaignType === CAMPAIGN_TYPES.CAPTURE_LEADS) {
        showErrorMsg('Image resolution must be 1920x900');
      }
    }
  };

  // const getVideoDimensions = (file) => {
  //   return new Promise((resolve) => {
  //     const fileUrl = URL.createObjectURL(file);
  //     const videoEl = document.createElement('video');
  //     videoEl.src = fileUrl;
  //     videoEl.addEventListener('loadedmetadata', function () {
  //       const height = this.videoHeight;
  //       const width = this.videoWidth;
  //       resolve({ height, width });
  //     });
  //   });
  // };

  const getImageDimensions = (file) => {
    return new Promise((resolve) => {
      let images = new Image();
      images.onload = () => {
        resolve({ height: images.height, width: images.width });
      };
      images.onerror = (error) => {
        showErrorMsg(error);
        resolve('');
      };

      let fileReader = new FileReader();
      fileReader.onload = () => (images.src = fileReader.result);
      fileReader.onerror = (error) => {
        showErrorMsg(error);
        resolve('');
      };
      if (file) {
        fileReader.readAsDataURL(file);
      }
    });
  };

  const handleVideoFile = async (e) => {
    const file = e.target.files[0];
    // const { height, width } = await getVideoDimensions(file);
    if (
      file.type !== 'video/mp4' &&
      file.type !== 'video/mpeg' &&
      file.type !== 'video/ogg' &&
      file.type !== 'video/quicktime' &&
      file.type !== 'video/webm' &&
      file.type !== 'video/x-m4v' &&
      file.type !== 'video/ms-asf' &&
      file.type !== 'video/x-msvideo' &&
      file.type !== 'video/x-ms-wmv'
    ) {
      showErrorMsg(
        `Only .3gp, .mp4, .m4a, .m4p, .m4b, .m4r, .m4v, .m1v,	.ogg, .mov, .qt,
         .webm, .m4v, .asf, .wma, .wmv, .wmv, .avi file types are accepted.`
      );
      return;
    }

    setIsMediaVideoUpdating(true);
    const url = URLS?.CAMPAIGN?.UPLOAD_MEDIA_VIDEO.replace('#ID#', params?.id);
    const formData = new FormData();
    formData.append('files', e.target.files[0]);
    httpPut(url, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
      .then((res) => {
        if (res.status === 200) {
          showSuccessMsg('Campaign video saved successfully!');
          setSelectMediaVideo(res?.data?.media_video_url || '');
        } else if (res?.error) {
          showErrorMsg(res?.error);
        }
        setIsMediaVideoUpdating(false);
      })
      .catch((err) => {
        showErrorMsg(err);
        setIsMediaVideoUpdating(false);
      });
  };

  const handleDeleteFile = ({ fileUrl, type }) => {
    if (type === MEDIA_TYPES.VIDEO) {
      setIsMediaVideoUpdating(true);
    }
    if (type === MEDIA_TYPES.IMAGE) {
      setIsMediaImageUpdating(true);
    }
    const url = URLS?.CAMPAIGN?.DELETE_GC_FILE.replace('#ID#', params?.id)
      .replace('#FILE_URL#', encodeURIComponent(fileUrl))
      .replace('#FILE_TYPE#', type);
    httpDelete(url)
      .then((res) => {
        if (res.status === 200) {
          showSuccessMsg(`${type} file deleted successfully!`);
          if (type === MEDIA_TYPES.IMAGE) {
            setSelectMediaImage('');
          }
          if (type === MEDIA_TYPES.VIDEO) {
            setSelectMediaVideo('');
          }
        } else if (res?.error) {
          showErrorMsg(res?.error);
        }
        setIsMediaImageUpdating(false);
        setIsMediaVideoUpdating(false);
      })
      .catch((err) => {
        showErrorMsg(err);
        setIsMediaVideoUpdating(false);
        setIsMediaImageUpdating(false);
      });
  };

  return (
    <CollapseBox>
      {modalViewType && (
        <Preview
          handleCancel={closeModal}
          modalViewType={modalViewType}
          setModalViewType={setModalViewType}
          handleOk={closeModal}
          selectMediaVideo={selectMediaVideo}
          selectMediaImage={selectMediaImage}
          selectedCampaignType={selectedCampaignType}
        />
      )}
      <Collapse defaultActiveKey={['1']}>
        <Panel header="Media" key="1">
          <Row gutter={16}>
            <Col className="gutter-row" xs={24}>
              <div className="media-btns">
                <>
                  {isMediaVideoUpdating ? (
                    <div>
                      <Button
                        type="primary"
                        disabled
                        className="loader-btn btn-mr-rt"
                      >
                        <Spin
                          indicator={
                            <LoadingOutlined
                              style={{
                                fontSize: 16,
                                marginRight: 10,
                                color: '#808080b8',
                              }}
                              spin
                            />
                          }
                        />
                        Loading...
                      </Button>
                    </div>
                  ) : (
                    <>
                      {selectMediaVideo ? (
                        <div className="video-block-box">
                          <Button
                            type="primary"
                            className="preview-btn btn-mr-rt"
                            onClick={() =>
                              handlePreviewType({ type: MEDIA_TYPES.VIDEO })
                            }
                          >
                            <EyeOutlined />
                            {'Preview Video'}
                          </Button>
                      {  showCancelBtn &&  <Popconfirm
                            title="Sure to delete video file?"
                            onConfirm={() =>
                              handleDeleteFile({
                                fileUrl: selectMediaVideo,
                                type: MEDIA_TYPES.VIDEO,
                              })
                            }
                          >
                            <Button
                              danger
                              shape="circle"
                              htmlType="submit"
                              className="video-delete-btn"
                            >
                              <DeleteOutlined />
                            </Button>
                          </Popconfirm>}
                        </div>
                      ) : (
                        <div className="custom-upload-btn">
                          <label htmlFor="media_video_url">
                            <div>
                              <UploadOutlined />
                              {'Upload Video'}
                            </div>
                            <input
                              id="media_video_url"
                              type="file"
                              name="media_video_url"
                              style={{ display: 'none' }}
                              onChange={handleVideoFile}
                              accept="video/mp4,video/x-m4v,video/*"
                              disabled={isUploadDisable}
                            />
                          </label>
                        </div>
                      )}
                    </>
                  )}
                </>
                {selectedCampaignType !== CAMPAIGN_TYPES.BRAND_AWARENESS && (
                  <>
                    {isMediaImageUpdating ? (
                      <Button type="primary" disabled className="loader-btn">
                        <Spin
                          indicator={
                            <LoadingOutlined
                              style={{
                                fontSize: 16,
                                marginRight: 10,
                                color: '#808080b8',
                              }}
                              spin
                            />
                          }
                        />
                        Loading...
                      </Button>
                    ) : (
                      <>
                        {selectMediaImage ? (
                          <div className="block-box">
                            <Button
                              type="primary"
                              className="preview-btn btn-mr-rt"
                              onClick={() =>
                                handlePreviewType({ type: MEDIA_TYPES.IMAGE })
                              }
                            >
                              <EyeOutlined />
                              Preview Image
                            </Button>
                         {showCancelBtn && <Popconfirm
                            title="Sure to delete image file?"
                            onConfirm={() =>
                              handleDeleteFile({
                                fileUrl: selectMediaImage,
                                type: MEDIA_TYPES.IMAGE,
                              })
                            }
                          >
                            <Button
                              danger
                              shape="circle"
                              htmlType="submit"
                              className="delete-btn"
                            >
                              <DeleteOutlined />
                            </Button>
                          </Popconfirm>}
                          </div>
                        ) : (
                          <div className="custom-upload-btn">
                            <label htmlFor="media_image_url">
                              <div>
                                <UploadOutlined />
                                Upload Image
                              </div>
                              <input
                                id="media_image_url"
                                type="file"
                                name="media_image_url"
                                style={{ display: 'none' }}
                                onChange={handleImageFile}
                                accept="image/x-png,image/gif,image/jpeg,image/webp"
                                disabled={isUploadDisable}
                              />
                            </label>
                          </div>
                        )}
                      </>
                    )}
                  </>
                )}
              </div>
            </Col>
          </Row>
          {selectMediaVideo && selectMediaImage && (
            <Button
              type="primary"
              className="final-btn"
              onClick={() =>
                handlePreviewType({ type: MEDIA_TYPES.VIDEO_IMAGE })
              }
            >
              <EyeOutlined />
              Final Preview
            </Button>
          )}
        </Panel>
      </Collapse>
    </CollapseBox>
  );
};

Media.propTypes = {
  isMediaImageUpdating: bool,
  isMediaVideoUpdating: bool,
  selectMediaImage: string,
  selectMediaVideo: string,
  selectedCampaignType: string,
  setIsMediaImageUpdating: func,
  setIsMediaVideoUpdating: func,
  setSelectMediaImage: func,
  setSelectMediaVideo: func,
};

export default Media;
