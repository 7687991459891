import styled from 'styled-components';

export const InfoWindowBox = styled.div`
  width: 250px;
  height: auto;
  border-radius: 5px;
  background: white;
  padding: 10px;
  h5 {
    font-size: 15px;
  }
  h6 {
    font-size: 13px;
  }
  .info-close {
    display: flex;
    font-size: 20px;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
    h5 {
      margin-bottom: 0px;
    }
    .button-box {
      padding: 0px 9px 3px 9px;
      border-radius: 50px;
      box-shadow: 1px 1px 10px #e3e3e3;
      cursor: pointer;
    }
  }
`;
