import styled from 'styled-components';

export const CollapseBox = styled.div`
  margin: 10px;
`;

export const Loader = styled.div`
  color: grey;
  font-size: 14px;
`;
