import React, { useState } from 'react';
// import { MODE_TYPES } from 'constants/common';
import BinaryosReleaseHistoryList from './release-history-list';
import CreateNewRelease from './create-new-release';
import { Button, Col, Row } from 'antd';
import { DownloadOutlined } from '@ant-design/icons';

const UnisocReleaseManager = () => {
  const [packageName, setPackageName] = useState('');
  const [appCurrentVersion, setAppCurrentVersion] = useState({});
  const [releaseFormVisible, setReleaseFormVisible] = useState(false);

  const resetAllData = () => {
    setPackageName('');
    setReleaseFormVisible(false);
    setAppCurrentVersion({});
  };

  const renderCurrentVersion = (currentAppVersionInfo) => {
    return (
      currentAppVersionInfo &&
      Object.keys(currentAppVersionInfo).length >= 1 && (
        <div className="while-bg-box">
          <Row>
            <Col md={12} style={{ display: 'flex', alignItems: 'center' }}>
              <span>{'Current Version: '}</span>
              <span className="ant-form-text f-w-600 m-l-5">
                {currentAppVersionInfo?.version_code}
              </span>
            </Col>
            <Col md={12} style={{ display: 'flex', justifyContent: 'end' }}>
              <a href={currentAppVersionInfo?.url} download>
                <Button type="primary" className="edit-btn">
                  <DownloadOutlined />
                  {'Download Current Version'}
                </Button>
              </a>
            </Col>
          </Row>
        </div>
      )
    );
  };

  return (
    <>
      {releaseFormVisible ? (
        <CreateNewRelease
          packageName={packageName}
          resetAllData={resetAllData}
          appCurrentVersion={appCurrentVersion}
          setAppCurrentVersion={setAppCurrentVersion}
          renderCurrentVersion={renderCurrentVersion}
        />
      ) : (
        <BinaryosReleaseHistoryList
          packageName={packageName}
          setPackageName={setPackageName}
          setReleaseFormVisible={setReleaseFormVisible}
          appCurrentVersion={appCurrentVersion}
          setAppCurrentVersion={setAppCurrentVersion}
          renderCurrentVersion={renderCurrentVersion}
        />
      )}
    </>
  );
};

export default UnisocReleaseManager;
