import React, { useEffect, useRef, useState } from 'react';
import './Media.css';
import { Button, Col, Form, Input, Popconfirm, Row, Spin } from 'antd';
import {
  DeleteOutlined,
  EyeOutlined,
  LoadingOutlined,
  UploadOutlined,
  CloseOutlined,
} from '@ant-design/icons';
import {
  URLS_V2,
  httpPost,
  httpPut,
  showErrorMsg,
  showSuccessMsg,
} from 'utils';
import { MEDIA_TYPES } from 'constants/campaign';
import Preview from 'components/watch-content-modal/preview';
import replaceIcon from '../../../../../assets/images/replace-icon.png'

const Media = (props) => {
  const {
    campaignId,
    setCampaignId,
    mediaForm,
    setMediaForm,
    campaignVideoUrl,
    setCampaignVideoUrl,
    campaignImageUrl,
    setCampaignImageUrl,
    uploadVideoWarning,
    setUploadVideoWarning,
    editModeOff,
    videoDurationWarning,
    imageDurationWarning,
  } = props;
  const [modalViewType, setModalViewType] = useState('');
  const [selectMediaVideo, setSelectMediaVideo] = useState('');
  const [showVideoPreview, setShowVideoPreview] = useState(false);
  const [selectMediaImage, setSelectMediaImage] = useState('');
  const [showImagePreview, setShowImagePreview] = useState(false);
  const [showVideoLoader, setShowVideoLoader] = useState(false);
  const [showImageLoader, setShowImageLoader] = useState(false)
  const [showImageDeleteLoader, setShowImageDeleteLoader] = useState(false)
  const [showVideoDeleteLoader, setShowVideoDeleteLoader] = useState(false)
  let cancelFileUpload=useRef(null);

  const handleVideoFile = async (e) => {

    const file = e.target.files[0];
    // const { height, width } = await getVideoDimensions(file);

    setShowVideoLoader(true);
    if (file?.size > 314572800
    ) {
      showErrorMsg("File size above 300mb is not allowed.")
      setShowVideoLoader(false);
      return;
    }
    if (
      file.type !== 'video/mp4'
      // file.type !== 'video/mpeg' &&
      // file.type !== 'video/ogg' &&
      // file.type !== 'video/quicktime' &&
      // file.type !== 'video/webm' &&
      // file.type !== 'video/x-m4v' &&
      // file.type !== 'video/ms-asf' &&
      // file.type !== 'video/x-msvideo' &&
      // file.type !== 'video/x-ms-wmv'
    ) {

      showErrorMsg(
        `Only .mp4 file type accepted.`
      );
      setShowVideoLoader(false);
      return;
    }

    const url = URLS_V2?.CAMPAIGNS?.UPLOAD_MEDIA_VIDEO.replace(
      '#ID#',
      campaignId
    );
    const formData = new FormData();
    formData.append('files', e.target.files[0]);
    httpPut(url, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    },
    cancelFileUpload
    )
      .then((res) => {
        if (res.status === 200) {
          setShowVideoLoader(false);
          showSuccessMsg('Campaign video saved successfully!');
          setSelectMediaVideo(res?.data?.media_video_url || '');
          setCampaignVideoUrl(res?.data?.media_video_url);
          setUploadVideoWarning(false);
          setShowVideoPreview(true);
        } else if (res?.error) {
          setShowVideoLoader(false);
          showErrorMsg(res?.error);
        }
        setShowVideoLoader(false);
      })
      .catch((err) => {
        setShowVideoLoader(false);
        showErrorMsg(err);
      });
  };

  const handleImageFile = async (e) => {
    const file = e.target.files[0];
    setShowImageLoader(true)
    if (file?.size > 5242880) {
      showErrorMsg("File size above 5mb is not allowed.")
      setShowImageLoader(false)
      return;
    }
    if (
      file.type !== 'image/png' &&
      file.type !== 'image/jpg' &&
      file.type !== 'image/jpeg'

      // file.type !== 'image/apng' &&
      // file.type !== 'image/avif' &&
      // file.type !== 'image/gif' &&
      // file.type !== 'image/jpeg' &&
      // file.type !== 'image/svg+xml' &&
      // file.type !== 'image/webp'
    ) {
      setShowImageLoader(false)

      showErrorMsg(
        `Only .jpg, .png, file types are accepted.`
      );
      return;
    }

    // const imageDimenstions = await getImageDimensions(file);
    // if (!imageDimenstions) return;
    // const { height, width } = imageDimenstions || {};
    // if (
    //   width === 1920 &&
    //   height === 1200
    //   //   (selectedCampaignType === CAMPAIGN_TYPES.BRAND_AWARENESS.value ||
    //   //     selectedCampaignType === CAMPAIGN_TYPES.WEBSITE_VISITS.value)
    // ) {
    //   isSuccess = true;
    // } else if (
    //   width === 1920 &&
    //   height === 900
    //   //   selectedCampaignType === CAMPAIGN_TYPES.CAPTURE_LEADS
    // ) {
    //   isSuccess = true;
    // }

    // if (isSuccess) {
    const url = URLS_V2?.CAMPAIGNS?.UPLOAD_MEDIA_IMAGE.replace(
      '#ID#',
      campaignId
    );
    const formData = new FormData();
    formData.append('files', e.target.files[0]);
    httpPut(url, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
      .then((res) => {
        if (res.status === 200) {
          showSuccessMsg('Campaign image saved successfully!');
          setCampaignImageUrl(res?.data?.media_image_url);

          setSelectMediaImage(res?.data?.media_image_url || '');
          setShowImagePreview(true);
          setShowImageLoader(false)

        } else if (res?.error) {
          setShowImageLoader(false)
          showErrorMsg(res?.error);
        }else{
          showErrorMsg(res?.message)
        }
        setShowImageLoader(false)
      })
      .catch((err) => {
        setShowImageLoader(false)
        showErrorMsg(err);
      });
    // } else {
    //   document.getElementById('media_image_url').value = '';
    //   //   if (
    //   //     selectedCampaignType === CAMPAIGN_TYPES.BRAND_AWARENESS.value ||
    //   //     selectedCampaignType === CAMPAIGN_TYPES.WEBSITE_VISITS.value
    //   //   ) {
    //   //     console.log('anotherCheck');

    //   //     showErrorMsg('Image resolution must be 1920x1200');
    //   //   } else if (selectedCampaignType === CAMPAIGN_TYPES.CAPTURE_LEADS.value) {
    //   //     console.log('anotherCheck');

    //   showErrorMsg('Image resolution must be 1920x900');
    //   //   }
    // }
  };
  const cancelRequest = () => {
    if(cancelFileUpload.current){
      cancelFileUpload.current("cancelled")
    } 
    cancelFileUpload=null;
  };

  useEffect(() => {
    if (campaignImageUrl) {
      setShowImagePreview(true)
      setSelectMediaImage(campaignImageUrl)
    }
    if (campaignVideoUrl) {
      setSelectMediaVideo(campaignVideoUrl)
      setShowVideoPreview(true)
    }
  }, [campaignImageUrl, campaignVideoUrl])

  const getImageDimensions = (file) => {
    return new Promise((resolve) => {
      let images = new Image();
      images.onload = () => {
        resolve({ height: images.height, width: images.width });
      };
      images.onerror = (error) => {
        showErrorMsg(error);
        resolve('');
      };

      let fileReader = new FileReader();
      fileReader.onload = () => (images.src = fileReader.result);
      fileReader.onerror = (error) => {
        showErrorMsg(error);
        resolve('');
      };
      if (file) {
        fileReader.readAsDataURL(file);
      }
    });
  };

  const handlePreviewType = ({ type }) => {
    setModalViewType(type);
  };

  const handleDeleteFile = ({ fileUrl, type }) => {

    if(type=="Video"){
setShowVideoPreview(false)
    }

    if(type=="Image"){
setShowImagePreview(false)
    }

    // let requiredUrl;
    // if (type == "Video") {
    //   setShowVideoDeleteLoader(true)
    //   requiredUrl = `campaign_video_url=${fileUrl}`
    // }
    // if (type == "Image") {
    //   setShowImageDeleteLoader(true)
    //   requiredUrl = `campaign_image_url=${fileUrl}`
    // }

    // const url = URLS_V2.CAMPAIGNS.DELETE_MEDIA_FILE.replace('#URL#', requiredUrl)
    // httpPost(url)
    //   .then((res) => {
    //     if (res.status === 200) {
    //       showSuccessMsg(`${type} file deleted successfully!`);
    //       if (type == "Video") {
    //         setSelectMediaVideo('');
    //         setShowVideoDeleteLoader(false)
    //         setCampaignVideoUrl("");
    //         setShowVideoPreview(false)
    //       }
    //       if (type == "Image") {
    //         setSelectMediaImage('');
    //         setShowImageDeleteLoader(false)
    //         setCampaignImageUrl("");
    //         setShowImagePreview(false);
    //       }
    //     } else if (res?.error) {
    //       setShowVideoDeleteLoader(false)
    //       setShowImageDeleteLoader(false)
    //       showErrorMsg(res?.error);
    //     } else {
    //       showErrorMsg(res?.message)
    //     }
    //     setShowVideoDeleteLoader(false)
    //     setShowImageDeleteLoader(false)
    //   })
    //   .catch((err) => {
    //     setShowVideoDeleteLoader(false)
    //     setShowImageDeleteLoader(true)
    //     showErrorMsg(err.message);
    //   });
  };

  const closeModal = () => {
    setModalViewType(false);
  };

  const mediaOnChange = (e) => {
    setMediaForm({ ...mediaForm, [e.target.name]: e.target.value });
  };

  return (
    <div className="media-section">
      {modalViewType && (
        <Preview
          handleCancel={closeModal}
          modalViewType={modalViewType}
          setModalViewType={setModalViewType}
          handleOk={closeModal}
          selectMediaVideo={selectMediaVideo}
          selectMediaImage={selectMediaImage}
        //   selectedCampaignType={selectedCampaignType}
        />
      )}

      <Row gutter={16}>
        <Col className="gutter-row" xs={24} sm={20} md={12}>
          <div className="title-text">Media</div>

          <div className="main">
            {showVideoPreview === false ? (
              <Col className="gutter-row" xs={24} sm={12} md={12}>
                <span style={{ fontSize: "11px" }}>Only .mp4, Max Size-300mb</span>
                <div
                  className={`custom-upload-btn ${uploadVideoWarning && 'warningLayout'
                    }`}
                >
                  {!showVideoLoader ? (
                    <label htmlFor="media_video_url">
                      <div>
                        <UploadOutlined />
                        {'Upload Video'}
                      </div>
                      <input
                        id="media_video_url"
                        type="file"
                        name="media_video_url"
                        style={{ display: 'none' }}
                        onChange={handleVideoFile}
                        accept="video/mp4,video/x-m4v,video/*"
                        disabled={editModeOff}
                      />
                    </label>
                  ) : (
                    <>
                      <Spin
                        indicator={
                          <LoadingOutlined
                            style={{
                              fontSize: 16,
                              marginRight: 10,
                              color: '#808080b8',
                            }}
                            spin
                          />
                        }
                      />
                      Loading...
                      <Popconfirm
                        onConfirm={cancelRequest}
                        title="Sure to want to cancel uploading?"
                      >
                        <Button danger shape="circle" disabled={editModeOff} style={{ marginLeft: "4px" }} >
                          <CloseOutlined />
                        </Button>
                      </Popconfirm>
                    </>
                  )}
                </div>
              </Col>
            ) : (
              <Col className="gutter-row" xs={24} sm={12} md={12}>
                <span className={`${showVideoPreview &&"file-format"}`}>Only .mp4, Max Size-300mb</span>

                <div className="video-btn-container">
                  <Button
                    type="primary"
                    className="video-btn"
                    onClick={() =>
                      handlePreviewType({ type: MEDIA_TYPES.VIDEO })
                    }
                  >
                    <EyeOutlined />
                    {'Preview Video'}
                  </Button>

                  <div className="video-dlt-btn">
                    <Popconfirm
                      title="Sure to Replace video file?"
                      onConfirm={() =>
                        handleDeleteFile({
                          fileUrl: selectMediaVideo,
                          type: MEDIA_TYPES.VIDEO,
                        })
                      }
                      disabled={editModeOff}
                    >
                      {showVideoDeleteLoader ? <Spin
                        indicator={
                          <LoadingOutlined
                            style={{
                              fontSize: 16,
                              marginRight: 10,
                              color: '#808080b8',
                            }}
                            spin
                          />
                        }
                      /> : <Button shape="circle" htmlType="submit" disabled={editModeOff} style={{background:"white", display:"flex", alignItems:"center", justifyContent:"center", border:"none"}}>
                        {/* <DeleteOutlined /> */}
                        <img src={replaceIcon} style={{height:"30px", width:"30px"}} ></img>
                      </Button>}
                    </Popconfirm>
                  </div>
                </div>
              </Col>
            )}

            {showImagePreview === false ? (
              <Col className="gutter-row" xs={24} sm={12} md={12}>
                <span style={{ fontSize: "11px" }}>Only .jpg .png, Max Size-5mb</span>
                <div className="custom-upload-btn">
                  {showImageLoader ?
                    <>
                      <Spin
                        indicator={
                          <LoadingOutlined
                            style={{
                              fontSize: 16,
                              marginRight: 10,
                              color: '#808080b8',
                            }}
                            spin
                          />
                        }
                      />
                      Loading...
                    </>

                    : <label htmlFor="media_image_url">
                      <div>
                        <UploadOutlined />
                        Upload Image
                      </div>
                      <input
                        id="media_image_url"
                        type="file"
                        name="media_image_url"
                        style={{ display: 'none' }}
                        onChange={handleImageFile}
                        accept="image/x-png,image/gif,image/jpeg,image/webp"
                        disabled={editModeOff}
                      />
                    </label>
                  }
                </div>
              </Col>
            ) : (
              <Col className="gutter-row" xs={24} sm={12} md={12}>
                <span className={`${showImagePreview &&"file-format"}`}>Only .mp4, Max Size-300mb</span>

                <div className="video-btn-container">
                  <Button
                    type="primary"
                    className="video-btn"
                    onClick={() =>
                      handlePreviewType({ type: MEDIA_TYPES.IMAGE })
                    }
                  >
                    <EyeOutlined />
                    Preview Image
                  </Button>
                  <div className="video-dlt-btn">
                    <Popconfirm
                      title="Sure to delete image file?"
                      onConfirm={() =>
                        handleDeleteFile({
                          fileUrl: selectMediaImage,
                          type: MEDIA_TYPES.IMAGE,
                        })
                      }
                      disabled={editModeOff}
                    >
                      {
                        showImageDeleteLoader ?
                          <Spin
                            indicator={
                              <LoadingOutlined
                                style={{
                                  fontSize: 16,
                                  marginRight: 10,
                                  color: '#808080b8',
                                }}
                                spin
                              />
                            }
                          /> :
                          <Button 
                          // danger
                           shape="circle" 
                           htmlType="submit" 
                           disabled={editModeOff}
                           style={{background:"white", display:"flex", alignItems:"center", justifyContent:"center", border:"none"}}
                            >
                            {/* <DeleteOutlined /> */}
                        <img src={replaceIcon} style={{height:"30px", width:"30px"}} ></img>

                          </Button>
                      }
                    </Popconfirm>
                  </div>
                </div>
              </Col>
            )}
            <Col className="gutter-row" xs={24} sm={12} md={12}>
              <Form.Item name="Video Duration" label="Video Duration">
                <Input
                  type="number"
                  name="campaign_video_duration"
                  value={mediaForm?.campaign_video_duration}
                  onChange={mediaOnChange}
                  disabled={editModeOff}
                  className={`input-relative ${videoDurationWarning && 'warningLayout'}`}
                ></Input>
                <span className="input-absolute">Sec</span>
              </Form.Item>
            </Col>
            <Col className="gutter-row" xs={24} sm={12} md={12}>
              <Form.Item name="Image Duration" label="Image Duration">
                <Input
                  type="number"
                  className={`input-relative ${imageDurationWarning && 'warningLayout'}`}
                  name="campaign_image_duration"
                  value={mediaForm?.campaign_image_duration}
                  onChange={mediaOnChange}
                  disabled={editModeOff}
                ></Input>
                <span className="input-absolute">Sec</span>
              </Form.Item>
            </Col>
            <Col className="gutter-row" xs={24} sm={12} md={12}>
              <Form.Item name="Video Volume" label="Video Volume">
                <Input
                  type="number"
                  className="input-relative"
                  name="campagin_video_volume"
                  value={mediaForm?.campagin_video_volume}
                  onChange={mediaOnChange}
                  disabled={editModeOff}
                ></Input>
                <span className="input-absolute">%</span>
              </Form.Item>
            </Col>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default Media;