import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { httpPut, URLS, showErrorMsg, httpPost } from 'utils';
import { CampaignQrBox } from './_campaign-qr.styled';
import axios from 'axios';

const CampaignQr = () => {
  const [, setIsFetchingRedirectUrl] = useState(false);
  const params = useParams();

  const getIp = async () => {
    const getIpData = await axios.get('https://api.ipify.org?format=json');
    if (getIpData.data.ip) {
      return getIpData.data.ip;
    }
    return '';
  };

  useEffect(async () => {
    const ip = await getIp();
    if (!ip) {
      showErrorMsg('Please Refresh the Page');
      return;
    }
    const url = URLS?.CAMPAIGN?.UPDATE_NO_OF_TIMES_VISITED_BY_QR.replace(
      '#ID#',
      params?.id
    );

    setIsFetchingRedirectUrl(true);
    await httpPost(URLS?.SCAN_LOGS.SCAN_LOG, {
      campaign_id: params.id || '',
      ip_address: ip || '',
    })
      .then((res) => {
        if (res.status === 200) {
          console.log('scanlogs sent succesfully');
        } else if (res?.error) {
          showErrorMsg(res?.error);
        }
      })
      .catch((err) => {
        showErrorMsg(err);
      });
    httpPut(url)
      .then((res) => {
        if (res.status === 200) {
          if (res?.data?.redirect_url) {
            window.location = res?.data?.redirect_url;
          } else {
            showErrorMsg('Not found redirect url');
          }
        } else if (res?.error) {
          showErrorMsg(res?.error);
        }
        setIsFetchingRedirectUrl(false);
      })
      .catch((err) => {
        showErrorMsg(err);
        setIsFetchingRedirectUrl(false);
      });
  }, []);

  return (
    <CampaignQrBox>
      <h4>We are redirecting you to redirectURL…</h4>
    </CampaignQrBox>
  );
};

export default CampaignQr;
