import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import {
  Table,
  Button,
  Form,
  Input,
  DatePicker,
  Row,
  Col,
  Select,
  Popconfirm,
  Spin,
} from 'antd';
import { dateFormatter, httpGet, httpPut, showErrorMsg, showSuccessMsg, URLS_V2 } from 'utils';
import { DeleteOutlined, EyeOutlined, LoadingOutlined } from '@ant-design/icons';
import CommonBreadCrumb from 'components/common-breadcrumb';
import { Loader } from '../_campaigns.styled';
import { debounce } from 'lodash';

const breakCrumbList = [
  { label: 'Home', link: '/dashboard' },
  { label: 'In-Active campaigns' },
];

const { RangePicker } = DatePicker;

const { Option } = Select;

const campaignStatusSelectOptions = (
  <>
    <Option key={'DRAFT'} value={'DRAFT'}>
      {'DRAFT'}
    </Option>
    <Option key={'COMPLETED'} value={'COMPLETED'}>
      {'COMPLETED'}
    </Option>
    <Option key={'EXPIRED'} value={'EXPIRED'}>
      {'EXPIRED'}
    </Option>
  </>
);


const InActiveCampaigns = () => {
  const [isFetching, setIsFetching] = useState(false);
  const [campaignsList, setCampaignsList] = useState([]);
  const [totalCampaigns, setTotalCampaigns] = useState(0);
  const [campaignsType, setCampaignsType] = useState([]);
  const [companiesName, setCompaniesName] = useState([]);
  const [industryType,setIndustryType]=useState([])
 const [deletingWatchId, setDeletingWatchId]=useState('')
  const [searchPagination, setSearchPagination] = useState({
    pageSize:100,
    page:1,
    order: -1,
    orderBy: 'start_date',
    searchValue: {},
  });
  const [form]=Form.useForm()

  useEffect(() => {
    fetchCampaignsList(searchPagination);
  }, [searchPagination?.searchValue]);

  useEffect(()=>{
    getCampaignTypes();
    getCompaniesName();
    getIndustryType();
  },[])

  const fetchCampaignsList = (searchPaginationParams) => {
    setIsFetching(true);
    const queryParams = new URLSearchParams();

    queryParams.append('pageSize', searchPaginationParams.pageSize);
    queryParams.append('page', searchPaginationParams.page);
    queryParams.append('order', searchPaginationParams.order);
    queryParams.append('orderBy', searchPaginationParams.orderBy);
    queryParams.append('campaign_active_status', false);
  
    if (
      searchPaginationParams.searchValue &&
      Object.keys(searchPaginationParams.searchValue).length >= 1
    ) {
      Object.keys(searchPaginationParams.searchValue).forEach((key) => {
        queryParams.append(key, searchPaginationParams.searchValue[key]);
      });
    }
    const url = `${
      URLS_V2?.CAMPAIGNS?.GET_CAMPAIGNS_LIST
    }?${queryParams.toString()}`;
    httpGet(url)
      .then((res) => {
        if (res?.status === 200) {
          setCampaignsList(res?.data || []);
          setTotalCampaigns(res?.totalItems || 0);
        }else{
          showErrorMsg(res?.message);
          setCampaignsList([]);
          setTotalCampaigns(0)
        }
      })
      .catch((error) => {
        setCampaignsList([]);
        setTotalCampaigns(0);
      })
      .finally(()=>{
        setIsFetching(false);
      });
  };

  const getCampaignTypes = async () => {
    try {
      const res = await httpGet(URLS_V2.CAMPAIGNS.GET_CAMPAIGN_TYPE);
      setCampaignsType(res?.data);
    } catch (error) {
      console.log('error');
    }
  };

  const getCompaniesName = async () => {
    try {
      const res = await httpGet(URLS_V2.CAMPAIGNS.COMPANIES_LIST);
      setCompaniesName(res?.data);
    } catch (error) {
      console.log('error');
    }
  };

  const getIndustryType = async () => {
    try {
      const res = await httpGet(URLS_V2.CAMPAIGNS.GET_INDUSTRY_CAMPAIGN);
      setIndustryType(res?.data);
    } catch (error) {
      console.log('error');
    }
  };

  const columns = [
    {
      key: 'campaign_name',
      title: CAMPAIGN_LABELS.CAMPAIGN_NAME,
      dataIndex: 'campaign_name',
      sorter: true,
    },
    {
      key: 'company_name',
      title: CAMPAIGN_LABELS.COMPANY_NAME,
      dataIndex: ["campaign_company",'company_name'],
      sorter: true,
    },
    {
      key: 'campaign_type_name',
      title: CAMPAIGN_LABELS.CAMPAIGN_TYPE,
      dataIndex: ["campaign_type",'campaign_type_name'],
      sorter: true,
    },
    {
      key: 'campaign_status',
      title: CAMPAIGN_LABELS.CAMPAIGN_STATUS,
      dataIndex: 'campaign_status',
      sorter: true,
    },
    {
      key: 'start_date',
      title: CAMPAIGN_LABELS.START_DATE,
      dataIndex: 'start_date',
      sorter: true,
      // defaultSortOrder: 'ascend',
      defaultSortOrder: 'descend',
      render: (value) => {
        return (function Actions() {
          return (
            <div className="created_at">
              {value && dateFormatter(value, 'Do MMMM YYYY')}
            </div>
          );
        })();
      },
    },
    {
      key: 'end_date',
      title: CAMPAIGN_LABELS.END_DATE,
      dataIndex: 'end_date',
      sorter: true,
      render: (value) => {
        return (function Actions() {
          return (
            <div className="created_at">
              {value && dateFormatter(value, 'Do MMMM YYYY')}
            </div>
          );
        })();
      },
    },
    {
      key: '_id',
      title: CAMPAIGN_LABELS.ACTIONS,
      dataIndex: '_id',
      render: (id) => {
        return (function Actions() {
          return (
            <div className="actions-btn">
              <Link to={`/v2/campaigns/details/${id}`}>
                <Button
                  shape="circle"
                  className="edit-btn"
                  style={{ marginLeft: '0.5rem' }}
                >
                  <EyeOutlined type="primary" className="preview-icon" />
                </Button>
              </Link>
              {/* <Link to={`/v2/campaigns/edit/${id}`}>
                <Button
                  shape="circle"
                  className="edit-btn"
                  style={{ marginLeft: '0.5rem' }}
                >
                
                  <FormOutlined type="primary" className="edit-icon" />
                </Button>
              </Link> */}
              <Popconfirm
                    title="Are you sure you want to Delete this Campaign?"
                    onConfirm={()=>handleDeleteCampaign(id)}
                    // onCancel={cancel}
                    okText="Okay"
                    cancelText="Cancel"
                  >
                    <Button
                  shape="circle"
                  className="delete-btn"
                  style={{ marginLeft: '0.5rem' }}
                >
                  {deletingWatchId === id ? (
                    <Spin indicator={<LoadingOutlined spin />} />
                  ) : (
                    <DeleteOutlined type="primary" className="delete-icon" />
                  )}
                </Button>
                  </Popconfirm>
            </div>
          );
        })();
      },
    },
  ];

  const handleTextSearch = (e) => {
    if (e.target.value) {
      setSearchPagination({
        ...searchPagination,
        searchValue: {
          ...searchPagination.searchValue,
          [e.target.name]: e.target.value,
        },
      });
    } else if (searchPagination.searchValue[e.target.name] !== undefined) {
      delete searchPagination.searchValue[e.target.name];
      setSearchPagination({
        ...searchPagination,
        searchValue: { ...searchPagination.searchValue },
      });
    }
  };

  const handleSelectSearch = (selectedValue, selected) => {
    if (selected === 'campaign_type') {
      if (selectedValue) {
        setSearchPagination({
          ...searchPagination,
          searchValue: {
            ...searchPagination.searchValue,
            campaign_type: selectedValue,
          },
        });
      } else if (searchPagination.searchValue['campaign_type'] !== undefined) {
        delete searchPagination.searchValue['campaign_type'];
        setSearchPagination({
          ...searchPagination,
          searchValue: { ...searchPagination.searchValue },
        });
      }
    } else if(selected === 'campaign_status'){
      if (selectedValue) {
        setSearchPagination({
          ...searchPagination,
          searchValue: {
            ...searchPagination.searchValue,
            campaign_status: selectedValue,
          },
        });
      } else if (
        searchPagination.searchValue['campaign_status'] !== undefined
      ) {
        delete searchPagination.searchValue['campaign_status'];
        setSearchPagination({
          ...searchPagination,
          searchValue: { ...searchPagination.searchValue },
        });
      }
    }else if(selected === 'campaign_company'){
      if (selectedValue) {
        setSearchPagination({
          ...searchPagination,
          searchValue: {
            ...searchPagination.searchValue,
            campaign_company: selectedValue,
          },
        });
      } else if (
        searchPagination.searchValue['campaign_company'] !== undefined
      ) {
        delete searchPagination.searchValue['campaign_company'];
        setSearchPagination({
          ...searchPagination,
          searchValue: { ...searchPagination.searchValue },
        });
      }
    }else if(selected === 'campaign_industry'){
      if (selectedValue) {
        setSearchPagination({
          ...searchPagination,
          searchValue: {
            ...searchPagination.searchValue,
            campaign_industry: selectedValue,
          },
        });
      } else if (
        searchPagination.searchValue['campaign_industry'] !== undefined
      ) {
        delete searchPagination.searchValue['campaign_industry'];
        setSearchPagination({
          ...searchPagination,
          searchValue: { ...searchPagination.searchValue },
        });
      }
    }else if(selected === 'is_deleted'){
      if (selectedValue) {
        setSearchPagination({
          ...searchPagination,
          searchValue: {
            ...searchPagination.searchValue,
            is_deleted: selectedValue,
          },
        });
      } else if (
        searchPagination.searchValue['is_deleted'] !== undefined
      ) {
        delete searchPagination.searchValue['is_deleted'];
        setSearchPagination({
          ...searchPagination,
          searchValue: { ...searchPagination.searchValue },
        });
      }
    }
  };

  const handleDateRangeSearch = (value) => {
    if (value && value.length > 1) {
      setSearchPagination({
        ...searchPagination,
        searchValue: {
          ...searchPagination.searchValue,
          campaign_start_date: value[0]?.unix(),
          campaign_end_date: value[1]?.unix(),
        },
      });
    } else if (
      searchPagination.searchValue['campaign_start_date'] !== undefined &&
      searchPagination.searchValue['campaign_end_date'] !== undefined
    ) {
      delete searchPagination.searchValue['campaign_start_date'];
      delete searchPagination.searchValue['campaign_end_date'];
      setSearchPagination({
        ...searchPagination,
        searchValue: { ...searchPagination.searchValue },
      });
    }
  };

  const handlePaginationChange = (pagination, filters, sorter) => {
    const { current, pageSize } = pagination;
    const tempSearchPagination = {
      ...searchPagination,
      page: String(current),
      pageSize: pageSize,
      order: sorter.order === 'descend' ? -1 : 1,
      orderBy: typeof sorter?.field ==="object"?sorter?.field?.[0]:sorter?.field,
    };
    setSearchPagination(tempSearchPagination);
    fetchCampaignsList(tempSearchPagination);
  };

  const  handleAllClear=()=>{
    setSearchPagination({
      ...searchPagination,
      searchValue: {},
    });
    form.resetFields();
  }

  const defaultTitle = () => {
    return (
      <>
        <div className="cus-table-right-btn">
          {`Showing Records: ${campaignsList?.length || 0} / Total Records: ${totalCampaigns}`}
        </div>
      </>
    );
  };

  const handleDeleteCampaign =(id)=>{
    setDeletingWatchId(id);

    let url=URLS_V2?.CAMPAIGNS?.DELETE_CAMPAIGN.replace("#CAMPAIGN_ID#", id)
    
    httpPut(url)
    .then((res)=>{
      if(res?.status ==200){
    showSuccessMsg('Campaign Deleted SuccessFully')
    setDeletingWatchId('');
    fetchCampaignsList(searchPagination)
      }else{
        showErrorMsg(res?.message)
    setDeletingWatchId('');

      }
    })
    .catch((err)=>{
      showErrorMsg(err.message)
    setDeletingWatchId('');
    })
     }

  return (
    <>
      <CommonBreadCrumb breadCrumbs={breakCrumbList} />
      <div className="while-bg-box">
        <Form className="main-filter-form" form={form} >
          <Row gutter={[16, 16]}>
            <Col md={6}>
              <Form.Item name="campaign_name" style={{ marginBottom: '0' }}>
                <Input
                  type="text"
                  name="campaign_name"
                  onChange={debounce((event) => handleTextSearch(event))}
                  placeholder="Search Campaign Name"
                />
              </Form.Item>
            </Col>
            <Col md={6}>
              <Form.Item name="campaign_type" style={{ marginBottom: '0' }}>
                <Select
                  showSearch
                  allowClear
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    option.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                  placeholder="Select Campaign Type"
                  onChange={(val) => handleSelectSearch(val, 'campaign_type')}
                >
                    {campaignsType?.map((item) => (
                    <Option key={item?._id} value={item?._id}>
                      {item?.campaign_type_title}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col md={6}>
              <Form.Item name="campaign_company" style={{ marginBottom: '0' }}>
                <Select
                  showSearch
                  allowClear
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    option.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                  placeholder="Select Company Name"
                  onChange={(val) => handleSelectSearch(val, 'campaign_company')}
                >
                  {companiesName?.map((item) => (
                    <Option key={item?._id} value={item?._id}>
                      {item?.company_name}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col md={6}>
              <Form.Item name="campaign_industry" style={{ marginBottom: '0' }}>
                <Select
                  showSearch
                  allowClear
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    option.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                  placeholder="Select Industry Type"
                  onChange={(val) => handleSelectSearch(val, 'campaign_industry')}
                >
                  {industryType?.map((item) => (
                    <Option key={item?._id} value={item?._id}>
                      {item?.industry_title}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col md={6}>
              <Form.Item name="campaign_status" style={{ marginBottom: '0' }}>
                <Select
                  showSearch
                  allowClear
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    option.children
                      .toLowerCase()
                      .indexOf(input?.toLowerCase()) >= 0
                  }
                  placeholder="Select Campaign Status"
                  onChange={(val) => handleSelectSearch(val, 'campaign_status')}
                >
                  {campaignStatusSelectOptions}
                </Select>
              </Form.Item>
            </Col>
            <Col md={6}>
              <Form.Item
                name="campaignStartEndTime"
                style={{ marginBottom: '0' }}
              >
                <RangePicker
                  name="campaignStartEndTime"
                  format="YYYY-MM-DD"
                  style={{ width: '100%' }}
                  placeholder={['Campaign Start Date', 'Campaign End Date']}
                  onChange={(campaignStartEndTime) =>
                    handleDateRangeSearch(campaignStartEndTime)
                  }
                />
              </Form.Item>
            </Col>
            <Col md={6}>
              <Form.Item name="is_deleted" style={{ marginBottom: '0' }}>
                <Select
                  showSearch
                  allowClear
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    option.children
                      .toLowerCase()
                      .indexOf(input?.toLowerCase()) >= 0
                  }
                  placeholder="Deleted"
                  onChange={(val) => handleSelectSearch(val, 'is_deleted')}
                >
                  <Option key={true} >Yes </Option>
                  <Option key={false} >No</Option>
                </Select>
              </Form.Item>
            </Col>
          </Row>
          
         <div style={{ marginTop:"10px" }} >
          <Button onClick={handleAllClear} disabled={!Object.keys(searchPagination.searchValue)?.length}  >Clear All</Button>
         </div>
        </Form>

      </div>
      <div className="main-table-body">
        <Table
          title={defaultTitle}
          loading={{
              indicator: <Loader>Fetching...</Loader>,
              spinning: isFetching,
            }}
          onChange={handlePaginationChange}
          columns={columns}
          dataSource={campaignsList || []}
          size="small"
          pagination={{
            position: ['topLeft', 'none'],
            defaultPageSize: 100, 
            total: totalCampaigns
          }}
          scroll={{ x: 400 }}
        />
      </div>
    </>
  );
};

export default InActiveCampaigns;

const CAMPAIGN_LABELS = {
  CAMPAIGN_NAME: 'Campaign Name',
  COMPANY_NAME: 'Company Name',
  CAMPAIGN_TYPE: 'Campaign Type',
  CAMPAIGN_STATUS: 'Campaign Status',
  START_DATE: 'Start Date',
  END_DATE: 'End Date',
  ACTIONS: 'Actions',
};
